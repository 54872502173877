import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderFieldRadio, SelectField, renderSelecttree } from '../../../Utils/renderField';
import {renderTextArea, AsyncSelectField} from "../../../Utils/renderField/renderField";
import { Link } from 'react-router-dom';
import { api } from 'api';

const getBonificaciones = (search) => {
    return api.get("bonificacion", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

let PagosItinerarios = (props) => {
    const { handleSubmit, pristine, reset, opcion_monto, onChangeTitulo, tipo_gastos } = props;
    return (
        <form name="pagosItinerariosform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="col-md-4 col-12">
                    <div className="form-group has-feedback col-12 p-0">
                        <label className="t-azul" htmlFor="nombre">Nombre</label>
                        <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    <div className="form-group has-feedback col-12 p-0">
                        <label className="t-azul"  htmlFor="monto">Monto</label>
                        <Field name="monto" label="monto" component={renderCurrency} type="text" className="form-control" />
                    </div>
                </div>
            </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-12">
                        <div className="form-group has-feedback col-12 p-0">
                            <label className="t-azul" htmlFor="nombre">Bonificacion</label>
                            <Field
                                name={`bono`}
                                component={AsyncSelectField}
                                loadOptions={getBonificaciones}
                                type="text"
                                filterOptions= {false}
                                isClearable={true}
                                valueKey="id"
                                labelKey="nombre"
                                className="form-control"
                                top ={{top:"67px", position:"inherit"}}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                    </div>
                </div>

            <br/>
            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/pagos_itinerario" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


PagosItinerarios = reduxForm({
    form: 'pagosItinerariosform', // a unique identifier for this form,
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            monto: validators.exists()('Este campo es requerido'),
            //bono: validators.exists()('Este campo es requerido'),
        });
    },
})(PagosItinerarios);


export default PagosItinerarios
