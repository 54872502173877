import React, { Component } from 'react'
import ToolBar from '../../../Utils/Toolbar/ToolbarReporteFlotantes';
import Modal from 'react-responsive-modal';

import { Link } from 'react-router-dom';



import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";
import {standardActions} from "../../../Utils/Grid/StandardActions";

import moment from 'moment';
import TarjetaCreditoForm from './tarjeta_form';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

import { RenderDatosAfectadoReporte } from '../../../Utils/renderField/renderReadField';

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class TarjetaFlotante extends Component {
    state = {
        id_transaccion: 0,
        transaccion_sel: null,
        anular_transaccion: false,
        open_modal_retiro:false,
        rows_seleccionado: [],
        seleccionados_default: []
    }
    componentDidMount(){
        this.props.reporteTarjetaFlotante();
    }
    componentDidUpdate(prevProps) {
        if(prevProps.reporte_tarjetas !== this.props.reporte_tarjetas){
            this.setState({
                rows_seleccionado: this.props.reporte_tarjetas.movimientos
            }, () =>  this.props.setTarjetaSeleccionados(this.state.rows_seleccionado));
            let new_array = _.map(this.props.reporte_tarjetas.movimientos, 'id');
            this.setState({
                seleccionados_default: new_array
            });
        }
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    botonModal = (id, row) => {

        if(row.estado){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../assets/img/icons/Ver.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            anular_transaccion: true
                        })
                        this.props.setOpenModal(true);
                     }}
                      alt="Ver eliminado" />)
    }
    botonRealizarMovimiento = (id, row) => {
        if(!row.estado){
            return(<div></div>)
        }
        if(!row.flotante){
            return(<div></div>)
        }
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={
                (e)=>{
                e.preventDefault();
                this.setState({
                id_transaccion: id,
                transaccion_sel: row,
                anular_transaccion: false
                })
                this.props.setOpenModal(true);
            }
            }>
                <img className="action-img" title="adicional" src={require("../../../../../../assets/img/icons/marcar_bono.png")} alt="Ver eliminado" />
            </a>
        )
    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModal(true);
    }
    onSubmit = (values) => {
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values);
            }
        });
    }
    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD')
                this.props.flotanteChequePagado(values);
            }
        });
    }
    renderModalAnulacion = () => {
        return(
            <Fragment>
                <div className="mt-5">
                            <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                        </div>
                            {
                                (this.state.transaccion_sel && !this.state.transaccion_sel.estado) ? (
                                    <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                                ): (
                                    <AnulacionForm
                                    onSubmit={this.onSubmit}
                                    cerrarModal={this.props.setOpenModal}
                                    />
                                )
                            }
            </Fragment>
        )
    }
    renderModalFlotantePagado = () => {
        let transaccion = null
        if(this.state.transaccion_sel){
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            }
        }
        return(
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">REALIZAR PAGO</h2>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={this.props.setOpenModal}
                    />
            </Fragment>
        )
    }

    onRowSelect = (row, isSelected) => {
        let _rows_seleccionados = _.cloneDeep(this.state.rows_seleccionado);
        const _row =  _.find(_rows_seleccionados, (x) => {
            return row.id == x.id;
        });
        if(_row){
            _.remove(_rows_seleccionados, (x) => {
                return x == _row;
            })
            let array_nuevo = _.cloneDeep(this.state.seleccionados_default);
            _.remove(array_nuevo, (x) => {
                return row.id == x
            })
            this.setState({
                rows_seleccionado: [..._rows_seleccionados],
                seleccionados_default: array_nuevo
            })
            this.props.setTarjetaSeleccionados([..._rows_seleccionados])
        }else{
            this.setState({
                rows_seleccionado: [..._rows_seleccionados, row],
                seleccionados_default: [...this.state.seleccionados_default, row.id]
            })
            this.props.setTarjetaSeleccionados([..._rows_seleccionados, row])
        }

    }
    onSelectAll = (isSelect, rows) => {
        let newRowsIds = isSelect ? rows.map(row => row.id) : [];
        let newRows = isSelect ? _.cloneDeep(rows) : [];
        this.setState({
            rows_seleccionado: newRows,
            seleccionados_default: newRowsIds
         });
         this.props.setTarjetaSeleccionados(newRows);
    };

    render() {
        const {
            loader,
            setCuentaTarjeta,
            cuentas_banco,
            reporte_tarjetas,
            open_modal_tarjeta,
            filtro_poss,
            estado_tarjeta,
            estadoTarjetaChange
        } = this.props;
        var selectRowProp = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.onRowSelect,
            selected: this.state.seleccionados_default,
            onSelectAll: this.onSelectAll
        };

        let boton_estado = false;
        if(this.state.seleccionados_default.length > 0) {
            boton_estado =  false;
        }else {
            boton_estado =  true;
        }

        return (
            <div>
                <Modal open={open_modal_tarjeta} onClose={(e) => {
                    this.props.setOpenModalTarjeta(false)
                }} center>
                    <div style={{border: "1px solid #e8e8e8", padding:"10px"}} className="mt-5 mb-3">
                        <div className="d-flex justify-content-center">
                            <h2 className="t-primary text-uppercase align-self-center">PAGAR</h2>
                        </div>
                    </div>
                    <div>
                    <TarjetaCreditoForm
                        cerrarModal={this.props.setOpenModalTarjeta}
                        initialValues={{ fecha: new Date(), tarjeta_seleccionados: this.state.rows_seleccionado,
                            poss: filtro_poss ? filtro_poss.id : null}}
                        titulo_modal="INGRESAR COBRO A"
                        onSubmit={(e) => {
                            let values = _.cloneDeep(e);
                            values.fecha = moment(values.fecha).format('YYYY-MM-DD');
                            let transacciones_arr = [];
                            let tarjeta_seleccionados = [];
                            let monto = 0;
                            values.tarjeta_seleccionados.forEach(x => {
                                x.monto_ingresado = x.monto_ingresado;
                                x.id = x.transaccion.id;
                                monto += Number(x.monto_ingresado);
                                tarjeta_seleccionados.push({monto_ingresado: Number(x.monto_ingresado), id: x.transaccion.id})
                                transacciones_arr.push(x.transaccion.id);
                            });
                            values.ids_tran = transacciones_arr;
                            values.monto = monto;
                            values.tarjeta_seleccionados = tarjeta_seleccionados;
                            this.props.marcarComoPagado(values);
                            this.props.setOpenModalTarjeta(false);
                        }} />
                    </div>
                </Modal>
                <div className="py-md-3 px-md-3 p-0" style={{border:"1px solid #E8E8E8", }}>
                    <ToolBar
                        es_tarjeta
                        {...this.props}
                        change_poss={this.props.setFiltroPoss}
                        filtro_poss={this.props.filtro_poss}
                        cuentas_banco={cuentas_banco}
                        changeFiltro={setCuentaTarjeta}
                        changeEstadoFiltro={true}
                        valorEstado={estado_tarjeta}
                        changeEstado={estadoTarjetaChange}
                        />
                </div>
                <div className="py-md-3 px-md-3 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-md-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-10 m-0 p-0 inline-headers text-center">
                            <span className="t-azul-claro h5 mr-3 font-weight-bold"  htmlFor="descripcion">TOTAL POR COBRAR </span>
                            <h3 className="t-primary"  htmlFor="descripcion">{
                             <RenderMoneda monto={reporte_tarjetas.total_cobrar} simbolo={"Q"} />
                            }</h3>
                        </div>
                    </div>
                </div>
                <div className="py-md-3 px-md-0 p-0" >
                    <ToolbarSimple
                        buscar={this.props.searchTarjetaChange}
                        buscador={this.props.search_tarjeta}
                        placeholder={"Buscar por: No doc, descripción, monto"}
                    />
                </div>
                {
                    estado_tarjeta.value && (
                        <div className="d-flex justify-content-center justify-content-md-end mt-4">
                    <Link
                        style={this.props.tarjetas_seleccionados < 1 ? {pointerEvents: "none", opacity: 0.5} : null}
                        to={`/reporte_asignar_tarjeta`}  className="btn btn-primary text-uppercase" >
                        PAGAR
                    </Link>
                </div>
                    )
                }
                <div className="p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                        <Grid
                        hover
                        striped
                        selectRowProp={selectRowProp}
                        data={{count: 0, results: reporte_tarjetas.movimientos}} loading={loader} className='reporte-table'  >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    className='reporte-table'
                                    dataSort
                                    isKey
                                    hidden
                                    dataFormat={standardActions({ adicional: this.botonModal, adicional2: this.botonRealizarMovimiento})}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    className='reporte-table'
                                    dataField="fecha"
                                    tdStyle={cellTachado}
                                    dataSort
                                    dataFormat = {
                                        (cell,row) => <RenderDatosAfectadoReporte cell={cell} row={row} />
                                    }
                                >
                                    FECHA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="transaccion"
                                    dataSort
                                    className='reporte-table'
                                    dataFormat={
                                        (cell, row) => {
                                            if(cell){
                                            return <span>{cell.descripcion}</span>
                                            }
                                            return <span>-- --</span>
                                        }
                                    }
                                    tdStyle={cellTachado}
                                >
                                    DESCRIPCIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="es_ingreso"
                                    className='reporte-table'
                                    tdStyle={cellTachado}
                                    dataSort
                                    dataFormat = {
                                        (cell, row)=>(cell? "Ingreso":"Egreso")
                                    }
                                >
                                    MOVIMIENTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="transaccion"
                                    className='reporte-table'
                                    dataSort
                                    tdStyle={cellTachado}
                                    dataFormat={
                                        (cell, row) => {
                                            if(cell){
                                                return <span>{cell.no_documento}</span>
                                            }
                                            return <span>-- --</span>
                                        }
                                    }
                                >
                                    DOCUMENTO
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="saldo"
                                    dataSort
                                    className='reporte-table'
                                    tdStyle={cellTachado}
                                    dataFormat ={
                                        (cell, row)=>(
                                            <RenderMoneda monto={ row.transaccion.monto } simbolo={this.getsimbolo(row.transaccion.moneda)} />
                                        )
                                    }
                                >
                                    MONTO
                                </TableHeaderColumn>
                        </Grid>
                </div>
            </div>
        )
    }
}
