import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import {standardActions} from '../../../Utils/Grid/StandardActions';
import moment from 'moment';

export default class GridAsignados extends Component {
    componentDidMount() {
        this.props.getDespachosAsignados();
    }

    render() {
        const { page2, data2, loader, getDespachosAsignados} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data2}
                    loading={loader}
                    page={page2}
                    className="reporte-table"
                    onPageChange={getDespachosAsignados}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        className='reporte-table'
                        width="2%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="fecha_despacho"
                        className='reporte-table'
                        dataFormat={(cell) => moment(cell).format("DD/MM/YYYY")}
                    >
                        FECHA DE DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despacho"
                        className='reporte-table'
                    >
                        NO. DE DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="itinerario"
                        className='reporte-table'
                        dataFormat={(cell) => `No. ${cell}`}
                    >
                        NO. DE ITINERARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente"
                        className='reporte-table'
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="piloto"
                        className='reporte-table'
                    >
                        PILOTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="vehiculo"
                        className='reporte-table'
                    >
                        VEHÍCULO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="entregado"
                        className='reporte-table'
                        width="11%"
                        dataFormat={(cell) => cell ? "Entregado" : "No entregado"}
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
