import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {customStandardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

export default class EstablecimientoGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos["establecimientos"] === false) {
            if(!!permisos[`ver_establecimientos`] === false) {
                return <Redirect to={`/`} />
            }
        } 
        return(
            <div className="Container bg-white">
                <h3 className="t-primary m-t p-t">Establecimientos</h3>
                <div className="row justify-content-end">
                    <div className="col-md-8 col-12">
                        <ToolbarSimple 
                            textBoton={permisos.crear_establecimientos ? "AGREGAR" : ""}
                            ruta="/establecimiento/nuevo"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre"}
                            permisos={permisos.establecimientos}
                        />
                        {/* <Link className="btn btn-primary" to="/Establecimiento/nuevo">AGREGAR</Link> */}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange} onSortChange={onSortChange} >
                                   { permisos.establecimientos &&( <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataSort
                                        dataFormat={(cell) => {
                                            return customStandardActions(cell, {
                                                eliminar: permisos.eliminar_establecimientos ? eliminar: undefined,
                                                editar: permisos.editar_establecimientos ? "establecimiento": undefined,
                                            });
                                        }}
                                    >
                                    </TableHeaderColumn>)}
                                    <TableHeaderColumn
                                        isKey
                                        dataField="nombre"
                                        dataSort
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="direccion"
                                        dataSort
                                    >
                                        DIRECCIÓN
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

