import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'valesGasolina' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'VALES_LOADER',
    DATA: "VALES_DATA",
    DATA_GENERAL: "DATA_GENERAL_VALES",
    ITEM: "VALES_ITEM",
    PAGE: `VALES_PAGE`,
    SET_VEHICULO: 'SET_VEHICULO_VALE',
    SET_FECHA_I: 'SET_FECHA_I_VALE',
    SET_FECHA_F: 'SET_FECHA_F_VALE',
    SET_MODAL_ANULACION: 'SET_MODAL_ANULACION_VALE'
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type:type,
    data
})

const setDataGeneral = ( type, dataGeneral ) =>({
    type: type,
    dataGeneral
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setVehiculo = vehiculo =>({
    type:constants.SET_VEHICULO,
    vehiculo
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});


const setOpenModalAnulacion = open_modal => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_ANULACION,
        open_modal_anulacion: open_modal
    })
}

// ACTIONS

const getResumen = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteValesGasolina;
    const params = { page };
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    if (resource.fecha_inicio)
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_final = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`${url}/reporteGeneralVales`, params).then((response) => {
        dispatch(setDataGeneral(constants.DATA_GENERAL ,response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteValesGasolina;
    const params = { page };
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    if (resource.fecha_inicio)
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_final = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`${url}/reporteVales`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
        dispatch(setData(constants.SET_PAGOS_CREDITO, response.pagos_ordenes))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));

    }).catch((error) => {
       mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const nuevoGasto = (monto) => (dispatch, getStore) => {
    const resource = getStore().reporteValesGasolina
    let data = {
        fecha: moment(),
        tipo_cuenta: "true",
        monto,
        desglose: [{}],
        vehiculo: resource.vehiculo,
        fecha_inicio: resource.fecha_inicio,
        fecha_fin: resource.fecha_fin
    }
    dispatch(push('/reporte_vales_gasolina/gasto'));
    if('GastoForm'){
        dispatch(initializeForm('GastoForm',data))
    }
}

const cerrar = id => (dispatch, getStore) => {
    dispatch(setLoader(true))
    let params = { id }
    api.post(`${url}/cerrar`,params).then((response) => {
        dispatch(leerItinerario(id))
        NotificationManager.success('Itinerario Cerrado', 3000);
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false))
    })
}

const editar = (id, data) => (dispatch, getStore) => {
    //data = formatData(data, getStore)
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(`/itinerario/${id}/detalle`));
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const anularVale = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/${data.id}/anularVale`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(getResumen());
        dispatch(listar());
        dispatch(setOpenModalAnulacion(false))
    }).catch((error) => {
        let mensaje = "Error en la anulación";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const mensajeError = (error) => {
    let mensaje = 'Error en la creación de la ruta';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
}

const changeVehiculo = (value) => (dispatch) => {
    dispatch(setVehiculo(value));
    dispatch(getResumen());
    dispatch(listar());
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(getResumen());
    dispatch(listar());
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(getResumen());
    dispatch(listar());
};

export const actions = {
    getResumen,
    listar,
    leer,
    crear,
    cerrar,
    editar,
    eliminar,
    changeVehiculo,
    changeFechaInicio,
    nuevoGasto,
    changeFechaFin,
    setOpenModalAnulacion,
    anularVale,
}

export const reducers = {
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) =>{
        return{
            ...state,
            data,
        };
    },
    [constants.DATA_GENERAL]:(state,{dataGeneral}) =>{
        return{
            ...state,
            dataGeneral,
        };
    },
    [constants.ITEM]:(state,{item}) =>{
        return{
            ...state,
            item,
        };
    },
    [constants.SET_MODAL_ANULACION]:(state,{ open_modal_anulacion })=>{
        return{
            ...state,
            open_modal_anulacion,
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SET_VEHICULO]: (state, { vehiculo }) => {
        return {
            ...state,
            vehiculo
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    dataGeneral: undefined,
    open_modal_anulacion: false,
    item: {},
    page: 1,
    ordering: '',
    vehiculo: null,
    fecha_inicio: moment().startOf('month'),
    fecha_fin: moment(),
}

export default handleActions(reducers, initialState);
