import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";

// Manejo de tabs
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';
import ToolbarReporteVales from '../../../Utils/Toolbar/ToolbarReporteVales';
import GridGastos from './GridGastos';
import AnulacionForm from './anulacionForm';
import { Redirect } from 'react-router';

export default class ReporteValesGasolina extends Component {

    state = {
        id_vale: undefined,
    }

    componentDidMount(){
        this.props.getResumen();
        this.props.listar();
    }

    abrirModalEliminar = (id, row) => {
        let _data = this.props.data
        this.setState({
            id_vale: id,
        })
        this.props.setOpenModalAnulacion(true);
    }

    onSubmit = (values) => {
        values.id = this.state.id_vale;
        Swal.fire({
            title: '¿Desea anular este vale?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularVale(values);
            }
        });
    }

    nuevoGasto = (monto_total_pendiente) => {
        this.props.nuevoGasto(monto_total_pendiente);
    }

    render() {
        const { loader, data, dataGeneral, permisos, open_modal_anulacion } = this.props;
        if(permisos.reporte_vales === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="VALES DE GASOLINA">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReporteVales {...this.props} />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-12 d-flex justify-content-center" >
                            <div >
                                <br/>
                                <h3 className="t-musgo">TOTAL DE GASTOS</h3>
                                <h3 className="t-primary m-0 text-center">
                                    <RenderMoneda monto={dataGeneral != null ? dataGeneral.monto_total : 0}/>
                                </h3>
                                <br/>
                            </div>
                        </div>
                    </div>
                    {
                        (this.props.vehiculo) && (
                            <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                                style={{border:"1px solid #E8E8E8", }}>
                                <div className="col-12 d-flex justify-content-center" >
                                    <div >
                                        <br/>
                                        <h3 className="t-musgo">RENDIMIENTO PROMEDIO DEL VEHICULO</h3>
                                        <h3 className="t-mostaza m-0 text-center">
                                            {dataGeneral != null && dataGeneral.rendimiento > 0 ? `${dataGeneral.rendimiento} km/gal` : "Sin registro"}
                                        </h3>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-12 d-flex justify-content-center" >
                            <div >
                                <br/>
                                <h3 className="t-musgo">PENDIENTE DE PAGAR</h3>
                                <h3 className="t-primary m-0 text-center">
                                    <RenderMoneda monto={dataGeneral != null ? dataGeneral.monto_total_pendiente : 0}/>
                                </h3>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3 mb-3 p-0">
                        <div className="col-12 d-flex justify-content-end" >
                            <button type="button" className="btn btn-primary" onClick={()=>{this.nuevoGasto(dataGeneral.monto_total_pendiente)}}> Pagar </button>
                        </div>
                    </div>

                    <Modal open={open_modal_anulacion} onClose={(e) => {
                        this.props.setOpenModalAnulacion(false)
                    }} center>
                        <div className="mt-5">
                            <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                        </div>
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModalAnulacion}
                            />
                    </Modal>


                    <GridGastos
                        { ...this.props }
                        abrirModalEliminar={this.abrirModalEliminar}
                    />

                </LoadMask>

            </CardSimple>
        )

    }
}
