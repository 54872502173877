import React, { Component } from 'react'
import {Link, Redirect} from 'react-router-dom'
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
// formulario
import DespachoForm from './despachoForm';


export default class DespachoContainer extends Component {
    state = {
        submited: false
    }
    componentDidMount(){
        let id = this.props.match.params.id
        if (id != undefined && id != null){
            
        }else{
            return (<Redirect to="/ordenesventa" />)
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.loader!=this.props.loader){
            this.setState({ submited: this.props.loader })
        }
    }
    handleSubmit = (values) => {
        const {DespacharProducto} = this.props
        if(this.state.submited === false){
            this.setState({ submited: true })
            let id = this.props.match.params.id
            console.log('submit', values)
            DespacharProducto(values, id)
        }
    }
    render() {
        const {loader } = this.props;
        return (
            <CardFormulario titulo={"Orden de despacho"}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <DespachoForm
                        orden={this.props.match.params.id}
                        onSubmit={this.handleSubmit}
                        initialValues={{tipo_movimiento:40}}
                    />
                </LoadMask>
            </CardFormulario>
        )
    }
}
