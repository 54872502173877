import React, { useState, Component, Fragment } from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency, renderSelecttree, renderSelectField, renderFieldRadio, renderPercentage } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import { SelectField, AsyncSelectField, renderNumber } from '../../../Utils/renderField/renderField.js';
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import { TIPO_GASOLINA } from '../../../../../utility/constants';
import moment from 'moment';
import { connect } from 'react-redux';
import { api } from 'api';
import { TipoTransaccion } from '../../../../../utility/constants';


const tipo = [
    {value: 0, label: "Pendiente de pago"},
    {value: 1, label: "Cerrado"}
]

const getPilotos = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("pilotos", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const getVehiculos = (search) => {
    let params = {
        tipo: 1 // Tipo 1 es vehiculos
    }
    if (search) {
        params.search = search;
    }
    return api.get("vehiculos", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const getProveedores = (search) => {
    return api.get("clientes", { search, tipo: 1 }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const renderDesglose = ({ fields, meta: { error, submitFailed }, simbolo, empresas, cambioDescuento }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th ></th>
                            <th>Empresa</th>
                            <th style={{ width: "20%" }}>Porcentaje</th>
                            <th style={{ width: "20%" }}>Subtotal</th>
                        </tr>
                    </thead>

                    <tbody>
                        {fields.map((empresa, index) => {
                            return (
                                <tr key={index} style={{ height: "68px" }}>
                                    <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                                        <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                            title="Borrar"
                                            onClick={() => {
                                                fields.remove(index)
                                            }}
                                        />
                                    </td>
                                    <td className="sin-borde-top">
                                        <Field
                                            name={`${empresa}.empresa`}
                                            label="empresa"
                                            labelKey="nombre" valueKey="id"
                                            component={renderSelectField}
                                            options={empresas}
                                            className="form-control" />
                                    </td>
                                    <td className="sin-borde-top">
                                        <Field
                                            name={`${empresa}.porcentaje`}
                                            type="number"
                                            addClass={"text-right"}
                                            component={renderPercentage}
                                            placeholder="Precio"
                                            _onChange={(e) => { cambioDescuento(e, 'porcentaje', index) }}
                                        />
                                    </td>
                                    <td className="sin-borde-topzz">
                                        <Field
                                            name={`${empresa}.subtotal`}
                                            label="tipo_cambio"
                                            prefix={simbolo}
                                            component={renderCurrency}
                                            parse={cell => parseFloat(cell)}
                                            className="form-control"
                                            top={{ top: "67px", position: "inherit" }}
                                            _onChange={(e) => { cambioDescuento(e, 'precio', index) }}
                                        />
                                    </td>
                                </tr>
                            );

                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR EMPRESA
                </button>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger h5 font-weight-bold">
                {error}
            </div>}
    </div>
)

let ValesForm = (props) => {
    const { handleSubmit, tipo_gastos, tipo_cuenta, tipo_documento, mostrar_banco, mostrar_deposito, select_cuentas, cambioDescuento } = props;
    return (
        <form name="ValesForm" className="form-validate mb-lg m-2 m-md-5" onSubmit={handleSubmit}>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={moment()}
                        maxDate="2030-12-31"
                        minDate="1990-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                </div>
            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="modelo">Piloto</label>
                    <Field
                        name="piloto.user"
                        loadOptions={getPilotos}
                        type="text"
                        component={AsyncSelectField}
                        top={{ top: "5px", position: "inherit" }}
                        valueKey="id"
                        labelKey="nombre"
                        isClearable={true}
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="placa">Vehiculo</label>
                    <Field
                        name="vehiculo"
                        loadOptions={getVehiculos}
                        type="text"
                        component={AsyncSelectField}
                        top={{ top: "5px", position: "inherit" }}
                        valueKey="id"
                        labelKey="placa"
                        isClearable={true}
                        className="form-control"
                    />
                    {
                        (props.vehiculo_elegido) && (
                            <div className="speech-bubble mt-1 p-2 ">
                                <span style={{color: "#ad1717"}} >
                                    {`Proximo cambio de aceite: ${props.vehiculo_elegido.cambio_aceite} km.`}
                                </span>
                            </div>

                        )
                    }
                </div>
            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="marca">Galones</label>
                    <Field name="galones_gasolina" label="galones_gasolina" component={renderField} onCambio={(e) => { props.setGalones() }} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="costo_compra">Precio por Galón</label>
                    <Field name="precio_galon" label="precio_galon" component={renderCurrency} _onChange={(e) => { props.setPrecioGalones(e) }} type="text" className="form-control" />
                </div>
            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="tipo">Tipo de gasolina</label>
                    <Field
                        name="tipo_gasolina"
                        valueKey="value"
                        labelKey="label"
                        options={TIPO_GASOLINA}
                        component={SelectField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="descripcion">Km Vehiculo</label>
                    <Field name="km_vehiculo" label="descripcion" component={renderField} type="number" className="form-control" />
                </div>
            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="tipo">Estado</label>
                    <Field
                        name="cerrado"
                        valueKey="value"
                        labelKey="label"
                        options={tipo}
                        component={SelectField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6 mt-4">
                        <div className="col-md-12 m-0 p-0 d-flex justify-content-start align-items-center">
                            <div className="col-md-10 m-0 p-0 inline-headers text-start">
                                <span className="t-azul-claro font-weight-bold h5 mr-3"  htmlFor="descripcion">TOTAL </span>
                                <h3 className="t-mostaza"  htmlFor="descripcion">{
                                <RenderMoneda monto={props.total} simbolo={"Q"} />
                                }</h3>
                            </div>
                        </div>
                </div>
            </div>

        {
                (props.cerrado) ?
                <div>
                    <div style={{ border: "1px solid #e8e8e8", padding: "10px" }}>
                        <div className="d-flex justify-content-center">
                            <h4 className="t-primary text-uppercase align-self-center">pagar desde</h4>
                        </div>
                        <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                                <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                                <Field
                                    name="tipo_cuenta"
                                    label="Cuenta"
                                    component={renderFieldRadio}
                                    type="radio"
                                    value={"true"}
                                    className="form-control" />
                            </div>
                            <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                                <Field
                                    value={"false"}
                                    name="tipo_cuenta"
                                    label="Mi Caja Chica"
                                    component={renderFieldRadio}
                                    type="radio"
                                    className="form-control" />
                            </div>
                        </div>
                    </div>
                
            <br />
            <div className="row justify-content-center">
                {tipo_gastos && tipo_gastos.tipo_gastos && props.editar ?
                    <div className="form-group has-feedback col-md-12">
                        <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                        <Field
                            name="tipo_gasto"
                            label="nombre"
                            options={tipo_gastos}
                            valueKey="value"
                            labelKey="label"
                            component={renderSelecttree}
                            showSearch={true}
                            filtro="title"
                            defaultValue={props.tipo_gasto.tipo_gasto.value}
                            type="text"
                            className="form-control" />
                    </div>
                    :
                    <div className="form-group has-feedback col-md-12">
                        <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                        <Field
                            name="tipo_gasto"
                            label="nombre"
                            options={tipo_gastos}
                            valueKey="value"
                            labelKey="label"
                            component={renderSelecttree}
                            showSearch={true}
                            filtro="title"
                            type="text"
                            className="form-control" />
                    </div>
                }
            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Proveedor</label>
                    <Field
                        name="proveedor"
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={getProveedores}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {

                        }}
                        className="form-control" />
                </div>
                {
                    (tipo_cuenta) && (
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="numero_cuenta">No. de Cuenta</label>
                            <Field
                                name="numero_cuenta"
                                label="label"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={select_cuentas}
                                className="form-control"
                            />
                        </div>
                    )
                }

            </div>
            {
                (tipo_cuenta) && (
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-md-6">
                            <label className="t-azul" htmlFor="tipo_documento">Tipo de Movimiento</label>
                            <Field
                                name="tipo_documento"
                                label="tipo_documento"
                                labelKey="label" valueKey="value"
                                component={renderSelectField}
                                options={TipoTransaccion}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6">
                            <label className="t-azul" htmlFor="no_documento">{tipo_documento}</label>
                            <Field name="no_documento" label="no_documento" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                )
            }
            {
                (mostrar_deposito) && (
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-md-6">
                            {
                                (mostrar_banco) && (
                                    <Fragment>
                                        <label className="t-azul" htmlFor="no_boleta">No. Depósito</label>
                                        <Field name="no_boleta" label="no_boleta" component={renderField} type="text" className="form-control" />
                                    </Fragment>
                                )
                            }

                        </div>
                        <div className="form-group has-feedback col-md-6">
                            <label className="t-azul" htmlFor="no_documento">Banco</label>
                            <Field name="banco_depositado" label="banco_depositado" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                )
            }

            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="form-group has-feedback col-12 m-0 p-0">
                        <label className="t-azul" htmlFor="descripcion">Descripción</label>
                        <Field
                            name="descripcion"
                            label="descripcion"
                            rows={7}
                            component={renderTextArea}
                            type="text"
                            top={{ top: "174px" }}
                            className="form-control" />
                    </div>
                </div>
            </div>
           
                    <Fragment>
                        <h4 className="t-primary text-uppercase">DESGLOSE DE GASTOS</h4>
                        <div className="col-12 m-0 p-0 d-flex justify-content-center">
                            <FieldArray
                                cambioDescuento={cambioDescuento}
                                simbolo={'Q'}
                                name="desglose"
                                empresas={props.empresas}
                                component={renderDesglose} />
                        </div>
                    </Fragment>
            </div>
                    :""
            }

            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/vales_gasolina" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>                               
        </form>
    );
};


ValesForm = reduxForm({
    form: 'ValesForm', // a unique identifier for this form
    initialValues: {
        fecha: moment()
    },
    validate: (data) => {
        const errors = {};
        if (!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if (!data.vehiculo) {
            errors.vehiculo = "Campo requerido";
        }
        if (data.cerrado == undefined || data.cerrado == null) {
            errors.cerrado = "Campo requerido";
        }
        if (data.cerrado) {
            if(data.cerrado == 1){
                if(!data.piloto){
                    errors.piloto = {
                        user: "Campo requerido"
                    }
                }
                if(!data.galones_gasolina){
                    errors.galones_gasolina = "Campo requerido";
                }
                if(!data.precio_galon){
                    errors.precio_galon = "Campo requerido";
                }
                if(!data.tipo_gasolina){
                    errors.tipo_gasolina = "Campo requerido";
                }
                if(!data.km_vehiculo){
                    errors.km_vehiculo = "Campo requerido";
                }
                if (!data.tipo_cuenta) {
                    errors.tipo_cuenta = "Campo requerido.";
                } else {
                    if (data.tipo_cuenta == 'true') {
                        if (!data.numero_cuenta) {
                            errors.numero_cuenta = "Campo requerido.";
                        }
                        if (!data.tipo_documento) {
                            errors.tipo_documento = 'Campo requerido';
                        }
                        if (!data.no_documento) {
                            errors.no_documento = "Campo requerido"
                        }
                    }
                }
                if (!data.proveedor) {
                    errors.proveedor = "Campo requerido.";
                }
                if (!data.tipo_gasto) {
                    errors.tipo_gasto = 'Campo requerido';
                }
                if (!data.descripcion) {
                    errors.descripcion = "Campo requerido"
                }
                if (!data.desglose || !data.desglose.length) {
                    errors.desglose = { _error: 'Debe de ingresar al menos una empresa' }
                } else {
        
                    const detalleArray = []
                    let hay_error = false
                    data.desglose.forEach(function (empresa, index) {
                        let detErrors = {};
                        let repetidos = [];
                        if (empresa.empresa) {
                            repetidos = _.filter(data.desglose, (x) => {
                                if (x.empresa) {
                                    return x.empresa == empresa.empresa
                                }
        
                                return false;
                            });
                        }
        
                        if (!empresa.empresa) {
                            detErrors.empresa = "Campo requerido";
                            hay_error = true
                        } else {
                            if (1 < repetidos.length) {
                                detErrors.empresa = "Esta empresa está repetido"
                                hay_error = true
                            }
                        }
                        if (!empresa.subtotal) {
                            detErrors.subtotal = "Campo requerido";
                            hay_error = true
                        } else {
                            if (parseFloat(empresa.subtotal) <= 0) {
                                detErrors.subtotal = "Debe ser mayor a 0"
                                hay_error = true
                            }
                        }
                        if (!empresa.porcentaje) {
                            detErrors.porcentaje = "Campo requerido";
                            hay_error = true
                        } else {
                            if (parseFloat(empresa.porcentaje) <= 0) {
                                detErrors.porcentaje = "Debe ser mayor a 0"
                                hay_error = true
                            }
                        }
        
                        if (detErrors) {
                            detalleArray[index] = detErrors;
                        }
                    });
                    if (detalleArray.length) {
                        errors.desglose = detalleArray
                        if (!hay_error) {
                            /*Manejo del total de desglose */
                            let subtotal = 0;
                            data.desglose.forEach(x => {
                                subtotal += Number(x.subtotal)
                            })
                            if (Number(subtotal.toFixed(2)) != Number(data.galones_gasolina * data.precio_galon).toFixed(2)) {
                                console.log('La sumatoria es difertene')
                                errors.desglose = { _error: 'La sumatoria del desglose no coincide con el monto del gasto' }
                            }
                            /*Fin del desglose */
                        }
        
                    }
                }
            }
        }
        
        return errors;
    },
})(ValesForm);


const selector = formValueSelector('ValesForm');
ValesForm = connect(state => {
    const galones_gasolina = selector(state, 'galones_gasolina');
    const precio_galon = selector(state, 'precio_galon');
    const cerrado = selector(state, 'cerrado');
    const tipo_cuenta_form = selector(state, 'tipo_cuenta')
    const tipo_movimiento = selector(state, 'tipo_documento')

    let tipo_cuenta = true
    let mostrar_deposito = false
    let mostrar_banco = false

    if (tipo_cuenta_form == "false") {
        tipo_cuenta = false;
        mostrar_deposito = true
        mostrar_banco = true
    } else {
        mostrar_banco = false
        mostrar_deposito = false
    }
    let tipo_documento = 'Documento de Registro'
    if (tipo_movimiento) {
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        if (seleccionado) {
            if (seleccionado.value == 2) {
                mostrar_deposito = true
                mostrar_banco = true
            } else if (seleccionado.value == 4) {
                mostrar_deposito = true
                mostrar_banco = false
            }
            tipo_documento = seleccionado.ayuda
        }
    }

    let total = 0
    if (galones_gasolina && precio_galon) {
        total = parseFloat(galones_gasolina) * parseFloat(precio_galon)
    }

    return {
        total,
        cerrado,
        tipo_cuenta,
        tipo_documento,
        mostrar_deposito,
        mostrar_banco,
    }

})(ValesForm);

export default ValesForm;
