import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_INGRESOS_LOADER',
    DATA: 'REPORTE_INGRESOS_DATA',
    PAGE: 'REPORTE_INGRESOS_PAGE',
    SET_FECHA_I: 'SET_FECHA_I_ACT_INGRESOS',
    SET_FECHA_F: 'SET_FECHA_F_ACT_INGRESOS',
    SET_TIPO: 'SET_TIPO_INGRESOS',
    SET_BODEGA: 'SET_BODEGA_INGRESOS',
    SET_PRODUCTO: 'SET_PRODUCTO_INGRESOS',
    SET_PROVEEDOR: 'SET_PROVEEDOR_INGRESOS',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = (type, page) => ({
    type,
    page,
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});

// ACTIONS

const reporteIngresos = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteIngresos;
    const params = { page };
    if (resource.fecha_inicio)
        params.fechaI = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fechaF = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.tipo)
        params.tipo = resource.tipo.value;
    if (resource.bodega)
        params.bodega = resource.bodega.id;
    if (resource.producto)
        params.producto = resource.producto.id;
    if (resource.proveedor)
        params.proveedor = resource.proveedor.id;
    dispatch(setLoader(true));
    api.get(`${url}/ingresos_bodega`, params).then((response) => {
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(constants.PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeTipo = (value) => (dispatch) => {
    dispatch(setData(constants.SET_TIPO, value));
    dispatch(reporteIngresos());
};

const changeBodega = (value) => (dispatch) => {
    dispatch(setData(constants.SET_BODEGA, value));
    dispatch(reporteIngresos());
};

const changeProducto = (value) => (dispatch) => {
    dispatch(setData(constants.SET_PRODUCTO, value));
    dispatch(reporteIngresos());
};

const changeProveedor = (value) => (dispatch) => {
    dispatch(setData(constants.SET_PROVEEDOR, value));
    dispatch(reporteIngresos());
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(reporteIngresos());
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(reporteIngresos());
};

export const actions = {
    reporteIngresos,
    changeTipo,
    changeBodega,
    changeProducto,
    changeProveedor,
    changeFechaInicio,
    changeFechaFin,
}

// REDUCERS
export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state, { data }) => {
        return{
            ...state,
            data: data
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SET_TIPO]: (state, { data }) => {
        return {
            ...state,
            tipo: data
        };
    },
    [constants.SET_BODEGA]: (state, { data }) => {
        return {
            ...state,
            bodega: data
        };
    },
    [constants.SET_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            producto: data
        };
    },
    [constants.SET_PROVEEDOR]: (state, { data }) => {
        return {
            ...state,
            proveedor: data
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    tipo: { value: 1, label: "Ingresos por compras" },
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
    bodega: null,
    producto: null,
    proveedor: null,
}

export default handleActions(reducers, initialState);
