import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarReportePrecioProducto from '../../../Utils/Toolbar/ToolbarReportePrecioProducto';
import GridPrecioProducto from './GridPrecioProducto';
import { Redirect } from 'react-router';


export default class ReportePrecioProducto extends Component {

    render() {
        const { loader, permisos } = this.props;
        if(permisos.precios_producto === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE PRECIOS DE PRODUCTOS">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReportePrecioProducto {...this.props} />
                        </div>
                    </div>
                    <br/>

                    <GridPrecioProducto
                        { ...this.props }
                    />

                </LoadMask>

            </CardSimple>
        )

    }
}
