import { push, replace } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import moment from 'moment';


export const createReducer = (storeId, endpoint, formName=undefined, resourceList=undefined) => {

    // ------------------------------------
    // Constants
    // ------------------------------------

    const constants = {
        LOADER: `${storeId.toUpperCase()}_LOADER`,
        DATA: `${storeId.toUpperCase()}_DATA`,
        DATAREFUSE: `${storeId.toUpperCase()}_DATAREFUSE`,
        ITEM: `${storeId.toUpperCase()}_ITEM`,
        PAGE: `${storeId.toUpperCase()}_PAGE`,
        ORDERING: `${storeId.toUpperCase()}_ORDERING`,
        SEARCH: `${storeId.toUpperCase()}_SEARCH`,
        SET_ROLES:'SET_ROLES',
        SET_EMPRESAS:'SET_EMPRESAS',
        SET_ESTABLECIMIENTOS: 'SET_ESTABLECIMIENTOS',
        SET_ESTADO: 'SET_ESTADO',
        SET_REGIONES:'SET_REGIONES',
        SET_CUENTAS: `${storeId.toUpperCase()}_CUENTAS`,
        TIPO_GASTOS: 'GASTOS_TIPO_GASTO',
        SET_SEARCH_EMPRESA: 'SET_SEARCH_EMPRESA',
        SET_SEARCH_ESTABLECIMIENTO: 'SET_SEARCH_ESTABLECIMIENTO'
};

    // -----------------------------------
    // Pure Actions
    // -----------------------------------

    const setLoader = loader => ({
        type: constants.LOADER,
        loader,
    });

    const setData = data => ({
        type: constants.DATA,
        data,
    });

    const setItem = item => ({
        type: constants.ITEM,
        item,
    });

    const setPage = page => ({
        type: constants.PAGE,
        page,
    });

    const setOrdering = ordering => ({
        type: constants.ORDERING,
        ordering,
    });

    const setSearch = search => ({
        type: constants.SEARCH,
        search,
    });

    const setDataSelect = (type, data) =>({
        type,
        data
    });

    // -----------------------------------
    // Actions
    // -----------------------------------

    const listar = (page = 1, data) => (dispatch, getStore) => {
        const resource = getStore()[storeId];
        const params = { page, ...data};
        params.ordering = resource.ordering;
        params.search = resource.search;
        if (resource.empresa != null)
            params.empresa = resource.empresa.value;
        if(storeId == 'usuarios' | storeId == 'vendedores'){
            params.is_active = resource.estado.value
        }
        dispatch(setLoader(true));
        api.get(endpoint, params).then((response) => {
            dispatch(setData(response));
            dispatch(setPage(page));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const listarRefuse = (page = 1) => (dispatch, getStore) => {
        const resource = getStore()[storeId];
        const params = { page };
        params.ordering = resource.ordering;
        params.search = resource.search;
        params.estate = 1
        dispatch(setLoader(true));
        api.get(`${endpoint}`, params).then((response) => {
            dispatch({type: constants.DATAREFUSE ,data: response});
            dispatch(setPage(page));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]))
        });
    };

    const leer = id => (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${endpoint}/${id}`).then((response) => {
            dispatch(setItem(response));
            if (!!formName) {
                if(response.fecha_fundacion){
                    response.fecha_fundacion = new Date(moment(response.fecha_fundacion).format());
                    response.cuenta_inicial = 0
                    response.cuenta_por = "1"
                }
                if(response.es_bonificacion){
                    response.es_bonificacion = "true"
                }else{response.es_bonificacion = "false"}
                dispatch(initializeForm(formName, response));
            }
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const crear = data => (dispatch) => {
        dispatch(setLoader(true));
        api.post(endpoint, data).then(() => {
            NotificationManager.success('Registro creado', 'Éxito', 3000);
            if (!!resourceList)
                dispatch(push(resourceList));
        }).catch((error) => {
            let mensaje = "Error en la creación";
            if(error && error.detail){
                mensaje = error.detail
            }
            else if(Object.keys(error).length > 0){
                let key = Object.keys(error)[0];
                mensaje = `${key}: ${error[key][0]}`
            }
            NotificationManager.error(mensaje, 'ERROR');
        }).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const editar = (id, data) => (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${endpoint}/${id}`, data).then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            if (!!resourceList)
                dispatch(push(resourceList));
        }).catch((error) => {
            let mensaje = "Error en la creación";
            if(error && error.detail){
                mensaje = error.detail
            }
            else if(Object.keys(error).length > 0){
                let key = Object.keys(error)[0];
                mensaje = `${key}: ${error[key][0]}`
            }
            NotificationManager.error(mensaje, 'ERROR');

        }).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const eliminar = id => (dispatch) => {
        dispatch(setLoader(true));
        api.eliminar(`${endpoint}/${id}`).then(() => {
            NotificationManager.success('Registro eliminado', 'Éxito', 3000);
        }).catch(() => {
            NotificationManager.success('Error en la transacción', 'Éxito', 3000);
        }).finally(() => {
            dispatch(listar());
            dispatch(setLoader(false));
        });
    };

    const searchChange = search => (dispatch) => {
        dispatch(setSearch(search));
        dispatch(listar());
    };

    const onSortChange = ordering => (dispatch, getStore) => {
        const sort = getStore()[storeId].ordering;
        if (ordering === sort) {
            dispatch(setOrdering(`-${ordering}`));
        } else {
            dispatch(setOrdering(ordering));
        }
        dispatch(listar());
    };

    const searchChangeRefuse = search => (dispatch) => {
        dispatch(setSearch(search));
        dispatch(listarRefuse(1, 1));
    };

    const onSortChangeRefuse = ordering => (dispatch, getStore) => {
        const sort = getStore().clientes.ordering;
        if (ordering === sort) {
            dispatch(setOrdering(`-${ordering}`));
        } else {
            dispatch(setOrdering(ordering));
        }
        dispatch(listarRefuse(sort.page,1));
    };

    const selectRoles = () => (dispatch) =>{
        dispatch(setLoader(true));
        api.get('role/selectroles').then((res)=>{
            dispatch(setDataSelect(constants.SET_ROLES, res))
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }

    const selectEmpresas = () => (dispatch) =>{
        dispatch(setLoader(true));
        api.get('empresa/selectempresas').then((res)=>{
            dispatch(setDataSelect(constants.SET_EMPRESAS, res))
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }

    const selectRegiones = () => (dispatch) =>{
        dispatch(setLoader(true));
        api.get('region/selectregiones').then((res)=>{
            dispatch(setDataSelect(constants.SET_REGIONES, res))
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }

    const selectEmpresas2 = () => (dispatch) =>{
        dispatch(setLoader(true));
        api.get('empresa/selectempresasE').then((res)=>{
            dispatch(setDataSelect(constants.SET_EMPRESAS, res))
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }

    const selectEstablecimientos = () => (dispatch) =>{
        dispatch(setLoader(true));
        api.get('establecimiento/selectEstablecimientosE').then((res)=>{
            dispatch(setDataSelect(constants.SET_ESTABLECIMIENTOS, res))
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }

    const selectCuentas = (id, moneda) => (dispatch) =>{
        dispatch(setLoader(true));
        let data = { moneda}
        api.post(`cuentas/selectcuentas`, data).then((res)=>{
            dispatch({type: constants.SET_CUENTAS, data: res})
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }

    const getTipoGastos = () => (dispatch) =>{
        dispatch(setLoader(true));
        api.get('tipo_gasto').then((res)=>{
            dispatch({
                type: constants.TIPO_GASTOS,
                tipo_gastos: res
            })
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }

    const resetFormulario = () => (dispatch) => {
        dispatch(initializeForm(formName, {
        }));
    }

    const changeEmpresa = empresa => dispatch => {
        dispatch({type:constants.SET_SEARCH_EMPRESA, empresa});
        dispatch(listar());
    }

    const changeEstablecimiento = establecimiento => dispatch => {
        dispatch({type:constants.SET_SEARCH_ESTABLECIMIENTO, establecimiento});
        dispatch(listar());
    }

    const changeEstado = estado => dispatch => {
        dispatch({type: constants.SET_ESTADO, estado});
        dispatch(listar());
    }

    const clear = () =>(dispatch) =>{
        dispatch({type: constants.ITEM, item: {}});
        dispatch({type:constants.SET_SEARCH_EMPRESA, empresa:null});

    };

    const listarespecial = (url, page = 1, data) => (dispatch, getStore) => {
        const resource = getStore()[storeId];
        const params = { page, ...data};
        params.ordering = resource.ordering;
        params.search = resource.search;
        if (resource.empresa != null)
            params.empresa = resource.empresa.value;
        dispatch(setLoader(true));
        api.get(url, params).then((response) => {
            dispatch(setData(response));
            dispatch(setPage(page));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const actions = {
        listar,
        leer,
        crear,
        editar,
        eliminar,
        searchChange,
        onSortChange,
        selectRoles,
        selectEmpresas,
        selectRegiones,
        selectEmpresas2,
        selectCuentas,
        listarRefuse,
        searchChangeRefuse,
        onSortChangeRefuse,
        getTipoGastos,
        resetFormulario,
        changeEmpresa,
        clear,
        listarespecial,
        changeEstado,
        selectEstablecimientos,
        changeEstablecimiento
    };

    // -----------------------------------
    // Reducers
    // -----------------------------------

    const reducers = {
        [constants.LOADER]: (state, { loader }) => {
            return {
                ...state,
                loader,
            };
        },
        [constants.DATA]: (state, { data }) => {
            return {
                ...state,
                data,
            };
        },
        [constants.ITEM]: (state, { item }) => {
            return {
                ...state,
                item,
            };
        },
        [constants.PAGE]: (state, { page }) => {
            return {
                ...state,
                page,
            };
        },
        [constants.ORDERING]: (state, { ordering }) => {
            return {
                ...state,
                ordering,
            };
        },
        [constants.SEARCH]: (state, { search }) => {
            return {
                ...state,
                search,
            };
        },
        [constants.SET_ROLES]:(state,{ data })=>{
            return{
                ...state,
                roles: data.role,
            }
        },
        [constants.SET_EMPRESAS]:(state,{ data })=>{
            return{
                ...state,
                empresas: data.empresa,
            }
        },
        [constants.SET_ESTABLECIMIENTOS]:(state,{ data })=>{
            return{
                ...state,
                establecimientos: data.establecimiento,
            }
        },
        [constants.SET_ESTADO]:(state,{ estado })=>{
            return{
                ...state,
                estado
            }
        },
        [constants.SET_CUENTAS]:(state,{ data })=>{
            return{
                ...state,
                cuentas_banco: data.cuenta,
            }
        },
        [constants.SET_REGIONES]:(state,{ data })=>{
            return{
                ...state,
                regiones: data,
            }
        },
        [constants.DATAREFUSE]:(state,{data}) => {
            return{
                ...state,
                datarefuse:data
            }
        },
        [constants.TIPO_GASTOS]: (state, {tipo_gastos}) => {
            return {
                ...state,
                tipo_gastos
            }
        },
        [constants.SET_SEARCH_EMPRESA]: (state, {empresa}) => {
            return {
                ...state,
                empresa
            }
        },
        [constants.SET_SEARCH_ESTABLECIMIENTO]: (state, {establecimiento}) => {
            return {
                ...state,
                establecimiento
            }
        },
    };

    const initialState = {
        loader: false,
        data: {
            results: [],
            count: 0,
        },
        item: {},
        page: 1,
        ordering: '',
        search: '',
        roles: [],
        empresas:[],
        establecimientos: [],
        regiones:[],
        cuentas_banco: [],
        datarefuse: {
            results: [],
            count: 0,
        },
        tipo_gastos: [],
        empresa: null,
        establecimiento: null,
        estado: {value: true, label: "Activos"}
    };

    return { reducers, initialState, actions };

};
