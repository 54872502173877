import React from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { connect } from 'react-redux';

import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderTextArea, AsyncSelectField, renderSelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import {
    renderDatePicker,
    renderAntTimeField,
} from "../../../Utils/renderField/renderField";
import { api } from '../../../../../utility/api';
import { Component } from 'react';
import GridDetalle from './gridRechazadas'

let tipoproducto = [];

// const getOrdenes = (search) => {
//     return api.get("ordenventa", { search, entregado: false, a_produccion: true }).
//         then((data) => {
//             if (data) return data.results;
//             return [];
//         }).catch(() => {
//             return [];
//         });
// };

class renderBonficacionPuesto extends Component {

    render() {
        const { fields, meta: { error, submitFailed }, getProductos } = this.props
        return (
            <div className=" col-sm-12 p-0">
                <div className=" col-sm-12 form-group np-r p-0">
                    <div className=" table-header-wrapper">
                        <table className="table table-striped table-highlight mb-0">
                            <thead>
                                <tr>
                                    <th style={{ width: "5%" }}></th>
                                    <th style={{ width: "25%" }}>PRODUCTO</th>
                                    <th style={{ width: "14%" }}>INVENTARIO</th>
                                    <th style={{ width: "14%" }}>DISPONIBLE</th>
                                    <th style={{ width: "14%" }}>OTRAS OP</th>
                                    <th style={{ width: "14%" }}>REQUERIDO OV</th>
                                    <th style={{ width: "14%" }}>EN ESTA OP</th>
                                </tr>
                            </thead>
                            <tbody >
                                {fields.map((PET, index) => {
                                    let transaccion = fields.get(index);
                                    return (
                                        <tr key={index} className="align-items-start">
                                            <td className="text-center sin-borde-top" style={{ width: "5%" }}>
                                                <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                                    title="Borrar"
                                                    onClick={() => {
                                                        fields.remove(index)
                                                    }}
                                                />
                                            </td>
                                            <td className="sin-borde-top " style={{ width: "25%" }}>
                                                <Field
                                                    withRef
                                                    // key={`${producto}-${simbolo}-${proveedor}`}
                                                    id={`${PET}.selectProducto`}
                                                    name={`${PET}.producto`}
                                                    label="nombre"
                                                    component={AsyncSelectField}
                                                    loadOptions={(search) => getProductos(search)}
                                                    top={{ top: "67px", position: "inherit" }}
                                                    type="text"
                                                    valueKey="id"
                                                    labelKey="nombre"
                                                    onChange={(e) => { }}
                                                    className="form-control"
                                                />
                                            </td>
                                            <td className="sin-borde-top " style={{ width: "14%" }}>
                                                <Field
                                                    name={`${PET}.inventario`}
                                                    type="number"
                                                    component={renderField}
                                                    top={{ top: "67px", position: "inherit" }}
                                                    readOnly
                                                />
                                            </td>
                                            <td className="sin-borde-top " style={{ width: "14%" }}>
                                                <Field
                                                    name={`${PET}.disponible`}
                                                    type="number"
                                                    component={renderField}
                                                    top={{ top: "67px", position: "inherit" }}
                                                    readOnly
                                                />
                                            </td>
                                            <td className="sin-borde-top " style={{ width: "14%" }}>
                                                <Field
                                                    name={`${PET}.otras_OP`}
                                                    type="number"
                                                    component={renderField}
                                                    top={{ top: "67px", position: "inherit" }}
                                                    readOnly
                                                />
                                            </td>
                                            <td className="sin-borde-top " style={{ width: "14%" }}>
                                                <Field
                                                    name={`${PET}.requerido`}
                                                    type="number"
                                                    component={renderField}
                                                    top={{ top: "67px", position: "inherit" }}
                                                    readOnly
                                                />
                                            </td>
                                            <td className="sin-borde-top " style={{ width: "14%" }}>
                                                <Field
                                                    name={`${PET}.cantidad`}
                                                    type="number"
                                                    component={renderField}
                                                    top={{ top: "67px", position: "inherit" }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-start mt-2">
                        <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                            AGREGAR
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const renderDetalleOV = ({ fields, meta: { error, submitFailed } }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="table-header-wrapper">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }} />
                            <th style={{ width: "50%" }}>PRODUCTO</th>
                            <th style={{ width: "40%" }}>CANTIDAD</th>
                            <th style={{ width: "5%" }} />
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((detalle, index) => {
                            return (
                                <tr key={index} className="align-items-start">
                                    <td />
                                    <td className="sin-borde-top ">
                                        <Field
                                            name={`${detalle}.producto.nombre`}
                                            type="text"
                                            component={renderField}
                                            top={{ top: "67px", position: "inherit" }}
                                            readOnly
                                            clase="field-clear"
                                        />
                                    </td>
                                    <td className="sin-borde-top ">
                                        <Field
                                            name={`${detalle}.cantidad`}
                                            type="number"
                                            component={renderField}
                                            top={{ top: "67px", position: "inherit" }}
                                            readOnly
                                            clase="field-clear"
                                        />
                                    </td>
                                    <td />
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        {/* {
            (submitFailed || error) && (
                <div className="invalid-feedback-array text-danger">
                    {error}
                </div>
            )
        } */}
    </div>
)

class ProduccionForm extends React.Component {
    state = {
        orden: null
    }
    getProductos = (search) => {
        let params = {}
        if (search) {
            params.search = search;
        }
        params.tiene_receta = true;
        params.empresa__entidad = this.props.empresa;
        return api.get("productos", params).
            then((data) => {
                if (data) {

                    return data.results
                }
                return [];
            }).catch(() => {
                return [];
            });
    };
    getDevoluciones = (search) => {
        let params = {search, tipo_movimiento: 70 }
        if(this.props.empresa){
            params.empresa__entidad = this.props.empresa
        }
        return api.get("despachos", params ).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    };
    
    render() {
        const { handleSubmit, empresas, estadoRead, empresa, tipo_pro } = this.props;
        return (
            <form name="ProduccionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                        <label className="t-azul" htmlFor="nombre">Empresa*</label>
                        <Field
                            name="empresa"
                            label="empresa"
                            labelKey="nombre" valueKey="id"
                            component={renderSelectField}
                            options={empresas}
                            onChange={(e) => { }}
                            className="form-control"
                            readOnly={estadoRead}
                            disabled={estadoRead}
                        />
                    </div>
                        <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                            <label className="t-azul" htmlFor="nombre">Devoluciones</label>
                            <Field
                                name={`devolucion`}
                                component={AsyncSelectField}
                                loadOptions={this.getDevoluciones}
                                isClearable={false}
                                type="text"
                                filterOptions={false}
                                valueKey="id"
                                labelKey="no_despacho"
                                className="form-control"
                                changePersonalizado={e => {
                                    // console.log("e ", e)
                                    this.setState({ orden: e })
                                    this.props.getRecetaDev(e.detalle_despacho? e.detalle_despacho:[], empresa)
                                }}
                                top={{ top: "67px", position: "inherit" }}
                             // disabled={estadoRead} 
                            />
                        </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-6 col-12">
                        <label className="t-azul" htmlFor="producto">Fecha de solicitud</label>
                        <Field
                            name="fecha"
                            selectedDate={new Date()}
                            maxDate="2030-12-31"
                            minDate="2018-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 col-12">
                        <label className="t-azul" htmlFor="producto">Fecha de entrega OP</label>
                        <Field
                            name="fecha_prox_entrega"
                            selectedDate={new Date()}
                            maxDate="2030-12-31"
                            minDate="2018-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                </div>
                <br />

                <div className="col-12 m-0 p-0">
                    <span className="m-none t-primary bold" >Detalle Devolucion</span>
                </div>
                <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 0px 10px 0px" }} />
                <GridDetalle
                    data={this.state.orden ? this.state.orden.detalle_despacho:[] }
                />
                <br />
                <div className="col-12 m-0 p-0">
                    <span className="m-none t-primary bold" >Detalle produccion</span>
                </div>
                <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 0px 10px 0px" }} />
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-12">
                        <FieldArray
                            name="produccion"
                            label="produccion"
                            component={renderBonficacionPuesto}
                            className="form-control"
                            getProductos={this.getProductos}
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-around justify-content-center">
                    <Link className="btn btn-secondary m-1 align-self-center" to={`/produccion/${empresa}`} >CANCELAR</Link>
                    <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                </div>
            </form>
        );
    }
};


export default reduxForm({
    form: 'ProduccionForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            empresa: validators.exists()('Este campo es requerido'),
            sueldo: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(ProduccionForm);
