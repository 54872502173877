import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from "Utils/Grid/StandardActions";

import Modal from 'react-responsive-modal';
import { iconos } from '../../../../../utility/icons';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ReajusteForm from './reajusteForm';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';

export default class InventarioGrid extends Component {
    state = {
        producto: null
    }
    componentDidMount(){
        this.props.listar(1, this.props.id_bodega)
    }

    pageChange = (page) => {
        this.props.listar(page, this.props.id_bodega)
    }

    botonRealizarReajuste = (id, row) => {
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={
                (e)=>{
                e.preventDefault();
                this.setState({producto: row});
                this.props.setOpenModal(true);
            }
            }>
                <img
                    className="action-img"
                    title="Reajustar o baja"
                    src={iconos.baja_reajuste}
                    alt="Reajustar o baja" />
            </a>
        )
    }
    handleSubmitReajuste = (values) => {
        let valores = _.cloneDeep(values);
        valores.cantidad = Number(values.cantidad);
        valores.bodega = Number(values.bodega);
        valores.tipo_movimiento = Number(values.tipo_movimiento);
        if (valores.vehiculo)
            valores.vehiculo = valores.vehiculo.id
        this.props.reajustar_inventario(valores, this.props.id_bodega);
    }
    render() {
        const { data, loader, listar: onPageChange, onSortChange, open_modal, page } = this.props;
        return (
            <Fragment>
                <Modal open={open_modal} onClose={(e) => {
                    this.props.setOpenModal(false);
                }} center>
                    <div className="">
                        <h2 className="text-center text-primary text-uppercase">SELECCIONAR TIPO ACCIÓN</h2>
                    </div>
                    <ReajusteForm
                        producto={this.state.producto}
                        initialValues={{
                            producto: this.state.producto ? this.state.producto.id : 0,
                            bodega: this.props.id_bodega,
                            tipo_movimiento: "50"
                        }}
                        onSubmit={this.handleSubmitReajuste}
                        cerrarModal={this.props.setOpenModal}
                    />
                </Modal>
                <Grid
                    data={data}
                    onPageChange={this.pageChange}
                    page={page}
                    hover
                    striped
                    loading={loader}
                    onSortChange={onSortChange}
                >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            dataFormat={
                                standardActions({ adicional: this.botonRealizarReajuste,})
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="codigo"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="label_unidad_inventario"
                            dataSort
                        >
                            UNIDAD DE MEDIDA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cantidad"
                            dataSort
                        >
                            CANTIDAD
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn
                            dataField="costo"
                            dataSort
                            dataFormat={
                                (cell,row)=>(
                                    <RenderMoneda monto={cell} simbolo={'Q. '} />
                                )
                            }
                        >
                            COSTO
                        </TableHeaderColumn> */}
                    </Grid>
            </Fragment>
        )
    }
}
