import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/bonificaciones/bonificacion';
import BonificacionNueva from './bonificacion';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

const mstp = state => {
    return {
        ...state.bonificacion,
        permisos: state.login.me.permisos,
    }
};

const mdtp = {
    ...actions,
    setFecha_anual :(value) => dispatch => {
        if (value == 'true'){
            dispatch(change('Bonificacionform', 'fecha_pago_anual', null))
        }
        else{
            dispatch(change('Bonificacionform', 'fecha_pago_anual', new Date()))
        }
    },
};

export default connect(mstp, mdtp)(BonificacionNueva)
