import React, { Component, Fragment } from 'react'
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../../Utils/Grid/StandardActions';
import CardSimple from '../../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda, RenderNombreSelect} from '../../../../Utils/renderField/renderTableField';
import FiltrosOrdenVenta from './filtrosOrdeVenta';
import { Field } from 'redux-form';
import renderFieldCheck from '../../../../Utils/renderField'
import ImageVer from '../../../../../../../assets/img/icons/flecha_amarilla.png';
import AumentoPrecioForm from './AumentoPrecioForm';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';

export default class AumentoProductoGrid extends Component {
    componentWillMount() {
        const { listar, page, selectEmpresas2, selectTipos } = this.props;
        selectEmpresas2()
        selectTipos()
        listar(page);
    }
    botonAumentoIndividual = (row) => {
        return (
            <a className="px-1" style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.props.setOpenModal(true)
                    this.props.setProductoSeleccionado(row)
                }}
            >
                <img className="action-img-ver" title="Aumento manual" src={ImageVer} alt="Aumento manual" />
            </a>
        )
    }
    renderModalAumentoIndividual = () => {
        return(
            <Fragment>
                <div className="mt-0">
                    <h3 className="text-center text-primary">AUMENTAR PRECIO</h3>
                </div>
                <AumentoPrecioForm
                    cerrarModal={this.props.setOpenModal}
                    texto_boton_movimiento={"GUARDAR"}
                    titulo_modal="TIPO DE AUMENTO"
                    handleSubmit={(e) => {
                        e.preventDefault();
                        this.props.guardarAumento()
                    }}
                />
            </Fragment>
        );
    }
    botonCheck = (row) =>{
        this.props.setProductosSeleccionados(row)
    }
    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos, changeEmpresa, changeTipo, open_modal, setOpenModal} = this.props;
            if(permisos.aumento_precios === false) {
                return <Redirect to={`/`} />
            }
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    ruta="producto/crear"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={permisos.productos}
                    estatefilterBar={true}
                    filterbar={
                        <FiltrosOrdenVenta
                            changeEmpresa={changeEmpresa}
                            empresas={this.props.empresas}
                            changeTipo={changeTipo}
                            tipos={this.props.tipos}
                        />

                    }
                />}
                titulo="Reporte aumento de precios de Productos">
                    <Modal
                        open={open_modal}
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                        center
                    >
                        {
                            this.renderModalAumentoIndividual()
                        }
                    </Modal>
                    <AumentoPrecioForm
                    texto_boton_movimiento={"APLICAR AUMENTO"}
                    titulo_modal="TIPO DE AUMENTO GENERAL"
                    handleSubmit={(e) => {
                        e.preventDefault();
                        this.props.guardarAumento(false)
                    }}
                    es_general={true}
                />
                    <div className="flex-1 px-5 mt-1 align-items-center d-flex centrar-check">
                    <input 
                        type="checkbox" 
                        className='mt-1'
                        component={renderFieldCheck} 
                        checked={this.props.seleccionar_todos}
                        onChange={() => this.props.setSeleccionarTodos()}
                        />
                        <h6 className='px-2 mt-3'>Seleccionar todos</h6>
                    </div>
                    <Grid hover striped data={data} size_page={25} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell, row) => {
                                return customStandardActions(cell, {
                                    adicional: (c, r) => {
                                         
                                        return <label className={`mb-0 d-flex py-1 px-0 px-sm-1 flex-row algin-items-center`}>
                                        <div className="flex-1 px-0 mt-1 d-flex justify-content-center centrar-check">
                                        <input 
                                        type="checkbox" 
                                        component={renderFieldCheck} 
                                        checked={row.check}
                                        onChange={() => {this.props.setProductosSeleccionados(row), this.forceUpdate()}}
                                        /> 
                                        </div>
                                        </label>
                                    },
                                    adicional2: (c,r) =>{
                                        return <div className="px-2"></div>
                                    },
                                    adicional3: (c, r) => {
                                        return this.botonAumentoIndividual(row)
                                    }
                                });
                            }}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="label_unidad_inventario"
                            dataSort
                        >
                            UNIDAD DE MEDIDA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="codigo"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                        >
                            DESCRIPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_producto"
                            dataSort
                            dataFormat = {
                                (cell, row) => (
                                    <RenderNombreSelect cell={cell} />
                                )
                            }
                        >
                            TIPO PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_venta"
                            dataSort
                            dataFormat = {
                                (cell, row)=>(
                                    <RenderMoneda monto={ cell } simbolo={'Q'} />
                                )
                            }
                        >
                            PRECIO VENTA
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
