import React, { Component, Fragment } from 'react'

import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import Toolbar from '../../../Utils/Toolbar/ToolbarReporteIngresoInterno';
import Grid from '../../../Utils/Grid/index';
import d3 from 'd3';


export default class DetalleReporteGeneral extends Component {
    componentDidMount(){
        this.props.getGastosEstadistica();
    }
    
    componentDidUpdate(prevProps){
    }
    componentWillUnmount(){
        setTimeout(() => {
            d3.selectAll('.nvtooltip').style('opacity', 0);
        }, 100);
        this.props.resetFechas();
    }
    render() {
        const { grafica_gastos } = this.props;

        return (
            <div className="col-md-12 m-0 p-0">
                <div className="py-3 px-md-3 " style={{border:"1px solid #E8E8E8", }}>
                    <Toolbar
                        change_empresa={this.props.set_empresa_detalle}
                        change_fechaI={this.props.set_fecha_inicio}
                        change_fechaF={this.props.set_fecha_final}
                        filtro_empresa={this.props.empresa_detalle}
                        filtro_mes={true}
                        filtro_fechas={true}
                        change_anio={this.props.set_anio}
                        {...this.props} />
                </div>
                <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0">
                        <div className="px-1 py-1">
                            <h5>TOTAL</h5>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Por pagar</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_ingresos_internos} simbolo={'Q. '} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Por cobrar</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_gastos_internos} simbolo={'Q. '} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                <h5>Detalle por empresa</h5>
                        <Grid hover striped data={{count: 0, results: grafica_gastos.detalle_empresas}}  >
                            <TableHeaderColumn
                                isKey
                                dataField="nombre"
                                dataSort
                                className='reporte-table'
                            >
                                EMPRESA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_ingresos"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                         <RenderMoneda monto={cell} simbolo={"Q. "} />
                                    )
                                }
                                className='reporte-table'
                            >
                                POR PAGAR
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_gastos"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <RenderMoneda monto={cell} simbolo={"Q. "} />
                                    )
                                }
                                className='reporte-table'
                            >
                                POR COBRAR
                            </TableHeaderColumn>
                        </Grid>
                    </div>
            </div>
        )
    }

}
