import React, { Component } from 'react'
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import Toolbar from '../../../Utils/Toolbar/ToolbarReporteIngreso';


import NVD3ChartMask from "../../../Utils/Graficas/NDV3ChartMask";
import {
    opcionesG1
} from '../../../Utils/Graficas/opciones';
export default class GeneralReporte extends Component {
    componentDidMount(){
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 2000)
        this.props.reporteIngresosEgresos()
    }
    render() {
        let options2 = _.cloneDeep(opcionesG1.optionsMedia)
        const {
            data,
            anio_general
        } = this.props;
        return (
            <div className="col-md-12 m-0 p-0">
                <div className="py-md-3 px-md-3 p-0" style={{border:"1px solid #E8E8E8", }}>
                    <Toolbar
                        change_empresa={this.props.set_empresa_general}
                        filtro_empresa={this.props.empresa_general}
                        change_anio={this.props.set_anio_general}
                        filtro_mes={false}
                        {...this.props} />
                </div>
                <div className="d-flex flex-wrap flex-column flex-md-row justify-content-around mt-4">
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className="p-0 m-0 mt-2 mr-md-1 flex-1 flex-column">
                        <div className="my-3 d-flex justify-content-center">
                            <div>
                                <h5>Ingresos</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                            <RenderMoneda monto={data.total_ingreso} simbolo={'Q'} />
                                }</h3>
                            </div>
                            
                        </div>
                        <div className="my-3 d-flex justify-content-center">
                            <div className="col-md-5">
                                <h6>Efectivo</h6>
                                <h4 className="t-mostaza text-left"  htmlFor="descripcion">{
                                            <RenderMoneda monto={data.total_ingreso_efectivo} simbolo={'Q'} />
                                }</h4>
                            </div>
                            <div className="col-md-5">
                                <h6>Bancos</h6>
                                <h4 className="t-mostaza text-left"  htmlFor="descripcion">{
                                            <RenderMoneda monto={data.total_ingreso_bancos} simbolo={'Q'} />
                                }</h4>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className=" p-0 m-0 mt-2 ml-md-2 flex-1 flex-column">
                            <div className="my-3 d-flex justify-content-center">
                            <div>
                                <h5>Gastos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                            <RenderMoneda monto={data.total_egreso} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="my-3 d-flex justify-content-center">
                            <div className="col-md-5">
                                <h6>Efectivo</h6>
                                <h4 className="t-primary"  htmlFor="descripcion">{
                                            <RenderMoneda monto={data.total_egreso_efectivo} simbolo={'Q'} />
                                }</h4>
                            </div>
                            <div className="col-md-5">
                                <h6>Bancos</h6>
                                <h4 className="t-primary"  htmlFor="descripcion">{
                                            <RenderMoneda monto={data.total_egreso_bancos} simbolo={'Q'} />
                                }</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0">
                        <div className="px-1 py-1">
                            <h5>INTERNOS</h5>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Ingresos</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={data.total_ingreso_interno} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Gastos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={data.total_egreso_interno} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap flex-column flex-md-row justify-content-around mt-4">
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className="p-0 m-0 mt-2 mr-md-1 flex-1 flex-column"></div>
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className="p-0 m-0 mt-2 mr-md-1 flex-1 flex-column">
                    <div className="col-12 row m-0 p-0">
                        <div className="px-1 py-1">
                            <h5>RETIROS</h5>
                        </div>
                        <div className="col-md-4 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Efectivo</h5>
                                <h3 className="t-primary text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={data.total_retiros_efectivo} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Bancos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={data.total_retiros_bancos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0">
                        <div className="px-3 py-1">
                            <h5>TOTAL</h5>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Ingresos</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={data.total_ingreso + data.total_ingreso_interno} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Gastos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={data.total_egreso + data.total_egreso_interno + data.total_retiros_efectivo + data.total_retiros_bancos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0 justify-content-center">
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8 justify-content-center">
                                <h5>Ingresos vs gastos</h5>
                                <h3 className="t-azul text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={(data.total_ingreso + data.total_ingreso_interno) - (data.total_egreso + data.total_egreso_interno + data.total_retiros_efectivo + data.total_retiros_bancos)} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                </div>                       
                <div className="col-12 m-0 p-0 mt-3">
                    <div className="">
                        <h3 className="t-primary">GENERAL</h3>
                    </div>
                    <div className="col-md-12">
                        <div>
                            <h4 className="t-primary">Reporte {anio_general.value}</h4>
                        </div>
                        <div className="horizontal-scroll">
                            <div  style={{width: '100%', minWidth: 700}}>
                            <NVD3ChartMask
                            id="desglose_gastos"
                            {...options2}
                            datum={[
                                {
                                    key: "Gastos",
                                    color: "#F3A631",
                                    values: data.gastos_actual
                                },
                                {
                                key: "Ingresos",
                                color: "#00334E",
                                values: data.ingreso_actual
                                },

                                ]} height={500}  />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div>
                            <h4 className="t-primary">Reporte {anio_general.value-1}</h4>
                        </div>
                        <div className="horizontal-scroll">
                            <div  style={{width: '100%', minWidth: 700}}>
                            <NVD3ChartMask
                            id="desglose_gastos"
                            {...options2}
                            datum={[
                                {
                                    key: "Gastos",
                                    color: "#F3A631",
                                    values: data.gastos_pasado
                                },
                                {
                                key: "Ingresos",
                                color: "#00334E",
                                values: data.ingreso_pasado
                                },

                                ]} height={500}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
