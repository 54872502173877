import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderDropDownField, renderSelectField, Cre } from '../../../Utils/renderField';
import {
    CreatableSelectField,
} from "Utils/renderField/renderField"
import { Link, Redirect } from 'react-router-dom';
import { TIPO_ENTREGA, TIPO_ENTREGA_CLIENTE } from '../../../../../utility/constants';
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";


let EntregaOCForm = (props) => {
    const { handleSubmit, previousStep, tipo_entrega, sucursales} = props;
    return (
        <form name="PeticionVentaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            {
                     (props.proceso_estado === 80 && props.estado_justificacion) && (
                    (
                        props.justificacion &&(
                            <div>
                                <div className="row justify-content-center text-center " >
                                    <div className="col-12 col-md-10"  style={{backgroundColor:"#e8e8e8"}}>
                                        <br/>
                                        <h6 className="t-mostaza text-uppercase" >Justificacion de rechazo: </h6>
                                        <p style={{fontWeight:600}} className="t-primary">{props.justificacion[props.justificacion.length -1].justificacion}</p>
                                    </div>
                                </div>
                                <br/>
                            </div>
                        )
                    ))
                }
            <div className="row justify-content-center">
                <h4 className="t-primary" >Datos de Entrega</h4>
            </div>
            <div className="col-12 m-0 p-0 d-flex justify-content-center">
                <div className="col-md-10 row m-0 p-0">
                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="fecha_prox_entrega">Fecha entrega</label>
                            <Field
                                name="fecha_prox_entrega"
                                selectedDate={new Date()}
                                maxDate="2030-12-31"
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                        </div>
                         <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="tipo_entrega">Tipo de entrega</label>
                            <Field
                                name="tipo_entrega"
                                label="tipo_documento"
                                labelKey="label" valueKey="value"
                                component={renderSelectField}
                                options={TIPO_ENTREGA_CLIENTE}
                                className="form-control"
                            />
                        </div>
                    </div>

                            <Fragment>
                                <div className="form-group has-feedback col-12 row">
                                    <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                        <label className="t-azul" htmlFor="direccion">Dirección Cliente*</label>
                                        <Field

                                            name="direccion"
                                            options={sucursales}
                                            component={CreatableSelectField}
                                            placeholder="Seleccione o Esciba"
                                        />
                                    </div>
                                    <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                        <label className="t-azul" htmlFor="encargado">Encargado*</label>
                                     <Field name="encargado" label="encargado" type="text" component={renderField} type="text" className="form-control" />
                                    </div>
                                </div>
                            </Fragment>

                    {
                        (tipo_entrega == 3) && (
                            <div className="form-group has-feedback col-12 row">
                                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                    <label className="t-azul" htmlFor="nombre_transporte">Nombre transporte*</label>
                                    <Field name="nombre_transporte" label="nombre_transporte" type="text" component={renderField} type="text" className="form-control" />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <button onClick={previousStep} className="btn btn-secondary  align-self-center">ANTERIOR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


EntregaOCForm =  reduxForm({
    form: 'PeticionVentaForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        return validate(data, {
            tipo_entrega: validators.exists()('Este campo es requerido'),
            direccion: validators.exists()('Este campo es requerido'),
            encargado: validators.exists()('Este campo es requerido'),
            nombre_transporte: validators.exists()('Este campo es requerido'),
        });
    },
})(EntregaOCForm);

const selector = formValueSelector('PeticionVentaForm');
EntregaOCForm = connect(state => {
    const tipo_entrega = selector(state,'tipo_entrega');
    const proveedor = selector(state,'proveedor');

    let sucursales = [];
    if(proveedor){
        sucursales = [];
        _.forEach(proveedor.sucursal, x => {
            sucursales.push({label: x.direccion, value: x.direccion})
        })
    }
    return {
        tipo_entrega,
        sucursales
    }
})(EntregaOCForm);
export default EntregaOCForm;
