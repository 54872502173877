import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import LoadMask from "../LoadMask/LoadMask";
import ImageVer from '../../../../../assets/img/icons/Ver.png';
import { RenderDateTime } from "../../Utils/renderField/renderTableField";
import acceptButton from '../../../../../assets/img/icons/aceptar@2x.png';
import regetButton from '../../../../../assets/img/icons/remove@2x.png';


export default class CardPMEnviadas extends Component {
    render() {
        const { data, loader } = this.props;
        return (
            <div className="">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br/>
                    <h5 className="t-primary">{ data && (data.length > 0 ? this.props.titulo: "")}</h5>
                    <div className="row d-flex justify-content-center">
                            <div className="row col-12 d-flex justify-content-around">
                                {
                                    data &&
                                    (data.map((item,index)=>{
                                        return(
                                            <div
                                            key={index}
                                            className="card col-lg-3 col-md-4 col-sm-6 col-12 pmcard"
                                            >
                                                <div className="text-center">
                                                    <h5 className="t-primary text-align-center">{item.es_ingreso? "INGRESO":"EGRESO"}</h5>
                                                </div>
                                                <div className="col-12">
                                                    <span style={{color:"#f3a631"}} >Fecha: </span>
                                                    <span><RenderDateTime fecha={item.transaccion && item.transaccion.fecha} /></span><br/>
                                                    <span style={{color:"#f3a631"}}>Enviada por: </span><br/>
                                                    <span>{item.transaccion &&  (`${item.transaccion.propietario}`)}</span><br/>
                                                    <span style={{color:"#f3a631"}}>Monto: </span>
                                                    <span>{item.transaccion && `Q. ${item.transaccion.monto}`}</span><br/>
                                                    {/* <span style={{color:"#f3a631"}}>Descripcion: </span><br/>
                                                    <span>{item.transaccion && `${item.transaccion.descripcion}`}</span> */}
                                                    { item.es_ingreso  ? (
                                                        <div className="pmiconCAJA">
                                                            <div >
                                                                <a href="#" style={{cursor:"pointer"}} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    this.props.aceptarEnvio(item.id)
                                                                }}>
                                                                    <img className="action-img-ver" title="aceptar" src={acceptButton} alt="aceptar" />
                                                                </a>
                                                            </div>
                                                            <br/>
                                                            <div >
                                                                <a href="#" style={{cursor:"pointer"}} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    this.props.rechazarEnvio(item.id)
                                                                }}>
                                                                    <img className="action-img-ver" title="rechazar" src={regetButton} alt="rechazar" />
                                                                </a>
                                                            </div>
                                                                
                                                        </div>
                                                    ):(
                                                        <div className="pmiconCAJA">
                                                            <a href="#" style={{cursor:"pointer"}} onClick={(e) => {
                                                                e.preventDefault()
                                                                this.props.cancelEnvio(item.id)
                                                            }}>
                                                                <img className="action-img-ver" title="eliminar" src={regetButton} alt="rechazar" />
                                                            </a>
                                                        </div>
                                                    ) 
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }))
                                }
                            </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}
