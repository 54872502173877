import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/liquidaciones/liquidaciones';
import detalleLiquidacion from './detalleLiquidacion';

//state.login.me.profile.role
const mstp = state => {
    return {
        ...state.liquidaciones,
        permisos: state.login.me.permisos,
        user: state.login.me
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(detalleLiquidacion)