import React, {useState, Component} from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {connect } from 'react-redux';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency, renderSwitch } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {SelectField, AsyncSelectField} from '../../../Utils/renderField/renderField.js'
import { formatSelects } from '../../../Utils/renderField/renderReadField';
import { UnidadMedida, TipoUnidades } from '../../../../../utility/constants';
import { api } from '../../../../../utility/api';


let tipoproducto = [];

const getRegiones = (search) => {
    return api.get("region", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


const renderRegionPrecio = ({fields, meta: {error, submitFailed }, getProductos, regiones, simbolo}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th style={{width: "40%"}}>Region</th>
                        <th style={{width: "40%"}} >Precio</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((PET, index) => {
                        let transaccion = fields.get(index);
                        return (
                            <tr key={index} className="align-items-start">
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar"
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top "  style={{width: "40%"}}>
                                <Field
                                    name={`${PET}.region`}
                                    component={AsyncSelectField}
                                    loadOptions={getRegiones}
                                    type="text"
                                    filterOptions= {false}
                                    valueKey="id"
                                    labelKey="region"
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </td>
                            <td className="sin-borde-top"  style={{width: "40%"}}  >
                                <Field
                                    name={`${PET}.precio`}
                                    label="precio"
                                    prefix={simbolo}
                                    type="number"
                                    component={renderCurrency}
                                    parce={cell => parseFloat(cell)}
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </td>
                        </tr>
                        );
                    }
                    )}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR PRECIO
                </button>
            </div>
        </div>
    </div>
)

const getTipoProducto = (search)  =>{
    return api.get("tipoproducto",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    tipoproducto = [];
                    data.results.forEach(x=>{
                        tipoproducto.push({value: x.id, label: x.nombre})
                    })
                    return tipoproducto
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}


class ProductoForm extends Component {
    componentDidUpdate(prevProps){
        const { item } = this.props;
        if(prevProps.item !== item){
            if(item){
                this.props.setValores(item.tipo_medida);
            }
        }
    }

    render() {
        const { handleSubmit, empresas, alerta, vendible} = this.props;

        return (
            <form name="productoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <h4 className="t-primary text-uppercase text-center">
                    Información de producto
                </h4>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-12">
                        <label className="t-azul" htmlFor="nombre">Nombre</label>
                        <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                    </div>

                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-6  col-12">
                        <label className="t-azul" htmlFor="empresa">Empresa</label>
                        <Field
                            name="empresa"
                            label="empresa"
                            valueKey="value"
                            labelKey="label"
                            options={empresas}
                            component={SelectField}
                        />
                    </div>
                    <div className="form-group has-feedback  col-md-6  col-12">
                        <label className="t-azul" htmlFor="tipo_producto">Tipo producto</label>
                        <Field
                                    name="tipo_producto"
                                    valueKey="value"
                                    labelKey="label"
                                    loadOptions={getTipoProducto}
                                    component={AsyncSelectField}
                                />
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="codigo">Código</label>
                        <Field name="codigo" label="codigo" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="precio_venta_interna">Precio venta interna</label>
                        <Field
                            name="precio_venta_interna"
                            label="precio_venta_interna"
                            component={renderCurrency}
                            type="number"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="precio_por_produccion">Precio produccion</label>
                        <Field
                            name="precio_por_produccion"
                            label="precio_por_produccion"
                            component={renderCurrency}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row ">
                    <div className="form-group has-feedback col-md-4 col-lg-2">
                        <label className="t-azul" htmlFor="alerta">Alerta</label>
                        <Field
                            name="alerta"
                            label=""
                            value="default"
                            component={renderSwitch}

                        />
                    </div>
                    {
                        (alerta) && (
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="minimo_existencias">Mínimo existencias</label>
                                <Field name="minimo_existencias" label="minimo_existencias" component={renderField} type="text" className="form-control" />
                            </div>
                        )
                    }
                </div>
                <div className="row ">
                    <div className="form-group has-feedback col-md-4 col-lg-2">
                        <label className="t-azul" htmlFor="vendible">Vendible</label>
                        <Field
                            name="vendible"
                            label=""
                            value="default"
                            component={renderSwitch}

                        />
                    </div>
                    {
                        (vendible) && (
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="precio_venta">Precio venta (Fabrica)</label>
                                <Field
                                    name="precio_venta"
                                    label="precio_venta"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control" />
                            </div>
                        )
                    }
                </div>
                <div className="row">
                    <div className="form-group has-feedback col-md-4 col-lg-4">
                        <label className="t-azul" htmlFor="unidad_medida">Unidad Recepción</label>
                        <Field
                            name="unidad_recepcion"
                            label="unidad_recepcion"
                            valueKey="value"
                            labelKey="label"
                            options={UnidadMedida}
                            component={SelectField}
                        />
                    </div>
                    <div className="form-group has-feedback col-md-4 col-lg-4">
                        <label className="t-azul" htmlFor="conversion">Conversión</label>
                        <Field
                            name="conversion"
                            label="conversion"
                            component={renderField}
                            type="number"
                            className="form-control" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group has-feedback col-md-4 col-lg-4">
                        <label className="t-azul" htmlFor="tipo_medida">Tipo de medida</label>
                        <Field
                            name="tipo_medida"
                            label="tipo_medida"
                            component={SelectField}
                            onChangeEditado={ e => {
                                this.props.onChangeTipoUnidad(e);
                            }}
                            options={TipoUnidades}
                            type="text"
                            className="form-control" />
                    </div>
                    <div className="form-group has-feedback col-md-4 col-lg-4">
                        <label className="t-azul" htmlFor="unidad_medida">Unidad inventario</label>
                        <Field
                            name="unidad_inventario"
                            label="unidad_inventario"
                            valueKey="value"
                            labelKey="label"
                            options={this.props.arr_medidas}
                            component={SelectField}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-sm-12  col-12">
                        <label className="t-azul" htmlFor="descripcion">Descripción</label>
                        <Field
                            name="descripcion"
                            label="descripcion"
                            component={renderTextArea}
                            top={{top:"95px"}}
                            type="text"
                            className="form-control" />
                    </div>
                </div>
                <div className="row  justify-content-sm-around justify-content-center">
                    <Link className="btn btn-secondary m-1 align-self-center" to="/productos" >CANCELAR</Link>
                    <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
                </div>

            </form>
        );
    }
}



ProductoForm =  reduxForm({
    form: 'productoForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
            codigo: validators.exists()('Este campo es requerido'),
            precio_venta: validators.exists()('Este campo es requerido'),
            minimo_existencias: validators.exists()('Este campo es requerido'),
            tipo_producto: validators.exists()('Este campo es requerido'),
            tipo_medida: validators.exists()('Este campo es requerido'),
            empresa: validators.exists()('Este campo es requerido'),
            unidad_recepcion: validators.exists()('Este campo es requerido'),
            conversion: validators.exists()('Este campo es requerido'),
            unidad_inventario: validators.exists()('Este campo es requerido'),
            precio_por_produccion: validators.exists()('Este campo es requerido'),
            precio_venta_interna: validators.exists()('Este campo es requerido'),
        });
    },
})(ProductoForm);

const selector = formValueSelector('productoForm')
ProductoForm = connect(state => {
    const alerta = selector(state, 'alerta')
    const vendible = selector(state, 'vendible')
    return {
        alerta,
        vendible
      }
    })(ProductoForm)
export default ProductoForm;
