import React, { Component } from 'react';
import moment from 'moment';

var monthByNumber = exports.monthByNumber = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
};

var numberByMonth = exports.numberByMonth = {
    Enero: 0,
    Febrero: 1,
    Marzo: 2,
    Abril: 3,
    Mayo: 4,
    Junio: 5,
    Julio: 6,
    Agosto: 7,
    Septiembre: 8,
    Octrubre: 9,
    Noviembre: 10,
    Diciembre: 11
};

var daysInMonth = exports.daysInMonth = {
    0: 31,
    1: 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31
};

var unit = exports.unit = {
    day: 'day',
    month: 'month',
    year: 'year'
};
export class DropDownDate2 extends Component {
    state = {
        startYear: 0,
        startMonth: 0,
        startDay: 0,
        endYear: 0,
        endMonth: 0,
        endDay: 0,
        selectedYear: 0,
        selectedMonth: 0,
        selectedDay: 0,
    }

    componentWillMount() {
        var startYear = void 0,
            startMonth = void 0,
            startDay = void 0,
            endYear = void 0,
            endMonth = void 0,
            endDay = void 0,
            selectedYear = void 0,
            selectedMonth = void 0,
            selectedDay = void 0;
        // console.log("SELECTED",this.props.selectedDate)
        if (this.props.startDate) {
            var date = new Date(this.props.startDate)
            console.log("1 :", date)
            startYear = date.getFullYear();
            console.log("1.1 :", startYear)
            startMonth = date.getMonth();
            console.log("1.2 :", startMonth)
            startDay = date.getDate();
            console.log("1.3 :", startDay)
        } else {
            console.log("1.4 :")
            startYear = 1990;
            startMonth = 0;
            startDay = 1;
        }
        if (this.props.endDate) {
            var _date = new Date(this.props.endDate);
            console.log("2 :", _date)
            endYear = _date.getFullYear();
            console.log("2.1 :", endYear)
            endMonth = _date.getMonth();
            console.log("2.2 :", endMonth)
            endDay = _date.getDate();
            console.log("2.3 :", endDay)
        } else {
            console.log("2.4 :")
            var _date2 = new Date();
            endYear = _date2.getFullYear();
            endMonth = _date2.getMonth();
            endDay = _date2.getDate();
        }
        if (this.props.selectedDate) {
            var _date3 = new Date(this.props.selectedDate);
            console.log("3 :", _date3)
            selectedYear = _date3.getFullYear();
            console.log("3.1 :", selectedYear)
            selectedMonth = _date3.getMonth();
            console.log("3.2 :", selectedMonth)
            selectedDay = _date3.getDate();
            console.log("3.3 :", selectedDay)
        } else {
            console.log("3.4 :")
            selectedYear = -1;
            selectedMonth = -1;
            selectedDay = -1;
        }
        this.setState(
            { startYear: startYear, startMonth: startMonth, startDay: startDay, endYear: endYear, endMonth: endMonth, endDay: endDay, selectedYear: selectedYear, selectedMonth: selectedMonth, selectedDay: selectedDay },
            console.log("startYear :", startYear),
            console.log("startMonth :", startMonth),
            console.log("startDay :", startDay),
            console.log("endYear :", endYear),
            console.log("endMonth :", endMonth),
            console.log("endDay :", endDay),
            console.log("selectedYear :", selectedYear),
            console.log("selectedMonth :", selectedMonth),
            console.log("selectedDay :", selectedDay),



        );
    }

    componentWillReceiveProps(nextProps) {
        console.log("componenteEillReceiveProps:")
        console.log("f1 : ", nextProps.selectedDate)
        console.log("f1.1 : ", this.props.selectedDate)
        // console.log("nextprops",nextProps.selectedDate)
        if (nextProps.selectedDate !== this.props.selectedDate) {
            var date = new Date(moment(nextProps.selectedDate));
            console.log("f1.2 : ", date)
            var selectedYear = date.getFullYear();
            console.log("f1.3 : ", selectedYear)
            var selectedMonth = date.getMonth();
            console.log("f1.4 : ", selectedMonth)
            var selectedDay = date.getDate();
            console.log("f1.5 : ", selectedDay)
            this.setState({ selectedYear: selectedYear, selectedMonth: selectedMonth, selectedDay: selectedDay });

            // this.handleYearChange(selectedYear)
            // this.handleMonthChange(selectedMonth)
            // this.handleDayChange(selectedDay)
        }
    }
    generateYearOptions = () => {
        console.log("generateYearOptions :")
        var _state = this.state;
        var startYear = _state.startYear;
        console.log("f2: ", startYear)
        var endYear = _state.endYear;
        console.log("f2.2 : ", endYear)
        var yearOptions = [];
        for (var _i = startYear; _i <= endYear; _i++) {
            yearOptions.push(_i);
        }
        /* yearOptions.map((item, index) => {
            yearOptions[index] = <option value={`${item}`}>{item}</option>
        }) */
        console.log("F2.3 RETURN : ", yearOptions)
        return yearOptions
    }

    generateMonthOptions = () => {
        // console.log("generateMOnthOptions :")
        var _state2 = this.state;
        let startMonth = _state2.startMonth;
        console.log("f3 : ", startMonth)
        let endMonth = _state2.endMonth;
        console.log("f3.1 : ", endMonth)
        let startYear = _state2.startYear;
        console.log("f3.2 : ", startYear)
        let endYear = _state2.endYear;
        console.log("f3.3 : ", endYear)
        let selectedYear = _state2.selectedYear;
        console.log("f3.4 : ", selectedYear)
        var months = [];
        if (selectedYear === startYear) {
            console.log("f3.5 : ", this.state.selectedDay)
            if (this.state.selectedDay === 6 && this.state.selectedMonth > this.state.startMonth){
                console.log("si entro 2")
                for (var i = startMonth +1; i <= endMonth; i++) {
                    months.push({
                        value: i,
                        month: monthByNumber[i]
                    });
                }
            }else{
                if (this.state.startDay > this.state.endDay && this.state.endDay >= 1 && this.state.endDay <=4){
                    console.log("si es mayor")

                    if (startMonth > endMonth) {
                        months.push({
                            value: startMonth,
                            month: monthByNumber[startMonth]
                        });
                    }
                    for (var i = startMonth; i < endMonth; i++) {
                        months.push({
                            value: i,
                            month: monthByNumber[i]
                        });
                    }
                }else{
                    if (startMonth > endMonth) {
                        months.push({
                            value: startMonth,
                            month: monthByNumber[startMonth]
                        });
                    }
                    for (var i = startMonth; i <= endMonth; i++) {
                        months.push({
                            value: i,
                            month: monthByNumber[i]
                        });
                    }
                }
            }
        } else if (selectedYear === endYear) {
            console.log("f3.6 : ", selectedYear, " === ", endYear)
            for (var _i2 = 0; _i2 <= endMonth; _i2++) {
                months.push({
                    value: _i2,
                    month: monthByNumber[_i2]
                });
            }
        } else {
            console.log("f3.7 : ")
            for (var _i3 = 0; _i3 <= 11; _i3++) {
                months.push({
                    value: _i3,
                    month: monthByNumber[_i3]
                });
            }
        }
        /* let monthOptions =[]
        month.map((item, index) => {
            monthOptions[index]= <option value={`${item.value}`}>{item.month}</option>
        }) */
        console.log("f3.8 : ", months)
        return months
    }

    generateDayOptions = () => {
        // console.log("generateDayOptions :")
        var _state3 = this.state;
        let startYear = _state3.startYear;
        console.log("f4 : ", startYear)
        let startMonth = _state3.startMonth;
        console.log("f4.1 : ", startMonth)
        let startDay = _state3.startDay;
        console.log("f4.2 : ", startDay)
        let endYear = _state3.endYear;
        console.log("f4.3 : ", endYear)
        let endMonth = _state3.endMonth;
        console.log("f4.4 : ", endMonth)
        let endDay = _state3.endDay;
        console.log("f4.5 : ", endDay)
        let selectedYear = _state3.selectedYear;
        console.log("f4.6 : ", selectedYear)
        let selectedMonth = _state3.selectedMonth;
        console.log("f4.7 : ", selectedMonth)
        var dayOptions = [];
        var monthDays = void 0;
        if (selectedYear === startYear) {
            if (selectedMonth === startMonth) {
                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                console.log("monthDays 0: ", monthDays)
                if (monthDays === 31 && this.state.selectedDay >= 6 && this.state.selectedDay <=27) {
                    console.log("si es 6")
                    for (var i = startDay + 1; i <= endDay; i++) {
                        dayOptions.push(i);
                    }
                    console.log("dayOptions1.1.1: ", dayOptions)
                }else if(monthDays === 30 && this.state.selectedDay >= 6 && this.state.selectedDay <=27){
                    if (startDay > endDay && this.state.selectedDay === 27){
                        console.log("1")
                        for (var i = startDay + 1; i <= monthDays; i++) {
                            dayOptions.push(i);
                        }
                        console.log("dayOptionsPruebaN: ", dayOptions)
                    }else{
                        console.log("2")
                        for (var i = startDay + 1; i <= endDay+1; i++) {
                            dayOptions.push(i);
                        }
                    }
                    console.log("dayOptionsPrueba: ", dayOptions)
                } else{
                    console.log("monsthDays1 :", monthDays)
                    console.log("startDay +1 :", startDay + 1)
                    for (var i = startDay + 1; i <= monthDays; i++) {
                        dayOptions.push(i);
                    }
                    console.log("dayOptions1.1: ", dayOptions)
                }
            } else {

                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                console.log("monsthDays else2 :", monthDays)
                for (var _i4 = 1; _i4 <= endDay + 1; _i4++) {
                    dayOptions.push(_i4);
                }
                console.log("dayOptions2.1: ", dayOptions)
            }
        } else if (selectedYear === endYear) {
            if (selectedMonth === endMonth) {
                console.log("else if 3")
                for (var _i5 = 1; _i5 <= endDay; _i5++) {
                    dayOptions.push(_i5);
                }
                console.log("dayOptions3.1: ", dayOptions)
            } else {
                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                if (selectedMonth === 11) {
                    monthDays = 5;
                }
                for (var _i6 = 1; _i6 <= monthDays; _i6++) {
                    dayOptions.push(_i6);
                }
            }
        } else {
            if (selectedMonth) {
                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                for (var _i7 = 1; _i7 <= monthDays; _i7++) {
                    dayOptions.push(_i7);
                }
            } else {
                for (var _i8 = 1; _i8 <= 31; _i8++) {
                    dayOptions.push(_i8);
                }
            }
        }
        /* return dayOptions.map((item, index) => {
            <option value={`${item}`}>{item}</option>
        }) */
        return dayOptions

    }
    handleYearChange = (year) => {
        year = parseInt(year)
        this.setState({
            selectedYear: year
        });
        if (this.props.onYearChange) {
            this.props.onYearChange(year)
        }
        this.handleDateChange(unit.year, year);
    }
    handleMonthChange = (month) => {
        month = parseInt(month);
        this.setState({ selectedMonth: month });
        if (this.props.onMonthChange) {
            this.props.onMonthChange(monthByNumber[month]);
        }
        this.handleDateChange(unit.month, month);
    }
    handleDayChange = (day) => {
        day = parseInt(day);
        this.setState({ selectedDay: day });
        if (this.props.onDayChange) {
            this.props.onDayChange(day);
        }
        this.handleDateChange(unit.day, day);
    }
    handleDateChange = (type, value) => {
        if (this.props.onDateChange) {
            var _state4 = this.state,
                selectedYear = _state4.selectedYear,
                selectedMonth = _state4.selectedMonth,
                selectedDay = _state4.selectedDay;

            if (type === unit.year) {
                selectedYear = value;
            } else if (type === unit.month) {
                selectedMonth = value;
            } else if (type === unit.day) {
                selectedDay = value;
            }
            if (selectedYear !== -1 && selectedMonth !== -1 && selectedDay !== -1) {
                this.props.onDateChange(new Date(selectedYear, selectedMonth, selectedDay));
            }
        }
    }

    render() {
        const { className, input } = this.props
        return (
            <div className="d-flex">
                <div className={this.props.className}>
                    <select
                        className="btn btn-outline-secondary dropdown"
                        name={"dia"}
                        value={this.state.selectedDay}
                        onChange={(e) => this.handleDayChange(e.target.value)}
                    >
                        {
                            this.generateDayOptions().map((item, index) => (
                                <option
                                    key={`${index}`}
                                    selected
                                    className="dropop"
                                    value={`${item}`}
                                >
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className={this.props.className}>
                    <select
                        className="btn btn-outline-secondary month dropdown"
                        name={"mes"}
                        value={this.state.selectedMonth}
                        onChange={(e) => this.handleMonthChange(e.target.value)}
                    >
                        {
                            this.generateMonthOptions().map((item, index) => {
                                return <option
                                    key={`${index}`}
                                    className="dropop"
                                    value={`${item.value}`}
                                >
                                    {item.month}
                                </option>
                                // return <option value={`${item.value}`}>{'hola'}</option>
                            })
                        }
                    </select>
                </div>
                <div className={this.props.className}>
                    <select
                        className="btn btn-outline-secondary dropdown"
                        name={"anio"}
                        value={this.state.selectedYear}
                        onChange={(e) => this.handleYearChange(e.target.value)}
                    >
                        {
                            this.generateYearOptions().map((item, index) => {
                                return (
                                    <option
                                        key={`${index}`}
                                        value={`${item}`}
                                        className="dropop"
                                    >
                                        {item}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        )
    }
}
