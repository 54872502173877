import React, { Component } from 'react';
import _ from 'lodash';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import { RenderNumber } from "../../../Utils/renderField/renderReadField";
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import ToolbarReporteIngresosP from '../../../Utils/Toolbar/ToolbarReporteIngresosP';
import moment from 'moment';
import { Redirect } from 'react-router';


export default class ReporteIngresoProducto extends Component {

    state = {
        meses: [],
    }

    componentDidMount() {
        this.props.reporteIngresos();
    }

    changeFechaInicio = (value) => {
        const { fecha_fin } = this.props;
        if (value <= fecha_fin) {
            this.props.changeFechaInicio(value);
        }
    };

    changeFechaFin = (value) => {
        const { fecha_inicio } = this.props;
        if (fecha_inicio <= value) {
            this.props.changeFechaFin(value);
        }
    }

    render() {
        const { loader, data, reporteIngresos, page, permisos } = this.props;
        if(permisos.ingresos_producto === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE INGRESO A BODEGA POR PRODUCTO">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{ padding: "15px" }}>
                            <ToolbarReporteIngresosP
                                {...this.props}
                                changeFechaInicio={this.changeFechaInicio}
                                changeFechaFin={this.changeFechaFin}
                            />
                        </div>
                    </div>

                    <div className="mt-3">
                        <Grid
                            hover
                            striped
                            data={data}
                            loading={loader}
                            page={page}
                            className="reporte-table"
                            onPageChange={reporteIngresos}
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                className='reporte-table'
                                dataFormat={(cell, row) => (
                                    moment(row.fecha).format("DD-MM-YYYY")
                                )}
                                width="10%"
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="producto"
                                className='reporte-table'
                                // width="25%"
                            >
                                PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="bodega"
                                className='reporte-table'
                                width="18%"
                            >
                                BODEGA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="despacho"
                                className='reporte-table'
                                width="15%"
                            >
                                NO. DESPACHO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="origen"
                                className='reporte-table'
                                width="18%"
                            >
                                ORIGEN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cantidad"
                                className='reporte-table'
                                dataAlign="right"
                                width="12%"
                                dataFormat={ cell => <RenderNumber value={cell} decimalScale={4} />}
                            >
                                CANTIDAD
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>
            </CardSimple>
        )
    }
}
