import React, { Component, Fragment } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderFieldRadio, renderSelectField, renderCurrency } from '../../../Utils/renderField';
import { TipoTransaccion } from '../../../../../utility/constants';
import { getSimbolo} from "../../../Utils/renderField/renderTableField";


import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import Modal from 'react-responsive-modal';
import PagoCreditoForm from './PagoCreditoForm';
import SeleccionTipoCuentaForm from './seleccionTipoCuenta';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";

class PagoOCForm extends Component {
    state = {
        open_modal:false,
        es_pago:false,
        es_cuenta: true,
        es_gasto: false
    }
    componentDidMount(){
        if(this.props.pago_inmediato == true){
            this.setState({
                es_pago: false,
                open_modal: true
            })
        }
        if (!this.props.es_editar) {
            console.log("props ", this.props.credito);
            this.props.setDiasCredito(this.props.credito);
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.pago_inmediato !== prevProps.pago_inmediato){
            if(this.props.pago_inmediato == true){
                this.setState({
                    es_pago: false,
                    open_modal: true
                })
            }
        }
    }
    renderDataFacturacion = () => {
        const { handleSubmit, previousStep, cuentas, tipo_documento, simbolo} = this.props;

        return(
            <Fragment>
                        <div className="row col-12 m-0 p-0">
                            <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                <label className="t-azul"  htmlFor="nit_facturacion">Nit Facturación:</label>
                                <Field name="nit_facturacion" label="nit_facturacion" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                <label className="t-azul"  htmlFor="nombre_factura">Nombre para Factura</label>
                                <Field name="nombre_factura" label="nombre_factura" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
            </Fragment>
        );
    };
    renderDiasCredito = () => {
        const { handleSubmit, previousStep, cuentas, tipo_documento, simbolo} = this.props;

        return(
            <Fragment>
                <div className="row col-12 m-0 p-0">
                    <div className={`form-group has-feedback  col-md-6  col-12 `}>
                        <label className="t-azul"  htmlFor="dias_al_credito">Días de Crédito</label>
                        <Field name="dias_al_credito" label="dias_al_credito" component={renderField} type="number" className="form-control" />
                    </div>
                </div>
            </Fragment>
        );
    };
    eliminarPago = (id, row) => {
        this.props.eliminarPagoCredito(id);

    }
    eliminarGasto = (id, row) => {
        this.props.eliminarGasto(id);
    }

    cerrarModal = () => {
        this.setState({open_modal: false})
    }

    onSubmitTipoCuenta = (values) =>{
        this.setState({
            es_cuenta: values.tipo_cuenta == 'true'? true : false
        })
        this.cerrarModal();
    }
    render() {
        const { handleSubmit, previousStep, proveedor, agregarGastoOC, tipo_cambio,
            agregarPagoCredito, simbolo, total, pago_inmediato, credito} = this.props;

        let ver_tipo_cambio = true;

        if(simbolo.trim() == "Q."){
            ver_tipo_cambio = false;
        }

        let total_quetzales = 0;
        if (tipo_cambio) {
            total_quetzales = total * Number(tipo_cambio);
        }

        return (
            <Fragment>

                <form name="pagoOCForm" className="form-validate mb-lg" onSubmit={handleSubmit}>

                    <div className="row justify-content-center">
                        <h4 className="t-primary" >Forma de Pago</h4>
                    </div>
                    <div className="col-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-10 row m-0 p-0">
                             <div className="row col-12 m-0 p-0 d-flex justify-content-center inline-header">
                                <div className={`form-group has-feedback col-md-5 inline-headers col-12`}>
                                    <span className="t-primary h5 mr-3"  htmlFor="descripcion">Proveedor.     </span>
                                    <h2 className="t-musgo"  htmlFor="descripcion">{
                                       proveedor
                                    }</h2>
                                </div>
                                <div className={`form-group has-feedback col-md-5 inline-headers col-12`}>
                                    <span className="t-primary h5 mr-3"  htmlFor="descripcion">Total.     </span>
                                    <h2 className="t-mostaza"  htmlFor="descripcion">{
                                        <RenderMoneda monto={total} simbolo={simbolo} />
                                    }</h2>
                                </div>
                            </div>
                            <div className="row col-12 m-0 p-0 d-flex justify-content-center">
                                <div className={`form-group has-feedback  col-md-3 col-12`}>
                                    <Field
                                        name="pago_inmediato"
                                        label="Pago Inmediato"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"true"}
                                        className="form-control" />
                                </div>
                                <div className={`form-group has-feedback col-md-3  col-12`}>
                                    <Field
                                        value={"false"}
                                        name="pago_inmediato"
                                        label="Pago al Crédito"
                                        component={renderFieldRadio}
                                        type="radio"
                                        className="form-control" />
                                </div>
                            </div>

                            {this.renderDataFacturacion()}
                            {
                                (ver_tipo_cambio) && (
                                    <div className="row col-12 m-0 p-0 d-flex justify-content-center">
                                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                            <label className="t-azul"  htmlFor="tipo_cambio">Tipo de cambio</label>
                                            <Field
                                                name="tipo_cambio"
                                                label="tipo_cambio"
                                                prefix={'Q. '}
                                                decimalScale={3}
                                                component={renderCurrency}
                                                parse={cell => parseFloat(cell)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                            <span className="t-primary h5 mr-3"  htmlFor="descripcion">Total de Quetzales </span>
                                            <h2 className="t-mostaza"  htmlFor="descripcion">{
                                                <RenderMoneda monto={total_quetzales} simbolo={'Q. '} />
                                            }</h2>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (!pago_inmediato)&& (
                                    this.renderDiasCredito()
                                )
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                                <button onClick={previousStep} className="btn btn-secondary  align-self-center">ANTERIOR</button>
                                <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Fragment>

        );
    }
}


PagoOCForm =  reduxForm({
    form: 'ordencompraForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            numero_cuenta: validators.exists()('Este campo es requerido'),
            tipo_documento: validators.exists()('Este campo es requerido'),
            no_documento: validators.exists()('Este campo es requerido'),
            monto: validators.exists()('Este campo es requerido'),
            nit_facturacion: validators.exists()('Este campo es requerido'),
            nombre_factura: validators.exists()('Este campo es requerido'),
            dias_al_credito: validators.exists()('Este campo es requerido'),
            tipo_cambio: validators.exists()('Este campo es requerido'),
        });
    },
})(PagoOCForm);

const selector = formValueSelector('ordencompraForm');
PagoOCForm = connect((state, props) => {
    let todos_productos = [];
    let simbolo = "Q. "
    let valor_iva = 0;

    const productos = selector(state, 'detalle_orden');
    const moneda = selector(state, 'moneda');
    const descuento = selector(state, 'descuento');
    const tiene_iva = selector(state, 'tiene_iva');
    const tipo_movimiento = selector(state, 'tipo_documento')
    const proveedor = selector(state, 'proveedor')
    const tipo_cambio = selector(state, 'tipo_cambio')

    let pago_inmediato = selector(state, 'pago_inmediato')
    let tipo_documento = 'Documento de Registro'
    if(tipo_movimiento){
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        tipo_documento = seleccionado.ayuda
    }
    if(moneda){
        simbolo = getSimbolo(moneda)
    }
    if(pago_inmediato == 'true'){
        pago_inmediato = true
    }else {
        pago_inmediato = false;
    }
    if(productos){
        todos_productos = productos;
    }

    let total = 0;
    let subtotal = 0;
    todos_productos.forEach( x => {
        if(!isNaN(x.cantidad) && !isNaN(x.precio_unitario)){
            subtotal += Number(x.cantidad) * Number(x.precio_unitario);
        }
    })

    total = subtotal
    if(descuento){
        total = total - descuento;
    }
    if(tiene_iva){
        valor_iva = total * 0.12;
        total = total + valor_iva;
    }

    return {
        pago_inmediato,
        simbolo,
        tipo_documento,
        total,
        tipo_cambio,
        proveedor: proveedor ? proveedor.nombre : '--',
        credito: proveedor.dia_credito
    }
})(PagoOCForm);


export default PagoOCForm
