import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/regiones/regiones';
import NuevaRegion from './NuevaRegion';

const mstp = state => {
    return {...state.regiones}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(NuevaRegion)
