import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DespachoPlantaGrid } from '..';
import {
    actions
} from '../../../../../redux/modules/despachosplanta/despachosplanta';
import DespachoPlantaCrear from './DespachoPlantaCrear';
import Facturacion from '../Facturas/index';
import FacturasContainer from '../Facturas/facturas';


const mstp = state => {
    return { ...state.despachosplanta, misdatos: state.form.DespachoPlantaForm, permisos: state.login.me.permisos, id_user: state.login.me.id }
};

const mdtp = {
    ...actions
};


const container = (props) => {
    const { misdatos } = props
    const [count, setCount] = React.useState(0);

    const setClienteSucursal = (e) => {
        setCount({ cliente: e.id, sucursal: e.sucursal })
    }

    const [state, setState] = React.useState({ vistaFacturacion: false, data: null });

    return (
        state.vistaFacturacion === false ?
            <DespachoPlantaCrear
                {...props}
                count={count}
                setClienteSucursal={setClienteSucursal}
                mostrarVista={(vistaFacturacion, index) => {
                    setState({
                        losdatos: misdatos.values.despachosDetalles ? misdatos.values.despachosDetalles[index] : [], vistaFacturacion, index
                    }
                    )
                }}
            />
            :
            <Facturacion
                {...props}
                losdatos={state.losdatos}
                mostrarVista={() => setState({ vistaFacturacion: false })} />
    )
}

export default connect(mstp, mdtp)(container)





