import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import Swal from 'sweetalert2';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import ToolbarProduccion from './filtrosProduccion';

function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}

class SplitDropDown extends Component{
    state={
        direccion: `/produccion/${this.props.orden}/orden`,
        nombre: "GENERAR OP"
    }

    handleBoton = (url) => {
        console.log("activador boton");
        console.log(this.props);
    }

    render(){
        const { orden } = this.props
        return(
            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                {/* <button type="button" className="btn btn-primary">{this.state.nombre}</button> */}
                {/* <Link className="btn btn-primary" to={this.state.direccion} >{this.state.nombre}</Link> */}
                <div className="btn-group" role="group">
                    <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-mostaza dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        // style={{width:"50px", minWidth:"auto", border:"0"}}
                    >NUEVA OP</button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <Link className="dropdown-item" to={`/produccion/${orden}/orden`} >GENERAR OP</Link>
                        <Link className="dropdown-item" to={`/produccion/ordenes/${orden}`} >GENERAR SOBRE OV</Link>
                        <Link className="dropdown-item" to={`/produccion/${orden}/sim`} >GENERAR SOBRE SI</Link>
                        <Link className="dropdown-item" to={`/produccion/${orden}/dev`} >GENERAR SOBRE DV</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default class ColaDeProduccionGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    state = {
        empresa: ''
    }

    componentWillMount() {
        const { listar, page, setEmpresa, clearSearch } = this.props;
        console.log("props ", this.props)
        let id = this.props.match.params.id
        clearSearch();
        listar(id);
        setEmpresa(id);

        for (const item of this.props.empresas) {
            if (item.id === id) {
                this.setState({empresa: item.nombre});
                break;
            }
        }
    }

    pageChange = (page) => {
        const { listar } = this.props;
        let id = this.props.match.params.id;
        listar(id, page);
    }

    eliminarop = (id) => {
        Swal.fire({
            title: '¿Eliminar?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminar(id)
            }
        });
    };

    botonModal = (id, row) => {

        if (row.produccion_finalizada || row.estado == false) {
            return (<div></div>)
        }
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                onClick={(e)=>{
                    this.eliminarop(id);
                }}
            >
                <img 
                    className="action-img" 
                    title="Ver eliminado" 
                    src={require("../../../../../../assets/img/icons/Cerrar.png")} alt="Ver eliminado"
                />
            </a>
        )
    }
    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        // if(permisos["empresas"] === false) {
        //     if(!!permisos[`ver_empresas`] === false) {
        //         return <Redirect to={`/`} />
        //     }
        // }
        return(
            <div className="Container bg-white">
                <h3 style={{textTransform: "uppercase"}} className="t-primary m-t p-t">{`ORDENES DE PRODUCCION DE ${this.props.nombre_empresa}`}</h3>
                <div className="row justify-content-end">
                    <div className="col-md-8 col-12">
                        <ToolbarSimple
                            textBoton="REPORTAR PRODUCCION"
                            ruta={`/reportar_produccion/${this.props.match.params.id}`}
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre"}
                            permisos={permisos.orden_produccion}
                            // textBoton2="GENERAR OP"
                            // ruta2={`/produccion/ordenes/${this.props.match.params.id}`}
                            splitboton={
                                (permisos.orden_produccion) && (
                                    <SplitDropDown
                                        orden={this.props.match.params.id}
                                    />
                                )
                            }
                            estatefilterBar
                            filterbar={
                                <ToolbarProduccion
                                    changeEstado= { this.props.changeEstado}
                                    valorEstado = { this.props.valorEstado}
                                />
                            }
                        />
                    </div>
                </div>
                {/* <SplitDropDown
                    orden={this.props.match.params.id}
                />
                */}
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid hover striped data={data} loading={loader}  onPageChange={this.pageChange} onSortChange={onSortChange} page={page} >
                                   { (permisos.orden_produccion || permisos.ver_orden_produccion) &&( <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        tdStyle={cellTachado}
                                        dataSort
                                        dataFormat={
                                            standardActions({
                                                ver:"/produccion/orden",
                                                adicional: this.botonModal,
                                            })
                                        }
                                    >
                                    </TableHeaderColumn>)}
                                    <TableHeaderColumn
                                        isKey
                                        tdStyle={cellTachado}
                                        dataField="no_orden"
                                        dataSort
                                    >
                                        NO ORDEN
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="creado"
                                        tdStyle={cellTachado}
                                        dataSort
                                        dataFormat={
                                            cell => <RenderDateTime fecha={cell}/>
                                        }
                                    >
                                        FECHA CREACION
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fecha"
                                        tdStyle={cellTachado}
                                        dataSort
                                        dataFormat={
                                            cell => <RenderDateTime fecha={cell}/>
                                        }
                                    >
                                        FECHA DE SOLICITUD
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fecha_prox_entrega"
                                        tdStyle={cellTachado}
                                        dataSort
                                        dataFormat={
                                            cell => <RenderDateTime fecha={cell}/>
                                        }
                                    >
                                        FECHA ENTREGA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={cellTachado}
                                        dataField="padre"
                                        dataFormat = { (cell, row) => {
                                            return (cell
                                                ? cell.proveedor
                                                    ? cell.proveedor
                                                    : cell.empresa_destino
                                                : row.devolucion && row.devolucion.cliente
                                            )}
                                        }
                                    >
                                        CLIENTE O EMPRESA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="produccion_finalizada"
                                        tdStyle={cellTachado}
                                        dataSort
                                        dataFormat = {
                                            (cell) => {
                                                if(cell){
                                                    return (<span className="t-corinto font-weight-bold">Finalizada</span>)
                                                }else{
                                                    return(<span className="t-verde font-weight-bold">Activa</span>)
                                                }
                                            }

                                        }
                                    >
                                        ESTADO PRODUCCIÓN
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

