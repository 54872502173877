import { handleActions } from 'redux-actions';
import { push, goBack } from "react-router-redux";
import { initialize as initializeForm, reset, change } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import { getMunicipios } from '../../../utility/constants';
import _ from 'lodash';
import moment from 'moment';

let url = 'itinerario' ;
let url_factura = 'factura';
let form = 'itinerarioForm';
let dirGrid = '/itinerario';

export const constants ={
    LOADER: 'IT_LOADER',
    DATA: "DATA",
    ITEM: "ITEM",
    SEARCH: "IT_SEARCH",
    PAGE: `IT_PAGE`,
    ITEMGASTOS: "ITEM_GASTOS_IT",
    EMPRESAS: 'GASTOS_EMPRESAS_IT',
    TIPO_GASTOS: 'GASTOS_TIPO_GASTO_IT',
    CLIENTE: "IT_CLIENTE",
    SET_MODAL_ANULAR_FACTURA: 'SET_MODAL_ANULAR_FACTURA',
    SET_MODAL_HISTORIAL: 'SET_MODAL_FACTURAS_HISTORIAL',
    ITEM_OC: "ITEM_OC",
    SET_ORDEN_INDEX: "SET_ORDEN_INDEX",
    SET_VER_PRODUCTOS: "SET_VER_PRODUCTOS",
    SUBMIT_PRODUCTOS: "SUBMIT_PRODUCTOS",
    SET_VER_PRODUCTOS_FALSE: "SET_VER_PRODUCTOS_FALSE",
    SET_MUNICIPIOS: 'SET_MUNICIPIOS',
    SET_MUNICIPIOS_BANCOS: 'SET_MUNICIPIOS_BANCOS',
    SET_ESTADO: 'SET_ESTADO_I',
    SET_ESTADO_AC: 'SET_ESTADO_AC_I',
    SET_DATE_START: 'I_SET_DATE_START',
    SET_DATE_END: 'I_SET_DATE_END',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type:type,
    data
})

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setCliente = (cliente) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE,
        data: cliente
    })
}

const setItemOC = (itemOC) => ( dispatch ) => {
    dispatch({
        type: constants.ITEM_OC,
        itemOC
    })
}

const setOpenModalAnularFactura = (open_modal_anular_factura) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_ANULAR_FACTURA,
        open_modal_anular_factura: open_modal_anular_factura
    })
}

const validarNitEP = () => (dispatch, getStore) => {
    const form = getStore().form.FacturaForm.values
    const nit = form.nit;
    const params = { nit };

    api.get(`clientes/validateNit`, params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        form.nombre = response.detail.nombre;
        dispatch(initializeForm('ClienteForm', form))
    }).finally( );
};

const obtenerTipoIdentificacion = () => (dispatch, getStore) => {
    const form = getStore().form.FacturaForm.values
    const tipo_doc = form.tipo;
    return tipo_doc;
}

const validarCuiEP = () => (dispatch, getState) => {
    const form = getState().form.FacturaForm.values
    const URL_GET_CUI = "get-valid-cui";
    const cui = form.nit;
    let nameNew = "";

    const fetchData = async () => {
        try {
            const data = await api.post(URL_GET_CUI, {cui});
            nameNew = data.first_name + " " + data.last_name;
            form.nombre = nameNew;
            await dispatch(initializeForm("FacturasForm", form));

        } catch (error) {
            nameNew = "invalido";
            form.nombre = nameNew;
            await dispatch(initializeForm("FacturasForm", form));
        }
    };
    fetchData();
};

const setOpenModalFacturasHistorial = (open_modal_historial) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_HISTORIAL,
        open_modal_historial: open_modal_historial
    })
}

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().itinerario;
    const params = { page };
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.itinerario_detalle__clienteProveedor__vendedor_id = me.id
    }
    params.estado = resource.estado ? resource.estado.value : true
    if(resource.estadoAC){
        params.cerrado = resource.estadoAC.value
    }
    if(resource.dateStart != null && resource.dateEnd != null){
        params.fecha_inicial = resource.dateStart.format("YYYY-MM-DD");
        params.fecha_final = resource.dateEnd.format("YYYY-MM-DD");
    }
    //params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        const response2 = _.cloneDeep(response)
        response2.results = []
        response.results.forEach((data, index) =>{
            let existe = false
            if(response2.results.length == 0){
                response2.results.push(data)
            }
            else{
                response2.results.forEach((data1, index1) =>{
                    if(data.id == data1.id){
                        existe = true
                    }
                })
                if(existe==false){
                    response2.results.push(data)
                }
            }
            
        })
        dispatch(setData(constants.DATA ,response2));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
        dispatch(setData(constants.SET_PAGOS_CREDITO, response.pagos_ordenes))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const BillDespachoPlanta = (params, bandera, source=null)=> (dispatch, getStore ) => {
    //params.despacho_id = datos.despacho.id
    //params.fecha = moment(datos.fecha).format('YYYY-MM-DDThh:mm');
    const despachos = getStore().form.itinerarioForm ? getStore().form.itinerarioForm.values.itinerario_detalle_clientes : getStore().despachosplanta.item.despachoplanta_detalle


    // Excluir identificacion si es Extranjero
    let error_ext = false;
    if (source) {
        source.item.itinerario_detalle_clientes[0].despacho.orden_detalle.proveedor.empresa.map(empresa =>{
            if (source.item.itinerario_detalle_clientes[0].despacho.orden_detalle.nit_facturacion == empresa.Nit && empresa.tipo == 'EXT') {
                error_ext = true;
            }
        })
    }
    if (error_ext) {
        NotificationManager.error('No se permite la facturación con EXT', 'Error', 5000);
        dispatch(setLoader(false));
        return ;
    }


    dispatch(setLoader(true));
    api.post(`despachosplanta/billdespachoplanta`, params).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(verifyBil(res.idFactura, params.id, bandera))

        if (despachos) {
            despachos.forEach(despacho =>{
                if(params.idDespacho == despacho.despacho.id){
                    despacho.despacho.facturas_despacho.push(res.idFactura)
                }
            })
        }
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR1', 3000);
        dispatch(setLoader(false));
    })
}

const verifyBil = (id, ov, bandera) => (dispatch) => {
    const params={id}
    api.get(`despachosplanta/verify_bill`, params).then((res)=>{
        if (res.estado == "0" || res.estado == 0){
            setTimeout(() => {
                dispatch(verifyBil(id, ov))
            }, 500);
        }else{
            if ((res.estado == "1" || res.estado == 1) || (res.estado == "4" || res.estado == 4)){
                NotificationManager.success('Factura timbrada', 'Éxito', 3000);
                dispatch(setData(constants.DATA, ov));

                if (bandera){
                    bandera()
                }
            }else{
                NotificationManager.error(res.mensaje, 'ERROR', 3000);
            }
            dispatch(setLoader(false))
        }
    }).catch(()=>{
        dispatch(setLoader(false))
    })
}
const leerItinerario = (id, entra_editar) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    api.get(`${url}/getDetalleItinerario/${id}`).then((response) => {

        response.desglose = [{}]
        let detalle = {};
        for(let i=0; i < response.itinerario_detalle_proveedores.length ; i++){
            detalle[i] = {
                detalle_orden: Object.values(JSON.parse(response.itinerario_detalle_proveedores[i].detalle_productos))
            }
            response.itinerario_detalle_proveedores[i].detalle_productos = detalle[i].detalle_orden
        }
        for(let i=0; i < response.itinerario_detalle_clientes.length ; i++){
            if(visualizar_mis_datos == true){
                if(response.itinerario_detalle_clientes[i].clienteProveedor.vendedor.id != me.id){
                    response.itinerario_detalle_clientes.splice(i)
                }
            }
            
        }
        //response.itinerario_detalle_proveedores.detalle_productos = detalle;
        dispatch({type: constants.ITEM, item: {} });
        dispatch({type: constants.ITEM, item: response});
        dispatch({
            type: constants.SUBMIT_PRODUCTOS,
            detalle
        })
        if (!!form && entra_editar==false)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch, getStore) => {
    let store = getStore().itinerario;
    let detalle = Object.values(store.detalle_productos)
    data.itinerario_detalle = [];
    if(detalle.length > 0){
        for(let i = 0; i < detalle.length; i++){
            let nuevoDetalle = {}
            for(let j = 0 ; j < detalle[i].detalle_orden.length ; j++){
                nuevoDetalle[j] = {
                    id: detalle[i].detalle_orden[j].producto.id,
                    nombre_producto: detalle[i].detalle_orden[j].producto.nombre,
                    cantidad: detalle[i].detalle_orden[j].cantidad,
                    orden: detalle[i].detalle_orden[j].orden,
                }
            }
            data.itinerario_detalle_proveedores[i].detalle_productos = JSON.stringify(nuevoDetalle)
        }
    }
    if(data.itinerario_detalle_clientes){
        for(let i = 0; i < data.itinerario_detalle_clientes.length ;i++){
            data.itinerario_detalle.push(data.itinerario_detalle_clientes[i]);
        }
    }
    if(data.itinerario_detalle_proveedores){
        for(let i = 0; i < data.itinerario_detalle_proveedores.length ;i++){
            data.itinerario_detalle.push(data.itinerario_detalle_proveedores[i]);
        }
    }
    //data = transformarData(data);
    dispatch(setLoader(true));
    api.post(`${url}`, data).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));

    }).catch((error) => {
       mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cerrar = id => (dispatch, getStore) => {
    dispatch(setLoader(true))
    let params = { id }
    api.post(`${url}/cerrar`,params).then((response) => {
        dispatch(leerItinerario(id))
        NotificationManager.success('Itinerario Cerrado', 3000);
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false))
    })
}

const editar = (id, data) => (dispatch, getStore) => {
    let store = getStore().itinerario;
    let detalle = Object.values(store.detalle_productos)
    data.itinerario_detalle = [];
    if(detalle.length > 0){
        for(let i = 0; i < detalle.length; i++){
            let nuevoDetalle = {}
            for(let j = 0 ; j < detalle[i].detalle_orden.length ; j++){
                nuevoDetalle[j] = {
                    id: detalle[i].detalle_orden[j].id,
                    nombre_producto: detalle[i].detalle_orden[j].nombre_producto,
                    cantidad: detalle[i].detalle_orden[j].cantidad,
                    orden: detalle[i].detalle_orden[j].orden,
                }
            }
            data.itinerario_detalle_proveedores[i].detalle_productos = JSON.stringify(nuevoDetalle)
        }
    }
    if(data.itinerario_detalle_clientes){
        for(let i = 0; i < data.itinerario_detalle_clientes.length ;i++){
            data.itinerario_detalle.push(data.itinerario_detalle_clientes[i]);
        }
    }
    if(data.itinerario_detalle_proveedores){
        for(let i = 0; i < data.itinerario_detalle_proveedores.length ;i++){
            data.itinerario_detalle.push(data.itinerario_detalle_proveedores[i]);
        }
    }
    //data = transformarData(data);
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getProductos = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`ordencompra/${id}`).then((data) => {
        dispatch( setItemOC(data) );
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const anularFacturaEP = (data, idDespachoPlanta) => (dispatch) => {
    dispatch(setLoader(true));

    api.post(`${url_factura}/anular_factura`, data).then(() => {
        NotificationManager.success('Factura Anulada', 'Éxito', 3000);

    }).catch((error) => {
        let mensaje = "Error en la anulación";
        if(error && error.detail) {
            mensaje = error.detail
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(leerItinerario(idDespachoPlanta))

        dispatch(setOpenModalAnularFactura(false))
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
};

const resetFormulario = () => (dispatch) => {
    dispatch(initializeForm(form, {
        desglose:[{}],
        fecha_salida: new Date(),
        fecha_entrada: new Date()
    }));
    dispatch({
        type: constants.SET_VER_PRODUCTOS,
        valor: false,
        index: 0,
        itemOC: []
    })
    dispatch({
        type: constants.SUBMIT_PRODUCTOS,
        detalle: []
    })
}

const mensajeError = (error) => {
    let mensaje = 'Error en la creación de la ruta';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
}

const crearDesdeItinerario = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`itinerario/desgloseGastos`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push(`/itinerario`));
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch({
            type: constants.EMPRESAS,
            empresas: res.empresa
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const getTipoGastos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipo_gasto').then((res)=>{
        dispatch({
            type: constants.TIPO_GASTOS,
            tipo_gastos: res
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

export const cambioDescuento = (valor, campo, index) => (dispatch, getStore) => {
    const {  values: { desglose }, values } = _.cloneDeep(getStore().form.itinerarioForm);
    const fraccion = desglose[index]

    let monto = 0
    if(values.monto){
        monto = values.monto
    }

    if(campo === 'precio'){
        if (valor){
            fraccion.porcentaje = ((valor / parseFloat(monto)) * 100).toFixed(2);
        }
        else{
            fraccion.porcentaje = 0;
        }
    }
    else if(campo=== 'monto'){
        if(valor){
            if(fraccion.porcentaje){
                fraccion.subtotal = ((fraccion.porcentaje / 100) * parseFloat(valor)).toFixed(2)
            }
        }
    }
    else{
        if (valor){
            fraccion.subtotal = ((valor / 100) * parseFloat(monto)).toFixed(2);
        }
        else{
            fraccion.porcentaje = 0;
        }

    }
    dispatch(initializeForm('itinerarioForm', {...values}))
}

const setOrdenIndex = (orden, index) => ( dispatch, getStore) => {
    dispatch({
        type: constants.SET_ORDEN_INDEX,
        orden,
        index,
    });
    if(orden){
        dispatch(getProductos(orden));
    } else{
        borrarProductos(index)
    }
}

const setVerProductos = (valor, index, editar) => ( dispatch, getStore ) => {
    if(valor){
        let store = getStore().itinerario;
        dispatch({
            type: constants.SET_VER_PRODUCTOS,
            valor,
            index,
            itemOC: store.detalle_productos[index]
        })
        if ('itinerarioFormProductos'){
            let formData = {
                detalle_orden: store.detalle_productos[index].detalle_orden
            }
            dispatch(initializeForm('itinerarioFormProductos', formData));
        }
    }else{
        dispatch({
            type: constants.SET_VER_PRODUCTOS_FALSE,
            valor
        })
    }
}

const onSubmitProductos = (data) => (dispatch, getStore) => {
    let store = getStore().itinerario;
    let detalle = store.detalle_productos;
    detalle[store.index] = data
    dispatch({
        type: constants.SUBMIT_PRODUCTOS,
        detalle
    })
    dispatch(setVerProductos(false));
}

const borrarProductos = ( index ) => (dispatch, getStore) => {
    let store = getStore().itinerario;
    let detalle = store.detalle_productos;
    delete detalle[index];
    dispatch({
        type: constants.SUBMIT_PRODUCTOS,
        detalle
    })
}

const PDFITINERARIO = ( id ) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('itinerario/imprimibleitinerario', {id}).then((res)=>{
       if (res.detail){
           console.log(res.detail)
           window.open(res.detail.archivo, '_blank');
            // return <a target="_blank" href={res.detail.archivo} 
            // className="btn btn-primary align-self-center"
            // />
       }
    }).catch((error)=>{
        mensajeError(error)
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setMunicipios = (val, set= 1) => (dispatch) =>{
    if (set == 1) {
        let municipios = getMunicipios(val)
        dispatch({type: constants.SET_MUNICIPIOS, data:municipios})
    }else
        dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:val})

}
const setAutomaticoReceptor = (info) => (dispatch, getStore) =>{
    const resource = getStore().form.FacturaForm;
    const formulario = resource.values;
    if (info.nombre) {
        formulario.nombre = info.nombre;
        formulario.nit = info.propietario.Nit.replace("-", "");
    }else{
        if (info.despacho.cliente_orden){
            let existe = 0
            info.despacho.orden_detalle.proveedor.empresa.forEach(empresa =>{
                if(info.despacho.orden_detalle.nit_facturacion==empresa.Nit){
                    formulario.nombre = empresa.nombre;
                    existe = 1
                }
                if(existe==0){
                    if(empresa.Nit=="CF" | empresa.Nit =="C/F"){
                        formulario.nombre = empresa.nombre
                    }
                }           
            })
            if (info.datoNit != null){
                formulario.nit = info.datoNit.replace("CF", "CF");
            }else{
                formulario.nit = "CF"
            }
        }
    }
    dispatch(initializeForm('FacturaForm', formulario));
}
const setCodigoPostal = (municipio) => (dispatch, getStore) =>{
    const resource = getStore().ordenventa;
    const codigo =  resource.municipios.find((item) => {
            if (item.value == municipio)
                return item.codigo
        })
    console.log(codigo)
    dispatch(change('FacturaForm','codigo_postal',codigo.codigo.toString()))
}
const estadoChange = estado => dispatch => {
    dispatch({type:constants.SET_ESTADO, estado});
    dispatch(listar());
}

const estadoChangeAC = estadoAC => dispatch => {
    dispatch({type:constants.SET_ESTADO_AC, estadoAC});
    dispatch(listar());
}

const setDateStart = (value) => (dispatch) => {
    dispatch({type: constants.SET_DATE_START, dateStart: value})
    dispatch(listar());
}

const setDateEnd = (value) => (dispatch) => {
    dispatch({type: constants.SET_DATE_END, dateEnd: value})
    dispatch(listar());
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    searchChange,
    clear,
    eliminar,
    leerItinerario,
    getProductos,
    setOrdenIndex,
    setVerProductos,
    borrarProductos,
    onSubmitProductos,
    resetFormulario,
    selectEmpresas,
    getTipoGastos,
    crearDesdeItinerario,
    setCliente,
    validarCuiEP,
    obtenerTipoIdentificacion,
    validarNitEP,
    setOpenModalAnularFactura,
    setOpenModalFacturasHistorial,
    anularFacturaEP,
    cambioDescuento,
    cerrar,
    PDFITINERARIO,
    setMunicipios,
    BillDespachoPlanta,
    setAutomaticoReceptor,
    setCodigoPostal,
    estadoChange,
    estadoChangeAC,
    setDateStart,
    setDateEnd,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) =>{
        return{
            ...state,
            data,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.ITEM]:(state,{item}) =>{
        return{
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.ITEMGASTOS]:(state,{itemGastos}) =>{
        return{
            ...state,
            itemGastos,
        };
    },
    [constants.EMPRESAS]: (state, {empresas}) => {
        return {
            ...state,
            empresas
        }
    },
    [constants.TIPO_GASTOS]: (state, {tipo_gastos}) => {
        return {
            ...state,
            tipo_gastos
        }
    },
    [constants.CLIENTE]: ( state,{ data })=>{
        return{
            ...state,
            cliente: data,
        }
    },
    [constants.SET_MODAL_ANULAR_FACTURA]: (state, { open_modal_anular_factura }) => {
        return {
            ...state,
            open_modal_anular_factura,
        };
    },
    [constants.SET_MODAL_HISTORIAL]: (state, { open_modal_historial }) => {
        return {
            ...state,
            open_modal_historial,
        };
    },
    [constants.ITEM_OC]: ( state,{ itemOC })=>{
        let store = state;
        store.detalle_productos[state.index] = itemOC
        return{
            ...state,
            detalle_productos: store.detalle_productos,
        }
    },
    [constants.SET_ORDEN_INDEX]: (state, {orden, index}) => {
        return {
            ...state,
            idOC: orden,
            index,
        }
    },
    [constants.SET_VER_PRODUCTOS]: (state, {valor, index, itemOC}) => {
        return {
            ...state,
            verProductos: valor,
            index,
            itemOC
        }
    },
    [constants.SUBMIT_PRODUCTOS]: ( state, { detalle } ) => {
        return {
            ...state,
            detalle_productos: detalle
        }
    },
    [constants.SET_VER_PRODUCTOS_FALSE]: ( state, { valor } ) => {
        return {
            ...state,
            verProductos: valor
        }
    },
    [constants.SET_MUNICIPIOS]:(state,{ data })=>{
        return{
            ...state,
            municipios: data,
        }
    },
    [constants.SET_MUNICIPIOS_BANCOS]:(state,{ data })=>{
        return{
            ...state,
            municipios_bancos: data,
        }
    },
    [constants.SET_ESTADO]:(state,{ estado })=>{
        return{
            ...state,
            estado,
        }
    },
    [constants.SET_ESTADO_AC]:(state,{ estadoAC })=>{
        return{
            ...state,
            estadoAC,
        }
    },
    [constants.SET_DATE_START]: (state, { dateStart }) => {
        return {
            ...state,
            dateStart
        }
    },
    [constants.SET_DATE_END]: (state, { dateEnd }) => {
        return {
            ...state,
            dateEnd
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    itemGastos: {},
    itemOC: {},
    search: '',
    page: 1,
    ordering: '',
    empresas: [],
    tipo_gastos: [],
    cliente: null,
    verProductos: false,
    idOC: undefined,
    index: undefined,
    detalle_productos: [],
    mostrar_estado: false,
    municipios: [],
    estado: {value: true, label: "Activos"},
    estadoAC: null,
    dateStart: moment().subtract(7, 'days'),
    dateEnd: moment(),
};

export default handleActions(reducers, initialState);

