import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'LOADER_RINGRESOS',
    DATA: 'DATA_RINGRESOS',
    ITEM: `ITEM_RINGRESOS`,
    PAGE: `PAGE_RINGRESOS`,
    ORDERING: `ORDERING_RINGRESOS`,
    SEARCH: `SEARCH_RINGRESOS`,
    REPORTE_DEUDA: `REPORTE_RINGRESOS`,
    EMPRESA_GENERAL: 'EMPRESA_GENERAL_RINGRESO',
    EMPRESA_DETALLE: 'EMPRESA_DETALLE_RINGRESO',
    ANIO: 'ANIO_RINGRESO',
    ANIO_GENERAL: 'ANIO_GENERAL_RINGRESO',
    MES: 'MES_RINGRESO',
    FECHA_INICIO: 'FECHA_INICIO_RINGRESO',
    FECHA_FINAL: 'FECHA_FINAL_RINGRESO',
    TOTALES_GENERAL: 'TOTALES_GENERAL_RINGRESO',
    TOTALES_DETALLE: 'TOTALES_DETALLE_RINGRESO',
    GRAFICA_INGRESO_ACTUAL: 'GRAFICA_INGRESO_ACTUAL_RINGRESO',
    GRAFICA_INGRESO_ANTERIOR: 'GRAFICA_INGRESO_ANTERIOR_RINGRESO',
    DATA_CATEGORIA: 'DATA_CATEGORIA_GASTOS',
    GRAFICA_GASTOS: 'GRAFICA_GASTO_RINGRESO',
    LISTADO_GASTOS_RINGRESO: 'LISTADO_GASTOS_RINGRESO',
    GRAFICA_RUBROS_GASTOS: 'GRAFICA_RUBROS_GASTOS'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().Reportes;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const listarCategoriasGasto = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().Reportes;
    const params = {};
    dispatch(setLoader(true));
    api.get(`tipo_gasto`, params).then((response) => {
        dispatch(setData(constants.LISTADO_GASTOS_RINGRESO ,response));
        dispatch(getGastosEstadistica())
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};


const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    api.get(`${url}/${id}`, params).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const getGastosEstadistica = id => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const params = {};
    const store = getStore()
    // const anio = store.r_ingreso_egreso.anio
    // const mes = store.r_ingreso_egreso.mes
    const empresa = store.r_ingreso_egreso.empresa_detalle

    const fecha_I = store.r_ingreso_egreso.fecha_inicio
    const fecha_F = store.r_ingreso_egreso.fecha_final

    if(empresa){
        params['empresa'] = empresa.id
    } if (fecha_I && fecha_F) {
        params.fecha_inicio = fecha_I.format("YYYY-MM-DD");
        params.fecha_final = fecha_F.format("YYYY-MM-DD");
    }
    api.get(`${url}/reporteGastosInternos`, params).then((response) => {
        dispatch({type: constants.GRAFICA_GASTOS, data: response});
        const array = [['Estado', 'Total']];
        if (!response.rubros_gastos.length) {
            array.push(['Sin data', 0]);
        }
        for (const item of response.rubros_gastos) {
            array.push([item.label, item.value]);
        }
        dispatch({type: constants.GRAFICA_RUBROS_GASTOS, data: array});
        dispatch()

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const reporteIngresosEgresos = id => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const params = {};
    const store = getStore()
    const anio = store.r_ingreso_egreso.anio_general
    const empresa = store.r_ingreso_egreso.empresa_general

    if(anio){
        params['anio'] = anio.value
    }
    if(empresa){
        params['empresa'] = empresa.id
    }
    api.get(`${url}/reporteGeneralIngresosInternos`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 2000)
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/createpv`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch((error) => {
        let mensaje = 'Error en la creación de Peticion de Materiales';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    data.fecha = moment(new Date(data.fecha)).format("YYYY-MM-D")
    data.fecha_prox_entrega = moment(new Date(data.fecha_prox_entrega)).format("YYYY-MM-D")
    api.put(`${url}/${id}/updatepv`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        dispatch(listPv(""));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().Reportes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
};


const set_empresa_general = empresa => (dispatch, getStore) => {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 2000)
    dispatch({type: constants.EMPRESA_GENERAL, data: empresa})
    dispatch(reporteIngresosEgresos())
}
const set_anio_general = anio => (dispatch, getStore) => {
    dispatch({type: constants.ANIO_GENERAL, data: anio})
    dispatch(reporteIngresosEgresos())
}

const set_empresa_detalle = empresa => (dispatch, getStore) => {
    dispatch({type: constants.EMPRESA_DETALLE, data: empresa})
    dispatch(getGastosEstadistica())
}
const set_anio = empresa => (dispatch, getStore) => {
    dispatch({type: constants.ANIO, data: empresa})
    dispatch(getGastosEstadistica())
}
const set_mes = empresa => (dispatch, getStore) => {
    dispatch({type: constants.MES, data: empresa})
    dispatch(getGastosEstadistica())
}
const set_fecha_inicio = (fecha, change=false) => (dispatch, getStore) => {
    dispatch({type: constants.FECHA_INICIO, data: fecha})
    if (change)
        dispatch(getGastosEstadistica())
}
const set_fecha_final = (fecha, change=false) => (dispatch, getStore) => {
    dispatch({type: constants.FECHA_FINAL, data: fecha})
    if (change)
        dispatch(getGastosEstadistica())
}

const resetFechas = () => (dispatch) => {
    console.log("reseteo")
    const fecha_inicio = moment().subtract(7, 'days');
    const fecha_final = moment();

    dispatch({type: constants.FECHA_INICIO, data: fecha_inicio})
    dispatch({type: constants.FECHA_FINAL, data: fecha_final})
}
const abrir_categoria = (value) => (dispatch, getStore) =>{
    const data = getStore().r_ingreso_egreso.listado_gastos
    data.forEach(categoria =>{
        if(value == categoria.value){
            categoria.seleccionado = !categoria.seleccionado
        }
        else{
            categoria.children.forEach(sub =>{
                if(value == sub.value){
                    sub.seleccionado = !sub.seleccionado
                }
                else{
                  sub.children.forEach(sub1 =>{
                    if(value == sub1.value){
                        sub1.seleccionado = !sub1.seleccionado
                    }
                    else{
                      sub1.children.forEach(sub12 =>{
                        if(value == sub12.value){
                            sub12.seleccionado = !sub12.seleccionado
                        }
                        else{
                          sub12.children.forEach(sub2 =>{
                            if(value == sub12.value){
                                sub12.seleccionado = !sub12.seleccionado
                            }
                          })  
                        }
                      })  
                    }
                  })  
                }
            })
        }
    })
    dispatch({type: constants.LISTADO_GASTOS_RINGRESO, data: data})
}
export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    clear,
    set_empresa_general,
    set_empresa_detalle,
    set_anio,
    set_mes,
    set_fecha_inicio,
    set_fecha_final,
    getGastosEstadistica,
    reporteIngresosEgresos,
    set_anio_general,
    listarCategoriasGasto,
    resetFechas,
    abrir_categoria
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.REPORTE_DEUDA]: (state, { data }) => {
        return {
            ...state,
            deudasEmpresas: data,
        };
    },
    [constants.PORCOBRAR]: (state, { data }) => {
        return {
            ...state,
            porCobrar: data,
        };
    },
    [constants.PORPAGAR]: (state, { data }) => {
        return {
            ...state,
            porPagar: data,
        };
    },
    [constants.EMPRESA_GENERAL]: (state, { data }) => {
        return {
            ...state,
            empresa_general: data,
        };
    },
    [constants.EMPRESA_DETALLE]: (state, { data }) => {
        return {
            ...state,
            empresa_detalle: data,
        };
    },
    [constants.ANIO]: (state, { data }) => {
        return {
            ...state,
            anio: data,
        };
    },
    [constants.MES]: (state, { data }) => {
        return {
            ...state,
            mes: data,
        };
    },
    [constants.TOTALES_GENERAL]: (state, { data }) => {
        return {
            ...state,
            totales_general: data,
        };
    },
    [constants.TOTALES_DETALLE]: (state, { data }) => {
        return {
            ...state,
            totales_detalle: data,
        };
    },
    [constants.GRAFICA_INGRESO_ACTUAL]: (state, { data }) => {
        return {
            ...state,
            grafica_ingreso_actual: data,
        };
    },
    [constants.GRAFICA_INGRESO_ANTERIOR]: (state, { data }) => {
        return {
            ...state,
            grafica_ingreso_anterior: data,
        };
    },
    [constants.DATA_CATEGORIA]: (state, { data }) => {
        return {
            ...state,
            data_categoria: data,
        };
    },
    [constants.GRAFICA_GASTOS]: (state, { data }) => {
        return {
            ...state,
            grafica_gastos: data,
        };
    },
    [constants.GRAFICA_RUBROS_GASTOS]: (state, { data }) => {
        return {
            ...state,
            grafica_rubros_gastos: data,
        };
    },
    [constants.FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [constants.FECHA_FINAL]: (state, { data }) => {
        return {
            ...state,
            fecha_final: data,
        };
    },
    [constants.ANIO_GENERAL]: (state, { data }) => {
        return {
            ...state,
            anio_general: data,
        };
    },
    [constants.ANIO_GENERAL]: (state, { data }) => {
        return {
            ...state,
            anio_general: data,
        };
    },
    [constants.LISTADO_GASTOS_RINGRESO]: (state, { data }) => {
        return {
            ...state,
            listado_gastos: data,
        };
    },
    [constants.REPORTE_DEUDA_CoP]: (state, { data }) => {
        return {
            ...state,
            deudasCoP: data,
        };
    },
};


export const initialState ={
    loader:false,
    data: {
        total_ingreso: 0,
        total_egreso: 0,
        ingreso_actual: [],
        ingreso_pasado: [],
        gastos_pasado: [],
        gastos_actual: [],
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    deudasEmpresas:[],
    porPagar:0,
    porCobrar:0,
    deudasCoP:[],
    empresa_general: null,
    empresa_detalle: null,
    anio: {value: moment().year(), label: moment().year()},
    anio_general: {value: moment().year(), label: moment().year()},
    mes: {value: new Date().getMonth() + 1, label: new Date().toLocaleDateString('default', {month:'long'})},
    totales_general: null,
    totales_detalle: null,
    grafica_ingreso_actual: null,
    grafica_ingreso_anterior: null,
    data_categoria: {results: [], count: 0},
    grafica_gastos: {
        total_ingresos: 0,
        total_gastos: 0,
        rubros_gastos:[]
    },
    grafica_rubros_gastos: [],
    fecha_inicio: moment().subtract(7, 'days'),
    fecha_final: moment(),
    listado_gastos: []
};

export default handleActions(reducers, initialState);


