import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import DespachoPlantaForm from './DespachoPlantaForm';
import moment from 'moment';
import { DespachoPlantaGrid } from '..';
import { Fragment } from 'react';


class DespachoPlantaCrear extends Component {

    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR DESPACHO PLANTA',
        count: false
    }

    componentWillMount(){
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR DESPACHO PLANTA'
            });
        }
    }

    componentWillUnmount(){
        //this.props.setVerProductos(false)
        this.props.resetFormulario();
    }

    onSubmit = (values) =>{
        const {crear, editar} = this.props;
        let id = this.props.match.params.id;
        let dataForm = _.cloneDeep(values);
        this.state.editar ? editar(id, dataForm) : crear(dataForm);
    }

    render() {
        const { loader, mostrarVista, count, setClienteSucursal} = this.props;

        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <DespachoPlantaForm
                        count={count}
                        setClienteSucursal={setClienteSucursal}
                        mostrarVista = {mostrarVista}
                        initialValues={{
                            tipo_cuenta:"true",
                            fecha_despacho: new Date()
                        }}
                        editar={this.state.editar}
                        onSubmit={this.onSubmit}
                        PDFDESPACHO={this.props.PDFDESPACHO}
                        permisos={this.props.permisos}
                        id_user={this.props.id_user}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default DespachoPlantaCrear;

