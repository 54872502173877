import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { RenderSchedule } from "../../../Utils/renderField/renderTableField";

export default class SucursalesGrid extends Component{
    componentWillMount() {
        let id=this.props.CPid
        this.props.selectSucursales(id);
    }
    render(){
        const { sucursales, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={sucursales} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} >
                    <TableHeaderColumn
                        dataField="encargado"
                        isKey
                        dataSort
                    >
                        ENCARGADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="direccion"
                        dataSort
                    >
                        DIRECCION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="telefono"
                        dataSort
                    >
                        TELEFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="horario_entrada"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderSchedule fecha_Entrada={row.horario_entrada} fecha_Salida={row.horario_salida} /> 
                            )
                        }
                    >
                       HORARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="latitud"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.foto_sucursal && <img src={row.foto_sucursal && row.foto_sucursal} width="100%" alt="foto" />}
                                    <p className="mb-0 text-black"> <label> Latitud: </label> {cell ? cell : 'Sin registro' }  </p>
                                    <p className="mb-0 text-black"> <label> Longitud: </label> {row.longitud ? row.longitud : 'Sin registro'} </p>
                                    {row.longitud && row.latitud &&
                                        <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${
                                            row.latitud},${row.longitud}`}
                                        >
                                            Ver en Maps
                                        </a>
                                    }
                                </div>
                            )}}
                    >
                        COORD.
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}