import React, { Component } from 'react'
import DespachoGrid from './despachogrid';
import { Link } from 'react-router-dom'
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

import HistoriaDespachosGrid from './historydespacho';

export default class DespachoContainer extends Component {
    state = {
        open_modal: false,
    }

    setOpenModal = (val) => {
        this.setState({
            open_modal: val,
        })

        if (val) {
            let data = { orden: this.props.orden.id }
            this.props.HistoryDispatch(data)
        }
    }

    finishIncoming = () => {
        Swal.fire({
            type: "warning",
            title: "Advertencia",
            text: 'Aún faltan productos por recibir, ¿Está seguro de finalizar la orden?',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: `Si`,
            cancelButtonText: `No`,
        }).then((result) => {
            if (result.value) {
                this.props.finishIncoming(this.props.orden.id)
            }
        })
    }

    setDespachar = () => {
        this.props.AutorizarDespacho(
            this.props.orden.id
        )
    }
    render() {
        const { cuentas, orden } = this.props;
        return (
            <div>
                <Modal open={this.state.open_modal} onClose={(e) => {
                    this.setOpenModal(false)
                }} center>
                    <div className="">
                        <h2 className="text-center text-primary text-uppercase">Historial de Ingresos</h2>
                    </div>
                    <HistoriaDespachosGrid
                        data={this.props.data}
                    />
                </Modal>
                <DespachoGrid
                    detalle_orden={orden.detalle_orden}
                />

                <div className="row  justify-content-sm-start justify-content-start">
                    <div className="col-12">
                        {
                            (!orden.entregado && orden.estado) && (
                                <Link className="btn btn-primary m-1 align-self-center" to={`/ingreso/ordencompra/${orden.id}`} >INGRESAR</Link>
                            )
                        }
                        <button className="btn btn-mostaza m-1 align-self-center" onClick={(e) => { this.setOpenModal(true) }}>HISTORIAL</button>
                        {/* <button className="btn btn-mostaza m-1 align-self-center">MARCAR DESPACHADA</button> */}
                        {
                            (!orden.entregado && orden.estado) && (
                                <button className="btn btn-red m-1 align-self-center" onClick={(e) => { this.finishIncoming() }}>FINALIZAR INGRESO</button>
                            )
                        }
                    </div>
                </div>

            </div>
        )
    }
}
