import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import { RenderNumber } from '../../../Utils/renderField/renderReadField';

import { TIPO_VEHICULO, TIPO_MAQUINARIA } from '../../../../../utility/constants';

import moment from 'moment';


export default class VehiculosGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos.vehiculos === false) {
            if(!!permisos.ver_vehiculos === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton="Agregar"
                    ruta="vehiculos_maquinaria/nuevo"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar"}
                    permisos={permisos.vehiculos}
                />}
                titulo="vehículos/maquinaria">
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {
                            (permisos.vehiculos) ? (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    isKey
                                    dataFormat={
                                        standardActions({ editar: "vehiculos_maquinaria", eliminar })
                                    }
                                />
                            ) : (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    width="2%"
                                    isKey
                                    dataFormat={cell => ""}
                                />
                            )
                        }
                        <TableHeaderColumn
                            dataField="fecha_compra"
                            dataSort
                            dataFormat={(cell)=> cell ? moment(cell).format("DD-MM-YYYY") : '' }
                        >
                            FECHA DE COMPRA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="placa"
                            dataSort
                        >
                            NO. PLACA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="modelo"
                        >
                            MODELO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="marca"
                        >
                            MARCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo"
                            dataFormat = {(cell, row)=> (
                                cell == TIPO_VEHICULO ? "Vehículo" : cell == TIPO_MAQUINARIA ? "Maquinaria" : "----"
                            )}
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="costo_compra"
                            dataAlign="right"
                            dataFormat={(cell) => <RenderMoneda monto={cell}/>}
                        >
                            COSTO
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="descripcion"
                        >
                            DESCRIPCIÓN
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
