import React, { Component } from 'react'
import Grid from '../../../../components/Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link } from 'react-router-dom';
import ImageVer from '../../../../../../assets/img/icons/Ver.png';

//Extras
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";


export default class HistorialLiquidaciones extends Component {
    componentWillMount() {
        const { listar, page, permisos } = this.props;
        listar(page);
    }
    render() {
        const { data, loader, listar: onPageChange, onSortChange, page, setOpenModal } = this.props;

        return (
           <Grid
                hover
                striped
                page={page}
                data={data}
                loading={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    dataSort
                    dataFormat={
                        standardActions({vermodal: setOpenModal})
                    }
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                >
                    No.
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    dataFormat = {
                        (cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="aprobado_por"
                    dataSort
                    dataFormat = {
                        (cell, row) => {
                            if (cell){
                                return <p>{cell.nombre.toUpperCase()}</p>
                            }
                        }
                    }
                >
                    APROBADO POR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="vendedor"
                    dataSort
                    dataFormat = {
                        (cell, row) => (
                            <p>{cell.nombre}</p>
                        )
                    }
                >
                    VENDEDOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="monto"
                    dataSort
                    dataFormat = {
                        (cell, row) => (
                            <RenderMoneda
                                monto={row.monto ? cell : 0}
                                simbolo={'Q. '}
                            />
                        )
                    }
                >
                    MONTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="estado_string"
                    dataSort
                    dataFormat = {
                        (cell, row) => (
                            <span>{cell.toUpperCase()}</span>
                        )
                    }
                >
                    ESTADO
                </TableHeaderColumn>
            </Grid>
        );
    }
}