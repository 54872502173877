import React from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {connect } from 'react-redux';

import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency, renderTextArea, AsyncSelectField, renderSelecttree, SelectField, renderFieldCheck } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import { TIPO_PUESTO } from '../../../../../utility/constants';
import { api } from '../../../../../utility/api';


let tipoproducto = [];

const getBonificaciones = (search) => {
    return api.get("bonificacion", { search, estate:'A' }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const renderBonficacionPuesto = ({fields, meta: {error, submitFailed }, getProductos, regiones, simbolo}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th style={{width: "40%"}}>BONIFICACION</th>
                        <th style={{width: "35%"}}>MONTO</th>
                        <th style={{width: "25%"}}>AFECTA AUSENCIAS</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((PET, index) => {
                        let transaccion = fields.get(index);
                        return (
                            <tr key={index} className="align-items-start" style={{height:"68px"}}>
                                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                        title="Borrar"
                                        onClick={() =>{
                                            fields.remove(index)
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top "  style={{width: "40%"}}>
                                    <Field
                                        name={`${PET}.bono`}
                                        component={AsyncSelectField}
                                        loadOptions={getBonificaciones}
                                        type="text"
                                        filterOptions= {false}
                                        valueKey="id"
                                        labelKey="nombre"
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                                <td className="sin-borde-top "  style={{width: "35%"}}>
                                    <Field
                                        name={`${PET}.valor`}
                                        label="monto"
                                        component={renderCurrency}
                                        parse={cell => parseFloat(cell)}
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                                <td className="sin-borde-top "  style={{width: "25%"}}>
                                    <Field
                                        name={`${PET}.es_afectado`}
                                        label="Afecta"
                                        component={renderFieldCheck}
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                            </tr>
                        );
                    }
                    )}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({es_afectado:false})}>
                    AGREGAR
                </button>
            </div>
        </div>
    </div>
)

const PuestosForm = (props) => {
    const { handleSubmit, tipo_gastos } = props;
    return (
        <form name="PuestosForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre del Puesto*</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Sueldo Base</label>
                    <Field
                    name="sueldo"
                    label="sueldo"
                    component={renderCurrency}
                    parse={cell => parseFloat(cell)}
                    type="text"
                    className="form-control"
                    />
                </div>
            </div>
            <div className="row justify-content-start">
                <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Tipo de gasto Sueldo</label>
                    <Field
                        name="Gasto_sueldo"
                        label="nombre"
                        component={renderSelecttree}
                        showSearch = { true }
                        filtro = "title"
                        options={tipo_gastos}
                        type="text"
                        valueKey="value"
                        labelKey="label"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                    <label className="t-azul" htmlFor="tipo">Tipo de Puesto</label>
                    <Field
                        name="tipo"
                        valueKey="value"
                        labelKey="label"
                        options={TIPO_PUESTO}
                        component={SelectField}
                    />
                </div>
            </div>
            <div className="row justify-content-start">
                <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Bono por Horas extras</label>
                    <Field
                        name="bono_horas_extras"
                        valueKey="id"
                        labelKey="nombre"
                        loadOptions={getBonificaciones}
                        component={AsyncSelectField}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-12">
                    <label className="t-azul"  htmlFor="producto">Descripción</label>
                    <Field name="descripcion" label="descripcion" component={renderTextArea} type="text" className="form-control" top={{top:"98px"}}/>
                </div>
            </div>
            <div className="row justify-content-start">
                <div className="form-group has-feedback  col-md-8 col-sm-12 col-12">
                    <FieldArray
                        name="bonificaciones"
                        label="bonificaciones"
                        component={renderBonficacionPuesto}
                        className="form-control"
                        regiones={props.regiones}
                        simbolo={'Q.'}
                    />
                </div>
            </div>
            <br/>
            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/puestos" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'PuestosForm', // a unique identifier for this form
    touchOnChange: true,
    validate: (data, props) => {
        const errors = {}
        if (!data.nombre){
            errors.nombre = "Campo requerido"
        }

        if (!data.sueldo){
            errors.sueldo = "Campo requerido";
        }else{
            if(parseFloat(data.sueldo) < 0){
                errors.sueldo = "Debe ser mayor o igual a 0";
            }
        }
        if (!data.tipo){
            errors.tipo = "Campo requerido"
        }
        if (!data.descripcion){
            errors.descripcion = "Campo requerido"
        }
        if (!data.Gasto_sueldo){
            errors.Gasto_sueldo = "Campo requerido"
        }
        if (!data.bono_horas_extras){
            errors.bono_horas_extras = "Campo requerido"
        }

        if (!data.bonificaciones || !data.bonificaciones.length){
            // errors.bonificaciones = {_error: 'No se puede generar una planilla vacia'}
        }else{
            const detalleArray = []
            data.bonificaciones.forEach(function(bono, index){
                let detErrors = {}
                let repetidos = []
                if( bono.bono ){
                    repetidos = _.filter(data.bonificaciones, (x)=>{
                        if (x.bono)
                            return x.bono.id == bono.bono.id
                        return false;
                    });
                }
                if(!bono.bono){
                    detErrors.bono = "Campo requerido";
                    detErrors.touched = true;
                }else{
                    if(1 < repetidos.length){
                        detErrors.bono = "Este bono esta repetido"
                        detErrors.touched = true;
                    }
                    if( bono.bono.es_bonificacion && bono.valor < 0 ){
                        detErrors.valor = "el valor debe ser mayor a 0"
                        // detErrors.touched = true;
                    }
                    if( !bono.bono.es_bonificacion && bono.valor > 0 ){
                        detErrors.valor = "el valor debe ser menor a 0"
                    }
                }
                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            })
            if(detalleArray.length){
                errors.bonificaciones = detalleArray
            }
        }
        console.log(errors)
        return errors;
    },
})(PuestosForm);
