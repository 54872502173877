import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import ProductosGrid from './productoGrid';
import FacturasGrid from './facturasGrid'
// import TransporteDetalle from './transporteDetalle';
// import GastoGrid from './Gastos/gastosGrid';
import PagosContainer from './Pagos/pagocContainer';
import DespachoContainer from './Despacho/despachoContainer';
// import ReintegroGrid from './Reintegros/reintegro';
// import OCpreview from './previewOC/OCpreview';

import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { getSimbolo} from "../../../Utils/renderField/renderTableField";

import {TIPO_ENTREGA} from "../../../../../utility/constants";
import LoadMask from "../../../Utils/LoadMask/LoadMask";



export default class DetalleOC extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        this.props.clear()
        let id = this.props.match.params.id
        this.props.leerOrdenCompra(id);


    }
    getEntrega =(valor)=>{
        let entrega =''
        TIPO_ENTREGA.forEach((item,index)=>{
            if (item.value === valor){
                entrega = item.label
            }
        })
        return entrega
    }
    render() {
        const { item, data, loader, listar: onPageChange, onSortChange, eliminar,cuentas, total_detalle, permisos } = this.props;
        let CPid = this.props.match.params.id
        return (
            <div className="Container bg-white">
                <LoadMask loading={loader} light>
                    {
                        item && (
                            <div className="m-t p-t fnt-size">
                            <div className="row m-none" style={{paddingTop:"15px"}}>
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-primary m-none">{ item ? item.no_orden:""}</h3>
                                </div>
                            </div>

                            <br/>
                            {/* DATOS GENERALES */}
                            <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Emitida por: </span><br/>
                                    {
                                        item.emitido_por && (
                                            <span className="m-none t-musgo semibold">{`${item.emitido_por.first_name} ${item.emitido_por.last_name}`}</span>
                                        )
                                    }
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Empresa origen: </span><br/>
                                    {
                                        item.empresa_origen && (
                                            <span className="m-none t-musgo semibold">{`${item.empresa_origen.nombre}`}</span>
                                        )
                                    }
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Empresa destino: </span><br/>
                                    {
                                        item.empresa_destino && (
                                            <span className="m-none t-musgo semibold">{`${item.empresa_destino.nombre}`}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <br/>{/* DESCRIPCION */}
                            <div className="col-12">
                                <span className="m-none t-primary bold" >Descripción</span>
                            </div>
                            <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"0px 15px 10px 15px"}}/>
                            <div className="row m-none">
                                <div className="col-12">
                                    <span className="m-none t-musgo semibold">{`${item.descripcion}`}</span>
                                </div>
                            </div>
                            <br/> {/*CONDICIONES DE ENTREGA */}
                            <div className="col-12">
                                <span className="m-none t-primary bold" >Fecha: </span>
                            </div>
                            <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"0px 15px 10px 15px"}}/>
                            <div className="row m-none">
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha: </span><br/>
                                    <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.fecha} ></RenderDateTime></span>
                                </div>

                            </div>
                            <br/> {/*CONDICIONES COMERCIALES */}

                            <div className="row m-none">
                                {
                                    item.documento &&(
                                        <div className="col-6">
                                            <a target="_blank" href={item.documento} className="btn btn-azul-claro align-self-center">VER DOCUMENTO</a>
                                        </div>
                                    )
                                }
                                {
                                    !item.a_produccion && (
                                        <div className="col-6">
                                            <button className="btn btn-primary align-self-center" 
                                                onClick={ e => {
                                                    this.props.AutorizarProduccion(item.id);
                                                    }
                                                }
                                            >
                                                AUTORIZAR PRODUCCION
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                            <br/> {/*  */}
                            <div className="row">
                                <div className="mb-4 col-12">
                                    <div className="mb-4 ">
                                        <div className=""><h6 className="m-0"></h6></div>
                                        <div className="p-0 px-3 pt-3 p-b">
                                        <Tabs
                                            defaultActiveKey="DETALLEOV"
                                            tabBarPoition = "top"
                                            onchange={this.callback}
                                            renderTabBar ={()=><ScrollableInkTabBar/>}
                                            renderTabContent={()=><TabContent/>}

                                        >
                                        {
                                            permisos.ingresos_sim &&
                                            <TabPane tab="PRODUCTOS" key="PRODUCTOS">
                                                <ProductosGrid
                                                    detalle_orden={item.detalle_orden}
                                                    moneda={item.moneda}
                                                    total_detalle={total_detalle}
                                                    permiso={permisos.ingresos_sim}
                                                />
                                            </TabPane>
                                        }
                                           {item.detalle_orden && item.detalle_orden.length && ( <TabPane tab="DESPACHO" key="DESPACHO">
                                                <DespachoContainer
                                                    data={this.props.history}
                                                    HistoryDispatch={this.props.HistoryDispatch}
                                                    AutorizarDespacho={this.props.AutorizarDespacho}
                                                    orden={item}
                                                    permiso={permisos.ingresos_sim}
                                                />
                                            </TabPane>)}
                                        </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        )
                    }

                </LoadMask>
            </div>

        )
    }
}

