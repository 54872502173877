import React, { Component } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";


//manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

//Toolbar
import Toolbar from '../../../Utils/Toolbar/ToolbarReporteIngreso';
import GeneralReporte from './general';
import DetalleReporte from './detalle';

import d3 from 'd3';

export default class IngresoEgresoReporte extends Component {
    componentWillUnmount(){
        setTimeout(() => {
            d3.selectAll('.nvtooltip').style('opacity', 0);
        }, 100);
    }

    render() {
        const { deudasEmpresas, data, loader, open_modal, permisos } = this.props;
        if(permisos["ingresoegreso"] === false) {
            return <Redirect to={`/`} />
        } 
        return (
            <CardSimple
                titulo="INGRESOS Y GASTOS">
                <div className="row">
                            <div className="mb-4 col-12 m-0 p-0">
                                <div className="mb-4 ">
                                    <div className=""><h6 className="m-0"></h6></div>
                                    <div className="p-0 px-3 pt-3 p-b">
                                    <Tabs
                                        defaultActiveKey="DETALLEOC"
                                        tabBarPoition = "top"
                                        onchange={this.callback}
                                        renderTabBar ={()=><ScrollableInkTabBar/>}
                                        renderTabContent={()=><TabContent/>}
                                    >
                                        <TabPane tab="GENERAL" key="GENERAL">
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <GeneralReporte {...this.props} />
                                            </LoadMask>
                                        </TabPane>
                                        <TabPane tab="DETALLE " key="DETALLE">
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <DetalleReporte {...this.props}/>
                                            </LoadMask>
                                        </TabPane>
                                    </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>

            </CardSimple>
        );
    }
}
