import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";

// Manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import moment from 'moment';

import ToolbarReporteVehiculos from '../../../Utils/Toolbar/ToolbarReporteVehiculos';
import GridOrdenes from './GridOrdenes';
import GridGastos from './GridGastos';
import GridRepuestos from './GridRepuestos';
import { Redirect } from 'react-router';

export default class ReporteVehiculos extends Component {

    componentDidMount(){
        this.props.getResumen();
    }

    render() {
        const { loader, resumen, permisos } = this.props;
        if(permisos.reporte_vehiculos === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="MAQUINARIA/VEHÍCULOS">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReporteVehiculos {...this.props} />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-12 d-flex justify-content-center" >
                            <div >
                                <br/>
                                <h3 className="t-musgo">TOTAL DE GASTOS</h3>
                                <h3 className="t-primary m-0 text-center">
                                    <RenderMoneda monto={resumen != null ? resumen.total : 0}/>
                                </h3>
                                <br/>
                            </div>
                        </div>
                    </div>
                    {
                        (resumen && resumen.km_actual !=null && resumen.km_actual != undefined) && (
                            <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3"
                                style={{border:"1px solid #E8E8E8", }}>
                                <div className="col-md-6 col-12  d-flex justify-content-center" >
                                    <div>
                                        <br/>
                                        <h4 className="t-musgo">KILOMETRAJE ACTUAL</h4>
                                        <h4 className="t-mostaza m-0 text-center bold">
                                            {`${resumen.km_actual} km.`}
                                        </h4>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 d-flex justify-content-center">
                                    <div>
                                        <br/>
                                        <h4 className="t-musgo">PROXIMO CAMBIO DE ACEITE</h4>
                                        <h4 className="t-mostaza m-0 text-center bold">
                                            {`${resumen.cambio_aceite} km.`}
                                        </h4>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (resumen && resumen.fecha_servicio !=null && resumen.fecha_servicio != undefined) && (
                            <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3"
                                style={{border:"1px solid #E8E8E8", }}>
                                <div className="col-12  d-flex justify-content-center" >
                                    <div>
                                        <br/>
                                        <h4 className="t-musgo">PROXIMA FECHA DE SERVICIO</h4>
                                        <h4 className="t-mostaza m-0 text-center bold">
                                            {moment(resumen.fecha_servicio).format("L")}
                                        </h4>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="mt-3">
                        <Tabs
                            defaultActiveKey="1"
                            tabBarPoition = "top"
                            renderTabBar ={()=><ScrollableInkTabBar/>}
                            renderTabContent={()=><TabContent/>}
                        >
                            <TabPane tab="ORDENES DE COMPRA" key="1">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridOrdenes {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tab="GASTOS" key="2">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridGastos {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tab="REPUESTOS" key="3">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridRepuestos {...this.props} />
                                </LoadMask>
                            </TabPane>
                        </Tabs>
                    </div>
                </LoadMask>

            </CardSimple>
        )

    }
}
