import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency, AsyncSelectField  } from '../../../Utils/renderField';
import { SelectField } from '../../../Utils/renderField/renderField.js'
import { departamentos } from '../../../../../utility/constants';
import { Link } from 'react-router-dom';
import {api} from 'api';

const getVendedores = (search) => {
    return api.get("vendedores", { search, is_active:true}).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const ClienteForm = (props) => {
    const { handleSubmit, municipios, es_cliente } = props;
    return (
        <form name="ClienteForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >{props.titulo}</h4>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-8 col-md-12  col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre*</label>
                    <Field name="nombre" label="nombre" type="text" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="telefono">Teléfono</label>
                    <Field name="telefono" label="telefono" component={renderField} className="form-control" />
                </div>
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="correo">Correo</label>
                    <Field name="correo" label="correo" component={renderField} className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="dia_credito">Días de Crédito</label>
                    <Field
                        name="dia_credito"
                        type="number"
                        component={renderField}
                    />
                </div>
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="encargado">Nombre del Encargado</label>
                    <Field name="encargado" label="encargado" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            {
                (es_cliente == 2 ) && (
                    <div className="row justify-content-center">
                        <div className={`form-group has-feedback ${"col-lg-8 col-12"}`}>
                            <label className="t-azul"  htmlFor="limite_credito">Limite de Crédito</label>
                            <Field
                                name="limite_credito"
                                label="limite_credito"
                                component={renderCurrency}
                                type="number"
                                className="form-control"
                            />
                        </div>
                    </div>
                )
            }

            {
                (es_cliente ==2) && (
                    <div className="row justify-content-center">
                        <div className={`form-group has-feedback ${ es_cliente==2 ? "col-lg-4 col-md-6  col-12":"col-lg-8 col-12"}`}>
                            <label className="t-azul"  htmlFor="region">Región*</label>
                            <Field
                                name="region"
                                label="region"
                                component={SelectField}
                                options={props.regiones}
                                valueKey={"id"}
                                labelKey={"region"}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                            <label className="t-azul" htmlFor="nombre">Vendedor*</label>
                            <Field
                                isClearable={true}
                                name="vendedor"
                                label="vendedor"
                                type="text"
                                component={AsyncSelectField}
                                loadOptions={getVendedores}
                                valueKey="id"
                                labelKey="first_name"
                                className="form-control"
                            />
                        </div>
                    </div>
                )
            }
            {
                (es_cliente == 2) &&(
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                        <label className="t-azul"  htmlFor="correo">Departamento*</label>
                        <Field
                            name="departamento"
                            label="departamento"
                            component={SelectField}
                            options={departamentos}
                            className="form-control"
                            onChange={
                                (e)=>{
                                    props.getMunicipios(`${e[0]}${e[1]}`)
                                }
                            }
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                        <label className="t-azul"  htmlFor="municipios">Municipio*</label>
                        <Field
                            name="municipios"
                            label="municipios"
                            component={SelectField}
                            options={municipios}
                            className="form-control"
                        />
                    </div>
                </div>)
            }
            {/* {
                !props.editar && (
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-lg-4 col-md-6 col-12">
                            <label className="t-azul"  htmlFor="cuenta_inicial">{es_cliente == 2? "Cuenta por Cobrar":"Cuenta por Pagar"}</label>
                            <Field name="cuenta_inicial" label="cuenta_inicial" component={renderCurrency} className="form-control" />
                        </div>
                        <div className="form-group has-feedback col-lg-4 col-md-6 col-12">
                        </div>
                    </div>
                )
            } */}
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-8 col-md-12  col-12">
                    <label className="t-azul"  htmlFor="descripcion">Comentario</label>
                    <Field name="descripcion" label="descripcion" component={renderTextArea} className="form-control" />
                </div>
            </div>


            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary  align-self-center" to={props.route} >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            direccion: validators.exists()('Este campo es requerido'),
            region: validators.exists()('Este campo es requerido'),
            vendedor: validators.exists()('Este campo es requerido'),
            departamento: validators.exists()('Este campo es requerido'),
            municipios: validators.exists()('Este campo es requerido'),
            limite_credito: validators.exists()('Este campo es requerido'),

        });
    },
})(ClienteForm);
