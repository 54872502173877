import React, { Component } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";
import {AsyncSelectField} from '../../../Utils/renderField';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import ToolbarReporteCuentas from '../../../Utils/Toolbar/ToolbarReporteCuentas'
// grid expandible
import DetalleCuenta from './detallecuenta';

import flechaA from  '../../../../../../assets/img/icons/flecha_amarilla.png';
import flechaD from  '../../../../../../assets/img/icons/flecha_azul.png';
const CuentasproC = require('../../../../../../assets/img/icons/Cuentas.png');


export default class CuentasPorPoC extends Component {
    state = {
        es_cliente:this.props.es_cliente,
    }
    handleEstado = (value = true) =>{
        console.log("Entro aca")
        this.setState({es_cliente: value})
        this.props.cuentasporCOP({
            clienteproveedor: null,
            vendedor: null,
            estado: 1,
            moneda: this.props.moneda,
            tipo_cambio_dolar:  0.13,
            tipo_cambio_euro:  0.12,
        }, value, this.props.page)
    }
    pageChange = (page) => {
        console.log("Pinche pagina ", page);
        this.props.cuentasporCOP({
            clienteproveedor: null,
            vendedor: null,
            estado: 1,
            moneda: this.props.moneda,
            tipo_cambio_dolar:  0.13,
            tipo_cambio_euro:  0.12,
        }, this.state.es_cliente, page)
    }
    handleDireccion = (direccion) =>{
        if (direccion == '/cuentas_por_cobrar')
            this.handleEstado(true)
        else
            this.handleEstado(false)

    }
    componentDidMount(){
        // console.log(this.props.match.url)
        this.props.changeFechaPagar(null, 1);
        this.props.changeEmpresa(null);
        this.handleDireccion(this.props.match.url)
    }
    componentDidUpdate(prevProps){
        // console.log(this.props.match.url)
        if (this.props.match.path != prevProps.match.path){
            this.handleDireccion(this.props.match.url)
        }
    }
    isExpandableRow(row) {
        if (row.saldo_vigencia.total != 0 ) return true;
        else return false;
    }
    expandComponent(row, es_cliente) {
        return (
          <DetalleCuenta data={ row.saldo_vigencia.ordenes } moneda={row.moneda} es_cliente={es_cliente} />
        );
      }
    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? flechaA : flechaD );
        } else {
            content = ' ';
        }
        return (
            <div>
                <img className="Cuentas-img" src={ content }></img>
            </div>
        );
    }
    botonImprimirordenes = (id, row) => {
        console.log(row, "hola")
        if (row.saldo_vigencia.total == 0 || this.state.es_cliente == false ) {
            return (<div></div>)
        }
        return (
            <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    console.log(row);
                    this.props.ImprimirOrdenes(row.id_clipro, row.id)
                }}
            >
                <img
                    // className="action-img"
                    style={{width:"19px", height:"19px"}}
                    title="Imprimir Ordenes"
                    src={CuentasproC} alt="Ver eliminado" />
            </a>
        )
    }
    render() {
        const { deudasCoP, page, data, loader, open_modal, permisos, totalizado, variables_busqueda } = this.props;
        if(permisos["cuentasp"] === false && permisos["cuentasc"] === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple
                titulo={ this.state.es_cliente? "CUENTAS POR COBRAR":"CUENTAS POR PAGAR"} margen={' '}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3" style={{border:"1px solid #E8E8E8", }}>
                            <div className="row col-md-9 col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}} >
                                <ToolbarReporteCuentas
                                    es_cliente={this.state.es_cliente}
                                    valorEsetado={{value: 1, label:"Todos"}}
                                    valorMoneda={{value: 'GTQ',label:"GTQ"}}
                                    cuentasporCOP={this.props.cuentasporCOP}
                                    isClearableCliPro={true}
                                    vistaOrdenes={false}
                                    changeFechaPagar={this.props.changeFechaPagar}
                                    fecha_I={this.props.fecha_I}
                                    fecha_F={this.props.fecha_F}
                                    changeEmpresa={this.props.changeEmpresa}
                                    empresa={this.props.empresa}
                                    permisos={permisos}
                                    id_user={this.props.id_user}
                                    changeVendedor={this.props.changeVendedor}
                                    changeMoneda={this.props.changeMoneda}
                                />
                            </div>
                            <div className="col-md-3 col-12 m-0 p-0 inline-headers " style={{borderLeft:"1px solid #E8E8E8"}}>
                                <div style={{padding:"10px 20px"}} className="text-center">
                                    <label className=""> ESTADOS: </label>
                                    <div className="text-left align-self-center">
                                        <label className="d-flex align-items-center"><span className="estado-reporte-2"></span><span className="t-musgo">Vigente</span></label>
                                        <label className="d-flex align-items-center"><span className="estado-reporte-3"></span><span className="t-musgo">Por vencer</span></label>
                                        <label className="d-flex align-items-center"><span className="estado-reporte-4"></span><span className="t-musgo">Vencido</span></label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                    style={{border:"1px solid #E8E8E8", }}
                    >
                        <div className="col-md-4 col-12 d-flex justify-content-center" >
                            <div >
                                <br/>
                                <h4 className="t-musgo">Total Pendiente</h4>
                                <h4 className="t-primary m-0"><RenderMoneda monto={totalizado.total} simbolo={getsimbolo(variables_busqueda.moneda)}/></h4>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3"
                    style={{border:"1px solid #E8E8E8", }}
                    >
                        <div className="col-md-4 col-12  d-flex justify-content-center" >
                            <div
                            // style={{borderRight:"1px solid #E8E8E8"}}
                            >
                                <br/>
                                <h5 className="t-musgo">Total Vigente</h5>
                                <h5 className="t-verde m-0"><RenderMoneda monto={totalizado.total_vigente} simbolo={getsimbolo(variables_busqueda.moneda)}/></h5>
                                <br/>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 d-flex justify-content-center">
                            <div
                            // style={{borderRight:"1px solid #E8E8E8"}}
                            >
                                <br/>
                                <h5 className="t-musgo">Total por Vencer</h5>
                                <h5 className="t-mostaza m-0"><RenderMoneda monto={totalizado.total_porvencer} simbolo={getsimbolo(variables_busqueda.moneda)}/></h5>
                                <br/>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 d-flex justify-content-center">
                            <div>
                                <br/>
                                <h5 className="t-musgo">Total de Vencidos</h5>
                                <h5 className="t-corinto m-0"><RenderMoneda monto={totalizado.total_vencido} simbolo={getsimbolo(variables_busqueda.moneda)}/></h5>
                                <br/>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Grid hover striped data={deudasCoP} loading={loader} page={page}
                            // expandableRow={ this.isExpandableRow }
                            // expandComponent={ (row) => ( this.expandComponent(row, this.props.es_cliente) ) }
                            // expandColumnOptions={ {
                            //     expandColumnVisible: true,
                            //     expandColumnComponent: this.expandColumnComponent,
                            //     columnWidth: 30
                            // } }
                            className="reporte-table"
                            onPageChange={this.pageChange}
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataSort
                                className='reporte-table'
                                dataFormat={
                                    standardActions({
                                        historialOrdenes:`${this.state.es_cliente? "/ordenes_por_cobrar":"/ordenes_por_pagar"}`,
                                        historialCuenta:"/reporte_deuda_cliente_proveedor",
                                        adicional2: this.botonImprimirordenes,
                                    })
                                }
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre_origen"
                                dataSort
                                className='reporte-table'
                            >
                                {this.state.es_cliente? `CLIENTE`:`PROVEEDOR`}
                            </TableHeaderColumn>
                            {
                                this.state.es_cliente && (
                                    <TableHeaderColumn
                                        dataField="limite_credito"
                                        dataSort
                                        className='reporte-table'
                                        dataAlign="right"
                                        dataFormat={(cell, row) =>(
                                            <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />
                                        )}
                                    >
                                        LIMITE DE CRÉDITO
                                    </TableHeaderColumn>
                                )
                            }
                            <TableHeaderColumn
                                dataField="saldo_vigencia"
                                dataAlign="right"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <RenderMoneda monto={Math.abs(cell.total)} simbolo={getsimbolo(row.moneda)} />
                                    )
                                }
                                className='reporte-table'
                            >
                                TOTAL
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="saldo_vigencia"
                                dataAlign="right"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <RenderMoneda monto={cell.pendiente} simbolo={getsimbolo(row.moneda)} />
                                        )
                                    }
                                    className='reporte-table'
                            >
                                PENDIENTE
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ultimo_afectado"
                                dataAlign="right"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <span style={{color:"#1B6197", fontWeight:"bold"}}>
                                            <RenderMoneda monto={Number.parseFloat(`${cell.saldo}`).toFixed(2)} simbolo={getsimbolo(row.moneda)} />
                                        </span>
                                    )
                                }
                                className='reporte-table-special'
                            >
                                SALDO
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>
            </CardSimple>
        );
    }
}
