import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderTextArea,
} from "../../../../Utils/renderField/renderField";

const RechazoForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="rechazoOCform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">                
                <div className="form-group has-feedback col-md-10 col-12">
                    <Field 
                        name="descripcionRechazo" 
                        label="descripcionRechazo" 
                        component={renderTextArea} 
                        top ={{top:"70px"}}
                        type="text" 
                        className="form-control" />
                </div>
            </div>
            
            <div className="row  justify-content-sm-around justify-content-center">
                <button onClick={()=>{props.handleModal(false)}} type="button" className="btn btn-secondary m-1 align-self-center">CANCELAR</button>                
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'rechazoOCform', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            descripcionRechazo: validators.exists()('Este campo es requerido'),
        });
    },
})(RechazoForm);
