import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import {standardActions} from '../../../Utils/Grid/StandardActions';
import moment from 'moment';

export default class GridNoAsignados extends Component {
    componentDidMount() {
        this.props.getDespachosNoAsignados();
    }

    render() {
        const { page, data, loader, getDespachosNoAsignados} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={getDespachosNoAsignados}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        className='reporte-table'
                        width="8%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="fecha"
                        className='reporte-table'
                        dataFormat={(cell) => moment(cell).format("DD/MM/YYYY")}
                    >
                        FECHA DE DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_despacho"
                        className='reporte-table'
                    >
                        NO. DE DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="orden"
                        className='reporte-table'
                    >
                        NO. DE ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente_orden"
                        className='reporte-table'
                        dataFormat={(cell) => cell ? cell.nombre : ""}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
