import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/ingreso_egreso_interno';
import IngresoEgresoInternoReporte from './ingreso_egreso_interno';

const mstp = state => {
    return {...state.r_ingreso_egreso_interno, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(IngresoEgresoInternoReporte)
