import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/cajachica/caja';
import DetalleCaja from './detalleCaja';

//state.login.me.profile.role
const mstp = state => {
    return {
        ...state.caja,
        entidad: state.login.me.entidad,
        role: 1,
        permisos: state.login.me.permisos,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetalleCaja)
