import { connect } from 'react-redux';
// import {
//     actions
// } from '../../../../../redux/modules/OrdenProduccion/ordenproduccion';
import {
    actions
} from '../../../../../redux/modules/OrdenVenta/ordenventa';
import {
    actions as actions2
} from '../../../../../redux/modules/OrdenProduccion/ordenproduccion';
import ListaordenesVenta from './ListaordenesVenta';

const mstp = state => {
    return {
        ...state.ordenventa,
        produccion: state.produccion,
        permisos: state.login.me.permisos,

    }
};

const mdtp = {
    ...actions,
    setEmpresa: actions2.setEmpresa
};

export default connect(mstp, mdtp)(ListaordenesVenta)