import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "pilotos",
    "pilotos",
    "EditarUsuarioForm",
    "/pilotos",
);

export default handleActions(reducers, initialState);
