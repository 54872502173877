import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions, customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimpleOrdenes from '../../../Utils/Toolbar/ToolbarSimpleOrdenes';
import { PROCESOS } from '../../../../../utility/constants'
import CardPvEnviadas from '../../../Utils/Cards/cardsPM';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { Card } from 'antd';
import { getsimbolo } from '../../../../../utility/constants';
import { Redirect } from 'react-router-dom';
import FiltrosOrdenVenta from './filtrosOrdeVenta';

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}

export default class OrdenVentaGrid extends Component {
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            permisos,
            entregadoChange,
            pagoChange,
            estadoChange,
            estado,
        } = this.props;
        if (permisos["ordenventa"] === false) {
            return <Redirect to={`/`} />;
        }
        return (
            <CardSimple
                titulo="Ordenes de venta"
                margen=" "
                toolbar={
                    <ToolbarSimpleOrdenes
                        // textBoton="Agregar"
                        ruta="/peticionesventa/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Orden"}
                        estatefilterBar={true}
                        filterbar={
                            <FiltrosOrdenVenta
                                valorEntrega={this.props.entregado}
                                valorPagado={this.props.pago_completo}
                                changeEntrega={entregadoChange}
                                changePago={pagoChange}
                                changeEstado={estadoChange}
                                valorEstado={estado}
                            />
                        }
                    />
                }
            >
                <br />
                <Grid
                    page={this.props.page}
                    hover
                    striped
                    data={data}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={(cell, row) => {
                            return customStandardActions(cell, {
                                adicional: (c, r) => {
                                    return row.entregado | permisos.visualizar_mis_datos ? <div style={{ width: '3.1rem' }}></div> : ""
                                },
                                eliminar: row.entregado | !row.estado | permisos.visualizar_mis_datos ? undefined : eliminar,
                                verpro: "ordenesventa",
                                editar: !row.entregado && row.estado && !permisos.visualizar_mis_datos  ? "ordenesventa": undefined,
                            });
                        }}
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={(cell, row) => {
                            return <RenderDateTime fecha={cell} />;
                        }}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="no_orden" dataSort tdStyle={cellTachado}>
                        NO. ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={(cell, row) => (cell ? cell.nombre : "--")}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={(cell, row) => (
                            <RenderMoneda
                                monto={row.monto ? cell : 0}
                                simbolo={getsimbolo(row.moneda)}
                            />
                        )}
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pago_completo"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={(cell, row) => {
                            if(row.cerrado){
                                return (
                                    <span className="t-mostaza font-weight-bold">
                                        Cerrado
                                    </span>
                                );
                            }
                            else if (cell) {
                                return (
                                    <span className="t-verde font-weight-bold">
                                        Pagada
                                    </span>
                                );
                            } else {
                                return (
                                    <span className="t-corinto font-weight-bold">
                                        Pendiente
                                    </span>
                                );
                            }
                        }}
                    >
                        PAGADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="entregado"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={(cell, row) => {
                            if(row.cerrado){
                                return (
                                    <span className="t-mostaza font-weight-bold">
                                        Cerrado
                                    </span>
                                );
                            }
                            else if (row.devuelto) {
                                return (
                                    <span className="t-mostaza font-weight-bold">
                                        Devuelto
                                    </span>
                                );
                            }
                            else if (cell) {
                                return (
                                    <span className="t-verde font-weight-bold">
                                        Entregada
                                    </span>
                                );
                            } else {
                                return (
                                    <span className="t-corinto font-weight-bold">
                                        Pendiente
                                    </span>
                                );
                            }
                        }}
                    >
                        DESPACHADO
                    </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
