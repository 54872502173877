import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardPMEnviadas from '../../../Utils/Cards/cardsPM'
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { Redirect } from 'react-router-dom';

//Extras
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { getsimbolo } from '../../../../../utility/constants';
import Swal from 'sweetalert2';

import ItinerarioForm from './itinerarioForm';
import ListaProductos from './listaProductos';
import Modal from 'react-responsive-modal';
import { set } from 'lodash';


export default class CrearItinerario extends Component {

    state = {
        editar: false,
        titulo: "CREAR RUTA",
        verProductos: false,
        idOC: undefined,
        detalle_productos: [],
        mostrar_estado: false,
    }

    componentDidMount(){
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leerItinerario(this.props.match.params.id, this.props.entra_editar);
            this.setState({
                editar:true,
                titulo: 'EDITAR ITINERARIO'
            });

        }
    }

    componentWillUnmount(){
        //this.props.mantener_datos()
        //this.props.resetFormulario();
    }

    onSubmit = (values) =>{
        const {crear, editar} = this.props;
        let id = this.props.match.params.id;
        let dataForm = _.cloneDeep(values);
        dataForm.piloto  = dataForm.piloto.id
        this.state.editar ? editar(id, dataForm) : crear(dataForm);
    }

    getDetalleOrden = (orden, index) => {
        this.props.setOrdenIndex(orden, index);
    }

    verProductos = (index) => {
        this.setState({
            titulo: "LISTADO DE PRODUCTOS",
        });
        this.props.setVerProductos(true, index)
    }

    cancelar = () => {
        this.setState({
            titulo: this.state.editar ? 'EDITAR ITINERARIO' : 'NUEVA RUTA',
        })
        this.props.setVerProductos(false)
    }

    onSubmitProductos = (data) => {
        this.props.onSubmitProductos(data);
    }

    borrarProductos = (index) => {
        this.props.borrarProductos(index);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, open_modal, page, permisos,
            entregadoChange, pagoChange, PDFITINERARIO, mostrarVista, setClienteSucursal, count} = this.props;
        if(permisos["itinerarios"] === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple
                titulo = { this.state.titulo }
                margen = {"mt-3"}
            >
                <LoadMask loading={loader} light type={"TailSpin"}>
                <div 
                style={this.props.verProductos ? {display: 'none'} : {display: 'inline'} }>
                    <ItinerarioForm
                    {...this.props}
                    editar = { this.state.editar }
                    idOC = {this.state.idOC }
                    onSubmit = { this.onSubmit }
                    verProductosOrden = { this.verProductos }
                    getDetalleOrden = { this.getDetalleOrden }
                    borrarProductos = { this.borrarProductos }
                    initialValues={{
                        fecha_salida: new Date(),
                        fecha_entrada: new Date(),
                        tipo_pago: 1,
                    }}
                    PDFITINERARIO={PDFITINERARIO}
                    mostrarVista={mostrarVista}
                    setClienteSucursal={setClienteSucursal}
                    count={count}
                    />

                </div>
                <div 
                style={this.props.verProductos ? {display: 'inline'} : {display: 'none'} }>
                    <ListaProductos
                        cancelar = { this.cancelar }
                        onSubmit = { this.onSubmitProductos }
                        idOC = { this.state.idOC }
                        initialValues={{
                            detalle_orden: this.props.itemOC.detalle_orden
                        }}
                        itemOC = { this.props.itemOC
                        }
                    />
                </div>
                </LoadMask>
            </CardSimple>
        );
    }
}

