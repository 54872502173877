import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/pago_flotante';
import { setTransaccion } from '../../../../../redux/modules/Transacciones/flotante_inverso';

import PagoFlotanteContainer from './pago_flotante_container';

const mstp = state => {
    return {
        ...state.r_pago_flotante,
        usuario: state.login.me.id,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions, setTransaccion
};

export default connect(mstp, mdtp)(PagoFlotanteContainer)
