import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
// import EmpleadosForm from './empeladosForm';
import EmpleadosForm from './empeladosForm';
import moment from 'moment';

class EmpleadoCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR EMPLEADO',
    }
    componentWillMount(){
        // this.props.selectEmpresas2();
        this.props.clear()
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR EMPLEADOS'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        let _valores = _.cloneDeep(values);
        _valores.empresa = values.empresa.id;
        if (values.vendedor)
            _valores.vendedor = values.vendedor.id;
        if (values.piloto)
            _valores.piloto = values.piloto.id;
        if (values.es_vendedor)
            _valores.es_vendedor = values.es_vendedor;
        _valores.fecha_nacimiento = moment(_valores.fecha_nacimiento).format('YYYY-MM-D');
        _valores.fecha_inicio_labores =  moment(_valores.fecha_inicio_labores).format('YYYY-MM-D');
        this.state.editar? editar(id,_valores) : crear(_valores);
    }
    render() {
        const { loader } = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <EmpleadosForm 
                        onSubmit={this.onSubmit}
                        initialValues={{
                            fecha_nacimiento:new Date(),
                            fecha_inicio_labores:new Date(),
                        }}
                        {...this.props}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default EmpleadoCrear;
