import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import renderFieldCheck from '../../../Utils/renderField'
import {estado_bodega} from '../../../../../utility/icons';
import { Link } from 'react-router-dom';


export default class BodegasGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page, this.props.match.params.id);
    }

    boton_adicional = (id, row) => {

        return (
            <Link to={`estado_bodega/${id}/`} className="px-2" >
                <img
                    className="action-img"
                    title="adicional"
                    src={estado_bodega}
                    alt="Estado bodega" />
            </Link>

        )
    }

    onPageChange = newPage => {
        const { match } = this.props;
        const id = match.params.id;
        this.props.listar(newPage, id);
      };

    render() {
        const { data, loader, listar: onPageChange, onSortChange, page, guardar } = this.props;
        return (
            <CardSimple
                titulo="Bodegas">
                    <Grid hover striped data={data} loading={loader} onPageChange={this.onPageChange} onSortChange={onSortChange} page={page} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell, row) => {
                                return customStandardActions(cell, {
                                    adicional: (c, r) => {
                                        return <label className={`mb-0 d-flex py-1 px-0 px-sm-1 flex-row algin-items-center`}>
                                        <div className="flex-1 px-0 mt-1 d-flex justify-content-center centrar-check">
                                        <input 
                                        type="checkbox" 
                                        component={renderFieldCheck} 
                                        checked={row.estado}
                                        onChange={() => {this.props.changeSelect(cell), this.forceUpdate()}}
                                        /> 
                                        </div>
                                        </label>
                                    },
                                })
                            }
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="empresa"
                            dataSort
                        >
                            EMPRESA
                        </TableHeaderColumn>
                    </Grid>
                    <div className="row  justify-content-sm-around justify-content-center">
                        {
                            this.props.match.params.tipo == "U" ?
                            <Link className="btn btn-secondary  align-self-center" to="/usuarios" >CANCELAR</Link>
                            :
                            <Link className="btn btn-secondary  align-self-center" to="/vendedores" >CANCELAR</Link>
                        }               
                        <button type="button" onClick={guardar} className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
            </CardSimple>
        );
    }
}
