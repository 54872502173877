import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';


import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';

import FlotanteInverso from './fl_inverso';
import { Link } from 'react-router-dom';

import { renderCurrency, renderTextArea, renderFieldRadio, renderField, renderDropDownField } from '../../../Utils/renderField';


import connected from 'rc-menu/lib/MenuItem';
import {getSimbolo} from "Utils/renderField/renderReadField";


 class AsignarTarjeta extends Component {
    render() {
        const { ver_nuevo, handleSubmit, onSubmit } = this.props;
        return (
            <form name="ordencompraForm" className="form-validate mb-lg " onSubmit={handleSubmit}>

                        <div
                            className="mb-3"
                            style={{border: "1px solid #e8e8e8", padding:"10px"}}
                            >
                            <div className="d-flex justify-content-center">
                                <h5 className="t-primary text-uppercase align-self-center">Seleccionar</h5>
                            </div>
                            <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                                <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                                    <Field
                                        name="opcion_asignacion"
                                        label="Crear nuevo"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"true"}
                                        className="form-control" />
                                </div>
                                <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                                    <Field
                                        value={"false"}
                                        name="opcion_asignacion"
                                        label="Seleccionar flotante"
                                        component={renderFieldRadio}
                                        type="radio"
                                        className="form-control" />
                                </div>
                            </div>
                        </div>
                        {
                            (ver_nuevo) ? (
                                    <Fragment>
                                        <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                                            <div className="form-group has-feedback  col-12">
                                                <label className="t-azul"  htmlFor="fecha_fundacion">Fecha</label>
                                                <Field
                                                    name="fecha"
                                                    maxDate="2030-12-31"
                                                    minDate="2018-01-01"
                                                    className="form-control"
                                                    component={renderDropDownField}
                                                />
                                            </div>
                                            <div className={`form-group has-feedback  col-md-6`}>
                                                <label className="t-azul"  htmlFor="no_documento">No. Transacción</label>
                                                <Field name="no_documento" label="no_documento" component={renderField} type="text" className="form-control" />
                                            </div>
                                            <div className={`form-group has-feedback col-md-6 `}>
                                                <label className="t-azul"  htmlFor="monto">Monto</label>
                                                <Field
                                                    name="monto"
                                                    label="monto"
                                                    prefix={'Q. '}
                                                    component={renderCurrency}
                                                    parse={cell => parseFloat(cell)}

                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group has-feedback  col-12">
                                                <label className="t-azul"  htmlFor="descripcion">Descripción</label>
                                                <Field
                                                    name="descripcion"
                                                    label="descripcion"
                                                    component={renderTextArea}
                                                    top={{top:"95px"}}
                                                    className="form-control"
                                                />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                                                    <Link className="btn btn-secondary align-self-center" to="/reporte_pago_flotante" >CANCELAR</Link>
                                                    <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>

                            ):(
                                <FlotanteInverso
                                    onSubmit={onSubmit}
                                    {...this.props}/>
                            )
                        }
            </form>

        )
    }
}

AsignarTarjeta = reduxForm({
    form: 'AsignarTarjetaForm',
    validate: (data, props) => {
        const monto_tarjetas = props.monto_tarjeta_seleccionada;
        const errors = {};

        if(!data.no_documento){
            errors.no_documento = "Campo requerido.";
        }
        if(!data.monto){
            errors.monto = "Campo requerido.";
        }else{
            if(Number(data.monto) < 1){
                errors.monto = "Debe ser mayor de 0";
            }
            if(monto_tarjetas < Number(data.monto)){
                errors.monto = `Debe de ingresar una cantidad menor de Q.${monto_tarjetas}`;
            }
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido.";
        }

        return errors;
    }
})(AsignarTarjeta);

const selector = formValueSelector('AsignarTarjetaForm');
AsignarTarjeta = connect((state, props) => {
    const opcion_asginacion = selector(state, 'opcion_asignacion');
    let ver_nuevo = true;

    if(opcion_asginacion == 'false'){
        ver_nuevo = false;
    }
    return {
        ver_nuevo
    }
})(AsignarTarjeta);

export default AsignarTarjeta;
