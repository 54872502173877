import React, {Component} from 'react';

export default function HeaderSimple(props){
    return (
        <div className="col-12 pt-4 px-4 m-0" >
            <h3 className="t-primary text-uppercase">
                {props.titulo}
            </h3>
        </div>
    );
}
