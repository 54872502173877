import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../../Utils/Grid/index';
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from '../../../../../../utility/constants';
import {RenderCurrency, RenderNumber} from "../../../../Utils/renderField/renderReadField";
import { renderNumber } from '../../../../Utils/renderField';

export default class despachoGrid extends Component{
    render(){
        const { detalle_orden, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={{count:10,results:detalle_orden}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                    <TableHeaderColumn
                        dataField="producto"
                        dataSort
                        dataFormat = {
                            (cell)=>(cell.nombre)
                        }
                        isKey
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataSort
                    >
                        CANTIDAD
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataSort
                        dataFormat={
                            (cell, row)=> (<RenderNumber value={cell - row.cantidad_actual } decimalScale={(cell - row.cantidad_actual)%1 == 0 ? 0:2}/>)
                        } 
                    >
                        CANTIDAD DESPACHADA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad_actual"
                        dataSort
                    >
                        RESTANTE
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}