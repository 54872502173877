import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates'
import {api} from 'api'

let estado = [
    {id: true, value: true, label:"Activos"},
    {id: false, value: false, label: "Anulados"},
]

let status_options = [
    {id: 0, value: 0, label:"Liquidados"},
    {id: 1, value: 1, label: "Pendientes"},
]

const getProveedores = (search) => {
    return api.get("clientes", { search, esCliente: '' }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};
let TipoTransaccion =[
    {value:1, label:'ELECTRONICA'},
    {value:2, label:'CHEQUE'},
    {value:4, label:'DEPOSITO'},
    {value:5, label:'TARJETA'},
    {value:6, label:'EFECTIVO'},
]


export default class ToolbarFiltroGastos extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
        
        proveedor: null,
        resultadosProveedor: [],
    }
    componentWillMount(){
        let resultadosProveedor = []
        resultadosProveedor = getProveedores('')
        resultadosProveedor.then((valor) => {
            this.setState({ resultadosProveedor: valor })
        })
    }
    format = (array=[]) =>{
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }
    render() {
        const {
            valorEstado,
            changeEstado,
            usuarios,
            usuario,
            changeUsuario,
            permisos
        } = this.props;
        return (
            <div className="row col-12 p-0">
                <div className="row col-12 m-0 text-lg-left mb-2">
                    <div className="col-lg-4 col-md-6 col-12 align-self-center">
                    <span className="t-azul-claro font-weight-bold">Fecha Inicial</span>
                        <SingleDatePicker
                        showClearDate={true}
                        className="form-control"
                        placeholder={"Fecha Inicio"}
                        date={this.props.dateStart ? moment(this.props.dateStart ) : null}
                        focused={this.state.focusedStart}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                        this.setState({dateStart:value})
                        this.props.setDateStart(value)
                        }}
                        onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                        numberOfMonths={1}
                        id={"dateStart"}
			            readOnly = { true }
                    />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 align-self-center">
                    <span className="t-azul-claro font-weight-bold">Fecha Final</span>
                        <SingleDatePicker
                        showClearDate={true}
                        className="form-control"
                        placeholder={"Fecha Final"}
                        date={this.props.dateEnd ? moment(this.props.dateEnd ) : null}
                        focused={this.state.focusedEnd}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                           this.setState({dateEnd:value})
                           this.props.setDateEnd(value);
                        }}
                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                        numberOfMonths={1}
                        id={"dateStart"}
			            readOnly = { true }
                    />
                    </div>
                        <div className={"col-lg-4 col-md-6 col-12 align-self-center"}>
                        <span className="t-azul-claro font-weight-bold">Tipo de movimiento</span>
                            <Select
                                isClearable={true}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={TipoTransaccion}
                                placeholder={"Seleccione"}
                                value={this.props.forma_pago}
                                onChange={this.props.formaPagoChange}
                                />

                        </div>
                </div>
                <div className="row col-12 m-0 text-lg-left mb-2">    
                    <div className={"col-lg-4 col-md-6 col-12 align-self-center"}>
                    <span className="t-azul-claro font-weight-bold">Proveedor</span>
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.proveedor}
                            isSearchable={true}
                            loadOptions={getProveedores}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => {
                                e ? this.setState({ proveedor: e.id, asyncValue: e ? e : null }) : this.setState({ proveedor: null })
                                if (this.props.changeProveedor) {
                                    e ? this.props.changeProveedor(e) : this.props.changeProveedor(null)
                                }
                            }}

                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={this.state.resultadosProveedor}
                            placeholder="Seleccionar.."
                        />
                    </div>
                    <div className={"col-lg-4 col-md-6 col-12 align-self-center"}>
                    <span className="t-azul-claro font-weight-bold">Estado</span>
                        <Select
                            isClearable={false}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={estado}
                            placeholder={"Estado"}
                            value={valorEstado}
                            onChange={changeEstado}
                        />
                    </div>
                    <div className={"col-lg-4 col-md-6 col-12 align-self-center"}>
                    <span className="t-azul-claro font-weight-bold">Liquidado</span>
                        <Select
                            isClearable={true}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={status_options}
                            placeholder={"Seleccione"}
                            value={this.props.status}
                            onChange={this.props.statusChange}
                        />
                    </div>
                </div>
                <div className="row col-12 m-0 text-lg-left mb-2">     
                    {
                        !permisos.gastos_propios && !permisos.visualizar_mis_datos &&
                        <div className={"col-lg-4 col-md-6 col-12 align-self-center"}>
                        <span className="t-azul-claro font-weight-bold">Usuario</span>
                        <Select
                                isClearable={true}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={true}
                                isSearchable={true}
                                value={usuario}
                                options={this.format(usuarios? usuarios:[])}
                                placeholder={"Usuario"}
                        
                                // value={null}
                                onChange={(e, action)=>{console.log(e, action); changeUsuario(e)}}
                            />
                        </div>}
                    </div>               
            </div>
        )
    }
}
