import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const LoginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-sm-10  col-12">
                    <label htmlFor="username">Usuario</label>
                    <Field name="username" label="Usuario" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-sm-10  col-12">
                    <label htmlFor="password">Contraseña</label>
                    <Field
                        name="password"
                        label="Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                    />
                </div>

            </div>
            <div className="row  justify-content-center">
                <div className="col-sm-10  col-12">

                <button type="submit" className="btn btn-primary btn-block m-1 align-self-center">INICIAR SESION</button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm);
