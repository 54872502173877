import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import ToolbarFiltroItinerarios from './filtroItinerarios';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { Redirect } from 'react-router-dom';

//Extras
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { getsimbolo } from '../../../../../utility/constants';
import Swal from 'sweetalert2';
import RutasGrid from './rutasGrid';

import Modal from 'react-responsive-modal';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}

export default class ItinerarioGrid extends Component {

    state = {
        aprube:true,
    }

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    handleData =(value)=>{
        this.setState({aprube:value})
    }

    render() {

        const { data, loader, listar: onPageChange, onSortChange, open_modal, page, permisos,
            entregadoChange, pagoChange, eliminar, rol } = this.props;
        if(permisos.itinerarios === false) {
            if(!!permisos.ver_itinerarios === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                titulo="RUTAS"
                margen={"mt-4"}
                toolbar={
                    <ToolbarSimple
                        textBoton={(!permisos.visualizar_mis_datos && permisos.crear_itinerarios) | rol == "VENDEDOR/SUPERIOR" ? "Agregar" : ""}
                        ruta="itinerario/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Piloto, Vehiculo, Orden"}
                        permisos={permisos.itinerarios}
                        estatefilterBar={true}
                        filterbar={
                            <ToolbarFiltroItinerarios
                            changeEstado={this.props.estadoChange}
                            valorEstado={this.props.estado}
                            changeEstadoAC={this.props.estadoChangeAC}
                            valorEstadoAC={this.props.estadoAC}
                            dateStart={this.props.dateStart}
                            dateEnd={this.props.dateEnd}
                            setDateStart={this.props.setDateStart}
                            setDateEnd={this.props.setDateEnd}
                            />
                        }
                    />
                }
            >
                <RutasGrid
                    data={data}
                    listar={this.props.listar}
                    loader={loader}
                    page={page}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                    eliminar = {eliminar}
                    abrirModalEliminar={this.abrirModalEliminar}
                    handleData={this.handleData}
                    setSimbolo={this.setSimbolo}
                    permiso={permisos.itinerarios}
                    estado={this.props.estado.value}
                    permisos={permisos}
                    rol={rol}
                />

            </CardSimple>
        );
    }
}

