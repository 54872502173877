import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/deuda_interna';
import DeudaInternaReporte from './DeudaInternaReporte';

const mstp = state => {
    return {...state.Reportes, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DeudaInternaReporte)
