import React, { Component } from 'react';
import Dashboard from '../app/Dashboard';


class Demo extends Component {
    render() {
        return (
            <Dashboard />
        );
    }
}

export default Demo;
