import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

// ------------------------------------
// constantes
// ------------------------------------
const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';


export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});


// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('user/send_email', data).then((response) => {
        console.log(response)
        dispatch(push("/")); //redireccionamiento a componente info
    }).catch(() => {
        NotificationManager.error('Nos se pudo enviar el correo', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onVerify =(data ={}) =>(dispatch) => {
    console.log(data)
    dispatch(setLoader(true));
    api.post('user/verify_token', data).then((response) => {
        console.log(response)
        dispatch(push("/")); //redireccionamiento a componente info
    }).catch(() => {
        NotificationManager.error('Nos se pudo enviar el correo', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    onSubmit,
    onVerify
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    }
};

export const initialState = {
    loader: false
};

export default handleActions(reducers, initialState);
