import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../../Utils/Grid/index';
import {Monedas, getsimbolo} from '../../../../../../utility/constants';
import {RenderCurrency, RenderNumber} from "../../../../Utils/renderField/renderReadField";
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";
class  DetalleGrid extends Component {
    render(){
        const { data, loader, listar: onPageChange, onSortChange, permiso } = this.props
        return(
            <Grid hover striped data={{count:10, results:data}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                <TableHeaderColumn
                    dataField="producto"
                    dataSort
                    dataFormat={
                        (cell, row) =>(cell.nombre)
                    }
                    isKey
                >
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="producto"
                    dataSort
                    dataFormat={
                        (cell, row) =>(cell.codigo)
                    }
                >
                    CODIGO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataSort
                >
                    DESPACHADO
                </TableHeaderColumn>
                {
                    permiso &&
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataSort
                        dataFormat={
                            (cell,row)=>( 
                                <RenderMoneda monto={cell? row.cantidad * row.precio_unitario: 0} simbolo={"Q"} />
                                
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                    }
            </Grid>
        )
    }
}



export default class HistoriaDespachoGrid extends Component{


    isExpandableRow(row) {
        if (row.detalle_despacho.length > 0) return true;
        else return false;
    }
    expandComponent(row, permiso) {
        return (
            <DetalleGrid data={ row.detalle_despacho } permiso={permiso} />
        );
    }

    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permiso  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={{count:10, results:data}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} 
                    expandableRow={ this.isExpandableRow }
                    expandComponent={(row) => this.expandComponent(row, permiso) }
                >
                    <TableHeaderColumn
                        dataField="no_despacho"
                        dataSort
                        isKey
                    >
                        NO. DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despachado_por"
                        dataSort
                        dataFormat = {
                            (cell, row) =>(`${cell.first_name} ${cell.last_name}`)
                        }
                    >
                        Encargado
                    </TableHeaderColumn>
                    {
                    permiso &&
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        dataFormat={
                            (cell,row)=>( 
                                <RenderMoneda monto={cell? cell: 0} simbolo={"Q"} />
                                
                            )
                        }
                    >
                        SUBTOTAL
                    </TableHeaderColumn>
                    }
                    <TableHeaderColumn
                        dataField="despachado_por"
                        dataSort
                        dataFormat = {
                            (cell, row) => {
                                return row.flujo_despacho==10 ? 'PENDIENTE' :(
                                    row.flujo_despacho==20 ? 'ACEPTADA':(
                                        row.flujo_despacho==30 ? 'RECHAZADA':(
                                            row.flujo_despacho==40 ? 'ENVIADA A SUCURSAL':''
                                        )
                                    )
                                )
                            }
                        }
                    >
                        Estado
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                        dataField="detalle_despacho"
                        dataSort
                        dataFormat={
                            (cell, row) =>{
                            return(<label>{cell.producto.nombre}</label> )
                            }
                        }
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="detalle_despacho"
                        dataSort
                        dataFormat={
                            (cell, row)=> (cell.cantidad)
                        } 
                    >
                        CANTIDAD DESPACHADA
                    </TableHeaderColumn> */}
                </Grid>

            </div>
        )
    }

}