import React, { Component, Fragment } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import CardSimple from '../../../Utils/Cards/cardSimple';

import LoadMask from "../../../Utils/LoadMask/LoadMask";


import EdicionBonos from './editBonos';



export default class BonosEmpleado extends Component {
    state ={
        editar: false,
        titulo: 'NUEVA',
    }
   
    handleSubmitBonos = (values)=>{
        const { todos_trabajadores, editBonosEmpleados, index, item } = this.props;
        editBonosEmpleados(values, todos_trabajadores, index, item);
        // this.setOpenModalEmpleado(false);
    }
    render() {
        const { loader,info_trabajador, index, empresas } = this.props

        return (
            <CardSimple>
                <div className="row col-12 ">
                    <div className="col-12">
                        <h2 className="text-primary text-uppercase">{info_trabajador.nombre_completo}</h2>
                    </div>
                    <div className="col-sm-6 col-12 d-flex">
                        <h5 className="t-mostaza text-uppercase">{`Puesto: `}</h5>
                        <h5 className="t-primary text-uppercase">{info_trabajador.puesto ? info_trabajador.puesto.nombre:""}</h5>
                        {/* <h6 className="text-center text-primary text-uppercase">{`(${index})`}</h6> */}
                    </div>
                    <div className="col-sm-6 col-12 d-flex">
                        <h5 className="t-mostaza text-uppercase">{`Tipo: `}</h5>
                        <h5 className="t-primary text-uppercase">{info_trabajador.puesto ? info_trabajador.puesto.tipo_label:"NoN"}</h5>
                    </div>
                </div>
                <br/>
                <LoadMask loading={loader} light>
                    <div className="col-md-12 row d-flex justify-content-center m-0 p-0">
                       <EdicionBonos
                            empresas={empresas}
                            onSubmit={this.handleSubmitBonos}
                            info_trabajador={info_trabajador}
                            sueldo_base={info_trabajador.puesto.sueldo_base}
                       />
                    </div>
                </LoadMask>

            </CardSimple>

        )
    }
}
