import { handleActions } from 'redux-actions';
import { NotificationManager, __esModule} from "react-notifications";
import { push } from "react-router-redux";
import { api } from "api";

let url = 'bodega_usuario' ;
let dirGrid = '/usuarios';

export const constants = {
    LOADER: `BODEGAS_LOADER`,
    DATA: `BODEGAS_DATA`,
    PAGE: `BODEGAS_PAGE`,
    ORDERING: `BODEGAS_ORDERING`
};

    // -----------------------------------
    // Pure Actions
    // -----------------------------------

const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});
const setData = data => ({
    type: constants.DATA,
    data,
});
const setPage = page => ({
    type: constants.PAGE,
    page,
});
const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});
const setDataSelect = (type, data) =>({
    type,
    data
});
// -----------------------------------
// Actions
// -----------------------------------
const listar = (page, id) => (dispatch, getStore) => {
    const resource = getStore().bodegaUsuario;
    const params = { page };
    params.usuario__id = id
    params.ordering = resource.ordering;
    dispatch(setLoader(true));
    api.get(url, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeSelect = (id) => (dispatch, getStore) =>{
    const resource = getStore().bodegaUsuario
    let bodegas = resource.data
    bodegas.results.forEach(bodega => {
        if(bodega.id == id){
            bodega.estado = !bodega.estado
        }
    });
    dispatch(setData(bodegas));
}

const guardar = () => (dispatch, getStore) =>{
    const resource = getStore().bodegaUsuario
    let data = resource.data.results
    dispatch(setLoader(true));
    api.post('bodega_usuario/guardar', data).then((response) => {
        NotificationManager.success(response.detail, 'Éxito', 3000);
        dispatch(push(dirGrid))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}
const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().bodegaUsuario.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    onSortChange,
    changeSelect,
    guardar
};
// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
};
const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
};
export default handleActions(reducers, initialState);


