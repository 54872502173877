import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { Redirect } from 'react-router-dom';
import {estado_bodega} from '../../../../../utility/icons';
import { Link } from 'react-router-dom';


export default class BodegaGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    boton_adicional = (id, row) => {

        return (
            <Link to={`estado_bodega/${id}/`} className="px-2" >
                <img
                    className="action-img"
                    title="adicional"
                    src={estado_bodega}
                    alt="Estado bodega" />
            </Link>

        )
    }


    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos, page, bodegas } = this.props;
        if(permisos["bodegas"] === false) {
            if(!!permisos[`ver_bodegas`] === false) {
                return <Redirect to={`/`} />
            }
        }
        else{
            data.results.forEach((data2, index) =>{
               let existe = false
               bodegas.forEach(bodega2 =>{
                   if(data2.id == bodega2){
                       existe = true
                   }
               })
               if(existe==false){
                data.results.splice(index, 1)
               }
           })
           data.results.forEach((data2, index) =>{
            let existe = false
            bodegas.forEach(bodega2 =>{
                if(data2.id == bodega2){
                    existe = true
                }
            })
            if(existe==false){
             data.results.splice(index, 1)
            }
        })
        }

        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton={permisos.bodegas_admin ? "Agregar" : ""}
                    ruta="bodega/crear"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={permisos.bodegas}
                />}
                titulo="Bodegas">
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} page={page} >
                        { permisos.bodegas && (<TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell) =>{
                                return customStandardActions(cell, {
                                    editar: permisos.bodegas_admin ? "bodega" : undefined,
                                    eliminar: permisos.bodegas_admin ? eliminar : undefined,
                                    adicional: this.boton_adicional
                                })
                            }  
                            }
                        >
                        </TableHeaderColumn>)}
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="empresa"
                            dataSort
                        >
                            EMPRESA
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
