import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { Link } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "Utils/renderField/renderTableField";

import { getSimbolo} from "Utils/renderField/renderTableField";

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class TarjetasAsignacion extends Component {
    render() {
        const { loader, onSubmit, previousStep, transaccion_seleccionada, tarjetas_seleccionados } = this.props;
        return (
            <Fragment>
                <div className="col-md-12 p-0">
                    {
                        (transaccion_seleccionada.nuevo) ? (
                            <h3>Transacción nueva</h3>
                        ) : (
                            <h3>Flotante inverso seleccionado</h3>
                        )
                    }
                    <div className="row m-none">
                        {
                            (transaccion_seleccionada.fecha) && (
                                <div className="col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha: </span><br/>
                                    <span className="m-none t-musgo semibold"><RenderDateTime fecha={transaccion_seleccionada.fecha} ></RenderDateTime></span>
                                </div>
                            )
                        }
                        <div className="col-md-6 col-12">
                            <span className="m-none t-mostaza semibold">No transacción: </span><br/>
                            <span className="m-none t-musgo semibold">
                                {`${transaccion_seleccionada.nuevo ? transaccion_seleccionada.no_documento : transaccion_seleccionada.transaccion.no_documento}`}
                            </span>
                        </div>
                        <div className="col-md-6 col-12">
                            <span className="m-none t-mostaza semibold">Monto: </span><br/>
                            <span className="m-none t-musgo semibold">
                                <RenderMoneda monto={transaccion_seleccionada.nuevo ? transaccion_seleccionada.monto : transaccion_seleccionada.transaccion.monto} simbolo={'Q. '} />
                            </span>
                        </div>
                        <div className="col-md-6 col-12">
                            <span className="m-none t-mostaza semibold">Descripción: </span><br/>
                            <span className="m-none t-musgo semibold">{`${transaccion_seleccionada.nuevo ? transaccion_seleccionada.descripcion : transaccion_seleccionada.transaccion.descripcion}`}</span>
                        </div>
                    </div>
                </div>
                <h5 className="mb-4">Esta transacción corresponde a los pagos siguientes:</h5>
                 <Grid
                        hover
                        striped
                        data={{count: 0, results: tarjetas_seleccionados}} loading={loader} className='reporte-table'  >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    className='reporte-table'
                                    dataSort
                                    isKey
                                    hidden
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    className='reporte-table'
                                    dataField="fecha"
                                    tdStyle={cellTachado}
                                    dataSort
                                    dataFormat = {
                                        (cell,row) => {
                                            return(
                                                <div>
                                                    <span className="font-weight-bold">FECHA </span><RenderDateTime fecha={cell} ></RenderDateTime> <br/>
                                                    {
                                                        (row.fecha_emision) && (
                                                            <Fragment>
                                                                <span className="font-weight-bold">FECHA EMISIÓN </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br/>
                                                            </Fragment>
                                                        )
                                                    }
                                                    <span className="font-weight-bold">TIPO DE PAGO</span> <span>{row.flotante ? <span>Flotante</span> : <span>Inmediato</span>}</span>
                                                </div>
                                            )
                                        }
                                    }
                                >
                                    FECHA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="transaccion"
                                    dataSort
                                    className='reporte-table'
                                    dataFormat={
                                        (cell, row) => {
                                            if(cell){
                                            return <span>{cell.descripcion}</span>
                                            }
                                            return <span>-- --</span>
                                        }
                                    }
                                    tdStyle={cellTachado}
                                >
                                    DESCRIPCIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="es_ingreso"
                                    className='reporte-table'
                                    tdStyle={cellTachado}
                                    dataSort
                                    dataFormat = {
                                        (cell, row)=>(cell? "Ingreso":"Egreso")
                                    }
                                >
                                    MOVIMIENTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="transaccion"
                                    className='reporte-table'
                                    dataSort
                                    tdStyle={cellTachado}
                                    dataFormat={
                                        (cell, row) => {
                                            if(cell){
                                                return <span>{cell.no_documento}</span>
                                            }
                                            return <span>-- --</span>
                                        }
                                    }
                                >
                                    DOCUMENTO
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="saldo"
                                    dataSort
                                    className='reporte-table'
                                    tdStyle={cellTachado}
                                    dataFormat ={
                                        (cell, row)=>(
                                            <RenderMoneda monto={ row.transaccion.monto } simbolo={getSimbolo(row.transaccion.moneda)} />
                                        )
                                    }
                                >
                                    MONTO
                                </TableHeaderColumn>
                        </Grid>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                                    <button
                                        type="button"
                                        onClick={previousStep}
                                        className="btn btn-secondary align-self-center" >ATRÁS</button>
                                    <button
                                        type="button"
                                        onClick={onSubmit}
                                        className="btn btn-primary m-1 align-self-center">ASIGNAR</button>
                                </div>
                            </div>
                        </div>
            </Fragment>
        );
    }
}
