import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';

let estado = [
    {id: false, value: 1, label:"Activo"},
    {id: undefined, value: 0, label:"Anulado"},
    {id: true, value: true, label:"Finalizado"},
    {id: null, value: undefined, label:"Todos"},
]


export default class ToolbarProduccion extends Component {
    componentWillMount(){
    }
    render() {
        const {
            changeEstado,
            valorEstado,
        } = this.props;
        return (
            <div className=" row col-lg-12 col-12 row m-0 p-0 ">
                <div className="col-12">
                     {/* <span className="t-musgo font-weight-bold">Año</span> */}
                    <Select
                    isClearable={false}
                    className={classNames('react-select-container')}
                    backspaceRemovesValue={false}
                    isSearchable={true}
                    options={estado}
                    placeholder={"Todos"}
                    value={valorEstado}
                    onChange={changeEstado}
                        />
                </div>
            </div>
        )
    }
}