import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/fcm';
import Fcm from './Fcm'

const ms2p = (state) => {
  return {
    ...state.fcm,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Fcm);
