import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardSimple from '../../../Utils/Cards/cardSimple';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class DevolucionGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page, setTMovimiento } = this.props;
        setTMovimiento(75) // para proveedores
        listar(page);
    }

    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos.devolucion_pro === false) {
            if(!!permisos.ver_devolucion_pro === false) {
                return <Redirect to={`/`} />
            }
        }
        return(
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton="Agregar"
                    ruta="/devolucionpro/nuevo"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Proveedor, No. Ingreso"}
                    permisos={permisos.devolucion_pro}
                />}
                titulo="Devoluciones a Proveedores">
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} page={page} onSortChange={onSortChange} >
                    <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey

                            dataFormat={
                                standardActions({ ver: "devolucionpro" })
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataSort
                            dataFormat = {
                                (cell, row) => (
                                    <RenderDateTime fecha={cell} />
                                )
                            }
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cliente"
                            dataSort
                            dataFormat = {
                                (cell) => {
                                    if(cell){
                                    return  (<span>{cell.nombre}</span>)
                                    }
                                    return (<div></div>)
                                }
                            }
                        >
                            PROVEEDOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="no_despacho"
                            dataSort
                        >
                            NO MOVIMIENTO
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn
                            dataField="despachado_por"
                            dataSort
                            dataFormat = { (cell, row) => (
                                <span>{cell.first_name}</span>

                            )
                            }
                        >
                            RECIBIDO POR
                        </TableHeaderColumn> */}
                        <TableHeaderColumn
                            dataField="flujo_despacho_label"
                            dataSort
                            dataFormat = {
                                (cell, row) => {
                                    if(cell == 'Aceptada'){
                                        return (<span className="t-verde font-weight-bold">Aceptada</span>)
                                    }else{
                                        return(<span className="t-corinto font-weight-bold">Pendiente</span>)
                                    }
                                }

                            }
                        >
                            Estado
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        )
    }
}

