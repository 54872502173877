import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import {UnidadMedida} from '../../../../../utility/constants';
import moment from 'moment';

export default class GridProductos extends Component {
    componentDidMount() {
        this.props.productosConsumidos();
    }

    render() {
        const { page2, data2, loader, productosConsumidos} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data2}
                    loading={loader}
                    page={page2}
                    className="reporte-table"
                    onPageChange={productosConsumidos}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="producto_Base"
                        className='reporte-table'
                        width="5%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="producto_Base__nombre"
                        className='reporte-table'
                    >
                        PRODUCTO CONSUMIDO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="producto_Base__tipo_producto__nombre"
                        className='reporte-table'
                    >
                        TIPO DE PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        className='reporte-table'
                        dataAlign="right"
                        thStyle={{paddingRight: "10%"}}
                        tdStyle={{paddingRight: "10%"}}
                        dataFormat={(cell) => <RenderNumber value={cell} decimalScale={4} />  }
                    >
                        UNIDADES CONSUMIDAS
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="unidad_medida"
                        className='reporte-table'
                        thStyle={{paddingLeft: 30}}
                        tdStyle={{paddingLeft: 30}}
                        width="18%"
                        dataFormat={(cell) => {
                            const item = _.find(UnidadMedida, { value: cell });
                            return item ? item.label : ""
                        }}
                    >
                        UNIDAD DE MEDIDA
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                        dataField="unidad_medida"
                        dataFormat={(cell) => ""}
                        width="5%"
                    /> */}
                </Grid>
            </Fragment>
        )
    }
}
