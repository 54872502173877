export { default as DeudaReporte } from './DeudaEntreEmpresas';
export { default as DeudaInternaReporte } from './DeudaEntreEmpresasInternas';
export { default as CuentasporCOP } from './cuentasPorCoP';
export { default as CuentasporC } from './cuetasPorC';
export { default as IngresoEgreso } from './Ingreso_egreso';
export { default as IngresoEgresoInterno } from './Ingreso_egreso_interno'
export { default as PagoFlotanteReporte } from './Pago_flotante';
export { default as ReporteVehiculos } from './MaquinariaVehiculos';
export { default as AsignarTarjeta } from './AsginarTarjeta';
export { default as DeudaDetallEmpresa } from './DetalleDeudaEmpresa';
export { default as DeudaDetalleEmpresaInterna } from './DetalleDeudaEmpresaInterna';
export { default as AsignarCheque } from './Asginar cheque';
// detalle de ordenes
export { default as OrdenesporCOP } from './ordenesPorCoP';
export { default as OrdenesporC } from './ordenesPorC';

export { default as ValesGasolina } from './valesGasolina';
export { default as crearGastoVale } from './crearGastoVale';
export { default as ReporteCobros } from './CobrosMes';
export { default as ReporteVentas } from './Ventasxmes';
export { default as ReporteCuentas } from './Cuentasxmes';
export { default as ReporteDespachosBancos } from './reporte_despachos_bancos';
export { default as ReporteCuentasPagar} from './CuentasxmesPagar'
export { default as ReporteProductos } from './productosMasVendidos';
export { default as ReporteProduccion } from './Produccion';
export { default as ReporteConsumo } from './ProductosConsumidos';
export { default as ReportePreciocliente } from './precioClientes';
export { default as ReportePrecioProducto } from './precioProductos';
export { default as ReportePilotos } from './ReportePilotos';
export { default as ReporteCostoInventario } from './costoInventario';
export { default as ReporteMateriaPrima} from './materiaPrima';
export { default as ReporteLoteInventario } from './loteCostoInventario';
export { default as ReporteCostoVenta } from './CostovsVenta';
export { default as ReporteIngresoProducto } from './IngresosProducto';

// planilla
export { default as AusenciasReporte } from './AusenciasReporte';
export { default as PlanillaporEmpleado } from './pplanilaporEmpleado';
export { default as PlanillaporEmpresa } from './pplanillaporEmpresa';
export { default as DetallePagoPlanilla } from './DetallePagoPlanilla';