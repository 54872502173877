import { connect } from 'react-redux';
import {
    actions
} from '../../../../../../redux/modules/Reporte/reportePreciosInternos';
import PreciosInternosGrid from './ProductoGris';

const mstp = state => {
    return {...state.reportePreciosInternos,permisos: state.login.me.permisos, }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PreciosInternosGrid)


