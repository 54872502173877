import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/bodegaUsuario/bodegaUsuario';
import BodegasGrid from './BodegasGrid';
 
const mstp = state => {
    return {...state.bodegas, permisos: state.login.me.permisos}
};
const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(BodegasGrid)
