import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import {standardActions} from '../../../Utils/Grid/StandardActions';
import moment from 'moment';

export default class GridItinerarios extends Component {
    componentDidMount() {
        this.props.getItinerariosActivos();
    }

    render() {
        const { page3, data3, loader, getItinerariosActivos} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data3}
                    loading={loader}
                    page={page3}
                    className="reporte-table"
                    onPageChange={getItinerariosActivos}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        className='reporte-table'
                        width="3%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="fecha_salida"
                        className='reporte-table'
                        dataFormat={(cell) => moment(cell).format("DD/MM/YYYY")}
                    >
                        FECHA DE SALIDA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        className='reporte-table'
                        dataFormat={(cell) => `No. ${cell}`}
                    >
                        NO. DE ITINERARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="piloto"
                        className='reporte-table'
                    >
                        PILOTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="vehiculo"
                        className='reporte-table'
                    >
                        VEHÍCULO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cerrado"
                        className='reporte-table'
                        width="10%"
                        dataFormat={(cell) => cell ? "Cerrado" : "Activo"}
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
