import React, { Component } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Redirect } from 'react-router-dom';


export default class DeudaInternaReporte extends Component {
    componentDidMount(){
        this.props.DeudaEmpresas()
    }

    render() {
        const { deudasEmpresas, data, loader, open_modal, permisos } = this.props;
        if(permisos["deuda_interna"] === false) {
            return <Redirect to={`/`} />
        } 
        return (
            <CardSimple
                titulo="REPORTE DE DEUDAS INTERNAS">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3">
                        <div className="col-lg-4 col-md-6 m-0 p-0 inline-headers text-center text-lg-right" 
                            style={{border:"1px solid #E8E8E8", }}>
                            <div style={{padding:"20px"}}>
                                <span className="t-azul-claro h5 mr-3"  htmlFor="descripcion">TOTAL POR PAGAR.     </span>
                                <br/>
                                <h2 className="t-mostaza text-break"  htmlFor="descripcion">{
                                    <RenderMoneda monto={Math.abs(this.props.porPagar)} simbolo={'Q.'} />
                                }</h2>
                                <hr style={{borderTop: "2px solid #F3A631", margin:"10px 0"}}/>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 m-0 p-0 inline-headers text-center text-lg-right" style={{border:"1px solid #E8E8E8"}}>
                            <div style={{padding:"20px"}}>
                                <span className="t-azul-claro h5 mr-3"  htmlFor="descripcion">TOTAL POR COBRAR.     </span>
                                <br/>
                                <h2 className="t-primary text-break"  htmlFor="descripcion">{
                                    <RenderMoneda monto={this.props.porCobrar} simbolo={'Q.'} />
                                }</h2>
                                <hr style={{borderTop: "2px solid #00334E", margin:"10px 0"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <Grid hover striped data={{count: 0, results: deudasEmpresas}} loading={loader}  >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataSort
                                className='reporte-table'
                                width='100'
                                dataFormat={
                                    standardActions({ ver:"/reporte_deuda_interna"})
                                }
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre_origen"
                                dataSort
                                className='reporte-table'
                            >
                                EMPRESA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ultimo_afectado"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        (cell.saldo <= 0) ? <RenderMoneda monto={Math.abs(cell.saldo)} simbolo={getsimbolo(row.moneda)} />:row.moneda=="GTQ" ? " Q. 0.00": "---"
                                    )
                                }
                                className='reporte-table'
                            >
                                POR PAGAR
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ultimo_afectado"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        cell.saldo ?
                                        (cell.saldo >=0) ? <RenderMoneda monto={cell.saldo} simbolo={getsimbolo(row.moneda)} />: row.moneda=="GTQ" ? " Q. 0.00": "---"  :row.moneda=="GTQ" ? " Q. 0.00": "---"
                                    )
                                }
                                className='reporte-table'
                            >
                                POR COBRAR
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>
            </CardSimple>
        );
    }
}
