import React from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderFieldRadio, renderTextArea, AsyncSelectField } from '../../../Utils/renderField';
import { api } from 'api';

const getVehiculos = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("vehiculos", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}


const ReajusteForm = (props) => {
    const { handleSubmit, producto } = props;
    return (
        <form name="anulacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                    <Field
                        name="tipo_movimiento"
                        label="Reajuste de producto"
                        component={renderFieldRadio}
                        type="radio"
                        value={"50"}
                        className="form-control" />
                </div>
                <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                    <Field
                        value={"60"}
                        name="tipo_movimiento"
                        label="Dar de baja"
                        component={renderFieldRadio}
                        type="radio"
                        className="form-control" />
                </div>
                <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                    <Field
                        value={"65"}
                        name="tipo_movimiento"
                        label="Asignar Repuesto"
                        component={renderFieldRadio}
                        type="radio"
                        className="form-control" />
                </div>
            </div>

            <div className="row">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="fecha_fundacion">Código</label>
                    <div className="col-md-12 p-0 m-0">
                        <span className="font-weight-bold">{producto.codigo}</span>
                    </div>
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="fecha_fundacion">Nombre</label>
                    <div className="col-md-12 p-0 m-0">
                        <span className="font-weight-bold">{producto.nombre} </span>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-md-6 col-12">
                    <label className="t-azul" htmlFor="cantidad">Cantidad</label>
                    <Field
                        name="cantidad"
                        label="cantidad"
                        type="number"
                        component={renderField}
                        top={{ top: "95px" }}
                        className="form-control"
                    />
                </div>
                {
                    (props.tipo_movimiento) && (props.tipo_movimiento == "65") ? (
                        <div className="form-group has-feedback  col-md-6 col-12">
                            <label className="t-azul" htmlFor="vehiculo">Maquinaria/vehículo</label>
                            <Field
                                name="vehiculo"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getVehiculos}
                                type="text"
                                valueKey="id"
                                labelKey="nombre"
                                isClearable={true}
                                className="form-control"
                            />
                        </div>
                    ) : (
                            <div className="form-group has-feedback  col-md-6 col-12" />
                        )
                }
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-sm-12  col-12">
                    <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        rows={7}
                        component={renderTextArea}
                        type="text"
                        top={{ top: "174px" }}
                        className="form-control" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="button" onClick={() => {
                            props.cerrarModal(false);
                        }} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector('ReajusteForm');
const ms2p = (state) => {
    const tipo_movimiento = selector(state, 'tipo_movimiento');
    return {
        tipo_movimiento
    };
}

export default connect(ms2p)(reduxForm({
    form: 'ReajusteForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            descripcion: validators.exists()('Este campo es requerido'),
            cantidad: validators.exists()('Este campo es requerido'),
        });
    },
})(ReajusteForm));
