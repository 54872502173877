import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { renderField, renderTextArea, renderSelectField, renderCurrency, AsyncSelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {  Monedas } from '../../../../../utility/constants';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";

import Modal from 'react-responsive-modal';
import {api} from 'api';
import moment from 'moment';

import EditBonosForm from '../editarbonos/editBonos';

class ItemArray extends Component {
    state = {
        trabajador: {},
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        // this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => props.getEmpleados(search)
          }
      }
      componentDidMount(){
        if( this.props.empleado_seleccionado){
            this.setState({trabajador: this.props.empleado_seleccionado.trabajador});
        }
      }
      componentDidUpdate(prevProps, prevState){
       if(this.props.empleado_seleccionado !== prevProps.empleado_seleccionado){
            const { todos, index } = this.props;
            if( this.props.empleado_seleccionado){
                this.setState({trabajador: this.props.empleado_seleccionado.trabajador});
            }
        }
    }
    render(){
        const {index, trabajador, eliminar, empleado_seleccionado, editar} = this.props;
        return (
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <div className="d-flex justify-content-center">
                        <div className="px-2">
                            <img className="action-img " src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                title="Borrar"
                                onClick={() =>{
                                    eliminar()
                                }}
                            />
                        </div>
                        <Link 
                            to='/planilla/EdicionBonos' 
                            className="px-2"
                            onClick={(e) =>{
                                editar()
                            }}
                            >
                            <img  
                                className="action-img" 
                                src={require('assets/img/icons/editar.png')} 
                                alt="Editar"
                                title="Editar"
                            />
                        </Link>
                    </div>
                </td>
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${trabajador}`}
                        name={`${trabajador}.trabajador`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top ={{top:"67px", position:"inherit"}}
                        type="text"
                        valueKey="id"
                        labelKey="nombre_completo"
                        onChange={(e) => {
                            this.setState({trabajador: e});
                        }}
                        className="form-control" />
                </td>
                <td className="sin-borde-top"  style={{width: "10%"}}>
                    <span>{ (this.state.trabajador && this.state.trabajador.puesto) ? this.state.trabajador.puesto.nombre:"NA"}</span>
                </td>
                <td className="sin-borde-top"  style={{width: "10%"}}>
                    <RenderMoneda 
                        monto={
                            (this.state.trabajador && this.state.trabajador.bonos_puesto && this.state.trabajador.actividades && this.state.trabajador.descuentos_puesto) ? 
                            this.state.trabajador.bonos_puesto.total + this.state.trabajador.puesto.total + this.state.trabajador.actividades.total + this.state.trabajador.descuentos_puesto.total :0
                        } 
                    />
                </td>
                <td className="sin-borde-top"  style={{width: "10%"}}>
                    <RenderMoneda 
                        monto={
                            (this.state.trabajador && this.state.trabajador.bonos_puesto && this.state.trabajador.actividades && this.state.trabajador.descuentos_puesto)  ? 
                                this.state.trabajador.bonos_puesto.total_banco + this.state.trabajador.puesto.total + this.state.trabajador.actividades.total_banco  + this.state.trabajador.descuentos_puesto.total:0
                        } 
                    />
                </td>
                <td className="sin-borde-top"  style={{width: "10%"}}>
                    <RenderMoneda monto={(this.state.trabajador && this.state.trabajador.bonos_puesto && this.state.trabajador.actividades)  ? this.state.trabajador.bonos_puesto.total_efectivo + this.state.trabajador.actividades.total_efectivo :0} />
                </td>
            </tr>
        );
    }
}


const renderPlanilla = ({fields, meta: {error, submitFailed }, getEmpleados, todos_trabajadores, productos, proveedor, setOpenModalEmpleado}) => (
    
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th style={{width: "30%"}}>NOMBRE</th>
                        <th style={{width: "15%"}}>PUESTO</th>
                        <th style={{width: "15%"}}>TOTAL</th>
                        <th  style={{width: "15%"}}>BANCO</th>
                        <th  style={{width: "15%"}}>EFECTIVO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((trabajador, index) => {
                        let todos = fields.getAll();
                        const empleado = todos_trabajadores[index];
                        
                        return (
                            <ItemArray
                                proveedor={proveedor}
                                empleado_seleccionado={empleado}
                                index={index}
                                key={index}
                                todos={todos}
                                trabajador={trabajador}
                                getEmpleados={getEmpleados}
                                eliminar={ () =>{
                                    fields.remove(index)
                                }}
                                editar={
                                    ()=>{
                                        setOpenModalEmpleado(true, fields.get(index), index)
                                    }
                                }
                                field={fields ? fields.get(index) : null}
                            />
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div>
        </div>
        {
            (submitFailed || error) && (
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>
            )
        }
    </div>
)
//fin de render de productos

class planillaForm extends Component {
    state = {
        proveedor: null,
        moneda: null,
        ModalEmpleado:false,
        info_trabajador: {},
        index: null,
    }
    getEmpleados = (search) => {
        let cant = this.props.getBusinessDays(
            this.props.fechaI,
            this.props.fechaF
        );
        let fecha1 = moment(this.props.fechaI).format('YYYY-MM-DD');
        let fecha2 = moment(this.props.fechaF).format('YYYY-MM-DD');
        let params = {
            fechaI: fecha1,
            fechaF: fecha2,
            dias:cant['days'],
            dias_mes:cant['days_month'],
            search
        }
       return api.get("empleados/getEmpleadosConBonos", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
    };
    reRender = () => {
        this.setState({
            proveedor: this.props.producto,
            moneda: this.props.moneda
        });
    }
    setOpenModalEmpleado = (val, info_trabajador={}, index=null )=>{
        const { editBonosEmpleados, setEmpleadoInfo, todos_trabajadores } = this.props 
        if (val == true){
        //     this.setState({ModalEmpleado: val, info_trabajador:info_trabajador.trabajador, index})
        //     // editBonosEmpleados(info_trabajador.trabajador.bonos_puesto.bonos)
            setEmpleadoInfo(info_trabajador.trabajador, index, todos_trabajadores, true)
        }
        else
            setEmpleadoInfo({}, null, [], false)
            // this.setState({ModalEmpleado: val, info_trabajador, index})

    }
    handleSubmitBonos = (values)=>{
        const { todos_trabajadores, editBonosEmpleados } = this.props;
        editBonosEmpleados(values, todos_trabajadores, this.state.index);
        this.setOpenModalEmpleado(false);
    }
    render() {
        const { handleSubmit,
                total,
                texto_descripcion, productos,todos_trabajadores, empresas } = this.props;
        const { info_trabajador, index } = this.state
        return (
            <div>
                <form name="planillaForm" className="form-validate mb-lg " onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                        <h4 className="t-primary" >{texto_descripcion}</h4>
                    </div>
                    <div className="col-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-10 row m-0 p-0 ">
                            <div className="col-12 d-flex justify-content-center ">
                                <h5 className="t-musgo">TOTAL A PAGAR</h5>
                            </div>
                            <div className="col-12 d-flex justify-content-center ">
                                <h3 className="t-primary"><RenderMoneda monto={total} /></h3>
                            </div>
                        </div>
                    </div>
                    <div className=" row col-12 m-0 p-0 d-flex justify-content-center">
                            <div className="col-lg-6 col-md-12 m-0 p-0">
                                <label className="t-azul" htmlFor="nombre">Fecha Inicio:</label>
                                <Field
                                    name="fechaI"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                    changePersonalizado={e => {
                                        let cant = this.props.getBusinessDays(e, this.props.fechaF);
                                        this.props.selectEmpleados(
                                            e, 
                                            this.props.fechaF,
                                            cant
                                        )
                                    }}
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 m-0 p-0">
                                <label className="t-azul" htmlFor="nombre">Fecha Final:</label>
                                <Field
                                    name="fechaF"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                    changePersonalizado={e => {
                                        let cant = this.props.getBusinessDays(this.props.fechaI, e);
                                        this.props.selectEmpleados(
                                            this.props.fechaI, 
                                            e,
                                            cant 
                                        )
                                    }}
                                />
                            </div>
                    </div>
                    <br/>
                    <div className="col-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-12 m-0 p-0">
                            <FieldArray
                                name="detalleplanilla"
                                productos={productos}
                                reRender={this.reRender}
                                getEmpleados={this.getEmpleados}
                                component={renderPlanilla} 
                                todos_trabajadores={todos_trabajadores}
                                setOpenModalEmpleado={this.setOpenModalEmpleado}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                                <Link className="btn btn-secondary  align-self-center" to="/planilla" >CANCELAR</Link>
                                <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}



planillaForm = reduxForm({
    form: 'planillaForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        const errors = {};
        if (!data.detalleplanilla || !data.detalleplanilla.length){
            errors.detalleplanilla = {_error: 'No se puede generar una planilla vacia'}
        }else{
            const detalleArray = []
            data.detalleplanilla.forEach(function(trabajador, index){
                let detErrors = {}
                let repetidos = []
                if( trabajador.trabajador ){
                    repetidos = _.filter(data.detalleplanilla, (x)=>{
                        if (x.trabajador)
                            return x.trabajador.id == trabajador.trabajador.id 
                        return false;
                    });
                }
                if(!trabajador.trabajador){
                    detErrors.trabajador = "Campo requerido";
                    detErrors.touched = true;
                }else{
                    if(1 < repetidos.length){
                        detErrors.trabajador = "Este nombre esta repetido"
                        detErrors.touched = true;
                    }
                }
                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            })
            if(detalleArray.length){
                errors.detalleplanilla = detalleArray
            }
        }

        return errors;
    },
})(planillaForm);

const selector = formValueSelector('planillaForm');
planillaForm = connect(state => {

    const trabajadores = selector(state, 'detalleplanilla');
    let todos_trabajadores = []
    if(trabajadores){
        todos_trabajadores = trabajadores;
    }

    let total = 0;
    let total_efectivo = 0;
    let total_banco = 0;
    todos_trabajadores.forEach( (item, index) => {
        if (item.trabajador){
            total += item.trabajador.bonos_puesto.total
            total_banco += item.trabajador.bonos_puesto.total_banco
            total_efectivo += item.trabajador.bonos_puesto.total_efectivo
        }
        if (item.trabajador){
            let sueldo = item.trabajador.puesto.sueldo
            sueldo.forEach((item, index)=>{
                if (item.sueldo){
                    total += item.sueldo - item.sueldo_descuento
                    total_banco += item.sueldo - item.sueldo_descuento
                }
            })
        }
        if (item.trabajador){
            total += item.trabajador.actividades.total
            total_banco += item.trabajador.actividades.total_banco
            total_efectivo += item.trabajador.actividades.total_efectivo
        }
        if (item.trabajador){
            total += item.trabajador.descuentos_puesto.total
            total_banco += item.trabajador.descuentos_puesto.total
        }
    })

    return {
        total,
        total_banco,
        total_efectivo,
        todos_trabajadores
    }
})(planillaForm);

export default planillaForm;
