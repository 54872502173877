import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import VendedorForm from '../../Users/Editar/UsuariosForm';

class PilotoCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR PILOTO',
    }
    componentWillMount(){
        // this.props.selectRegiones()
        this.props.selectEmpresas2();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR PILOTO'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        console.log(values)
        this.state.editar? editar(id,values) : crear(values);
    }
    render() {
        const { loader, empresas } = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <VendedorForm 
                        vendedores_form={false} 
                        pilotos_form={true} 
                        onSubmit={this.onSubmit} 
                        empresas={empresas}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default PilotoCrear;
