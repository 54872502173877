import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderField,
    AsyncSelectField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
    renderTextArea,
    renderSelecttree,
    renderFieldCheck
} from '../../../Utils/renderField';
import { TipoTransaccion } from '../../../../../utility/constants';
import { getSimbolo} from "../../../Utils/renderField/renderTableField"
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders"
import {api} from 'api';


const getTotalesTipoDocumentos = (props) => {
    let params = {clipro: true, idClienteProveedor: props.idClienteProveedor};
    return api.get("reportes/pendiente_tipo_documentos", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};


let AsignarFlotanteInvForm = (props) => {
    const {handleSubmit, cerrarModal, idClienteProveedor, nombreCliente, monto} = props;
    return (
        <form name="AsignarFlotanteInvForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row">
                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                    <label className="t-azul" htmlFor="label_monto">Tipo de Documento</label>
                    <Field
                        name="tipo_documento_pago"
                        label="tipo_documento_pago"
                        labelKey="label_monto"
                        valueKey="value"
                        component={AsyncSelectField}
                        loadOptions={() => {return getTotalesTipoDocumentos(props)}}
                        className="form-control"
                    />
                </div>
            </div>

            <h4 className="mt-3 text-center text-primary text-uppercase">Al cliente: {nombreCliente}</h4>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                        <button type="button" onClick={()=>{cerrarModal(false)}} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">ASIGNAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


AsignarFlotanteInvForm = reduxForm({
    form: 'AsignarFlotanteInvForm', // a unique identifier for this form
    initialValues: {},
    validate: (data, props) => {
        const errors = {};
        if (data.tipo_documento_pago) {
            if (props.monto && parseFloat(props.monto) > parseFloat(data.tipo_documento_pago.monto_total)) {
                errors.tipo_documento_pago = 'El monto tiene que ser menor al monto pendiente del documento.';
            }
        }
        if (!data.tipo_documento_pago) {
            errors.tipo_documento_pago = "Campo requerido.";
        }

        return errors;
    },
})(AsignarFlotanteInvForm);


export default AsignarFlotanteInvForm;
