import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { renderField, renderSelectField, renderCurrency, renderFieldRadio } from '../../../Utils/renderField';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";

import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import Swal from 'sweetalert2';

const empresas_nombre =(val, empresas) =>{
    let nombre = ""
    empresas.forEach((item) => {
        if (item.id == val){
            nombre = item.nombre
        }
    })
    return nombre
}


class totalesForm extends Component {
    handleSubmit = (values) =>{
        const { handleSubmit, es_borrador, total_empresas, setTotalEmpresas } = this.props
        values.preventDefault();
        setTotalEmpresas(total_empresas)
        if (es_borrador == '1')
           handleSubmit(values)
        else{
            Swal.fire({
                title: '¿Desea aplicar el pago de planilla?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, aplicar pago!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    handleSubmit(values)
                }
            })
        }
    }
    render(){
        const { handleSubmit, total, total_banco, total_efectivo, previousStep, total_empresas, loader, empresas } = this.props
        return(
            <form name="planillaForm" className="form-validate mb-lg " onSubmit={ this.handleSubmit}>
                <br/>
                <div className="row justify-content-center">
                    <div className="col-md-10 row m-0 " style={{border:"1px solid #E8E8E8"}}>
                        <div className="col-12 d-flex  justify-content-center">
                            <h5>SELECCIONES OPCIÓN DE PLANILLA</h5>
                        </div>
                        <div className="col-6 d-flex justify-content-center ">
                            <Field
                            name="es_borrador"
                            label="Borrador"
                            component={renderFieldRadio}
                            type="radio"
                            value={"1"}
                            className="form-control" />
                        </div>
                        <div className="col-6 d-flex justify-content-center ">
                            <Field
                            name="es_borrador"
                            label="Pagar planilla"
                            component={renderFieldRadio}
                            type="radio"
                            value={"0"}
                            className="form-control" />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row justify-content-center">
                    <div className="col-md-10 row m-0 p-0 " >
                        <div className="col-12 p-0 d-flex justify-content-start ">
                            <h5>TOTAL DE PLANILLA</h5>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-10 row m-0  "  style={{border:"1px solid #E8E8E8"}}>
                        <div className="col-12 d-flex justify-content-center ">
                            <h5 className="t-musgo">TOTAL A PAGAR</h5>
                        </div>
                        <div className="col-12 d-flex justify-content-center ">
                            <h3 className="t-primary"><RenderMoneda monto={total} /></h3>
                        </div>
                        <div className="col-6 d-flex justify-content-center ">
                            <h5 className="t-musgo">TOTAL EN BANCO</h5>
                        </div>
                        <div className="col-6 d-flex justify-content-center ">
                            <h5 className="t-musgo">TOTAL A EFECTIVO</h5>
                        </div>
                        <div className="col-6 d-flex justify-content-center ">
                            <h3 className="t-primary"><RenderMoneda monto={total_banco} /></h3>
                        </div>
                        <div className="col-6 d-flex justify-content-center ">
                            <h3 className="t-primary"><RenderMoneda monto={total_efectivo} /></h3>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row justify-content-center">
                    <div className="col-md-10 row m-0  p-0">
                        <Grid hover striped data={{count: 10, results: total_empresas}} loading={loader}  pagination={false}  >
                            <TableHeaderColumn
                                isKey
                                dataField="empresa"
                                dataSort
                                className="reporte-table"
                                dataFormat = {
                                    (cell) => (empresas_nombre(cell, empresas))
                                }
                            >
                                EMPRESA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                dataSort
                                dataAlign="right"
                                className="reporte-table"
                                dataFormat={
                                    (cell) =><RenderMoneda monto={cell} />
                                }
                            >
                                TOTAL
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <button onClick={previousStep} className="btn btn-secondary  align-self-center">ANTERIOR</button>
                            <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}

totalesForm = reduxForm({
    form: 'planillaForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        const errors = {};
        return errors;
    },
})(totalesForm);

const selector = formValueSelector('planillaForm');
totalesForm = connect(state => {

    const trabajadores = selector(state, 'detalleplanilla');
    const es_borrador = selector(state, 'es_borrador');
    let todos_trabajadores = []
    if(trabajadores){
        todos_trabajadores = trabajadores;
    }

    
    // TOTAL POR TIPO DE PAGO
    
    let total = 0;
    let total_efectivo = 0;
    let total_banco = 0;
    todos_trabajadores.forEach( (item, index) => {
        if (item.trabajador){
            total += item.trabajador.bonos_puesto.total
            total_banco += item.trabajador.bonos_puesto.total_banco
            total_efectivo += item.trabajador.bonos_puesto.total_efectivo
        }
        if (item.trabajador){
            let sueldo = item.trabajador.puesto.total
            total += sueldo
            total_banco += sueldo
        }
        if (item.trabajador){
            total += item.trabajador.actividades.total
            total_banco += item.trabajador.actividades.total_banco
            total_efectivo += item.trabajador.actividades.total_efectivo
        }
        if (item.trabajador){
            total += item.trabajador.descuentos_puesto.total
            total_banco += item.trabajador.descuentos_puesto.total
        }
    })

    //  TOTAL POR EMPRESA
    let suma = 0
    let empresas = new Map()
    todos_trabajadores.forEach((item, index)=>{
        item.trabajador.puesto.sueldo.forEach((sueldo, index)=>{
            if (empresas.has(sueldo.empresa) == false){
                empresas.set(sueldo.empresa, parseFloat(sueldo.sueldo) - parseFloat(sueldo.sueldo_descuento))
            }else{
                empresas.set(sueldo.empresa, empresas.get(sueldo.empresa) + parseFloat(sueldo.sueldo) - parseFloat(sueldo.sueldo_descuento));
            }
        })
        item.trabajador.bonos_puesto.bonos.forEach((bono,index)=>{
            if (empresas.has(bono.empresa) == false){
                empresas.set(bono.empresa, parseFloat(bono.valor) - parseFloat(bono.valor_descuento))
            }else{
                suma = empresas.get(bono.empresa) + (parseFloat(bono.valor) - parseFloat(bono.valor_descuento))
                empresas.set(bono.empresa, suma)
            }
        })
        item.trabajador.actividades.sueldo.forEach((acti, index)=>{
            if (empresas.has(acti.empresa) == false){
                empresas.set(acti.empresa, parseFloat(acti.valor) - parseFloat(acti.valor_descuento))
            }else{
                empresas.set(acti.empresa, empresas.get(acti.empresa) + parseFloat(acti.valor) - parseFloat(acti.valor_descuento));
            }
        })
        item.trabajador.descuentos_puesto.bonos.forEach((des, index)=>{
            let resultado = 0;
            let descuento =  parseFloat(des.valor)
            if (empresas.has(des.empresa) == false){
                empresas.set(des.empresa,  descuento)
            }else{
                resultado = empresas.get(des.empresa)
                resultado = resultado + des.valor
                empresas.set(des.empresa, resultado)
            }
        })
    })
    let total_empresas = []
    empresas.forEach((item, key, map) =>{
        total_empresas.push({empresa: key, total: item})
    })


    return {
        total,
        total_banco,
        total_efectivo,
        todos_trabajadores,
        total_empresas,
        es_borrador
    }
})(totalesForm);

export default totalesForm;