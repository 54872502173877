import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { renderField, renderFieldRadio, renderPercentage, renderCurrency,
    renderTextArea, renderSelecttree, AsyncSelectField } from '../../../../Utils/renderField';


let AumentoPrecioForm = (props) => {
    const {
        handleSubmit,
        texto_boton_movimiento,
        tipo_monetario,
        es_general
    } = props;

    return (
        <form name="AumentoPrecioForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div style={{ border: "1px solid #e8e8e8", padding: "10px" }}>
                <div className="d-flex justify-content-center">
                    <h5 className="t-primary text-uppercase align-self-center">{props.titulo_modal ? props.titulo_modal : ""}</h5>
                </div>
                <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                    <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                        <Field
                            name="tipo_monetario"
                            label="Monetario"
                            component={renderFieldRadio}
                            type="radio"
                            value={"true"}
                            className="form-control" />
                    </div>
                    <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                        <Field
                            value={"false"}
                            name="tipo_monetario"
                            label="Porcentual"
                            component={renderFieldRadio}
                            type="radio"
                            className="form-control" />
                    </div>
                </div>
            </div>
            {
                es_general && (
                    <div className="row">
                    <div className="col-6">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                    {
                        
                        tipo_monetario === "true" ? 
                        <div className="form-group has-feedback col-md-6 col-12">
                            <label className="t-azul" htmlFor="monto">Monto</label>
                                    <Field
                                        name="monto"
                                        label="monto"
                                        prefix={"Q"}
                                        component={renderCurrency}
                                        parse={cell => parseFloat(cell)}
                                        className="form-control"
                                    />
                        </div>
                    :
                        <div className="form-group has-feedback col-md-6 col-12">
                                <label className="t-azul" htmlFor="monto">Porcentaje</label>
                                        <Field
                                            name="porcentaje"
                                            label="porcentaje"
                                            component={renderPercentage}
                                            className="form-control"
                                        />
                        </div>
                    }
                                <button type="submit" className="btn btn-primary m-1 align-self-center">
                                    {
                                        (texto_boton_movimiento) ? (`${texto_boton_movimiento}`) : ("GUARDAR")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !es_general && (
                    <div>
                    {
                        
                        tipo_monetario === "true" ? 
                        <div className="form-group has-feedback col-md-6 col-12">
                            <label className="t-azul" htmlFor="monto">Monto</label>
                                    <Field
                                        name="monto"
                                        label="monto"
                                        prefix={"Q"}
                                        component={renderCurrency}
                                        parse={cell => parseFloat(cell)}
                                        className="form-control"
                                    />
                        </div>
                    :
                        <div className="form-group has-feedback col-md-6 col-12">
                                <label className="t-azul" htmlFor="monto">Porcentaje</label>
                                        <Field
                                            name="porcentaje"
                                            label="porcentaje"
                                            component={renderPercentage}
                                            className="form-control"
                                        />
                        </div>
                    }
                    <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                                <button type="submit" className="btn btn-primary m-1 align-self-center">
                                    {
                                        (texto_boton_movimiento) ? (`${texto_boton_movimiento}`) : ("GUARDAR")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            }
        </form>
    );
};


AumentoPrecioForm = reduxForm({
    form: 'AumentoPrecioForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    initialValues: {
        tipo_monetario: 'true',
    },
    validate: (data) => {
        const errors = {};
        if(!data.monto){
            errors.monto = "Campo requerido.";
        }
        else{
            if(data.monto <= 0){
                errors.monto ="El monto debe ser mayor a 0"
            }
        }
        if(!data.porcentaje){
            errors.porcentaje = "Campo requerido.";
        }
        else{
            if(data.porcentaje <= 0){
                errors.porcentaje ="El porcentaje debe ser mayor a 0"
            }
        }
        return errors;
    },
})(AumentoPrecioForm);
     
const selector = formValueSelector('AumentoPrecioForm');
AumentoPrecioForm = connect((state) => {
    const tipo_monetario_form = selector(state, 'tipo_monetario');
    let tipo_monetario = tipo_monetario_form;

    return {
        tipo_monetario
    };

})(AumentoPrecioForm);

export default AumentoPrecioForm;
