import { connect } from 'react-redux';
/* import {
    actions
} from '../../../../../../redux/modules/gastos/gastos'; */
import {actions as actionsIT} from '../../../../../../redux/modules/itinerarios/itinerarios';
import DesgloseGastos from './DesgloseGastos';
//...state.gastos,
const mstp = state => {
    return { ...state.itinerario }
};

const mdtp = {
    ...actionsIT
};

export default connect(mstp, mdtp)(DesgloseGastos)
