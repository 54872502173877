import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import {standardActions} from '../../../Utils/Grid/StandardActions';
import moment from 'moment';

export default class GridGastos extends Component {

    componentDidMount() {
        //this.props.listarGastos();
    }

    render() {
        const { loader, data, page, listar, onPageChange, onSortChange} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={listar} onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataSort
                        className='reporte-table'
                        dataFormat={(cell)=>(
                                cell ? '' : ''
                            )
                            //standardActions({  eliminarModal: this.props.abrirModalEliminar })
                        }
                        width="10%"
                    />
                    <TableHeaderColumn
                        dataField="vehiculo"
                        dataSort
                        className='reporte-table'
                        width="28%"
                        dataFormat={(cell, row) => (
                            <div>
                                <Fragment>
                                    <span className="font-weight-bold">Tipo: </span>
                                    <span style={{ color: "black" }} >{cell.tipo == 1 ? 'Vehículo' : 'Maquinaria'}</span>
                                    <br />
                                </Fragment>
                                <Fragment>
                                    <span className="font-weight-bold">Placa: </span>
                                    <span style={{ color: "black" }} >{cell.placa}</span>
                                    <br />
                                </Fragment>
                            </div>
                        )}
                    >
                        VEHÍCULO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        className='reporte-table'
                        width="14%"
                        dataFormat={(cell) => moment(cell).format('DD-MM-YYYY')}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="galones_gasolina"
                        dataSort
                        className='reporte-table'
                        width="14%"
                        dataFormat={(cell) => ( cell ? cell : "Sin registro")}
                    >
                        GALONES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataSort
                        className='reporte-table'
                        width="14%"
                        dataFormat={(cell) => ( cell ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }

}
