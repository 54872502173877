import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";
import {RenderDateTimeAll} from "../../../Utils/renderField/renderTableField";

import Anterior from './Anterior';
import Actual from './Actual';

import CardSimple from '../../../Utils/Cards/cardSimple';


class DetalleBitacora extends Component {
    static propTypes = {
        bitacora: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.detail(this.props.match.params.id);
    }

    render() {
        const {cargando, updateData} = this.props;
        // const descripcion = _.find(ACCIONES_BITACORA, {label: bitacora.accion}) ? _.find(ACCIONES_BITACORA, {label: bitacora.accion}) : {};

        return (
            <CardSimple
            titulo="Detalle Bitácora">
                <div className="col-12 px-0">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <LoadMask loading={cargando} light blur>
                                    <div className="d-flex flex-column flex-md-row">
                                        <div className="d-flex flex-2 pl-5">
                                            <span>
                                                <strong className="text-primary subtitulo font-weight-bold pr-3">{ updateData.accion}</strong>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row mb-3">
                                        <div className="d-flex flex-1 pl-5">
                                            <span>
                                                <strong className="text-azul subtitulo font-weight-bold pr-3">Fecha y hora:</strong>
                                            </span>
                                            <RenderDateTimeAll fecha={updateData.creado} ></RenderDateTimeAll>

                                        </div>
                                        <div className="d-flex flex-1">
                                            <span>
                                                <strong  className="text-azul subtitulo font-weight-bold pr-3">Responsable:</strong>
                                            </span>
                                            {
                                                (updateData && updateData.usuario) && (
                                                    <span>{updateData.usuario.first_name} {updateData.usuario.last_name}</span>
                                                )
                                            }

                                        </div>

                                    </div>
                                    <div className="d-flex flex-column flex-sm-row font-italic">
                                        {!!updateData.detalle_anterior && (
                                            <Anterior detalle={JSON.parse(updateData.detalle_anterior)} />
                                        )}
                                        {!!updateData.detalle && (
                                            <Actual detalle={JSON.parse(updateData.detalle)} anterior={JSON.parse(updateData.detalle_anterior)} />
                                        )}
                                    </div>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
        </CardSimple>

        );
    }
}

export default DetalleBitacora;
