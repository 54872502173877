import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderNumber, RenderDateTime, RenderString, dateFormatter } from "Utils/renderField/renderReadField";
import { isArray, isObject } from "utility/validation";
import moment from "moment";
import { formatoMoneda } from '../../../Utils/renderField/renderReadField';

class Actual extends Component {
    static propTypes = {
        detalle: PropTypes.any.isRequired,
        anterior: PropTypes.any,
    };

    constructor(props) {
        super(props);
    }
    formatoLlave(llave) {
       if(!isNaN(parseInt(llave)))
            return parseInt(llave)+1;
        try {
            return llave.split("_").join(" ")
        } catch (e) {
            return llave;
        }
    }

    formatoDetalle(valor, llave, anterior, resaltar){
        try {
            if (isObject(valor)) {
                return (<div key={llave} className="d-flex flex-column px-2 py-1 ml-0 ml-sm-3">
                    <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {Object.keys(valor).sort().map((llave2) => {
                        let comparacion = {};
                        try {
                            comparacion = anterior[llave2];
                        }
                        catch (e) { }
                        return this.formatoDetalle(valor[llave2], llave2, comparacion, resaltar);
                    })}
                </div>)
            }
            if (isArray(valor)) {
                return (<div key={llave} className="d-flex flex-column px-2 py-1 ml-0 ml-sm-3">
                    <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {valor.map((llave2, index) => {
                        let comparacion = {};
                        try {
                            comparacion = anterior[index];
                        }
                        catch (e) { }
                        return this.formatoDetalle(llave2, index, comparacion, resaltar);
                    })}
                </div>)
            }
            if (valor == null) {
                const diferentes = valor !== anterior;
                return (<div key={llave} className={`d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>----------------------</div>
                </div>)
            }
            if (!isNaN(valor) && !(valor === true) && !(valor === false)) {
                const diferentes = valor !== anterior;
                return (<div key={llave} className={`d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><RenderNumber value={valor} /></div>
                </div>)
            }
            if (valor === true) {
                const diferentes = valor !== anterior;
                return (<div key={llave} className={`d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b  className="text-azul"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>Sí</div>
                </div>)
            }
            if (valor === false) {
                const diferentes = valor !== anterior;
                return (<div key={llave} className={`d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>No</div>
                </div>)
            }
            let diferentes = false;
            try {
                diferentes = valor.toString() !== anterior.toString();

            } catch (e) { }
            const fecha_tiempo = moment(valor.toString(), "YYYY-MM-DDThh:mm:ssZ", true);

            if (fecha_tiempo.isValid()) {
                return (<div key={llave} className={`d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><RenderDateTime value={valor} /></div>
                </div>)
            }
            const fecha = moment(valor.toString(), "YYYY-MM-DD", true);

            if (fecha.isValid()) {
                return (<div key={llave} className={`d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><span>{dateFormatter(valor)}</span></div>
                </div>)
            }
            return (<div key={llave} className={`d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}}>{valor}</div>
            </div>)
        } catch (e) {
            return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3">
                <b  className="text-azul my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}} />
            </div>)
        }
    }

    render() {
        const { detalle, anterior } = this.props;
        const resaltar = !!anterior;
        return (
            <div className="d-flex flex-1 flex-column">
               <div className="d-flex flex-row p-2 ml-0 pl-sm-5 justify-content-start background-secondary mb-4">
                    <h6 className="mx-0 my-0 subtitulo py-0 font-weight-bold "><strong>Actual</strong></h6>
                </div>
                {Object.keys(detalle).map((llave) => {
                    let comparacion = {};
                    // try en dado caso el anterior no tiene la llave
                    try {
                        comparacion = anterior[llave]
                    } catch (e) {
                    }
                    return this.formatoDetalle(detalle[llave], llave, comparacion, resaltar);
                })}
            </div>
        )
    }
}

export default Actual;
