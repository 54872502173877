import React, { Component } from 'react';
import _ from 'lodash';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { MESES } from '../../../../../utility/constants';
import ToolbarReporteCobros from '../../../Utils/Toolbar/ToolbarReporteCobros';
import moment from 'moment';
import { Redirect } from 'react-router';

function cellTotal(cell, row) {
    if(row.id == 0){
        return {color: "#00334E", fontSize: 16, fontWeight: "bold"}
    }
    return {}
}

export default class ReporteCuentasPagar extends Component {

    state = {
        meses: [],
    }

    componentDidMount() {
        const { getTotales, listarCobros, fecha_inicio, fecha_fin } = this.props;
        getTotales();
        // listarCobros();
        this.cantidadMeses(fecha_inicio, fecha_fin);
        this.props.selectEmpresas();
    }

    cantidadMeses = (fecha_inicio, fecha_fin) => {
        let meses = [];
        let mes_inicio = fecha_inicio.month() + 1;
        let anio_inicio = fecha_inicio.year();
        const mes_fin = fecha_fin.month() + 1;
        const anio_fin = fecha_fin.year();
        let bandera = true;
        if (mes_inicio == mes_fin && anio_inicio == anio_fin)
            bandera = false;
        while (bandera) {
            const item = _.find(MESES, { value: mes_inicio });
            meses.push(`${item.label}`);
            if (mes_inicio < 12) {
                mes_inicio += 1;
            } else {
                anio_inicio += 1
                mes_inicio = 1
            }

            if (anio_inicio == anio_fin && mes_inicio == mes_fin)
                bandera = false
        }
        // Ultimo mes
        const item = _.find(MESES, { value: mes_fin });
        meses.push(`${item.label}`);
        this.setState({meses: meses});
    };

    validarFecha = (fecha_inicio, fecha_fin) => {
        let diferencia = 1;
        let mes_inicio = fecha_inicio.month() + 1;
        let anio_inicio = fecha_inicio.year();
        const mes_fin = fecha_fin.month() + 1;
        const anio_fin = fecha_fin.year();
        let bandera = true;
        if (mes_inicio == mes_fin && anio_inicio == anio_fin)
            bandera = false;
        if (moment(`${anio_inicio}-${mes_inicio}-1`) > moment(`${anio_fin}-${mes_fin}-1`)){
            console.log("La fecha inicial es mayor")
            diferencia = 13
            bandera = false;
        }
        while (bandera) {
            diferencia += 1;
            if (mes_inicio < 12) {
                mes_inicio += 1;
            } else {
                anio_inicio += 1
                mes_inicio = 1
            }

            if (anio_inicio == anio_fin && mes_inicio == mes_fin)
                bandera = false
        }

        return diferencia <= 12;
    }

    cambiarFecha1 = (value) => {
        const { changeFechaInicio, fecha_inicio, fecha_fin } = this.props;
        if (this.validarFecha(value, fecha_fin)) {
            changeFechaInicio(value);
            this.cantidadMeses(value, fecha_fin);
        }
    };

    cambiarFecha2 = (value) => {
        const { changeFechaFin, fecha_inicio, fecha_fin } = this.props;
        if (this.validarFecha(fecha_inicio, value)) {
            changeFechaFin(value);
            this.cantidadMeses(fecha_inicio, value);
        }
    };

    render() {
        const { loader, resumen, data, listarCobros, page, permisos, empresas, changeEmpresa } = this.props;
        if(permisos.cuentas_mes_pagar === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE DE CUENTAS POR PAGAR POR MESES">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{ padding: "15px" }}>
                            <ToolbarReporteCobros
                                {...this.props}
                                changeFechaInicio={this.cambiarFecha1}
                                changeFechaFin={this.cambiarFecha2}
                                empresas={empresas}
                                change={changeEmpresa}
                                es_pagar={true}
                                // tproducto={true}
                            />
                        </div>
                    </div>

                    <div className="mt-3">
                        <Grid
                            hover
                            striped
                            data={data}
                            loading={loader}
                            page={page}
                            className="reporte-table"
                            onPageChange={listarCobros}
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                className='reporte-table'
                                dataFormat={(cell, row) => (
                                    row.nombre_origen
                                )}
                                tdStyle={cellTotal}
                                width="15%"
                            >
                                PROVEEDOR
                            </TableHeaderColumn>
                            {
                                this.state.meses.map((label, index) => (
                                    <TableHeaderColumn
                                        dataField="meses"
                                        className='reporte-table'
                                        thStyle={{textTransform: "uppercase"}}
                                        dataAlign="right"
                                        tdStyle={cellTotal}
                                        dataFormat={(cell, row) => ( cell[index] ? <RenderMoneda monto={cell[index].total} simbolo={this.props.moneda.simbolo}/> : "Sin registro" )}
                                    >
                                        {label}
                                    </TableHeaderColumn>
                                ))
                            }
                            <TableHeaderColumn
                                dataField="meses"
                                className='reporte-table'
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    let suma = 0;
                                    cell.forEach(mes => suma +=  mes.total);
                                    return (<RenderMoneda monto={suma} simbolo={this.props.moneda.simbolo}/>)
                                }}
                                tdStyle={cellTotal}
                                width="10%"
                            >
                                TOTAL
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>
            </CardSimple>
        )
    }
}
