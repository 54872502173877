import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/ValesGasolina/valesGasolina';
import ValesGrid from './ValesGrid';

const mstp = state => {
    return {...state.valesGasolina ,permisos: state.login.me.permisos, }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ValesGrid)


