import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Link, Redirect } from 'react-router-dom';
import { renderField, renderFieldRadio, renderSelectField, renderCurrency, renderTextArea, renderSelecttree} from '../../../Utils/renderField';

const PossForm = (props) => {
    const { handleSubmit, cuentas} = props;
    return (
        <form name="PossForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-6">
                    <label className="t-azul" htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="cuenta">No. de Cuenta</label>
                            <Field
                                name="cuenta"
                                label="cuenta"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={cuentas}
                                className="form-control"
                            />
                        </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-sm-12  col-12">
                    <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        type="text"
                        className="form-control" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <Link className="btn btn-secondary  align-self-center" to="/poss" >CANCELAR</Link>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'PossForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
        });
    },
})(PossForm);
