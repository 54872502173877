import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderAntDatePicer } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";


const RegionForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="RegionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="region">Región</label>
                    <Field name="region" label="region" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul"  htmlFor="descripcion">Descripción</label>
                    <Field name="descripcion" label="descripcion" component={renderTextArea} top={{bottom: '0'}} type="text" className="form-control" />
                </div>
            </div>
            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/regiones" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'RegionForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            region: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(RegionForm);
