import React, { Component } from 'react'
import {Link, Redirect} from 'react-router-dom'
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
// formulario
import DespachoForm from './despachoForm';


export default class DespachoContainer extends Component {
    componentDidMount(){
        let id = this.props.match.params.id
        if (id != undefined && id != null){
            this.props.inicializarDespacho(id);
        }else{
            return (<Redirect to="/devoluciones" />)
        }
    }
    handleSubmit = (values) => {
        const { realizarDespachos, loader } = this.props;
        if(loader === false) {
            const body = {
                id: this.props.match.params.id,
                Despachos: values.Despachos
            };
            let id = this.props.match.params.id
            console.log('submit', body)
            realizarDespachos(body);
        }
    }
    render() {
        const {loader } = this.props;
        return (
            <CardFormulario titulo={"Orden de despacho"}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <DespachoForm
                        orden={this.props.match.params.id}
                        onSubmit={this.handleSubmit}
                        initialValues={{tipo_movimiento:40}}
                    />
                </LoadMask>
            </CardFormulario>
        )
    }
}
