import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_VEHICULO_LOADER',
    RESUMEN: 'REPORTE_VEHICULO_RESUMEN',
    DATA: 'REPORTE_VEHICULO_DATA',
    DATA2: 'REPORTE_VEHICULO_DATA2',
    DATA3: 'REPORTE_VEHICULO_DATA3',
    PAGE: 'REPORTE_VEHICULO_PAGE',
    PAGE2: 'REPORTE_VEHICULO_PAGE2',
    PAGE3: 'REPORTE_VEHICULO_PAGE3',
    ORDERING: 'REPORTE_VEHICULO_ORDERING',
    SET_VEHICULO: 'SET_VEHICULO',
    SET_FECHA_I: 'SET_FECHA_I',
    SET_FECHA_F: 'SET_FECHA_F',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = (type, page) => ({
    type,
    page,
});

const setVehiculo = vehiculo =>({
    type:constants.SET_VEHICULO,
    vehiculo
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});


// ACTIONS

const getResumen = () => (dispatch, getStore) => {
    const resource = getStore().reporteVehiculos;
    const params = { };
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`${url}/resumen_vehiculos`, params).then((response) => {
        response.total = response.total_gastos + response.total_compras + response.total_repuestos
        dispatch(setData(constants.RESUMEN ,response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarCompras = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteVehiculos;
    const params = { page };
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`${url}/compras_vehiculos`, params).then((response) => {
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(constants.PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarGastos = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteVehiculos;
    const params = { page };
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`${url}/gastos_vehiculos`, params).then((response) => {
        dispatch(setData(constants.DATA2, response));
        dispatch(setPage(constants.PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarRepuestos = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteVehiculos;
    const params = { page };
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`${url}/repuestos_vehiculos`, params).then((response) => {
        console.log(response)
        dispatch(setData(constants.DATA3, response));
        dispatch(setPage(constants.PAGE3, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeVehiculo = (value) => (dispatch) => {
    dispatch(setVehiculo(value));
    dispatch(getResumen());
    dispatch(listarCompras(1));
    dispatch(listarGastos(1));
    dispatch(listarRepuestos(1));
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(getResumen());
    dispatch(listarCompras(1));
    dispatch(listarGastos(1));
    dispatch(listarRepuestos(1));
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(getResumen());
    dispatch(listarCompras(1));
    dispatch(listarGastos(1));
    dispatch(listarRepuestos(1));
};

export const actions = {
    getResumen,
    listarCompras,
    listarGastos,
    listarRepuestos,
    changeVehiculo,
    changeFechaInicio,
    changeFechaFin,
}

export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.RESUMEN]:(state, { data }) => {
        return{
            ...state,
            resumen: data
        }
    },
    [constants.DATA]:(state, { data }) => {
        return{
            ...state,
            data: data
        }
    },
    [constants.DATA2]:(state, { data }) => {
        return{
            ...state,
            data2: data
        }
    },
    [constants.DATA3]:(state, { data }) => {
        return{
            ...state,
            data3: data
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page: page,
        };
    },
    [constants.PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [constants.PAGE3]: (state, { page }) => {
        return {
            ...state,
            page3: page,
        };
    },
    [constants.SET_VEHICULO]: (state, { vehiculo }) => {
        return {
            ...state,
            vehiculo
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
}

export const initialState = {
    loader:false,
    resumen: {},
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    data3: {
        results: [],
        count: 0,
    },
    page: 1,
    page2: 1,
    page3: 1,
    vehiculo: null,
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
}

export default handleActions(reducers, initialState);
