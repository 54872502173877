import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'cuentas' ;
let url_liquidaciones = 'liquidaciones'
let form = 'EgresoForm';
let dirGridCuentas = '/caja';

export const constants ={
    LOADER: 'CAJA_LOADER',
    DATA: 'CAJA_DATA',
    ITEM: `CAJA_ITEM`,
    ITEM_LIQUIDACION: `ITEM_LIQUIDACION`,
    PAGE: `CAJA_PAGE`,
    PAGEHISTORIAL: `CAJA_PAGEHISTORIAL`,
    DATA_HISTORIAL_LIQUIDACIONES:'DATA_HISTORIAL_LIQUIDACIONES',
    ORDERING: `CAJA_ORDERING`,
    SEARCH: `CAJA_SEARCH`,
    SET_EMPRESAS: 'SET_EMPRESAS',
    SET_PENDIENTES:'SET_PENDIENTES',
    SET_ESTADO: 'SET_ESTADO',
    SET_HISTORIAL: 'SET_HISTORIAL',
    SET_ESTADOP: 'SET_ESTADOP',
    SET_MODAL: 'SET_MODAL',
    SET_DATE_START: 'SET_DATE_START',
    SET_DATE_END: 'SET_DATE_END',
    VALUE_MOVIMIENTO: 'VALUE_MOVIMIENTO_CAJA',
    SET_TOTAL_DEBE: 'SET_TOTAL_DEBE_CAJA',
    SET_TOTAL_HABER: 'SET_TOTAL_HABER_CAJA',
    SET_OCULTAR_BOTON: 'SET_OCULTAR_BOTON',
    //modal de caja
    SET_DATE_START_MODAL: 'SET_DATE_START_MODAL',
    SET_DATE_END_MODAL: 'SET_DATE_END_MODAL',
    VALUE_MOVIMIENTO_MODAL: 'VALUE_MOVIMIENTO_MODAL',
    SEARCH_MODAL: `CAJA_MOVIMIENTOS_SEARCH_MODAL`,
    ESTADO_PERIODO_ID: `ESTADO_PERIODO_ID`,
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setPageHistorial = page => ({
    type: constants.PAGEHISTORIAL,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().caja;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.cob = 1;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch({type: constants.SET_OCULTAR_BOTON, boton: false})
    });
};

const leer = id => (dispatch) => {
    let params={"cob": 1}
    dispatch(setLoader(true));
    api.get(`${url}/${id}`, params).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        // if (!!form)
            // dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (!!dirGridCuentas)
            dispatch(push(dirGridCuentas));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGridCuentas)
            dispatch(push(dirGridCuentas));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch, getStore) => {
    const resource = getStore().caja;
    dispatch(setSearch(search));
    dispatch(estadoMiCaja(resource.item.id))
    dispatch(listar())
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().caja.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const movimiento = (data, id)=> dispatch => {
    dispatch(setLoader(true));
    dispatch({type: constants.SET_OCULTAR_BOTON, boton: true})
    api.post(`${url}/moveracaja`, data).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(listPCaja(id));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listPCaja = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id}
    api.get(`${url}/listTransaccionescaja`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const imprimirDetalleLiquidacionEP = (liquidacion_id) => (dispatch, getStore) => {
    window.open(`/api/${url_liquidaciones}/imprimir_detalle/?id=${liquidacion_id}&auth_token=${localStorage.getItem("token")}`, '_blank');
};

const estadoMiCaja = (id=1) => (dispatch, getStore) =>{
    dispatch(setLoader(true));
    const resource = getStore().caja;
    let total_debe = 0;
    let total_haber =0;
    let params = {id}
    if(resource.dateStart != null){
        params.fecha_inicial= resource.dateStart.format("YYYY-MM-DD");
    }
    if(resource.dateEnd != null){
        params.fecha_final= resource.dateEnd.format("YYYY-MM-DD");
    }
    if(resource.valorMovimiento != null){
        params.es_ingreso = resource.valorMovimiento.value
    }
    if(resource.search != null){
        params.search = resource.search
    }
    api.get(`${url}/estadomicaja`, params).then((res)=>{
        res=res.filter(item=>item.id)
        dispatch(setData(constants.SET_ESTADO, res))
        res.forEach(movimiento =>{
            total_debe += movimiento.debe;
            total_haber += movimiento.haber;
        })
        dispatch({type: constants.SET_TOTAL_DEBE, total_debe: total_debe})
        dispatch({type: constants.SET_TOTAL_HABER, total_haber: total_haber})
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
    dispatch(setLoader(true));
    params.page=1
    params.ordering = ""
    params.caja= id
    api.get(`periodos`, params).then((response) => {
        dispatch({type:constants.SET_HISTORIAL, historial:response});
        dispatch(setPageHistorial(1));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const AcceptMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/acceptmovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(estadoMiCaja(caja));
        dispatch(listPCaja(caja));
        dispatch(leer(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarHistorialLiquidaciones = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().caja;
    const permisos = getStore().login.me.permisos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));

    if (permisos.ver_todo_liquidacion_historial == true) params.ver_todo = true;

    api.get(`${url_liquidaciones}/historial_liquidaciones`, params).then((res)=>{
        dispatch(setData(constants.DATA_HISTORIAL_LIQUIDACIONES, res));
        dispatch(setPage(page));
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
};

const getLiquidacionEP = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url_liquidaciones}/${id}`).then((response) => {
        dispatch({type: constants.ITEM_LIQUIDACION, item_liquidacion: response});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const RefuseMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/refusemovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        // dispatch(estadoMiCaja(caja));
        // dispatch(leer(caja));
        dispatch(listPCaja(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const CancelMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/cancelmovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        // dispatch(estadoMiCaja(caja));
        // dispatch(leer(caja));
        dispatch(listPCaja(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cierredecaja = (caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/cierremicaja`, {id: caja}).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(estadoMiCaja(caja));
        dispatch(leer(caja));
        // dispatch(listPCaja(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const HistorialMiCaja = (page = 1, caja) => (dispatch, getStore) => {
    const resource = getStore().caja;
    const params = { page };
    params.ordering = resource.ordering;
    //params.search = resource.search;
    params.caja = resource.item.id
    dispatch(setLoader(true));
    api.get(`periodos`, params).then((response) => {
        dispatch({type:constants.SET_HISTORIAL, historial:response});
        dispatch(setPageHistorial(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const estadoPeriodo = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id}
    dispatch({type:constants.ESTADO_PERIODO_ID, estadoPeriodoId:id});
    api.get(`${url}/estadoperiodo`, params).then((res)=>{
        dispatch(setData(constants.SET_ESTADOP, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const cerrarEstadoPeriodo = () => (dispatch, getStore) =>{
    dispatch(setLoader(true));
    const resource = getStore().caja;
    let id = resource.historial.results[0].id
    let params = {id}
    api.get(`${url}/estadoperiodo`, params).then((res)=>{
        dispatch(setData(constants.SET_ESTADOP, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const retiroDeCaja = (data, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/retirardecaja`, data).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(estadoMiCaja(caja));
        dispatch(listPCaja(caja));
        dispatch(leer(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}
const setModalDescripcion = (estado) => dispatch => {
    dispatch({
        type: constants.SET_MODAL,
        data: estado
    });
}

const setDateStart = (value) => (dispatch, getStore) => {
    const resource = getStore().caja;
    dispatch({type: constants.SET_DATE_START, dateStart: value})
    dispatch(estadoMiCaja(resource.item.id))
}
const setDateEnd = (value) => (dispatch, getStore) => {
    const resource = getStore().caja;
    dispatch({type: constants.SET_DATE_END, dateEnd: value})
    dispatch(estadoMiCaja(resource.item.id))
}
const changeMovimiento = valorMovimiento => (dispatch, getStore) => {
    const resource = getStore().caja;
    dispatch({
        type: constants.VALUE_MOVIMIENTO,
        valorMovimiento
    })
    dispatch(estadoMiCaja(resource.item.id))
};


//petición para obtener el historial de movimientos
const _getDataMovimientos = () => (dispatch, getStore)  => {
    dispatch(setLoader(true));
    const resource = getStore().caja;
    let params = {id: resource.estadoPeriodoId}
    if(resource.dateStartModal != null){
        params.fecha_inicial= resource.dateStartModal.format("YYYY-MM-DD");
    }
    if(resource.dateEndModal != null){
        params.fecha_final= resource.dateEndModal.format("YYYY-MM-DD");
    }
    if(resource.valorMovimientoModal != null){
        params.es_ingreso = resource.valorMovimientoModal.value
    }
    if(resource.searchModal != null){
        params.search = resource.searchModal
    }
    console.log(params)
    api.get(`${url}/estadoperiodoFilter`, params).then((data)=>{
        console.log(data)
        dispatch({type:constants.SET_ESTADOP, data})
    }).catch((data)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

//Actions para Modal de historial de movimientos
const setDateStartModal = (value) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_DATE_START_MODAL,
        dateStartModal: value
    })
    dispatch(_getDataMovimientos());
};
const setDateEndModal = (value) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_DATE_END_MODAL,
        dateEndModal: value
    })
    dispatch(_getDataMovimientos());
};
const changeMovimientoModal = valorMovimientoModal => (dispatch, getStore) => {
    dispatch({
        type: constants.VALUE_MOVIMIENTO_MODAL,
        valorMovimientoModal
    })
    dispatch(_getDataMovimientos());
};

const searchChangeModal = searchModal => (dispatch, getStore) => {
    dispatch({
        type: constants.SEARCH_MODAL,
        searchModal,
    })
    dispatch(_getDataMovimientos());
};



export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    selectEmpresas,
    movimiento,
    listPCaja,
    listarHistorialLiquidaciones,
    getLiquidacionEP,
    estadoMiCaja,
    AcceptMovimiento,
    RefuseMovimiento,
    CancelMovimiento,
    HistorialMiCaja,
    cierredecaja,
    estadoPeriodo,
    cerrarEstadoPeriodo,
    retiroDeCaja,
    setModalDescripcion,
    setDateStart,
    setDateEnd,
    changeMovimiento,
    setDateStartModal,
    setDateEndModal,
    changeMovimientoModal,
    imprimirDetalleLiquidacionEP,
    searchChangeModal,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.ITEM_LIQUIDACION]: (state, { item_liquidacion }) => {
        return {
            ...state,
            item_liquidacion,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGEHISTORIAL]: (state, { page }) => {
        return {
            ...state,
            pagehistorial:page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.SET_ESTADO]:(state,{ data })=>{
        return{
            ...state,
            estado: data,
        }
    },
    [constants.SET_HISTORIAL]:(state,{ historial })=>{
        return{
            ...state,
            historial,
        }
    },
    [constants.SET_ESTADOP]:(state,{ data })=>{
        return{
            ...state,
            periodo: data,
        }
    },
    [constants.SET_MODAL]:(state,{ data })=>{
        return{
            ...state,
            open_modal: data,
        }
    },
    [constants.SET_DATE_START]: (state, { dateStart }) => {
        return {
            ...state,
            dateStart
        }
    },
    [constants.SET_DATE_END]: (state, { dateEnd }) => {
        return {
            ...state,
            dateEnd
        }
    },
    [constants.VALUE_MOVIMIENTO]:(state,{ valorMovimiento })=>{
        return{
            ...state,
            valorMovimiento,
        }
    },
    [constants.SET_TOTAL_DEBE]:(state,{ total_debe })=>{
        return{
            ...state,
            total_debe,
        }
    },
    [constants.SET_TOTAL_HABER]:(state,{ total_haber })=>{
        return{
            ...state,
            total_haber,
        }
    },
    [constants.SET_OCULTAR_BOTON]:(state,{ boton })=>{
        return{
            ...state,
            boton,
        }
    },
    [constants.DATA_HISTORIAL_LIQUIDACIONES]:(state,{data}) => {
        return{
            ...state,
            dataHistorialLiquidaciones: data
        }
    },
    // Reducers para modal
    [constants.SET_DATE_START_MODAL]: (state, { dateStartModal }) => {
        return {
            ...state,
            dateStartModal
        }
    },
    [constants.SET_DATE_END_MODAL]: (state, { dateEndModal }) => {
        return {
            ...state,
            dateEndModal
        }
    },
    [constants.VALUE_MOVIMIENTO_MODAL]:(state,{ valorMovimientoModal })=>{
        return{
            ...state,
            valorMovimientoModal,
        }
    },
    [constants.SEARCH_MODAL]: (state, { searchModal }) => {
        return {
            ...state,
            searchModal,
        };
    },
    [constants.ESTADO_PERIODO_ID]: (state, { estadoPeriodoId }) => {
        return {
            ...state,
            estadoPeriodoId,
        };
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    dataHistorialLiquidaciones: {
        results: [],
        count: 0,
    },
    item: {},
    item_liquidacion: {},
    page: 1,
    pagehistorial: 1,
    ordering: '',
    search: '',
    empresas:[],
    pendientes:[],
    estado:[],
    historial:{
        results: [],
        count: 0,
    },
    periodo:[],
    open_modal: false,
    dateStart: null,
    dateEnd: null,
    valorMovimiento: null,
    total_debe: 0,
    total_haber: 0,
    boton: false,
    // MODAL
    dateStartModal: null,
    dateEndModal: null,
    valorMovimientoModal: null,
    searchModal: '',
    estadoPeriodoId: null,
};

export default handleActions(reducers, initialState);
