import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/Reporte/dashboard';
import Home from './Home';

const mstp = state => {
    return {
        ...state.dashboard,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions,
};

export default connect(mstp,mdtp)(Home);
