import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';

import moment from 'moment';


export default class AusenciasGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos.ausencias === false) {
            if(!!permisos.ver_ausencias === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton="Reportar Ausencia"
                    ruta="ausencias/nuevo"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={permisos.ausencias}
                />}
                titulo="Listado de Ausencias">
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {permisos.ausencias ? (
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                width="10%"
                                dataSort
                                isKey
                                dataFormat={
                                    standardActions({ eliminar })
                                }
                            />
                        ): (
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                width="2%"
                                dataSort
                                isKey
                                dataFormat={cell => ""}
                            />
                        )}
                        <TableHeaderColumn

                            dataField="fecha_inicial"
                            dataAlign="center"
                            dataSort
                            dataFormat = {(cell, row)=> `${moment(cell).format("L") }   ${(row.fecha_final != null && row.fecha_final != undefined)  ?  `- ${moment(row.fecha_final).format("L")}`: ""}`}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="empleado"
                            dataSort
                            dataFormat = {(cell, row)=> `${cell.nombre} ${cell.apellido}`}
                        >
                            EMPLEADO
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn

                            dataField="empresa_actividad"
                            dataSort
                            dataFormat = {(cell, row)=> cell.nombre}
                        >
                            EMPRESA
                        </TableHeaderColumn> */}
                        <TableHeaderColumn

                            dataField="total_dias_ausencia"
                            dataSort
                            dataAlign="center"
                            tdStyle={{paddingRight: "2%"}}
                            thStyle={{paddingRight: "2%"}}
                            dataFormat = {(cell, row)=> <RenderNumber value={cell} decimalScale={2} /> }
                        >
                            DIAS AUSENTADAS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_ausencia"
                            dataSort
                        >
                            TIPO DE AUSENCIA
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="descripcion"
                            dataSort
                            // dataFormat = {(cell, row)=> (
                            //     cell == 1 ? "Horario normal" : "Horario extra"
                            // )}
                        >
                            
                            DESCRIPCION
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
