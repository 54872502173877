import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/ingreso_egreso';
import IngresoEgresoReporte from './ingreso_egreso';

const mstp = state => {
    return {...state.r_ingreso_egreso, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(IngresoEgresoReporte)
