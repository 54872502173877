import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderFieldRadio, SelectField, renderSelecttree, renderFieldCheck,  } from '../../../Utils/renderField';
import {renderTextArea} from "../../../Utils/renderField/renderField";
import { Link } from 'react-router-dom';
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";

const origen_pago = [
    {label: 'Banco', value: 10},
    {label: 'Efectivo', value: 20},
]

let Bonificacionform = (props) => {
    const { handleSubmit, pristine, reset, opcion_monto, onChangeTitulo, tipo_gastos, es_anual } = props;
    return (
        <form name="Bonificacionform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <Field
                        name="es_bonificacion"
                        label="Bonificación"
                        component={renderFieldRadio}
                        type="radio"
                        value={"true"}
                        // parse={(value)=> value=="true"}
                        onChange={(e) => {
                            onChangeTitulo(e.target.value)
                        }}
                        className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <Field
                        name="es_bonificacion"
                        label="Descuento"
                        component={renderFieldRadio}
                        type="radio"
                        value={"false"}
                        onChange={(e) => {
                            onChangeTitulo(e.target.value)
                        }}
                        className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-4 col-12">
                    <div className="form-group has-feedback col-12 p-0">
                        <label className="t-azul" htmlFor="nombre">Nombre</label>
                        <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    {
                        opcion_monto && (
                            <div className="form-group has-feedback col-12 p-0">
                                <label className="t-azul"  htmlFor="monto">Monto</label>
                                <Field name="monto" label="monto" component={renderCurrency} type="text" className="form-control" />
                            </div>
                        )
                    }
                </div>
            </div>
            {
                (opcion_monto) && (
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-12">
                            <div className="form-group has-feedback col-12 p-0">
                                <label className="t-azul" htmlFor="origen_pago">Origen de pago</label>
                                <Field name="origen_pago" label="origen_pago" component={SelectField}
                                    options={origen_pago}
                                    type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="form-group has-feedback col-12 p-0">
                                <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                                <Field
                                    name="tipo_gasto"
                                    label="nombre"
                                    component={renderSelecttree}
                                    showSearch = { true }
                                    filtro = "title"
                                    options={tipo_gastos}
                                    type="text"
                                    valueKey="value"
                                    labelKey="label"
                                    className="form-control" 
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="row justify-content-center">
                {
                    (opcion_monto) && (
                        <div className="form-group has-feedback col-md-4 col-12">
                            <label className="t-azul" htmlFor="descripcion">Anual*</label>
                            {/* <Field name="fecha_fundacion" label="fecha_fundacion" component={renderDatePicker} className="form-control" /> */}
                            <Field 
                                name="es_anual" label="" 
                                component={renderFieldCheck} 
                                className="form-control" 
                                onChange={(e)=>{
                                    props.setFecha_anual(e.target.value)
                                }}
                            />
                    </div>
                    )
                }
                <div className="form-group has-feedback col-md-4 col-12">
                    {
                        es_anual && (
                            <div>
                                <label className="t-azul" htmlFor="descripcion">Fecha de cobro</label>
                                <Field 
                                    name="fecha_pago_anual" 
                                    selectedDate={new Date()}  
                                    maxDate="2030-12-31"
                                    minDate="1940-01-01"
                                    className="form-control" 
                                    component={renderDropdownDateField}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-8 col-12">
                <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        className="form-control"
                        rows={3}
                        top={{top:'96px'}}
                    />
                </div>
            </div>
            <br/>
            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/bonificaciones" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


Bonificacionform = reduxForm({
    form: 'Bonificacionform', // a unique identifier for this form,
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            es_bonificacion: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(Bonificacionform);

const selector = formValueSelector('Bonificacionform');
Bonificacionform = connect((state, ownProps) =>{
    const es_bonificacion = selector(state, 'es_bonificacion')
    const es_anual = selector(state, 'es_anual')
    let opcion_monto = true
    if(es_bonificacion == "true" || es_bonificacion == undefined){
        opcion_monto = true;
    }else {
        opcion_monto = false;
    }

    return{
        opcion_monto,
        es_anual,
    }
})(Bonificacionform)


export default Bonificacionform
