import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/deuda_interna';

import DetalleDeudaInterna from './deudaDetalleEmpresaInterna';

const mstp = state => {
    return {
        ...state.ReportesInternos,
        permisos: state.login.me.permisos,
        usuario: state.login.me.id,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetalleDeudaInterna)
