import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_PRODUCTOS_LOADER',
    DATA: "REPORTE_PRODUCTOS_DATA",
    ITEM: "REPORTE_PRODUCTOS_ITEM",
    PAGE: `REPORTE_PRODUCTOS_PAGE`,
    SET_FECHA_I: 'SET_FECHA_I_REPORTE_PRODUCTOS',
    SET_FECHA_F: 'SET_FECHA_F_REPORTE_PRODUCTOS',
    SET_MODAL_ANULACION: 'SET_MODAL_ANULACION_REPORTE_PRODUCTOS',
    SET_EMPRESA: 'SET_EMPRESA',
    SET_TIPO_PRODUCTO: 'SET_TIPO_PRODUCTO',
    SET_PRODUCTO: 'SET_PRODUCTO',
    SET_CLIENTE: 'SET_CLIENTE_PRODUCTOS',
    SET_VENDEDOR: 'SET_VENDEDOR_PRODUCTOS',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type:type,
    data
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};


const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});

const setEmpresa = empresa => ({
    type: constants.SET_EMPRESA,
    empresa
})

const setTipoProducto = tipoProducto => ({
    type: constants.SET_TIPO_PRODUCTO,
    tipoProducto
})

const setProducto = producto => ({
    type: constants.SET_PRODUCTO,
    producto
})

// ACTIONS

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteProductos;
    const params = { page };
    if (resource.fecha_inicio)
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_final = moment(resource.fecha_fin).format("YYYY-MM-DD")
    if(resource.empresa){
        params.empresa = resource.empresa.id
    }
    if(resource.tipoProducto){
        params.tipo_producto = resource.tipoProducto.value
    }
    if(resource.producto){
        params.producto = resource.producto.id
    }
    if(resource.cliente){
        params.cliente = resource.cliente.id
    }
    if(resource.vendedor){
        params.vendedor = resource.vendedor.id
    }
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = me.id
    }
    dispatch(setLoader(true));
    api.get(`${url}/productos_mas_vendidos`, params).then((response) => {
        response.totalProducto = response.results.reduce(
            (sum, value) => (
                typeof value.total == "number" 
                ? sum + value.total 
                : sum), 0);
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const mensajeError = (error) => {
    let mensaje = 'Error en la creación de la ruta';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
}

const changeEmpresa = (value) => (dispatch) => {
    dispatch(setEmpresa(value));
    dispatch(listar());
};

const changeTipoProducto = value => (dispatch) => {
    dispatch(setTipoProducto(value));
    dispatch(listar())
}

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(listar());
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(listar());
};

const changeProducto = (value) => (dispatch) => {
    dispatch(setProducto(value));
    dispatch(listar())
};

const changeCliente = (value) => (dispatch) => {
    dispatch(setData(constants.SET_CLIENTE, value));
    dispatch(listar());
};

const changeVendedor = (value) => (dispatch) => {
    dispatch(setData(constants.SET_VENDEDOR, value));
    dispatch(listar());
};

export const actions = {
    listar,
    changeFechaInicio,
    changeFechaFin,
    changeEmpresa,
    changeTipoProducto,
    changeProducto,
    changeCliente,
    changeVendedor
}

export const reducers = {
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) =>{
        return{
            ...state,
            data,
        };
    },
    [constants.ITEM]:(state,{item}) =>{
        return{
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
    [constants.SET_EMPRESA]: (state, { empresa }) => {
        return {
            ...state,
            empresa
        };
    },
    [constants.SET_TIPO_PRODUCTO]: (state, { tipoProducto }) => {
        return {
            ...state,
            tipoProducto
        };
    },
    [constants.SET_PRODUCTO]: (state, { producto }) => {
        return {
            ...state,
            producto
        };
    },
    [constants.SET_CLIENTE]: (state, { data }) => {
        return {
            ...state,
            cliente: data
        };
    },
    [constants.SET_VENDEDOR]: (state, { data }) => {
        return {
            ...state,
            vendedor: data
        };
    },
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    fecha_inicio: moment().startOf('month'),
    fecha_fin: moment(),
    empresa: undefined,
    tipoProducto: undefined,
    producto: undefined,
    cliente: null,
    vendedor: null,
}

export default handleActions(reducers, initialState);
