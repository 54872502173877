import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import BonosDescuentosGrid from './bonosgrid';

export default class BonificacionesGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };
    state = {
        aprube:true,
    }
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }
    handleData =(value)=>{
        this.setState({aprube:value})
    }
    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos.bonificaciones === false) {
            if(!!permisos.ver_bonificaciones === false) {
                return <Redirect to={`/`} />
            }
        }
        return(
            <div className="Container bg-white">
                <h3 className="t-primary m-t p-t">BONIFICACIONES Y DESCUENTOS</h3>
                <div className="row justify-content-end">
                    <div className="col-md-8 col-12">
                        <ToolbarSimple
                            textBoton="AGREGAR"
                            ruta="/bonificacion_descuento/nuevo"
                            buscar={this.state.aprube? this.props.searchChange:this.props.searchChangeRefuse}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre"}
                            permisos={permisos.bonificaciones}
                        />
                        {/* <Link className="btn btn-primary" to="/empresa/nuevo">AGREGAR</Link> */}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                {/* <BonosDescuentosGrid
                                    data={data}
                                    listar={this.props.listar}
                                    loader={loader}
                                    page={page}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                    eliminar={eliminar}
                                    handleData={this.handleData}
                                    value={true}
                                    permiso={permisos.bonificaciones}
                                /> */}
                                <Tabs
                                    defaultActiveKey="TIPOBONIFICACIONES"
                                    tabBarPoition = "top"
                                    onChange={(key) => {
                                        key === 'BONIFICACIONES' ? this.handleData(true): this.handleData(false)
                                    }}
                                    renderTabBar ={()=><ScrollableInkTabBar/>}
                                    renderTabContent={()=><TabContent/>}
                                >
                                    <TabPane tab="BONIFICACIONES" key="BONIFICACIONES">
                                        <BonosDescuentosGrid
                                            data={data}
                                            listar={this.props.listar}
                                            loader={loader}
                                            page={page}
                                            onPageChange={onPageChange}
                                            onSortChange={onSortChange}
                                            eliminar={eliminar}
                                            handleData={this.handleData}
                                            value={true}
                                            permiso={permisos.bonificaciones}
                                        />
                                    </TabPane>
                                    <TabPane tab="DESCUENTOS" key="DESCUENTOS">
                                        <BonosDescuentosGrid
                                            data={this.props.datarefuse}
                                            listar={this.props.listarRefuse}
                                            loader={loader}
                                            page={page}
                                            onPageChange={onPageChange}
                                            onSortChange={this.props.onSortChangeRefuse}
                                            eliminar={eliminar}
                                            handleData={this.handleData}
                                            value={false}
                                            permiso={permisos.bonificaciones}
                                        />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

