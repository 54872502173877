import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/Reporte/reporteProduccion';
import ReporteConsumo from './ReporteConsumo';

const mstp = state => {
    return {
        ...state.reporteProduccion,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteConsumo)
