import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {customStandardActions} from "../../../Utils/Grid/StandardActions";
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

export default class TipoProductoGrid extends Component {

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {

        const { data,page, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos["tproducto"] === false) {
            if(!!permisos[`ver_tproducto`] === false) {
                return <Redirect to={`/`} />
            }
        } 
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton={permisos.crear_tproducto ? "Agregar" : ""}
                    ruta="tipoproducto/crear"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    // tituloBuscador={"Buscar por: Nombre"}
                    placeholder={"Buscar por: Nombre"}
                    // margen = "mt-40"
                    permisos={permisos.tproducto}
                  />}
                titulo="Tipo de productos">
                    <Grid hover page={page} striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {permisos.tproducto && (<TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell) => {
                                return customStandardActions(cell, {
                                    eliminar: permisos.eliminar_tproducto ? eliminar: undefined,
                                    editar: permisos.editar_tproducto ? "tipoproducto": undefined,
                                });
                            }}
                        >
                        </TableHeaderColumn>)}
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
