import React, { Component, Fragment } from 'react'
import { RenderMoneda } from "Utils/renderField/renderTableField";

export default class RowGrafica extends Component {
    render() {
        let {item, index, abrir_categoria} = this.props;

        let nivel = item.level;
        let clase_margen = `ml-${nivel + 1}`
        return (
            <Fragment>
                <tr key={index} >
                    <td className=" sin-borde-top">
                        <span className={`${nivel == 0 ? 'font-weight-bold':''} ${clase_margen}`} >
                            {
                                 item.level == 0 ?
                                 !item.selectable ?
                                 item.seleccionado ? 
                                 <span className="m-none t-mostaza semibold"> {item.title}
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                  </span>
                                 :
                                 <span className="m-none t-musgo semibold"> {item.title}
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                  </span>
                                  :
                                  <span className="m-none t-mostaza semibold">{item.title}</span>
                                 :
                                 item.level == 1 ?
                                 !item.selectable ?
                                 item.seleccionado ?
                                 <span className="m-none t-musgo semibold"> {item.title} 
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                 </span>
                                 :
                                 <span className="m-none t-musgo semibold"> {item.title} 
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                 </span>
                                 :<span className="m-none t-musgo semibold">{item.title}</span>
                                 :
                                 item.level == 2 ?
                                 !item.selectable ?
                                 item.seleccionado ?
                                 <span className="m-none t-primary semibold"> {item.title} 
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                 </span>
                                 :
                                 <span className="m-none t-primary semibold"> {item.title} 
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                 </span>
                                 :<span className="m-none t-primary semibold">{item.title}</span>
                                 :
                                 item.level == 3 ?
                                 !item.selectable ?
                                 item.seleccionado ?
                                 <span className="m-none t-azul semibold"> {item.title} 
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                 </span>
                                 :
                                 <span className="m-none t-azul semibold"> {item.title} 
                                 &nbsp; &nbsp;
                                 <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                     title="Abrir" campo
                                     onClick={() => {
                                         abrir_categoria(item.value), this.forceUpdate()
                                     }}
                                 />
                                 </span>
                                 :<span className="m-none t-azul semibold">{item.title}</span>
                                 :
                                 <div>
                                     {item.title}
                                     
                                 </div>
                                 
                            
                                
                            }
                            
                        </span>
                    </td>
                    <td className="sin-borde-top">
                        <div className={`${nivel == 0 ? 'font-weight-bold':''} `} >
                            <RenderMoneda
                                monto={item.monto}
                                simbolo={'Q'}
                            />
                        </div>

                    </td>
                </tr>
                 {
                     item.seleccionado && (
                     item.children.map((_sug_gasto, _index) => {
                         return(
                             <Fragment key={_index}>
                                 {
                                    _sug_gasto.monto > 0.00 && (
                                       <RowGrafica item={_sug_gasto} index={_index} abrir_categoria={abrir_categoria} />
                                    )
                                 }
                            </Fragment>
                         )
                     })
                     )
                 }
            </Fragment>
        )
    }
}
