import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Grid from '../../../Utils/Grid/index';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import ImagenAlerta from '../../../../../../assets/img/icons/alarm.png'
class TransaccionesGrid extends Component{
    state = {
        openhistory: true
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }

    render(){
        let {data, onPageChange, page, loader } = this.props
        let datos = {results: data}
        return(
            <LoadMask loader = {loader}>
                <Grid hover striped data={datos} loading={loader} page={page} pagination={false} onPageChange={onPageChange}  >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        width="4%"
                        dataFormat={
                                standardActions({  vermodal: this.props.setOpenModalHistory })                       
                        }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat = {
                            (cell,row)=>(
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente"
                        dataSort
                        width='20%'
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_documento"
                        dataSort
                        dataFormat ={
                            (cell) => (
                                cell
                            )
                        }
                    >
                        TIPO DOC.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_documento"
                        dataSort
                        dataFormat ={(cell,row) => (
                            <div>
                               {row.tipo_documento_pago === 2 && (
                                    <img className="action-img"  src={ImagenAlerta} alt="Imagen"/>
                                )}
                                {
                                    cell
                                }
                            </div>
                            )
                        }
                    >
                        No. DOC.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_documento"
                        dataSort
                        dataFormat ={(cell,row) => {
                            // String facturas
                            let facturas_string = row.facturas_asociadas.map(factura => factura.numero).join(', ')
                            return  <div><span>{facturas_string}</span> </div>
                        }}
                    >
                        No. FAC.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={'Q. '} />
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="descripcion"
                        dataSort
                    >
                        DESC.
                    </TableHeaderColumn>
                </Grid>

            </LoadMask>
        )
    }
}

export default withRouter(TransaccionesGrid);
