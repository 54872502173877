import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderFieldCheck, renderFieldRadio } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';

const RolesForm = (props) => {
    const { handleSubmit} = props;
    return (
        <form name="Rolesform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-lg-4 col-md-6  col-12">
                    <label className="t-azul" htmlFor="role">Nombre del Rol</label>
                    <Field name="nombre" label="role" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback  col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="descripcion">Descripción</label>
                    <Field name="descripcion" label="descripcion" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-lg-2 col-md-6  col-12">
                </div>
                <div className="form-group has-feedback  col-lg-5 col-md-6  col-12">
                    <div className="col-6">
                        <Field name="visualizar_mis_datos" label="Filtrar datos de vendedor" component={renderFieldCheck} className="form-control" />
                    </div>
                </div>
                <div className="form-group has-feedback  col-lg-5 col-md-6  col-12">
                    <div className="col-6">
                        <Field name="gastos_propios" label="Filtrar gastos propios" component={renderFieldCheck} className="form-control" />
                    </div>
                    <div className="col-6">
                        <Field name="revisar_gastos" label="Revisar gastos" component={renderFieldCheck} className="form-control" />
                    </div>
                </div>
                
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-lg-8 col-md-12  col-12">
                    <h4 className="t-primary text-upercase" >Permisos de Vistas</h4>
                </div>
            </div>
            <div className="row justify-content-center ">
                <div className="form-group has-feedback  col-lg-4 col-md-6  col-12">
                    {/* <label className="t-azul" htmlFor="role">Dashboard    .</label> */}
                    <Field name="dashbord" label="Dashboard" component={renderFieldCheck} className="form-control" />
                    <div className="row">
                        <div className="col-6">
                            <Field name="gastos" label="Gastos" component={renderFieldCheck} className="form-control"  />
                        </div>
                        <div className="col-6">
                            <Field name="ver_gastos" label="Ver Gastos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="bodegas" label="Bodegas" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_bodegas" label="Ver Bodegas" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="bodegas_admin" label="Bodegas (admin)" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="sim" label="S.I.M." component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_sim" label="Ver S.I.M" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Field name="liquidacion_pendientes" label="Liquidaciones Pendientes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-12">
                            <Field name="ingresos_sim" label="Ingresos S.I.M" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-12">
                            <Field name="registro_rapido_clientes" label="Registro rápido de clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-12">
                            <Field name="despachos_planta" label="Despachos Planta" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                   

                    <h6 className="t-mostaza" htmlFor="role">Orden de Compra:</h6>
                    <Field name="ordencompra" label="Orden de Compra" component={renderFieldCheck} className="form-control" />
                    <Field name="peticionmateriales" label="Peticion de Materiales" component={renderFieldCheck} className="form-control" />
                    <Field name="ingresos_oc" label="Ingreso en OC" component={renderFieldCheck} className="form-control" />

                    <h6 className="t-mostaza" htmlFor="role">Devoluciones:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="devolucion_cli" label="Dev. a clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_devolucion_cli" label="Ver Dev. a clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="devolucion_pro" label="Dev. a prov" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_devolucion_pro" label="Ver Dev. a prov" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="devolucion_cli_rechazar_aprobar" label="Dev. Rechazar/Aprobar a clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                    <h6 className="t-mostaza" htmlFor="role">Vehiculos:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="vehiculos" label="Vehículos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_vehiculos" label="Ver Vehículos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="vales" label="Vales de Gasolina" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_vales" label="Ver Vales de Gasolina" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                    <h6 className="t-mostaza" htmlFor="role">Rutas:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="itinerarios" label="Itinerarios" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_itinerarios" label="Ver Itinerarios" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_itinerarios" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_itinerarios" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_itinerarios" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="desglose_itinerarios" label="Desglose" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_detalle_itinerarios" label="Ver detalle" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="pagos_itinerarios" label="Pagos de Itinerarios" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_pagos_itinerarios" label="Ver Pagos de Itinerarios" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="anular_factura" label="Anulación de Facturas" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="agregar_factura_externa" label="Agregar Facturas Externas" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                    <h6 className="t-mostaza" htmlFor="role">Reportes:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="bitacora" label="Bitacora" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="deuda" label="Deudas" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="cuentasp" label="Cuentas por Pagar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="cuentasc" label="Cuentas por Cobrar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="ingresoegreso" label="Ingresos y Egreso" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="pago_flotante" label="Pago Flotante" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="reporte_vehiculos" label="Vehículos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="reporte_vales" label="Vales de Gasolina" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="cobros_mes" label="Cobros por mes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ventas_mes" label="Ventas por mes" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="cuentas_mes" label="Cuentas por C. por mes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="producto_vendido" label="Producto más vendido" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="reporte_despachos_bancos" label="Despachos-Bancos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="reporte_produccion" label="Producción" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="reporte_consumo" label="Productos Consumidos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="precios_cliente" label="Precios de Clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="precios_producto" label="Precios de Productos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Field name="precios_cliente_modificar" label="Modificar Precios de Clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="actividad_pilotos" label="Actividad de Pilotos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="costo_inventario" label="Costos de Inventaro" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="costovsventas" label="Costo vs Ventas" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ingresos_producto" label="Ingresos por Producto" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="rep_ausencias" label="Ausencias" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="aumento_precios" label="Aumento de precios" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Field name="rep_pagoplanilla" label="Historial pago de planilla" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-12">
                            <Field name="repo_pagoplanilla_empresa" label="Historial Pago de planilla por Empresa" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="precios_internos" label="Precios Internos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="cuentas_mes_pagar" label="Cuentas Por P. por mes" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="reporte_materia_prima" label="Reporte de materia prima" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ingresoegreso_interno" label="Ingresos y Egresos internos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="deuda_interna" label="Deuda Interna" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="form-group has-feedback  col-lg-4 col-md-6  col-12">
                    <h6 className="t-mostaza" htmlFor="role">Caja:</h6>
                    {/* <Field name="micaja" label="Mi Caja" component={renderFieldCheck} disabled className="form-control" /> */}
                    <Field name="cajaschicas" label="Cajas Chicas" component={renderFieldCheck} className="form-control" />

                    <h6 className="t-mostaza" htmlFor="role">Liquidaciones:</h6>
                    {/* <Field name="micaja" label="Mi Caja" component={renderFieldCheck} disabled className="form-control" /> */}
                    <Field name="ver_todo_liquidacion_historial" label="Ver todo el historial" component={renderFieldCheck} className="form-control" />

                    <h6 className="t-mostaza" htmlFor="role">Orden de Venta:</h6>
                    <Field name="ordenventa" label="Orden de Venta" component={renderFieldCheck} className="form-control" />
                    <Field name="peticionventa" label="Peticion de venta" component={renderFieldCheck} className="form-control" />
                    <Field name="botonagregar" label="Agregar Boton (Nueva Petición)" component={renderFieldCheck} className="form-control" />

                    <h6 className="t-mostaza" htmlFor="role">Administrativos:</h6>
                    <h6 className="t-primary" htmlFor="role">Usuarios</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="usuarios" label="Usuarios" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_usuarios" label="Ver Usuarios" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_usuarios" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_usuarios" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_usuarios" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Empresas</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="empresas" label="Empresas" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_empresas" label="Ver Empresas" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_empresas" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_empresas" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_empresas" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Establecimientos</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="establecimientos" label="Establecimientos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_establecimientos" label="Ver Establecimientos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_establecimientos" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_establecimientos" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_establecimientos" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Vendedores</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="vendedores" label="Vendedores" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_vendedores" label="Ver Vendedores" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_vendedores" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_vendedores" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_vendedores" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Clientes</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="clientes" label="Clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_clientes" label="Ver Clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_clientes" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_clientes" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_clientes" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_detalle_clientes" label="Ver" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Proveedores</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="proveedores" label="Proveedores" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_proveedores" label="Ver Proveedor" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_proveedores" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_proveedores" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_proveedores" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_detalle_proveedores" label="Ver" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Productos</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="productos" label="Productos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_productos" label="Ver Productos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_productos" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_productos" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_productos" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Tipo de producto</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="tproducto" label="Tipo de Producto" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_tproducto" label="Ver Tipos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_tproducto" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_tproducto" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_tproducto" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Roles</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="roles" label="Roles" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_roles" label="Ver Roles" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_roles" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_roles" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_roles" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Regiones</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="regiones" label="Regiones" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_regiones" label="Ver Regiones" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_regiones" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_regiones" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_regiones" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Pilotos</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="pilotos" label="Pilotos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_pilotos" label="Ver Pilotos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_pilotos" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_pilotos" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_pilotos" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Categoria de Gastos</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="categiagastos" label="Categia de Gastos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_categiagastos" label="Ver Categorias" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_categiagastos" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_categiagastos" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_categiagastos" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="t-primary" htmlFor="role">Poss</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="poss" label="Poss" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_poss" label="Ver Poss" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="crear_poss" label="Crear" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="editar_poss" label="Editar" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="eliminar_poss" label="Eliminar" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                    <h6 className="t-mostaza" htmlFor="role">Retiro:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="retiro_caja" label="Retiro de Caja" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="retiro_banco" label="Retiro de Banco" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                    <h6 className="t-mostaza" htmlFor="role">Cuentas:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="bancos" label="Bancos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_bancos" label="Ver Bancos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                    <h6 className="t-mostaza" htmlFor="role">Planilla:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="puestos" label="Puestos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_puestos" label="Ver Puestos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="bonificaciones" label="Bonificaciones" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_bonificaciones" label="Ver Bonificaciones" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="empleados" label="Empleados" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_empleados" label="Ver Empleados" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="planilla" label="Pago de Planilla" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_planilla" label="Ver Pago de Planilla" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="actividades" label="Actividades" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_actividades" label="Ver Actividades" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="asig_actividades" label="Asig. Actividades" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_asig_actividades" label="Ver Asig. Actividades" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="ausencias" label="Ausencias" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_ausencias" label="Ver Ausencias" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                    <h6 className="t-mostaza" htmlFor="role">Producción:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="orden_produccion" label="Orden de Producción" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_orden_produccion" label="Ver Producción" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="row justify-content-center ">
                <div className="form-group has-feedback  col-lg-8 col-md-12  col-12">
                    <h4 className="t-primary text-upercase" >Notificaciones</h4>
                </div>
                <div className="row col-lg-8 col-12 m-0 p-0">
                    <div className="form-group has-feedback col-md-6 col-sm-6 col-12">
                        <h6 className="t-mostaza" htmlFor="role">Orden venta:</h6>
                        <Field name="pv_creada" label="Peticion de venta creada" component={renderFieldCheck} className="form-control" />
                        <Field name="pv_autorizada" label="Peticion de venta autorizada" component={renderFieldCheck} className="form-control" />

                        <h6 className="t-mostaza" htmlFor="role">Produccion:</h6>
                        <Field name="op_creada" label="Orden produccion creada" component={renderFieldCheck} className="form-control" />
                        <Field name="op_finalizada" label="Orden produccion finalizada" component={renderFieldCheck} className="form-control" />

                        <h6 className="t-mostaza" htmlFor="role">Liquidacion:</h6>
                        <Field name="liquidacion_creacion" label="Liquidacion creada" component={renderFieldCheck} className="form-control" />

                        <h6 className="t-mostaza" htmlFor="role">Caja:</h6>
                        <Field name="cobro_efectivo" label="Cobro de efectico" component={renderFieldCheck} className="form-control" />
                    </div>
                    <div className="form-group has-feedback col-md-6 col-sm-6 col-12">
                        <h6 className="t-mostaza" htmlFor="role">Orden compra:</h6>
                        <Field name="sm_creada" label="Solicitud Materiales creada" component={renderFieldCheck} className="form-control" />
                        <Field name="sm_autorizada" label="Solicitud Materiales autorizada" component={renderFieldCheck} className="form-control" />
                        <Field name="oc_creada" label="Orden Compra creada" component={renderFieldCheck} className="form-control" />

                        <h6 className="t-mostaza" htmlFor="role">Inventario:</h6>
                        <Field name="ds_creado" label="Despacho  creada" component={renderFieldCheck} className="form-control" />
                        <Field name="it_creado" label="Itinerario creada" component={renderFieldCheck} className="form-control" />
                        <Field name="ing_oc" label="Ingreso por OC" component={renderFieldCheck} className="form-control" />
                        <Field name="low_inventario" label="Producto bajo inventario" component={renderFieldCheck} className="form-control" />
                    </div>
                </div>
            </div>

            <div className="row  justify-content-sm-around justify-content-center">
                    <Link className="btn btn-secondary m-1 align-self-center" to="/roles" >CANCELAR</Link>
                    <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'RolesForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            role: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(RolesForm);
