import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reporteProductos';
import ReporteProductos from './ReporteProductos';

const mstp = state => {
    return {
        ...state.reporteProductos,
        permisos: state.login.me.permisos,
        id_user: state.login.me.id 
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteProductos)
