import React from 'react';
import HeaderSimple from '../Header/HeaderSimple';


function CardSimple(props) {
    return (
            <div className="Container bg-white shadow-card m-t">
                <HeaderSimple titulo={props.titulo}/>
                {(props.toolbar) && (
                    props.toolbar
                )}
                <div className={`row ${props.margen ? props.margen:'mt-5'} mx-4`}>
                    <div className="mb-4 col-12 m-0 p-0">
                       {props.children}
                    </div>
                </div>
            </div>
    );
}

export default CardSimple;
