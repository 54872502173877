import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, AsyncSelectField, SelectField, renderSelectField } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import { renderDropdownDateField } from "Utils/renderField/otherrenders";
import { departamentos } from '../../../../../utility/constants';
import { api } from '../../../../../utility/api';

const getPuestos = (search) => {
    return api.get("puestos", { search }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getEmpresa = (search) => {
    return api.get("empresa/selectempresasE", { search }).
        then((data) => {
            if (data) return data.empresa;
            return [];
        }).catch(() => {
            return [];
        });
};

const getVendedores = (search) => {
    return api.get("vendedores/sin_empleado", { search }).
        then((data) => {
            console.log("data ", data)
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getPilotos = (search) => {
    return api.get("pilotos/sin_empleado", { search }).
        then((data) => {
            console.log("data ", data)
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const tipo_cuenta = [
    { value: 10, label: 'Monetaria' },
    { value: 20, label: 'Ahorro' },
]

const genero = [
    { value: 1, label: "Masculino" },
    { value: 2, label: "Femenino" },
]

const tipo_empleado = [
    { value: 0, label: "Ninguno" },
    { value: 1, label: "Vendedor" },
    { value: 2, label: "Piloto" },
]

let EmpleadosForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, opcion_cpp, permiso_cuenta } = props;
    return (
        <form name="Empresaform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre*</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="producto">Apellidos*</label>
                    <Field name="apellido" label="apellido" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="lugarNacimiento"> Lugar de Nacimiento </label>
                    <Field name="lugar_nacimiento" maxlength={50} label="lugarNacimiento" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="pueblo_pertenencia"> Pueblo de pertenencia </label>
                    <Field name="pueblo_pertenencia" maxlength={50} label="puebloPertenencia" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="genero"> Departamento </label>
                    <Field
                        name='departamento'
                        component={SelectField}
                        options={departamentos}
                        type="text"
                        filterOptions={false}
                        valueKey="value"
                        labelKey="label"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="genero"> Genero </label>
                    <Field
                        name='genero'
                        component={SelectField}
                        options={genero}
                        type="text"
                        filterOptions={false}
                        valueKey="value"
                        labelKey="label"
                        className="form-control"
                    // top ={{top:"67px", position:"inherit"}}
                    />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="telefono">Telefono</label>
                    <Field name="telefono" label="telefono" component={renderField} type="texto" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="producto">puesto*</label>
                    <Field
                        name='puesto'
                        component={AsyncSelectField}
                        loadOptions={getPuestos}
                        type="text"
                        filterOptions={false}
                        valueKey="id"
                        labelKey="nombre"
                        className="form-control"
                    // top ={{top:"67px", position:"inherit"}}
                    />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre de Contacto</label>
                    <Field name="nombre_contacto" label="nombre_contacto" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="producto">Telefono de Contacto</label>
                    <Field name="telefono_contacto" label="telefono_contacto" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="banco"> Banco </label>
                    <Field name="banco" maxlength={30} label="banco" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="no_cuenta">No cuenta</label>
                    <Field name="no_cuenta" label="no_cuenta" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="tipo_cuenta">Tipo cuenta</label>
                    <Field
                        name='tipo_cuenta'
                        component={SelectField}
                        options={tipo_cuenta}
                        type="text"
                        filterOptions={false}
                        valueKey="value"
                        labelKey="label"
                        className="form-control"
                    // top ={{top:"67px", position:"inherit"}}
                    />

                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="nombre">Código</label>
                    <Field name="codigo" label="codigo" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="nit">Nit</label>
                    <Field name="nit" label="nit" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="dpi">DPI</label>
                    <Field name="dpi" label="dpi" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="igss">IGSS</label>
                    <Field name="igss" label="igss" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="numero_hijos">Número de hijos</label>
                    <Field name="numero_hijos" label="numero_hijos" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="licencia">Licencia</label>
                    <Field name="licencia" label="licencia" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="tipo_licencia"> Tipo Licencia </label>
                    <Field name="tipo_licencia" maxlength={1} label="tipo_licencia" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="idiomas_que_domina"> Idiomas que domina </label>
                    <Field name="idiomas_que_domina" maxlength={100} label="idiomas_que_domina" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="nivel_academico">Nivel académico</label>
                    <Field name="nivel_academico" label="nivel_academico" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="estado_civil">Estado Civil</label>
                    <Field
                        name="estado_civil"
                        label="estado_civil"
                        labelKey="label"
                        valueKey="value"
                        component={renderSelectField}
                        options={[
                            { value: 10, label: 'Soltero' },
                            { value: 20, label: 'Casado' },
                            { value: 30, label: 'Divorciado' },
                            { value: 40, label: 'Viudo' },
                        ]}
                        onChange={(e) => {
                        }}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="fecha_nacimiento">Fecha nacimiento</label>
                    <Field
                        name="fecha_nacimiento"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="1940-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="fecha_inicio_labores">Fecha inicio labores</label>
                    <Field
                        name="fecha_inicio_labores"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2000-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="empresa">Empresa</label>
                    <Field
                        name='empresa'
                        component={AsyncSelectField}
                        loadOptions={getEmpresa}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        className="form-control"
                    // top={{ top: "67px", position: "inherit" }}
                    />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="es_vendedor"> Vendedor / Piloto </label>
                    <Field
                        name='es_vendedor'
                        component={SelectField}
                        options={tipo_empleado}
                        type="text"
                        filterOptions={false}
                        valueKey="value"
                        labelKey="label"
                        className="form-control"
                    />
                </div>
                {/* <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="vendedor">Vendedor</label>
                    <Field
                        name='vendedor'
                        component={AsyncSelectField}
                        loadOptions={getVendedores}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        className="form-control"
                        // top={{ top: "67px", position: "inherit" }}
                    />
                </div> */}
            </div>

            <div className="row justify-content-center">
                {
                    props.es_vendedor == '1' &&
                    <div className="form-group has-feedback col-md-4 col-12">
                        <label className="t-azul" htmlFor="vendedor">Vendedor</label>
                        <Field
                            name='vendedor'
                            component={AsyncSelectField}
                            loadOptions={getVendedores}
                            type="text"
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                        // top={{ top: "67px", position: "inherit" }}
                        />
                    </div>
                }
                {
                    props.es_vendedor == '2' &&
                    <div className="form-group has-feedback col-md-4 col-12">
                        <label className="t-azul" htmlFor="piloto">Piloto</label>
                        <Field
                            name='piloto'
                            component={AsyncSelectField}
                            loadOptions={getPilotos}
                            type="text"
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                        />
                    </div>
                }
                <div className={`form-group has-feedback ${props.es_vendedor == '1' || props.es_vendedor == '2' ? 'col-md-4' : 'col-md-8'} col-12`}>
                    <label className="t-azul" htmlFor="nombre">Direccion</label>
                    <Field name="direccion" label="direccion" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row  justify-content-sm-around justify-content-center mt-3">
                <Link className="btn btn-secondary m-1 align-self-center" to="/empleados" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


EmpleadosForm = reduxForm({
    form: 'EmpleadosForm', // a unique identifier for this form,
    initialValues: {
        es_vendedor: 0
    },
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            apellido: validators.exists()('Este campo es requerido'),
            puesto: validators.exists()('Este campo es requerido'),
            empresa: validators.exists()('Este campo es requerido'),
            nit: validators.exists()('Este campo es requerido'),
            no_cuenta: validators.exists()('Este campo es requerido'),
        });
    },
})(EmpleadosForm);

const selector = formValueSelector('EmpleadosForm');
// EmpleadosForm = connect(state =>{
//     // let puesto = selector(state, 'puesto')
//     // if(!!puesto){
//     //     puesto = puesto.id
//     // }else {}
//    return{

//     }
// })(EmpleadosForm)

const ms2p = (state) => {
    let es_vendedor = selector(state, 'es_vendedor')
    return {
        ...state.empleados,
        permisos: state.login.me.permisos,
        es_vendedor: es_vendedor,
    }
}

export default connect(ms2p)(EmpleadosForm)
