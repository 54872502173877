import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset, change, destroy } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let endpoint = 'produccion';
let formName = '';
let resourceList = '/produccion';
let storeId = "produccion"

export const constants = {
    LOADER: `${storeId.toUpperCase()}_LOADER`,
    DATA: `${storeId.toUpperCase()}_DATA`,
    DATAREFUSE: `${storeId.toUpperCase()}_DATAREFUSE`,
    ITEM: `${storeId.toUpperCase()}_ITEM`,
    ITEM_OV: `${storeId.toUpperCase()}_ITEM_OV`,
    PAGE: `${storeId.toUpperCase()}_PAGE`,
    ORDERING: `${storeId.toUpperCase()}_ORDERING`,
    SEARCH: `${storeId.toUpperCase()}_SEARCH`,
    HISTORIAL: `${storeId.toUpperCase()}_HISTORIAL`,
    SET_ROLES: 'SET_ROLES',
    SET_EMPRESAS: 'SET_EMPRESAS',
    SET_REGIONES: 'SET_REGIONES',
    SET_CUENTAS: `${storeId.toUpperCase()}_CUENTAS`,
    TIPO_GASTOS: 'GASTOS_TIPO_GASTO',
    EMPRESAS_PRODUCCION: 'EMPRESAS_PRODUCCION',
    EMPRESA: 'EMPRESA',
    NOMBRE_EMPRESA: 'NOMBRE_EMPRESA',
    SET_PRODUCCION_INFO: 'SET_PRODUCCION_INFO',
    SET_UNIDAD_MEDIDA: "SET_UNIDAD_MEDIDA",
    SET_ORDEN: "SET_ORDEN",
    SET_ESTADO: "SET_ESTADO_PRODUCCION",
};

const setLoader = loader => ({
    type: constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setItem = item => ({
    type: constants.ITEM,
    item
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setDataSelect = (type, data) => ({
    type,
    data
});

const setUnidadMedidaType = (unidadMedida) => ({
    type: constants.SET_UNIDAD_MEDIDA,
    unidadMedida,
});

const setOrdenType = (orden) => ({
    type: constants.SET_ORDEN,
    orden,
});
const listar = (empresa, page = 1) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = { page };
    params.empresa = empresa ? empresa : resource.empresa;
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.valorEstado && resource.valorEstado.value != undefined) {

        if (resource.valorEstado.value === 0 || resource.valorEstado.value === 1) {
            params.estado = resource.valorEstado.value;
            params.produccion_finalizada = false;
        } else {
            params.produccion_finalizada = resource.valorEstado.value
        }
    }

    dispatch(setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(page));
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarRefuse = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.estate = 1
    dispatch(setLoader(true));
    api.get(`${endpoint}`, params).then((response) => {
        dispatch({ type: constants.DATAREFUSE, data: response });
        dispatch(setPage(page));
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        console.log("response ", response)
        dispatch(setItem(response));
        if (!!formName) {
            if (response.fecha_fundacion) {
                response.fecha_fundacion = new Date(moment(response.fecha_fundacion).format());
                response.cuenta_inicial = 0
                response.cuenta_por = "1"
            }
            if (response.es_bonificacion) {
                response.es_bonificacion = "true"
            } else { response.es_bonificacion = "false" }
            dispatch(initializeForm(formName, response));
        }
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
        NotificationManager.success('Orden de produccion creada', 'Éxito', 3000);
        if (!!resourceList)
            dispatch(push(`${resourceList}/${id}`));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error && error.detail) {
            mensaje = error.detail
        } else if (Object.keys(error).length > 0) {
            let key = Object.keys(error)[0];
            mensaje = `${key}: ${error[key][0]}`
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
    }).catch(() => {
        let mensaje = "Error en la creación";
        if (error && error.detail) {
            mensaje = error.detail
        } else if (Object.keys(error).length > 0) {
            let key = Object.keys(error)[0];
            mensaje = `${key}: ${error[key][0]}`
        }
        NotificationManager.error(mensaje, 'ERROR');

    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error al eliminar', 'Éxito', 3000);
    }).finally(() => {
        dispatch(listar());
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const searchChangeRefuse = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarRefuse(1, 1));
};

const onSortChangeRefuse = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarRefuse(sort.page, 1));
};

const changeEstado = (estado) => (dispatch) => {
    dispatch(setData(constants.SET_ESTADO, estado));
    dispatch(listar())
}

const selectRoles = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('role/selectroles').then((res) => {
        dispatch(setDataSelect(constants.SET_ROLES, res))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false))
    })
}

const selectEmpresas = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res) => {
        dispatch(setDataSelect(constants.SET_EMPRESAS, res))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false))
    })
}

const selectRegiones = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('region/selectregiones').then((res) => {
        dispatch(setDataSelect(constants.SET_REGIONES, res))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false))
    })
}

const selectEmpresas2 = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res) => {
        dispatch(setDataSelect(constants.SET_EMPRESAS, res))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false))
    })
}

const selectCuentas = (id, moneda) => (dispatch) => {
    dispatch(setLoader(true));
    let data = { moneda }
    api.post(`cuentas/selectcuentas`, data).then((res) => {
        dispatch({ type: constants.SET_CUENTAS, data: res })
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false))
    })
}

const getTipoGastos = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('tipo_gasto').then((res) => {
        dispatch({
            type: constants.TIPO_GASTOS,
            tipo_gastos: res
        })
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false))
    })
}

const setUnidadMedida = (index, data) => (dispatch, getStore) => {
    let store = getStore().produccion;
    let unidadMedida = store.unidadMedida;
    unidadMedida[index] = data.unidad_medida;
    dispatch(setUnidadMedidaType(unidadMedida));
};
const eliminarUnidadMedida = (index) => (dispatch, getStore) => {
    let store = getStore().produccion;
    let unidadMedida = store.unidadMedida;
    unidadMedida.splice(index, index + 1);
    dispatch(setUnidadMedidaType(unidadMedida));
};
const setOrden = (orden) => (dispatch) => {
    dispatch(setOrdenType(orden));
};
const resetFormulario = () => (dispatch) => {
    dispatch(initializeForm(formName, {}));
}

const clear = () => (dispatch) => {
    dispatch({ type: constants.ITEM, item: {} });
};

const ListarEmpresasProduccion = () => (dispatch) => {
    dispatch(setLoader(true));
    let params = {};
    api.get(`${endpoint}/getProduccionEmpresas`, params).then((response) => {
        dispatch(setData(constants.EMPRESAS_PRODUCCION, response));
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail : "Error al listar las Empresas", 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerov = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    api.get(`ordenventa/${id}`, params).then((response) => {
        dispatch({ type: constants.ITEM_OV, item: response });
        dispatch(change('ProduccionForm', `orden`, response))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const leersi = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    api.get(`ventainterna/${id}`, params).then((response) => {
        dispatch({ type: constants.ITEM_OV, item: response });
        dispatch(change('ProduccionForm', `orden`, response))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const getRecetaOV = (id, empresa) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { orden: id, empresa };
    api.get(`${endpoint}/getRecipe`, params).then((response) => {
        // dispatch({type: constants.ITEM_OV, item: response});
        dispatch(change('ProduccionForm', `produccion`, response))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
}

const setEmpresa = (id) => (dispatch) => {
    dispatch({ type: constants.EMPRESA, empresa: id });
    api.get(`empresa/empresa_entidad`, { entidad: id }).then((response) => {
        const nombre = response.nombre;
        dispatch({ type: constants.NOMBRE_EMPRESA, nombre_empresa: nombre });
    }).catch(() => {}).finally(() => {});
}

const setRecetaProducto = (producto, fila, index, produccion, state_receta, empresa = null, orden_produccion) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    api.get(`productos/${producto}`, params).then((response) => {
        dispatch({
            type: constants.SET_PRODUCCION_INFO,
            produccion: {
                fila,
                index,
                produccion,
                orden_produccion
            }
        })
        dispatch({ type: constants.EMPRESA, empresa })
        if (state_receta) {
            dispatch(change('RecetaForm', 'receta', fila.producto.recetas ? fila.producto.recetas : response.recetas));
        }
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
}

const editRecetaProducto = (values, produccion_info, empresa) => (getStore, dispatch) => {
    dispatch(setLoader(true));
    let Producto = produccion_info.produccion[produccion_info.index];
    let detalleProduccion = produccion_info.produccion

    // sueldos
    Producto.producto.recetas = values.receta
    Producto.producto.cambio_receta = true

    // reintegrando la inforamcion al listado de empleados
    detalleProduccion[produccion_info.index] = Producto

    // dispatch(push(`/#/reportar_produccion/${empresa}`));
    dispatch(change(`ProduccionForm`, 'orden', produccion_info.orden_produccion))
    dispatch(change(`ProduccionForm`, 'reporte', detalleProduccion))
    dispatch(setLoader(false));

}

const destroyformulario = (formulario) => (dispatch) => {
        dispatch(setOrdenType(undefined));
        dispatch(setUnidadMedidaType([]));
        dispatch(destroy(formulario))
    }
    // reportarProduccion
const reportarProduccion = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${endpoint}/reportarProduccion`, data).then(() => {
        NotificationManager.success('Reporte de produccion creada', 'Éxito', 3000);
        dispatch(destroyformulario("ProduccionForm"));
        if (!!resourceList)
            dispatch(push(`${resourceList}/${id}`));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error && error.detail) {
            mensaje = error.detail
        } else if (Object.keys(error).length > 0) {
            let key = Object.keys(error)[0];
            mensaje = `${key}: ${error[key][0]}`
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const clearSearch = () => (dispatch) => {
    dispatch(setSearch(""));
}

const getRecetaDev = (dataDev, entidad) => (dispatch) => {
    dispatch(setLoader(true))
    let produccion = []
    dataDev.forEach((item, index) => {
        if (item.producto.tiene_receta == true) {
            if (item.producto.entidad_empreas == entidad && item.tipo_devolucion == 70) {
                produccion.push({ producto: item.producto, cantidad: item.cantidad })
            }
        }
    });
    dispatch(change('ProduccionForm', `produccion`, produccion))
    dispatch(setLoader(false))
}

const getHistorial = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}/historial_produccion`).then((response) => {
        dispatch(setData(constants.HISTORIAL, response));
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    selectRoles,
    selectEmpresas,
    selectRegiones,
    selectEmpresas2,
    selectCuentas,
    listarRefuse,
    searchChangeRefuse,
    onSortChangeRefuse,
    getTipoGastos,
    changeEstado,
    resetFormulario,
    clear,
    ListarEmpresasProduccion,
    leerov,
    getRecetaOV,
    setEmpresa,
    setRecetaProducto,
    editRecetaProducto,
    destroyformulario,
    reportarProduccion,
    leersi,
    getRecetaDev,
    clearSearch,
    setUnidadMedida,
    eliminarUnidadMedida,
    setOrden,
    getHistorial,
};


export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_ROLES]: (state, { data }) => {
        return {
            ...state,
            roles: data.role,
        }
    },
    [constants.SET_EMPRESAS]: (state, { data }) => {
        return {
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_CUENTAS]: (state, { data }) => {
        return {
            ...state,
            cuentas_banco: data.cuenta,
        }
    },
    [constants.SET_REGIONES]: (state, { data }) => {
        return {
            ...state,
            regiones: data,
        }
    },
    [constants.DATAREFUSE]: (state, { data }) => {
        return {
            ...state,
            datarefuse: data
        }
    },
    [constants.TIPO_GASTOS]: (state, { tipo_gastos }) => {
        return {
            ...state,
            tipo_gastos
        }
    },
    [constants.EMPRESAS_PRODUCCION]: (state, { data }) => {
        return {
            ...state,
            produccionEmpresa: data
        }
    },
    [constants.ITEM_OV]: (state, { item }) => {
        return {
            ...state,
            itemov: item
        }
    },
    [constants.SET_UNIDAD_MEDIDA]: (state, { unidadMedida }) => {
        return {
            ...state,
            unidadMedida,
        };
    },
    [constants.SET_ORDEN]: (state, { orden }) => {
        return {
            ...state,
            orden,
        };
    },
    [constants.SET_ESTADO]: (state, { data }) => {
        return {
            ...state,
            valorEstado: data,
        };
    },
    [constants.EMPRESA]: (state, { empresa }) => {
        return {
            ...state,
            empresa
        }
    },
    [constants.NOMBRE_EMPRESA]: (state, { nombre_empresa }) => {
        return {
            ...state,
            nombre_empresa
        }
    },
    [constants.SET_PRODUCCION_INFO]: (state, { produccion }) => {
        return {
            ...state,
            produccion
        }
    },
    [constants.HISTORIAL]: (state, { data }) => {
        return {
            ...state,
            historial: data
        }
    },
};


export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    itemov: {},
    empresa: null,
    nombre_empresa: null,
    page: 1,
    ordering: '',
    search: '',
    roles: [],
    empresas: [],
    regiones: [],
    valorEstado: undefined,
    cuentas_banco: [],
    datarefuse: {
        results: [],
        count: 0,
    },
    historial: [],
    tipo_gastos: [],
    produccionEmpresa: [],
    produccion: {},
    unidadMedida: [],
    orden: undefined,
};

export default handleActions(reducers, initialState);