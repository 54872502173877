import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";

import PagosItinerariosForm from './PagoItinerarioForm';

class Bonificacion extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'NUEVO PAGO',
        data:{}
    }
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo:'EDITAR PAGO'
            });
        }
    }
    onSubmit = (valores) =>{
        let values = _.cloneDeep(valores);
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        if(values.bono)
            values.bono = values.bono.id;
        this.state.editar ? editar(id,values) : crear(values);
    }

    render() {
        const { loader, item } = this.props;
        return (
            <div className="Container">
               <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 card card-small m-t">
                            <div className="card-body">
                                <h3 className="t-primary card-title">{`${this.state.titulo}`}</h3>
                                <div className="p-0 px-3 pt-3">
                                    <LoadMask loading={loader} light type={"TailSpin"}>
                                        <PagosItinerariosForm
                                            onSubmit={this.onSubmit}
                                            initialValues={{ monto: 0}}
                                        />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bonificacion;
