import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/ValesGasolina/valesGasolina';
import detalleVales from './detalleVales';

const mstp = state => {
    return {...state.valesGasolina}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(detalleVales)