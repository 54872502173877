import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderField,
    AsyncSelectField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
    renderTextArea,
    renderSelecttree,
    SelectField
} from '../../../Utils/renderField';
import { getSimbolo} from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";
import {api} from 'api';

const getEmpresas = (search) => {
    let params = {}
    params.search = search
    params.es_principal = false
    return api.get("empresa", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

let DescuentoForm = (props) => {
    const { handleSubmit, texto_boton_movimiento, simbolo, cerrarModal, idCliente} = props;
    return (
        <form name="DescuentoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            
            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Fecha Descuento</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Empresa</label>
                    <Field
                        name="empresa"
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={getEmpresas}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {

                        }}
                        className="form-control" />
                </div>
            </div>
            
            <div className="row col-12 m-0 p-0">
                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                    <label className="t-azul"  htmlFor="monto">Monto de Descuento*</label>
                    <Field
                        name="monto"
                        label="monto"
                        prefix={simbolo}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback  col-sm-12  col-12">
                <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        type="text"
                        className="form-control" 
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                        <button type="button" onClick={cerrarModal} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">
                            {
                                (texto_boton_movimiento) ? (`${texto_boton_movimiento}`) : ("AGREGAR DESCUENTO")
                            }
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};


DescuentoForm =  reduxForm({
    form: 'DescuentoForm', // a unique identifier for this form
    initialValues: {
        fecha: new Date(),
        fecha_cobro: new Date(),
    },
    validate: (data) => {
        return validate(data, {
            fecha: validators.exists()('Este campo es requerido'),
            monto: validators.exists()('Este campo es requerido'),
        });
    },
})(DescuentoForm);

const selector = formValueSelector('DescuentoForm');
DescuentoForm = connect(state => {
    return{
    }
})(DescuentoForm);


export default DescuentoForm;
