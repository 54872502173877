
import React, { Component } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";


export default class GridPlanilla extends Component {

    state = {
        data: {
            results: null,
            count: 0
        }
    }

    componentDidMount() {
        const { data } = this.props;
        if(data){
            let count = data.length
            this.setState({ data: { results: data, count: count } });
        }
    }

    componentWillUnmount(){
        this.setState({ data: undefined })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.data !== this.props.data){
            let count = this.props.data.length
            this.setState({ data: { results: this.props.data, count: count } });
        }
    }

    render() {

        const { loader, page, onPageChange, onSortChange } = this.props;
        return (
            <div className="row m-none">
                <div className="col-12 p-0">
                            <Grid hover
                                pagination={false}
                                striped data={this.state.data}
                                loading={loader}
                            >
                                <TableHeaderColumn
                                    dataField="trabajador"
                                    dataAlign="center"
                                    isKey
                                    dataSort
                                    width="10%"
                                    dataFormat={(cell) => cell? "" :" "}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="trabajador"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (
                                            cell ? cell.nombre_completo : "0"
                                        )
                                    }
                                >
                                    NOMBRE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="trabajador"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (
                                            cell ? cell.puesto.nombre : "0"
                                        )
                                    }
                                >
                                    PUESTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="trabajador"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (cell ? <RenderMoneda monto={cell.puesto.total} simbolo={'Q '} /> : '0')
                                    }
                                >
                                    SUELDO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="trabajador"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (cell ? <RenderMoneda monto={cell.bonos_puesto.total} simbolo={'Q '} /> : '0')
                                    }
                                >
                                    BONOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="trabajador"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (cell ? <RenderMoneda monto={cell.actividades.total} simbolo={'Q '} /> : '0')
                                    }
                                >
                                    ACTIVIDADES
                                </TableHeaderColumn>
                            </Grid>
                </div>
            </div>
        )
    }
}
