import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";
import LoadMask from "Utils/LoadMask/LoadMask";

import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";


export default class DetallePago extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        // this.props.clear()
        let id = this.props.match.params.id
        this.props.DetallarPagoEmpleado(id);
    }
    render() {
        const { item, loader } = this.props;
        let CPid = this.props.match.params.id
        return (
            <div className="Container bg-white">
                <LoadMask loading={loader} light type={"TailSpin"}>

                    {item.empleado && (
                        <div className="m-t p-t fnt-size m-3">
                            <div className="row col-12 ">
                                <div className="col-12">
                                    <h2 className="text-primary text-uppercase">{item.empleado ? `${item.empleado.nombre} ${item.empleado.apellido}`: ""}</h2>
                                </div>
                                <div className="col-sm-6 col-12 d-flex">
                                    <h5 className="t-mostaza text-uppercase">{`Puesto: `}</h5>
                                    <h5 className="t-primary text-uppercase">{item.tipo_puesto ? item.tipo_puesto.puesto:""}</h5>
                                    {/* <h6 className="text-center text-primary text-uppercase">{`(${index})`}</h6> */}
                                </div>
                                <div className="col-sm-6 col-12 d-flex">
                                    <h5 className="t-mostaza text-uppercase">{`Tipo: `}</h5>
                                    <h5 className="t-primary text-uppercase">{item.tipo_puesto ? item.tipo_puesto.label_tipo:"NoN"}</h5>
                                </div>
                            </div>
                            <br/>
                            {/* DATOS GENERALES */}
                            {/* <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} /> */}
                            <div className="row m-none" 
                                style={{border:"1px solid #E8E8E8", padding: " 10px 10px 10px 10px",  margin: "10px 15px"}}
                            >
                               <div className="col-12 row">
                                    <div className="col-md-9 col-12 row p-0" style={{width: "100%"}}>
                                        <div className="col-12 row">
                                            <div className={`form-group has-feedback col-sm-6 col-12 d-flex align-items-start  justify-content-md-start justify-content-start m-0`}>
                                                <h5 className="t-musgo text-uppercase" >Total de Sueldos:</h5>
                                                <div className="d-flex justify-content-end" style={{width:"80%"}}>
                                                    {/* <h4 className={ sueldo_base < total_sueldo ?  "text-danger":`t-primary`}> */}
                                                    <h4 className={`t-primary`}>
                                                        <RenderMoneda monto={item.sueldo} />
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className={`form-group has-feedback col-sm-6 col-12 d-flex align-items-start  justify-content-md-start justify-content-start m-0`}>
                                                <h5 className="t-musgo text-uppercase" >Total de Actividades:</h5>
                                                <div className="d-flex justify-content-end" style={{width:"80%"}}>
                                                    <h4 className="t-primary">
                                                        <RenderMoneda monto={item.subtotal_actividades} />
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className={`form-group has-feedback col-sm-6 col-12 d-flex align-items-start  justify-content-md-start justify-content-start m-0`}>
                                                <h5 className="t-musgo text-uppercase" >Total de Bonos:</h5>
                                                <div className="d-flex justify-content-end" style={{width:"80%"}}>
                                                    <h4 className="t-primary">
                                                        <RenderMoneda monto={item.subtotal_bonos} />
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className={`form-group has-feedback col-sm-6 col-12 d-flex align-items-start  justify-content-md-start justify-content-start m-0`}>
                                                <h5 className="t-musgo text-uppercase" >Total de Descuentos:</h5>
                                                <div className="d-flex justify-content-end" style={{width:"80%"}}>
                                                    <h4 className="t-primary">
                                                        <RenderMoneda monto={item.descuento_sueldo} />
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr style={{borderTop: "1px solid rgb(223, 224, 224)", margin:"0px 0px 20px 0px"}}/>
                                        </div>
                                        <div className="col-12 row">
                                            <div className={`form-group has-feedback col-sm-6  col-12 d-flex align-items-start  justify-content-md-start justify-content-start m-0`}>
                                                <h5 className="t-musgo text-uppercase" >Total Banco:</h5>
                                                <div className="d-flex justify-content-end" style={{width:"80%"}}>
                                                    <h4 className="t-primary">
                                                        <RenderMoneda monto={item.subtotal_banco} />
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className={`form-group has-feedback col-sm-6 col-12 d-flex align-items-start  justify-content-md-start justify-content-start m-0`}>
                                                <h5 className="t-musgo text-uppercase" >Total Efetivo:</h5>
                                                <div className="d-flex justify-content-end" style={{width:"80%"}}>
                                                    <h4 className="t-primary">
                                                        <RenderMoneda monto={item.subtotal_efectivo} />
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-group has-feedback col-md-3 col-12 d-flex align-items-center justify-content-start `}>
                                        <h5 className="t-musgo text-uppercase" >Total:</h5>
                                        <div className="d-flex justify-content-end" style={{width:"80%"}}>
                                            <h4 className="t-primary">
                                                <RenderMoneda monto={item.total} />
                                            </h4>
                                        </div>
                                    </div>

                               </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-4 ">
                                        <div className="p-0 px-3 pt-3 p-b">
                                            <div className=""><h4 className="m-0 text-uppercase">{item.tipo_puesto.tipo==1 ?  "SUELDO BASE":"produccion"}</h4></div>
                                            <Grid hover striped data={{count:10, results:item.sueldo_detalle }} loading={loader} pagination={false} >
                                                <TableHeaderColumn
                                                    isKey
                                                    dataField="Empresa_pago"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            cell.nombre
                                                        )
                                                    }
                                                >
                                                    EMPRESA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="valor"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            <RenderMoneda monto={cell} ></RenderMoneda>
                                                        )
                                                    }
                                                >
                                                    VALOR
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="valor_descuento"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            <RenderMoneda monto={cell} ></RenderMoneda>
                                                        )
                                                    }
                                                >
                                                    DESCUENTO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="subtotal"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            <RenderMoneda monto={cell} ></RenderMoneda>
                                                        )
                                                    }
                                                >
                                                    SUBTOTAL
                                                </TableHeaderColumn>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-4 ">
                                        <div className="p-0 px-3 pt-3 p-b">
                                            <div className=""><h4 className="m-0 text-uppercase">bonificaciones</h4></div>
                                            <Grid hover striped data={{count:10, results:item.bonos }} loading={loader} pagination={false}  >
                                                <TableHeaderColumn
                                                    isKey
                                                    dataField="bono_descuento"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            cell.nombre
                                                        )
                                                    }
                                                >
                                                    BONO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="Empresa_pago"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            cell.nombre
                                                        )
                                                    }
                                                >
                                                    EMPRESA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="valor"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            <RenderMoneda monto={cell} ></RenderMoneda>
                                                        )
                                                    }
                                                >
                                                    VALOR
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="valor_descuento"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            <RenderMoneda monto={cell} ></RenderMoneda>
                                                        )
                                                    }
                                                >
                                                    DESCUENTO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="subtotal"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            <RenderMoneda monto={cell} ></RenderMoneda>
                                                        )
                                                    }
                                                >
                                                    SUBTOTAL
                                                </TableHeaderColumn>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {item.tipo_puesto.tipo==5 && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-4 ">
                                            <div className="p-0 px-3 pt-3 p-b">
                                                <div className=""><h4 className="m-0 text-uppercase">actividades</h4></div>
                                                <Grid hover striped data={{count:10, results:item.actividades }} loading={loader} pagination={false}  >
                                                    <TableHeaderColumn
                                                        dataField="Empresa_pago"
                                                        isKey
                                                        dataSort
                                                        dataFormat={
                                                            (cell,row)=>(
                                                                cell.nombre
                                                            )
                                                        }
                                                    >
                                                        EMPRESA
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="valor"
                                                        dataSort
                                                        dataFormat={
                                                            (cell,row)=>(
                                                                <RenderMoneda monto={cell} ></RenderMoneda>
                                                            )
                                                        }
                                                    >
                                                        VALOR
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="valor_descuento"
                                                        dataSort
                                                        dataFormat={
                                                            (cell,row)=>(
                                                                <RenderMoneda monto={cell} ></RenderMoneda>
                                                            )
                                                        }
                                                    >
                                                        DESCUENTO
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="subtotal"
                                                        dataSort
                                                        dataFormat={
                                                            (cell,row)=>(
                                                                <RenderMoneda monto={cell} ></RenderMoneda>
                                                            )
                                                        }
                                                    >
                                                        SUBTOTAL
                                                    </TableHeaderColumn>
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-4 ">
                                        <div className="p-0 px-3 pt-3 p-b">
                                            <div className=""><h4 className="m-0 text-uppercase">descuentos</h4></div>
                                            <Grid hover striped data={{count:10, results:item.descuentos }} loading={loader} pagination={false}  >
                                                <TableHeaderColumn
                                                    isKey
                                                    dataField="bono"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            cell.nombre
                                                        )
                                                    }
                                                >
                                                    BONO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="Empresa_pago"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            cell.nombre
                                                        )
                                                    }
                                                >
                                                    EMPRESA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="subtotal"
                                                    dataSort
                                                    dataFormat={
                                                        (cell,row)=>(
                                                            <RenderMoneda monto={cell} ></RenderMoneda>
                                                        )
                                                    }
                                                >
                                                    SUBTOTAL
                                                </TableHeaderColumn>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    )
                    }
                </LoadMask>
            </div>

        )
    }
}

