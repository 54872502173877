import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import GridloteInventario from './GridLoteInventario';


export default class ReporteLoteInventario extends Component {

    componentDidMount(){
        let id = this.props.match.params.id
        this.props.changeProductoLote(id)
    }

    render() {
        const { loader, item } = this.props;

        return (
            <CardSimple titulo={`${item && item.results ? item.results[0].nombre : " "}`}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br/>

                    <GridloteInventario
                        { ...this.props }
                    />

                </LoadMask>

            </CardSimple>
        )

    }
}
