import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { Monedas } from "../../../../../utility/constants";
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';


function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}

export default class DespachoPlantaGrid extends Component {

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, loader, listar, page, onSortChange, eliminar, permisos, rol } = this.props;
        if(permisos["despachos_planta"] === false) {
                return <Redirect to={`/`} />
        }
        return (
            <Fragment>
                <CardSimple
                    toolbar={<ToolbarSimple
                        textBoton={(!permisos.visualizar_mis_datos | rol =="VENDEDOR/SUPERIOR") ? "Agregar" : ""}
                        ruta="despachosplanta/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Cliente, No.Orden"}
                        permisos={permisos.despachos_planta}
                    />}
                    titulo="Despachos Planta">
                    <Grid hover striped data={data} loading={loader} onPageChange={listar} page={page} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            dataAlign="center"
                            dataSort
                            dataFormat={
                                standardActions({
                                    verdespachoplanta: "despachosplanta"
                                })
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_despacho"
                            dataSort
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (
                                    <RenderDateTime fecha={row.fecha_despacho} ></RenderDateTime>
                                )
                            }
                        >
                            FECHA DESPACHO
                            </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre_cliente"
                            dataSort
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (cell ? cell : '--')
                            }
                        >
                            CLIENTE
                            </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="no_orden"
                            dataSort
                            tdStyle={cellTachado}
                            dataFormat={(cell, row) => cell.map((item) => {
                                return (
                                    item ? item : '--'
                                )
                            })
                            }
                        >
                            ORDEN VENTA
                            </TableHeaderColumn>
                    </Grid>
                </CardSimple>
            </Fragment>

        );
    }
}
