import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_VEHICULO_LOADER',
    RESUMEN: 'REPORTE_VEHICULO_RESUMEN',
    DATA: 'REPORTE_VEHICULO_DATA',
    DATA2: 'REPORTE_VEHICULO_DATA2',
    DATA3: 'REPORTE_VEHICULO_DATA3',
    PAGE: 'REPORTE_VEHICULO_PAGE',
    PAGE2: 'REPORTE_VEHICULO_PAGE2',
    PAGE3: 'REPORTE_VEHICULO_PAGE3',
    ORDERING: 'REPORTE_VEHICULO_ORDERING',
    SET_EMPLEADO: 'SET_EMPLEADO',
    SET_EMPRESA_PLANILLA: 'SET_EMPRESA_PLANILLA',
    SET_FECHA_I: 'SET_FECHA_I',
    SET_FECHA_F: 'SET_FECHA_F',
    SET_TOTAL_DIAS_AUSENCIA: 'SET_TOTAL_DIAS_AUSENCIA',
    SET_TOTALES_PLANILLA1: 'SET_TOTALES_PLANILLA1',
    SET_ITEM: "SET_ITEM",
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type,
    data
});

const setItem = ( item) => ({
    type: constants.SET_ITEM,
    item
});

const setPage = (type, page) => ({
    type,
    page,
});

const setEmpleado = empleado =>({
    type:constants.SET_EMPLEADO,
    empleado
});

const setEmpresa = empresa =>({
    type:constants.SET_EMPRESA_PLANILLA,
    empresa
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});


// ACTIONS


const listarAusencias= (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportePlanilla;
    const params = { page };
    if (resource.empleado)
        params.empleado = resource.empleado.id;
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`ausencias/reporteausencias`, params).then((response) => {
        let contador = 0
        response.results.forEach(item => {
            contador += item.dias_ausencia
        });
        dispatch(setData(constants.DATA, response));
        dispatch(setData(constants.SET_TOTAL_DIAS_AUSENCIA, contador));
        dispatch(setPage(constants.PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const ListarPagosEmpleado = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportePlanilla;
    const params = { page };
    if (resource.empleado)
        params.empleado = resource.empleado.id;
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`planilla/pagosplanillaempleado`, params).then((response) => {
        let total = 0
        let total_bonos = 0
        let total_sueldo = 0
        let total_actividades = 0
        let total_produccion = 0;
        let total_descuentos = 0
        response.results.forEach(item => {
            total += item.total
            total_bonos += item.subtotal_bonos
            if (item.tipo_empleado == 5 ){ total_produccion += item.sueldo }
            if (item.tipo_empleado == 1 ){ total_sueldo += item.sueldo }
            total_actividades += item.subtotal_actividades
            total_descuentos += item.descuento_sueldo
        });
        let totales ={
            total, total_bonos, total_sueldo, total_actividades, total_descuentos, total_produccion
        }
        dispatch(setData(constants.DATA2, response));
        dispatch(setPage(constants.PAGE2, page));
        dispatch(setData(constants.SET_TOTALES_PLANILLA1, totales));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const ListarPagosEmpresas = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportePlanilla;
    const params = { page };
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get(`planilla/pagosplanillaempresa`, params).then((response) => {
        let total = 0
        let total_bonos = 0
        let total_sueldo = 0
        let total_produccion = 0
        let total_actividades = 0
        let total_descuentos = 0
        response.results.forEach(item => {
            total += item.subtotal

            if (item.tipo_pago == 1){ total_bonos += item.subtotal }
            if (item.tipo_pago == 2 && item.tipo_empleado == 5 ){ total_produccion += item.subtotal }
            if (item.tipo_pago == 2 && item.tipo_empleado == 1 ){ total_sueldo += item.subtotal }
            if (item.tipo_pago == 3){ total_actividades += item.subtotal }
            if (item.tipo_pago == 4){ total_descuentos += item.subtotal }
        });
        let totales ={
            total, total_bonos, total_sueldo, total_actividades, total_descuentos, total_produccion
        }
        dispatch(setData(constants.DATA3, response));
        dispatch(setPage(constants.PAGE3, page));
        dispatch(setData(constants.SET_TOTALES_PLANILLA1, totales));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getTotalesPagos = () => (dispatch, getStore) => {
    const resource = getStore().reportePlanilla;
    const params = {};
    if (resource.empleado)
        params.empleado = resource.empleado.id;
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
        console.log('Totales',params)
    dispatch(setLoader(true));
    api.get(`planilla/pagosplanillaempleadototales`, params).then((response) => {
        dispatch(setData(constants.SET_TOTALES_PLANILLA1, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const DetallarPagoEmpleado = (iditem) => (dispatch, getStore) => {
    let params ={detalle: iditem}
    dispatch(setLoader(true));
    api.get(`planilla/detallepago`, params).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeEmpleado = (value) => (dispatch) => {
    dispatch(setEmpleado(value));
    dispatch(setEmpresa(null));
    dispatch(listarAusencias(1));
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(listarAusencias(1));
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(listarAusencias(1));
};


const changeEmpleadoPlanilla = (value) => (dispatch) => {
    dispatch(setEmpleado(value));
    dispatch(setEmpresa(null));
    dispatch(ListarPagosEmpleado(1));
    dispatch(getTotalesPagos());
};

const changeFechaInicio1 = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(ListarPagosEmpleado(1));
    dispatch(getTotalesPagos());
};

const changeFechaFin1 = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(ListarPagosEmpleado(1));
    dispatch(getTotalesPagos());
};

const changeEmpresaoPlanilla = (value) => (dispatch) => {
    dispatch(setEmpresa(value));
    dispatch(ListarPagosEmpresas(1));
};

const changeFechaInicio2 = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(ListarPagosEmpresas(1));
};

const changeFechaFin2 = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(ListarPagosEmpresas(1));
};

const changeEmpresao2 = (value) => (dispatch) => {
    dispatch(setEmpleado(null));
    dispatch(setEmpresa(value));
    dispatch(listarAusencias(1));
};

const changeEmpresao3 = (value) => (dispatch) => {
    dispatch(setEmpleado(null));
    dispatch(setEmpresa(value));
    dispatch(ListarPagosEmpleado(1));
    dispatch(getTotalesPagos())
};

export const actions = {
    listarAusencias,
    ListarPagosEmpleado,
    ListarPagosEmpresas,
    changeEmpleado,
    changeFechaInicio,
    changeFechaFin,
    changeEmpleadoPlanilla,
    changeFechaInicio1,
    changeFechaFin1,
    changeEmpresaoPlanilla,
    changeFechaInicio2,
    changeFechaFin2,
    changeEmpresao2,
    changeEmpresao3,
    DetallarPagoEmpleado,
}

export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.RESUMEN]:(state, { data }) => {
        return{
            ...state,
            resumen: data
        }
    },
    [constants.DATA]:(state, { data }) => {
        return{
            ...state,
            data: data
        }
    },
    [constants.DATA2]:(state, { data }) => {
        return{
            ...state,
            data2: data
        }
    },
    [constants.DATA3]:(state, { data }) => {
        return{
            ...state,
            data3: data
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page: page,
        };
    },
    [constants.PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [constants.PAGE3]: (state, { page }) => {
        return {
            ...state,
            page3: page,
        };
    },
    [constants.SET_EMPLEADO]: (state, { empleado }) => {
        return {
            ...state,
            empleado
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
    [constants.SET_TOTAL_DIAS_AUSENCIA]:(state, { data }) => {
        return{
            ...state,
            total_dias: data
        }
    },
    [constants.SET_TOTALES_PLANILLA1]:(state, { data }) => {
        return{
            ...state,
            totalizados: data
        }
    },
    [constants.SET_EMPRESA_PLANILLA]: (state, { empresa }) => {
        return {
            ...state,
            empresa
        };
    },
    [constants.SET_ITEM]: (state, { item }) => {
        return {
            ...state,
            item
        };
    },
}

export const initialState = {
    loader:false,
    resumen: {},
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    data3: {
        results: [],
        count: 0,
    },
    total_dias:0,
    totalizados: {
        total: 0, 
        total_bonos: 0,
        total_sueldo: 0,
        total_produccion:0,
        total_actividades: 0,
        total_descuentos: 0
    },
    item:{},
    page: 1,
    page2: 1,
    page3: 1,
    empleado: null,
    empresa: null,
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
}

export default handleActions(reducers, initialState);
