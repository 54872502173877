import React, { useState, Component } from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import { SelectField, AsyncSelectField, renderNumber } from '../../../Utils/renderField/renderField.js';
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";

import { TIPO_VEHICULO, TIPO_MAQUINARIA } from '../../../../../utility/constants';
import moment from 'moment';

const tipo = [
    { value: TIPO_VEHICULO, label: 'Vehículo' },
    { value: TIPO_MAQUINARIA, label: 'Maquinaria' }
]


const VehiculosForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="ActividadesForm" className="form-validate mb-lg m-2 m-md-5" onSubmit={handleSubmit}>

            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Fecha de Compra</label>
                    <Field
                        name="fecha_compra"
                        selectedDate={moment()}
                        maxDate="2030-12-31"
                        minDate="1990-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="placa">Placa</label>
                    <Field name="placa" label="placa" component={renderField} type="text" className="form-control" />
                </div>

            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="modelo">Modelo</label>
                    <Field name="modelo" label="modelo" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="marca">Marca</label>
                    <Field name="marca" label="marca" component={renderField} type="text" className="form-control" />
                </div>

            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="tipo">Tipo</label>
                    <Field
                        name="tipo"
                        valueKey="value"
                        labelKey="label"
                        options={tipo}
                        component={SelectField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="costo_compra">Costo</label>
                    <Field name="costo_compra" label="costo_compra" component={renderCurrency} type="text" className="form-control" />
                </div>
            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-12">
                    <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field name="descripcion" label="descripcion" component={renderTextArea} type="text" className="form-control" />
                </div>
            </div>

            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/vehiculos_maquinaria" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>

        </form>
    );
};


export default reduxForm({
    form: 'VehiculosForm', // a unique identifier for this form
    initialValues: {
        fecha_compra: moment()
    },
    validate: (data) => {
        return validate(data, {
            fecha_compra: validators.exists()('Este campo es requerido'),
            placa: validators.exists()('Este campo es requerido'),
            modelo: validators.exists()('Este campo es requerido'),
            marca: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
            tipo: validators.exists()('Este campo es requerido'),
            costo_compra: validators.exists()('Este campo es requerido'),
        });
    },
})(VehiculosForm);
