import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Empresas/empresas';
import EmpresasGrid from './EmpresasGrid';

const mstp = state => {
    return {
        ...state.empresas, 
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(EmpresasGrid)
