import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency } from '../../../Utils/renderField';
import {SelectField, AsyncSelectField, renderSelectField} from '../../../Utils/renderField/renderField.js'
import { formatSelects } from '../../../Utils/renderField/renderReadField';
import {connect} from 'react-redux';
import { Monedas} from '../../../../../utility/constants';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import { number } from 'validate-redux-form/compiled/validators';


class ItemArray extends React.Component{
    state = {
        prefix: "Q. ",
        monto: 0,
        t_cambio: 1,
        cambio: 0,
    }
    handleMoneda=(value)=>{
        let simbolo ="Q."
        Monedas.forEach((item, index) =>{
            if (value == item.value){
                simbolo = item.simbolo + ". "
                this.setState({prefix: simbolo})
            }
        })

    }

    render(){
        const {eliminar, index, empresas, cpp, cambio} = this.props
        return(
            <tr key={index}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar" 
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${cpp}.empresa`}
                        label="empresa"
                        valueKey="value"
                        labelKey="label"
                        options={formatSelects(empresas)}
                        component={SelectField}
                    />
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${cpp}.moneda`}
                        label="moneda"
                        component={renderSelectField}
                        options={Monedas}
                        onChange={(e) => {
                            this.handleMoneda(e.target.value)
                        }}
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${cpp}.tipo_cambio`}
                        label="tipo_cambio"
                        prefix={this.state.prefix}
                        component={renderCurrency}
                        decimalScale={4}
                        parse={cell => parseFloat(cell)}
                        onChange={(e) => {
                            // console.log(e)
                        //   this.setState({t_cambio: e})
                        }}
                        className="form-control" 
                    />
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${cpp}.cuenta_inicial`}
                        label="cuenta_inicial"
                        prefix={this.state.prefix}
                        component={renderCurrency}
                        className="form-control" 
                        parse={cell => parseFloat(cell)}
                        onChange={(e) => {
                            // console.log(e)
                            // this.setState({monto: e})
                        }}
                    />
                </td>
                {/* <td className="sin-borde-top" >
                    <RenderMoneda monto={cambio} simbolo={"Q. "} />
                </td> */}
            </tr>
        )
    }


}

const renderCuentasProveedor = ({fields, meta: {error, submitFailed }, contactos, empresas}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th style={{width: "30%"}}>EMPRESA</th>
                        <th style={{width: "20%"}}>MONEDA</th>
                        <th style={{width: "20%"}}>TIPO CAMBIO</th>
                        <th style={{width: "20%"}}>SALDO INICIAL</th>
                        {/* <th>MONTO EN Q</th> */}
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((cpp, index) => {
                        let cambio = 0;
                        // const cuenta = fields.get(index)
                        // try{
                        //     cambio = Number(cuenta.cuenta_inicial) / Number(cuenta.tipo_cambio);
                        // }catch(error){
                        //     cambio = 0;
                        // }
                        return(
                            <ItemArray
                            index={index}
                            key={index}
                            cpp={cpp}
                            // cambio={cambio}
                            eliminar={ () =>{
                                fields.remove(index)
                            }}
                            empresas={empresas}
                            />
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({
                    "tipo_cambio": 1,
                    "moneda": "GTQ",
                    "cuenta_inicial": 0,

                })}>
                    AGREGAR CUENTA
                </button>
            </div>
        </div>
    </div>
)

const renderCuentasClientes = ({fields, meta: {error, submitFailed }, contactos, empresas}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>EMPRESA</th>
                        <th>SALDO INICIAL</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((cpp, index) => (
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar" 
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${cpp}.empresa`}
                                    label="empresa"
                                    valueKey="value"
                                    labelKey="label"
                                    options={formatSelects(empresas)}
                                    component={SelectField}
                                />
                            </td>
                            <td className="sin-borde-top" >
                                <Field
                                    name={`${cpp}.cuenta_inicial`}
                                    label="cuenta_inicial"
                                    component={renderCurrency}
                                    className="form-control" 
                                />
                            </td>
                            
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({
                    "cuenta_inicial": 0,
                })}>
                    AGREGAR CUENTA
                </button>
            </div>
        </div>
    </div>
)



let CuentacppForm = (props) => {
    const { handleSubmit, previousStep, empresas, es_cliente } = props;
    return (
        <form name="CuentacppForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >{`CUENTAS POR ${ es_cliente == 2 ? 'COBRAR':'PAGAR'}`}</h4>
            </div>
            <FieldArray name="cpp" component={es_cliente == 2 ? renderCuentasClientes:renderCuentasProveedor}  empresas={empresas}/>
            <div className="row  justify-content-sm-around justify-content-center">
                <button onClick={previousStep} className="btn btn-secondary m-1 align-self-center">ANTERIOR</button>                        
                <button type="submit" className="btn btn-primary m-1 align-self-center">REGISTRAR</button>
            </div>
        </form>
    );
};


CuentacppForm =  reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        return validate(data, {
        });
    },
})(CuentacppForm);

const selector = formValueSelector('ClientesForm');
CuentacppForm = connect( (state, ownProps)=>{
    const cpp = selector(state, 'cpp');

    if (!cpp) {
        if (ownProps.es_cliente == 2){
            ownProps.setEmpresasForm(ownProps.empresas);   
        }
        else{
            ownProps.setEmpresasFormProvedor(ownProps.empresas);
        }
    }
    return{
        test: true
    }
})(CuentacppForm)

export default  CuentacppForm;