import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';


const AnulacionForm = (props) => {
    const { handleSubmit} = props;
    return (
        <form name="anulacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center mt-4">
                <div className="form-group has-feedback col-md-10 col-12">
                    <Field
                        name="descripcionAnulacion"
                        label="descripcionAnulacion"
                        placeholder="Descripción de la anulación"
                        component={renderTextArea}
                        type="text"
                        top ={{top:"67px"}}
                        className="form-control" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="button" onClick={() => {
                            props.cerrarModal(false);
                        }} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'anulacionForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            descripcionAnulacion: validators.exists()('Este campo es requerido'),
        });
    },
})(AnulacionForm);
