import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import _ from "lodash";
import { NotificationManager} from "react-notifications";
import moment from 'moment';
import { bool } from 'prop-types';
import { getMunicipios } from '../../../utility/constants';
import { change } from 'redux-form';

let url = 'clientes' ;
let form = 'ClientesForm';
let dirGrid = '/clientes';
let dirGrid2 = '/proveedores';

export const constants ={
    LOADER: 'CLIENTES_LOADER',
    DATA: 'CLIENTES_DATA',
    ITEM: `CLIENTES_ITEM`,
    PAGE: `CLIENTES_PAGE`,
    ORDERING: `CLIENTES_ORDERING`,
    SEARCH: `CLIENTES_SEARCH`,
    SET_CONTACTOS: 'SET_CONTACTOS',
    SET_CUENTAS: 'SET_CUENTAS',
    SET_SUCURSALES: 'SET_SUCURSALES',
    SET_NITS: 'SET_NITS',
    SET_REGIONES_CP: 'SET_REGIONES_CP',
    SET_MUNICIPIOS: 'SET_MUNICIPIOS',
    SET_MUNICIPIOS_BANCOS: 'SET_MUNICIPIOS_BANCOS',
    SET_CONTROLES: 'SET_CONTROLES',
    SET_EMPRESAS:'SET_EMPRESAS',
    LOADER_COMPONENT: 'CLIENTES_LOADER_COMPONENT',
    SET_VENDEDORES: 'SET_VENDEDORES',
    SET_SEARCH_VENDEDOR: 'SET_SEARCH_VENDEDOR',
    SET_FACTURACION_REF: 'SET_FACTURACION_REF',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})
const setLoaderComponent = loaderComponet =>({
    type:constants.LOADER_COMPONENT,
    loaderComponet
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setDataSelect = (type, data) =>({
    type,
    data
});

const setFacturacionRef = facturacion_ref => ({
    type: constants.SET_FACTURACION_REF,
    facturacion_ref,
});

const listar = (page = 1, esCliente = true) => (dispatch, getStore) => {
    const resource = getStore().clientes;
    const params = { page };
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = me.id
    }
    if (resource.vendedor != null)
    params.vendedor = resource.vendedor.value;
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.esCliente = esCliente
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const listarPro = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().clientes;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    // params.esCliente = "false"
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = (id, esCliente) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const params = {};
    const resource = getStore().clientes;
    params.esCliente=esCliente;
    api.get(`${url}/${id}`,params).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if (!!form){
            dispatch(initializeForm(form, response));

            // Respaldo de datos de facturacion
            response.facturacion.forEach((identificacion, index) => {
                if (identificacion.tipo == "NIT" && identificacion.Nit && identificacion.nombre) {
                    let existe = _.find(resource.facturacion_ref, x => x.nombre == nombre && x.tipo=='CUI')
                    if (!existe) {
                        let temp = [];
                        temp.push(_.cloneDeep(identificacion));
                        dispatch(setFacturacionRef(temp));
                    }
                }
            })

            dispatch(setMunicipios(response.departamento))
            let municipios = response.sucursales.map(
                (item, index) => getMunicipios(item.departamento)
            )
            dispatch(setMunicipios(municipios, 2))
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, esCliente, facturacion_ref=null) => (dispatch) => {
    dispatch(setLoader(true));
    let esValido = true;

     // Validación de Identificaciones
     if (data.facturacion && facturacion_ref) {
        data.facturacion.forEach((identificacion, index) => {
            if (identificacion.nombre.split(' ').join('').length == 0 || identificacion.Nit.split(' ').join('').length == 0) {
                if (identificacion.tipo == "NIT") NotificationManager.error(`NIT inválido`, 'ERROR');
                else if (identificacion.tipo == "CUI") NotificationManager.error(`CUI inválido`, 'ERROR');
                dispatch(setLoader(false));
                esValido = false;
            }
            else if (identificacion.nombre.split(' ').join('') == 'Invalido') {
                if (identificacion.tipo == "NIT") NotificationManager.error(`NIT inválido`, 'ERROR');
                else if (identificacion.tipo == "CUI") NotificationManager.error(`CUI inválido`, 'ERROR');
                dispatch(setLoader(false));
                esValido = false;
            } // Verificación NIT
            if (identificacion.tipo == "NIT" && identificacion.Nit && identificacion.nombre) {
                let existe = _.find(facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='NIT')
                if (existe && existe.Nit != identificacion.Nit) {
                    NotificationManager.error(`Datos de NIT Inválidos para ${existe.nombre}`, 'ERROR');
                    dispatch(setLoader(false));
                    esValido = false;
                }
            } // Verificación CUI
            else if (identificacion.tipo == "CUI" && identificacion.Nit && identificacion.nombre) {
                let existe = _.find(facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='CUI')
                if (existe && existe.Nit != identificacion.Nit) {
                    NotificationManager.error(`Datos de CUI Inválidos para ${existe.nombre}`, 'ERROR');
                    dispatch(setLoader(false));
                    esValido = false;
                }
            }
        })
    }

    if (esValido) {
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset('ClientesForm'));
        if (!!dirGrid)
            if(esCliente===1){
                dispatch(push(dirGrid2));
            }else{
                dispatch(push(dirGrid));
            }
    }).catch((res) => {
        NotificationManager.error( res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
    }
};

const editar = (id, data, esCliente, facturacion_ref=null) => (dispatch) => {
    dispatch(setLoader(true));
    let esValido = true;

    // Validación de Identificaciones
    if (data.facturacion && facturacion_ref) {
        data.facturacion.forEach((identificacion, index) => {
            if (identificacion.nombre.split(' ').join('').length == 0 || identificacion.Nit.split(' ').join('').length == 0) {
                if (identificacion.tipo == "NIT") NotificationManager.error(`NIT inválido`, 'ERROR');
                else if (identificacion.tipo == "CUI") NotificationManager.error(`CUI inválido`, 'ERROR');
                dispatch(setLoader(false));
                esValido = false;
            }
            else if (identificacion.nombre.split(' ').join('') == 'Invalido') {
                if (identificacion.tipo == "NIT") NotificationManager.error(`NIT inválido`, 'ERROR');
                else if (identificacion.tipo == "CUI") NotificationManager.error(`CUI inválido`, 'ERROR');
                dispatch(setLoader(false));
                esValido = false;
            } // Verificación NIT
            else if (identificacion.tipo == "NIT" && identificacion.Nit && identificacion.nombre) {
                let existe = _.find(facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='NIT')
                if (existe && existe.Nit != identificacion.Nit) {
                    NotificationManager.error(`Datos de NIT Inválidos para ${existe.nombre}`, 'ERROR');
                    dispatch(setLoader(false));
                    esValido = false;
                }
            } // Verificación CUI
            else if (identificacion.tipo == "CUI" && identificacion.Nit && identificacion.nombre) {
                let existe = _.find(facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='CUI')
                if (existe && existe.Nit != identificacion.Nit) {
                    NotificationManager.error(`Datos de CUI Inválidos para ${existe.nombre}`, 'ERROR');
                    dispatch(setLoader(false));
                    esValido = false;
                }
            }
        })
    }


    if (esValido) {
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            if(esCliente===1){
                dispatch(push(dirGrid2));
            }else{
                dispatch(push(dirGrid));
            }
    }).catch((res) => {
        NotificationManager.error( res.detail ? res.detail:'Error en la Edicion', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
    }
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const clearSearch = () => (dispatch) => {
    dispatch(setSearch(""));
    dispatch({type:constants.SET_SEARCH_VENDEDOR, vendedor:null});
}

const searchChangePro = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarPro());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectContactos = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id};
    api.get(`${url}/selectContactos`, params).then((res)=>{
        dispatch(setData(constants.SET_CONTACTOS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectCuentas = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params ={id}
    api.get(`${url}/selectCuentas`, params).then((res)=>{
        dispatch(setData(constants.SET_CUENTAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectSucursales = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params ={id}
    api.get(`${url}/selectSucursales`, params).then((res)=>{
        dispatch(setData(constants.SET_SUCURSALES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectNits = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params ={id}
    api.get(`${url}/selectNit`, params).then((res)=>{
        dispatch(setData(constants.SET_NITS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectRegiones = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('region/selectregiones').then((res)=>{
        dispatch({type: constants.SET_REGIONES_CP, data:res})
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setMunicipios = (val, set= 1) => (dispatch) =>{
    if (set == 1) {
        let municipios = getMunicipios(val)
        dispatch({type: constants.SET_MUNICIPIOS, data:municipios})
    }else
        dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:val})

}

const AddMunicipio = () => (dispatch, getStore) =>{
    const resource = getStore().clientes;
    console.log("")
    let anterior = resource.municipios_bancos
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior, getMunicipios('01')]})
}

const ChangeMunicipio = ( index, depto ) => (dispatch, getStore) =>{
    const resource = getStore().clientes;
    const formulario = getStore().form.ClientesForm;

    let anterior = resource.municipios_bancos;
    anterior[index] = _.cloneDeep(getMunicipios(depto));
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior]})
    dispatch({type: constants.SET_CONTROLES, data:anterior[index]})
    formulario.values.sucursales[index].municipios = anterior[index][0].value

}

const selectEmpresas = () => (dispatch) =>{
    // devuelve el listado de empreas con el id de su entidad

    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setDataSelect(constants.SET_EMPRESAS, res));
        dispatch(setEmpresasForm(res));
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectVendedor = () => (dispatch) =>{
    const params = {};
    dispatch(setLoader(true));
    params.is_active=true
    api.get('vendedores/select_vendedores',params).then((res)=>{
        dispatch(setDataSelect(constants.SET_VENDEDORES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const changeVendedor = vendedor => dispatch => {
    dispatch({type:constants.SET_SEARCH_VENDEDOR, vendedor});
    dispatch(listar());
}

const setEmpresasForm = value => dispatch =>{
    let Empresas =[]
    value.forEach((item, index) => {
        Empresas[index] = {
            // empresa: {id: item.id, value: item.id, label: item.nombre},
            empresa: item.id,
            cuenta_inicial: 0
        }

    });
    dispatch(change('ClientesForm', 'cpp', Empresas))
}

const setEmpresasFormProvedor = value => dispatch =>{
    let Empresas =[]
    value.forEach((item, index) => {
        Empresas[index] = {
            // empresa: {id: item.id, value: item.id, label: item.nombre},
            empresa: item.id,
            moneda: "GTQ",
            tipo_cambio: 1,
            cuenta_inicial: 0
        }

    });
    dispatch(change('ClientesForm', 'cpp', Empresas))
}

const limpiarItem = () => dispatch => {
    dispatch(setData(constants.ITEM, {}));
}

const validateNit = index => (dispatch, getStore) => {
    const form = getStore().form.ClientesForm.values
    const source = getStore().clientes;
    const nit = form.facturacion[index].Nit;
    const params = { nit };
    api.get(`${url}/validateNit`,params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        form.facturacion[index].nombre = response.detail.nombre;

        // Respaldo de datos de facturacion
        if (response.detail.valido == "true") {
            if (form.facturacion[index].tipo == "NIT" && form.facturacion[index].Nit && form.facturacion[index].nombre) {
                let nombre_completo = response.detail.first_name + " " + response.detail.last_name;
                let existe = _.find(source.facturacion_ref, x => x.nombre == nombre_completo && x.tipo=='CUI')
                if (!existe) {
                    let temp = source.facturacion_ref;
                    temp.push(_.cloneDeep(form.facturacion[index]));
                    dispatch(setFacturacionRef(temp));
                }
            }
        }


        dispatch(initializeForm('ClienteForm', form))
    }).finally( );
};

const setDataName = (index, data=undefined) => (dispatch, getState) => {
    dispatch(setLoaderComponent(true));
    const form = getState().form.ClientesForm.values
    const source = getState().clientes;
    const URL_GET_CUI = "get-valid-cui";
    const cui = form.facturacion[index].Nit;
    let nameNew = "";
    //si recibe data es porque se esta editando
    if (data) {
        nameNew = data.first_name + " " + data.last_name;
        form.facturacion[index].nombre = nameNew;


        // Respaldo de datos de facturacion
        let nameSinEspacios = nameNew.split(" ").join("");
        if (nameSinEspacios.length > 0) {
            if (form.facturacion[index].tipo == "NIT" && form.facturacion[index].Nit && form.facturacion[index].nombre) {
                let existe = _.find(source.facturacion_ref, x => x.nombre == nameNew && x.tipo=='CUI')
                if (!existe) {
                    let temp = source.facturacion_ref;
                    temp.push(_.cloneDeep(form.facturacion[index]));
                    dispatch(setFacturacionRef(temp));
                }
            }
        }


        dispatch(initializeForm("ClienteForm", form));
    }
    else {
        const fetchData = async () => {
            try {
                const data = await api.post(URL_GET_CUI, {cui});
                nameNew = data.first_name + " " + data.last_name;
                form.facturacion[index].nombre = nameNew;


                // Respaldo de datos de facturacion
                if (data.first_name != "Invalido") {
                    let existe = _.find(source.facturacion_ref, x => x.nombre == nameNew && x.tipo=='CUI')
                    if (!existe) {
                        let temp = source.facturacion_ref;
                        temp.push(_.cloneDeep(form.facturacion[index]));
                        dispatch(setFacturacionRef(temp));
                    }
                }


                await dispatch(initializeForm("ClienteForm", form));
            } catch (error) {
                nameNew = "invalido";
                form.facturacion[index].nombre = nameNew;
                await dispatch(initializeForm("ClienteForm", form));
            }
        };
        fetchData()
    }
    dispatch(setLoaderComponent(false));
};

const changeFieldClienteForm = (field, value) => (dispatch, getState) => {
    dispatch(change("ClienteForm", field, value));
}

const getFieldClienteFormFacturacion = (index, tipo) => (dispatch, getStore) => {
    const form = getStore().form.ClientesForm.values
    const tipo_doc = form.facturacion[index][tipo];
    return tipo_doc;
}


export const actions = {
    listar,
    listarPro,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    searchChangePro,
    onSortChange,
    selectContactos,
    selectCuentas,
    selectSucursales,
    selectNits,
    selectRegiones,
    setMunicipios,
    AddMunicipio,
    ChangeMunicipio,
    selectEmpresas,
    setEmpresasForm,
    setEmpresasFormProvedor,
    limpiarItem,
    clearSearch,
    validateNit,
    changeFieldClienteForm,
    setDataName,
    getFieldClienteFormFacturacion,
    changeVendedor,
    selectVendedor,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.LOADER_COMPONENT]:(state,{loaderComponet}) =>{
        return{
            ...state,
            loaderComponet,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item:data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_CONTACTOS]:(state,{ data })=>{
        return{
            ...state,
            contactos:{
                results:data.contactos,
                count:data.count
            }
        }
    },
    [constants.SET_CUENTAS]:(state,{ data })=>{
        return{
            ...state,
            cuentas:  {
                results:data.cuentas,
                count:data.count
            }
        }
    },
    [constants.SET_SUCURSALES]:(state,{ data })=>{
        return{
            ...state,
            sucursales:  {
                results:data.sucursales,
                count:data.count
            }
        }
    },
    [constants.SET_NITS]:(state,{ data })=>{
        return{
            ...state,
            nits:  {
                results:data.Nits,
                count:data.count
            }
        }
    },
    [constants.SET_REGIONES_CP]:(state,{ data })=>{
        return{
            ...state,
            regiones: data,
        }
    },
    [constants.SET_MUNICIPIOS]:(state,{ data })=>{
        return{
            ...state,
            municipios: data,
        }
    },
    [constants.SET_MUNICIPIOS_BANCOS]:(state,{ data })=>{
        return{
            ...state,
            municipios_bancos: data,
        }
    },
    [constants.SET_CONTROLES]:(state,{ data })=>{
        return{
            ...state,
            controles: data,
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_SEARCH_VENDEDOR]: (state, {vendedor}) => {
        return {
            ...state,
            vendedor
        }
    },
    [constants.SET_VENDEDORES]:(state,{ data })=>{
        return{
            ...state,
            vendedores: data,
        }
    },
    [constants.SET_FACTURACION_REF]: (state, { facturacion_ref }) => {
        return {
            ...state,
            facturacion_ref,
        };
    },
};


export const initialState ={
    loader:false,
    loaderComponet:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    contactos:{
        results:[],
        count:0
    },
    cuentas:{
        results:[],
        count:0
    },
    sucursales:{
        results:[],
        count:0
    },
    nits:{
        results:[],
        count:0
    },
    regiones:[],
    municipios:[],
    municipios_bancos:[],
    controles:[],
    empresas:[],
    facturacion_ref: []
};

export default handleActions(reducers, initialState);
