import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import CardSimple from '../../../Utils/Cards/cardSimple';
import GridPlanilla from './gridPlanilla';
import LoadMask from '../../../Utils/LoadMask/LoadMask';


export default class DetallePlanilla extends Component {
    state = {
        data: {
            results: null,
        }
    }
    componentDidMount() {
        const { detallePlanilla, permisos } = this.props;
        detallePlanilla(this.props.match.params.id);
        //this.props.handleData(this.props.value)
    }

/*     descargar = (id) => {
        const { descargarCSV } = this.props;
        console.log("Planilla ", id);
        descargarCSV(id, 1);
        setTimeout(() => {
            descargarCSV(id, 2);
        }, 2000);
        console.log("Planilla ", id);
    }

    renderStandarActions = () =>{
        const { value, eliminar } = this.props
        const { descargar } = this;
        if (value) {
            return standardActions({ verpro: "planilla", descargar })
        }
        else{
            return standardActions({ editar: "planilla",  eliminar })
        }
    } */

    render() {
        const { item, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        let data = {}
        return (
            <CardSimple margen="mt-1">
                <LoadMask  loading={loader} light type={"TailSpin"}>
                { !!item && item.fecha_inicio &&(
                    <div className="m-t p-t fnt-size">
                        <div className="row m-none" style={{paddingTop:"15px"}}>
                            <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                <h3 className="t-primary m-none"> { item ? `No. ${item.no_pago}` :""}</h3>
                            </div>
                        </div>
                        <br/>
                        <div className="col-12">
                            <span className="m-none t-primary bold"> Detalle </span>
                        </div>
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                        <div className="row m-none">
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Fecha inicial: </span> <br/>
                                <span className="m-none t-musgo semibold"> <RenderDateTime fecha={item.fecha_inicio} > </RenderDateTime> </span>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Fecha final: </span> <br/>
                                <span className="m-none t-musgo semibold"> <RenderDateTime fecha={item.fecha_final}> </RenderDateTime> </span>
                            </div>
                        </div>
                        <br/>
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                        <div className="row m-none">
                            <div className="col-md-4 col-12  d-flex justify-content-start" >
                                <div>
                                    <br/>
                                    <h5 className="t-mostaza">Total</h5>
                                    <h5 className="t-musgo m-0"><RenderMoneda monto={item.total} simbolo={'Q '}/></h5>
                                    <br/>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 d-flex justify-content-start">
                                <div>
                                    <br/>
                                    <h5 className="t-mostaza">Total Banco</h5>
                                    <h5 className="t-musgo m-0"><RenderMoneda monto={item.total_banco} simbolo={'Q '}/></h5>
                                    <br/>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 d-flex justify-content-start">
                                <div>
                                    <br/>
                                    <h5 className="t-mostaza">Total Efectivo</h5>
                                    <h5 className="t-musgo m-0"><RenderMoneda monto={item.total_efectivo} simbolo={'Q '}/></h5>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <br/>
                        {
                            true &&(
                                <div className="col-6">
                                    <a target="_blank" href={item.archivo} className="btn btn-primary align-self-center">Imprimible</a>
                                </div>
                            )
                        }
                        <br/>
                        {item.editable_planilla && item.editable_planilla.detalleplanilla &&(
                            <div className="col-12">
                                <div className='col-12 p-0'>
                                    <span className="m-none t-primary bold"> Empleados </span>
                                </div>
                                <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 0px"}}/>
                                <GridPlanilla
                                    data = {item.editable_planilla.detalleplanilla}
                                    loader = {loader}
                                />
                            </div>
                            )
                        }
                    </div>
                )
                }
                </LoadMask>
            </CardSimple>
        );
    }
}
