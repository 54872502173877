import React, { Component, Fragment } from 'react'

import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import Toolbar from '../../../Utils/Toolbar/ToolbarReporteIngreso';

import NVD3ChartMask from "../../../Utils/Graficas/NDV3ChartMask";
import {
    opcionesG1
} from '../../../Utils/Graficas/opciones';

import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';

import RowTablaGasto from './Componentes/row_tabla_gasto';

import d3 from 'd3';
import Chart from 'react-google-charts';

function asignarValores(listado_gastos, totales_gasto){
    listado_gastos.map((item_gasto, index) => {
        let _gasto = totales_gasto.find( element =>  element.transaccion__tipo_gasto == item_gasto.value)
        if(_gasto){
            item_gasto.monto = _gasto.value
        }
        asignarValores(item_gasto.children, totales_gasto)
    })
}

function saldoHijos(item_gasto) {
    if(item_gasto.children.length > 0){
        item_gasto.children.map((_sub_item, index) => {
            saldoHijos(_sub_item)
            let total = _.sumBy(_sub_item.children, 'monto')
            if(total == 0){
                total = _sub_item.monto;
            }
            _sub_item.monto = total;
        })
    }
}

function calcularTotales(listado_gastos) {
    listado_gastos.map((item_gasto, index) => {
       saldoHijos(item_gasto)
       let total = _.sumBy(item_gasto.children, 'monto')
        if(total == 0){
            total = item_gasto.monto;
        }
        item_gasto.monto = total
    })
}

export default class DetalleReporteGeneral extends Component {
    state = {
        listado_gastos: []
    }
    componentDidMount(){
        this.props.getGastosEstadistica();
        this.props.listarCategoriasGasto();
    }
    
    componentDidUpdate(prevProps){
        if(this.props.grafica_gastos !== prevProps.grafica_gastos){
            if(this.props.listado_gastos && this.props.grafica_gastos.rubros_gastos){
                let listado_gastos = _.cloneDeep(this.props.listado_gastos);
                let totales_gasto = _.cloneDeep(this.props.grafica_gastos.rubros_gastos);
                asignarValores(listado_gastos, totales_gasto);
                calcularTotales(listado_gastos)
                this.setState({listado_gastos: listado_gastos})
            }
        }
    }
    componentWillUnmount(){
        setTimeout(() => {
            d3.selectAll('.nvtooltip').style('opacity', 0);
        }, 100);
        this.props.resetFechas();
    }

    actualizar_categoria =(value) =>{
        this.props.abrir_categoria(value);
        let listado_gastos = _.cloneDeep(this.props.listado_gastos);
        let totales_gasto = _.cloneDeep(this.props.grafica_gastos.rubros_gastos);
        asignarValores(listado_gastos, totales_gasto);
        calcularTotales(listado_gastos)
        this.setState({listado_gastos: listado_gastos})
    }
    render() {
        const { page, loader, listar: onPageChange,
            mes,
            listado_gastos,
            onSortChange, grafica_gastos } = this.props;
        let data = {
            results:grafica_gastos.rubros_gastos,
            count: 0
        }
        // console.log('data: ', data)
        let options2 = _.cloneDeep(opcionesG1.optionsPieChart)
        return (
            <div className="col-md-12 m-0 p-0">
                <div className="py-3 px-md-3 " style={{border:"1px solid #E8E8E8", }}>
                    <Toolbar
                        change_empresa={this.props.set_empresa_detalle}
                        change_fechaI={this.props.set_fecha_inicio}
                        change_fechaF={this.props.set_fecha_final}
                        filtro_empresa={this.props.empresa_detalle}
                        filtro_mes={true}
                        filtro_fechas={true}
                        change_anio={this.props.set_anio}
                        {...this.props} />
                </div>
                <div className="d-flex flex-wrap flex-column flex-md-row justify-content-around mt-4">
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className="p-0 m-0 mt-2 mr-md-1 flex-1 flex-column">
                        <div className="my-3 d-flex justify-content-center">
                            <div>
                                <h5>Ingresos</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_ingresos} simbolo={'Q'} />
                                }</h3>
                            </div>
                            
                        </div>
                        <div className="my-3 d-flex justify-content-center">
                            <div className="col-md-5">
                                <h6>Efectivo</h6>
                                <h4 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_ingresos_efectivo} simbolo={'Q'} />
                                }</h4>
                            </div>
                            <div className="col-md-5">
                                <h6>Bancos</h6>
                                <h4 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_ingresos_bancos} simbolo={'Q'} />
                                }</h4>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className=" p-0 m-0 mt-2 ml-md-2 flex-1 flex-column">
                            <div className="my-3 d-flex justify-content-center">
                            <div>
                                <h5>Gastos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_gastos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="my-3 d-flex justify-content-center">
                            <div className="col-md-5">
                                <h6>Efectivo</h6>
                                <h4 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_gastos_efectivo} simbolo={'Q'} />
                                }</h4>
                            </div>
                            <div className="col-md-5">
                                <h6>Bancos</h6>
                                <h4 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_gastos_bancos} simbolo={'Q'} />
                                }</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0">
                        <div className="px-1 py-1">
                            <h5>INTERNOS</h5>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Ingresos</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_ingresos_internos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Gastos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_gastos_internos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap flex-column flex-md-row justify-content-around mt-4">
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className="p-0 m-0 mt-2 mr-md-1 flex-1 flex-column"></div>
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className="p-0 m-0 mt-2 mr-md-1 flex-1 flex-column">
                    <div className="col-12 row m-0 p-0">
                        <div className="px-1 py-1">
                            <h5>RETIROS</h5>
                        </div>
                        <div className="col-md-4 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Efectivo</h5>
                                <h3 className="t-primary text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_retiros_efectivo} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Bancos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_retiros_bancos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0">
                        <div className="px-3 py-1">
                            <h5>TOTAL</h5>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Ingresos</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_ingresos_internos + grafica_gastos.total_ingresos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Gastos</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={grafica_gastos.total_gastos_internos + grafica_gastos.total_gastos + grafica_gastos.total_retiros_efectivo +grafica_gastos.total_retiros_bancos} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0 justify-content-center">
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8 justify-content-center">
                                <h5>Ingresos vs gastos</h5>
                                <h3 className="t-azul text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={(grafica_gastos.total_ingresos_internos + grafica_gastos.total_ingresos) - (grafica_gastos.total_gastos_internos + grafica_gastos.total_gastos + grafica_gastos.total_retiros_efectivo +grafica_gastos.total_retiros_bancos)} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                </div>         
                <div className="d-flex flex-wrap flex-column flex-md-row justify-content-around mt-4">
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className="p-0 m-0 mt-2 mr-md-1 flex-1 flex-column">
                        <div className="px-3 py-2">
                            <h4>Gastos</h4>
                        </div>
                        <Chart
                            width={'600px'}
                            height={'500px'}                          
                            chartType="PieChart"
                            loader={<div>Cargando Gráfica</div>}
                            data={this.props.grafica_rubros_gastos}
                            options={{
                                colors: ["#073A56","#1B6197","#7CBEE1", "#F3A631", "#2270a0" , "#9C9C9C"],
                            }}
                        />
                                    
                    </div>
                    <div
                        style={{border:"1px solid #E8E8E8", }}
                        className=" p-0 m-0 mt-2 ml-md-2 flex-1 flex-column">
                            <div className="px-3 py-2  " style={{border:"1px solid #E8E8E8", }}>
                                <h4>Categoría de Gastos</h4>
                            </div>
                            <div className="px-1" style={{
                                overflowY: 'scroll',
                                overflowX: 'hidden'
                            }}>

                                <table className="table table-sm table-responsive-sm m-0 table-striped">
                                    <thead>
                                    <tr>
                                        <th className="reporte-table" style={{width: '75%'}}>Gasto</th>
                                        <th className="reporte-table">Monto</th>
                                    </tr>
                                    </thead>
                                {/* </table>

                            </div>

                            <div className="contenido-contactos">
                                <table className="table table-sm  m-0 table-striped"> */}
                                    <tbody>
                                    {this.state.listado_gastos.map((item_gasto, index) => {
                                        return (
                                            <Fragment key={index}>
                                                {
                                                    item_gasto.monto > 0.00 && (
                                                        <RowTablaGasto item={item_gasto} index={index} abrir_categoria={this.actualizar_categoria} />
                                                    )
                                                }
                                            </Fragment>
                                        );

                                    })}
                                    </tbody>
                                </table>
                            </div>
                    </div>
                </div>
            </div>
        )
    }

}
