import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency, renderDropDownField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";


const RealizarPagoForm = (props) => {
    const { handleSubmit, transaccion} = props;
    console.log(props.simbolo)
    return (
        <form name="anulacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul"  htmlFor="fecha_fundacion">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropDownField}
                    />

                </div>
                <div className="form-group has-feedback col-md-6 col-12 d-flex align-items-center">
                    <span className="font-weight-bold mr-1">Cheque no:  </span> <span>{transaccion.no_documento}</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-12">
                    <label className="t-azul"  htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        top={{top:"95px"}}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="col-md-12 m-0 p-0 d-flex justify-content-center mt-3">
                    <div className="col-md-10 m-0 p-0 inline-headers text-center text-md-right">
                        <span className="t-primary h5 mr-3"  htmlFor="descripcion">TOTAL.     </span>
                        <h2 className="t-mostaza"  htmlFor="descripcion">{
                            <RenderMoneda monto={transaccion.monto} simbolo={getSimbolo(transaccion.moneda)} />
                        }</h2>
                    </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="button" onClick={() => {
                            props.cerrarModal(false);
                        }} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'RealizarPagoForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            descripcionAnulacion: validators.exists()('Este campo es requerido'),
            credito: validators.exists()('Este campo es requerido'),
        });
    },
})(RealizarPagoForm);
