import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {SelectField} from '../../Utils/renderField/renderField.js'

const RegisterForm = (props) => {
    const { handleSubmit, format, roles, empresas, vendedores_form} = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-4">
                    <label className="t-azul" htmlFor="username">Nombre de usuario*</label>
                    <Field name="username" label="Usuario" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-4">
                    <label className="t-azul"  htmlFor="first_name">Nombre*</label>
                    <Field name="first_name" label="Nombre" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-4">
                    <label className="t-azul"  htmlFor="last_name">Apellido*</label>
                    <Field name="last_name" label="Apellido" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-4">
                    <label className="t-azul"  htmlFor="address">Direccion</label>
                    <Field name="profile.address" label="address" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-4">
                    <label className="t-azul"  htmlFor="email">Correo*</label>
                    <Field name="email" label="email" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-4">
                    <label className="t-azul"  htmlFor="phone">Teléfono</label>
                    <Field name="profile.phone" label="phone" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-4">
                    <label className="t-azul"  htmlFor="password">Contraseña*</label>
                    <Field
                        name="password"
                        label="Contraseña"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-4">
                    <label className="t-azul"  htmlFor="confirmPassword">Confirmar Contraseña*</label>
                    <Field
                        name="confirmPassword"
                        label="Confirmar Contraseña"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            {
                (!vendedores_form) &&(
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-4">
                            <label className="t-azul" htmlFor="role">Role*</label>
                            <Field
                                name="profile.role"
                                label="role"
                                options={format(roles)}
                                component={SelectField}
                            />
                        </div>
                        <div className="form-group has-feedback col-4">
                            <label className="t-azul" htmlFor="empresa">Empresa</label>
                            <Field
                                name="profile.empresa"
                                label="empresa"
                                options={format(empresas)}
                                component={SelectField}
                            />
                        </div>
                    </div>
                )
            }


            <div className="buttons-box">
                <Link className="btn btn-secondary  align-self-center" to="/usuarios" >CANCELAR</Link>
                <button type="submit" className="btn btn-azul-claro m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'register', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
               validators.exists()('Este campo es requerido'),
               matchPassword(data.password, data.confirmPassword)()('Las contraseñas no coinciden')
            ),
            username: validators.exists()('Este campo es requerido'),
            first_name: validators.exists()('Este campo es requerido'),
            last_name: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
            email: validators.exists()('Este campo es requerido'),
        });
    },
})(RegisterForm);
