import { push, replace } from "react-router-redux";
import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import moment from 'moment';
import { StrictMode } from "react";
import Swal from 'sweetalert2';

let url = 'productos' ;
let form = 'PreciosInternosForm';
let dirGrid = '/precios_internos';

export const constants = {
    LOADER: `PRECIOS_INTERNOS_LOADER`,
    DATA: `PRECIOS_INTERNOS_DATA`,
    DATAREFUSE: `PRECIOS_INTERNOS_DATAREFUSE`,
    ITEM: `PRECIOS_INTERNOS_ITEM`,
    PAGE: `PRECIOS_INTERNOS_PAGE`,
    ORDERING: `PRECIOS_INTERNOS_ORDERING`,
    SEARCH: `PRECIOS_INTERNOS_SEARCH`,
    SET_ROLES:'PRECIOS_INTERNOS_SET_ROLES',
    SET_EMPRESAS:'PRECIOS_INTERNOS_SET_EMPRESAS',
    SET_TIPOS: 'PRECIOS_INTERNOS_TIPOS',
    SET_REGIONES:'PRECIOS_INTERNOS_SET_REGIONES',
    SET_CUENTAS: `PRECIOS_INTERNOS_CUENTAS`,
    TIPO_GASTOS: 'PRECIOS_INTERNOS_GASTOS_TIPO_GASTO',
    SET_SEARCH_EMPRESA: 'PRECIOS_INTERNOS_SET_SEARCH_EMPRESA',
    SET_SEARCH_TIPO: 'PRECIOS_INTERNOS_SET_SEARCH_TIPO',
    PRODUCTOS_SELECCIONADOS: 'PRECIOS_INTERNOS_SELECCIONADOS',
    PRODUCTO_SELECCIONADO: 'PRECIOS_INTERNOS_SELECCIONADO',
    SET_OPEN_MODAL: 'SET_OPEN_MODAL_PRECIOS_INTERNOS',
    SET_SELECCIONAR_TODOS: 'SET_SELECCIONAR_TODOS_PRECIOS_INTERNOS',
    SET_PRODUCTOS_DESMARCADOS: 'SET_PRODUCTOS_DESMARCADOS_PRECIOS_INTERNOS',
    SET_FECHA_ACTUALIZACION: 'SET_FECHA_ACTUALIZACION',
    SET_PRECIO_INTERNO_ANTERIOR: 'SET_PRECIO_INTERNO_ANTERIOR'
};

    // -----------------------------------
    // Pure Actions
    // -----------------------------------

const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});
const setData = data => ({
    type: constants.DATA,
    data,
});
const setItem = item => ({
    type: constants.ITEM,
    item,
});
const setPage = page => ({
    type: constants.PAGE,
    page,
});
const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});
const setSearch = search => ({
    type: constants.SEARCH,
    search,
});
const setDataSelect = (type, data) =>({
    type,
    data
});
// -----------------------------------
// Actions
// -----------------------------------
const listar = (page = 1, data) => (dispatch, getStore) => {
    const resource = getStore().reportePreciosInternos;
    const params = { page, ...data};
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.empresa != null)
        params.empresa = resource.empresa.value;
    if (resource.tipo != null)
        params.tipo_producto = resource.tipo.value;
    params.vendible = true
    dispatch(setLoader(true));
    api.get(url, params).then((response) => {
        if(resource.seleccionar_todos){
            if(resource.productos_desmarcados.length > 0){
                response.results.forEach(producto =>{
                    resource.productos_desmarcados.forEach(prod =>{
                        if(producto.id == prod.id){
                            producto.check=false
                        }
                        else{
                            producto.check=true
                        }
                    })
                })
            }
            else{
                response.results.forEach(producto =>{
                    producto.check=true
                })
            }
        }
        else{
            response.results.forEach(producto =>{
                if(resource.productos_seleccionados.length > 0){
                    resource.productos_seleccionados.forEach(prod =>{
                        if(producto.id == prod.id){
                            producto.check=true
                        }
                    })
                }
                else{
                    producto.check=false
                }
            })
        }
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const listarRefuse = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportePreciosInternos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.estate = 1
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch({type: constants.DATAREFUSE ,data: response});
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};
const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch(setItem(response));
        if (!!formName) {
            if(response.fecha_fundacion){
                response.fecha_fundacion = new Date(moment(response.fecha_fundacion).format());
                response.cuenta_inicial = 0
                response.cuenta_por = "1"
            }
            if(response.es_bonificacion){
                response.es_bonificacion = "true"
            }else{response.es_bonificacion = "false"}
            dispatch(initializeForm(formName, response));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(url, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if(error && error.detail){
            mensaje = error.detail
        }
        else if(Object.keys(error).length > 0){
            let key = Object.keys(error)[0];
            mensaje = `${key}: ${error[key][0]}`
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if(error && error.detail){
            mensaje = error.detail
        }
        else if(Object.keys(error).length > 0){
            let key = Object.keys(error)[0];
            mensaje = `${key}: ${error[key][0]}`
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(listar());
        dispatch(setLoader(false));
    });
};
const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};
const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().reportePreciosInternos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};
const searchChangeRefuse = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarRefuse(1, 1));
};
const onSortChangeRefuse = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarRefuse(sort.page,1));
};
const selectRoles = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('role/selectroles').then((res)=>{
        dispatch(setDataSelect(constants.SET_ROLES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setDataSelect(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectTipos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipoproducto/selecttipos').then((res)=>{
        dispatch(setDataSelect(constants.SET_TIPOS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectRegiones = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('region/selectregiones').then((res)=>{
        dispatch(setDataSelect(constants.SET_REGIONES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectEmpresas2 = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch(setDataSelect(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectCuentas = (id, moneda) => (dispatch) =>{
    dispatch(setLoader(true));
    let data = { moneda}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch({type: constants.SET_CUENTAS, data: res})
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const getTipoGastos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipo_gasto').then((res)=>{
        dispatch({
            type: constants.TIPO_GASTOS,
            tipo_gastos: res
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const resetFormulario = () => (dispatch) => {
    dispatch(initializeForm(formName, {
    }));
}
const changeEmpresa = empresa => dispatch => {
    dispatch({type:constants.SET_SEARCH_EMPRESA, empresa});
    dispatch(listar());
}
const changeTipo = tipo => dispatch => {
    dispatch({type:constants.SET_SEARCH_TIPO, tipo});
    dispatch(listar());
}
const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
    dispatch({type:constants.SET_SEARCH_EMPRESA, empresa:null});
};
const listarespecial = (url, page = 1, data) => (dispatch, getStore) => {
    const resource = getStore().reportePreciosInternos;
    const params = { page, ...data};
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.empresa != null)
        params.empresa = resource.empresa.value;
    dispatch(setLoader(true));
    api.get(url, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setProductosSeleccionados = producto => (dispatch, getStore) => {
    const seleccionar_todos = getStore().reportePreciosInternos.seleccionar_todos
    let productos = getStore().reportePreciosInternos.productos_seleccionados
    let productos_desmarcados = getStore().reportePreciosInternos.productos_desmarcados
    let productos_listados = getStore().reportePreciosInternos.data
    let existe = false
    productos_listados.results.forEach(prod =>{
        if(producto.id == prod.id){
            prod.check = !prod.check
        }
    })
    if(productos.length == 0){
        productos.push(producto)
    }
    else{
        productos.forEach((prod, index) =>{
            if(producto.id == prod.id){
                productos.splice(index, 1)
                existe = true
            }
        })
        if(existe == false){
            productos.push(producto)
        }
    }
    if(seleccionar_todos){
        if(productos_desmarcados.length == 0){
            productos_desmarcados.push(producto)
        }
        else{
            productos_desmarcados.forEach((prod, index) =>{
                if(producto.id == prod.id){
                    productos_desmarcados.splice(index, 1)
                    existe = true
                }
            })
            if(existe == false){
                productos_desmarcados.push(producto)
            }
        }
    }
    dispatch(setData(productos_listados));
    dispatch({type: constants.PRODUCTOS_SELECCIONADOS, data: productos})
}

const setOpenModal = value => (dispatch) =>{
    dispatch({type: constants.SET_OPEN_MODAL, open_modal: value})
    if (!!form){
        let tipo_monetario = 'true'
        let es_aumento = 'true'
        let values = {}
        values.tipo_monetario = tipo_monetario
        values.es_aumento = es_aumento
        dispatch(initializeForm(form, values));
    }
} 
const setProductoSeleccionado = producto => (dispatch) => {
    dispatch({type: constants.PRODUCTO_SELECCIONADO, producto_seleccionado: producto})
}

const guardarCambio = (es_individual=true) => (dispatch, getStore) => {
    const form = getStore().form.PreciosInternosForm.values
    const producto = getStore().reportePreciosInternos.producto_seleccionado
    const productos = getStore().reportePreciosInternos.productos_seleccionados
    let data = {}
    if(es_individual){
        data.producto = producto
    }
    else{
        data.productos = productos
    }
    const tipo_monetario = form.tipo_monetario
    const es_aumento = form.es_aumento
    data.tipo_monetario = tipo_monetario
    data.es_aumento = es_aumento
    data.es_individual = es_individual
    if(tipo_monetario== 'true'){
        let monto = form.monto
        data.monto = monto
    }
    else{
        let porcentaje = form.porcentaje
        data.porcentaje = porcentaje
    }
    if(es_individual){
        dispatch(setLoader(true));
        api.post(`productos/cambiar_precio_interno`, data).then((res)=>{
            NotificationManager.success('Producto actualizado', 'Éxito', 3000);
            dispatch({type: constants.SET_OPEN_MODAL, open_modal: false})
            dispatch(listar())
            let tipo_monetario = 'true'
            let es_aumento = 'true'
            let values = {}
            values.tipo_monetario = tipo_monetario
            values.es_aumento = es_aumento
            values.monto = 0
            values.porcentaje = 0
            dispatch(initializeForm('PreciosInternosForm', values));
        }).catch((res)=>{
            NotificationManager.error(res.detail, 'ERROR');
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }
    else{
        Swal.fire({
            title: '¿Desea actualizar todos los productos listados?',
            text: '¡No podrá revertir esta acción!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Aceptar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if(result.value){
                const resource = getStore().reportePreciosInternos
                data.search = resource.search;
                if (resource.empresa != null)
                    data.empresa = resource.empresa.value;
                if (resource.tipo != null)
                    data.tipo_producto = resource.tipo.value;
                dispatch(setLoader(true));
                api.post(`productos/cambiar_precio_interno`, data).then((res)=>{
                    NotificationManager.success('Producto actualizado', 'Éxito', 3000);
                    dispatch({type: constants.SET_OPEN_MODAL, open_modal: false})
                    let tipo_monetario = 'true'
                    let es_aumento = 'true'
                    let values = {}
                    values.tipo_monetario = tipo_monetario
                    values.es_aumento = es_aumento
                    values.monto = 0
                    values.porcentaje = 0
                    dispatch(initializeForm('PreciosInternosForm', values));
                    dispatch(listar())
                }).catch((res)=>{
                    NotificationManager.error(res.detail, 'ERROR');
                }).finally(()=>{
                    dispatch(setLoader(false))
                })
            }
            else{
                dispatch(setLoader(false))
            }
        });
    }
    
}
const setSeleccionarTodos = () => (dispatch, getStore) => {
    let productos_listados = getStore().reportePreciosInternos.data
    let value = getStore().reportePreciosInternos.seleccionar_todos
    value = !value
    dispatch({type: constants.SET_SELECCIONAR_TODOS, seleccionar_todos: value})
    productos_listados.results.forEach(prod =>{
        prod.check = value
    })
    dispatch({type: constants.SET_PRODUCTOS_DESMARCADOS, data: []})
    dispatch(setData(productos_listados));
}

const setDatosModal = (row) => (dispatch, getStore) => {
    dispatch({type: constants.SET_FECHA_ACTUALIZACION, fecha_actualizacion: row.fecha_actualizacion})
    dispatch({type: constants.SET_PRECIO_INTERNO_ANTERIOR, precio_interno_anterior: row.precio_interno_anterior})
}
export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    selectRoles,
    selectEmpresas,
    selectTipos,
    selectRegiones,
    selectEmpresas2,
    selectCuentas,
    listarRefuse,
    searchChangeRefuse,
    onSortChangeRefuse,
    getTipoGastos,
    resetFormulario,
    changeEmpresa,
    changeTipo,
    clear,
    listarespecial,
    setProductosSeleccionados,
    setProductoSeleccionado,
    setOpenModal,
    guardarCambio,
    setSeleccionarTodos,
    setDatosModal
};
// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_ROLES]:(state,{ data })=>{
        return{
            ...state,
            roles: data.role,
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_TIPOS]:(state,{ data })=>{
        return{
            ...state,
            tipos: data.tipo,
        }
    },
    [constants.SET_CUENTAS]:(state,{ data })=>{
        return{
            ...state,
            cuentas_banco: data.cuenta,
        }
    },
    [constants.SET_REGIONES]:(state,{ data })=>{
        return{
            ...state,
            regiones: data,
        }
    },
    [constants.DATAREFUSE]:(state,{data}) => {
        return{
            ...state,
            datarefuse:data
        }
    },
    [constants.TIPO_GASTOS]: (state, {tipo_gastos}) => {
        return {
            ...state,
            tipo_gastos
        }
    },
    [constants.SET_SEARCH_EMPRESA]: (state, {empresa}) => {
        return {
            ...state,
            empresa
        }
    },
    [constants.SET_SEARCH_TIPO]: (state, {tipo}) => {
        return {
            ...state,
            tipo
        }
    },
    [constants.PRODUCTOS_SELECCIONADOS]: (state, {data}) => {
        return {
            ...state,
            productos_seleccionados: data
        }
    },
    [constants.SET_PRODUCTOS_DESMARCADOS]: (state, {data}) => {
        return {
            ...state,
            productos_desmarcados: data
        }
    },
    [constants.PRODUCTO_SELECCIONADO]: (state, {producto_seleccionado}) => {
        return {
            ...state,
            producto_seleccionado
        }
    },
    [constants.SET_OPEN_MODAL]: (state, {open_modal}) => {
        return {
            ...state,
            open_modal
        }
    },
    [constants.SET_SELECCIONAR_TODOS]: (state, {seleccionar_todos}) => {
        return {
            ...state,
            seleccionar_todos
        }
    },
    [constants.SET_FECHA_ACTUALIZACION]: (state, {fecha_actualizacion}) => {
        return {
            ...state,
            fecha_actualizacion
        }
    },
    [constants.SET_PRECIO_INTERNO_ANTERIOR]: (state, {precio_interno_anterior}) => {
        return {
            ...state,
            precio_interno_anterior
        }
    },
};
const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    roles: [],
    empresas:[],
    tipos: [],
    regiones:[],
    cuentas_banco: [],
    datarefuse: {
        results: [],
        count: 0,
    },
    tipo_gastos: [],
    empresa: null,
    tipo: null,
    productos_seleccionados: [],
    producto_seleccionado: null,
    open_modal: false,
    seleccionar_todos: true,
    productos_desmarcados: [],
    fecha_actualizacion: null,
    precio_interno_anterior: null
};
export default handleActions(reducers, initialState);


