import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import {standardActions} from '../../../Utils/Grid/StandardActions';
import moment from 'moment';

export default class GridOrdenes extends Component {

    componentDidMount() {
        this.props.listarCompras();
    }

    render() {
        const { page, data, loader, listarCompras} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={listarCompras}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataSort
                        className='reporte-table'
                        dataFormat={(cell) => ""}
                        width="10%"
                    />
                    <TableHeaderColumn
                        dataField="vehiculo_asignado"
                        dataSort
                        className='reporte-table'
                        width="28%"
                        dataFormat={(cell, row) => (
                            <div>
                                <Fragment>
                                    <span className="font-weight-bold">Tipo: </span>
                                    <span style={{ color: "black" }} >{cell.tipo == 1 ? 'Vehículo' : 'Maquinaria'}</span>
                                    <br />
                                </Fragment>
                                <Fragment>
                                    <span className="font-weight-bold">Placa: </span>
                                    <span style={{ color: "black" }} >{cell.placa}</span>
                                    <br />
                                </Fragment>
                                <Fragment>
                                    <span className="font-weight-bold">Modelo: </span>
                                    <span style={{ color: "black" }} >{cell.modelo}</span>
                                    <br />
                                </Fragment>
                                <Fragment>
                                    <span className="font-weight-bold">Marca: </span>
                                    <span style={{ color: "black" }} >{cell.marca}</span>
                                    <br />
                                </Fragment>
                            </div>
                        )}
                    >
                        MAQUINARIA/VEHÍCULO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_orden"
                        dataSort
                        className='reporte-table'
                        width="28%"
                        dataFormat={(cell, row) => (
                            <div>
                                <Fragment>
                                    <span className="font-weight-bold">Proveedor: </span>
                                    <span style={{ color: "black" }} >{row.proveedor}</span>
                                    <br />
                                </Fragment>
                                <Fragment>
                                    <span className="font-weight-bold">No. Orden de Compra: </span>
                                    <span style={{ color: "black" }} >{cell}</span>
                                    <br />
                                </Fragment>
                                <Fragment>
                                    <span className="font-weight-bold">Descripción: </span>
                                    <span style={{ color: "black" }} >{row.descripcion ? row.descripcion : "Sin información"}</span>
                                    <br />
                                </Fragment>
                            </div>
                        )}
                    >
                        ORDEN DE COMPRA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        className='reporte-table'
                        width="14%"
                        dataFormat={(cell) => moment(cell).format('DD-MM-YYYY')}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="saldo"
                        dataSort
                        className='reporte-table'
                        width="20%"
                        dataAlign="right"
                        thStyle={{paddingRight: "5%"}}
                        tdStyle={{paddingRight: "5%"}}
                        dataFormat={(cell) => <RenderMoneda monto={cell}/>}
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }

}
