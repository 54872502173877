import React, { Component } from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderNumber } from "../../../Utils/renderField/renderReadField";
import Grid from '../../../Utils/Grid/index';
import moment from 'moment';

const HistorialProduccion = (props) => {
    const { data, loader } = props;
    return (
        <div className="margin-tabgrid px-3">
            <Grid
                hover
                striped
                data={{ count: 10, results: data }}
                loading={loader}
                pagination={false}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    isKey
                    width="12%"
                    dataFormat={(cell, row) => moment(row.creado).format("DD/MM/YYYY")}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="empleado"
                    dataSort
                    // width="15%"
                >
                    EMPLEADO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="producto"
                    dataSort
                >
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign="right"
                    dataFormat={cell => <RenderNumber value={cell} decimalScale={4} />}
                >
                    CANTIDAD
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tipo_horario"
                >
                    TIPO DE HORARIO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="horas_trabajadas"
                    dataAlign="right"
                    width="20%"
                >
                    HORAS TRABAJADAS
                </TableHeaderColumn>
            </Grid>
        </div>
    )
}

export default HistorialProduccion;
