import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';

export default class ToolbarMovimientos extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    }
    componentWillMount(){
    }
    format = (array=[]) =>{
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }
    render() {
        const {
            changeUsuario,
            usuarios,
            changeTipo,
            tipos,
            id_bodega
        } = this.props;
        return (
            <div className="row col-12 p-0 m-0">
                <div className={"col-lg-3 col-md-3 col-12 align-self-center"}>
                        <SingleDatePicker
                        className="form-control"
                        placeholder={"Fecha Inicio"}
                        date={this.props.dateStart ? moment(this.props.dateStart ) : null}
                        focused={this.state.focusedStart}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                        this.setState({dateStart:value})
                        this.props.setDateStart(value, id_bodega)
                        }}
                        onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                        numberOfMonths={1}
                        id={"dateStart"}
			            readOnly = { true }
                    />
                    </div>
                    <div className={"col-lg-3 col-md-3 col-12 align-self-center"}>
                        <SingleDatePicker
                        className="form-control"
                        placeholder={"Fecha Final"}
                        date={this.props.dateEnd ? moment(this.props.dateEnd ) : null}
                        focused={this.state.focusedEnd}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                           this.setState({dateEnd:value})
                           this.props.setDateEnd(value, id_bodega);
                        }}
                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                        numberOfMonths={1}
                        id={"dateStart"}
			            readOnly = { true }
                    />
                    </div>
                <div className="col-3 p-2 m-0 ">
                    <Select
                        isClearable={true}
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={true}
                        isSearchable={true}
                        options={this.format(usuarios? usuarios:[])}
                        placeholder={"Usuario"}
                        // value={null}
                        onChange={(e, action)=>{console.log(e, action); changeUsuario(e, id_bodega)}}
                    />
                </div>
                <div className="col-3 p-2 m-0 ">
                    <Select
                        isClearable={true}
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={true}
                        isSearchable={true}
                        options={this.format(tipos? tipos:[])}
                        placeholder={"Tipo producto"}
                        // value={null}
                        onChange={(e, action)=>{console.log(e, action); changeTipo(e, id_bodega)}}
                    />
                </div>
            </div>
        )
    }
}