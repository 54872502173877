import React, { Component } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';

export default class AsignacionesGrid extends Component {

    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {

        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;

        return (
            <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                {permisos.asig_actividades ? (
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        isKey
                        dataFormat={
                            standardActions({ eliminar })
                        }
                    />
                ): (
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width="2%"
                        dataSort
                        isKey
                        dataFormat={cell => ""}
                    />
                )}
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    dataFormat = {(cell, row)=> moment(cell).format("L") }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="actividad"
                    dataSort
                    dataFormat = {(cell, row)=> cell.nombre}
                >
                    ACTIVIDAD
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="empleado"
                    dataSort
                    dataFormat = {(cell, row)=> `${cell.nombre} ${cell.apellido}`}
                >
                    EMPLEADO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="empresa_actividad"
                    dataSort
                    dataFormat = {(cell, row)=> cell.nombre}
                >
                    EMPRESA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="horas_trabajadas"
                    dataSort
                    dataAlign="right"
                    tdStyle={{paddingRight: "2%"}}
                    thStyle={{paddingRight: "2%"}}
                    dataFormat = {(cell, row)=> <RenderNumber value={cell} decimalScale={2} />}
                >
                    Horas Trabajadas
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tipo_horario"
                    dataSort
                    dataFormat = {(cell, row)=> (
                        cell == 1 ? "Horario normal" : "Horario extra"
                    )}
                >
                    Tipo de horario
                </TableHeaderColumn>
            </Grid>
        );
    }
}
