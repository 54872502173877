import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, AsyncSelectField, SelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {
    renderTextArea,
    renderFieldCheck,
    renderSelectField2
} from "../../../Utils/renderField/renderField";
import { renderDropdownDateField, renderDataPickerDateField, renderDataPickerDateFreeField } from "../../../Utils/renderField/otherrenders";

import { getSimbolo } from "../../../Utils/renderField/renderTableField";
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { RenderNumber } from "../../../Utils/renderField/renderReadField";
import { api } from 'api';
import { departamentos, municipios, DIAS_FACTURACION } from '../../../../../utility/constants';
import { forEach } from 'lodash';
import moment from 'moment'

class ItemArray extends Component {
    state = {
        producto: {}
    }
    setPrecioUnitario = (producto) => {
        const { todos, index } = this.props;
        if (producto !== undefined) {
            todos[index]['facturar'] = producto.cantidad - producto.cantidad_facturada;
            // if (producto.existencias < producto.cantidad_actual){
            // }else{
            //     todos[index]['facturar'] = producto.cantidad - producto.cantidad_facturada;
            // }
        }
    }


    componentDidMount() {
        if (this.props.producto_seleccionado) {
            this.setState({ producto: this.props.producto_seleccionado.producto });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.producto_seleccionado !== prevProps.producto_seleccionado) {
            const { todos, index } = this.props;
            if (this.props.producto_seleccionado) {
                this.setState({ producto: this.props.producto_seleccionado.producto });
            }
        }
    }


    render() {
        const { getProductosOrden, eliminar, index, Despacho, subtotal, producto_seleccionado } = this.props
        return (
            <tr key={index} style={{ height: "68px" }}>
                {/*
                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() => {
                            eliminar()
                        }}
                    />
                </td>
                */}
                <td className="sin-borde-top">
                    <Field
                        label="nombre"
                        name={`${Despacho}.producto`}
                        loadOptions={getProductosOrden}
                        type="text"
                        component={AsyncSelectField}
                        top={{ top: "67px", position: "inherit" }}
                        valueKey="producto"
                        labelKey="producto_nombre"
                        onChange={(e) => {
                            this.setState({ producto: e });
                            this.setPrecioUnitario(e)
                        }}
                        className="form-control"
                    />

                </td>
                <td className="sin-borde-top" >
                    <label><RenderNumber value={this.state.producto ? this.state.producto.cantidad : 0} /></label>
                </td>
                {/* <td className="sin-borde-top">
                    <label><RenderNumber value={this.state.producto ? this.state.producto.cant_despacho : 0} /></label>
                </td>
                <td className="sin-borde-top">
                    <label><RenderNumber value={this.state.producto ? this.state.producto.cantidad_facturada : 0} /></label>
                </td> */}
                <td className="sin-borde-top" >
                    <label><RenderNumber value={this.state.producto ? this.state.producto.cantidad : 0} /></label>
                </td>
                <td className="sin-borde-top">
                    <label><RenderMoneda monto={this.state.producto ? this.state.producto.precio_unitario  : 0} /></label>
                </td>
                <td className="sin-borde-top">
                    <label><RenderMoneda monto={subtotal   } /></label>
                </td>
                <td className="sin-borde-top">
                    <label><RenderMoneda monto={this.state.producto ? this.state.producto.descuento: 0} /></label>
                </td>
            </tr>
        )
    }
}

const renderProductos = ({ fields, meta: { error, submitFailed }, getProductosOrden, subtotales }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th style={{ width: "10%" }}>Solicitada</th>
                            {/* <th style={{ width: "10%" }}>Despachada</th>
                            <th style={{ width: "10%" }}>Facturada</th> */}
                            <th style={{ width: "12%" }}>por facturar</th>
                            <th style={{ width: "12%" }}>precio</th>
                            <th style={{ width: "12%" }}>Subtotal</th>
                            <th style={{ width: "12%" }}>Descuento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((Despacho, index) => {
                            let todos = fields.getAll()
                            const producto = todos[index];
                            return (
                                <ItemArray
                                    index={index}
                                    producto_seleccionado={producto}
                                    key={index}
                                    Despacho={Despacho}
                                    todos={todos}
                                    getProductosOrden={getProductosOrden}
                                    subtotal={subtotales[index]}
                                    eliminar={() => {
                                        fields.remove(index)
                                    }}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/*
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div>
            */}
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>}
    </div>
)


class AgregarFForm extends React.Component {
    state = {
        es_cf: false,
        facturaCheckSize: 6,
        bloquerNombre: true,
        disabledBandera: true,
        fechas_facturacion: {
            minima: moment().subtract(DIAS_FACTURACION, 'days').format('YYYY-MM-DD'),
            maxima: moment().add(DIAS_FACTURACION, 'days').format('YYYY-MM-DD')
        }
    }
    componentDidMount(){
        if(this.props.nit == "CF" | this.props.nit == "C/F"){
            this.setState({
                es_cf: true,
                disabledBandera: true,
                bloquerNombre: false
            });
        }
    }
    getProductosOrden = (search) => {
        let params = {}
        if (search) {
            params.search = search;
        }
        params.orden = this.props.orden;
        params.bandera = 1;
        return api.get("ordenventa/productosporventa", params).
            then((data) => {
                if (data) return data;
                return [];
            }).catch(() => {
                return [];
            });
    };

    changeOptionCheckFacturaExterna = (e, esFacturaExterna) => {
        if (esFacturaExterna) {
            this.setState({
                disabledBandera: true,
                bloquerNombre: true,
                facturaCheckSize: 6,
                fechas_facturacion: {
                    minima: moment().subtract(DIAS_FACTURACION,'days').format('YYYY-MM-DD'),
                    maxima: moment().add(DIAS_FACTURACION, 'days').format('YYYY-MM-DD')
                }
            })
        }
        else {
            this.setState({
                disabledBandera: false,
                bloquerNombre: false,
                facturaCheckSize: 2,
                fechas_facturacion: {
                    minima: undefined,
                    maxima: undefined
                }
            })
        }
    }

    consultarIdentificacion = () => {
        let tipo = this.props.obtenerTipoIdentificacion();
        switch (tipo) {
            case "NIT":
                this.props.validarNitEP();
                break;
            case "CUI":
                this.props.validarCuiEP();
                break;
            case "EXT":
                break;
            default:
                break;
        }
    }


    render() {
        let { handleSubmit, item, total, subtotales, municipios, mostrarVista, losdatos, permisos,
            condicionPago, esFacturaExterna } = this.props
        let simbolo = item.moneda;
        let nombre = ""
        let existe = 0
        let tipo = ""
        let optionsDocumentos = [
            { value: "NIT", label: "NIT", id: "NIT" },
            { value: "CUI", label: "CUI", id: "CUI" }
        ];
        let condicionesPagoOptions = [
            { value: "1", label: "Al Contado", id: 1 },
            { value: "2", label: "Al Crédito", id: 2 }
        ];

        losdatos.despacho.orden_detalle.proveedor.empresa.map(empresa =>{

            let nit_orden_detalle = losdatos.despacho.orden_detalle.nit_facturacion.replace(/-/g, "").replace(" ", "")
            let nit_facturacion = empresa.Nit.replace(/-/g, "").replace(" ", "")

            if(nit_orden_detalle == nit_facturacion){
                tipo = empresa.tipo
                nombre = empresa.nombre
                existe = 1
            }
            if(existe==0){
                if(empresa.Nit=="CF" |  empresa.Nit=="C/F"){
                    nombre= empresa.nombre
                }
            }
        })

        total = total
        return (
            <form name="FacturaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                {(losdatos.despacho.orden_detalle.detalle_orden && !!losdatos.despacho.orden_detalle.detalle_orden.length) && (
                    <div className="row m-none">
                        <span className="m-none t-musgo semibold">{losdatos.despacho.cliente_orden.nombre}</span>
                        <div className=" col-12 justify-content-center">
                            <span className="m-none t-mostaza semibold">Facturas Solicitadas</span><br />
                            <div key={losdatos}>
                                <div className="row">
                                    <div className="col-sm-2 col-12">
                                        <span className="m-none t-mostaza semibold"></span><br />
                                        <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                                            target="_blank"
                                            onClick={() => { this.props.setAutomaticoReceptor({ ...losdatos, datoNit: losdatos.despacho.orden_detalle.nit_facturacion }), losdatos.despacho.orden_detalle.nit_facturacion=="CF" |  losdatos.despacho.orden_detalle.nit_facturacion=="C/F" ? this.setState({es_cf: true}) : this.setState({es_cf: false})}}
                                        >
                                            <img
                                                className="action-img"
                                                title="aplicar"
                                                src={require("../../../../../../assets/img/icons/Ver.png")} alt="applicar" />
                                        </a>
                                    </div>
                                    <div className="col-sm-3 col-12">
                                        <span className="m-none t-mostaza semibold"> Identificación: {tipo ? tipo : " "} </span><br />
                                        <span className="m-none t-musgo semibold">{`${losdatos.despacho.orden_detalle.nit_facturacion ? losdatos.despacho.orden_detalle.nit_facturacion : "CF"}`}</span>
                                    </div>
                                    <div className="col-sm-4 col-12">
                                        <span className="m-none t-mostaza semibold">Nombre: </span><br />
                                        <span className="m-none t-musgo semibold">{nombre}</span>
                                    </div>
                                    <div className="col-sm-3 col-12">
                                        <span className="m-none t-mostaza semibold">Monto: </span><br />
                                        <span className="m-none t-musgo semibold">{losdatos.despacho.monto ? <RenderMoneda monto={losdatos.despacho.monto} simbolo={getSimbolo(simbolo)} /> : "--"}</span>
                                    </div>
                                </div>
                            </div>

                            {total < 2500 && (
                                <div>
                                    <div className="row">
                                        {/* <div className="col-sm-2 col-12">
                                            <span className="m-none t-mostaza semibold"></span><br />
                                            <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                                                target="_blank"
                                                onClick={() => { this.props.setAutomaticoReceptor({ nombre: "Consumidor Final", propietario: { Nit: "CF" } }), this.setState({es_cf: true}) }}
                                            >
                                                <img
                                                    className="action-img"
                                                    title="aplicar"
                                                    src={require("../../../../../../assets/img/icons/Ver.png")} alt="applicar" />
                                            </a>
                                        </div>
                                        <div className="col-sm-3 col-12">
                                            <span className="m-none t-mostaza semibold">Identificación: </span><br />
                                            <span className="m-none t-musgo semibold">{`CF`}</span>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <span className="m-none t-mostaza semibold">Nombre: </span><br />
                                            <span className="m-none t-musgo semibold">{`Consumidor Final`}</span>
                                        </div>
                                        <div className="col-sm-3 col-12">
                                            <span className="m-none t-mostaza semibold">Monto: </span><br />
                                            <span className="m-none t-musgo semibold">{<RenderMoneda monto={0} simbolo={getSimbolo(simbolo)} />}</span>
                                        </div> */}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
                }

                <br />
                <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px", padding: "0" }} />
                <br />

                <div className=" col-12 justify-content-center">

                    <div className="row justify-content-start">
                        <div className={`form-group has-feedback col-sm-${ permisos.agregar_factura_externa ? 2 : 12 } col-12`}>
                            <label className="t-azul" htmlFor="nombre">Fecha</label>
                            {/* <Field
                                name="fecha"
                                selectedDate={moment()}
                                className="form-control"
                                component={renderDataPickerDateField}
                            /> */}
                            <Field
                                name="fecha"
                                selectedDate={moment()}
                                minDate={this.state.fechas_facturacion.minima}
                                maxDate={this.state.fechas_facturacion.maxima}
                                // minDate={moment().subtract(5, 'days').format('YYYY-MM-DD')}
                                // maxDate={moment().add(5, 'days').format('YYYY-MM-DD')}
                                className="form-control"
                                component={renderDataPickerDateFreeField}
                            />
                        </div>
                        {
                            (permisos.agregar_factura_externa) && (
                                <div className={`form-group has-feedback col-sm-${ this.state.facturaCheckSize } col-12`}>
                                    <label className="t-azul" htmlFor="esFacturaExterna">Fuente Externa</label>
                                    <Field
                                        name="esFacturaExterna"
                                        component={renderFieldCheck}
                                        onChange={(e) => {
                                            this.changeOptionCheckFacturaExterna(e, esFacturaExterna), this.forceUpdate()
                                        }}
                                    />
                                </div>
                            )
                        }
                        {
                            (esFacturaExterna) && (
                                <div className="col-8 justify-content-center">
                                    <div className="row justify-content-start">
                                        <div className="form-group has-feedback col-sm-4">
                                            <label htmlFor="no_factura">No. Factura</label>
                                            <Field
                                                name="no_factura"
                                                label="No. Factura"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group has-feedback col-sm-4">
                                            <label htmlFor="no_serie">No. Serie</label>
                                            <Field
                                                name="no_serie"
                                                label="No. Factura"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group has-feedback col-sm-4">
                                            <label htmlFor="no_autorizacion">No. Autorización</label>
                                            <Field
                                                name="no_autorizacion"
                                                label="No. Autorización"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            (esFacturaExterna) && (
                                <div className=" col-12 justify-content-center">
                                    <div className="row justify-content-start">
                                        <div className="form-group has-feedback col-sm-6 col-12">
                                            <label htmlFor="condicionPago">Condición Pago</label>
                                            <Field
                                                name="condicionPago"
                                                type="text"
                                                component={renderSelectField2}
                                                options={condicionesPagoOptions}
                                            />
                                        </div>
                                        {
                                            (condicionPago=="2") && (
                                                <div className="form-group has-feedback col-sm-6 col-12">
                                                    <label htmlFor="diasAlCredito">Días de Crédito</label>
                                                    <Field
                                                        name="diasAlCredito"
                                                        label="DiasAlCredito"
                                                        component={renderField}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }

                        <div className="form-group has-feedback col-sm-5 col-12">
                            <label htmlFor="nombre">Nombre</label>
                            <Field
                                name="nombre"
                                label="Nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                                readOnly={this.state.bloquerNombre}
                            />
                        </div>
                        <div className="form-group has-feedback col-sm-2 col-12">
                            <label htmlFor="tipo">Tipo</label>
                            <Field
                                name="tipo"
                                type="text"
                                component={renderSelectField2}
                                options={optionsDocumentos}
                                disabled={this.state.disabledBandera}
                            />
                        </div>
                        <div className="form-group has-feedback col-sm-1 col-12 sin-borde-top" style={{paddingTop:"30px", paddingLeft:"30px"}}>
                            <img
                                className="action-img"
                                src={require("assets/img/icons/buscar.png")}
                                alt="Buscar"
                                title="Buscar"
                                onClick={() => {this.consultarIdentificacion()}}
                            />
                        </div>
                        <div className="form-group has-feedback col-sm-4 col-12">
                            <label htmlFor="nit">Identificación</label>
                            <Field
                                name="nit"
                                label="Nit"
                                component={renderField}
                                type="text"
                                className="form-control"
                                readOnly={this.state.disabledBandera}
                            />
                        </div>
                        <div className={`form-group has-feedback col-12`}>
                            <label className="t-azul" htmlFor="nombre">Direccion</label>
                            <Field name="direccion" label="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="form-group has-feedback col-md-4 col-sm-6 col-12">
                            <label className="t-azul" htmlFor="nombre">Departamento</label>
                            <Field
                                name={`departamento`}
                                label="departamento"
                                component={SelectField}
                                options={departamentos}
                                className="form-control"
                                onChange={
                                    (e) => {
                                        this.props.getMunicipios(`${e[0]}${e[1]}`)
                                    }
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-md-4 col-sm-6 col-12">
                            <label className="t-azul" htmlFor="nombre">Municipio</label>
                            <Field
                                name={`municipios`}
                                label="municipios"
                                component={SelectField}
                                options={municipios}
                                className="form-control"
                                onChange={
                                    (e, a, c) => {
                                        this.props.setCodigoPostal(a)
                                    }
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-md-4 col-sm-6 col-12">
                            <label className="t-azul" htmlFor="nombre">Codigo Postal</label>
                            <Field
                                name={`codigo_postal`}
                                label="codigo_postal"
                                component={renderField}
                                type="text"
                                className="form-control"
                                readOnly={true}
                            />
                        </div>
                        <div className="form-group has-feedback  col-12">
                            {/* <label htmlFor="monto">Monto</label> */}
                            <FieldArray
                                subtotales={subtotales}
                                name='detalle_factura'
                                component={renderProductos}
                                getProductosOrden={this.getProductosOrden}
                                simbolo={simbolo}

                            />
                        </div>
                        <div className="col-12 p-0 m-0 row justify-content-end">
                            <div className="form-group has-feedback col-md-4 col-sm-6 col-12 d-flex align-items-end">
                                <label className="t-azul">TOTAL A FACTURAR:</label>
                                <h3 className="t-primary" htmlFor="region">{<RenderMoneda monto={total} />}</h3>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row  justify-content-sm-around justify-content-center">
                    {mostrarVista
                        ? <button className="btn btn-secondary m-1 align-self-center" onClick={mostrarVista}>CANCELAR</button>

                        : <Link className="btn btn-secondary m-1 align-self-center" to={`/ordenesventa/${item.orden ? item.orden.id : 0}/detalle`} >CANCELAR</Link>
                    }
                    <button type="submit" className="btn btn-primary m-1 align-self-center" >AGREGAR</button>

                </div>
            </form>
        )
    }
}



AgregarFForm = reduxForm({
    form: 'FacturaForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {};

        if(!data.nombre){
            errors.nombre = "Este campo es requerido.";
        }
        if(!data.nit){
            errors.nit = "Este campo es requerido.";
        }
        if(!data.no_factura){
            errors.no_factura = "Este campo es requerido.";
        }
        if(!data.tipo){
            errors.tipo = "Este campo es requerido.";
        }
        if(!data.monto){
            errors.monto = "Este campo es requerido.";
        }


        if(data.esFacturaExterna){
            if(!data.no_autorizacion){
                errors.no_autorizacion = "Este campo es requerido.";
            }
            if(!data.no_serie){
                errors.no_serie = "Este campo es requerido.";
            }
        }

        return errors;
    },
})(AgregarFForm);

const selector = formValueSelector('FacturaForm');
AgregarFForm = connect(state => {

    const detalle = selector(state, 'detalle_factura');
    const esFacturaExterna = selector(state, 'esFacturaExterna');
    const condicionPago = selector(state, 'condicionPago');

    let subtotales = [];
    let resultado = 0;
    let total = 0;
    let total_descuentos = 0;
    if (detalle) {
        detalle.forEach((item, index) => {
            if (item.producto) {
                if (item.facturar) {
                    resultado = item.producto.precio_unitario * item.facturar;
                } else {
                    resultado = 0;
                }
                subtotales.push(resultado);
                total += resultado;
                total_descuentos += item.producto.descuento
            }
        });
    }
    total = total - total_descuentos
    return {
        total,
        subtotales,
        condicionPago,
        esFacturaExterna
    }
})(AgregarFForm);

export default AgregarFForm;
