import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import {standardActions} from '../../../Utils/Grid/StandardActions';


export default class GridCostoInventario extends Component {

    render() {
        const { loader, data, page, listar, onPageChange, onSortChange} = this.props;
        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={listar} onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataSort
                        className='reporte-table'
                        dataFormat={
                            standardActions({  ver: 'costos_inventario' })
                        }
                        width="10%"
                    />
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                        className='reporte-table'
                        dataFormat={(cell, row) => (
                            cell ? cell : "Sin registro"
                        )}
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell, row) => (
                            cell ? <RenderMoneda monto={cell} decimalScale={4}/> : "Sin registro" 
                        )}
                    >
                        COSTO BRUTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="inventario_mes"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell) => ( cell  || cell == 0 ? cell : "Sin registro" )}
                    >
                        INVENTARIO MES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell) => ( cell  || cell == 0 ? cell : "Sin registro" )}
                    >
                        EXISTENCIAS
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell, row) => ( cell  || cell == 0 ? <RenderMoneda monto={ row.conversion ? (cell* row.inventario_mes)/row.conversion : 0 }/> : "Sin registro" )}
                    >
                        TOTAL BRUTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo_unitario"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell, row) => (
                            cell ? <RenderMoneda monto={cell}/> : "Sin registro"
                        )}
                    >
                        COSTOS INDIREC.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo_total"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell, row) => ( cell  || cell == 0 ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                    >
                        COSTO TOTAL
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }

}
