import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/ValesGasolina/valesGasolina';
import ValesCrear from './ValesCrear';

const mstp = state => {
    return {...state.valesGasolina ,permisos: state.login.me.permisos, }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ValesCrear)


