import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_PILOTOS_LOADER',
    DATA: 'REPORTE_PILOTOS_DATA',
    DATA2: 'REPORTE_PILOTOS_DATA2',
    DATA3: 'REPORTE_PILOTOS_DATA3',
    PAGE: 'REPORTE_PILOTOS_PAGE',
    PAGE2: 'REPORTE_PILOTOS_PAGE2',
    PAGE3: 'REPORTE_PILOTOS_PAGE3',
    SET_FECHA_I: 'SET_FECHA_I_ACT_PILOTOS',
    SET_FECHA_F: 'SET_FECHA_F_ACT_PILOTOS',
    SET_PILOTO: 'SET_PILOTO_ACT_PILOTOS',
    SET_VEHICULO: 'SET_VEHICULO_ACT_PILOTOS',
    SET_DESPACHO: 'SET_DESPACHO_ACT_PILOTOS',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = (type, page) => ({
    type,
    page,
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});

// ACTIONS

const getDespachosNoAsignados = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reportePilotos;
    const params = { page };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.despacho)
        params.despacho = resource.despacho.id;
    dispatch(setLoader(true));
    api.get(`${url}/despachos_no_asignados`, params).then((response) => {
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(constants.PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDespachosAsignados = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reportePilotos;
    const params = { page };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.piloto)
        params.piloto = resource.piloto.id;
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    if (resource.despacho)
        params.despacho = resource.despacho.id;
    dispatch(setLoader(true));
    api.get(`${url}/despachos_asignados`, params).then((response) => {
        dispatch(setData(constants.DATA2, response));
        dispatch(setPage(constants.PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getItinerariosActivos = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reportePilotos;
    const params = { page };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.piloto)
        params.piloto = resource.piloto.id;
    if (resource.vehiculo)
        params.vehiculo = resource.vehiculo.id;
    dispatch(setLoader(true));
    api.get(`${url}/itinerarios_activos`, params).then((response) => {
        dispatch(setData(constants.DATA3, response));
        dispatch(setPage(constants.PAGE3, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changePiloto = (value) => (dispatch) => {
    dispatch(setData(constants.SET_PILOTO, value));
    dispatch(getDespachosAsignados());
    dispatch(getItinerariosActivos());
};

const changeVehiculo = (value) => (dispatch) => {
    dispatch(setData(constants.SET_VEHICULO, value));
    dispatch(getDespachosAsignados());
    dispatch(getItinerariosActivos());
};

const changeDespacho = (value) => (dispatch) => {
    dispatch(setData(constants.SET_DESPACHO, value));
    dispatch(getDespachosNoAsignados());
    dispatch(getDespachosAsignados());
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(getDespachosNoAsignados());
    dispatch(getDespachosAsignados());
    dispatch(getItinerariosActivos());
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(getDespachosNoAsignados());
    dispatch(getDespachosAsignados());
    dispatch(getItinerariosActivos());
};

export const actions = {
    getDespachosNoAsignados,
    getDespachosAsignados,
    getItinerariosActivos,
    changePiloto,
    changeVehiculo,
    changeDespacho,
    changeFechaInicio,
    changeFechaFin,
}

// REDUCERS
export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state, { data }) => {
        return{
            ...state,
            data: data
        }
    },
    [constants.DATA2]:(state, { data }) => {
        return{
            ...state,
            data2: data
        }
    },
    [constants.DATA3]:(state, { data }) => {
        return{
            ...state,
            data3: data
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [constants.PAGE3]: (state, { page }) => {
        return {
            ...state,
            page3: page,
        };
    },
    [constants.SET_VEHICULO]: (state, { data }) => {
        return {
            ...state,
            vehiculo: data
        };
    },
    [constants.SET_PILOTO]: (state, { data }) => {
        return {
            ...state,
            piloto: data
        };
    },
    [constants.SET_DESPACHO]: (state, { data }) => {
        return {
            ...state,
            despacho: data
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    data3: {
        results: [],
        count: 0,
    },
    page: 1,
    page2: 1,
    page3: 1,
    piloto: null,
    vehiculo: null,
    despacho: null,
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
}

export default handleActions(reducers, initialState);
