import React, { Component, Fragment } from 'react';
import {RenderDateTimeAll, RenderDateTime, RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import TransaccionesGrid from './gridTransacciones';
import {Link} from 'react-router-dom';
import { updateSyncErrors } from 'redux-form';


export default class detalleLiquidacion extends Component {

    componentDidMount(){
        this.props.leer(this.props.match.params.idLiquidacion)
    }

    aceptar = () => {
        this.props.aceptarLiquidacion(
            this.props.match.params.idLiquidacion,
            this.props.match.params.id,
        )
    }
    rechazar = () => {
        this.props.rechazarLiquidacion(
            this.props.match.params.idLiquidacion,
            this.props.match.params.id,
        )
    }
    render(){
        const { item, loader, } = this.props;
        console.log(item)
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                <div className="Container bg-white shadow-card">
                    <div className={`row mt-4 mx-4`}>
                        <div className="mb-4 col-12 m-0 p-0">
                        { !!item && item.fecha && (
                            <div className="m-t p-t fnt-size">
                                <br/>
                                <div className="mt-0">
                                    <h2 className="text-left text-primary">LIQUIDACION No. {item.id}</h2>
                                </div>
                                <br/> <br/>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <span className="m-none t-mostaza semibold">Fecha:</span>
                                        <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.fecha}></RenderDateTime></span><br/>
                                        <span className="m-none t-mostaza semibold">Vendedor: </span>
                                        <span className="m-none t-musgo semibold">{`${item.vendedor.nombre}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">Monto: </span>
                                        <span className="m-none t-musgo semibold"><RenderMoneda monto={item.monto} simbolo={'Q. '} /></span>
                                    </div>
                                </div>
                                <br/> <br/>
                                {item && item.transacciones && (
                                    <TransaccionesGrid
                                        data = {item.transacciones}
                                        loader={loader}
                                    />
                                    )
                                }
                                <br/> <br/> <br/>
                                <div className="col-12">
                                    <div className="d-flex justify-content-around">
                                        <Link to={`/caja/${this.props.match.params.id}`} className="btn btn-secondary">CANCELAR</Link>
                                        {
                                            item.estado != 10 && (
                                            <button className="btn btn-mostaza" type="button" onClick={this.rechazar}> RECHAZAR </button>
                                        )}
                                        {this.props.user.id != item.vendedor.id && item.estado != 10 &&(
                                            <button className="btn btn-primary" type="button" onClick={this.aceptar}> ACEPTAR </button>
                                        )}
                                    </div>
                                </div>
                                <br/>
                            </div>
                        )
                        }
                        </div>
                    </div>
                </div>
            </LoadMask>
        )
    }
}
