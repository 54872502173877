import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_PRODUCCION_LOADER',
    TOTAL: 'REPORTE_PRODUCCION_TOTAL',
    DATA: 'REPORTE_PRODUCCION_DATA',
    DATA2: 'REPORTE_PRODUCCION_DATA2',
    PAGE: 'REPORTE_PRODUCCION_PAGE',
    PAGE2: 'REPORTE_PRODUCCION_PAGE2',
    SET_FECHA_I: 'SET_FECHA_I_PRODUCCION',
    SET_FECHA_F: 'SET_FECHA_F_PRODUCCION',
    SET_EMPRESA: 'SET_EMPRESA_PRODUCCION',
    SET_PRODUCTO: 'SET_PRODUCTO_PRODUCCION',
    SET_TIPO_PRODUCTO: 'SET_TIPO_PRODUCTO_PRODUCCION',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = (type, page) => ({
    type,
    page,
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});

// ACTIONS

const getTotal = () => (dispatch, getStore) => {
    const resource = getStore().reporteProduccion;
    const params = { };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.empresa)
        params.empresa = resource.empresa.entidad;
    if (resource.producto)
        params.producto = resource.producto.id;
    if (resource.tipo_producto)
        params.tipo_producto = resource.tipo_producto.value;
    dispatch(setLoader(true));
    api.get(`${url}/totales_produccion`, params).then((response) => {
        dispatch(setData(constants.TOTAL, response.total_unidades));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const porTiposProductos = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteProduccion;
    const params = { page };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.empresa)
        params.empresa = resource.empresa.entidad;
    if (resource.producto)
        params.producto = resource.producto.id;
    if (resource.tipo_producto)
        params.tipo_producto = resource.tipo_producto.value;
    dispatch(setLoader(true));
    api.get(`${url}/produccion_tipos`, params).then((response) => {
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(constants.PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const porProductos = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteProduccion;
    const params = { page };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.empresa)
        params.empresa = resource.empresa.entidad;
    if (resource.producto)
        params.producto = resource.producto.id;
    if (resource.tipo_producto)
        params.tipo_producto = resource.tipo_producto.value;
    dispatch(setLoader(true));
    api.get(`${url}/produccion_productos`, params).then((response) => {
        dispatch(setData(constants.DATA2, response));
        dispatch(setPage(constants.PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const tipoProductosConsumidos = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteProduccion;
    const params = { page, tipo_reporte: 1 };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.producto)
        params.producto = resource.producto.id;
    if (resource.tipo_producto)
        params.tipo_producto = resource.tipo_producto.value;
    dispatch(setLoader(true));
    api.get(`${url}/productos_consumidos`, params).then((response) => {
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(constants.PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const productosConsumidos = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteProduccion;
    const params = { page, tipo_reporte: 2 };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.producto)
        params.producto = resource.producto.id;
    if (resource.tipo_producto)
        params.tipo_producto = resource.tipo_producto.value;
    dispatch(setLoader(true));
    api.get(`${url}/productos_consumidos`, params).then((response) => {
        dispatch(setData(constants.DATA2, response));
        dispatch(setPage(constants.PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeEmpresa = (value, tipo=1) => (dispatch) => {
    console.log("empresa ", value);
    dispatch(setData(constants.SET_EMPRESA, value));
    if (tipo==1) {
        dispatch(getTotal());
        dispatch(porProductos());
        dispatch(porTiposProductos());
    } else {
        dispatch(tipoProductosConsumidos());
        dispatch(productosConsumidos());
    }
};

const changeProducto = (value, tipo=1) => (dispatch) => {
    console.log("producto ", value);
    dispatch(setData(constants.SET_PRODUCTO, value));
    if (tipo==1) {
        dispatch(getTotal());
        dispatch(porProductos());
        dispatch(porTiposProductos());
    } else {
        dispatch(tipoProductosConsumidos());
        dispatch(productosConsumidos());
    }
};

const changeTipoProducto = (value, tipo=1) => (dispatch) => {
    console.log("tipo producto ", value);
    dispatch(setData(constants.SET_TIPO_PRODUCTO, value));
    if (tipo==1) {
        dispatch(getTotal());
        dispatch(porProductos());
        dispatch(porTiposProductos());
    } else {
        dispatch(tipoProductosConsumidos());
        dispatch(productosConsumidos());
    }
};

const changeFechaInicio = (value, tipo=1) => (dispatch) => {
    dispatch(setFechaI(value));
    if (tipo==1) {
        dispatch(getTotal());
        dispatch(porProductos());
        dispatch(porTiposProductos());
    } else {
        dispatch(tipoProductosConsumidos());
        dispatch(productosConsumidos());
    }
};

const changeFechaFin = (value, tipo=1) => (dispatch) => {
    dispatch(setFechaF(value));
    if (tipo==1) {
        dispatch(getTotal());
        dispatch(porProductos());
        dispatch(porTiposProductos());
    } else {
        dispatch(tipoProductosConsumidos());
        dispatch(productosConsumidos());
    }
};

const resetData = () => (dispatch) => {
    dispatch(setData(constants.DATA, {
        results: [],
        count: 0,
    }));
    dispatch(setPage(constants.PAGE, 1));
    dispatch(setData(constants.DATA2, {
        results: [],
        count: 0,
    }));
    dispatch(setPage(constants.PAGE2, 1));
    // Filtros
    dispatch(setFechaI(moment(`${moment().year()}-1-1`)));
    dispatch(setFechaF(moment()));
    dispatch(setData(constants.SET_TIPO_PRODUCTO, null));
    dispatch(setData(constants.SET_PRODUCTO, null));
    dispatch(setData(constants.SET_EMPRESA, null));
}

export const actions = {
    getTotal,
    porTiposProductos,
    porProductos,
    tipoProductosConsumidos,
    productosConsumidos,
    changeEmpresa,
    changeProducto,
    changeTipoProducto,
    changeFechaInicio,
    changeFechaFin,
    resetData,
}

// REDUCERS
export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.TOTAL]:(state, { data }) => {
        return{
            ...state,
            total_unidades: data
        }
    },
    [constants.DATA]:(state, { data }) => {
        return{
            ...state,
            data: data
        }
    },
    [constants.DATA2]:(state, { data }) => {
        return{
            ...state,
            data2: data
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2 : page,
        };
    },
    [constants.SET_EMPRESA]: (state, { data }) => {
        return {
            ...state,
            empresa: data
        };
    },
    [constants.SET_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            producto: data
        };
    },
    [constants.SET_TIPO_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            tipo_producto: data
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
}

export const initialState = {
    loader:false,
    total_unidades: 0,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    page: 1,
    page2: 1,
    empresa: null,
    producto: null,
    tipo_producto: null,
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
}

export default handleActions(reducers, initialState);
