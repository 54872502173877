import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import ToolbarReporteProductos from '../../../Utils/Toolbar/ToolbarReporteProductos';
import GridMasVendidos from './GridMasVendidos';
import { Redirect } from 'react-router';



export default class ReporteProductos extends Component {

    componentDidMount(){
        this.props.listar();
    }

    render() {
        const { loader, data, permisos } = this.props;
        if(permisos.producto_vendido === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE DE PRODUCTO MÁS VENDIDO">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReporteProductos {...this.props} producto_vendido={true} />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-12 d-flex justify-content-center" >
                            <div className='mr-5' >
                                <br/>
                                <h3 className="t-musgo">TOTAL DE PRODUCTO</h3>
                                <h3 className="t-primary m-0 text-center">
                                    <RenderMoneda monto = {data != null && data.results.length > 0 ? `${data.results[0].granTotal} ` : 0}/>
                                </h3>
                                <br/>
                            </div>
                            <div >
                                <br/>
                                <h3 className="t-musgo">SUBTOTAL DE PRODUCTO</h3>
                                <h3 className="t-primary m-0 text-center">
                                    <RenderMoneda monto = {data != null && data.totalProducto ? `${data.totalProducto} ` : 0}/>
                                </h3>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <br/>

                    <GridMasVendidos
                        { ...this.props }
                    />

                </LoadMask>

            </CardSimple>
        )

    }
}
