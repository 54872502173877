import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';

const TipoGastoForm = (props) => {
    const { handleSubmit} = props;
    return (
        <form name="TipoGastoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-6">
                    <label className="t-azul" htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <button type="button" onClick={props.cerrarModal} className="btn btn-secondary  align-self-center" to="/admin_tipocategoria" >CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'TipoGastoForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
        });
    },
}) (TipoGastoForm);
