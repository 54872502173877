import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda, RenderNombreSelect} from '../../../Utils/renderField/renderTableField';
import FiltrosOrdenVenta from './filtrosOrdeVenta';


export default class ProductoGrid extends Component {
    componentWillMount() {
        const { listar, page, selectEmpresas2 } = this.props;
        selectEmpresas2()
        listar(page);
    }
    componentWillUnmount(){
        const { clear } = this.props;
        clear()
    }
    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos, changeEmpresa } = this.props;
        if(permisos["productos"] === false) {
            if(!!permisos[`ver_productos`] === false) {
                return <Redirect to={`/`} />
            }
        } 
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton={permisos.crear_productos ? "Agregar" : ""}
                    ruta="producto/crear"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={permisos.productos}
                    estatefilterBar={true}
                    filterbar={
                        <FiltrosOrdenVenta
                            changeEmpresa={changeEmpresa}
                            empresas={this.props.empresas}
                        />

                    }
                />}
                titulo="Productos">
                    <Grid hover striped data={data} size_page={25} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {permisos.productos &&(<TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell) => {
                                return customStandardActions(cell, {
                                    eliminar: permisos.eliminar_productos ? eliminar: undefined,
                                    editar: permisos.editar_productos ? "producto": undefined,
                                });
                            }}
                        >
                        </TableHeaderColumn>)}
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="label_unidad_inventario"
                            dataSort
                        >
                            UNIDAD DE MEDIDA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="codigo"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                        >
                            DESCRIPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_producto"
                            dataSort
                            dataFormat = {
                                (cell, row) => (
                                    <RenderNombreSelect cell={cell} />
                                )
                            }
                        >
                            TIPO PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_venta"
                            dataSort
                            dataFormat = {
                                (cell, row)=>(
                                    <RenderMoneda monto={ cell } simbolo={'Q'} />
                                )
                            }
                        >
                            PRECIO VENTA
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
