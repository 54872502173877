import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/Reporte/reporteProduccion';
import ReporteProduccion from './ReporteProduccion';

const mstp = state => {
    return {
        ...state.reporteProduccion,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteProduccion)
