import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from "react-notifications";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";

import PuestoForm from './puestoForm';

class Puesto extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR PUESTO',
    }
    componentWillMount(){
        this.props.getTipoGastos();
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR PUESTO',
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        if (!values.bonificaciones || !values.bonificaciones.length) {
            NotificationManager.error("El puesto debe de tener bonificaciones", 'ERROR');
        } else {
            this.state.editar? editar(id,values) : crear(values);
        }
    }

    render() {
        const { loader } = this.props;
        return (
            <CardFormulario
                titulo={`${this.state.titulo}`}
            >
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br/>
                    <PuestoForm
                        onSubmit={this.onSubmit}
                        tipo_gastos={this.props.tipo_gastos}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default Puesto;
