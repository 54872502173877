import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset, change } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'planilla' ;
let form = 'planillaForm';
let dirGrid = '/planilla';

export const constants ={
    LOADER: 'PV_LOADER',
    DATA: 'PV_DATA',
    DATAREFUSE: `PV_DATA_DATAREFUSE`,
    ITEM: `PV_ITEM`,
    PAGE: `PV_PAGE`,
    ORDERING: `PV_ORDERING`,
    SEARCH: `PV_SEARCH`,
    SET_EMPLEADOS:'SET_EMPLEADOS',
    SET_CHILDS:'SET_CHILDS',
    SET_PENDIENTES:'SET_PENDIENTES',
    CLIENTE_PV: 'CLIENTE_PV',
    SET_EMPRESAS:'SET_EMPRESAS',
    SET_EMPLEADO_INFO:'SET_EMPLEADO_INFO',
    SET_FECHAS: 'SET_FECHAS',
    SET_ESTADO: 'SET_ESTADO_P',
    SET_TOTAL_EMPRESAS: 'SET_TOTAL_EMPRESAS'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().peticionV;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    // params.state = '1';
    params.es_borrador = false
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch({type:constants.DATA, data:response});
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const listarRefuse = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().peticionV;
    const planilla = getStore().planilla;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.estado = planilla.estado ? planilla.estado.value : true
    // params.state = '1';
    params.es_borrador = true;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        console.log(response)
        dispatch({type: constants.DATAREFUSE ,data: response});
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.es_borrador = 'true';
    params.planilla_id = id;
    api.get(`${url}/see_borrador`, params).then((response) => {
        let res = JSON.parse(response.editable_planilla)
        dispatch(setData(constants.SET_FECHAS, { fechaI: res.fechaI, fechaF:res.fechaF }))
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, res));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const detallePlanilla = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = { es_borrador: 'false' };
    api.get(`${url}/${id}`, params).then((response) => {
        let res = JSON.parse(response.editable_planilla)
        response.editable_planilla = res
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, res));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch, getStore) => {
    const total_empresas = getStore().planilla.total_empresas
    dispatch(setLoader(true));
    data.total_empresas = total_empresas
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));
        dispatch(clear())
    }).catch((error) => {
        let mensaje = 'Error en la creación de Planilla';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data,) => (dispatch, getStore) => {
    const total_empresas = getStore().planilla.total_empresas
    dispatch(setLoader(true));
    data.total_empresas = total_empresas
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid){
            dispatch(push(dirGrid));
        }
        dispatch(clear())
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        dispatch(listarRefuse(1, 1));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const searchChange2 = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(1,1));
};

const searchChangeRefuse = search => (dispatch) => {
    dispatch(setSearch(search));
    console.log("Search refuse")
    dispatch(listarRefuse(1, 1));
};

const onSortChangeRefuse = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarRefuse(sort.page,1));
};


const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpleados = (fechaI, fechaF, cant_dias={days:0, days_month:0}) => (dispatch) =>{
    dispatch(setLoader(true));
    let fecha1 = moment(fechaI).format('YYYY-MM-DD');
    let fecha2 = moment(fechaF).format('YYYY-MM-DD');
    let params = {
        fechaI: fecha1,
        fechaF: fecha2,
        dias:cant_dias['days'],
        dias_mes:cant_dias['days_month'],
    }
    dispatch(setData(constants.SET_FECHAS, { fechaI, fechaF}))
    api.get(`empleados/getListaEmpleados`, params).then((res)=>{
        dispatch(setData(constants.SET_EMPLEADOS, res))
        let detalleplanilla = []
        res.forEach( (item, index) => {
            detalleplanilla[index] = {
                trabajador: item
            };
        }) 
        dispatch(change(form, `detalleplanilla`, detalleplanilla))

    }).catch((res)=>{
        let mensaje = JSON.parse(res.text)
        NotificationManager.error(mensaje.detail, 'ERROR');
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const editBonosEmpleados = (values, trabajadores_info, index, item) => (dispatch) =>{
    dispatch(setLoader(true));
    let total = 0;
    let total_sueldo = 0;
    let total_actividades = 0;
    let total_banco = 0;
    let total_efectivo = 0;
    let total_descuento = 0;
    let detalleplanilla = trabajadores_info
    let trabajador = trabajadores_info[index];

    // sueldos
    trabajador.trabajador.puesto.sueldo = values.sueldos
    values.sueldos.forEach((item, index) => {
        total_sueldo += item.sueldo ? parseFloat(item.sueldo)- parseFloat(item.sueldo_descuento):0
    })

    trabajador.trabajador.puesto.total = total_sueldo;

    // bonificaciones
    trabajador.trabajador.bonos_puesto.bonos = values.bonificaciones

    values.bonificaciones.forEach( (item, index) => {
        if (item.bono){
            if (item.origen_pago == 20){
                total_efectivo += item.valor ? parseFloat(item.valor) - parseFloat(item.valor_descuento):0
            }else{
                total_banco += item.valor ? parseFloat(item.valor) - parseFloat(item.valor_descuento):0
            }
            total += item.valor ? parseFloat(item.valor) - parseFloat(item.valor_descuento):0
        }
       
    })

    trabajador.trabajador.bonos_puesto.total = total
    trabajador.trabajador.bonos_puesto.total_banco = total_banco
    trabajador.trabajador.bonos_puesto.total_efectivo = total_efectivo

    // descuentos
    
    values.descuentos.forEach( (item, index) => {
        if (item.bono){
            item.origen_pago = 10
            total_descuento += item.valor ? parseFloat(item.valor):0
        }
        
    })
    
    trabajador.trabajador.descuentos_puesto.bonos = values.descuentos
    trabajador.trabajador.descuentos_puesto.total = total_descuento

    // Actividades
    let total_bancoA = 0;
    let total_efectivoA = 0;
    trabajador.trabajador.actividades.sueldo = values.actividades
    values.actividades.forEach( (item, index) => {
        if (item.origen_pago == 20){
            total_efectivoA += item.valor ? parseFloat(item.valor) - parseFloat(item.valor_descuento):0
        }else{
            total_bancoA += item.valor ? parseFloat(item.valor) - parseFloat(item.valor_descuento):0
        }
        total_actividades += item.valor ? parseFloat(item.valor) - parseFloat(item.valor_descuento):0
    })

    trabajador.trabajador.actividades.total = total_actividades;
    trabajador.trabajador.actividades.total_banco = total_bancoA
    trabajador.trabajador.actividades.total_efectivo = total_efectivoA


    // reintegrando la inforamcion al listado de empleados
    detalleplanilla[index] = trabajador

    dispatch(push(`/planilla/${ item.id ? item.id:'nuevo'}`));
    dispatch(change(form, `detalleplanilla`, detalleplanilla))
    dispatch(setLoader(false));

}

const refusePV = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/refusepv`, id).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(dirGrid));
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const acceptPV = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/acceptpv`, id).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(dirGrid));
        // dispatch(push(`/ordencompra/${res.id}`));
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const listChilds = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/listchildsOC`, id).then((res)=>{
        dispatch(setData(constants.SET_CHILDS, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const listPv = (val) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {val}
    api.get(`${url}/listpv`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const setProveedor = (proveedor) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE_PV,
        proveedor
    })
}

const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setEmpleadoInfo = (info_trabajador, index, todos_trabajadores, state_bonos) =>(dispatch) =>{
    dispatch({
        type:constants.SET_EMPLEADO_INFO, 
        data:{
            info_trabajador,
            index,
            todos_trabajadores,
            state_bonos
        } 
    })
    if (state_bonos){
        dispatch(initializeForm('EditBonosForm', 
            {
                sueldos: info_trabajador.puesto? info_trabajador.puesto.sueldo:[],
                bonificaciones: info_trabajador.bonos_puesto? info_trabajador.bonos_puesto.bonos: [],
                descuentos: info_trabajador.descuentos_puesto? info_trabajador.descuentos_puesto.bonos: [],
                actividades: info_trabajador.actividades? info_trabajador.actividades.sueldo:[],
            }
        ));
    }

}

const resetPage = () => (dispatch) => {
    dispatch(setPage(1));
}

const descargarCSV = (id, tipo) => (dispatch) => {
    window.location.replace(`/api/${url}/${id}/descargar_csv?tipo=${tipo}&auth_token=${localStorage.getItem("token")}`);
}

const estadoChange = estado => dispatch => {
    dispatch({type:constants.SET_ESTADO, estado});
    dispatch(listarRefuse());
}

const setTotalEmpresas = (total_empresas) =>(dispatch) =>{
    dispatch({type: constants.SET_TOTAL_EMPRESAS, total_empresas})
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    detallePlanilla,
    searchChange,
    searchChange2,
    onSortChange,
    selectEmpleados,
    refusePV,
    acceptPV,
    listChilds,
    listPv,
    setProveedor,
    clear,
    listarRefuse,
    searchChangeRefuse,
    onSortChangeRefuse,
    editBonosEmpleados,
    selectEmpresas,
    setEmpleadoInfo,
    resetPage,
    descargarCSV,
    estadoChange,
    setTotalEmpresas
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPLEADOS]:(state,{ data })=>{
        return{
            ...state,
            empleados: data,
        }
    },
    [constants.SET_CHILDS]:(state,{ data })=>{
        return{
            ...state,
            hijos: data,
        }
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.CLIENTE_PV]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [constants.DATAREFUSE]:(state,{data}) => {
        return{
            ...state,
            datarefuse:data
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_EMPLEADO_INFO]:(state,{ data })=>{
        return{
            ...state,
            info_trabajador: data.info_trabajador,
            index: data.index,
            todos_trabajadores: data.todos_trabajadores,
            state_bonos:data.state_bonos,
        }
    },
    [constants.SET_FECHAS]:(state,{ data })=>{
        return{
            ...state,
            fechaI: data.fechaI,
            fechaF: data.fechaF,
        }
    },
    [constants.SET_ESTADO]:(state, { estado })=>{
        return{
            ...state,
            estado,
        }
    },
    [constants.SET_TOTAL_EMPRESAS]:(state, { total_empresas })=>{
        return{
            ...state,
            total_empresas,
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    empleados: [],
    hijos:[],
    proveedor: null,
    pendientes:[],
    datarefuse: {
        results: [],
        count: 0,
    },
    empresas:[],
    info_trabajador:{},
    index: null,
    todos_trabajadores:[],
    state_bonos: false,
    fechaI: null,
    fechaF: null,
    estado: {value: true, label: "Activos"},
    total_empresas: []
};

export default handleActions(reducers, initialState);
