import React, {Component} from 'react';
import LoadMask from "../../../..//Utils/LoadMask/LoadMask";

import Modal from 'react-responsive-modal';

import imglogo from '../../../../../../../assets/img/logo.png'
import PDFViewer from 'pdf-viewer-reactjs'

import RechazoForm from './rechazoForm'

export default class OCpreview extends Component{
    state = {
        open_modal:false,
    }
    handleModal = (value) =>{
        this.setState({open_modal:value})
    }
    handleSubmit = (values) =>{
        console.log(this.props.id)
        console.log(values)
        values.id =  this.props.id
        values.mensaje = "Orden de Compra rechazada"
        this.props.refusePM(values);
    }
    handleAprobar = () =>{
        let values = {
            id:this.props.id,
            pm_oc:'oc'
        }
        this.props.acceptPM(values);
    }
    handleImprimir = () =>{
        
    }
    render(){
        let { item, loader } = this.props
        return(
            <div>
                <Modal open={this.state.open_modal} onClose={(e)=>{
                        this.setState({open_modal:false})
                    }} center 
                >
                    <div className="mt-5">
                        <h2 className="text-center text-primary">JUSTIFICACIÓN DE RECHAZO</h2>
                    </div>
                    {
                        (item.proceso_estado!="Rechazado")?(
                            <RechazoForm
                               onSubmit={this.handleSubmit}
                               handleModal={this.handleModal}
                            />
                        ):(
                            <div className="form-validate mb-lg text-center" >
                                <span style={{fontSize: 20}}>{"Esta Peticion OC ya fue rechazada"}</span>
                            </div>
                        )
                    }
                </Modal>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <h3 className="t-primary m-t p-t align-self-sm-start align-self-center">Orden de Compra pendiente NO. {item.no_orden}</h3>
                    {
                        item.documento != null?
                        (
                            <div className="">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                                            <button onClick={()=>this.handleModal(true)} className="btn btn-secondary m-1 align-self-center">RECHAZAR</button>
                                            <button onClick={this.handleAprobar} className="btn btn-primary m-1 align-self-center">APROBAR</button>
                                            <a target="_blank" href={item.documento} download={`${item.no_orden}`} className="btn btn-azul-claro m-1 align-self-center">IMPRIMIR</a>
                                        </div>
                                    </div>
                                </div><br/>
                                <div className="prueba">
                                    <PDFViewer
                                        navbarOnTop={true}
                                        hideRotation={false}
                                        document={{
                                            url: `${item.documento}`
                                        }}
                                    />
                                </div>
                            </div>
                        ):(
                            <div className="t-mostaza align-self-center">{"Documento no disponible para mostrar"}</div>
                        )
                    }
                </LoadMask>
            </div>
        )
    }
}