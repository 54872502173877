import React, { Component, Fragment } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import { standardActions } from "../../../Utils/Grid/StandardActions";

function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}


const ListadoT = (props) => {
    const { onPageChange, data, loader, open_modal, permisos, page, nombre_empresa, moneda } = props;
    return (
        <Fragment>
            <Grid hover
                striped data={data}
                loading={loader}
                page={page}
                onPageChange={props.onChangePage}  >
                {/* <TableHeaderColumn
                                dataSort
                                // width='125'
                                dataField="tipo"
                                dataFormat={(cell)=><span>{props.getMovimiento(cell)}</span>}
                            >
                                MOVIMIENTO
                            </TableHeaderColumn> */}
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    tdStyle={{ whiteSpace: 'normal' }}
                    dataFormat={(cell, row) => {
                        return !row.no_despacho ? standardActions({
                            eliminarModal: props.abrirModalEliminar,
                            adicional: props.botonModal,
                        })(cell,row)
                        :
                        row.no_despacho && row.es_ingreso ?
                        standardActions({
                            eliminarModal: props.abrirModalEliminar,
                            adicional: props.botonModal,
                        })(cell,row)
                        :
                        standardActions({
                            adicional: props.botonModal,
                        })(cell,row)
                    }}
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    width='300'
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            cell ? (
                                <div>                                 
                                    {
                                        row.tipo && (                                           
                                            <div>
                                                <span>{() => props.getMovimiento(row.tipo)}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        row.cliente && (                                          
                                            <Fragment>
                                                <span className="font-weight-bold">Cliente: </span>
                                                <span style={{ color: "black" }} >{row.cliente}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        cell.label_tipo_transaccion && (
                                            <div>
                                                <span className="font-weight-bold">Transaccion: </span>
                                                <span className="t-musgo ">{cell.label_tipo_transaccion}</span><br />
                                            </div>
                                        )
                                    }        
                                    {
                                        (row.transaccion_anterior && row.transaccion.tipo_documento != 6 && !row.flotante && !row.no_despacho) ? 
                                        <div>
                                                <span className="font-weight-bold">No. documento: </span>
                                                <span className="t-musgo ">{row.transaccion_anterior.no_documento}</span><br />
                                                <span className="font-weight-bold">No. documento (nuevo): </span>
                                                <span className="t-musgo ">{cell.no_documento}</span><br />
                                        </div>
                                        : 
                                        cell.no_documento && (
                                            <div>
                                                <span className="font-weight-bold">No. documento: </span>
                                                <span className="t-musgo ">{cell.no_documento}</span><br />
                                            </div>
                                        )
                                        
                                    }
                                    {
                                        row.no_factura !="" && (
                                            <div>
                                                <span className="font-weight-bold">No. factura: </span>
                                                <span className="t-musgo ">{row.no_factura}</span><br />
                                            </div>
                                        )
                                    }                      
                                    {
                                        cell.nombre_tipo_gasto && (
                                            <div>
                                                <span className="font-weight-bold">Tipo de Gasto: </span>
                                                <span className="t-musgo ">{cell.nombre_tipo_gasto}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (row.transaccion_anterior && row.transaccion.tipo_documento != 6 && !row.flotante && !row.no_despacho) ?(
                                        <div>                                
                                            <span className="font-weight-bold">Descripcion: </span>
                                            <span className="t-musgo ">{row.transaccion_anterior.descripcion}</span><br />
                                        </div> 
                                    )
                                    :
                                    <div>                                
                                            <span className="font-weight-bold">Descripcion: </span>
                                            <span className="t-musgo ">{cell.descripcion}</span><br />
                                        </div> 
                                    } 
                                    {
                                        cell.empresa_nombre && row.tipo !=14 && (
                                            <div>
                                                <span className="font-weight-bold">Empresa: </span>
                                                <span className="t-musgo ">{cell.empresa_nombre}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        cell.nombre_proveedor && (
                                            <div>
                                                <span className="font-weight-bold"> Proveedor: </span>
                                                <span className="t-musgo"> {cell.nombre_proveedor} </span>
                                            </div>
                                        )
                                    }
                                    {
                                        cell.orden && (
                                            <div>
                                                <span className="font-weight-bold">orden: </span>
                                                <span className="t-musgo ">{cell.orden}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        row.no_despacho && (
                                            <div>
                                                <span className="font-weight-bold">despacho: </span>
                                                <span className="t-musgo ">{row.no_despacho}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (cell.moneda != Monedas[1].value) ? (
                                            <div>
                                                <span className="font-weight-bold">moneda: </span>
                                                <span className="t-musgo ">{cell.moneda}</span>
                                                <span className="font-weight-bold"> con tipo de cambio  </span>
                                                <span className="t-musgo ">
                                                    <RenderMoneda
                                                        monto={cell.tipo_cambio}
                                                        simbolo={getsimbolo(cell.moneda)}
                                                        decimalScale={4}
                                                    />
                                                </span>
                                            </div>
                                        ) : (
                                                <div>
                                                    <span className="font-weight-bold">moneda: </span>
                                                    <span className="t-musgo ">{cell.moneda}</span>
                                                </div>
                                            )
                                    }
                                    {
                                        row.devolucion !="" && (
                                            <div>
                                                <span className="font-weight-bold">Devolución: </span>
                                                <span className="t-musgo ">{row.devolucion}</span><br />
                                            </div>
                                        )
                                    } 
                                    {
                                        (row.transaccion.banco_referencia) && (
                                            <Fragment>
                                                <span className="font-weight-bold">Banco de referencia: </span> {row.transaccion.banco_referencia} <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.flotante) && (
                                            <div>
                                                <span className="font-weight-bold">Es flotante: </span>
                                                <span className="t-musgo ">Sí, pendiente validación.</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (row.fecha_asignacion && row.transaccion.tipo_documento != 6 && !row.flotante && !row.no_despacho) && (
                                            <Fragment>
                                                <span className="font-weight-bold">Fecha asignación </span> {row.fecha_asignacion} <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.recibido_de) && row.tipo !=14 &&(
                                            <Fragment>
                                                <span className="font-weight-bold">Recibido de: </span> {row.recibido_de} <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.despachado_a) && row.tipo !=14 &&(
                                            <Fragment>
                                                <span className="font-weight-bold">Despachado a: </span> {row.despachado_a} <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.pago_de) && row.tipo ==14 | row.tipo == 15&&(
                                            <Fragment>
                                                <span className="font-weight-bold">Pago de: </span> {row.pago_de} <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.pago_a) && row.tipo ==14 | row.tipo == 15&&(
                                            <Fragment>
                                                <span className="font-weight-bold">Pago a: </span> {row.pago_a} <br />
                                            </Fragment>
                                        )
                                    }
                                </div>
                            ) : " NO HAY INFORMACION "
                        )
                    }
                >
                    DETALLE TRANSACCION
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    // width='100'
                    tdStyle={cellTachado}
                    dataSort
                    dataFormat={
                        (cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="debe"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            <RenderMoneda monto={cell} simbolo={getsimbolo(moneda)} />
                        )
                    }
                >
                    DEBE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="haber"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            <RenderMoneda monto={cell} simbolo={getsimbolo(moneda)} />
                        )
                    }
                >
                    HABER
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="saldo"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            <RenderMoneda monto={(Number.parseFloat(`${cell}`).toFixed(2) == 0 ) ? Math.abs(Number.parseFloat(`${cell}`).toFixed(2)) : Number.parseFloat(`${cell}`).toFixed(2)} simbolo={getsimbolo(moneda)} />
                        )
                    }

                >
                    SALDO
                </TableHeaderColumn>
            </Grid>
        </Fragment>
    )
};

export default ListadoT;
