import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

export default class PossGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos.poss === false) {
            if(!!permisos.ver_poss === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton={permisos.crear_poss ? "Agregar" : ""}
                    ruta="poss/crear"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={permisos.poss}
                />}
                titulo="POSS">
                    <Grid hover striped data={data} loading={loader}
                        page={page}
                        onPageChange={onPageChange} onSortChange={onSortChange} >
                        {
                            (permisos.poss) ? (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    isKey
                                    dataFormat={(cell) => {
                                        return customStandardActions(cell, {
                                            eliminar: permisos.eliminar_poss ? eliminar: undefined,
                                            editar: permisos.editar_poss ? "poss": undefined,
                                        });
                                    }}
                                />
                            ) : (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    width="2%"
                                    isKey
                                    dataFormat={cell => ""}
                                />
                            )
                        }
                        <TableHeaderColumn

                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="label_cuenta"
                            dataSort
                        >
                            CUENTA DE BANCO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                        >
                            DESCRIPCIÓN
                        </TableHeaderColumn>

                    </Grid>
            </CardSimple>
        );
    }
}
