import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reporteVentas';
import ReporteVentas from './reporte ventas';

const mstp = state => {
    return {
        ...state.reporteVentas,
        permisos: state.login.me.permisos,
        id_user: state.login.me.id 
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteVentas)
