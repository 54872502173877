import React, { Component, Fragment } from 'react';
import {Link } from 'react-router-dom';
import LoadMask from "../LoadMask/LoadMask";
import ImageVer from '../../../../../assets/img/icons/Ver.png';
import { RenderDateTime } from "../../Utils/renderField/renderTableField";


export default class CardDSEnviadas extends Component {
    render() {
        const { data, loader } = this.props;
        return (
            <div className="">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <h5 className="t-primary">{ data.length > 0 ? this.props.titulo: ""}</h5>
                    <div className="row d-flex justify-content-center">
                            <div className="row col-12 d-flex justify-content-around">
                                {
                                    data.map((item,index)=>{
                                            return(
                                                <div
                                                    key={index}
                                                    className="card col-lg-3 col-md-4 col-sm-6 col-12 row pmcard"
                                                >
                                                    <div className="col-12">
                                                        <span  className="align-self-center font-weight-bold text-center" >DESPACHO</span><br/>
                                                        <span style={{color:"#f3a631"}}>No. despacho: </span>
                                                        <span>{item.no_despacho}</span><br/>
                                                        <span style={{color:"#f3a631"}}>Tipo: </span>
                                                        <span>{item.tipo_movimiento_label}</span><br/>
                                                        <span style={{color:"#f3a631"}} >Fecha: </span>
                                                        <span><RenderDateTime fecha={item.fecha} /></span><br/>
                                                        {
                                                            (item.nombre_reponsable) && (
                                                                <Fragment>
                                                                    <span style={{color:"#f3a631"}}>Emitida por: </span>
                                                                    <span>{item.nombre_reponsable? item.nombre_reponsable:'----'}</span><br/>
                                                                </Fragment>
                                                            )
                                                        }
                                                        {
                                                            (item.cliente_orden) && (
                                                                <Fragment>
                                                                    <span style={{color:"#f3a631"}}>Cliente: </span>
                                                                    <span>{item.cliente_orden.nombre}</span><br/>
                                                                </Fragment>
                                                            )
                                                        }
                                                        {
                                                            (item.nombre_cliente) && (
                                                                <Fragment>
                                                                    <span style={{color:"#f3a631"}}>Cliente: </span>
                                                                    <span>{item.nombre_cliente}</span><br/>
                                                                </Fragment>
                                                            )
                                                        }
                                                        <span style={{color:"#f3a631"}}>Direccion: </span>
                                                        <span>{item.direccion ? item.direccion : "" }</span>
                                                    </div>
                                                    {this.props.link && (<div className="pmicon">
                                                        <Link 
                                                            to={
                                                                (item.tipo_movimiento == 456) ? 
                                                                ``:`/estado_bodega/${this.props.id_bodega}/despacho/${item.id}`
                                                            } 
                                                            className="" 
                                                        >
                                                            <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                                                        </Link>
                                                    </div>)}
                                                </div>
                                            )
                                    })
                                }
                            </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}
