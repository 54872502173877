import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/Reporte/reporteIngresos';
import ReporteIngresoProducto from './IngresosProducto';

const mstp = state => {
    return {
        ...state.reporteIngresos,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteIngresoProducto)
