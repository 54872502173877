import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';

let entregado = [
    {id: true, value: "1", label:"Entregado"},
    {id: false, value: "0", label:"Pendiente"},
    {id: true, value: "2", label:"Devueltos"},
    {id: true, value: "3", label: "Cerrados"},
    {id: false, value: null, label:"Todos"},
]
let pago_completo = [
    {id: true, value: "1", label:"Pagados"},
    {id: false, value: "0", label:"Pendientes"},
    {id: true, value: "3", label: "Cerrados"},
    {id: false, value: null, label:"Todos"},
]
let estado = [
    {id: true, value: true, label: "Activos"},
    {id: false, value: false, label: "Anulados"}
]




export default class ToolbarEstadoCuenta extends Component {
    componentWillMount(){
    }
    render() {
        const {
            valorEntrega,
            valorPagado,
            valorEstado,
            changeEntrega,
            changePago,
            changeEstado,
        } = this.props;
        return (
            <div className="row col-12 m-0 text-lg-left">
                <div className={`col-lg-4 col-md-4 col-12 align-self-center`}>
                     {/* <span className="t-musgo font-weight-bold">Año</span> */}
                    <Select
                    isClearable={false}
                    className={classNames('react-select-container')}
                    backspaceRemovesValue={false}
                    isSearchable={true}
                    options={pago_completo}
                    placeholder={"Pagados"}
                    value={valorPagado}
                    onChange={changePago}
                        />
                </div>
                <div className={`col-lg-4 col-md-4 col-12 align-self-center`}>
                    {/* <span className="t-musgo font-weight-bold">Mes</span> */}
                    <Select
                    isClearable={false}
                    className={classNames('react-select-container')}
                    backspaceRemovesValue={false}
                    isSearchable={true}
                    options={entregado}
                    placeholder={"Despachos"}
                    value={valorEntrega}
                    onChange={changeEntrega}
                        />
                </div>
                <div className={`col-lg-4 col-md-4 col-12 align-self-center`}>
                {/* <span className="t-azul-claro font-weight-bold">Estado</span> */}
                    <Select
                    isClearable={false}
                    className={classNames('react-select-container')}
                    backspaceRemovesValue={false}
                    isSearchable={true}
                    options={estado}
                    placeholder={"Estado"}
                    value={valorEstado}
                    onChange={changeEstado}
                        />
                </div>
                
            </div>
        )
    }
}