import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';
import Chart from "react-google-charts";
import { RenderMoneda } from "../../Utils/renderField/renderTableField";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CardSimple from '../../Utils/Cards/cardSimple';
import { Monedas } from '../../../../utility/constants';
import Switch from "react-switch";

export default class Dashboard extends Component {

  /*   componentDidMount() {
        this.props.ingresoEgreso();
        this.props.cuentasCobrar();
        this.props.cuentasPagar();
        this.props.ventasMetas();
        this.props.topProductosVendidos();
        this.props.topProductosProducidos();
    }
 */
    render() {
        const { loader, cuentas_cobrar, cuentas_pagar, ventas, top_vendidos, top_producidos, dashboard_visualize } = this.props;
        return (

            <CardSimple titulo="DASHBOARD">
                {/*swich para ocultar el dashboard*/} 
                                    <center>
                                        <div style={{ color: 'white' }}>
                                            {dashboard_visualize ? 
                                                <b> Desactivar </b>
                                                :
                                                <b> Activar </b>
                                            }   <b>Dashboard</b>
                                        </div>
                                        <Switch
                                            onColor="#0a5995"
                                            offColor="#ccc"
                                            onChange={(value) => {
                                                this.props.dashboardVisualize(value)
                                            }}
                                            checked={dashboard_visualize}
                                            id="normal-switch"
                                        />
                                    </center>
               {
                dashboard_visualize ?
                                 
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="head-dash">Ingresos y Gastos</div>
                        <div className="col-md-12 mx-0 px-0">
                            <div className="">
                                <div style={{ width: '100%', flex: 1 }}>
                                    <Chart
                                        width={"100%"}
                                        height={250}
                                        chartType="ColumnChart"
                                        loader={<div>Cargando Gráfica</div>}
                                        data={this.props.ingresos_egresos}
                                        options={{
                                            chartArea: { width: '70%' },
                                            bar: { groupWidth: '40%' },
                                            colors: ['#1B6197', '#FF9437'],
                                            hAxis: {
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                format: 'Q ###,###,###.00'
                                            },
                                            tooltip: {
                                                format: 'Q ###,###,###.00'
                                            },
                                        }}
                                        legendToggle
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-4 mt-3">
                                <Link className="btn btn-secondary  align-self-center" to="/reporte_ingreso">
                                    VER MÁS
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex mt-3">
                        <div className="d-flex flex-1 flex-column mr-md-1 w-100"
                            style={{ border: "1px solid #E8E8E8", }}>
                            <div className="head-dash">Cuentas por cobrar</div>
                            <div className="h-100 d-flex align-items-end flex-column w-100">
                                <div className="d-flex flex-1 w-100 align-items-center">
                                    <div style={{ width: '100%', flex: 1 }}>
                                        <Chart
                                            width={'100%'}
                                            height={'200px'}
                                            chartType="PieChart"
                                            loader={<div>Cargando Gráfica</div>}
                                            data={[
                                                ['Estado', 'Total'],
                                                ['Vigente', cuentas_cobrar.vigentes],
                                                ['Por Vencer', cuentas_cobrar.por_vencer],
                                                ['Vencido', cuentas_cobrar.vencidas],
                                            ]}
                                            options={{
                                                colors: ['#1B6197', '#1C8ECB', '#FF9437'],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-1 justify-content-center">
                                        <h5 className="t-musgo">TOTAL:&nbsp;</h5>
                                        <h5>
                                            <RenderMoneda monto={cuentas_cobrar.total} />
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex flex-row w-100">
                                    <div className="d-flex flex-1 flex-column align-items-center">
                                        <h6 className="t-musgo m-0">VIGENTE: </h6>
                                        <h6 className="t-azul-medio">
                                            <RenderMoneda monto={cuentas_cobrar.vigentes} />
                                        </h6>
                                    </div>
                                    <div className="d-flex flex-1 flex-column align-items-center">
                                        <h6 className="t-musgo m-0">POR VENCER: </h6>
                                        <h6 className="t-celeste">
                                            <RenderMoneda monto={cuentas_cobrar.por_vencer} />
                                        </h6>
                                    </div>
                                    <div className="d-flex flex-1 flex-column align-items-center">
                                        <h6 className="t-musgo m-0">VENCIDO: </h6>
                                        <h6 className="t-mostaza">
                                            <RenderMoneda monto={cuentas_cobrar.vencidas} />
                                        </h6>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center w-100 mb-4">
                                    <Link className="btn btn-secondary  align-self-center" to="/cuentas_por_cobrar">
                                        VER MÁS
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-1 flex-column mx-md-1 w-100"
                            style={{ border: "1px solid #E8E8E8", }}>
                            <div className="head-dash">Cuentas por pagar</div>
                            <div className="">
                                <div className="d-flex flex-row mt-2">
                                    <div className="col-12 justify-content-center align-items-center px-5">
                                        <span className="t-musgo font-weight-bold">Moneda</span>
                                        <Select
                                            isClearable={false}
                                            className={classNames('react-select-container')}
                                            backspaceRemovesValue={false}
                                            isSearchable={true}
                                            options={Monedas}
                                            placeholder={"Moneda"}
                                            value={this.props.moneda}
                                            onChange={this.props.setMoneda}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-1">
                                    <div style={{ width: '100%', flex: 1 }}>
                                        <Chart
                                            width={'100%'}
                                            height={'200px'}
                                            chartType="PieChart"
                                            loader={<div>Cargando Gráfica</div>}
                                            data={[
                                                ['Estado', 'Total'],
                                                ['Vigente', cuentas_pagar.vigentes],
                                                ['Por Vencer', cuentas_pagar.por_vencer],
                                                ['Vencido', cuentas_pagar.vencidas],
                                            ]}
                                            options={{
                                                colors: ['#1B6197', '#1C8ECB', '#FF9437'],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-1 justify-content-center">
                                        <h5 className="t-musgo">TOTAL:&nbsp;</h5>
                                        <h5>
                                            <RenderMoneda monto={cuentas_pagar.total} simbolo={this.props.moneda.simbolo} />
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-1 flex-column align-items-center">
                                        <h6 className="t-musgo m-0">VIGENTE: </h6>
                                        <h6 className="t-azul-medio">
                                            <RenderMoneda monto={cuentas_pagar.vigentes} simbolo={this.props.moneda.simbolo} />
                                        </h6>
                                    </div>
                                    <div className="d-flex flex-1 flex-column align-items-center">
                                        <h6 className="t-musgo m-0">POR VENCER: </h6>
                                        <h6 className="t-celeste">
                                            <RenderMoneda monto={cuentas_pagar.por_vencer} simbolo={this.props.moneda.simbolo} />
                                        </h6>
                                    </div>
                                    <div className="d-flex flex-1 flex-column align-items-center">
                                        <h6 className="t-musgo m-0">VENCIDO: </h6>
                                        <h6 className="t-mostaza">
                                            <RenderMoneda monto={cuentas_pagar.vencidas} simbolo={this.props.moneda.simbolo} />
                                        </h6>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mb-4">
                                    <Link className="btn btn-secondary  align-self-center" to="/cuentas_por_pagar">
                                        VER MÁS
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-1 flex-column ml-md-1 w-100"
                            style={{ border: "1px solid #E8E8E8", }}>
                            <div className="head-dash">Ventas del mes</div>
                            <div className="my-auto h-100">
                                <div className="d-flex flex-1 flex-column justify-content-center align-items-center h-100">
                                    <div className="d-flex flex-1 justify-content-center align-items-center align-self-center my-auto">
                                        <div style={{ fontSize: "80px", color: "#00334E", fontWeight: "bold" }}>
                                            {`${ventas.porcentaje}%`}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link className="btn btn-secondary  align-self-center" to="/reporte_ventas">
                                            VER MÁS
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex mt-3"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="head-dash">Top 10 de los productos más vendidos de este mes</div>
                        <div className="col-md-12 mx-0 px-0">
                            <div className="">
                                <div style={{ width: '100%', flex: 1 }}>
                                    <Chart
                                        width={"100%"}
                                        height={250}
                                        chartType="ColumnChart"
                                        loader={<div>Cargando Gráfica</div>}
                                        data={top_vendidos}
                                        options={{
                                            chartArea: { width: '70%' },
                                            legend: { position: 'none' },
                                            bar: { groupWidth: '45%' },
                                            colors: ['#1B6197'],
                                            hAxis: {
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                format: 'Q ###,###,###.00',
                                                minValue: 0
                                            },
                                        }}
                                        legendToggle
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                                <Link className="btn btn-secondary  align-self-center" to="/reporte_productos">
                                    VER MÁS
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex mt-3"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="head-dash">Top 10 de los productos más producidos de este mes</div>
                        <div className="col-md-12 mx-0 px-0">
                            <div className="">
                                <div style={{ width: '100%', flex: 1 }}>
                                    <Chart
                                        width={"100%"}
                                        height={250}
                                        chartType="ColumnChart"
                                        loader={<div>Cargando Gráfica</div>}
                                        data={top_producidos}
                                        options={{
                                            chartArea: { width: '70%' },
                                            legend: { position: 'none' },
                                            bar: { groupWidth: '45%' },
                                            colors: ['#1C8ECB'],
                                            hAxis: {
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                minValue: 0
                                            },
                                        }}
                                        legendToggle
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                                <Link className="btn btn-secondary  align-self-center" to="/reporte_produccion">
                                    VER MÁS
                                </Link>
                            </div>
                        </div>
                    </div>
                </LoadMask>
            :""}
            </CardSimple>
        )
    }
}
