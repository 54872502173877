import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import CardPvEnviadas from '../../../Utils/Cards/cardsPM';
import CardSimple from '../../../Utils/Cards/cardSimple';

import PVGrid from './peticionesVentaGrid';

export default class PVListadoTab extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };
    state = {
        aprube:true,
    }
    componentWillMount() {
        const { listar, listPv, page, permisos } = this.props;
        //listar(page);
        listPv('')
    }
    handleData =(value)=>{
        this.setState({aprube:value})
    }
    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos["peticionventa"] === false && permisos["ordenventa"] === false ) {
            return <Redirect to={`/`} />
        }
        return(
            <CardSimple
                titulo="Peticiones de Venta">
                    {
                        (this.props.pendientes && this.props.pendientes.length > 0) &&  (
                            <CardPvEnviadas
                                data={this.props.pendientes}
                                loader={loader}
                                titulo="PENDIENTES DE APROBAR"
                                link={`peticionesventa`}
                            />
                        )
                    }
                    <h5 className="t-primary">{'LISTADO DE PETICIONES DE VENTA'}</h5>
                    <ToolbarSimple
                        margen={"0"}
                        textBoton="Agregar"
                        ruta="/peticionesventa/nuevo"
                        buscar={this.state.aprube? this.props.searchChange:this.props.searchChangeRefuse}
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Orden"}
                        permisos={permisos.ordenventa || permisos.botonagregar? true : false}
                        //permisos={permisos.ordenventa}
                    />
                    <br/>
                    <Tabs
                        defaultActiveKey="TIPOBONIFICACIONES"
                        tabBarPoition = "top"
                        onChange={(key) => {
                            key === 'ENVIADAS' ? this.handleData(true): this.handleData(false)
                        }}
                        renderTabBar ={()=><ScrollableInkTabBar/>}
                        renderTabContent={()=><TabContent/>}
                    >
                        <TabPane tab="ENVIADAS" key="ENVIADAS">
                            <PVGrid
                                data={data}
                                listar={this.props.listar}
                                loader={loader}
                                page={page}
                                onPageChange={onPageChange}
                                onSortChange={onSortChange}
                                eliminar={eliminar}
                                handleData={this.handleData}
                                value={true}
                            />
                        </TabPane>
                        <TabPane tab="RECHAZADAS" key="RECHAZADAS">
                            <PVGrid
                                data={this.props.datarefuse}
                                listar={this.props.listarRefuse}
                                loader={loader}
                                page={page}
                                onPageChange={onPageChange}
                                onSortChange={this.props.onSortChangeRefuse}
                                eliminar={eliminar}
                                handleData={this.handleData}
                                value={false}
                            />
                        </TabPane>   
                    </Tabs>
                   
            </CardSimple>
        )
    }
}

