import React, { Component } from 'react'
import {Link, Redirect} from 'react-router-dom'
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
// formulario
import FacturasForm from './FacturasForm';


export default class FacturasContainer extends Component {

    componentDidMount(){
        this.props.setMunicipios(this.props.losdatos.despacho.cliente_orden.departamento)
        this.props.setCodigoPostal(this.props.losdatos.despacho.cliente_orden.municipios)
    }

    handleSubmit = (values) => {
        const {BillDespachoPlanta, item, losdatos} = this.props
        values.id = losdatos.despacho.orden
        values.idDespacho = losdatos.despacho.id
        BillDespachoPlanta(values, this.props.mostrarVista, this.props)
    }

    datos = (productos) =>{
        let listadoDatos =[]
        productos.despacho.detalle.map((items) =>
            listadoDatos.push({producto: {producto: items.producto.producto, producto_nombre: items.producto.nombre, cantidad: items.cantidad, cantidad_actual: items.cantidad_actual, cantidad_facturada: items.cantidad_facturada, precio_unitario: items.precio_unitario, cant_despacho: items.cant_despacho, descuento: items.descuento},  facturar: items.cantidad})
        );
        return listadoDatos;

    }
    componentWillUnmount(){
    }

    render() {
        const {loader, item, mostrarVista, misdatos, losdatos} = this.props;
        let tipo = "";
        losdatos.despacho.orden_detalle.proveedor.empresa.map(empresa =>{

            let nit_orden_detalle = losdatos.despacho.orden_detalle.nit_facturacion.replace(/-/g, "").replace(" ", "")
            let nit_facturacion = empresa.Nit.replace(/-/g, "").replace(" ", "")

            if (nit_orden_detalle==nit_facturacion) {
                tipo = empresa.tipo
            }
        })
        return (
            <CardFormulario titulo={"Facturacion"}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <FacturasForm
                        {...this.props}
                        orden={losdatos.despacho.orden}
                        setCodigoPostal={this.props.setCodigoPostal}
                        onSubmit={this.handleSubmit}
                        setAutomaticoReceptor={this.props.setAutomaticoReceptor}
                        item={item}
                        getMunicipios={this.props.setMunicipios}
                        municipios={this.props.municipios}
                        initialValues={{
                            fecha:new Date(),
                            nombre:losdatos.despacho.orden_detalle.nombre_factura,
                            nit:losdatos.despacho.orden_detalle.nit_facturacion,
                            direccion: losdatos.despacho.orden_detalle.direccion ? losdatos.despacho.orden_detalle.direccion : "Ciudad",
                            tipo: tipo,
                            departamento: losdatos.despacho.cliente_orden.departamento,
                            municipios: losdatos.despacho.cliente_orden.municipios,
                            codigo_postal:'09001',
                            detalle_factura: this.datos(losdatos)
                        }}
                        nit={losdatos.despacho.orden_detalle.nit_facturacion}
                        mostrarVista={mostrarVista}
                        losdatos={losdatos}
                    />
                </LoadMask>
            </CardFormulario>
        )
    }
}
