import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import ToolbarFiltroFecha from '../../../Utils/Toolbar/ToolbarFiltroFecha';
import AsignacionesGrid from './AsignacionesGrid';
import HistorialGrid from './HistorialGrid';


class Asignaciones extends Component{

    state = {
        key: 'ASIGNACIONES'
    }

    onChangeTab = (key) => {
        this.setState({ key });
    }

    render(){

        const { permisos } = this.props;

        if(permisos.ver_asig_actividades === false) {
            if(!!permisos.asig_actividades === false) {
                return <Redirect to={`/`} />
            }
        }

        return (
            <CardSimple titulo="asignación de actividades" >
                {this.state.key === 'ASIGNACIONES'
                    ? <ToolbarSimple
                        textBoton="Asignar"
                        ruta="asignacion_actividades/nuevo"
                        buscar={(search)=>this.props.searchChange(search, this.state.key)}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Empleado, Empresa, Actividad"}
                        permisos={permisos.asig_actividades}
                        margen="mb-4"
                    />
                    : <div className="mb-4">
                        <ToolbarFiltroFecha
                            buscar={search=>this.props.searchChange(search, this.state.key)}
                            buscador={this.props.searchHistorial}
                            {...this.props}
                            placeholder={"Buscar por: Empleado, Empresa, Actividad"}
                            justify="justify-content-end"
                        />
                    </div>
                }
                <div className="p-0 px-3 pt-3">
                    <Tabs
                        defaultActiveKey="ASIGNACIONES"
                        tabBarPoition = "top"
                        renderTabBar ={()=><ScrollableInkTabBar/>}
                        renderTabContent={()=><TabContent/>}
                        onChange={key => this.onChangeTab(key)}
                    >
                        <TabPane tab="ASIGNACIONES" key="ASIGNACIONES">
                            <AsignacionesGrid
                                {...this.props}
                            />
                        </TabPane>
                        <TabPane tab="HISTORIAL" key="HISTORIAL">
                            <HistorialGrid
                                {...this.props}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </CardSimple>
        )
    }
}

export default Asignaciones;
