import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reportPreciosProducto';
import ReportePrecioProducto from './ReportePrecioProducto';

const mstp = state => {
    return {
        ...state.reportePrecioProducto,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReportePrecioProducto)
