import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Modal from 'react-responsive-modal';
import Grid from '../../../Utils/Grid/index';
import { RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { RenderNumber } from "../../../Utils/renderField/renderReadField";
import { Monedas } from "../../../../../utility/constants";
import LoadMask from "Utils/LoadMask/LoadMask";

import HistorialProduccion from './HistorialProduccion';

export default class DetalleOP extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    state = {
        modal: false
    }

    componentDidMount() {
        this.props.clear()
        let id = this.props.match.params.idOP
        this.props.leer(id);

    }

    getsimbolo = (valor) => {
        let simbolo = ''
        Monedas.forEach((item, index) => {
            if (item.value === valor) {
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }

    openModal = () => {
        const id = this.props.match.params.idOP;
        this.props.getHistorial(id);
        this.setState({modal: true});
    };

    closeModal = () => {
        this.setState({modal: false});
    };

    renderModal = () => {
        return (
            <Modal open={this.state.modal} onClose={(e)=>{
                this.setState({modal: false})
            }} center >
                <div className="">
                    <h2 className="text-center text-primary text-uppercase">Historial de producción</h2>
                </div>
                <HistorialProduccion
                    data={this.props.historial}
                    loader={this.props.loader}
                />
            </Modal>
        )
    }

    render() {
        const { item, data, loader } = this.props;
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                <div className="Container bg-white">
                    <div className="m-t p-t fnt-size">
                        <div className="row m-none" style={{ paddingTop: "15px" }}>
                            <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                <h3 className="t-primary m-none">{item ? item.no_orden : ""}</h3>
                            </div>
                        </div>
                        {this.renderModal()}
                        <br />{/* DATOS GENERALES */}
                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                        <div className="row m-none">
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Emitida por: </span><br />
                                <span className="m-none t-musgo semibold">
                                    {`${item.emitido_por && item.emitido_por.first_name} ${item.emitido_por && item.emitido_por.last_name}`}
                                </span>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Empresa: </span><br />
                                <span className="m-none t-musgo semibold">{item.nombre_empresa}</span>
                            </div>
                        </div>
                        <br />{/* DESCRIPCION */}
                        <div className="col-12">
                            <span className="m-none t-primary bold" >Descripción</span>
                        </div>
                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                        <div className="row m-none">
                            <div className="col-12">
                                <span className="m-none t-musgo semibold">{`${item.descripcion}`}</span>
                            </div>
                        </div>
                        <br /> {/*CONDICIONES DE ENTREGA */}
                        <div className="col-12">
                            <span className="m-none t-primary bold" >Fecha</span>
                        </div>
                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                        <div className="row m-none">
                            <div className="col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Fecha: </span><br />
                                <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.fecha} ></RenderDateTime></span>
                            </div>
                            <div className="col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Fecha aproximada de entrega: </span><br />
                                <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.fecha_prox_entrega} ></RenderDateTime></span>
                            </div>
                        </div>
                        <br /> {/*Detalle */}
                        <div className="col-12">
                            <span className="m-none t-primary bold" >Detalle</span>
                        </div>
                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                        <div className="row">
                            <div className="mb-4 col-12">
                                <div className="mb-4 ">
                                    <div className=""><h6 className="m-0"></h6></div>
                                    <div className="p-0 px-3 pt-3 p-b">
                                        <div className="margin-tabgrid px-3">
                                            <Grid
                                                hover
                                                striped
                                                data={{ count: 10, results: item.detalle_orden }}
                                                loading={loader}
                                                // onPageChange={onPageChange}
                                                // onSortChange={onSortChange}
                                                pagination={false}>
                                                <TableHeaderColumn
                                                    dataField="producto"
                                                    dataFormat={
                                                        (cell) => (cell.nombre)
                                                    }
                                                    isKey
                                                >
                                                    PRODUCTO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="cantidad"
                                                    dataAlign="right"
                                                    dataFormat={cell => <RenderNumber value={cell} decimalScale={4} />}
                                                >
                                                    CANTIDAD SOLICITADA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="cantidad_actual"
                                                    dataAlign="right"
                                                    dataFormat={
                                                        (cell, row) => <RenderNumber value={row.cantidad - cell} decimalScale={4} />
                                                    }
                                                >
                                                    CANTIDAD REPORTADA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="cantidad_actual"
                                                    dataAlign="right"
                                                    dataFormat={cell => <RenderNumber value={cell} decimalScale={4} />}
                                                >
                                                    CANTIDAD FALTANTE
                                                </TableHeaderColumn>
                                            </Grid>
                                            <div className="row justify-content-sm-start justify-content-start ml-0">
                                                <button onClick={this.openModal} className="btn btn-primary m-1 align-self-center">VER HISTORIAL</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadMask>
        )
    }
}
