import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import { RenderDateTime } from "../../../Utils/renderField/renderReadField";
import ImageVer from '../../../../../../assets/img/icons/chequeAefectivo.png';
import PrecioClienteForm from './PrecioClienteForm';
import Modal from 'react-responsive-modal';
import {customStandardActions} from '../../../Utils/Grid/StandardActions';
import renderFieldCheck from '../../../Utils/renderField'

export default class GridPrecioCliente extends Component {
    componentWillMount() {
        const { selectEmpresas, selectTipos, selectVendedores } = this.props;
        selectEmpresas()
        selectTipos()
        selectVendedores()
    }
    botonCambioIndividual = (row) => {
        return (
            <a className="px-1" style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.props.setOpenModal(true, row.precio_unitario)
                    this.props.setProductoSeleccionado(row)
                    this.props.setDatosModal(row)
                }}
            >
                <img className="action-img-ver" title="Cambio manual" src={ImageVer} alt="Cambio manual" />
            </a>
        )
    }
    renderModalCambioIndividual = () => {
        return(
            <Fragment>
                <div className="mt-0">
                    <h3 className="text-center text-primary">CAMBIAR PRECIO</h3>
                </div>
                <PrecioClienteForm
                    cerrarModal={this.props.setOpenModal}
                    texto_boton_movimiento={"GUARDAR"}
                    titulo_modal="TIPO DE CAMBIO"
                    handleSubmit={(e) => {
                        e.preventDefault();
                        this.props.guardarCambio()
                    }}
                    fecha_actualizacion = {this.props.fecha_actualizacion}
                    precio_interno_anterior = {this.props.precio_interno_anterior}
                />
            </Fragment>
        );
    }
    botonCheck = (row) =>{
        this.props.setProductosSeleccionados(row)
    }
    render() {
        const { loader, data, page, listar, onPageChange, onSortChange, open_modal, setOpenModal, permisos} = this.props;
        return (
            <Fragment>
                <Modal
                        open={open_modal}
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                        center
                    >
                        {
                            this.renderModalCambioIndividual()
                        }
                    </Modal>
                    {permisos.precios_cliente_modificar &&
                    <PrecioClienteForm
                    texto_boton_movimiento={"APLICAR CAMBIO"}
                    titulo_modal="TIPO DE CAMBIO GENERAL"
                    handleSubmit={(e) => {
                        e.preventDefault();
                        this.props.guardarCambio(false)
                    }}
                    es_general={true}
                    />}
                     <div className="flex-1 px-5 mt-1 align-items-center d-flex centrar-check">
                    <input 
                        type="checkbox" 
                        className='mt-1'
                        component={renderFieldCheck} 
                        checked={this.props.seleccionar_todos}
                        onChange={() => this.props.setSeleccionarTodos()}
                        />
                        <h6 className='px-2 mt-3'>Seleccionar todos</h6>
                    </div>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={listar} onSortChange={onSortChange}
                >
                   {
                   permisos.precios_cliente_modificar && 
                   <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        dataFormat={(cell, row) => {
                            return customStandardActions(cell, {
                                adicional: (c, r) => {
                                         
                                    return <label className={`mb-0 d-flex py-1 px-0 px-sm-1 flex-row algin-items-center`}>
                                    <div className="flex-1 px-0 mt-1 d-flex justify-content-center centrar-check">
                                    <input 
                                    type="checkbox" 
                                    component={renderFieldCheck} 
                                    checked={row.check}
                                    onChange={() => {this.props.setProductosSeleccionados(row), this.forceUpdate()}}
                                    /> 
                                    </div>
                                    </label>
                                },
                                adicional2: (c,r) =>{
                                    return <div className="px-2"></div>
                                },
                                adicional3: (c, r) => {
                                    return this.botonCambioIndividual(row)
                                }
                            });
                        }}
                        >
                    </TableHeaderColumn>}
                    <TableHeaderColumn
                        dataField="cliente"
                        dataSort
                        className='reporte-table'
                        dataFormat={(cell) => cell ? cell : "Sin registro"}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey
                        dataField="fecha_actualizacion_anterior"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell, row) => (
                            cell ? <RenderDateTime value={cell} /> : "Sin registro"
                        )}
                    >
                        ACT. ANTERIOR
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio_unitario_anterior"
                        dataSort
                        className='reporte-table'
                        dataAlign="right"
                        dataFormat={(cell) => ( cell  || cell == 0 ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                    >
                        PRECIO ANTERIOR
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                        className='reporte-table'
                        dataFormat={(cell) => cell ? cell : "Sin registro"}
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_producto_label"
                        dataSort
                        className='reporte-table'
                        dataFormat={(cell) => ( cell ? cell : "Sin registro")}
                    >
                        TIPO PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_actualizacion_actual"
                        dataSort
                        className='reporte-table'
                        dataAlign="center"
                        dataFormat={(cell, row) => (
                            cell ? <RenderDateTime value={cell} /> : "Sin registro"
                        )}
                    >
                        ULTIMA ACT.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio_unitario"
                        dataSort
                        className='reporte-table'
                        dataAlign="right"
                        dataFormat={(cell) => ( cell  || cell == 0 ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                    >
                        PRECIO VENTA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio_venta"
                        dataSort
                        className='reporte-table'
                        dataAlign="right"
                        dataFormat={(cell) => ( cell  || cell == 0 ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                    >
                        PRECIO FABRICA
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }

}
