import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/itinerarios/itinerarios';
import itinerariosGrid from './itinerariosGrid';

const mstp = state => {
    return {...state.itinerario, permisos: state.login.me.permisos, rol: state.login.me.profile.rol_name}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(itinerariosGrid)