import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';

import VehiculosForm from './VehiculosForm';




class VehiculosCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR VEHÍCULO/MAQUINARIA',
        cuentas: []
    }
    componentWillMount(){
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR VEHÍCULO/MAQUINARIA'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} = this.props;
        let id = this.props.match.params.id;
        this.state.editar ? editar(id, values) : crear(values);
    }
    render() {
        const { loader, cuentas_banco} = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <VehiculosForm
                        onSubmit={this.onSubmit}
                        editar={this.state.editar} />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default VehiculosCrear;
