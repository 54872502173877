import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/despachosplanta/despachosplanta';
import NuevaFactura from './facturas';

const mstp = state => {
    return {...state.despachosplanta}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(NuevaFactura)
