import React, { Component } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import Modal from 'react-responsive-modal';
import ValesForm from './ValesForm';


class VehiculosCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR VALE DE GASOLINA',
        cuentas: [],
        modal: false,
        values: undefined,
    }
    componentWillMount(){
        this.props.resetForm();
        this.props.getTipoGastos();
        this.props.getSelectCuentas();
        this.props.selectEmpresas();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR VALE DE GASOLINA'
            });
        }
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    onSubmit = (values) =>{
        const {crear, editar} = this.props;
        let id = this.props.match.params.id;
        this.state.editar ? editar(id, values) : crear(values);
    }

    closeModal = () =>{
        this.setState({ modal: false });
    }

    openModal = (values) => {
        this.setState({ modal: true, values: values});
    }

    esCerrado = (values) =>{
        if(values.cerrado == 1){
            this.openModal(values);
        } else{
            this.onSubmit(values);
        }
    }

    renderModalCerrar = () => {
        const { modal } = this.state;
        return (
            <Modal open={modal} onClose={ this.openModal } center>
                <div className="mt-5">
                    <br/>
                    <h2 className="text-center text-primary">DESEA DAR POR CERRADO <br/> EL VALE DE GASOLINA </h2>
                    <br/>
                </div>
                <div className="d-flex  col-12 justify-content-around mt-5">
                    <button className="btn btn-secondary" onClick={this.closeModal}> CANCELAR </button>
                    <button className="btn btn-primary" onClick={()=>{ this.onSubmit(this.state.values) }}>
                        ACEPTAR
                    </button>
                </div>

            </Modal>
        )
    }

    render() {
        const { loader, cuentas_banco} = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                {this.renderModalCerrar()}
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <ValesForm
                        onSubmit={this.esCerrado}
                        editar={this.state.editar}
                        initialValues = {{
                            fecha: new Date(),
                            cerrado: 0
                        }}
                        tipo_gastos={this.props.tipo_gastos}
                        select_cuentas={this.props.select_cuentas}
                        cambioDescuento={this.props.cambioDescuento}
                        empresas={this.props.empresas}
                        setGalones={this.props.setGalones}
                        setPrecioGalones={this.props.setPrecioGalones}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default VehiculosCrear;
