import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import TransaccionForm from './transaccionForm';
import {  Monedas } from '../../../../../utility/constants';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import moment from 'moment';

class Empresa extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        ingreso: false,
        titulo: 'REGISTRAR EGRESO',
    }
    componentDidMount(){
        this.props.selectEmpresas();
        let id = this.props.match.params.id
        this.props.meCuenta(id, true);
        this.props.initialC();
        // const { Ingreso } = this.props.location.state
        let var_ingreso = this.props.location.state? this.props.location.state.Ingreso:false
        if(var_ingreso) {
            this.setState({titulo: 'REGISTRAR INGRESO'})
        }
        this.setState({
            ingreso:var_ingreso,
        })
    }
    format = (array=[]) =>{
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }
    handleSubmit = (values) =>{
        //id de la cuenta a la que pertenece la nueva transaccion
        let valores = _.cloneDeep(values);

        let id = this.props.match.params.id;
        valores.es_ingreso = this.state.ingreso;
        //Se manda el id de la cuenta
        if(valores.flotante_inverso){
            //Se verifica que los valores sean inversos.
            if(valores.flotante_inverso == 'true'){
                valores.flotante_inverso = true;
            }else {
                valores.flotante_inverso = false;
            }
        }
        valores.cuenta_p = this.props.match.params.id;
        this.props.crear(valores, id);
    }
    handleCuentas =(idEmpresa) =>{
        idEmpresa &&(this.props.selectCuentas(idEmpresa))
    }
    getSimbolo=(simbolo)=>{
        let prefix='Q'
        Monedas.forEach((item,index)=>{
            if (item.value == simbolo){
                prefix = item.simbolo
            }
        })
        return prefix + '. '
    }
    validCambio =(monto, cambio)=>{
        if (monto != 0){
            if(cambio !=0){return monto/cambio}
            else{ return 0}
        }else { return 0}
    }
    render() {
        const { loader, empresas, cuentas, meCuenta, destino, me, valorAnio, valorMes } = this.props;
        let id = this.props.match.params.id;
        let Cambio = false;
        if (me.moneda != destino.moneda && destino.moneda != undefined)
        {
            Cambio=true;
        }else {Cambio=false;}
        let var_ingreso = this.props.location.state? this.props.location.state.Ingreso:false
        return (
            <CardFormulario
                className="col-md-12"
                // titulo={this.state.titulo}
                >
                <LoadMask loading={loader} light >
                    <div className="p-0 m-0 d-flex justify-content-center ">
                        <div className="col-md-12 p-0 m-0">
                            <h3 className="t-primary text-uppercase">{this.state.titulo}</h3>
                            <TransaccionForm
                                onSubmit={this.handleSubmit}
                                format={this.format}
                                empresas={empresas}
                                cuentas={cuentas}
                                valorAnio={valorAnio.value}
                                valorMes={valorMes.value}
                                handleCuentas={this.handleCuentas}
                                noCuenta ={id}
                                meCuenta={meCuenta}
                                Cambio={Cambio}
                                meSimbolo={me.moneda}
                                simbolo = {destino.moneda}
                                getSimbolo={this.getSimbolo}
                                validCambio={this.validCambio}
                                initialValues={{
                                    'es_ingreso':this.state.ingreso,
                                    'egreso_tipo': 'true',
                                    'tipo_cambio': 1,
                                    'fecha': new Date(),
                                    'flotante_inverso': 'false'
                                }}
                                ingreso={this.state.ingreso}
                                principal={this.props.me.es_principal}
                            />
                        </div>

                    </div>

                </LoadMask>
            </CardFormulario>

        );
    }
}

export default Empresa;
