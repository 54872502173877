import React, { Component } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import { customStandardActions } from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import ToolbarSimpleCliente from '../../../Utils/Toolbar/ToolbarSimpleCliente';

export default class RolesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page, clearSearch,selectVendedor } = this.props;
        clearSearch();
        selectVendedor()
        listar(page, true);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos, changeVendedor} = this.props;
        if(permisos["clientes"] === false) {
            if(!!permisos[`ver_clientes`] === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <div className="Container bg-white">
                <h3 className="t-primary m-t p-t">CLIENTES</h3>
                <div className="row justify-content-end">
                    <div className="col-md-8 col-sm-8 col-8">
                        <ToolbarSimpleCliente
                            textBoton={permisos.crear_clientes ? "AGREGAR" : ""}
                            ruta="/clientes/nuevo"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre, Nit"}
                            permisos={permisos.clientes}
                            changeVendedor={changeVendedor}
                            vendedores={this.props.vendedores}
                        />                                          
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                                    {permisos.clientes && (<TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataSort
                                        dataFormat={(cell) => {
                                            return customStandardActions(cell, {
                                                eliminar: permisos.eliminar_clientes ? eliminar: undefined,
                                                editCliente: permisos.editar_clientes ? "clientes": undefined,
                                                agenda: permisos.ver_detalle_clientes ? "clientes": undefined
                                            });
                                        }}
                                        >
                                    </TableHeaderColumn>)}
                                    <TableHeaderColumn
                                        dataField="nombre"
                                        isKey
                                        dataSort
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="telefono"
                                        dataSort
                                    >
                                        TELÉFONO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="sucursal"
                                        dataSort
                                        dataFormat={
                                            (cell,row) => {
                                                if (cell.length){
                                                    return cell[0].direccion
                                                }
                                                else return ""
                                            }
                                        }
                                    >
                                        DIRECCION
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

