import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/despachosplanta/despachosplanta';
import DespachoPlantaGrid from './DespachoPlantaGrid';

const mstp = state => {
    return {...state.despachosplanta, permisos: state.login.me.permisos, rol: state.login.me.profile.rol_name}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DespachoPlantaGrid)
