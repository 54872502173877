import React, {Component, Fragment} from 'react'
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import TransaccionesHistorialGrid from '../detalleLiquidacion/gridTransaccionesHistorial';
import iconoImprimir from '../../../../../../assets/img/icons/imprimir.png';


export default class HistorialDetalle extends Component{

    render(){
        const {item_liquidacion, loader, imprimirDetalleLiquidacionEP} = this.props;

        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                <div className={`row mt-0`}>
                    { !!item_liquidacion && item_liquidacion.fecha && (
                        <div className="fnt-size">
                            <br/>
                            <div className="mt-0">
                                <h2 className="text-left text-primary">LIQUIDACION No. {item_liquidacion.id}</h2>
                            </div>
                            <br/> <br/>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha:</span>
                                    <span className="m-none t-musgo semibold"><RenderDateTime fecha={item_liquidacion.fecha}></RenderDateTime></span><br/>
                                    <span className="m-none t-mostaza semibold">Vendedor: </span>
                                    <span className="m-none t-musgo semibold">{`${item_liquidacion.vendedor.nombre}`}</span><br/>
                                    <span className="m-none t-mostaza semibold">Monto: </span>
                                    <span className="m-none t-musgo semibold"><RenderMoneda monto={item_liquidacion.monto} simbolo={'Q. '} /></span><br/>
                                    <span className="m-none t-mostaza semibold">Aprobado por: </span>
                                    <span className="m-none t-musgo semibold">{item_liquidacion.aprobado_por ? `${item_liquidacion.aprobado_por.nombre.toUpperCase()}`: ""}</span>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="col-md-12 m-0 p-0 d-flex justify-content-end">
                                        <img
                                            className="action-img"
                                            alt="Imprimir Liquidación"
                                            title="Imprimir Liquidación"
                                            src={iconoImprimir}
                                            onClick={() => imprimirDetalleLiquidacionEP(item_liquidacion.id)}>
                                        </img>
                                    </div>
                                </div>
                            </div>
                            <br/> <br/>
                            {item_liquidacion && item_liquidacion.transacciones && (
                                <TransaccionesHistorialGrid
                                    data = {item_liquidacion.transacciones}
                                    loader={loader}
                                />
                                )
                            }
                        </div>
                    )}
                </div>
            </LoadMask>
        )
    }
}
