import React, { Component, Fragment } from 'react';
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import Modal from 'react-responsive-modal';
import PagoCreditoForm from '../../Crear/PagoCreditoForm';
import { NotificationManager, __esModule} from "react-notifications";


import { TipoTransaccion, getsimbolo } from '../../../../../../utility/constants';
import { RenderMoneda, RenderDateTime } from "../../../../Utils/renderField/renderTableField";

import Swal from 'sweetalert2';
import moment from 'moment';


import AnulacionForm from '../../../Transacciones/Listar/anulacionForm';
import RealizarPagoForm from '../../../Transacciones/Listar/realizarPagoForm';

import { getSimbolo } from '../../../../Utils/renderField/renderTableField';
import { api } from 'api';


function cellTachado(cell, row) {
    if(!row.estado){
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 };
    }
    return { whiteSpace: 'normal', padding: 5 };
}


export default class pagosCredito extends Component {
    state = {
        open_modal:false,
        es_pago:false,
        es_cuenta: true,
        id_transaccion: null,
        transaccion_sel: null,
        anular_transaccion: true,
        abonar_cheque: false
    }

    abrirModalEliminar = (id, row) => {
        console.log('row gastos, eliminar ', id, row);
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        });
        this.props.setOpenModalAnulacion(true);
    }

    onSubmit = (values) => {
        const { orden } = this.props;
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values, orden.orden.id);
            }
        });
    }

    marcarOCCompleta = () => {
        const { orden } = this.props;
        if( orden.saldo == 0 ){
            Swal.fire({
                title: '¿Desea marcar la orden de compra como pagada?',
                text: '¡No podrá revertir esta acción!',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: '¡Sí, pagada!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.marcarPagadaOC(orden.orden.id);
                }
            });
        }else if( orden.saldo < 0 ){
            Swal.fire({
                title: `Le debe ${-1*orden.saldo} al proveedor`,
                text: '¡Por favor realizar el pago para el balance!',
                confirmButtonText: 'Ok',
                reverseButtons: true
            }).then((result) => {});
        }else{
            Swal.fire({
                title: `Se le ha dado un adelanto de ${-1*orden.saldo} al proveedor`,
                text: '¡Verifica los ingresos!',
                confirmButtonText: 'Ok',
                reverseButtons: true
            }).then((result) => {});
        }
    }

    cerrarModal = () => {
        this.props.cerrarModales();
    }

    botonRealizarMovimiento = (id, row) => {
        if(!row.estado) {
            return(<div></div>);
        }
        if(!row.flotante) {
            return(<div></div>);
        }
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                onClick={(e)=>{
                    e.preventDefault();
                    this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    abonar_cheque: false
                });
                this.props.setOpenModalPago(true);
                }}
            >
                <img className="action-img" title="adicional" src={require("../../../../../../../assets/img/icons/marcar_bono.png")} alt="Ver eliminado"/>
            </a>
        );
    }

    renderModalAnulacion = () => {
        const { open_modal_anulacion } = this.props;
        return (
            <Modal open={open_modal_anulacion} onClose={(e) => {
                this.props.setOpenModalAnulacion(false);
            }} center
            >
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>

                {
                        (this.state.transaccion_sel && !this.state.transaccion_sel.transaccion.estado) ? (
                            <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                        ): (
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModalAnulacion}
                            />
                        )
                    }

            </Modal>
        );
    }


    botonModal = (id, row) => {
        if(row.estado){
            return(<div></div>);
        }
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                onClick={(e)=>{
                e.preventDefault();
                this.setState({
                    id_transaccion: id,
                    transaccion_sel: row
                });
                this.props.setOpenModalAnulacion(true);
                }}
            >
                <img className="action-img" title="Ver eliminado" src={require("../../../../../../../assets/img/icons/Ver.png")} alt="Ver eliminado"/>
            </a>
        );
    }


    renderModalFlotantePagado = () => {
        let transaccion = null;
        if(this.state.transaccion_sel){
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            };
        }

        return(
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">REALIZAR PAGO</h2>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={()=> {
                        this.props.cerrarModales();
                    }}
                />
            </Fragment>
        );
    }

    getNotas = (search) => {
        let params = {
            orden: this.props.match.params.id,
            aplicada: false,
            moneda: this.props.item.moneda,
        }
        if (search) {
            params.search = search;
        }
        return api.get("notas_credito", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    };

    renderModalAbonarPago = () => {
        const {  pagos_credito, cuentas,  simbolo, orden, item, tipo_gastos } = this.props;

        let simbolo_orden = getSimbolo(orden.orden.moneda);

        let total_pagos1 = 0
            orden.pagos.map( numero => {
                if(numero.estado == true)
                    total_pagos1 = total_pagos1 + numero.monto
            })
        return(
            <Fragment>
                <div className="mt-0">
                    <h3 className="text-center text-primary">PAGO</h3>
                </div>
                <PagoCreditoForm
                    orden = {orden}
                    cerrarModal={this.cerrarModal}
                    cuentas={cuentas}
                    simbolo={simbolo}
                    simbolo_orden={simbolo_orden}
                    es_gasto={true}
                    texto_boton_movimiento={"AGREGAR PAGO"}
                    titulo_modal="PAGAR DESDE"
                    tipo_gastos={tipo_gastos}
                    onSubmit={(e) => {

                        let monto_pago = e.monto;
                        const tipo_cambio = e.tipo_cambio;
                        const monto_pendiente = orden.saldo_pendiente;

                        if (tipo_cambio > 0) {
                            monto_pago = (e.monto / e.tipo_cambio).toFixed(2);
                        }
                        
                        // const monto_pago = e.monto+total_pagos1;
                        // console.log("#######################################################################");
                        // console.log("monto_dolares", monto_pago);
                        // console.log("monto_pendiente",monto_pendiente);
                        // console.log("validacion",monto_pago > monto_pendiente);
                        // console.log(orden);
                        // console.log("#######################################################################");

                        (monto_pago > monto_pendiente) ? NotificationManager.error("El monto del pago es mayor al faltante", 'ERROR') : this.props.pagoOC(orden.orden.id);
                        console.log("======>>>>>> ==== " , e.monto)
                        // this.props.abonarGastoOPago(orden.orden.id, true);
                        this.cerrarModal(); 
                    }}
                    es_pago={true}
                    getNotas={this.getNotas}
                    moneda_orden={this.props.item.moneda}
                />
            </Fragment>
        );
    }

    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD');
                this.props.flotanteChequePagado(values, this.props.id_cuenta);
            }
        });
    }


    render() {
        const {  open_modal_pago, cuentas,  simbolo, orden, item } = this.props;
        let data = {
            count: 1,
            results:orden.pagos
        };

        return (
            <Fragment>
                {this.renderModalAnulacion()}
                <Modal
                    open={open_modal_pago}
                    onClose={(e) => {
                        this.props.cerrarModales();
                    }}
                    center
                >
                    {
                        (this.state.abonar_cheque) ? (
                            this.renderModalAbonarPago()
                        ): (
                            this.renderModalFlotantePagado()
                        )
                    }
                </Modal>
                <Grid hover striped data={data}  pagination={false} >
                    <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    dataFormat={standardActions({
                        // eliminarModal: this.abrirModalEliminar,
                        adicional: this.botonModal,
                        adicional2: this.botonRealizarMovimiento
                    })}
                >
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell,row) => {
                            return(
                                <div>
                                    <span className="font-weight-bold">FECHA </span><RenderDateTime fecha={cell} ></RenderDateTime> <br/>
                                    {
                                        (row.fecha_emision) && (
                                            <Fragment>
                                                <span className="font-weight-bold">FECHA EMISIÓN </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br/>
                                            </Fragment>
                                        )
                                    }
                                    <span className="font-weight-bold">TIPO DE PAGO</span> <span>{row.flotante ? <span>Flotante</span> : <span>Inmediato</span>}</span>
                                </div>
                            );
                        }
                    }
                >
                    FECHA
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="tipo_cuenta"
                    dataSort
                    tdStyle={cellTachado}

                >
                    ENTRO A
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="numero_cuenta"
                    dataSort
                    tdStyle={cellTachado}

                >
                    CUENTA
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            let id = Number(cell.tipo_documento);
                            let cuenta = _.find(TipoTransaccion, x => x.value == id);
                            if(cuenta){
                                return(cuenta.label);
                            }
                        }
                    }
                >
                    TIPO DOCUMENTO
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            return <span>{cell.no_documento}</span>;
                        }
                    }
                >
                    NO. TRANSACCIÓN
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="monto"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat ={
                        (cell, row)=>{
                            if(cell){
                                return <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />;
                            }
                        }
                    }
                >
                    MONTO
                </TableHeaderColumn>
                </Grid>
                {
                (!orden.orden.pago_completo && orden.orden.estado) && (
                    <Fragment>
                        <button type="button" onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                open_modal: true,
                                es_pago: true,
                                es_gasto: false,
                                abonar_cheque: true
                            });
                            this.props.setOpenModalPago(true);
                        }} className="btn btn-primary m-1 align-self-center mt-2"
                        >AGREGAR PAGO</button>
                        <button type="button" onClick={(e) => {
                                e.preventDefault();
                                this.marcarOCCompleta();
                            }} className="btn  btn-mostaza  m-1 align-self-center mt-2"
                         >ORDEN DE COMPRA PAGADA</button>
                    </Fragment>
                )
            }

            </Fragment>
        );
    }
}
