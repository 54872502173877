import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';


let url = 'reportes' ;


export const constants ={
    LOADER: 'REPORTE_COBROS_LOADER',
    SET_CLIENTE: 'SET_CLIENTE_SALDOS',
    SET_LOAD_OPTIONS_DESPACHOS: 'SET_LOAD_OPTIONS_DESPACHOS',
    SET_FECHA_I: 'SET_FECHA_I_SALDOS',
    SET_FECHA_F: 'SET_FECHA_F_SALDOS',
    SET_FECHA_I_OV: 'SET_FECHA_I__OV',
    SET_FECHA_F_OV: 'SET_FECHA_F_OV',
    SET_EMPRESAS_FLOTANTES:'SET_EMPRESAS_FLOTANTES',
    SET_FECHA_I_FLOTANTES: 'SET_FECHA_I_FLOTANTES',
    SET_FECHA_F_FLOTANTES: 'SET_FECHA_F_FLOTANTES',
    SET_CLIENTE_OV: 'SET_CLIENTE_OV',
    SET_DESPACHO_OV: 'SET_DESPACHO_OV',
    SET_SEARCH_EMPRESA: 'CUENTAS_SET_SEARCH_EMPRESA'
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setCliente = (type, cliente) => ({
    type,
    cliente
});

const setLoadOptionsDespachos = (type, loadOptionsDespachos) => ({
    type,
    loadOptionsDespachos
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});

const setFechaIOV = fecha_inicio_ov =>({
    type:constants.SET_FECHA_I_OV,
    fecha_inicio_ov
});

const setFechaFOV = fecha_fin_ov =>({
    type:constants.SET_FECHA_F_OV,
    fecha_fin_ov
});

const setClienteFlotantes = (type, cliente_flotantes) => ({
    type,
    cliente_flotantes
});

const setFechaIFlotantes = fecha_inicio_flotantes =>({
    type:constants.SET_FECHA_I_FLOTANTES,
    fecha_inicio_flotantes
});

const setFechaFFlotantes = fecha_fin_flotantes =>({
    type:constants.SET_FECHA_F_FLOTANTES,
    fecha_fin_flotantes
});

const setClienteOV = (type, cliente_ov) => ({
    type,
    cliente_ov
});

const setDespachoOV = (type, despacho_ov) => ({
    type,
    despacho_ov
});

const setEmpresas = (type, data) =>({
    type,
    data
});


const descargaArchivoDespachosTotalesEP = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().reporteDespachosBancos;
    const params = {
        fecha_inicio: resource.fecha_inicio.format("YYYY-MM-D"),
        fecha_fin: resource.fecha_fin.format("YYYY-MM-D")
    };

    if (resource.cliente) {
        params["id_cliente"] = resource.cliente.id;
    }

    api.get(`${url}/descargar_reporte_despachos_totales`, params).then((response) => {
        NotificationManager.success(response.detail, 'Éxito', 5000);
        setTimeout(() => {
            dispatch(cicloVerificacion(response.id_archivo));
        }, 500);
    }).catch(() => {
        NotificationManager.error('Error en la Descarga del Reporte', 'Error', 5000);
        dispatch(setLoader(false));
    }).finally(() => {
    });
};

const descargaArchivoDespachosOrdenVentaEP = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().reporteDespachosBancos;
    const params = {
        fecha_inicio: resource.fecha_inicio_ov.format("YYYY-MM-D"),
        fecha_fin: resource.fecha_fin_ov.format("YYYY-MM-D")
    };

    if (resource.cliente_ov) {
        params["id_cliente"] = resource.cliente_ov.id;
    }

    api.get(`${url}/descargar_reporte_orden_venta`, params).then((response) => {
        NotificationManager.success(response.detail, 'Éxito', 5000);
        setTimeout(() => {
            dispatch(cicloVerificacion(response.id_archivo));
        }, 500);
    }).catch(() => {
        NotificationManager.error('Error en la Descarga del Reporte', 'Error', 5000);
        dispatch(setLoader(false));
    }).finally(() => {
    });
};

const cicloVerificacion = (id_archivo) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    setTimeout(function () {
        dispatch(descarga_estadoEP(id_archivo));
    }, 5000)
}

const descargaArchivoBancosFlotantesEP = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().reporteDespachosBancos;
    const params = {
        fecha_inicio: resource.fecha_inicio_flotantes.format("YYYY-MM-D"),
        fecha_fin: resource.fecha_fin_flotantes.format("YYYY-MM-D")
    };

    if (resource.empresa) {
        params["id_empresa"] = resource.empresa.value;
    }

    api.get(`${url}/descargar_reporte_bancos_flotantes`, params).then((response) => {
        NotificationManager.success(response.detail, 'Éxito', 5000);
        setTimeout(() => {
            dispatch(cicloVerificacionFlotantes(response.id_archivo));
        }, 500);
    }).catch(() => {
        NotificationManager.error('Error en la Descarga del Reporte', 'Error', 5000);
        dispatch(setLoader(false));
    }).finally(() => {
    });
};

const cicloVerificacionFlotantes = (id_archivo) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    setTimeout(function () {
        dispatch(descarga_estadoEP(id_archivo));
    }, 5000)
}

const descarga_estadoEP = (id_archivo) => (dispatch, getStore) => {
    let FALLO = 0;
    let FINALIZADO = 1;
    let EN_PROCESO = 2;

    let params = { id_archivo }
    api.get(`${url}/descargar_estado`, params).then((response) => {
        if (response.estado == EN_PROCESO){
            dispatch(cicloVerificacion(id_archivo));
        }
        else if (response.estado == FINALIZADO){
            dispatch(setLoader(false));
            if (response.id) {
                window.open(response.archivo, "_blank");
            }
        }
        else if (response.estado == FALLO){
            dispatch(setLoader(false));
            NotificationManager.error(response.motivo, 'Error', 5000);
        }
    }).catch(() => {
        NotificationManager.error('Error en la Descarga del Reporte', 'Error', 5000);
        dispatch(setLoader(false));
    }).finally(() => {
    });
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch(setEmpresas(constants.SET_EMPRESAS_FLOTANTES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const changeEmpresa = empresa => dispatch => {
    dispatch({type:constants.SET_SEARCH_EMPRESA, empresa});
}

const changeCliente = (value) => (dispatch) => {
    dispatch(setCliente(constants.SET_CLIENTE, value));
};

const changeLoadOptionsDespacho = () => (dispatch, getStore) => {
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
};

const changeFechaInicioOV = (value) => (dispatch) => {
    dispatch(setFechaIOV(value));
};

const changeFechaFinOV = (value) => (dispatch) => {
    dispatch(setFechaFOV(value));
};

const changeClienteFlotantes = (value) => (dispatch) => {
    dispatch(setClienteFlotantes(constants.SET_CLIENTE_FLOTANTES, value));
};

const changeFechaInicioFlotantes = (value) => (dispatch) => {
    dispatch(setFechaIFlotantes(value));
};

const changeFechaFinFlotantes = (value) => (dispatch) => {
    dispatch(setFechaFFlotantes(value));
};

const changeClienteOV = (value) => (dispatch) => {
    dispatch(setClienteOV(constants.SET_CLIENTE_OV, value));
};

const changeDespachoOV = (value) => (dispatch) => {
    console.log(value.cliente_orden.id)
    dispatch(setDespachoOV(constants.SET_DESPACHO_OV, value));
    dispatch(setClienteOV(constants.SET_CLIENTE_OV, value.cliente_orden));
};

export const actions = {
    descargaArchivoDespachosTotalesEP,
    descargaArchivoBancosFlotantesEP,
    descargaArchivoDespachosOrdenVentaEP,
    descarga_estadoEP,
    cicloVerificacion,
    changeCliente,
    changeFechaInicio,
    changeFechaFin,
    changeFechaInicioOV,
    changeFechaFinOV,
    cicloVerificacionFlotantes,
    changeClienteFlotantes,
    changeFechaInicioFlotantes,
    changeFechaFinFlotantes,
    changeClienteOV,
    changeDespachoOV,
    changeLoadOptionsDespacho,
    changeEmpresa,
    setLoadOptionsDespachos,
    selectEmpresas,
}


export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.SET_CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente
        };
    },
    [constants.SET_LOAD_OPTIONS_DESPACHOS]: (state, { loadOptionsDespachos }) => {
        return {
            ...state,
            loadOptionsDespachos
        };
    },
    [constants.SET_EMPRESAS_FLOTANTES]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
    [constants.SET_FECHA_I_OV]: (state, { fecha_inicio_ov }) => {
        return {
            ...state,
            fecha_inicio_ov
        };
    },
    [constants.SET_FECHA_F_OV]: (state, { fecha_fin_ov }) => {
        return {
            ...state,
            fecha_fin_ov
        };
    },
    [constants.SET_CLIENTE_FLOTANTES]: (state, { cliente_flotantes }) => {
        return {
            ...state,
            cliente_flotantes
        };
    },
    [constants.SET_FECHA_I_FLOTANTES]: (state, { fecha_inicio_flotantes}) => {
        return {
            ...state,
            fecha_inicio_flotantes
        };
    },
    [constants.SET_FECHA_F_FLOTANTES]: (state, { fecha_fin_flotantes }) => {
        return {
            ...state,
            fecha_fin_flotantes
        };
    },
    [constants.SET_CLIENTE_OV]: (state, { cliente_ov }) => {
        return {
            ...state,
            cliente_ov
        };
    },
    [constants.SET_DESPACHO_OV]: (state, { despacho_ov }) => {
        return {
            ...state,
            despacho_ov
        };
    },
    [constants.SET_SEARCH_EMPRESA]: (state, {empresa}) => {
        return {
            ...state,
            empresa
        }
    }
}


export const initialState = {
    loader:false,
    cliente: null,
    loadOptionsDespachos: [],
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
    fecha_inicio_ov: moment(`${moment().year()}-1-1`),
    fecha_fin_ov: moment(),
    cliente_flotantes: null,
    fecha_inicio_flotantes: moment(`${moment().year()}-1-1`),
    fecha_fin_flotantes: moment(),
    cliente_ov: null,
    despacho_ov: null,
    empresas: [],
    empresa: null
}

export default handleActions(reducers, initialState);
