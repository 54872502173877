import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import ItemTree from './components/ItemTree';
import Modal from 'react-responsive-modal';
import LoadMask from "../../../Utils/LoadMask/LoadMask";

import TipoCategoriaForm from './categoriaForm';
import Swal from 'sweetalert2';

export default class TipoCategoria extends Component {
    state = {
        opcion: 0,
        item: null,
        initialValues: {},
        titulo: "SUBCATEGORÍA"
    }
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    seleccionarItem = (item, opcion) => {
        this.setState({
            item: item,
            opcion: opcion
        });
        this.setState({titulo: 'SUBCATEGORIA'});
        if(opcion == 1){
            if(item)
                this.setState({initialValues: {parent: item.value}})
        }else if(opcion == 2) {
            this.setState({initialValues: {...item}})
        }else {
            this.setState({initialValues: {...item}})
            Swal.fire({
                title: '¿Desea eliminar la categoria?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, borrar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(item.value);
                }
            });
        }
    }
    onSubmit = (data) => {
        if(this.state.opcion == 1){
            //Revisa si es para crear
            this.props.crear(data);
        }
        else if(this.state.opcion == 2){
            //Revisa si es una edición
            this.props.editar(this.state.item.value, data)
        }else {
            // revisa si es un delete
        }
    }
    cerrarModal = () => {
        this.props.setOpenModal(false);
    }
    renderModal= ()=>{
        const { data, loader_formulario, open_modal } = this.props;
        const { opcion } = this.state; 
        return(
            <Modal open={open_modal} onClose={(e) => {
                this.props.setOpenModal(false)
            }} center>
                <div className="mt-5">
                    {
                        (opcion === 1) && (
                            <Fragment>
                                <h2 className="text-center text-primary">AGREGAR CATEGORÍA</h2>
                                {
                                    (this.state.item) && (
                                        <h3 className="text-center text-primary">DE: {this.state.item.label}</h3>
                                    )
                                }
                            </Fragment>
                        )
                    }
                    {
                        (opcion === 2) && (
                            <Fragment>
                                <h2 className="text-center text-primary">EDITAR CATEGORÍA</h2>
                            </Fragment>
                        )
                    }
                    {
                        (opcion === 3) && (
                            <Fragment>
                                <h2 className="text-center text-primary">AGREGAR CATEGORÍA</h2>
                                <h3 className="text-center text-primary">DE: {this.state.item.label}</h3>
                            </Fragment>
                        )
                    }
                    <LoadMask loading={loader_formulario} light type={"TailSpin"}>
                        <TipoCategoriaForm
                            cerrarModal={this.cerrarModal}
                            initialValues={this.state.initialValues}
                            onSubmit={this.onSubmit} />
                    </LoadMask>

                </div>

            </Modal>
        )
    }

    render() {
        const { data, loader, open_modal, permisos } = this.props;
        if(permisos["categiagastos"] === false) {
            if(!!permisos[`ver_categiagastos`] === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                titulo="CATEGORÍA DE GASTOS">
                <LoadMask loading={loader} light type={"TailSpin"}>

                    {permisos.crear_categiagastos && <button type="button"
                    onClick={(e) => {
                        e.preventDefault()
                        this.seleccionarItem(null, 1);
                        this.setState({titulo: 'CATEGORIA'});
                        this.props.setOpenModal(true);
                    }}
                    className="btn btn-azul-claro align-self-center m-3">Crear categoria</button>}
                    {this.renderModal()}
                    <div className="col-md-6 contendor-arbol fondo-arbol">
                    {
                            data.map(item => {
                                return(
                                    <ItemTree
                                        setOpenModal={this.props.setOpenModal}
                                        seleccionarItem={this.seleccionarItem}
                                        item={item}
                                        permisos={permisos}
                                        abrir_categoria={this.props.abrir_categoria}
                                        />
                                )
                            })
                        }
                    </div>

                </LoadMask>
            </CardSimple>
        );
    }
}
