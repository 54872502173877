import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/PeticionVenta/peticionventa';
import PeticionVForm from './peticionesventa';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import {cloneDeep} from 'lodash'

const mstp = state => {
    return {
        ...state.peticionV,
        role: state.login.me.permisos.ordenventa,
        permisos: state.login.me.permisos,
        id_user: state.login.me.id
    }
};

const mdtp = {
    ...actions,
    setDias_de_credito:( value) => dispatch => dispatch(change('PeticionVentaForm', 'dias_al_credito', value)),
    setNombre_nit:(index, value) => dispatch => dispatch(change('PeticionVentaForm', `Ordennits[${index}].nombre`, value)),
    setNits:(value) => dispatch => {
        let Nits = []
        value.forEach( (item, index) => {
            Nits[index] = {
                propietario: {id:item.id,value:item.id, label:item.Nit},
                nombre: item.nombre,
                tipo: item.tipo,
            };
        })
        dispatch(change('PeticionVentaForm', `Ordennits`, Nits))},
};

export default connect(mstp, mdtp)(PeticionVForm)
