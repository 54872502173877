import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'bodega' ;
let form = 'TipoCategoriaForm';
let dirGrid = '/admin_tipocategoria';

export const constants ={
    LOADER: 'LOADER_EB',
    DATA: 'DATA_EB',
    ITEM: 'ITEM_EB',
    PAGE: `PAGE_EB`,
    PAGE2: `PAGE2_EB`,
    PAGE3: `PAGE3_EB`,
    ORDERING: `ORDERING_EB`,
    SEARCH: `SEARCH_EB`,
    OPEN_MODAL: 'OPENMODAL_EB',
    LOADER_FORMULARIO: 'LOADER_FORMULARIO_EB',
    SET_PENDIENTES:'SET_PENDIENTES',
    SET_PRODUCTOSDS:'SET_PRODUCTOSDS',
    HISTORIA_MOVIMIENTOS: 'SET_HISTORIA_EB',
    HISTORIA_MOVIMIENTOS_DESPACHOS: 'SET_HISTORIA_DESPACHOS_EB',
    DESPACHOS_PENDIENTES: 'DESPACHOS_PENDIENTES_EB',
    SET_TIPOS: 'PRODUCTO_TIPOS_EB',
    SET_SEARCH_TIPO: 'PRODUCTO_SET_SEARCH_TIPO_EB',
    SET_USUARIOS: 'USUARIOS_EB',
    SET_SEARCH_USUARIO: 'USUARIO_SET_EB',
    SET_DATE_START: 'SET_DATE_START_EB',
    SET_DATE_END: 'SET_DATE_END_EB',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})
const setLoaderFormulario = loader_formulario =>({
    type:constants.LOADER_FORMULARIO,
    loader_formulario
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setPage2 = page2 => ({
    type: constants.PAGE2,
    page2,
});

const setPage3 = page3 => ({
    type: constants.PAGE3,
    page3,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setDataSelect = (type, data) =>({
    type,
    data
});

const listar = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().estado_bodega;
    const params = { page };
    params.bodega = bodega
    params.search = resource.search;
    dispatch(setLoader(true));
    dispatch(getHistoriaMovimientos(1, bodega))
    api.get(`inventario/getInventario/${bodega}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
        dispatch(getHistoriaMovimientos(1, bodega))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const getHistoria = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().estado_bodega;
    const params = { page };
    params.bodega = bodega
    params.search = resource.search;
    dispatch(setLoader(true));

    api.get(`despachos/getHistorialBodega`, params).then((response) => {
        dispatch(setData(constants.HISTORIA_MOVIMIENTOS ,response));
        dispatch(setPage2(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getHistoriaMovimientos = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().estado_bodega;
    const params = { page };
    params.bodega = bodega
    params.search = resource.search;
    if(resource.usuario != null){
        params.usuario = resource.usuario.value
    }
    if(resource.tipo != null){
        params.tipo = resource.tipo.value
    }
    if(resource.dateStart != null && resource.dateEnd != null){
        params.fecha_inicial = resource.dateStart.format("YYYY-MM-DD");
        params.fecha_final = resource.dateEnd.format("YYYY-MM-DD");
    }
    dispatch(setLoader(true));

    api.get(`despachos/getHistorialMovimientosBodega`, params).then((response) => {
        dispatch(setData(constants.HISTORIA_MOVIMIENTOS_DESPACHOS ,response));
        dispatch(setPage3(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        console.log('Response, ', response);
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDespachosPendientes = id => (dispatch, getStore) => {
    // let params = {bodega_destino: id, flujo_despacho: 10, tipo_despacho:10}
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    const params = {flujo_despacho: 10, tipo_despacho:10, bodega: id};
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = me.id
        params.select=false
    }
    dispatch(setLoader(true));
    api.get(`despachos/getDespachosPendientes`, params).then((response) => {
        dispatch(setData(constants.DESPACHOS_PENDIENTES,response.data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const crear = data => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(listar());

    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const reajustar_inventario = (data, bodega) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`despachos`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(listar(1, bodega));
        dispatch(getHistoriaMovimientos(1, bodega))
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());

    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
};

const searchChange = (search, bodega, bandera) => (dispatch) => {
    if (bandera == "INVENTARIO"){
        dispatch(setSearch(search));
        dispatch(listar(1, bodega));
    }
    else if (bandera =='HISTORIAL'){
        dispatch(setSearch(search));
        dispatch(getHistoria(1, bodega));
    }
    else{
        dispatch(setSearch(search));
        dispatch(getHistoriaMovimientos(1, bodega));
    }
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}

const listDS = (id_bodega) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id_bodega}
    api.get(`despachos/listds`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const setProductoDespacho = (parametros) => (dispatch) =>{
    dispatch(setLoader(true));
    console.log('parametros', parametros)
    let params = {...parametros}
    api.get(`despachos/getListadepacho`, params).then((res)=>{
        dispatch(setData(constants.SET_PRODUCTOSDS, res))
        let detalle = []
        res.forEach( (item, index) => {
            detalle[index] = {
                producto: item,
                despachar: item.es_bodega? item.cantidad_actual: 0
            };
        })
        dispatch(change('DespachoBodegaForm', `Despachos`, detalle))

    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const DespacharProducto = (data, orden, bodega) => (dispatch) => {
    dispatch(setLoader(true));
    data.orden = orden;
    data.bodega = bodega;
    api.post(`despachos/createdespachobodega`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset('DespachoBodegaForm'));
        dispatch(getHistoriaMovimientos(1, bodega))
        if (!!dirGrid)
            dispatch(push(`/estado_bodega/${bodega}`));
            // dispatch(goBack())
    }).catch((error) => {
        let mensaje = 'Error en la creación del despacho';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const DespachoEntreBodega = (data, bodega) => (dispatch) => {
    dispatch(setLoader(true));
    data.bodega = bodega;
    api.post(`despachos/despachoEntreBodega`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push(`/estado_bodega/${bodega}`));
        dispatch(getHistoriaMovimientos(1, bodega))
            // dispatch(goBack())
    }).catch((error) => {
        let mensaje = 'Error en la creación del despacho';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {

            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeTipo = (tipo, id_bodega) => dispatch => {
    dispatch({type:constants.SET_SEARCH_TIPO, tipo});
    dispatch(getHistoriaMovimientos(1, id_bodega));
}

const selectTipos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipoproducto/selecttipos').then((res)=>{
        dispatch(setDataSelect(constants.SET_TIPOS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const changeUsuario = (usuario, id_bodega) => dispatch => {
    dispatch({type:constants.SET_SEARCH_USUARIO, usuario});
    dispatch(getHistoriaMovimientos(1, id_bodega));
}

const selectUsuarios = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('user/selectusuarios').then((res)=>{
        dispatch(setDataSelect(constants.SET_USUARIOS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setDateStart = (value, id_bodega) => (dispatch) => {
    dispatch({type: constants.SET_DATE_START, dateStart: value})
    dispatch(getHistoriaMovimientos(1, id_bodega));
}

const setDateEnd = (value, id_bodega) => (dispatch) => {
    dispatch({type: constants.SET_DATE_END, dateEnd: value})
    dispatch(getHistoriaMovimientos(1, id_bodega));
}


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModal,
    reajustar_inventario,
    listDS,
    setProductoDespacho,
    DespacharProducto,
    getHistoria,
    getDespachosPendientes,
    DespachoEntreBodega,
    getHistoriaMovimientos,
    changeTipo,
    selectTipos,
    changeUsuario,
    selectUsuarios,
    setDateStart,
    setDateEnd
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE2]: (state, { page2 }) => {
        return {
            ...state,
            page2,
        };
    },
    [constants.PAGE3]: (state, { page3 }) => {
        return {
            ...state,
            page3,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.SET_PRODUCTOSDS]:(state,{ data })=>{
        return{
            ...state,
            productosDS: data,
        }
    },
    [constants.HISTORIA_MOVIMIENTOS]:(state,{ data })=>{
        return{
            ...state,
            historia_movimientos: data,
        }
    },
    [constants.HISTORIA_MOVIMIENTOS_DESPACHOS]:(state,{ data })=>{
        return{
            ...state,
            historia_movimientos_despachos: data,
        }
    },
    [constants.DESPACHOS_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            despachos_pendientes: data,
        }
    },
    [constants.SET_TIPOS]:(state,{ data })=>{
        return{
            ...state,
            tipos: data.tipo,
        }
    },
    [constants.SET_SEARCH_TIPO]: (state, {tipo}) => {
        return {
            ...state,
            tipo
        }
    },
    [constants.SET_USUARIOS]:(state,{ data })=>{
        return{
            ...state,
            usuarios: data.usuario,
        }
    },
    [constants.SET_SEARCH_USUARIO]: (state, {usuario}) => {
        return {
            ...state,
            usuario
        }
    },
    [constants.SET_DATE_START]: (state, { dateStart }) => {
        return {
            ...state,
            dateStart
        }
    },
    [constants.SET_DATE_END]: (state, { dateEnd }) => {
        return {
            ...state,
            dateEnd
        }
    },
};


export const initialState ={
    loader:false,
    data: [],
    item: {nombre: ''},
    page: 1,
    page2: 1,
    page3: 1,
    ordering: '',
    search: '',
    open_modal: false,
    loader_formulario: false,
    pendientes:[],
    productosDS:[],
    historia_movimientos:{
        results: [],
        count: 0
    },
    historia_movimientos_despachos:{
        results: [],
        count: 0
    },
    despachos_pendientes: [],
    tipo: null,
    tipos: [],
    usuario: null,
    usuarios: [],
    dateStart: null,
    dateEnd: null,
};

export default handleActions(reducers, initialState);
