import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {RenderDateTimeAll, RenderDateTime, RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import LoadMask from "Utils/LoadMask/LoadMask";
//import OCpreview from './previewOC/OCpreview';


export default class detalleVales extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        let id = this.props.match.params.id
        this.props.leer(id);
    }

    cerrar = (event) => {
        this.props.cerrar(event.target.value);
    }

    render() {
        const { item, data, loader, listar: onPageChange, onSortChange, eliminar,cuentas, tipo_gastos } = this.props;
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>

                <div className="Container bg-white">
                { !!item && item.vehiculo &&(
                    <div className="m-t p-t fnt-size">
                        <div className="row m-none" style={{paddingTop:"15px"}}>
                            <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                <h3 className="t-primary m-none"> { item ? `No. ${item.id}` :""}</h3>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Estado: </span> <br/>
                                <span className="m-none t-musgo semibold">{`${item.cerrado ? "Cerrado" : "Abierto"}`}</span>
                            </div>
                        </div>
                        <br/>

                        <div className="col-12">
                            <span className="m-none t-primary bold"> Datos del vale </span>
                        </div>
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                        <div className="row m-none">
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Placa: </span> <br/>
                                <span className="m-none t-musgo semibold">{`${item.vehiculo.placa}`}</span>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Modelo: </span> <br/>
                                <span className="m-none t-musgo semibold">{`${item.vehiculo.modelo}`}</span>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Marca: </span> <br/>
                                <span className="m-none t-musgo semibold">{`${item.vehiculo.marca}`}</span>
                            </div>
                        </div>
                        <br/>
                        <div className="col-12">
                            <span className="m-none t-primary bold"> Gasolina </span>
                        </div>
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                        <div className="row m-none mb-5">
                            <div className="col-lg-3 col-md-4 col-6">
                                <span className="m-none t-mostaza semibold">Galones: </span> <br/>
                                <span className="m-none t-musgo semibold">{item.galones_gasolina ? `${item.galones_gasolina} galones` : '' }</span>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <span className="m-none t-mostaza semibold">Precio Galon: </span> <br/>
                                <span className="m-none t-musgo semibold">{item.precio_galon ? <RenderMoneda monto={item.precio_galon} simbolo={"Q"} /> : ''}</span>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <span className="m-none t-mostaza semibold">Tipo gasolina: </span> <br/>
                                <span className="m-none t-musgo semibold"> {item.tipo_gasolina ? 'Diesel' : ''} </span>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">km Recorrido: </span> <br/>
                                <span className="m-none t-musgo semibold">{ item.km_vehiculo ? `${item.km_vehiculo} km` : ""}</span>
                            </div>
                        </div>
                        <br/>
                        {item.estado == false && (
                            <div>
                                <div className="col-12">
                                    <span className="m-none t-primary bold"> Anulación </span>
                                </div>
                                <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                                <div className="row m-none mb-5">
                                    <div className="col-lg-3 col-md-4 col-6">
                                        <span className="m-none t-mostaza semibold">Fecha: </span> <br/>
                                        <span className="m-none t-musgo semibold">{item.fecha_anulacion}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-6">
                                        <span className="m-none t-mostaza semibold">descripcion: </span> <br/>
                                        <span className="m-none t-musgo semibold">{ item.descripcionAnulacion }</span>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                        <div className="col-md-12 m-0 p-0 d-flex justify-content-end align-items-center">
                            <div className="col-md-10 m-0 p-0 mr-5 inline-headers text-end d-flex justify-content-end">
                                <span className="t-azul-claro font-weight-bold h5 mr-3"  htmlFor="descripcion">TOTAL </span>
                                <h3 className="t-mostaza"  htmlFor="descripcion">{
                                <RenderMoneda monto={item.total} simbolo={"Q"} />
                                }</h3>
                            </div>
                        </div>
                        {item.desglose_gastos &&
                                <div>
                                    <br />
                                    <div className=" fnt-size">
                                        <h4 className="t-primary">Desglose de gastos</h4>
                                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                                    
                                        <div className="row m-none">
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Categoría de gasto: </span><br />
                                                <span className="m-none t-musgo semibold">{item.desglose_gastos.tipo_gasto ? item.desglose_gastos.tipo_gasto.title : null}</span>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Fecha: </span><br />
                                                <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.desglose_gastos.fecha} ></RenderDateTime></span>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Monto: </span><br />
                                                <span className="m-none t-musgo semibold"><RenderMoneda monto={item.desglose_gastos.monto} /*simbolo={getSimbolo(this.props.moneda)} */ /></span>
                                            </div>
                                        </div>
                                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                                        <div className="row m-none">
                                            <div className="col-12">
                                                <span className="m-none t-mostaza semibold">Descripción: </span><br />
                                                <span className="m-none t-musgo semibold">{`${item.desglose_gastos.descripcion}`}</span>
                                            </div>
                                        </div>
                                        <div className="row m-none">
                                            <div className="col-12 color-header-blanco">
                                                <hr />
                                                <table className="table table-sm table-responsive-sm m-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="t-mostaza" >Empresa</th>
                                                            <th className="t-mostaza text-right" style={{ width: "20%" }}>Cantidad</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            item.desglose_gastos && item.desglose_gastos.afectados && (
                                                                <Fragment>
                                                                    {item.desglose_gastos.afectados.map((empresa, index) => {
                                                                        if(empresa.tipo == 10){
                                                                            return (
                                                                                <tr key={index} >
                                                                                    <td className="sin-borde-top" style={{ width: "48px" }}>
                                                                                        <span>{empresa.empresa_nombre}</span>
                                                                                    </td>
                                                                                    <td className="sin-borde-top text-right" style={{ width: "20%" }}>
                                                                                        <RenderMoneda monto={empresa.debe} /* simbolo={getsimbolo(empresa.transaccion.moneda)} */ />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                        }                                                                       
                                                                    )}
                                                                </Fragment>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className="col-md-12 m-0 p-0 mt-4 inline-headers text-center text-md-right">
                                                    <span className="t-mostaza h5 mr-3" htmlFor="descripcion">Total:  </span>
                                                    <h3 className=" t-primary" htmlFor="descripcion">{
                                                        <RenderMoneda monto={item.desglose_gastos.monto} /* simbolo={getsimbolo(item.desglose_gastos.moneda)} */ />
                                                    }</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        <br/><br/>
                        {!item.cerrado && item.estado &&
                            <div className="row m-none mb-5">
                                <div className="col-lg-12 d-flex justify-content-end mr-5">
                                    <a target="_blank" href={item.documento} className="btn btn-azul-claro align-self-center" style={{color: "white"}}> vale de gasolina </a>
                                </div>
                            </div>
                        }
                        <br/>
                    </div>
                )
                }
                </div>

            </LoadMask>
        )
    }
}
