import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reporteCobros';
import ReporteCobros from './ReporteCobros';

const mstp = state => {
    return {
        ...state.reporteCobros,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteCobros)
