import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../../Utils/Cards/cardFormulario';
//import DesgloseForm from './DesgloseForm';
import ItinerarioForm from './DesgloseForm';
import moment from 'moment';
import Modal from 'react-responsive-modal';


class DesgloseGastos extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR GASTO',
        modal: false,
        values: undefined,
        itinerario: {}
    }
    componentWillMount(){
        this.props.resetFormulario();
        this.props.getTipoGastos();
        this.props.selectEmpresas();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leerItinerario(this.props.match.params.id);
        }
    }

    onSubmit = () =>{
        const {editarDesdeItinerario, crearDesdeItinerario} =this.props
        //let id = this.props.match.params.id
        let valores_formulario = _.cloneDeep(this.state.values)
        valores_formulario.fecha = moment(valores_formulario.fecha).format('YYYY-MM-DD')
        valores_formulario.idItinerario = this.props.match.params.id;
        //this.state.editar? editar(id,valores_formulario) : crear(valores_formulario);
        this.state.editar ? editarDesdeItinerario(valores_formulario) : crearDesdeItinerario(valores_formulario)
    }

    openModal = (values) => {
        this.setState({ modal: !this.state.modal, values: values});
    }

    renderModalCerrar = () => {
        const { modal } = this.state;
        return (
            <Modal open={modal} onClose={ this.openModal } center>
                <div className="mt-5">
                    <br/>
                    <h2 className="text-center text-primary">DESEA DAR POR CERRADO <br/> EL ITINERARIO DE RUTA</h2>
                    <br/>
                </div>
                <div className="d-flex  col-12 justify-content-around mt-5">
                    <button className="btn btn-secondary" onClick={this.openModal}> CANCELAR </button>
                    <button className="btn btn-primary" onClick={()=>{ this.onSubmit(); this.openModal() }}>
                        ACEPTAR
                    </button>
                </div>

            </Modal>
        )
    }


    render() {

        const { loader, empresas, select_cuentas, cambioDescuento } = this.props;

        return (
            <div className="Container bg-white">
                {this.renderModalCerrar()}
                <LoadMask loading={loader} light type={"TailSpin"}>
                        <div className="m-t p-t fnt-size">
                            <div className="row m-none" style={{paddingTop:"15px"}}>
                            <br/>
                            <div className="col-12 col-md-10 offset-md-1">
                                <ItinerarioForm
                                empresas={empresas}
                                select_cuentas={select_cuentas}
                                itinerario = {this.props.itemItinerario}
                                initialValues={{
                                    desglose:[{}],
                                    tipo_cuenta:"true",
                                    fecha: new Date(),
                                    pendiente_liquidar:false
                                    }}
                                    editar={this.state.editar}
                                    cambioDescuento={cambioDescuento}
                                    tipo_gastos={this.props.tipo_gastos}
                                    tipo_gasto = {this.props.item}
                                    onSubmit={this.openModal}/>
                                </div>
                            </div>
                        </div>
                </LoadMask>
            </div>
        );
    }
}
export default DesgloseGastos;
