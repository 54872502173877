import React, { Fragment, Component } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderFieldCheck } from '../../../Utils/renderField';
import { valores_tipo_transacciones } from '../../../../../utility/constants';


class ItemArray extends Component {

    render() {
        const { index, item, simbolo, meProfile, tipo_documento } = this.props;

        return (
            <tr key={index} style={{ height: "68px" }}>
                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                    {
                        (meProfile.rol_name == "Piloto" || meProfile.rol_name == "PILOTO" || meProfile.rol_name == "piloto" || tipo_documento == valores_tipo_transacciones.tarjeta || tipo_documento == valores_tipo_transacciones.deposito) ? (
                            <Field
                                name={`${item}.id`}
                                component={renderFieldCheck}
                                onlyRead={true}
                                valorRead={true}
                                top={{ top: "67px", position: "inherit" }}
                            />
                        ) : (
                            <Field
                                name={`${item}.id`}
                                component={renderFieldCheck}
                                top={{ top: "67px", position: "inherit" }}
                            />

                        )
                    }
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${item}.fecha`}
                        type="text"
                        component={renderField}
                        readOnly={true}
                        top={{ top: "67px", position: "inherit" }}
                        clase="inputRead"
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${item}.no_documento`}
                        type="text"
                        component={renderField}
                        readOnly={true}
                        top={{ top: "67px", position: "inherit" }}
                        clase="inputRead"
                    />
                </td>
                {
                    // (this.props.field.facturas_asociadas && this.props.field.facturas_asociadas.length > 0) && (
                        <td className="sin-borde-top">
                            <Field
                                name={`${item}.facturas_asociadas_string`}
                                type="text"
                                component={renderField}
                                readOnly={true}
                                top={{ top: "67px", position: "inherit" }}
                                clase="inputRead"
                            />
                        </td>
                    // )

                }
                <td className="sin-borde-top" align="center">
                    <Field
                        name={`${item}.monto`}
                        type="text"
                        component={renderField}
                        readOnly={true}
                        top={{ top: "67px", position: "inherit" }}
                        clase="inputRead"
                    />
                </td>
            </tr>
        );
    }
}


const renderProductos = ({ fields, meta: { error, submitFailed }, simbolo, meProfile, tipo_documento }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead className="bg-table-head">
                        <tr className="bg-primary">
                            <th></th>
                            <th>FECHA</th>
                            <th>NO. DOCUMENTO</th>
                            {
                                (true) && (
                                    <th>NO. FACTURA</th>
                                )

                            }
                            <th> MONTO </th>
                        </tr>
                    </thead>
                    <tbody className="bg-table-body">
                        {fields.map((item, index) => {
                            return (
                                <ItemArray
                                    index={index}
                                    key={index}
                                    item={item}
                                    simbolo={simbolo}
                                    eliminar={() => {
                                        fields.remove(index)
                                    }}
                                    field={fields ? fields.get(index) : null}
                                    meProfile={meProfile}
                                    tipo_documento={tipo_documento}
                                />
                            );

                        })}
                    </tbody>
                </table>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>
        }
    </div>
)


let ChequesForm = (props) => {
    const { data, handleSubmit, } = props;
    return (
        <form name="LiquidarChequesForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="col-12 m-0 p-0 d-flex justify-content-center">
                <div className="col-md-12 row m-0 p-0">
                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback col-12 `}>
                            <FieldArray
                                name="cheques"
                                component={renderProductos}
                                meProfile={props.meProfile}
                                tipo_documento={props.tipo_documento}
                            />
                            <br />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <button type="button" onClick={props.setCloseModal} className="btn btn-secondary  align-self-cente">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">ENVIAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'LiquidarChequesForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        return errors;
    },
})(ChequesForm);

