import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";


export default class ProductosGrid extends Component{
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    render(){
        const { detalle_orden,moneda, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={{count:10,results:detalle_orden}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                    <TableHeaderColumn
                        dataField="producto"
                        dataSort
                        dataFormat = {(cell, row) => {
                            return (
                                <React.Fragment>
                                    {cell.nombre}
                                    {(row.tipo_devolucion === 40 || row.tipo_devolucion === '40') &&
                                        <React.Fragment>
                                            <div style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                                                REEMPLAZO
                                            </div>
                                            {row.producto_cambio.nombre}
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )
                        }}
                        isKey
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_devolucion_label"
                        dataSort
                    >
                        TIPO DEVOLUCION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio_unitario"
                        dataSort
                        dataFormat ={(cell, row) => {
                            return(
                                <React.Fragment>
                                    <RenderMoneda monto={cell? cell: 0} simbolo={'Q.'} />
                                    {(row.tipo_devolucion === 40 || row.tipo_devolucion === '40') &&
                                        <React.Fragment>
                                            <br/>
                                            <br/>
                                            <RenderMoneda monto={row.producto_cambio ? row.producto_cambio.precio_venta: 0} simbolo={'Q.'} />
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )
                        }}
                    >
                        COSTO DEL PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataSort
                        dataFormat={(cell, row) => {
                            return(
                                <React.Fragment>
                                    {cell}
                                    {(row.tipo_devolucion === 40 || row.tipo_devolucion === '40') &&
                                        <React.Fragment>
                                            <br/>
                                            <br/>
                                            {row.producto_cambio.cantidad_a_cambiar}
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )
                        }}
                    >
                        CANTIDAD
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="subtotal_despachado"
                        dataSort
                        dataFormat={(cell,row) => {
                            let subtotal_reemplazo = 0
                            if(row.producto_cambio.nombre){
                                const cantidad = Number(row.producto_cambio.cantidad_a_cambiar),
                                    precio = Number(row.producto_cambio.precio_venta)
                                subtotal_reemplazo = (cantidad * precio).toFixed(2)
                            }
                            return(
                                <React.Fragment>
                                    <RenderMoneda monto={cell} simbolo={'Q.'} />
                                    {(row.tipo_devolucion === 40 || row.tipo_devolucion === '40') &&
                                        <React.Fragment>
                                            <br/>
                                            <br/>
                                            <RenderMoneda monto={subtotal_reemplazo} simbolo={'Q.'} />
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                )
                        }}
                    >
                        SUBTOTAL
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}
