import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Devolucion/devolucion';
import DevolucionCrear from './DevolucionCrear';

const mstp = state => {
    return {
        ...state.devolucion,
        permisos: state.login.me.permisos,
        id_user: state.login.me.id
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DevolucionCrear)
