import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";

import BonificacionForm from './bonificacionForm';

class Bonificacion extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        accion: 'NUEVA',
        titulo: 'BONIFICACION',
        data:{}
    }
    componentWillMount(){
        this.props.getTipoGastos();
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                accion: 'EDITAR',
                titulo:'BONIFICACIÓN O DESCUENTO'
            });
        }
    }
    onSubmit = (valores) =>{
        let values = _.cloneDeep(valores);
        if (values.es_bonificacion == "true"){ values.es_bonificacion =true}
        else{values.es_bonificacion = false}
        values.origen_pago = valores.origen_pago;
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        this.state.editar? editar(id,values) : crear(values);
    }
    onChangeTitulo = (value) => {
        if (value == 'true'){
            this.setState({
                titulo: 'BONIFICACION',
                accion: this.props.match.params.id ? "EDITAR":"NUEVA"
            })
        }else{
            this.setState({
                titulo: 'DESCUENTO',
                accion: this.props.match.params.id ? "EDITAR":"NUEVO"
            })
        }
    }

    render() {
        const { loader, item } = this.props;
        return (
            <div className="Container">
               <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 card card-small m-t">
                            <div className="card-body">
                                <h3 className="t-primary card-title">{`${this.state.accion} ${this.state.titulo}`}</h3>
                                <div className="p-0 px-3 pt-3">
                                    <LoadMask loading={loader} light type={"TailSpin"}>
                                        <BonificacionForm
                                            onSubmit={this.onSubmit}
                                            initialValues={{ es_bonificacion: 'true', monto: 0, es_anual:false}}
                                            onChangeTitulo={this.onChangeTitulo}
                                            tipo_gastos={this.props.tipo_gastos}
                                            setFecha_anual={this.props.setFecha_anual}
                                        />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bonificacion;
