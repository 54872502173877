import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/Ausencias/ausensias';
import AusenciasCrear from './AusenciasCrear';

const mstp = state => {
    return {...state.ausencias ,permisos: state.login.me.permisos, user: state.login.me.id}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AusenciasCrear)


