import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/PagosItinerarios/pagosItinerarios';
import PagosItinerariosGrid from './PagosItinerariosGrid';

const mstp = state => {
    return {
        ...state.pagosItinerarios,
        permisos: state.login.me.permisos,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PagosItinerariosGrid)
