import React, { Component, Fragment } from 'react'
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../../Utils/Grid/StandardActions';
import CardSimple from '../../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda, RenderNombreSelect} from '../../../../Utils/renderField/renderTableField';
import FiltrosOrdenVenta from './filtrosOrdeVenta';
import { Field } from 'redux-form';
import renderFieldCheck from '../../../../Utils/renderField'
import ImageVer from '../../../../../../../assets/img/icons/chequeAefectivo.png';
import PreciosInternosForm from './PreciosInternosForm';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';

export default class PreciosInternosGrid extends Component {
    componentWillMount() {
        const { listar, page, selectEmpresas2, selectTipos } = this.props;
        selectEmpresas2()
        selectTipos()
        listar(page);
    }
    botonCambioIndividual = (row) => {
        return (
            <a className="px-1" style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.props.setOpenModal(true)
                    this.props.setProductoSeleccionado(row)
                    this.props.setDatosModal(row)
                }}
            >
                <img className="action-img-ver" title="Cambio manual" src={ImageVer} alt="Cambio manual" />
            </a>
        )
    }
    renderModalCambioIndividual = () => {
        return(
            <Fragment>
                <div className="mt-0">
                    <h3 className="text-center text-primary">CAMBIAR PRECIO</h3>
                </div>
                <PreciosInternosForm
                    cerrarModal={this.props.setOpenModal}
                    texto_boton_movimiento={"GUARDAR"}
                    titulo_modal="TIPO DE CAMBIO"
                    handleSubmit={(e) => {
                        e.preventDefault();
                        this.props.guardarCambio()
                    }}
                    fecha_actualizacion = {this.props.fecha_actualizacion}
                    precio_interno_anterior = {this.props.precio_interno_anterior}
                />
            </Fragment>
        );
    }
    botonCheck = (row) =>{
        this.props.setProductosSeleccionados(row)
    }
    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos, changeEmpresa, changeTipo, open_modal, setOpenModal} = this.props;
            if(permisos.precios_internos === false) {
                return <Redirect to={`/`} />
            }
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    ruta="producto/crear"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={permisos.productos}
                    estatefilterBar={true}
                    filterbar={
                        <FiltrosOrdenVenta
                            changeEmpresa={changeEmpresa}
                            empresas={this.props.empresas}
                            changeTipo={changeTipo}
                            tipos={this.props.tipos}
                        />

                    }
                />}
                titulo="Reporte de Precios Internos">
                    <Modal
                        open={open_modal}
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                        center
                    >
                        {
                            this.renderModalCambioIndividual()
                        }
                    </Modal>
                    <PreciosInternosForm
                    texto_boton_movimiento={"APLICAR CAMBIO"}
                    titulo_modal="TIPO DE CAMBIO GENERAL"
                    handleSubmit={(e) => {
                        e.preventDefault();
                        this.props.guardarCambio(false)
                    }}
                    es_general={true}
                />
                    <Grid hover striped data={data} size_page={25} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell, row) => {
                                return customStandardActions(cell, {
                                    adicional3: (c, r) => {
                                        return this.botonCambioIndividual(row)
                                    }
                                });
                            }}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="label_unidad_inventario"
                            dataSort
                        >
                            UNIDAD DE MEDIDA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="codigo"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                        >
                            DESCRIPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_producto"
                            dataSort
                            dataFormat = {
                                (cell, row) => (
                                    <RenderNombreSelect cell={cell} />
                                )
                            }
                        >
                            TIPO PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_venta_interna"
                            dataSort
                            dataFormat = {
                                (cell, row)=>(
                                    <RenderMoneda monto={ cell } simbolo={'Q'} />
                                )
                            }
                        >
                            PRECIO INTERNO
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
