import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
    actions
} from '../../../../../redux/modules/itinerarios/itinerarios';
import DetalleItinerario from './detalleItinerario';
import Facturacion from '../Facturas/index';

const mstp = state => {
    return {...state.itinerario, permisos: {...state.login.me.permisos}, misdatos: {...state.itinerario}}
};

const mdtp = {
    ...actions
};

const container = (props) => {
    const { misdatos } = props
    const [state, setState] = React.useState({ vistaFacturacion: false, data: null });

    return (
        state.vistaFacturacion === false ?
            <DetalleItinerario
                {...props}
                mostrarVista={(vistaFacturacion, index) => {
                    setState({
                        losdatos: misdatos.item.itinerario_detalle_clientes ? misdatos.item.itinerario_detalle_clientes[index] : [], vistaFacturacion, index
                    }
                    )
                }}
            />
            :
            <Facturacion
                {...props}
                losdatos={state.losdatos}
                mostrarVista={() => setState({ vistaFacturacion: false })} />
    )
}

export default connect(mstp, mdtp)(container)