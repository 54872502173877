import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import ToolbarFiltroVendedores from './filtroVendedores';
import {estado_bodega} from '../../../../../utility/icons';
import { Link, Redirect } from 'react-router-dom';
export default class VendedorGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }
    boton_adicional = (id, row) => {
        const tipo = "V"
        return (
            <Link to={`bodega_usuario/${id}/${tipo}`} className="px-2" >
                <img
                    className="action-img"
                    title="adicional"
                    src={estado_bodega}
                    alt="Estado bodega" />
            </Link>

        )
    }
    boton_adicional2 = (id, row) => {
        return (
            <div style={{ width: '2.1rem' }}></div>
        )
    }
    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos["vendedores"] === false) {
            if(!!permisos[`ver_vendedores`] === false) {
                return <Redirect to={`/`} />
            }
        } 
        return (
            <CardSimple
                toolbar= {
                   <ToolbarSimple
                        textBoton={permisos.crear_vendedores ? "Agregar" : ""}
                        ruta="vendedor/crear"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre"}
                        permisos={permisos.vendedores}
                        estatefilterBar={true}
                            filterbar={
                                <ToolbarFiltroVendedores
                                changeEstado={this.props.changeEstado}
                                valorEstado={this.props.estado}
                                />
                            }
                    />
                }
                titulo="Vendedores">
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {
                            permisos.vendedores && this.props.estado.value && (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={(cell, row) => {
                                        return customStandardActions(cell, {
                                            eliminar: permisos.eliminar_vendedores ? eliminar: undefined,
                                            editar: permisos.editar_vendedores ? "vendedor": undefined,
                                            adicional4: row.permisos.bodegas | row.permisos.ver_bodegas ? this.boton_adicional: this.boton_adicional2
                                        });
                                    }}
                                >
                                </TableHeaderColumn>

                            )
                        }
                        <TableHeaderColumn
                            isKey
                            dataField="first_name"
                            dataSort
                            dataFormat={(cell, row) => `${cell} ${row.last_name}`}
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="profile"
                            dataFormat={cell => cell ? cell.phone : "---"}
                            dataSort
                        >
                            CONTACTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            CORREO
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
