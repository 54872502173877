import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardSimple from '../../../Utils/Cards/cardSimple';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { departamentos, CUENTA, ESTADO_CIVIL  } from '../../../../../utility/constants';

 class DetalleEmpleado extends Component{
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { leer } = this.props;
        if (this.props.match.params.id){
            leer(this.props.match.params.id);

        }else{
           return <Redirect to={`/`} />
        }
    }

    render(){
        const { item, loader, permisos } = this.props;
        // if(permisos["empresas"] === false) {
        //     if(!!permisos[`ver_empresas`] === false) {
        //         return <Redirect to={`/`} />
        //     }
        // }
        return(
            <CardSimple titulo={"Datos del Empleado"} margen="">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {
                        item && (
                            <div>
                                <hr style={{borderTop: "2px solid #00334E", margin:"10px 0px"}}/>
                                <div className="row">
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Nombre</h5>
                                        <h6 className="t-musgo">{`${item.nombre}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Apellido</h5>
                                        <h6 className="t-musgo">{`${item.apellido}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Telefono</h5>
                                        <h6 className="t-musgo">{`${item.telefono ? item.telefono : 'Sin registro'}`}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Lugar de Nacimiento</h5>
                                        <h6 className="t-musgo">{`${item.lugar_nacimiento}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Pueblo de Pertenencia</h5>
                                        <h6 className="t-musgo">{`${item.pueblo_pertenencia}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Genero</h5>
                                        <h6 className="t-musgo">{`${item.genero==1 ? "Masculino" : "Femenino"}`}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Departamento</h5>
                                        {departamentos.map (depart => depart.value==item.departamento ?
                                        <h6 className="t-musgo">{`${depart.label}`}</h6>
                                        : ""
                                        )}
                                        
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Estado civil</h5>
                                        {ESTADO_CIVIL.map (estado => estado.value==item.estado_civil ?
                                        <h6 className="t-musgo">{`${estado.label}`}</h6>
                                        : ""
                                        )}
                                        
                                    </div>
                                    
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Fecha de nacimiento</h5>
                                        <h6 className="t-musgo"><RenderDateTime fecha={item.fecha_nacimiento} ></RenderDateTime></h6>
                                    </div>
                                </div>
                                <hr style={{borderTop: "2px solid #00334E", margin:"10px 0px"}}/>
                                <div className="row">
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Puesto</h5>
                                        <h6 className="t-musgo">{`${item.puesto && item.puesto.nombre}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Empresa</h5>
                                        <h6 className="t-musgo">{`${ (item && item.empresa) ? item.empresa.nombre:"-----------"}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Contacto</h5>
                                        <h6 className="t-musgo">{`${item.nombre_contacto ? item.nombre_contacto : 'Sin registro'}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Telefono de Contacto</h5>
                                        <h6 className="t-musgo">{`${item.telefono_contacto ? item.telefono_contacto : 'Sin registro'}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Idiomas que Domina</h5>
                                        <h6 className="t-musgo">{`${item.idiomas_que_domina}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Tipo de licencia</h5>
                                        <h6 className="t-musgo">{`${item.tipo_licencia}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Código</h5>
                                        <h6 className="t-musgo">{`${item.codigo ? item.codigo : 'Sin registro'}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Banco</h5>
                                        <h6 className="t-musgo">{`${item.banco}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">No cuenta</h5>
                                        <h6 className="t-musgo">{`${item.no_cuenta}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Tipo cuenta</h5>
                                        {CUENTA.map (cuenta => cuenta.value==item.tipo_cuenta ?
                                        <h6 className="t-musgo">{`${cuenta.label}`}</h6>
                                        : ""
                                        )}
                                        
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Nit</h5>
                                        <h6 className="t-musgo">{`${item.nit}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Vendedor</h5>
                                        <h6 className="t-musgo">{`${item.vendedor ? item.vendedor.nombre : ""}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">DPI</h5>
                                        <h6 className="t-musgo">{`${item.dpi ? item.dpi : ""}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">IGSS</h5>
                                        <h6 className="t-musgo">{`${item.igss ? item.igss : ""}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Número de hijos</h5>
                                        <h6 className="t-musgo">{`${item.numero_hijos ? item.numero_hijos : ""}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Licencia</h5>
                                        <h6 className="t-musgo">{`${item.licencia ? item.licencia : ""}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Nivel académico</h5>
                                        <h6 className="t-musgo">{`${item.nivel_academico ? item.nivel_academico : ""}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Nivel académico</h5>
                                        <h6 className="t-musgo">{`${item.nivel_academico ? item.nivel_academico : ""}`}</h6>
                                    </div>
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Fecha inicio de labores</h5>
                                        <h6 className="t-musgo"><RenderDateTime fecha={item.fecha_inicio_labores} ></RenderDateTime></h6>
                                    </div>
                                </div>
                                <hr style={{borderTop: "2px solid #00334E", margin:"10px 0px"}}/>
                                <div className="row">
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <h5 className="t-mostaza">Direccion</h5>
                                        <h6 className="t-musgo">{`${item.direccion ? item.direccion : 'Sin registro'}`}</h6>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </LoadMask>
            </CardSimple>
        )
    }
}

export default DetalleEmpleado
