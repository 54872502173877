import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';

import RegionForm from './regionForm';

class Empresa extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR REGIÓN',
        data:{}
    }
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR REGIÓN',
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        this.state.editar? editar(id,values) : crear(values);
    }

    render() {
        const { loader } = this.props;
        return (
            <CardSimple
                titulo = {this.state.titulo}
            >
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <RegionForm 
                        onSubmit={this.onSubmit} 
                    />
                </LoadMask>
            </CardSimple>
                             
        );
    }
}

export default Empresa;
