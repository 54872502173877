import React, { Component } from 'react';
import _ from 'lodash';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import ToolbarReporteProductos from '../../../Utils/Toolbar/ToolbarReporteProductos';
import ToolbarReportePilotos from '../../../Utils/Toolbar/ToolbarReportePilotos';

import GridNoAsignados from './GridNoAsignados';
import GridAsignados from './GridAsignados';
import GridItinerarios from './GridItinerarios';

// Manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import moment from 'moment';
import { Redirect } from 'react-router';


export default class ReporteProduccion extends Component {

    changeFechaInicio = (value) => {
        const {fecha_fin} = this.props;
        if (value <= fecha_fin) {
            this.props.changeFechaInicio(value);
        }
    };

    changeFechaFin = (value) => {
        const {fecha_inicio} = this.props;
        if (fecha_inicio <= value) {
            this.props.changeFechaFin(value);
        }
    }

    render() {
        const { loader, permisos } = this.props;
        if(permisos.actividad_pilotos === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE DE PILOTOS">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReportePilotos
                                {...this.props}
                                changeFechaInicio={this.changeFechaInicio}
                                changeFechaFin={this.changeFechaFin}
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <Tabs
                            defaultActiveKey="1"
                            tabBarPoition = "top"
                            renderTabBar ={()=><ScrollableInkTabBar/>}
                            renderTabContent={()=><TabContent/>}
                        >
                            <TabPane tab="DESPACHOS NO ASIGNADOS" key="1">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridNoAsignados {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tab="DESPACHOS ASIGNADOS" key="2">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridAsignados {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tab="ITINERARIOS ACTIVOS" key="3">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridItinerarios {...this.props} />
                                </LoadMask>
                            </TabPane>
                        </Tabs>
                    </div>
                </LoadMask>

            </CardSimple>
        )
    }
}
