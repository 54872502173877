import React, { Component, Fragment } from 'react'

const dots = require('../../../../../../../assets/img/tree.png')

import { Dropdown, Button, DropdownButton } from 'react-bootstrap'

import "./dropdown.css";

export default class ItemTree extends Component {

    render() {
        const { item, permisos, item_padre, abrir_categoria} = this.props;
        return (
            <div className="ml-5 no-padding contendor-arbol ">

                <div className="rayado-opciones px-2 py-2" style={{display: 'flex', position: 'relative', height: 40}}>
                    <div style={{flexGrow: 3}}>
                        {
                            item.level == 0 ?
                            !item.selectable ?
                            item.seleccionado ? 
                            <span className="m-none t-mostaza semibold"> {item.title}
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                             </span>
                            :
                            <span className="m-none t-musgo semibold"> {item.title}
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                             </span>
                             :
                             <span className="m-none t-mostaza semibold">{item.title}</span>
                            :
                            item.level == 1 ?
                            !item.selectable ?
                            item.seleccionado ?
                            <span className="m-none t-musgo semibold"> {item.title} 
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                            </span>
                            :
                            <span className="m-none t-musgo semibold"> {item.title} 
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                            </span>
                            :<span className="m-none t-musgo semibold">{item.title}</span>
                            :
                            item.level == 2 ?
                            !item.selectable ?
                            item.seleccionado ?
                            <span className="m-none t-primary semibold"> {item.title} 
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                            </span>
                            :
                            <span className="m-none t-primary semibold"> {item.title} 
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                            </span>
                            :<span className="m-none t-primary semibold">{item.title}</span>
                            :
                            item.level == 3 ?
                            !item.selectable ?
                            item.seleccionado ?
                            <span className="m-none t-azul semibold"> {item.title} 
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_amarilla.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                            </span>
                            :
                            <span className="m-none t-azul semibold"> {item.title} 
                            &nbsp; &nbsp;
                            <img className="action-img" src={require('assets/img/icons/flecha_azul.png')} alt="Abrir"
                                title="Abrir" campo
                                onClick={() => {
                                    abrir_categoria(item.value), this.forceUpdate()
                                }}
                            />
                            </span>
                            :<span className="m-none t-azul semibold">{item.title}</span>
                            :
                            <div>
                                {item.title}
                                
                            </div>
                            
                        }
                    </div>
                    
                    {
                        !item.es_interno && (
                        <div style={{position: 'absolute', right: 10, width: 10}}>
                            <Dropdown >
                                <Dropdown.Toggle variant="success"  id="dropdown-basic">
                                    <img src={dots} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {permisos.crear_categiagastos && <Dropdown.Item onClick={() =>{
                                        this.props.seleccionarItem(item, 1)
                                        this.props.setOpenModal(true);
                                    }}>Agregar categoria</Dropdown.Item>}
                                    {permisos.editar_categiagastos && <Dropdown.Item onClick={() => {
                                        this.props.seleccionarItem(item, 2);
                                        this.props.setOpenModal(true);
                                    }}>Editar</Dropdown.Item>}
                                    {permisos.eliminar_categiagastos && <Dropdown.Item onClick={() => {
                                        this.props.seleccionarItem(item, 3);
                                    }}>Eliminar</Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        )
                    }
                </div>


                     {
                        item.seleccionado && (
                        item.children.map(item => {
                            return(
                                <ItemTree
                                    setOpenModal={this.props.setOpenModal}
                                    seleccionarItem={this.props.seleccionarItem}
                                    permisos={permisos}
                                item={item} 
                                item_padre ={this.props.item}
                                abrir_categoria={this.props.abrir_categoria}
                                />
                            )
                        }))
                    }

            </div>
        )
    }
}
