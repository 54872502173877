import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import VendedorForm from '../../Users/Editar/UsuariosForm';
import { api } from 'api';


class VendedorCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR Vendedor',
        roles: {}
    }
    getRoles = (search) => {
        return api.get("role/selectrolesvendedor").
            then((data) => {
                if (data) this.setState({roles: data});
                return [];
            }).catch(() => {
                return [];
            });
    };
    componentWillMount(){
        this.getRoles();
        this.props.selectRegiones()
        this.props.selectEmpresas2();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR Vendedor'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        console.log(values)
        this.state.editar? editar(id,values) : crear(values);
    }
    format = (array=[]) =>{
        const nuevoArray = [];
        if(array.role){
            array.role.forEach((item,index) => {
                nuevoArray[index]={"label": item.nombre , "value": item.id}
            });
            return nuevoArray
        }
        return []
    }

    render() {
        const { loader, empresas } = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <VendedorForm
                        vendedores_form={true}
                        onSubmit={this.onSubmit}
                        regiones={this.props.regiones}
                        empresas={empresas}
                        roles={this.state.roles}
                        format={this.format}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default VendedorCrear;
