import React, { Component } from 'react';
import _ from 'lodash';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { MESES } from '../../../../../utility/constants';
import ToolbarReporteDespachosBancos from '../../../Utils/Toolbar/ToolbarReporteDespachosBancos';
import moment from 'moment';
import { Redirect } from 'react-router';

function cellTotal(cell, row) {
    if(row.id == 0){
        return {color: "#00334E", fontSize: 16, fontWeight: "bold"}
    }
    return {}
}

export default class ReporteDespachosBancos extends Component {

    state = {
        meses: [],
    }

    componentDidMount() {
        const { fecha_inicio, fecha_fin } = this.props;
        this.cantidadMeses(fecha_inicio, fecha_fin);
        this.props.selectEmpresas();
    }

    cantidadMeses = (fecha_inicio, fecha_fin) => {
        let meses = [];
        let mes_inicio = fecha_inicio.month() + 1;
        let anio_inicio = fecha_inicio.year();
        const mes_fin = fecha_fin.month() + 1;
        const anio_fin = fecha_fin.year();
        let bandera = true;
        if (mes_inicio == mes_fin && anio_inicio == anio_fin)
            bandera = false;
        while (bandera) {
            const item = _.find(MESES, { value: mes_inicio });
            meses.push(`${item.label}`);
            if (mes_inicio < 12) {
                mes_inicio += 1;
            } else {
                anio_inicio += 1
                mes_inicio = 1
            }

            if (anio_inicio == anio_fin && mes_inicio == mes_fin)
                bandera = false
        }
        // Ultimo mes
        const item = _.find(MESES, { value: mes_fin });
        meses.push(`${item.label}`);
        this.setState({meses: meses});
    };

    validarFecha = (fecha_inicio, fecha_fin) => {
        let diferencia = 1;
        let mes_inicio = fecha_inicio.month() + 1;
        let anio_inicio = fecha_inicio.year();
        const mes_fin = fecha_fin.month() + 1;
        const anio_fin = fecha_fin.year();
        let bandera = true;
        if (mes_inicio == mes_fin && anio_inicio == anio_fin)
            bandera = false;
        if (moment(`${anio_inicio}-${mes_inicio}-1`) > moment(`${anio_fin}-${mes_fin}-1`)){
            console.log("La fecha inicial es mayor")
            diferencia = 13
            bandera = false;
        }
        while (bandera) {
            diferencia += 1;
            if (mes_inicio < 12) {
                mes_inicio += 1;
            } else {
                anio_inicio += 1
                mes_inicio = 1
            }

            if (anio_inicio == anio_fin && mes_inicio == mes_fin)
                bandera = false
        }

        return diferencia <= 12;
    }

    render() {
        const { loader, permisos, empresas } = this.props;

        if(permisos.reporte_despachos_bancos === false) {
            return <Redirect to={`/`} />
        }
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                <CardSimple titulo="REPORTE DESPACHOS CLIENTES">
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{ padding: "15px" }}>
                            <ToolbarReporteDespachosBancos
                                {...this.props}
                                reporte={1}
                                cliente={this.props.cliente}
                                despacho={null}
                                empresas={empresas}
                                fecha_inicio={this.props.fecha_inicio}
                                fecha_fin={this.props.fecha_fin}
                                changeCliente={this.props.changeCliente}
                                changeFechaInicial={this.props.changeFechaInicio}
                                changeFechaFinal={this.props.changeFechaFin}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3 mb-3 p-0">
                        <div className="col-12 d-flex justify-content-end" >
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {this.props.descargaArchivoDespachosTotalesEP()}}>
                                Descargar
                            </button>
                        </div>
                    </div>
                </CardSimple>
                <CardSimple titulo="REPORTE BANCOS">
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{ padding: "15px" }}>
                            <ToolbarReporteDespachosBancos
                                {...this.props}
                                reporte={2}
                                cliente={this.props.cliente_flotantes}
                                despacho={null}
                                empresas={empresas}
                                fecha_inicio={this.props.fecha_inicio_flotantes}
                                fecha_fin={this.props.fecha_fin_flotantes}
                                changeCliente={this.props.changeClienteFlotantes}
                                changeFechaInicial={this.props.changeFechaInicioFlotantes}
                                changeFechaFinal={this.props.changeFechaFinFlotantes}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3 mb-3 p-0">
                        <div className="col-12 d-flex justify-content-end" >
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {this.props.descargaArchivoBancosFlotantesEP()}}>
                                Descargar
                            </button>
                        </div>
                    </div>
                </CardSimple>
                <CardSimple titulo="REPORTE DESPACHOS O/V">
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{ border: "1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{ padding: "15px" }}>
                            <ToolbarReporteDespachosBancos
                                {...this.props}
                                reporte={3}
                                cliente={this.props.cliente_ov}
                                despacho={this.props.despacho_ov}
                                empresas={null}
                                fecha_inicio={this.props.fecha_inicio_ov}
                                fecha_fin={this.props.fecha_fin_ov}
                                changeFechaInicial={this.props.changeFechaInicioOV}
                                changeFechaFinal={this.props.changeFechaFinOV}
                                changeCliente={this.props.changeClienteOV}
                                changeDespachoOV={this.props.changeDespachoOV}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3 mb-3 p-0">
                        <div className="col-12 d-flex justify-content-end" >
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {this.props.descargaArchivoDespachosOrdenVentaEP()}}>
                                Descargar
                            </button>
                        </div>
                    </div>
                </CardSimple>
            </LoadMask>
        )
    }
}
