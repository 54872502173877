import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

export default class PilotosGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos.pilotos === false) {
            if(!!permisos.ver_pilotos === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                toolbar= {
                   <ToolbarSimple
                        textBoton={permisos.crear_pilotos ? "Agregar" : ""}
                        ruta="pilotos/crear"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre"}
                        // permisos={permisos.vendedores}
                        permisos={permisos.pilotos}
                    />
                }
                titulo="Pilotos">
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {
                            (permisos.pilotos) ? (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return customStandardActions(cell, {
                                            eliminar: permisos.eliminar_pilotos ? eliminar: undefined,
                                            editar: permisos.editar_pilotos ? "pilotos": undefined,
                                        });
                                    }}
                                />
                            ) : (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    width="2%"
                                    dataSort
                                    dataFormat={cell => ""}
                                />

                            )
                        }
                        <TableHeaderColumn
                            isKey
                            dataField="first_name"
                            dataSort
                            dataFormat={(cell, row) => `${cell} ${row.last_name}`}
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="profile"
                            dataFormat={cell => cell ? cell.phone : "---"}
                            dataSort
                        >
                            CONTACTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            CORREO
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
