import { handleActions } from 'redux-actions';
import { push, goBack } from "react-router-redux";
import { initialize as initializeForm, reset as resetForm } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";
import moment from 'moment';
import { valores_tipo_transacciones, efectivo  } from '../../../utility/constants';
import _ from 'lodash';


let url = 'liquidaciones' ;
let form = 'LiquidarChequesForm';
let dirGrid = '/';

export const constants ={
    LOADER: 'LIQUIDACIONES_LOADER',
    DATA: 'LIQUIDACIONES_DATA',
    ITEM: `LIQUIDACIONES_ITEM`,
    PAGE: `LIQUIDACIONES_PAGE`,
    ORDERING: `LIQUIDACIONES_ORDERING`,
    SEARCH: `LIQUIDACIONES_SEARCH`,
    OPEN_MODAL: 'LIQUIDACIONES_OPENMODAL',
    OPEN_MODAL_TARJETA: 'OPEN_MODAL_TARJETA',
    OPEN_MODAL_CHEQUE: 'OPEN_MODAL_CHEQUE',
    OPEN_MODAL_TRANSACCION: 'OPEN_MODAL_TRANSACCION',
    OPEN_MODAL_EFECTIVO: 'OPEN_MODAL_EFECTIVO',
    LOADER_FORMULARIO: 'LIQUIDACIONES_LOADER_FORMULARIO',
    TRANSACCIONES: 'LIQUIDACIONES_TRANSACCIONES',
    PENDIENTES: "PENDIENTES",
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setLoaderFormulario = loader_formulario =>({
    type: constants.LOADER_FORMULARIO,
    loader_formulario
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setTransacciones = transacciones => ({
    type: constants.TRANSACCIONES,
    transacciones
})

const setPendientes = pendientes => ({
    type: constants.PENDIENTES,
    pendientes
})


const listar = (page = 1, id) => (dispatch, getStore) => {
    const resource = getStore().gastos;
    const params = { page };
    params.search = resource.search;
    params.caja = id;
    dispatch(setLoader(true));
    api.get(`${url}/cobros_pendientes`, params).then((response) => {
        let cheques = response.filter(function (item){
            return item.tipo_documento == valores_tipo_transacciones.cheque
        });
        let depositos = response.filter(function(item){
            return item.tipo_documento == valores_tipo_transacciones.deposito
        })
        let Efectivo = response.filter(function(item){
            return item.tipo_documento == valores_tipo_transacciones.efectivo
        })
        let tarjeta = response.filter(function (item){
            return item.tipo_documento == valores_tipo_transacciones.tarjeta
        });
        let electronica = response.filter(function(item){
            return item.tipo_documento == valores_tipo_transacciones.electronica
        })
        let totalCheques = 0;
        for(let i = 0; i< cheques.length ; i++){
            totalCheques = parseFloat(totalCheques) + parseFloat(cheques[i].monto)
        }
        let totalDepositos = 0;
        for(let i = 0; i< depositos.length ; i++){
            totalDepositos = parseFloat(totalDepositos) + parseFloat(depositos[i].monto);
        };
        let totalEfectivo = 0;
        for(let i = 0; i< Efectivo.length ; i++){
            totalEfectivo = parseFloat(totalEfectivo) +  parseFloat(Efectivo[i].monto);
        };
        let totalTarjeta = 0;
        for(let i = 0; i< tarjeta.length ; i++){
            totalTarjeta = parseFloat(totalTarjeta) + parseFloat(tarjeta[i].monto);
        };
        let totalElectronica = 0;
        for(let i = 0; i< electronica.length ; i++){
            totalElectronica = parseFloat(totalElectronica) +  parseFloat(electronica[i].monto);
        };
        let data = {results: response}
        data.totalCheques= totalCheques;
        data.totalDepositos= totalDepositos;
        data.totalEfectivo= totalEfectivo;
        data.totalTarjeta= totalTarjeta;
        data.totalElectronica=totalElectronica;
        if(response.length > 0)
            data.moneda = response[0].moneda
        dispatch(setData(constants.DATA ,data));
        dispatch({type: constants.PAGE, page: page});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, id) => (dispatch, getStore) => {
    dispatch(setLoaderFormulario(true));
    let store = getStore().liquidaciones;
    let dataFinal = store.transacciones.concat(data.cheques)
    let params={ transacciones: [] }
    for(let i=0; i < dataFinal.length ; i++){
        if(dataFinal[i].id)
            params.transacciones[i] = {id: dataFinal[i].id }
    }
    if(params.transacciones.length > 0){
        api.post(`${url}`, params).then(() => {
            NotificationManager.success('Registro creado', 'Éxito', 3000);
            dispatch(setTransacciones([]));
            dispatch(listar(1, id));
            dispatch(verPendientes())
        }).catch((res) => {
            NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
        }).finally(() => {
            dispatch(setLoaderFormulario(false));

            dispatch(setOpenModal(false))
            dispatch(setOpenModalTarjeta(false))
            dispatch(setOpenModalCheque(false))
            dispatch(setOpenModalTransaccion(false))
            dispatch(setOpenModalEfectivo(false))
        });
    } else{
        NotificationManager.error('Se necesita al menos una transaccion', 'ERROR');
        dispatch(setLoaderFormulario(false));
    }
};

const leerLiquidacionesPendientes = (id)  => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    let params = { caja: id }
    api.get(`${url}/cobros_pendientes`, params).then((response) => {
        let data = response.filter(function (item){
            return item.tipo_documento != valores_tipo_transacciones.cheque
        });
        dispatch(setTransacciones(data))
        let cheques = {cheques : response.filter(function (item){
            return item.tipo_documento == valores_tipo_transacciones.cheque
        })};
        dispatch(setData(response));
        if(!!form)
            dispatch(initializeForm(form, cheques))
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
}

const getLiquidacionesPendientes = (caja_id, tipos_documento)  => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    let params = { caja_id, tipos_documento }
    api.get(`${url}/liquidaciones_pendientes_documento`, params).then((response) => {

        // String facturas
        response.map((transaccion) => {
            let facturas_string = transaccion.facturas_asociadas.map(factura => factura.numero).join(', ')
            transaccion.facturas_asociadas_string = facturas_string;
		});

        let cheques = response;
        // dispatch(setTransacciones(response))
        dispatch(setData(response));

        if(!!form)
            dispatch(initializeForm(form, {cheques}))
    }).catch((res) => {
        console.log(res)
        NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
}

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/gastos'));

    }).catch((e) => {
        console.log(e)
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};

const aceptarLiquidacion = (id, idCaja) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${'cuentas'}/aceptarliquidar`, {id}).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(`/caja/${idCaja}`));
        // dispatch(estadoMiCaja(caja));
        // dispatch(listPCaja(caja));
        // dispatch(leer(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const rechazarLiquidacion = (id, idCaja) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/rechasarliquidaciones`, {id}).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(`/caja/${idCaja}`));
        // dispatch(estadoMiCaja(caja));
        // dispatch(listPCaja(caja));
        // dispatch(leer(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const eliminar =values => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}/anularTransaccion`, values).then(() => {
        dispatch(setOpenModal(false))
        dispatch(listar());

    }).catch(() => {
        NotificationManager.success('Error en la operacion', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


const setOpenModal = (open_modal, id) => (dispatch, getStore) => {
    if(open_modal == true){
        dispatch(leerLiquidacionesPendientes(id))
    }
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}

const setOpenModalTarjeta = (isOpen, id, tipos_documento) => (dispatch, getStore) => {
    if (isOpen == true) {
        dispatch(getLiquidacionesPendientes(id, tipos_documento))
    }
    dispatch({type: constants.OPEN_MODAL_TARJETA, open_modal_tarjeta: isOpen})
}

const setOpenModalCheque = (isOpen, id, tipos_documento) => (dispatch, getStore) => {
    if (isOpen == true) {
        dispatch(getLiquidacionesPendientes(id, tipos_documento))
    }
    dispatch({type: constants.OPEN_MODAL_CHEQUE, open_modal_cheque: isOpen})
}

const setOpenModalTransaccion = (isOpen, id, tipos_documento) => (dispatch, getStore) => {
    if (isOpen == true) {
        dispatch(getLiquidacionesPendientes(id, tipos_documento))
    }
    dispatch({type: constants.OPEN_MODAL_TRANSACCION, open_modal_transaccion: isOpen})
}

const setOpenModalEfectivo = (isOpen, id, tipos_documento) => (dispatch, getStore) => {
    if (isOpen == true) {
        dispatch(getLiquidacionesPendientes(id, tipos_documento))
    }
    dispatch({type: constants.OPEN_MODAL_EFECTIVO, open_modal_efectivo: isOpen})
}

const verPendientes = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/liquidaciones_pendientes`).then((response) => {
        dispatch(setPendientes(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const abonarEfectivoCheque = (id, es_pago=true, id_transaccion, caja) => (dispatch, getStore) => {
    //Agrega un registro a la BD seǵun sea el gasto
    const formData = _.cloneDeep(getStore().form.ConvertirChequeForm.values);
    formData.cuenta = id
    formData.es_pago = es_pago
    formData.fecha = moment(formData.fecha).format('YYYY-MM-DD')
    formData.descripcion = formData.descripcion + `, este efectivo fue cobrado de un cheque en el banco:  ${formData.banco_referencia}`
    console.log('formData', formData)

    const data = {descripcionAnulacion: "Este cheque fue cobrado a efectivo", id: id_transaccion}


    dispatch(setLoader(true));
    api.post(`periodos/agregarGastoOPago`, formData).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(anularTransaccion(data, caja))
    }).catch((error) => {
        let mensaje = 'Error en el abono de pago';
        console.log(error)
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                try {
                    mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
                } catch (error) {
                    mensaje = "Error al agregar bono";
                }
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
        //dispatch(leerOrdenCompra(id));
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setModalPagos(false));
    });
 }

 const anularTransaccion = (data, id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`periodos/anularTransaccion`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(listar(1,id));
        dispatch(setModal(false))

    }).catch((error) => {
        let mensaje = 'Error en la anulación';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

 const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch({
            type: constants.EMPRESAS,
            empresas: res.empresa
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModal,
    setOpenModalTarjeta,
    setOpenModalCheque,
    setOpenModalTransaccion,
    setOpenModalEfectivo,
    verPendientes,
    abonarEfectivoCheque,
    aceptarLiquidacion,
    rechazarLiquidacion,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.OPEN_MODAL_TARJETA]: (state, { open_modal_tarjeta }) => {
        return {
            ...state,
            open_modal_tarjeta,
        };
    },
    [constants.OPEN_MODAL_CHEQUE]: (state, { open_modal_cheque }) => {
        return {
            ...state,
            open_modal_cheque,
        };
    },
    [constants.OPEN_MODAL_TRANSACCION]: (state, { open_modal_transaccion }) => {
        return {
            ...state,
            open_modal_transaccion,
        };
    },
    [constants.OPEN_MODAL_EFECTIVO]: (state, { open_modal_efectivo }) => {
        return {
            ...state,
            open_modal_efectivo,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.TRANSACCIONES]: (state, { transacciones }) => {
        return {
            ...state,
            transacciones
        }
    },
    [constants.PENDIENTES]: (state, { pendientes }) => {
        return {
            ...state,
            pendientes
        }
    }
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        totalCheques: 0,
        totalDepositos: 0,
        totalEfectivo: 0
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    open_modal: false,
    open_modal_tarjeta: false,
    open_modal_cheque: false,
    open_modal_transaccion: false,
    open_modal_efectivo: false,
    loader_formulario: false,
    transacciones: [],
    pendientes: []
};

export default handleActions(reducers, initialState);
