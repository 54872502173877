import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';


let url = 'reportes' ;
let form = '';
let dirGrid = '/';


export const constants ={
    LOADER: 'REPORTE_COBROS_LOADER',
    RESUMEN: 'REPORTE_COBROS_RESUMEN',
    DATA: 'REPORTE_COBROS_DATA',
    PAGE: 'REPORTE_COBROS_PAGE',
    SET_CLIENTE: 'SET_CLIENTE_COBROS',
    SET_VENDEDOR: 'SET_VENDEDOR_COBROS',
    SET_FECHA_I: 'SET_FECHA_I_COBROS',
    SET_FECHA_F: 'SET_FECHA_F_COBROS',
    SET_EMPRESAS:'COBROS_SET_EMPRESAS',
    SET_SEARCH_EMPRESA: 'COBROS_SET_SEARCH_EMPRESA',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});

const setDataSelect = (type, data) =>({
    type,
    data
});

// ACTIONS

const getTotales = () => (dispatch, getStore) => {
    const resource = getStore().reporteCobros;
    const params = { };
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.vendedor)
        params.vendedor = resource.vendedor.id;
    if (resource.cliente)
        params.cliente = resource.cliente.id;
    if (resource.empresa){
        params.empresa = resource.empresa.value
    }
    dispatch(setLoader(true));
    api.get(`${url}/cobros_totales`, params).then((response) => {
        const data = _.cloneDeep(response.results);
        dispatch(setData(constants.RESUMEN, data));
        dispatch(listarCobros());
    }).catch(() => {
    }).finally(() => {
    });
};

const listarCobros = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteCobros;
    const params = { page };
    const resumen_subtotal = []
    if (resource.fecha_inicio)
        params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.vendedor)
        params.vendedor = resource.vendedor.id;
    if (resource.cliente)
        params.cliente = resource.cliente.id;
    if (resource.empresa){
        params.empresa = resource.empresa.value
    }
    dispatch(setLoader(true));
    api.get(`${url}/cobros_meses`, params).then((response) => {
        const resumen = resource.resumen;
        const results = _.cloneDeep(response.results);
        if(results.length > 0){
            results[0].meses.forEach(mes =>{
                resumen_subtotal.push({total: 0})
            })
            results.forEach(cliente =>{
                cliente.meses.forEach((mes, index) =>{
                    resumen_subtotal[index].total += mes.total  
                })
            })
        }
        results.unshift({id: 0, nombre_origen: "TOTAL GENERAL", meses: resumen});
        results.push({id: 0, nombre_origen: "SUBTOTAL", meses: resumen_subtotal});
        response.results = results;
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeCliente = (value) => (dispatch) => {
    dispatch(setData(constants.SET_CLIENTE, value));
    dispatch(getTotales());
};

const changeVendedor = (value) => (dispatch) => {
    dispatch(setData(constants.SET_VENDEDOR, value));
    dispatch(getTotales());
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(getTotales());
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(getTotales());
};

const changeEmpresa = empresa => dispatch => {
    dispatch({type:constants.SET_SEARCH_EMPRESA, empresa});
    dispatch(getTotales());
}
const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch(setDataSelect(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

export const actions = {
    getTotales,
    listarCobros,
    changeCliente,
    changeVendedor,
    changeFechaInicio,
    changeFechaFin,
    changeEmpresa,
    selectEmpresas
}


export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.RESUMEN]:(state, { data }) => {
        return{
            ...state,
            resumen: data
        }
    },
    [constants.DATA]:(state, { data }) => {
        return{
            ...state,
            data: data
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SET_CLIENTE]: (state, { data }) => {
        return {
            ...state,
            cliente: data
        };
    },
    [constants.SET_VENDEDOR]: (state, { data }) => {
        return {
            ...state,
            vendedor: data
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_SEARCH_EMPRESA]: (state, {empresa}) => {
        return {
            ...state,
            empresa
        }
    },
}

export const initialState = {
    loader:false,
    resumen: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    cliente: null,
    vendedor: null,
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
    empresa: null,
    empresas: []
}

export default handleActions(reducers, initialState);
