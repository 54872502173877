
import React, { Component } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Fragment } from 'react';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import flecha from '../../../../../../assets/img/icons/down arrow.png';
import {standardActions, customStandardActions2 } from "../../../Utils/Grid/StandardActions";
import iconoFacturar from '../../../../../../assets/img/icons/facturar.png';
import AnularFacturaForm from './anularFacturaForm';

import {api} from 'api';


class DetalleGrid extends Component {
    render() {
        const { data, loader, listar: onPageChange, onSortChange, } = this.props
        return (
            <Grid hover striped data={{ count: 10, results: data }} loading={loader}
                onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}
            >
                <TableHeaderColumn
                    dataField="id"
                    className='reporte-table'
                    dataSort
                    isKey
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombre_producto"
                    className='reporte-table'
                    dataSort
                >
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    className='reporte-table'
                    dataSort
                >
                    CANTIDAD
                </TableHeaderColumn>
            </Grid>
        )
    }
}

export default class GridClientesProveedores extends Component {

    state = {
        itinerario_detalle: {
            results: null,
            count: 0
        },
        id_factura: null,
    }

    componentDidMount() {
        const { itinerario } = this.props;
        let count = itinerario.length
        this.setState({ itinerario_detalle: { results: itinerario, count: count } });
    }

    isExpandableRow(row) {
        return true
        // if (row.detalle_productos.length > 0) return true;
        // else return false;
    }
    expandComponent(row) {
        return (
            <DetalleGrid data={row.detalle_productos} />
        );
    }

    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded
                ? <img className="action-img onExpand" title="Ver" src={flecha} alt="Ver" />
                : <img className="action-img expand" title="Ver" src={flecha} alt="Ver" />
            );
        } else {
            content = ' ';
        }
        return (
            <div className="d-flex justify-content-center"> {content} </div>
        );
    }

    expandedColumnHeaderComponent({ anyExpand }) {
        const content = (anyExpand ? '(-)' : '(+)');
        return (
            <div>
                {content}
            </div>
        );
    }
    adicionalbotonimprimible = (id, row) => {
        // if (row.estado) {
        //     return (<div></div>)
        // }
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                onClick={(e)=>{
                    e.preventDefault();
                    this.props.PDFITINERARIO(row.despacho.id)
                    console.log(row.despacho) 
                }}
            >
                <img
                    className="action-img"
                    title="Imprimible"
                    src={require("../../../../../../assets/img/icons/Ver.png")} alt="Ver eliminado"/>
            </a>
        )
       
    }
    botonImprimirFactura = (id, row) => {
        return (
            <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                target="_blank"
                onClick={(e) => {
                    if (row.despacho.facturas_despacho[0]) {
                        row.despacho.orden_detalle.facturas.forEach((item) => {
                            if (item.id == row.despacho.facturas_despacho[0]) {
                                window.open(item.factura, "_blank")
                            }
                        })
                    } else {
                        NotificationManager.error("Este Despacho no esta facturado")
                    }
                }}
            >
                <img
                    className="action-img"
                    title="PDF Factura"
                    src={require("../../../../../../assets/img/icons/facturapdf.png")} alt="PDF Factura" />
            </a>
        )
    }
    botonFacturar = (id, row, cont) => {
        return (
            <td className="sin-borde-topzz">
                <img className="action-img" alt="Facturacion" title="Facturación" src={iconoFacturar} onClick={() => this.props.mostrarVista(true, cont - 1)}></img>
            </td>
        )
    }

    abrirModalAnularFactura = (id, row) => {
        this.props.setOpenModalAnularFactura(true);

        this.setState({
            id_factura: row.despacho.facturas_despacho[0]
        })
    }

    renderModalAnularFactura = () => {
        return (
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>
                {
                    (
                        <AnularFacturaForm
                            onSubmit={this.onSubmitAnularFactura}
                            cerrarModal={this.props.setOpenModalAnularFactura}
                        />
                    )
                }
            </Fragment>
        )
    }

    onSubmitAnularFactura = (values) => {
        values.id_factura = this.state.id_factura;

        Swal.fire({
            title: '¿Desea anular la factura?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularFacturaEP(values, this.props.match.params.id);
            }
        });
    }

    abrirModalHistorialFacturas = (id, row) => {
        api.get('factura/get_historial_facturas', {id_despacho: row.despacho.id}
        ).then((data) => {
            if (data) {
                this.setState({
                    historial_facturas: data.results
                })
            }
        }).catch(() => {
            this.setState({
                historial_facturas: []
            })
        });
        this.props.setOpenModalFacturasHistorial(true, row);
    }

    botonFactura = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.factura){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank" 
                    href={row.factura}
                    // onClick={(e)=>{
                    //     e.preventDefault();
                    //     this.setState({
                    //     id_transaccion: id,
                    //     transaccion_sel: row
                    // })
                    // this.props.setOpenModal(true);
                    // }}
                >
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/facturapdf.png")} alt="PDF"/>
                </a>
            )
        }else{return(<div></div>)}

    }

    botonXml = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.archivo_xml){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank" 
                    href={row.archivo_xml}
                    // onClick={(e)=>{
                    //     e.preventDefault();
                    //     this.setState({
                    //     id_transaccion: id,
                    //     transaccion_sel: row
                    // })
                    // this.props.setOpenModal(true);
                    // }}
                    download={`${row.archivo_xml}`}
                >
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/XML.png")} alt="PDF"/>
                </a>
            )
        }else{return(<div></div>)}

    }

    renderModalHistorialFacturas = (props) => {
        const { historial_facturas } = this.state;

        return (
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">Historial de Facturas</h2>
                </div>
                <Grid hover striped data={{count:10,results:historial_facturas}} loading={props.loader} onPageChange={props.onPageChange} onSortChange={props.onSortChange} pagination={false}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={
                            (cell, row)=>{
                                return row.estado_factura=='ACEPTADA' || row.estado_factura=='ANULADA' || row.estado_factura=='EXTERNA' ?
                                standardActions({
                                    // eliminar:this.handleDelete
                                    adicional:this.botonFactura,
                                    adicional2:this.botonXml,
                                })(cell,row) : (row.estado_factura==2 ? 'FALLIDA' : 'PENDIENTE')
                            }
                        }
                    > </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nit"
                        dataSort
                    >
                        NIT
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero"
                        dataSort
                    >
                        No. FACTURA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        dataFormat ={
                            (cell, row)=>(
                                <RenderMoneda monto={cell? cell : 0} simbolo={'Q'} />
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado_factura"
                        dataSort
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }

    render() {

        const { paraCliente, loader, open_modal_anular_factura, open_modal_historial, permisos, page, onPageChange, onSortChange, estado } = this.props;
        let cont = 0
        return (
            <div className="row m-none">
                <div className="col-12">
                    {paraCliente ? (
                        <Fragment>
                            <Modal open={open_modal_anular_factura} onClose={(e) => {this.props.setOpenModalAnularFactura(false)}} center>
                                {this.renderModalAnularFactura()}
                            </Modal>

                            <Modal open={open_modal_historial} onClose={(e) => {this.props.setOpenModalFacturasHistorial(false)}} center>
                                {this.renderModalHistorialFacturas(this.props)}
                            </Modal>

                            <Grid hover
                                pagination={false}
                                striped data={this.state.itinerario_detalle}
                                loading={loader}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    dataSort
                                    width="25%"
                                    /* dataFormat={
                                        standardActions({ adicional: this.adicionalbotonimprimible})
                                    } */
                                    dataFormat={(cell, row, index) => {
                                        cont +=1
                                        return customStandardActions2(cell, row,{
                                            adicional2: estado ? this.adicionalbotonimprimible : undefined ,
                                            adicional3: estado && row.despacho.facturas_despacho.length > 0 ? this.botonImprimirFactura : undefined,
                                            adicional: row.despacho.facturas_despacho.length > 0 ? undefined : this.botonFacturar.bind(this, row.id, row, cont),
                                            anularFacturaModal: row.despacho.facturas_despacho.length > 0 && permisos.anular_factura ? this.abrirModalAnularFactura : undefined,
                                        });
                                    }}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="clienteProveedor"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (
                                            cell ? cell.nombre : "-----"
                                        )
                                    }
                                >
                                    {paraCliente ? 'CLIENTE' : 'PROVEEDOR'}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="sucursal"
                                    dataSort
                                >
                                    SUCURSAL
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="despacho"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (cell ? cell.no_despacho : '--')
                                    }
                                >
                                    DESPACHO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="firma"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (
                                            cell ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <img className="firma-img" src={cell}  />
                                                </div>
                                            ) : 'Sin entregar'
                                        )
                                    }
                                >
                                    FIRMA DE RECIBIDO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="despacho"
                                    dataSort
                                    dataFormat={(cell, row, index) => {
                                        return customStandardActions2(cell, row,{
                                            verpagosModal: this.abrirModalHistorialFacturas,
                                        });
                                    }}
                                >
                                HISTORIAL FACTURAS
                                </TableHeaderColumn>
                            </Grid>
                        </Fragment>
                    ) :
                        (
                            <Grid hover
                                pagination={false}
                                striped data={this.state.itinerario_detalle}
                                loading={loader}
                                expandableRow={this.isExpandableRow}
                                expandComponent={this.expandComponent}
                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent: this.expandColumnComponent,
                                    columnWidth: 100,
                                    expandedColumnHeaderComponent: this.expandedColumnHeaderComponent
                                }}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    dataSort
                                    width="10%"
                                    dataFormat={() => " "}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="clienteProveedor"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (
                                            cell ? cell.nombre : "-----"
                                        )
                                    }
                                >
                                    {paraCliente ? 'CLIENTE' : 'PROVEEDOR'}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="sucursal"
                                    dataSort
                                >
                                    SUCURSAL
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="orden_compra"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (cell ? cell.no_orden : '--')
                                    }
                                >
                                    ORDEN DE C.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="entregado"
                                    dataSort
                                    dataFormat={
                                        (cell, row) => (cell ? 'Recogido' : 'Sin recoger')
                                    }
                                >
                                    RECOGIDO
                                </TableHeaderColumn>
                            </Grid>
                        )
                    }
                </div>
            </div>
        )
    }
}
