import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Devolucion/devolucion';
import Despacho from './despachoNuevo';

const mstp = state => {
    return {...state.devolucion}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Despacho)
