import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderFieldRadio,renderFieldCheck } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";
import {SelectField, AsyncSelectField} from '../../../Utils/renderField/renderField.js'

let Empresaform = (props) => {
    const { handleSubmit, pristine, reset, submitting, opcion_cpp, permiso_cuenta, establecimientos } = props;
    return (
        <form name="Empresaform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre de la Empresa</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul"  htmlFor="producto">Producto</label>
                    <Field name="producto" label="producto" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="establecimiento">Establecimiento</label>
                    <Field
                        name="establecimiento"
                        label="establecimiento"
                        valueKey="value"
                        labelKey="label"
                        options={establecimientos}
                        component={SelectField}
                    />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <br/>
                    <br/>
                    <Field name="es_individual" label="Individual" component={renderFieldCheck} className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <Field name="es_principal" label="Principal" component={renderFieldCheck} className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <Field name="es_transporte" label="Transporte" component={renderFieldCheck} className="form-control" />
                </div>
            </div>
            { permiso_cuenta && (opcion_cpp && (
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-4 col-md-6 col-12">
                        <label className="t-azul"  htmlFor="cuenta_inicial">Saldo Inicial</label>
                        <Field name="cuenta_inicial" label="cuenta_inicial" component={renderCurrency} className="form-control" />
                    </div>
                    <div className="form-group has-feedback col-md-4 col-12">
                        {/* <label className="t-azul"  htmlFor="producto">Cuenta por </label> */}
                        <Field 
                            name="cuenta_por" 
                            label="Cuenta por Pagar" 
                            component={renderFieldRadio} 
                            type="radio"
                            value={"1"}
                            className="form-control" />
                        <Field 
                            name="cuenta_por" 
                            label="Cuenta por Cobrar" 
                            component={renderFieldRadio} 
                            type="radio"
                            value={"0"}
                            className="form-control" />
                    </div>
                </div>
                ))
            }
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-8 col-12">
                    <label className="t-azul"  htmlFor="fecha_fundacion">Fecha de Fundación</label>
                    {/* <Field name="fecha_fundacion" label="fecha_fundacion" component={renderDatePicker} className="form-control" /> */}
                    <Field 
                        name="fecha_fundacion" 
                        selectedDate={new Date()}  
                        maxDate="2030-12-31"
                        minDate="1940-01-01"
                        className="form-control" 
                        component={renderDropdownDateField}
                    />
                </div>
            </div>
            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/empresas" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


Empresaform = reduxForm({
    form: 'Empresaform', // a unique identifier for this form,
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            producto: validators.exists()('Este campo es requerido'),
            fecha_fundacion: validators.exists()('Este campo es requerido'),
        });
    },
})(Empresaform);

const selector = formValueSelector('Empresaform');
Empresaform = connect(state =>{
    const es_principal = selector(state, 'es_principal')
    const es_actualizado = selector(state, 'es_actualizado')
    
    let opcion_cpp = true
    let permiso_cuenta = true

    if(!!es_principal == true){
        opcion_cpp = false
    }
    else { opcion_cpp = true}
    if (!!es_actualizado == true){
        permiso_cuenta = false
    }
    else{permiso_cuenta=true}

    return{
        opcion_cpp,
        permiso_cuenta
    }
})(Empresaform)


export default Empresaform