import React, {Fragment, Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderField,
    AsyncSelectField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
    renderTextArea,
    renderSelecttree,
    renderPercentage,
} from '../../../Utils/renderField';
// import { TipoTransaccion } from '../../../../../utility/constants';
import { getSimbolo} from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";
import {api} from 'api';


const TipoTransaccion = [
    {value:1, label:'ELECTRONICA', ayuda: 'No. transacción'},
    // {value:2, label:'CHEQUE', ayuda: 'No. cheque'},
    // {value:4, label:'DEPOSITO', ayuda: 'No. boleta'},
    // {value:5, label:'TARJETA', ayuda: 'No. transacción'},
]

const getPoss = (search) => {
    return api.get("poss", { search, tipo: 1 }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const renderDesglose = ({fields, meta: {error, submitFailed }, simbolo, empresas, cambioDescuento}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Empresa</th>
                        <th style={{width: "20%"}}>Porcentaje</th>
                        <th  style={{width: "20%"}}>Subtotal</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((empresa, index) => {
                        return (
                            <tr key={index} style={{height:"68px"}}>
                                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                        title="Borrar"
                                        onClick={() =>{
                                            fields.remove(index)
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                    name={`${empresa}.empresa`}
                                    label="empresa"
                                    labelKey="nombre" valueKey="id"
                                    component={renderSelectField}
                                    options={empresas}
                                    className="form-control"/>
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${empresa}.porcentaje`}
                                        type="number"
                                        addClass={"text-right"}
                                        component={renderPercentage}
                                        placeholder="Precio"
                                        _onChange={(e) => {cambioDescuento(e, 'porcentaje', index, 'ReintegroForm')}}
                                    />
                                </td>
                                <td className="sin-borde-topzz">
                                    <Field
                                        name={`${empresa}.subtotal`}
                                        label="tipo_cambio"
                                        prefix={simbolo}
                                        component={renderCurrency}
                                        parse={cell => parseFloat(cell)}
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                        _onChange={(e) => {cambioDescuento(e, 'precio', index, 'ReintegroForm')}}
                                    />
                                </td>
                            </tr>
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR EMPRESA
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger h5 font-weight-bold">
                        {error}
                </div>}
    </div>
)

class ReintegroForm extends React.Component {
    state = {
        monto: 0,
    }

    render(){
        const { handleSubmit, cuentas, texto_boton_movimiento, simbolo, tipo_documento,
            cerrarModal, es_gasto, tipo_cuenta, tipo_gastos, esCobro, esCheque, es_tarjeta,
            cambioDescuento,
        } = this.props;
        return(
        <form name="ReintegroForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div style={{border: "1px solid #e8e8e8", padding:"10px"}}>
                <div className="d-flex justify-content-center">
                    <h5 className="t-primary text-uppercase align-self-center">{this.props.titulo_modal? this.props.titulo_modal:""}</h5>
                </div>
                <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                    <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                        <Field
                            name="tipo_cuenta"
                            label="Cuenta"
                            component={renderFieldRadio}
                            type="radio"
                            value={"true"}
                            className="form-control" />
                    </div>
                    <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                        <Field
                            value={"false"}
                            name="tipo_cuenta"
                            label="Mi Caja Chica"
                            component={renderFieldRadio}
                            type="radio"
                            className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className={`form-group has-feedback  col-md-6  col-12 `}>

                </div>
            </div>

            {
                (tipo_cuenta) && (
                    <div className="row col-12 m-0 p-0">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="tipo_documento">Tipo de Movimiento</label>
                            <Field
                                name="tipo_documento"
                                label="tipo_documento"
                                labelKey="label" valueKey="value"
                                component={renderSelectField}
                                options={TipoTransaccion}
                                className="form-control"
                            />
                        </div>
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="no_documento">{tipo_documento}</label>
                            <Field name="no_documento" label="no_documento" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                )
            }
            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul"  htmlFor="monto">Monto</label>
                    <Field
                        name="monto"
                        label="monto"
                        prefix={simbolo}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                        _onChange={(e) => {
                            this.setState({monto: e});
                            console.log(e);
                            cambioDescuento(e, 'monto', 0, 'ReintegroForm')
                        }}
                    />
                </div>
                <div className="row col-12 m-0 p-0">
                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                    <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                    <Field
                        name="tipo_gasto"
                        label="nombre"
                        component={renderSelecttree}
                        showSearch = { true }
                        filtro = "title"
                        options={tipo_gastos}
                        type="text"
                        valueKey="value"
                        labelKey="label"
                        className="form-control" />
                </div>
                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                </div>
            </div>
                {
                    (tipo_cuenta && !es_tarjeta) && (
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="numero_cuenta">No. de Cuenta</label>
                            <Field
                                name="numero_cuenta"
                                label="numero_cuenta"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={cuentas}
                                className="form-control"
                            />
                        </div>
                    )
                }
                {
                    (es_tarjeta) && (
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                             <label className="t-azul" htmlFor="nombre">POS</label>
                            <Field
                                name="poss"
                                label="poss"
                                component={AsyncSelectField}
                                loadOptions={getPoss}
                                type="text"
                                valueKey="id"
                                labelKey="nombre"
                                onChange={(e) => {
                                }}
                                isClearable={false}
                                className="form-control" />
                        </div>
                    )
                }

            </div>

            {
                (esCobro && esCheque) && (
                    <div className="row col-12 m-0 p-0">
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="nombre">Fecha de Cobro</label>
                            <Field
                                name="fecha_cobro"
                                selectedDate={new Date()}
                                maxDate="2030-12-31"
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                        </div>
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="nombre">Banco de referencia</label>
                            <Field
                                name="banco_referencia"
                                className="form-control"
                                component={renderField}
                            />
                        </div>
                    </div>
                )
            }
            {
                (es_gasto) && (
                    <div className="row col-12 m-0 p-0">
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="descripcion">Descripción</label>
                            <Field
                                name="descripcion"
                                label="descripcion"
                                component={renderTextArea}
                                type="text"
                                className="form-control" />
                        </div>
                    </div>
                )
            }
            {
                (this.state.monto > 0) && (
                    <Fragment>
                        <h4 className="t-primary text-uppercase">DESGLOSE</h4>
                        <div className="col-12 m-0 p-0 d-flex justify-content-center">
                            <FieldArray
                                cambioDescuento={cambioDescuento}
                                simbolo={'Q'}
                                name="desglose"
                                empresas={this.props.empresas}
                                component={renderDesglose} />
                        </div>
                    </Fragment>
                )
            }

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                        <button type="button" onClick={cerrarModal} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">
                            {
                                (texto_boton_movimiento) ? (`${texto_boton_movimiento}`) : ("AGREGAR PAGO")
                            }
                        </button>
                    </div>
                </div>
            </div>
        </form>
        )
    }
}

ReintegroForm =  reduxForm({
    form: 'ReintegroForm', // a unique identifier for this form
    initialValues: {
        tipo_cuenta: 'true',
        fecha: new Date(),
        fecha_cobro: new Date(),
    },
    validate: (data) => {
        const errors = {};
        if(!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if(!data.monto){
            errors.monto = "Campo requerido";
        }else{
            if (data.monto <= 0) {
                errors.monto = "Debe ser mayor a 0";
            }
        }
        if(!data.numero_cuenta){
            errors.numero_cuenta = "Campo requerido";
        }
        if(!data.tipo_documento){
            errors.tipo_documento = "Campo requerido";
        }
        if(!data.no_documento){
            errors.no_documento = "Campo requerido";
        }
        if(!data.tipo_gasto){
            errors.tipo_gasto = "Campo requerido";
        }
        if(!data.desglose || !data.desglose.length){
            errors.desglose = {_error: 'Debe de ingresar al menos una empresa'}
        }else {

            const detalleArray = []
            let hay_error = false
            data.desglose.forEach(function(empresa, index){
                let detErrors = {};
                let repetidos = [];
                if(empresa.empresa){
                    repetidos = _.filter(data.desglose, (x) => {
                        if(x.empresa){
                            return  x.empresa == empresa.empresa
                        }

                        return false;
                        });
                }

                if(!empresa.empresa){
                    detErrors.empresa =  "Campo requerido";
                    hay_error = true
                }else {
                    if(1 < repetidos.length){
                        detErrors.empresa = "Esta empresa está repetido"
                        hay_error = true
                    }
                }
                if(!empresa.subtotal){
                    detErrors.subtotal = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.subtotal) <= 0){
                        detErrors.subtotal = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }
                if(!empresa.porcentaje){
                    detErrors.porcentaje = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.porcentaje) <= 0){
                        detErrors.porcentaje = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }

                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.desglose = detalleArray
                if(!hay_error){
                     /*Manejo del total de desglose */
                    let subtotal = 0;
                    data.desglose.forEach( x => {
                        subtotal += Number(x.subtotal)
                    })
                    console.log('sub total: ', subtotal, data.monto)
                    if(Number(subtotal) != Number(data.monto)){
                        console.log('La sumatoria es diferente')
                        errors.desglose = {_error: 'La sumatoria del desglose no coincide con el monto del gasto'}
                    }
                    /*Fin del desglose */
                }

            }
        }
        return errors;
    },
})(ReintegroForm);

const selector = formValueSelector('ReintegroForm');
ReintegroForm = connect(state => {
    const tipo_movimiento = selector(state, 'tipo_documento')
    const tipo_cuenta_form = selector(state, 'tipo_cuenta')

    let tipo_cuenta = true
    let esCheque = false;
    let es_tarjeta = false;
    if(tipo_cuenta_form == "false"){
        tipo_cuenta = false
    }
    let tipo_documento = 'Documento de Registro'
    if(tipo_movimiento){
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        tipo_documento = seleccionado.ayuda
        if (seleccionado.value == 2){esCheque = true}
        if(seleccionado.value == 5){es_tarjeta = true}
    }
    return{
        tipo_documento,
        tipo_cuenta,
        esCheque,
        es_tarjeta
    }


})(ReintegroForm);


export default ReintegroForm;
