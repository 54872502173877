import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda, RenderNombreSelect} from '../../../Utils/renderField/renderTableField';


export default class ProductoGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos.actividades === false) {
            if(!!permisos.ver_actividades === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton="Agregar"
                    ruta="actividad/crear"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={permisos.actividades}
                />}
                titulo="actividades">
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {permisos.actividades ? (
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                isKey
                                dataFormat={
                                    standardActions({ editar: "actividad",  eliminar })
                                }
                            />
                        ): (
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                width="2%"
                                isKey
                                dataFormat={cell => ""}
                            />
                        )}
                        <TableHeaderColumn

                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                        >
                            DESCRIPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="monto"
                            dataSort
                            dataFormat = {
                                (cell, row)=>(
                                    <RenderMoneda monto={ cell } simbolo={'Q'} />
                                )
                            }
                        >
                            MONTO
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
