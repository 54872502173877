import React, {Component} from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import {standardActions} from "../../../Utils/Grid/StandardActions";

import CardSimple from '../../../Utils/Cards/cardSimple';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import MiCaja from './miCaja';
import EgresoForm from './egresoForm';
import RetiroForm from './retiroForm';
import HistorialCaja from './historialCaja';
import HistorialLiquidaciones from '../../liquidaciones/liquidacionesGrid/historial';
import EstadoPeriodo from './periodoCaja';
import CerrarEstadoPeriodo from './cerrarPeriodoCaja';
import HistorialDetalle from '../../liquidaciones/liquidacionesGrid/historialDetalle'

import {LiquidacionesGrid} from '../../liquidaciones';


class DetalleCaja extends Component{
    state = {
        open_modal:false,
        open_modal_retiro:false,
        modalHistory:false,
        modalHistorialLiquidaciones: false,
        open_modal_cierre: false,
        periodo: null
    }
    handleSubmit = (values) => {
        if(values.egreso_tipo == "true"){
            values.caja = undefined;
        } else {
            values.banco = undefined;
        }
        this.props.movimiento(values, this.props.match.params.id);
        this.setOpenModal(false);
    }
    handleSubmitRetiro = (values) => {
        this.props.retiroDeCaja(values, this.props.match.params.id);
        this.setOpenModalRetiro(false);
    }
    setOpenModal = (val)=>{
        this.setState({open_modal:val})
        if (val===true)
            this.props.listar()
    }
    setOpenModalRetiro = (val)=>{
        this.setState({open_modal_retiro:val})
        if (val===true)
            this.props.selectEmpresas()
    }
    setOpenModalHistory = (val, row)=>{
        this.setState({modalHistory:val})
        if (val === true)
            this.props.estadoPeriodo(row.id)
            // this.setState({periodo:row})
    }
    setOpenModalHistorilLiquidaciones = (val, row)=>{
        this.setState({modalHistorialLiquidaciones: val})
        if (val === true)
        this.props.getLiquidacionEP(row.id)
    }
    componentDidMount(){
        let id = this.props.match.params.id;
        let {leer, listPCaja, estadoMiCaja } = this.props
        // lee la informaciond el estado de la caja del ultimo periodo
        leer(id);
        //lista los traslados que etan pendientes de confirmar en ambas vias
        listPCaja(id);
        //obtiene el estado de cuenta de la caja en el ultimo periodo
        estadoMiCaja(id);
    }
    componentDidUpdate(prevProps){
        if (this.props.match.params.id != prevProps.match.params.id){
            let id = this.props.match.params.id;
            let {leer, listPCaja, estadoMiCaja } = this.props
            // lee la informaciond el estado de la caja del ultimo periodo
            leer(id);
            //lista los traslados que etan pendientes de confirmar en ambas vias
            listPCaja(id);
            //obtiene el estado de cuenta de la caja en el ultimo periodo
            estadoMiCaja(id);
        }
    }
    setOpenModalCierre = (val)=>{
        this.setState({open_modal_cierre: val})
        if (val === true)
            this.props.cerrarEstadoPeriodo()
    }
    cerrarperiodo = () =>{
        this.props.cierredecaja(this.props.match.params.id)
    }

    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    render(){
        let {data, item, loader, entidad, empresas, permisos, page, onSortChangeRefuse, dataHistorialLiquidaciones,
            listarHistorialLiquidaciones
         } = this.props

        return(
            <CardSimple
                titulo={item? `caja de: ${item.nombre_dueno} ${item.apellido_dueno}`: "n/a" }
                margen={' '}
            >

                <Modal open={this.state.open_modal_retiro} onClose={(e) => {
                        this.setOpenModalRetiro(false)
                    }} center>
                        <div className="">
                            <h2 className="text-center text-primary text-uppercase">Registrar Retiro</h2>
                        </div>
                        <RetiroForm
                            loader={loader}
                            initialValues={{fecha:new Date()}}
                            data={empresas}
                            onSubmit={this.handleSubmitRetiro}
                            setOpenModalRetiro={this.setOpenModalRetiro}
                        />
                </Modal>
                <Modal open={this.state.open_modal} onClose={(e) => {
                        this.setOpenModal(false)
                    }} center>
                        <div className="">
                            <h2 className="text-center text-primary text-uppercase">Registrar transferencia</h2>
                        </div>
                        <EgresoForm
                            boton={this.props.boton}
                            initialValues={{fecha:new Date(), egreso_tipo: 'false'}}
                            data={data}
                            onSubmit={this.handleSubmit}
                            setOpenModal={this.setOpenModal}
                        />
                </Modal>
                <Modal
                    open={this.state.modalHistory}
                    onClose={(e) => {
                        this.setOpenModalHistory(false)
                    }}
                    center
                    >
                        <div className="">
                            <h2 className="text-center text-primary text-uppercase">Historial de movimientos</h2>
                        </div>
                        <EstadoPeriodo
                            {...this.props}
                            data={this.props.periodo}
                            loader={loader}
                        />
                </Modal>
                <Modal open={this.state.open_modal_cierre} onClose={(e) => {
                        this.setOpenModalCierre(false)
                    }} center>
                        <div className="">
                            <h2 className="text-center text-primary text-uppercase">¿Desea cerrar la caja?</h2>
                        </div>
                        <CerrarEstadoPeriodo
                            data={this.props.periodo}
                            loader={loader}
                            cerrarperiodo={this.cerrarperiodo}
                            setOpenModalCierre={this.setOpenModalCierre}
                        />
                </Modal>
                <Modal
                    open={this.state.modalHistorialLiquidaciones}
                    onClose={(e) => {
                        this.setOpenModalHistorilLiquidaciones(false)
                    }}
                    center
                    >
                        <HistorialDetalle
                            {...this.props}
                            data={this.props.periodo}
                            loader={loader}
                        />
                </Modal>
                <Tabs
                    defaultActiveKey="CAJACHICA"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="CAJA" key="CAJA">
                        {/* ACTIVDAD RECIENTE DE CAJA CHICA */}
                        <MiCaja
                            {...this.props}
                            data={this.props.estado}
                            item={item}
                            role={this.props.role}
                            loader={loader}
                            entidad={entidad}
                            setOpenModal={this.setOpenModal}
                            setOpenModalRetiro={this.setOpenModalRetiro}
                            setOpenModalCierre = {this.setOpenModalCierre}
                            pendientes={this.props.pendientes}
                            AcceptMovimiento={this.props.AcceptMovimiento}
                            RefuseMovimiento={this.props.RefuseMovimiento}
                            CancelMovimiento={this.props.CancelMovimiento}
                            idcaja={this.props.match.params.id}
                            permisos={permisos}
                            dateStart={this.props.dateStart}
                            dateEnd={this.props.dateEnd}
                            setDateStart={this.props.setDateStart}
                            setDateEnd={this.props.setDateEnd}
                            changeMovimiento={this.props.changeMovimiento}
                            valorMovimiento={this.props.valorMovimiento}
                            total_debe={this.props.total_debe}
                            total_haber={this.props.total_haber}
                        />
                    </TabPane>
                    <TabPane tab="HISTORIAL" key="HISTORIAL">
                        {/* HISTORIAL DE CAJA CHICA */}
                        <HistorialCaja
                            historial={this.props.historial}
                            pagehistorial={this.props.pagehistorial}
                            loader={this.props.loader}
                            HistorialMiCaja={this.props.HistorialMiCaja}
                            caja={this.props.match.params.id}
                            setOpenModalHistory = {this.setOpenModalHistory}
                        />
                    </TabPane>
                    <TabPane tab="LIQUIDACIONES" key="LIQUIDACIONES">
                        <LiquidacionesGrid
                        id = {item.id}
                        />
                    </TabPane>
                    <TabPane tab="HISTORIAL LIQUIDACIONES" key="HISTORIAL-LIQUIDACIONES">
                        <HistorialLiquidaciones
                            id={item.id}
                            permisos={permisos}
                            data={dataHistorialLiquidaciones}
                            loader={loader}
                            page={page}
                            listar ={listarHistorialLiquidaciones}
                            setOpenModal={this.setOpenModalHistorilLiquidaciones}
                        />
                    </TabPane>
                </Tabs>
            </CardSimple>
        )
    }
}

export default withRouter(DetalleCaja);
