import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderSelectField, AsyncSelectField, renderCurrency, renderPercentage } from '../../../Utils/renderField/renderField';
import { Link, Redirect } from 'react-router-dom';
import {SelectField} from '../../../Utils/renderField/renderField.js'
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";

import {api} from 'api';

const getRegiones = (search) => {
    return api.get("region", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


const renderRegiones = ({fields, meta: {error, submitFailed }, contactos}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th> REGIÓN</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((REGION, index) => (
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar"
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${REGION}.region`}
                                    type="text"
                                    component={AsyncSelectField}
                                    loadOptions={getRegiones}
                                    valueKey={'id'}
                                    labelKey={'region'}
                                    top ={{top:"67px", position:"inherit"}}
                                    className="form-control"
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div>
        </div>
    </div>
)

const formatE = (array=[]) =>{
    const nuevoArray = [];
    array.forEach((item,index) => {
        nuevoArray[index]={"label": item.nombre , "value": item.id}
    });
    return nuevoArray
}

const renderMetas = ({fields, meta: {error, submitFailed }, empresas, format}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Empresa</th>
                        <th>Meta</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((REGION, index) => (
                        <tr key={index} style={{height:"68px"}}>
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar"
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${REGION}.empresa`}
                                    label="empresa"
                                    options={formatE(empresas)}
                                    component={renderSelectField}
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${REGION}.meta`}
                                    label="empresa"
                                    component={renderCurrency}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({'meta': 0})}>
                    AGREGAR
                </button>
            </div>
        </div>
        {
            (submitFailed || error) && (
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>
            )
        }
    </div>
)
class UsuariosForm extends React.Component {
    render(){
        const { handleSubmit, regiones, format, roles, empresas, vendedores_form, pilotos_form, sumatoria} = this.props;
        console.log(this.props)
        return(
            <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-sm-6  col-12">
                        <label className="t-azul" htmlFor="username">Nombre de usuario*</label>
                        <Field name="username" label="Usuario" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="form-group has-feedback  col-sm-6  col-12">
                        <label className="t-azul"  htmlFor="first_name">Nombre*</label>
                        <Field name="first_name" label="Nombre" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-sm-6  col-12">
                        <label className="t-azul"  htmlFor="last_name">Apellido*</label>
                        <Field name="last_name" label="Apellido" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="form-group has-feedback  col-sm-6  col-12">
                        <label className="t-azul"  htmlFor="password">Contraseña*</label>
                        <Field
                            name="password"
                            label="Contraseña"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-sm-6  col-12">
                        <label className="t-azul"  htmlFor="email">Correo*</label>
                        <Field name="email" label="email" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="form-group has-feedback col-sm-6  col-12">
                        <label className="t-azul"  htmlFor="phone">Teléfono</label>
                        <Field name="profile.phone" label="phone" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
                {
                    (vendedores_form) && (
                    <div className="row justify-content-start">
                        <div className="form-group has-feedback col-sm-6  col-12">
                            <label className="t-azul"  htmlFor="email">Porcentaje de comision*</label>
                            <Field
                                name={`profile.porcentaje_comision`}
                                type="number"
                                addClass={"text-right"}
                                component={renderPercentage}
                                placeholder="Comision"
                                // _onChange={(e) => { cambioDescuento(e, 'porcentaje', index) }}
                            />
                        </div>
                    </div>
                    )
                }
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-sm-12  col-12">
                        <label className="t-azul"  htmlFor="address">Dirección</label>
                        <Field name="profile.address" label="address" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
                {
                    (!vendedores_form) && (!pilotos_form) && (
                        <div className="row justify-content-center">
                            <div className="form-group has-feedback col-sm-6  col-12">
                                <label className="t-azul" htmlFor="role">Role*</label>
                                <Field
                                    name="profile.role"
                                    label="role"
                                    options={format(roles)}
                                    component={renderSelectField}
                                    />
                            </div>
                            <div className="form-group has-feedback  col-sm-6  col-12">
                                <label className="t-azul" htmlFor="empresa">Empresa</label>
                                <Field
                                    name="profile.empresa"
                                    label="empresa"
                                    options={format(empresas)}
                                    component={renderSelectField}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    (vendedores_form) && (!pilotos_form) && (
                        <div className="row justify-content-start">
                            <div className="form-group has-feedback col-sm-6  col-12">
                                <label className="t-azul" htmlFor="role">Role*</label>
                                <Field
                                    name="profile.role"
                                    label="role"
                                    options={format(roles)}
                                    component={renderSelectField}
                                    />
                            </div>
                        </div>
                    )
                }
                
                {
                    (vendedores_form) && (
                        <div className="row justify-content-start">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul"  htmlFor="region">Región</label>
                                <FieldArray
                                    name="profile.region"
                                    label="region"
                                    component={renderRegiones}
                                />
                            </div>
                        </div>
                    )
                }
                {/* metas de venta */}
                {
                    (vendedores_form) && (
                        <div>
                            <div className="row justify-content-start">
                                <div className="form-group has-feedback col-12">
                                    <label className="t-azul"  htmlFor="region">Metas por ventas</label>
                                    <FieldArray
                                        name="profile.metas"
                                        label="metas"
                                        component={renderMetas}
                                        empresas={empresas}
                                        format={format}
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-end">
                                <div className="form-group has-feedback col-md-4 col-sm-6 col-12">
                                    <label className="t-azul">TOTAL METAS:</label>
                                    <h3 className="t-primary"  htmlFor="region">{<RenderMoneda monto={sumatoria}/>}</h3>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="row  justify-content-sm-around justify-content-center">
                        <Link className="btn btn-secondary  m-1 align-self-center" to={`${!vendedores_form ?  !pilotos_form ? "/usuarios" : "/pilotos" : '/vendedores'}`} >CANCELAR</Link>
                        <button type="submit" className="btn btn-primary m-1  align-self-center">GUARDAR</button>
                </div>
            </form>

        )
    }
}
// const UsuariosForm = (props) => {
//     return (
//     );
// };

UsuariosForm = reduxForm({
    form: 'EditarUsuarioForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if(!data.username){
            errors.username = "Campo requerido"
        }
        if(!data.first_name){
            errors.first_name = "Campo requerido"
        }
        if(!data.last_name){
            errors.last_name = "Campo requerido"
        }
        if(!data.email){
            errors.email = "Campo requerido"
        }
        const errorsProfile = {};

        if (!props.vendedores_form && (data.profile && !data.profile.role)){
            errorsProfile.role = "Campo requerido"
        }
        else if(props.vendedores_form && (data.profile && !data.profile.role)){
            errorsProfile.role = "Campo requerido"
        }
        // if (props.vendedores_form && data.profile && !data.profile.region)
        // {
        //     errorsProfile.region = "Campo requerido"
        // }
        if (errorsProfile){
            errors.profile = errorsProfile;
        }
        if ( data.profile && (data.profile.metas || data.profile.length) ){
            const detalleArray = []
            data.profile.metas.forEach( function(meta, index){
                let detErrores = {}
                let repetidos = []
                if (meta.empresa){
                    repetidos = _.filter(data.profile.metas, (x)=>{
                        if (x.empresa)
                            return x.empresa == meta.empresa
                        return false;
                    });
                }
                if (!meta.empresa){
                    detErrores.empresa = "campo requerido";
                }else{
                    if(1 < repetidos.length){
                        detErrores.empresa = "Este nombre esta repetido"
                    }
                }
                if(detErrores){
                    detalleArray[index] = detErrores;
                }
            })
            if(detalleArray.length){
                errors.profile.metas = detalleArray
            }
        }
        return errors
    },
})(UsuariosForm);

const selector = formValueSelector('EditarUsuarioForm');
UsuariosForm = connect(state =>{

    const perfil = selector(state, 'profile')
    let sumatoria = 0;
    if (perfil){
        const metas = perfil.metas ? perfil.metas:[]
        metas.forEach((item, index) => {
            if (item.meta){
                sumatoria += parseFloat(item.meta)
            }
        })

    }
    return{
        sumatoria
    }
})(UsuariosForm);

export default UsuariosForm;
