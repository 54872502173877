import React, {Component} from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import {customStandardActions} from "../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardSimple from '../../../Utils/Cards/cardSimple';

export default class RegionesGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos["regiones"] === false) {
            if(!!permisos[`ver_regiones`] === false) {
                return <Redirect to={`/`} />
            }
        } 
        return(
            <CardSimple
                titulo={`REGIONES`}
                toolbar={
                    <ToolbarSimple 
                        textBoton={permisos.crear_regiones ? "AGREGAR" : ""}
                        ruta="/regiones/nueva"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Region"}
                        permisos={permisos.regiones}
                    />
                }
            >
                <div className="row">
                    <div className="mb-4 col-12">
                       
                        <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange} onSortChange={onSortChange} >
                            {permisos.regiones && (<TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={(cell) => {
                                    return customStandardActions(cell, {
                                        eliminar: permisos.eliminar_regiones ? eliminar: undefined,
                                        editar: permisos.editar_regiones ? "regiones": undefined,
                                    });
                                }}
                            >
                            </TableHeaderColumn>)}
                            <TableHeaderColumn
                                isKey
                                dataField="region"
                                dataSort
                            >
                                REGION
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                            >
                                DESCRIPCION
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>

            </CardSimple>
                
        )
    }
}

