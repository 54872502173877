import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/OrdenVenta/ordenventa';
import DetalleOV from './detalleOV';

const mstp = state => {
    return {...state.ordenventa,  permisos: state.login.me.permisos, rol: state.login.me.profile.rol_name}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetalleOV)
