import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';



//Extras
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";




export default class GridDetalle extends Component {
    componentWillMount() {
      
    }
    render() {
        const { data, loader, listar: onPageChange, onSortChange, page } = this.props;
        return (
           <Grid hover
                striped data={{count:10, results:data}}
                loading={loader} 
                pagination={false}
            >
                <TableHeaderColumn
                    dataField="producto"
                    dataSort
                    isKey
                    dataFormat = {(cell, row) => {
                        return (
                            <React.Fragment>
                                {cell.nombre}
                                {(row.tipo_devolucion === 40 || row.tipo_devolucion === '40') &&
                                    <React.Fragment>
                                        <div style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                                            REEMPLAZO
                                        </div>
                                        {row.producto_cambio.nombre}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    }}
                >
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataSort
                    dataFormat={(cell, row) => {
                        return(
                            <React.Fragment>
                                {cell}
                                {(row.tipo_devolucion === 40 || row.tipo_devolucion === '40') &&
                                    <React.Fragment>
                                        <br/>
                                        <br/>
                                        {row.producto_cambio.cantidad_a_cambiar}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    }}
                >
                    CANTIDAD
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tipo_devolucion_label"
                    dataSort
                >
                    TIPO DE DEVOLUCION
                </TableHeaderColumn>
            </Grid>
        );
    }
}
