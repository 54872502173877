import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reportevales';
import ReporteValesGasolina from './ReporteValesGasolina';

const mstp = state => {
    return {
        ...state.reporteValesGasolina,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteValesGasolina)
