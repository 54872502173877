
import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "ausencias",
    "ausencias",
    "AusenciasForm",
    "/ausencias",
);

export default handleActions(reducers, initialState);
