import React, { Component, Fragment } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { Monedas } from "../../../../../utility/constants";
import { withRouter } from 'react-router';

import ToolbarEstadoCuenta from '../../../Utils/Toolbar/ToolbarEstadoCuenta';
import CardSimple from '../../../Utils/Cards/cardSimple';
import Modal from 'react-responsive-modal';
import AnulacionForm from './anulacionForm';
import RealizarPagoForm from './realizarPagoForm';
import RetiroForm from './retiroForm';
import EstadoCuentaForm from './estadoCuentaForm';

import Swal from 'sweetalert2';
import moment from 'moment';

import { getSimbolo } from 'Utils/renderField/renderTableField';
import CardsCaja from '../../../Utils/Cards/cardsCaja';

// iconos
import { iconos } from '../../../../../utility/icons';
import ImagenAlerta from '../../../../../../assets/img/icons/alarm.png'


function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}

class TransaccionesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };
    state = {
        id_transaccion: 0,
        transaccion_sel: null,
        anular_transaccion: false,
        open_modal_retiro: false,
        archivo: null,
        open_modal_cuenta: false,
        open_modal_ver: false
    }
    componentDidMount() {
        this.cargar();
    }

    cargar = () => {
        // Funcion que llama a las acciones del reducer de transacciones para cargar el periodo seleccionado
        // y el listado de transacciones de dicho periodo
        let id = this.props.match.params.id
        const { listPCaja, page, meCuenta, getPeriodo } = this.props;
        meCuenta(id, true);
        getPeriodo();
        listPCaja(id);
    }

    getsimbolo = (valor) => {
        let simbolo = ''
        Monedas.forEach((item, index) => {
            if (item.value === valor) {
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    setFile = (file) => {
        this.setState({ file });
    }
    botonModal = (id, row) => {

        if (row.estado) {
            return (<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../assets/img/icons/Ver.png")}
            onClick={(e) => {
                e.preventDefault();
                this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    anular_transaccion: true
                })
                this.props.setOpenModal(true);
            }}
            alt="Ver eliminado" />)
    }

    botonModalDetalle = (id, row) => {
        if (row.estado) {
            return (<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../assets/img/icons/Ver.png")}
            onClick={(e) => {
                e.preventDefault();
                this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    anular_transaccion: true
                })
                this.props.setOpenModalDetalle(true);
            }}
            alt="Ver eliminado" />)
    }

    botonRealizarMovimiento = (id, row) => {
        if (!row.estado) {
            return (<div></div>)
        }
        if (row.bloqueado) {
            return (<div></div>)
        }
        if (row.transaccion.flotante_inverso) {
            return (
                <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }} onClick={
                    (e) => {
                        e.preventDefault();
                        this.props.setTransaccion({
                            ...row.transaccion,
                            id_cuenta: this.props.match.params.id
                        });
                    }
                }>
                    <img className="action-img" title="Ver flotante" src={require("../../../../../../assets/img/icons/Pagos_flotantes.png")} alt="Ver pago flotante" />
                </a>
            )
        } else {
            if (!row.flotante) {

                return (<div></div>)
            }
            return (
                <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }} onClick={
                    (e) => {
                        e.preventDefault();
                        this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            anular_transaccion: false
                        })
                        this.props.setOpenModal(true);
                    }
                }>
                    {
                        (row.tipo_flotante) ? (
                            <img
                                className="action-img" title="cheque cliente"
                                src={iconos.cheque_cliente} alt="Cheque cliente" />
                        ) : (
                                <img
                                    className="action-img" title="Cheque proveedor"
                                    src={iconos.cheque_proveedor} alt="Cheque proveedor" />
                            )
                    }

                </a>
            )
        }

    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModal(true);
    }

    abrirModalDetalle = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModalDetalle(true,row);
    }

    onSubmit = (values) => {
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values);
                this.cargar();
            }
        });
    }


    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD')
                this.props.flotanteChequePagado(values);
            }
        });
    }


    cerrerPeriodo = () => {
        Swal.fire({
            title: '¿Desea cerrar el periodo?',
            text: '¡Ya no podrá realizar nuevas transacciones ni anulaciones!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, cerrar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: '¿Está seguro de cerrar el periodo?',
                    text: '¡No podrá revertir esta acción!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, cerrar!',
                    cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.props.cierrePeriodo(this.props.match.params.id);
                    }
                });
            }
        });
    }
    renderModalAnulacion = () => {
        return (
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>
                {
                    (this.state.transaccion_sel && !this.state.transaccion_sel.estado) ? (
                        <div className="form-validate mb-lg text-center" >
                            {console.log(this.state.transaccion_sel)}
                            <span className="m-none t-mostaza semibold">Fecha anulación: </span><br />
                            <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br />
                            <span className="m-none t-mostaza semibold">Usuario anulación: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br />
                            <span className="m-none t-mostaza semibold">descripción: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br />
                        </div>
                    ) : (
                            <AnulacionForm
                                onSubmit={this.onSubmit}
                                cerrarModal={this.props.setOpenModal}
                            />
                        )
                }
            </Fragment>
        )
    }

    renderModalDetalle = () => {
        const { open_modal_ver } = this.props;
        const { transaccion_sel } = this.state;
        const abonosRelacionados = transaccion_sel&&transaccion_sel.abonos_relacionados_bancos || [];
        return (
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">Detalle del Pago</h2>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>No. Factura</th>
                            <th>No. Despacho</th>
                            <th>Monto Abonado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {abonosRelacionados.map(abono => (
                            <tr key={abono.id}>
                                <td>{abono.no_factura_rel}</td>
                                <td>{abono.no_despacho_rel}</td>
                                <td>Q   {abono.monto_abonado.toLocaleString('es-GT')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        )
    }

    renderModalFlotantePagado = () => {
        let transaccion = null
        if (this.state.transaccion_sel) {
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            }
        }
        return (
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">REALIZAR PAGO</h2>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={this.props.setOpenModal}
                />
            </Fragment>
        )
    }
    setOpenModalRetiro = (val) => {
        this.setState({ open_modal_retiro: val })
        if (val === true)
            this.props.selectEmpresas()
    }
    setOpenModalEstadoCuenta = (val) => {
        if (val)
            this.setState({ open_modal_cuenta: val })
        else
            this.setState({ open_modal_cuenta: val, file: null })

    }
    handleSubmitRetiro = (values) => {
        values.cuenta = this.props.match.params.id
        this.props.retiroDeCuenta(values, this.props.match.params.id);
        this.setOpenModalRetiro(false);
    }
    handleSubmitEstadoCuenta = (values) => {
        const { subirEstadoCuenta } = this.props
        if (this.state.file) {
            subirEstadoCuenta(
                { cuenta: this.props.match.params.id },
                [{ name: "csv", file: this.state.file }],
                this.props.match.params.id
            )
            this.setOpenModalEstadoCuenta(false);
        } else {
            subirEstadoCuenta(
                { cuenta: this.props.match.params.id },
                [],
                this.props.match.params.id
            )
        }
    }
    handleBloqueo = (id) => {
        const idCuenta = this.props.match.params.id
        this.props.bloquear_transaccion(id, idCuenta);
    }
    aceptarEnvio = (id) => {
        this.props.AcceptMovimiento(id, this.props.match.params.id);
    }
    rechazarEnvio = (id) => {
        this.props.RefuseMovimiento(id, this.props.match.params.id);
    }
    cancelEnvio = (id) => {
        this.props.CancelMovimiento(id, this.props.match.params.id);
    }
    render() {
        const { data, me, loader, listar: onPageChange, onSortChange, eliminar, periodo, valorMovimiento, open_modal,open_modal_ver, permisos } = this.props;
        let id = this.props.match.params.id;
        return (
            <CardSimple
                titulo={me ? me.propietario : ""}>

                <CardsCaja
                    data={this.props.pendientes}
                    loader={loader}
                    aceptarEnvio={this.aceptarEnvio}
                    rechazarEnvio={this.rechazarEnvio}
                    cancelEnvio={this.cancelEnvio}
                    titulo={"TRANSFERENCIAS PENDIENTES"}
                />
                <Modal open={open_modal_ver} onClose={(e) => {this.props.setOpenModalDetalle(false)}} center>
                    {
                      (
                        this.renderModalDetalle()
                      )
                    }
                </Modal>

                <Modal open={open_modal} onClose={(e) => {
                    this.props.setOpenModal(false)
                }} center>
                    {
                        (this.state.anular_transaccion) ? (
                            this.renderModalAnulacion()
                        ) : (
                                this.renderModalFlotantePagado()
                            )
                    }
                </Modal>
                <Modal open={this.state.open_modal_retiro} onClose={(e) => {
                    this.setOpenModalRetiro(false)
                }} center>
                    <div className="">
                        <h2 className="text-center text-primary text-uppercase">Registrar Retiro</h2>
                    </div>
                    <RetiroForm
                        initialValues={{ fecha: new Date() }}
                        simbolo={getSimbolo(periodo.moneda)}
                        manejo_tipo_cambio
                        data={this.props.empresas}
                        onSubmit={this.handleSubmitRetiro}
                        setOpenModalRetiro={this.setOpenModalRetiro}
                    />
                </Modal>
                <Modal open={this.state.open_modal_cuenta} onClose={(e) => {
                    this.setOpenModalEstadoCuenta(false)
                }} center>
                    <div className="">
                        <h2 className="text-center text-primary text-uppercase">SUBIR ESTADO DE CUENTA</h2>
                    </div>
                    <EstadoCuentaForm
                        setFile={this.setFile}
                        onSubmit={(values) => { this.handleSubmitEstadoCuenta(values); }}
                        setOpenModalEstadoCuenta={this.setOpenModalEstadoCuenta}
                    />
                </Modal>
                <div className="col-md-12 row m-0  p-0 mt-3 d-flex justify-content-between">
                    <div className="col-lg-12 col-md-12 p-0 row">
                    <div className="row col-12 p-0">
                            <ToolbarEstadoCuenta 
                            {...this.props} 
                            buscar={this.props.searchChange}
                            buscador={this.props.search} />
                        </div>
                        {
                            (!periodo.es_cerrado) && (
                                <div className="col-lg-12  col-sm-12 mt-3 col-12 mt-5 mb-4 pl-0 ml-0 ml-md-1 ">

                                    <div className="col-12 m-0 p-0 mt-3 d-flex flex-wrap justify-content-evenly flex-column flex-sm-row flex-column">
                                        <Link className="btn btn-t mb-1 btn-primary align-self-center mr-md-2 " to={{
                                            pathname: `/transaccion/nueva/${id}`,
                                            state: {
                                                Ingreso: false
                                            }
                                        }}>EGRESO</Link>
                                        <Link className="btn btn-t mb-1 btn-mostaza align-self-center" to={{
                                            pathname: `/transaccion/nueva/${id}`,
                                            state: {
                                                Ingreso: true
                                            }
                                        }}
                                        >
                                            INGRESO
                                        </Link>
                                        {
                                            (permisos["retiro_banco"] === true) && (
                                                <button type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.setOpenModalRetiro(true);
                                                    }}
                                                    className="btn btn-t mb-1 btn-verde-obscuro align-self-center mr-md-2">RETIRO</button>
                                            )
                                        }
                                        <button type="button"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.cerrerPeriodo();
                                            }}
                                            className="btn btn-azul-claro mb-1 align-self-center">CERRAR
                                        </button>
                                        {
                                            (periodo.es_principal) && (
                                                <button type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // this.handleSubmitEstadoCuenta(1);
                                                        this.setOpenModalEstadoCuenta(true);
                                                    }}
                                                    className="btn btn-ec mb-1 align-self-center">SUBIR E.C.
                                                </button>
                                            )
                                        }
                                    </div>



                                </div>
                            )
                        }
                        <div className="col-12 mt-3 mb-4">
                            <div className="row col-12 m-0 p-0">
                                <div className="col-md-4 col-sm-6 col-12 ">
                                    <span className="t-musgo font-weight-bold">SALDO INICIAL</span>
                                    <h4 className="t-mostaza m-none">{<RenderMoneda monto={periodo.saldo_apertura} simbolo={this.getsimbolo(periodo.moneda)} />}</h4>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="t-musgo font-weight-bold">
                                        SALDO {periodo.es_cerrado ? 'FINAL' : 'ACTUAL'}
                                    </span>
                                    <h2 className="t-primary m-none">{
                                        <RenderMoneda monto={
                                            me.saldo_reserva ?
                                                (
                                                    me.saldo_reserva + me.saldo_a
                                                ) : (
                                                    periodo.saldo_cierre ? periodo.saldo_cierre : periodo.saldo_apertura
                                                )
                                        } simbolo={this.getsimbolo(periodo.moneda)} />
                                    }</h2>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="t-musgo font-weight-bold">SALDO DISPONIBLE</span>
                                    <h4 className="t-azul m-none">{<RenderMoneda monto={periodo.saldo_cierre - me.bloqueados} simbolo={this.getsimbolo(periodo.moneda)} />}</h4>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="tabla-scoll">
                    <Grid hover striped data={{ count: 10, results: periodo.movimientos }} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} >
                        <TableHeaderColumn
                            dataField="id"
                            width="5%"
                            dataAlign="right"
                            tdStyle={cellTachado}
                            dataSort
                            dataFormat = {(cell, row) => {
                                let sinFacturas = false;

                                if (row.abonos_relacionados_bancos && row.abonos_relacionados_bancos.length != 0) {
                                    row.abonos_relacionados_bancos.forEach(item =>{
                                        if (item.no_factura_rel.length == 0) sinFacturas = true;
                                    })
                                }

                                if (row.es_ingreso && sinFacturas) {
                                    return <div><img className="action-img"  src={ImagenAlerta} alt="Imagen"/></div>
                                }
                            }}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            width="10%"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell, row) => {
                                return (row.asignado_a === null) ? standardActions({
                                    eliminarModal: this.abrirModalEliminar,
                                    adicional: this.botonModal,
                                    adicional2: this.botonRealizarMovimiento,
                                    candado: this.handleBloqueo, 
                                })(cell,row)
                                :
                                standardActions({
                                    eliminarModal: this.abrirModalEliminar,                                
                                    adicional: this.botonModal,
                                    verpagosModal:this.abrirModalDetalle,
                                    adicional3: this.botonModalDetalle,
                                    adicional2: this.botonRealizarMovimiento,
                                    candado: this.handleBloqueo
                                })(cell,row)
                            }}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            width="10%"
                            columnClassName=""
                            tdStyle={cellTachado}
                            dataSort
                            dataFormat={
                                (cell, row) => {
                                    return (
                                        <div>
                                            <span className="font-weight-bold">FECHA </span><RenderDateTime fecha={cell} ></RenderDateTime> <br />
                                            {
                                                (row.fecha_emision) && (
                                                    <Fragment>
                                                        <span className="font-weight-bold">FECHA EMISIÓN </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br />
                                                    </Fragment>
                                                )
                                            }
                                            <span className="font-weight-bold">TIPO DE PAGO</span> <span style={{ color: "black" }}>{row.flotante ? <span >Flotante</span> : <span>Inmediato</span>}</span>
                                        </div>
                                    )
                                }
                            }
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="transaccion"
                            width="20%"
                            dataSort
                            tdStyle={cellTachado}
                            dataFormat={(cell, row) => (
                                cell ? (
                                    <div>
                                        {/* <span>{row.id}</span> */}
                                        {
                                            cell.lugar_transaccion && (
                                                <Fragment>

                                                    <span className="font-weight-bold">Transaccion realizada: </span>
                                                    <span style={{ color: "black" }} >{cell.lugar_transaccion}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.nombre_cliente && (
                                                <Fragment>

                                                    <span className="font-weight-bold">Cliente: </span>
                                                    <span style={{ color: "black" }} >{cell.nombre_cliente}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.nombre_proveedor && (
                                                <Fragment>

                                                    <span className="font-weight-bold">Proveedor: </span>
                                                    <span style={{ color: "black" }} >{cell.nombre_proveedor}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.nombre_tipo_gasto && (
                                                <Fragment>

                                                    <span className="font-weight-bold">Gasto: </span>
                                                    <span style={{ color: "black" }} >{cell.nombre_tipo_gasto}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.label_tipo_transaccion && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Transaccion: </span>
                                                    <span className="t-musgo ">{cell.label_tipo_transaccion}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.no_documento && (
                                                <Fragment>
                                                    <span className="font-weight-bold">No. documento: </span>
                                                    <span className="t-musgo ">{cell.no_documento}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.propietario && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Propietario: </span>
                                                    <span className="t-musgo ">{cell.propietario}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        <span className="font-weight-bold">Descripcion: </span>
                                        <span className="t-musgo ">{cell.descripcion}</span><br />
                                        {
                                            cell.orden && (
                                                <div>
                                                    <span className="font-weight-bold">orden: </span>
                                                    <span className="t-musgo ">{cell.orden}</span>
                                                </div>
                                            )
                                        }
                                        {
                                            row.asignado_a && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Asignado a: </span>
                                                    <span className="t-musgo ">{row.asignado_a}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            row.fecha_asignacion && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Fecha de asignación: </span>
                                                    <span className="t-musgo ">{row.fecha_asignacion}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            row.pago_a && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Pago a: </span>
                                                    <span className="t-musgo ">{row.pago_a}</span><br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                ) : " NO HAY INFORMACION "
                            )}
                        >
                            DETALLE TRANSACCION
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="es_ingreso"
                            width="10%"
                            tdStyle={cellTachado}
                            dataSort
                            dataFormat={
                                (cell, row) => (cell ? "Ingreso" : "Egreso")
                            }
                        >
                            MOVIMIENTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="debe"
                            dataSort
                            width="15%"
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (
                                    <RenderMoneda monto={row.es_ingreso ? cell : 0} simbolo={this.getsimbolo(periodo.moneda)} />
                                )
                            }
                        >
                            DEBE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="haber"
                            dataSort
                            width="15%"
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (
                                    <RenderMoneda monto={row.es_ingreso ? 0 : cell} simbolo={this.getsimbolo(periodo.moneda)} />
                                )
                            }
                        >
                            HABER
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="saldo"
                            dataSort
                            width="15%"
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (
                                    <RenderMoneda monto={cell} simbolo={this.getsimbolo(periodo.moneda)} />
                                )
                            }
                        >
                            SALDO
                        </TableHeaderColumn>
                    </Grid>
                </div>

            </CardSimple>
        )
    }
}

export default withRouter(TransaccionesGrid)
