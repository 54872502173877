import React, { Component } from 'react';
import _ from 'lodash';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import ToolbarReporteProductos from '../../../Utils/Toolbar/ToolbarReporteProductos';

import GridProductos from './GridProductos';
import GridTipos from './GridTipos';

// Manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import moment from 'moment';
import { Redirect } from 'react-router';


export default class ReporteConsumo extends Component {

    componentDidMount() {
        this.props.resetData();
    }

    componentWillUnmount() {
        this.props.resetData();
    }


    changeFechaInicio = (value) => {
        const {fecha_fin} = this.props;
        if (value <= fecha_fin) {
            this.props.changeFechaInicio(value, 2);
        }
    };

    changeFechaFin = (value) => {
        const {fecha_inicio} = this.props;
        if (fecha_inicio <= value) {
            this.props.changeFechaFin(value, 2);
        }
    }

    render() {
        const { loader, total_unidades, permisos } = this.props;
        if(permisos.reporte_consumo === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE DE PRODUCTOS CONSUMIDOS">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReporteProductos
                                {...this.props}
                                changeEmpresa={(value) => this.props.changeEmpresa(value, 2) }
                                changeProducto={(value) => this.props.changeProducto(value, 2) }
                                changeTipoProducto={(value) => this.props.changeTipoProducto(value, 2) }
                                tipoProducto={this.props.tipo_producto}
                                changeFechaInicio={this.changeFechaInicio}
                                changeFechaFin={this.changeFechaFin}
                                produccion={true}
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <Tabs
                            defaultActiveKey="1"
                            tabBarPoition = "top"
                            renderTabBar ={()=><ScrollableInkTabBar/>}
                            renderTabContent={()=><TabContent/>}
                        >
                            <TabPane tab="POR TIPOS" key="1">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridTipos {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tab="POR PRODUCTOS" key="2">
                                <LoadMask loading={loader} light type={"TailSpin"}>
                                    <GridProductos {...this.props} />
                                </LoadMask>
                            </TabPane>
                        </Tabs>
                    </div>
                </LoadMask>

            </CardSimple>
        )
    }
}
