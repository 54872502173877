import React, { Component, Fragment } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import CardSimple from '../../../Utils/Cards/cardSimple';
import AsignarTarea from './AsignarTarjeta';
import TarjetasAsignacion from './TarjetasAsignacion';



export default class AsignarContainer extends Component {
    state ={
        step: 1,
        valor_formulario: null
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };
    firstStep = (values) => {
        this.setState({valor_formulario: values});
        this.nextStep();
    }
    onSubmit = () => {
        this.props.marcarComoTarjetaPagado(this.state.valor_formulario);
    }
    render() {
        const { step } = this.state;

        return (
            <CardSimple
                    titulo="ASIGNAR TARJETA A MOVIMIENTO"
                >
                 <div className="col-md-12 row d-flex justify-content-center">
                    <div className="col-md-7">
                        <ProgressBar
                            percent={step === 1 ? 50: step === 2 ? 100 : 100}
                            filledBackground= "#f3a631"
                        >
                            <Step transition="scale">
                            {({ accomplished, index }) => (
                                <div
                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                            >
                                <div className={ `${accomplished? 'step-caption-selected':"step-caption"}`}>
                                    {index + 1}
                                </div>
                            </div>
                            )}
                            </Step>
                            <Step transition="scale">
                            {({ accomplished, index }) => (
                                <div
                                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                            >
                                <div className={ `${accomplished? 'step-caption-selected':"step-caption"}`}>
                                    {index + 1}
                                </div>
                            </div>
                            )}
                            </Step>
                        </ProgressBar>
                    </div>
                    <div className="col-md-12 mt-5">
                        {
                            (step == 1) && (
                                <AsignarTarea
                                    {...this.props}
                                    onSubmit={this.firstStep}
                                    initialValues={{opcion_asignacion: 'true', nuevo: true, fecha: new Date()}}/>
                            )
                        }
                        {
                            (step === 2) && (
                                <TarjetasAsignacion
                                    values_formulario={this.state.valor_formulario}
                                    previousStep={this.previousStep}
                                    transaccion_seleccionada={this.state.valor_formulario}
                                    onSubmit={this.onSubmit}
                                    {...this.props}/>
                            )
                        }
                    </div>
                 </div>

            </CardSimple>

        )
    }
}
