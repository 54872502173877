import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";

import ProduccionForm from './produccionForm';

class ProduccionNuevo extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        orden: false,
        titulo: 'NUEVA ORDEN DE PRODUCCION POR DEVOLUCIONES',
        values: {},
        estadoRead: false,
        tipo: 'N'
    }
    componentWillMount(){
        let direccion = this.props.match.path
        const { selectEmpresas} = this.props
        selectEmpresas();
        let id_empresa = this.props.match.params.empresa
        
        if(this.props.match.params.empresa){
            this.setState(
                {
                    values:{
                        orden:null,
                        empresa:id_empresa,
                    },
                    estadoRead: true,
                }
            )
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        // let id = this.props.match.params.id
        crear(values, this.props.match.params.empresa);
    }
    
    render() {
        const { loader, empresas, itemov } = this.props;
        return (
            <CardFormulario
                titulo={`${this.state.titulo}`}
            >
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br/>
                    <ProduccionForm 
                        onSubmit={this.onSubmit} 
                        empresas={empresas}
                        initialValues={{
                            empresa: this.state.values.empresa,
                            fecha_prox_entrega: new Date(),
                            fecha: new Date(),
                            devolucion:true
                        }}
                        empresa={this.props.match.params.empresa}
                        estadoRead={this.state.estadoRead}
                        getRecetaOV={this.props.getRecetaOV}
                        leerov={this.props.leerov}
                        leersi={this.props.leersi}
                        bandera_orden={this.state.orden}
                        tipo_pro={this.state.tipo}
                        getRecetaDev={this.props.getRecetaDev}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default ProduccionNuevo;
