import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { renderNumber } from '../../../Utils/renderField';
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import { SelectField, AsyncSelectField, renderTextArea, renderFieldRadio } from '../../../Utils/renderField/renderField.js'
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import { api } from '../../../../../utility/api';
// import validate from './validacion';

const tipo_horario = [
    { value: 1, label: 'Horario laboral' },
    { value: 5, label: 'Horario extra' }
]

const getEmpleados = (search) => {
    return api.get("empleados", { search }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getEmpresas = (search) => {
    return api.get("empresa", { search }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

let tipo = [
    {value:10 , label: "Injustificado", help_text:"Descuenta"},
    {value:20 , label: "Vacaciones", help_text:"No Descuenta"},
    {value:30 , label: "Justificado", help_text:"Descuenta"},
    {value:40 , label: "Suspensión sin Sueldo", help_text:"Descuenta"},
    {value:50 , label: "Suspensión con Sueldo", help_text:"NO Descuenta"},
]


const renderAusencias = ({ fields, meta: { error, submitFailed }, option }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="table-header-wrapper">
                <table className="table table-striped table-highlight  mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "20%" }}>Empleado</th>
                            {/* <th style={{ width: "20%" }}>Empresa</th> */}
                            {
                                option && (
                                    <th style={{ width: "20%" }}>Dia</th>
                                )
                            }
                            <th style={{ width: "30%" }}>Descripcion</th>
                            <th style={{ width: "30%" }}>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((asignacion, index) => (
                            <tr key={index} style={{height:"68px"}}>
                                <td className="text-center sin-borde-top">
                                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                        title="Borrar"
                                        onClick={() => {
                                            fields.remove(index)
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.empleado`}
                                        component={AsyncSelectField}
                                        loadOptions={getEmpleados}
                                        type="text"
                                        filterOptions={false}
                                        valueKey="id"
                                        labelKey="nombre_completo"
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                                {/* <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.empresa`}
                                        component={AsyncSelectField}
                                        loadOptions={getEmpresas}
                                        type="text"
                                        filterOptions={false}
                                        valueKey="id"
                                        labelKey="nombre"
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td> */}
                                {
                                    option && (
                                        <td className="sin-borde-top">
                                            <Field
                                                name={`${asignacion}.dias_ausencia`}
                                                type="text"
                                                component={renderNumber}
                                                decimalScale={2}
                                                top ={{top:"67px", position:"inherit"}}

                                            />
                                        </td>
                                    )
                                }
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.descripcion`}
                                        component={renderTextArea}
                                        label="descripcion"
                                        rows={1}
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.tipo_ausencia`}
                                        component={SelectField}
                                        options={tipo}
                                        label="tipo_ausencia"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({descripcion:"", dias_ausencia: 1, tipo_ausencia: 10})}>
                    AGREGAR
                </button>
            </div>
        </div>
    </div>
)

class AusenciasForm extends React.Component{
    state ={
        option: true,
    }
    changeoption = (value) => {
        this.setState({option:value})
    }
    render(){
        const { handleSubmit } = this.props;
        return (
            <form name="AusenciasForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="row justify-content-center py-1" />
                <div className="col-md-12 d-flex justify-content-center">
                    <div className="col-md-12 mb-3" style={{border: "1px solid #e8e8e8", padding:"10px"}}>
                            <div className="d-flex justify-content-center">
                                <h5 className="t-primary text-uppercase align-self-center">Seleccione Ausencia</h5>
                            </div>
                            <div className="row d-flex justify-content-sm-around ">
                                <div className={`form-group has-feedback col-md-2 col-sm-4 col-12`}>
                                    <Field
                                        name="dia"
                                        label="Por Dia"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"true"}
                                        // parse={(value)=> value=="true"}
                                        onChange={(e) => {
                                            this.changeoption(true)
                                        }}
                                        className="form-control" />
                                </div>
                                <div className={`form-group has-feedback  col-md-2 col-sm-4 col-12`}>
                                    <Field
                                        name="dia"
                                        label="Por rango"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"false"}
                                        onChange={(e) => {
                                            this.changeoption(false)
                                        }}
                                        className="form-control" />
                                </div>
                            </div>
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="nombre">{this.state.option ? "Fecha":"Fecha Inicial" }</label>
                        <Field
                            name="fecha"
                            selectedDate={moment()}
                            maxDate="2040-12-31"
                            minDate="2020-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                    {
                        !this.state.option && (
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="nombre">Fecha final</label>
                                <Field
                                    name="fecha_final"
                                    selectedDate={moment()}
                                    maxDate="2040-12-31"
                                    minDate="2020-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                        )
                    }
                </div>

                <div className="form-group has-feedback  col-12">
                    <FieldArray name="ausencias" component={renderAusencias}  option={this.state.option}/>
        
                </div>
                <div className="row  justify-content-sm-around justify-content-center">
                    <Link className="btn btn-secondary  align-self-center" to="/ausencias" >CANCELAR</Link>
                    <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                </div>
            </form>
        );
    }
}
// let AusenciasForm = (props) => {
//     const { handleSubmit } = props;
// };


AusenciasForm = reduxForm({
    form: 'AusenciasForm', // a unique identifier for this form
    initialValues: {
        fecha: moment()
    },
    validate: data => {
        const errors = {};
        const errors_detalles = [];
    
        if (data.ausencias) {
            if (data.ausencias.length) {
                data.ausencias.forEach((item, index) => {
                    const detalleErrors = {};
                    let repetidos = [];
                    if (item.empleado) {
                        repetidos = _.filter(data.ausencias, (x) => {
                            if (x.empleado)
                                return x.empleado.id == item.empleado.id
                            return false;
                        });
                    }

                    if (!item.empleado) {
                        detalleErrors.empleado = "Campo obligatorio";
                        errors_detalles[index] = detalleErrors;
                    }else {
                        if (1 < repetidos.length) {
                            detalleErrors.empleado = "Empleado repetido"
                            errors_detalles[index] = detalleErrors;
                        }
                    }
                    // if (!item.empresa) {
                    //     detalleErrors.empresa = "Campo obligatorio";
                    //     errors_detalles[index] = detalleErrors;
                    // }
                    if (!item.dias_ausencia) {
                        detalleErrors.dias_ausencia = "Campo obligatorio";
                        errors_detalles[index] = detalleErrors;
                    }else{
                        if (item.dias_ausencia < 0) {
                            detalleErrors.dias_ausencia = "Debe ser mayor a 0";
                            errors_detalles[index] = detalleErrors;
                        }
                        if (item.dias_ausencia > 1) {
                            detalleErrors.dias_ausencia = "Debe ser menor a 1";
                            errors_detalles[index] = detalleErrors;
                        }
                    }
                    if (!item.descripcion) {
                        detalleErrors.descripcion = "Campo obligatorio";
                        errors_detalles[index] = detalleErrors;
                    }
                    if (!item.tipo_ausencia) {
                        detalleErrors.tipo_ausencia = "Campo obligatorio";
                        errors_detalles[index] = detalleErrors;
                    }
                });
                if (errors_detalles.length)
                    errors.ausencias = errors_detalles;
            }
        }
        return errors;
    },
})(AusenciasForm);



export default AusenciasForm;
