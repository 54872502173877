import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, AsyncSelectField, renderCurrency, renderSelectField } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import { NotificationManager } from "react-notifications";

import { api } from 'api';
import { RenderCurrency, RenderNumber } from "../../../Utils/renderField/renderReadField";

class ItemArray extends Component {
    state = {
        producto: {},
    }
    setPrecioUnitario = (producto) => {
        const { todos, index } = this.props;
        if (producto !== undefined && producto.cantidad_actual > 0) {
            todos[index]['despachar'] = producto.existencias;
        }
    }
    render() {
        const { getProductosOrden, eliminar, index, Despacho, productos } = this.props
        console.log("LOS PRODUCTOS DEL DESPACHO>>>", productos)
        const d_index = parseInt(Despacho.split("[")[1].split("]")[0])
        const despacho_dev = productos[d_index]
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() => {
                            eliminar()
                        }}
                    />
                    {despacho_dev && (despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40") &&
                        despacho_dev.producto_cambio && 
                        <React.Fragment>
                            <br/><br/><br/><br/>
                            <span>Reemplazo</span>
                        </React.Fragment>
                    }
                </td>
                <td className="sin-borde-top">
                    <Field
                        label="nombre"
                        name={`${Despacho}.producto`}
                        loadOptions={getProductosOrden}
                        type="text"
                        component={AsyncSelectField}
                        top={{ top: "67px", position: "inherit" }}
                        valueKey="id"
                        labelKey="nombre"
                        disabled={(despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40")}
                        onChange={(e) => {
                            this.setState({ producto: e });
                            this.setPrecioUnitario(e)
                        }}
                        className="form-control"
                    />
                    {
                        (this.props.productos && this.props.productos[index].tipo_devolucion) && (
                            <div className="speech-bubble mt-1 p-2">
                                <span>
                                    {this.props.productos[index].tipo_devolucion == '20' ?
                                        'Se realizará un cargo al cliente'
                                        : this.props.productos[index].tipo_devolucion == '30' ? 'Se generará un credito al cliente'
                                            : 'No se realizará ningun cargo al cliente'
                                    }
                                </span>
                            </div>
                        )
                    }
                    {despacho_dev && (despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40") && 
                        despacho_dev.producto_cambio &&
                        <Field
                            label="nombre"
                            name={`${Despacho}.producto_cambio`}
                            loadOptions={(search) => {
                                    let params = {}
                                    if(despacho_dev.producto){
                                        params.empresa__id = despacho_dev.producto.empresa
                                        return this.props.getProductosOrden(search, params)
                                    } else {
                                        return []
                                    }
                                }
                            }
                            type="text"
                            disabled
                            component={AsyncSelectField}
                            top={{ top: "67px", position: "inherit" }}
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                        />
                    }

                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${Despacho}.tipo_devolucion`}
                        labelKey={`label`}
                        valueKey="value"
                        component={renderSelectField}
                        disabled={(despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40")}
                        options={[
                            { value: 10, label: 'Garantía' },
                            { value: 20, label: 'Reparación' },
                            { value: 40, label: 'Cambio de mercancía' },
                        ]}
                    />
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${Despacho}.requerido`}
                        type="number"
                        readOnly
                        // min="0" max="5"
                        component={renderField}
                        top={{ top: "67px", position: "inherit" }}
                    />
                    {despacho_dev && (despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40") && 
                        despacho_dev.producto_cambio && 
                        <React.Fragment>
                            <br/><br/>
                            <Field
                                name={`${Despacho}.producto_cambio.cantidad_a_cambiar`}
                                type="number"
                                readOnly
                                style={{ marginTop: 8 }}
                                component={renderField}
                            />
                        </React.Fragment>
                    }
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${Despacho}.producto.existencias`}
                        type="number"
                        readOnly
                        // min="0" max="5"
                        component={renderField}
                        top={{ top: "67px", position: "inherit" }}
                    />
                    {despacho_dev && (despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40") && 
                        despacho_dev.producto_cambio && 
                        <React.Fragment>
                            <br/><br/>
                            <Field
                                name={`${Despacho}.producto_cambio.existencias`}
                                type="number"
                                readOnly
                                style={{ marginTop: 8 }}
                                component={renderField}
                            />
                        </React.Fragment>
                    }
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${Despacho}.cantidad`}
                        type="number"
                        // min="0" max="5"
                        readOnly={(despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40")}
                        component={renderField}
                        top={{ top: "67px", position: "inherit" }}
                    />
                    {despacho_dev && (despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40") && 
                        despacho_dev.producto_cambio && 
                        <React.Fragment>
                            <br/><br/>
                            <Field
                                name={`${Despacho}.producto_cambio.cantidad_a_despachar`}
                                type="number"
                                style={{ marginTop: 8 }}
                                component={renderField}
                                top={{ top: "67px", position: "inherit" }}
                            />
                        </React.Fragment>
                    }
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${Despacho}.precio_unitario`}
                        type="number"
                        // min="0" max="5"
                        disabled={(despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40")}
                        component={renderCurrency}
                        top={{ top: "67px", position: "inherit" }}
                    />
                    {despacho_dev && (despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40") && 
                        despacho_dev.producto_cambio && 
                        <React.Fragment>
                            <br/><br/>
                            <Field
                                name={`${Despacho}.producto_cambio.precio_venta`}
                                type="number"
                                style={{ marginTop: 8 }}
                                disabled
                                component={renderCurrency}
                            />
                        </React.Fragment>
                    }
                </td>
            </tr>
        )
    }
}
const renderProductos = ({ fields, meta: { error, submitFailed }, getProductosOrden, productos }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th ></th>
                            <th>Producto</th>
                            <th style={{ width: "10%" }}>Tipo de devolución</th>
                            <th style={{ width: "15%" }}>Cantidad Solicitada</th>
                            <th style={{ width: "15%" }}>Existencias</th>
                            <th style={{ width: "15%" }}>a Despachar</th>
                            <th style={{ width: "15%" }}>Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((Despacho, index) => {
                            let todos = fields.getAll()
                            return (
                                <ItemArray
                                    index={index}
                                    key={index}
                                    Despacho={Despacho}
                                    todos={todos}
                                    getProductosOrden={getProductosOrden}
                                    productos={productos}
                                    eliminar={() => {
                                        fields.remove(index)
                                    }}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR DESPACHO
                    </button>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>}
    </div>
)
//fin de render de productos


class DespachoForm extends Component {
    getProductosOrden = (search, params = {}) => {
        if (search) {
            params.search = search;
        }
        return api.get("productos/getProductosConExistencias", params).
            then((data) => {
                if (data) return data.results
                return [];
            }).catch(() => {
                return [];
            });
    };

    validateSubmit = (e) => {
        let valid = true
        const productos = this.props.productos
        const notification_time = 5000
        e.preventDefault()
        productos.forEach(item => {
            //Si es cambio de mercaderia
            if(item.tipo_devolucion === 40){
                if(!item.producto_cambio.cantidad_a_despachar || 
                    item.producto_cambio.cantidad_a_despachar === "" ||
                    item.producto_cambio.cantidad_a_despachar <= 0){
                    valid = false
                    NotificationManager.error(
                        'La cantidad de despacho debe ser mayor a 0', 
                        'Error', 
                        notification_time);
                }
                if(item.producto_cambio.cantidad_a_despachar > item.producto_cambio.cantidad_a_cambiar){
                    valid = false
                    NotificationManager.error(
                        'La cantidad de despacho debe ser menor o igual a lo solicitado', 
                        'Error', 
                        notification_time);
                }
                if(item.producto_cambio.existencias < item.producto_cambio.cantidad_a_despachar){
                    valid = false
                    NotificationManager.error(
                        'No hay suficientes existencias', 
                        'Error', 
                        notification_time);
                }
            }
        });
        console.log("PRODUCTOS DEL VALIDATE>>>", this.props.productos)
        if(valid) this.props.handleSubmit()
    }
    render() {
        const { handleSubmit, orden } = this.props;
        return (
            <form name="DespachoForm" className="form-validate mb-lg" onSubmit={this.validateSubmit}>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-12">
                        <FieldArray
                            name="Despachos"
                            component={renderProductos}
                            getProductosOrden={this.getProductosOrden}
                            productos={this.props.productos}
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-around justify-content-center">
                    <Link className="btn btn-secondary m-1 align-self-center" to={`/devolucion/${orden}`} >CANCELAR</Link>
                    <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                </div>
            </form>
        );
    }
};

const selector = formValueSelector('DespachoForm');

export default connect(state => {
    const productos = selector(state, 'Despachos');

    return {
        productos,
    }

})(reduxForm({
    form: 'DespachoForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if (!data || !data.Despachos) {
            errors.Despachos = { _error: 'Debe ingresar al menos un producto' }
        } else {
            const despachoArray = []
            data.Despachos.forEach((producto, index) => {
                let detErrors = {};
                let repetidos = [];
                if (producto.producto) {
                    repetidos = _.filter(data.Despachos, (x) => {
                        if (x.producto)
                            return x.producto.id == producto.producto.id
                        return false;
                    });
                }

                if (!producto.producto) {
                    detErrors.producto = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.producto = "Este producto está repetido"
                    }
                }
                if (!producto.despachar) {
                    detErrors.despachar = "Campo requerido"
                } else {
                    if (parseFloat(producto.despachar) <= 0) {
                        detErrors.despachar = "Debe ser mayor a 0"
                    }
                    if (parseFloat(producto.despachar) > producto.producto.cantidad_actual) {
                        detErrors.despachar = `Debe ser un maximo de ${producto.producto.cantidad_actual}`
                    }
                    if (parseFloat(producto.despachar) > producto.producto.existencias) {
                        detErrors.despachar = `Hay una existencias de ${producto.producto.existencias}`
                    }
                }

                if (detErrors) {
                    despachoArray[index] = detErrors;
                }
            });
            if (despachoArray.length) {
                errors.Despachos = despachoArray
            }
        }
        return errors
    },
})(DespachoForm));

// const selector = formValueSelector('DespachoForm');
// DespachoForm = connect(state =>{
// })
