import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { getSimbolo} from "../../../Utils/renderField/renderTableField";
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { renderField, AsyncSelectField, renderTextArea } from '../../../Utils/renderField';

class ItemArray extends Component{
    state={
        producto: null,
    }

    render(){
        const {getProductosOrden, eliminar, index, Despacho, item }= this.props
        return(
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${Despacho}.producto.nombre`}
                        type="text"
                        readOnly
                        // min="0" max="5"
                        component={renderField}
                        top ={{top:"67px", position:"inherit"}}
                        clase="inputRead2"
                    />
                    {/* <label> {item.detalle_despacho[index].producto.nombre} </label> */}
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${Despacho}.cantidad`}
                        type="number"
                        // min="0" max="5"
                        component={renderField}
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
            </tr>
        )
    }
}

const renderProductos = ({fields, meta: {error, submitFailed }, getProductosOrden,item, todosProductos}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0">
                <table className="table table-hover table-bordered table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>PRODUCTO</th>
                        <th  style={{width: "30%"}}>CANTIDAD</th>
                    </tr>
                    </thead>
                    <tbody >
                        {fields.map((Despacho, index) => {
                            let todos = fields.getAll()

                            return(
                                <ItemArray
                                    index={index}
                                    key={index}
                                    item={item}
                                    Despacho={Despacho}
                                    todos={todos}
                                    getProductosOrden={getProductosOrden}
                                    eliminar={ () =>{
                                        fields.remove(index)
                                    }}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR DESPACHO
                </button>
            </div> */}
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}
    </div>
)

class DetalleDespachoFormBodega extends Component {
    
    render() {
        const { item, data, loader, handleSubmit, rechazarDespacho, guardarDespacho, listar: onPageChange, onSortChange, eliminar,cuentas } = this.props;

        return (
            <div className="row mt-0 pt-0">
                <div className="mb-4 col-12 px-0 mx-0">
                    <div className="mb-4 ">
                        <div className="p-0 px-3 pt-0 p-b">
                            <form name="DespachoEnBodegaForm" className="form-validate mb-lg" onSubmit={handleSubmit}> {/*(e)=>{this.submit(e)}*/}
                                <FieldArray
                                    name="detalle_despacho"
                                    component={renderProductos}
                                    item={item}
                                    getProductosOrden={this.getProductosOrden}
                                />
                                <div className="col-12">
                                    <button className="btn btn-mostaza m-1 align-self-center" type="button" onClick={rechazarDespacho}>RECHAZAR</button>
                                    <button className="btn btn-azul-claro m-1 align-self-center" type="button" onClick={guardarDespacho}>GUARDAR</button>
                                    {loader==false ? <button className="btn btn-primary m-1 align-self-center" type="submit">FINALIZAR</button>: ""}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


DetalleDespachoFormBodega = reduxForm({
    form: 'DespachoEnBodegaForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if (!data || !data.detalle_despacho || !data.detalle_despacho.length ){
            errors.detalle_despacho = {_error:'Debe despachar al menos un producto'}
        }else{
            const despachoArray = []
            data.detalle_despacho.forEach( (producto, index)=>{
                let detErrors = {};
                if(!producto.cantidad){
                    detErrors.cantidad = "Campo requerido"
                }else {
                    if(parseFloat(producto.cantidad) <= 0){
                        detErrors.cantidad = "Debe ser mayor a 0"
                    }
                    if(parseFloat(producto.cantidad) > producto.cantidad_actual){
                        detErrors.cantidad = `Debe ser un maximo de ${producto.cantidad_actual}`
                    }
                }
                if(detErrors){
                    despachoArray[index] = detErrors;
                }

            });
            if(despachoArray.length){
                errors.detalle_despacho = despachoArray
            }
        }
        return errors
    },
})(DetalleDespachoFormBodega);


export default DetalleDespachoFormBodega;