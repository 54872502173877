import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { renderField, renderTextArea, renderSelectField, renderCurrency, AsyncSelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {  Monedas } from '../../../../../utility/constants';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";
import { NotificationManager } from "react-notifications";
import moment from 'moment';
import {api} from 'api';
import { CONDICION_DIAS_GENERACION_NOTAS } from '../../../../../utility/constants';


const getClientes = (search, props) => {
    const params = {}
    params.search = search
    params.esCliente = 1
    params.tipo = 2
    if(props.permisos.visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = props.id_user
    }
    return api.get("clientes", params).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

class ItemArray extends Component {
    state = {
        producto: {},
        sel_producto: {}
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => props.getProductos(search)
        }
    }

    getProductosOrden = (search, params = {}) => {
        if (search) {
            params.search = search;
        }
        return api.get("productos/getProductosConExistencias", params).
            then((data) => {
                if (data) return data.results
                return [];
            }).catch(() => {
                return [];
            });
    };

    componentDidUpdate(prevProps, prevState){
        if(this.props.simbolo !== prevProps.simbolo){
            if(this.textInput && this.textInput.current){
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                  })
                this.forceUpdate();
               // this.textInput.ref.props.loadOptions()
            }
        }
        if(this.props.cliente !== prevProps.cliente){
            if(this.textInput && this.textInput.current){
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                  })
                this.forceUpdate();
               // this.textInput.ref.props.loadOptions()
            }
        }
        if(this.props.prod_seleccionado !== prevProps.prod_seleccionado){
            const { todos, index } = this.props;
            if( this.props.prod_seleccionado)
                this.setState({producto: this.props.prod_seleccionado.producto});
        }
    }
    setPrecioUnitario = (producto_nuevo) => {
        const { todos, index } = this.props;
            if(producto_nuevo !== undefined && producto_nuevo.precio_ultimo !== undefined){
                if (producto_nuevo.precio_ultimo != null && producto_nuevo.precio_ultimo != undefined ){
                    todos[index]['precio_unitario'] = producto_nuevo.precio_ultimo;}
                else{
                    todos[index]['precio_unitario'] = 0;
                }
            }

    }
    render(){
        const {index, producto, productos, simbolo, eliminar, empresa, subtotal, cliente, prod_seleccionado, editar} = this.props;
        const d_index = parseInt(producto.split("[")[1].split("]")[0])
        const despacho_dev = productos[d_index]
        let subtotal_cambio = 0
        try{
            subtotal_cambio = despacho_dev.producto_cambio.precio_venta * 
                            (despacho_dev.producto_cambio.cantidad_a_cambiar ? Number(despacho_dev.producto_cambio.cantidad_a_cambiar) : 0 )
        } catch(e) {
        }
        return (
            <React.Fragment>
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        forwardRef
                        //withRef
                        key={`${producto}-${cliente}-${empresa}`}
                        ref={this.textInput}
                        id={`${producto}.selectProducto`}
                        name={`${producto}.producto`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top ={{top:"67px", position:"inherit"}}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            this.setState({producto: e});
                            this.setPrecioUnitario(e);
                        }}
                        className="form-control" />
                        {
                            (this.props.productos && this.props.productos[index].tipo_devolucion ) && (
                                <div className="speech-bubble mt-1 p-2">
                                    <span>
                                        {this.props.productos[index].tipo_devolucion == '20' ?
                                            'Se realizará un cargo al cliente'
                                            : this.props.productos[index].tipo_devolucion == '30' ? 'Se generará un credito al cliente'
                                            : 'No se realizará ningun cargo al cliente'
                                        }
                                    </span>
                                </div>
                            )
                        }
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${producto}.tipo_devolucion`}
                        labelKey={`label`}
                        valueKey="value"
                        component={renderSelectField}
                        options={[
                            // {value: 10, label:'Garantía'},
                            // {value: 20, label:'Reparación'},
                            {value: 30, label:'Devolución directa'},
                            // {value: 40, label:'Cambio de mercancía'},
                        ]}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${producto}.cantidad`}
                        type="number"
                        component={renderField}
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
                {
                    (editar) && (
                        <Fragment>
                            <td className="sin-borde-topzz">
                                <Field
                                    name={`${producto}.precio_unitario`}
                                    label="tipo_cambio"
                                    prefix={'Q.'}
                                    component={renderCurrency}
                                    parse={cell => parseFloat(cell)}
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </td>
                            <td className="sin-borde-top"  style={{width: "10%"}}>
                                <RenderMoneda monto={subtotal} simbolo={simbolo} />
                            </td>
                        </Fragment>
                    )
                }

            </tr>
            {despacho_dev && (despacho_dev.tipo_devolucion === 40 || despacho_dev.tipo_devolucion === "40") && 
                <tr>
                    <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                        <span>Reemplazo</span>
                    </td>
                    <td className="sin-borde-top">
                        <Field
                            label="nombre"
                            name={`${producto}.producto_cambio`}
                            loadOptions={(search) => {
                                    let params = {}
                                    if(despacho_dev.producto){
                                        params.empresa__id = despacho_dev.producto.empresa
                                        return this.getProductosOrden(search, params)
                                    } else {
                                        return []
                                    }
                                }
                            }
                            type="text"
                            component={AsyncSelectField}
                            top={{ top: "67px", position: "inherit" }}
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                        />
                    </td>
                    <td></td>
                    <td className="sin-borde-top" >
                        <Field
                            name={`${producto}.producto_cambio.cantidad_a_cambiar`}
                            type="number"
                            // min="0" max="5"
                            component={renderField}
                            top={{ top: "67px", position: "inherit" }}
                        />
                    </td>
                    {
                        (editar) && (
                            <Fragment>
                                <td className="sin-borde-topzz">
                                    <Field
                                        name={`${producto}.producto_cambio.precio_venta`}
                                        label="tipo_cambio"
                                        prefix={'Q.'}
                                        component={renderCurrency}
                                        parse={cell => parseFloat(cell)}
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                                <td className="sin-borde-top"  style={{width: "10%"}}>
                                    <RenderMoneda monto={subtotal_cambio} simbolo={simbolo} />
                                </td>
                            </Fragment>
                        )
                    }
                </tr>
            }
            </React.Fragment>
        );
    }
}


const renderProductos = ({fields, meta: {error, submitFailed }, simbolo, getProductos, mostrar_precios, productos, proveedor, empresa, editar, productos_add, addProductos, removeProductos}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Producto</th>
                        <th style={{width: "20%"}}>Tipo devolución</th>
                        <th style={{width: "20%"}}>Cantidad</th>
                        {
                            (editar) && (
                                <Fragment>
                                    <th style={{width: "20%"}}>Costo unitario</th>
                                    <th  style={{width: "10%"}}>Subtotal</th>
                                </Fragment>
                            )
                        }

                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((producto, index) => {
                        let subtotal = 0;
                        let mensaje;
                        let existe=0;
                        let todos = fields.getAll();
                        const prod = fields.get(index);
                        if(productos_add.length == 0){
                            if(prod.producto){
                                addProductos(prod);
                                prod.producto.precio_venta = prod.precio_unitario;
                            }
                        }
                        else{
                            productos_add.forEach(producto =>{
                                if(prod.producto && producto.producto){
                                    if(producto.producto.id == prod.producto.id){
                                        existe=1;
                                    }
                                }
                            })
                            if(existe==0 && prod.producto){
                                addProductos(prod);
                                prod.producto.precio_venta = prod.precio_unitario;
                            }
                        }
                        try {
                            subtotal = Number(prod.cantidad) * Number(prod.precio_unitario)
                        } catch (error) {
                            subtotal = 0;
                        }
                        return (
                            <ItemArray
                                editar={editar}
                                empresa={empresa}
                                mostrar_precios={mostrar_precios}
                                prod_seleccionado={prod}
                                proveedor={proveedor}
                                index={index}
                                key={index}
                                todos={todos}
                                subtotal={subtotal}
                                producto={producto}
                                getProductos={getProductos}
                                productos={productos}
                                simbolo={simbolo}
                                eliminar={ () =>{
                                    fields.remove(index),
                                    removeProductos(prod)
                                }}
                                field={fields ? fields.get(index) : null}
                            />
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR PRODUCTO
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}
    </div>
)
//fin de render de productos


const getTrabajador = (search) => {
    return api.get("user", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
class DevolucionForm extends Component {
    state = {
        proveedor: null,
        proveedorDespacho: {},
        idOrden: null,
        moneda: null,
        empresa: null,
        sucursal: null,
        descripcion: null,
        encargado: null
    }
    getCliente = (id) => {
        return api.get(`clientes/${id}`, {esCliente: true}).
        then((data) => {
            if (data){
                this.setState({ proveedorDespacho: data });
                this.setState({ proveedor: data.id });
                this.props.setCliente(data.id);
                this.getDespachos();
            }
            return [];
        }).catch(() => {
            return [];
        });
    };

    getSucursal = (id) => {
        return api.get(`devolucion/obtener_sucursal/${id}`).then((data) => {
            
            return [];
        }).catch(() => {
            return [];
        });
    };
    constructor(props){
        super(props);
        this.textInputDespachos = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => this.getDespachos(search),
        }
    }

    componentDidMount() {
        if (this.props.editar) this.props.clearData();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.cliente !== prevProps.cliente){
            if(this.textInputDespachos && this.textInputDespachos.current){
                this.textInputDespachos.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.getDespachos(search)
                });
                this.forceUpdate();
                this.setState({producto: {}})
                //this.textInputDespachos.ref.props.loadOptions();
            }
        }
        if(this.state.empresa !== prevState.empresa){
            if(this.textInputDespachos && this.textInputDespachos.current){
                this.textInputDespachos.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.getDespachos(search)
                });
                this.forceUpdate();
                this.setState({producto: {}})
                //this.textInputDespachos.ref.props.loadOptions();
            }
        }
        if (!this.state.idOrden && prevProps.item.depacho_devolucion) {
            this.setState({idOrden: prevProps.item.depacho_devolucion.id})
        }
    }

    getDespachos = (search) => {
        let params = {
            tipo_movimiento : 40,
            tipo_despacho: 10
        };
        if(search){
            params.search = search;
        }
        if(this.state.empresa){
            params.empresa_id = this.state.empresa
        }
        if(this.state.proveedor){
            params.orden__proveedor = this.state.proveedor
        }
        if(this.props.permisos.visualizar_mis_datos == true){
            params.visualizar_mis_datos = true
            params.id_user=this.props.id_user
            params.select = true
        }
        return api.get("despachos", params).
            then((data) => {
                if (data)
                return data.results;
                return [];
            }).catch(() => {
                return [];
        });

    }
    getProductos = (search, params = {}) => {
        params = {
            tipo_movimiento : 40,
            tipo_despacho: 10
        };
        if(search){
            params.search = search;
        }
        if(this.state.empresa){
            params.empresa_id = this.state.empresa
        }
        if(this.state.idOrden){
            return api.get(`despachos/${this.state.idOrden}`,params).
            then((data) => {
                if (data){
                    let p = [];
                    data.detalle_despacho.map((item,index) => {
                        let yaAgregado = _.find(this.props.productos, { producto: {id: item.producto.id} });
                        if (yaAgregado==undefined) {
                            item.producto.cantidad = data.detalle_despacho[index].cantidad;
                            p.push(item.producto);
                        }
                    })
                    return p;
                }
                return [];
            }).catch(() => {
                return [];
            });
        } else {
            return api.get(`productos`,{search}).
            then((data) => {
                return data.results;
            }).catch(() => {
                return [];
            });
        }
    };
        reRender = () => {
        this.setState({
            proveedor: this.props.cliente,
            moneda: this.props.moneda
        });
    }

    validateSubmit = () => {
        let valid = true
        const productos = this.props.productos
        const notification_time = 5000
        productos.forEach(item => {
            //Si es cambio de mercaderia
            if(item.tipo_devolucion === 40){
                if(!item.producto_cambio.cantidad_a_cambiar || 
                    item.producto_cambio.cantidad_a_cambiar === "" ||
                    item.producto_cambio.cantidad_a_cambiar <= 0){
                    valid = false
                    NotificationManager.error(
                        'La cantidad de cambio debe ser mayor a 0', 
                        'Error', 
                        notification_time);
                }
                if(this.props.editar == true &&
                    (!item.producto_cambio.precio_venta ||
                    item.producto_cambio.precio_venta === "" ||
                    item.producto_cambio.precio_venta <= 0)) {
                    valid = false
                    NotificationManager.error(
                        'El precio de cambio debe ser mayor a 0', 
                        'Error', 
                        notification_time);
                }
            }
        });
        if(valid) this.props.handleSubmit()
    }

    render() {
        const { handleSubmit,
                total,
                simbolo,
                mostrar_precios,
                empresas,
                texto_descripcion,
                cliente,
                permisos,
                item,
                subtotal, handleCuentas, empresa, productos, tiene_iva} = this.props;

        let textoNotas = "";
        let hoy = moment();
        try {
            if (item.depacho_devolucion && !item.depacho_devolucion.factura_despacho) {
                textoNotas = '*El Despacho no tiene Factura';
            }
            else {
                let fechaCertificacion = moment(item.depacho_devolucion.factura_despacho.fecha_certificacion, 'YYYY-MM-DD');
                let diferenciaDias = hoy.diff(fechaCertificacion,'days')

                if (diferenciaDias <= CONDICION_DIAS_GENERACION_NOTAS) {
                    textoNotas = '*Se creará una Nota de Crédito';
                }
                else textoNotas = '*Se creará una Nota de Abono'
            }
        }
        catch (e) {
            textoNotas = "";
            console.log(e)
        }

        return (
            <form name="DevolucionForm" className="form-validate mb-lg " onSubmit={() => console.log('NOOO')}>
                <div className="row justify-content-center">
                    <h4 className="t-primary" >{texto_descripcion}</h4>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 row m-0 p-0">
                        <div className="row col-12 m-0 p-0">
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="nombre">Fecha</label>
                                <Field
                                    name="fecha"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="caja">Empresa</label>
                                <Field
                                    name="empresa"
                                    labelKey={`nombre`} valueKey="id"
                                    component={renderSelectField}
                                    onChangePersonal={e => {
                                        this.setState({empresa:e})
                                    }}
                                    options={empresas}
                                />
                            </div>
                        </div>
                        <div className="row col-12 m-0 p-0">
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="cliente">Cliente</label>
                                <Field
                                    name="cliente"
                                    deafultValue={this.state.proveedor}
                                    label="cliente"
                                    component={AsyncSelectField}
                                    loadOptions={(search) => getClientes(search, this.props)}
                                    disabled={this.props.editar}
                                    defaultValue={this.state.proveedorDespacho}
                                    type="text"
                                    valueKey="id"
                                    labelKey="nombre"
                                    onChange={(e) => {
                                        this.setState({proveedor: e.id});
                                        this.props.setCliente(e.id);
                                    }}
                                    className="form-control" />
                            </div>
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="encargado">Encargado</label>
                                <Field
                                    name="encargado"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getTrabajador}
                                    type="text"
                                    valueKey="id"
                                    labelKey="first_name"
                                    onChange={(e) => {
                                        this.setState({encargado: e});
                                    }}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="row col-12 m-0 p-0">
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="sucursal">Sucursal</label>
                                    <Field
                                        name="sucursal"
                                        component={renderField}
                                        className="form-control"
                                    />
                            </div>
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="depacho_devolucion">Despacho por Orden de Venta</label>
                                <Field
                                    name="depacho_devolucion"
                                    label="nombre"
                                    key={`od-${cliente}`}
                                    id={`od-${cliente}`}
                                    ref={this.textInputDespachos}
                                    component={AsyncSelectField}
                                    loadOptions={this.state.loadOptions}
                                    disabled={this.props.editar}
                                    type="text"
                                    valueKey="id"
                                    labelKey="no_despacho"
                                    onChange={(e) => {
                                        this.props.leer(e.id, this.props.editar, this.state.descripcion, this.state.empresa, this.state.encargado)
                                        this.setState({idOrden: e.id});
                                        this.getCliente(e.proveedor);                                    
                                        // this.props.change('sucursal', e.orden.direccion)
                                        //this.setState({proveedor: e.proveedor});
                                    }}
                                    className="form-control" />
                            </div>
                        </div>

                       <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback  col-sm-12  col-12">
                                <label className="t-azul" htmlFor="descripcion">Descripción</label>
                                <Field
                                    name="descripcion"
                                    label="descripcion"
                                    deafultValue={this.state.descripcion}
                                    rows={7}
                                    component={renderTextArea}
                                    type="text"
                                    top={{top:"174px"}}
                                    onChange={(e) => {
                                        this.setState({descripcion: e.target.value});
                                    }}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback  col-sm-12  col-12">
                                <label className="m-none t-mostaza semibold" htmlFor="descripcion">{ textoNotas }</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 m-0 p-0">
                        <FieldArray
                            empresa={this.state.empresa}
                            mostrar_precios={mostrar_precios}
                            cliente={this.state.cliente}
                            productos={productos}
                            reRender={this.reRender}
                            getProductos={this.getProductos}
                            simbolo={simbolo}
                            name="detalle_despacho"
                            editar={this.props.editar}
                            component={renderProductos}
                            addProductos={this.props.addProductos} 
                            productos_add={this.props.productos_add}
                            removeProductos={this.props.removeProductos}/>
                    </div>
                </div>
                {
                    (this.props.editar) && (
                        <div className="col-md-12 m-0 p-0 d-flex justify-content-center mt-3">
                                <div className="col-md-10 m-0 p-0 inline-headers text-center text-md-right">
                                    <span className="t-primary h5 mr-3"  htmlFor="descripcion">TOTAL.     </span>
                                    <h2 className="t-mostaza"  htmlFor="descripcion">{
                                        <RenderMoneda monto={total} simbolo={simbolo} />
                                    }</h2>
                                </div>
                        </div>
                    )
                }

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <Link className="btn btn-secondary  align-self-center" to="/devoluciones" >CANCELAR</Link>
                            {
                                (this.props.editar && permisos.devolucion_cli_rechazar_aprobar) && (
                                    <button type="button" onClick={this.props.anular} className="btn btn-azul-claro m-1 align-self-center">
                                        RECHAZAR
                                    </button>

                                )
                            }
                            {
                                (this.props.editar && permisos.devolucion_cli_rechazar_aprobar) && (
                                    <button
                                        type="button"
                                        onClick={this.validateSubmit}
                                        className="btn btn-primary m-1 align-self-center"
                                        disabled={this.props.loader_formulario}>
                                        APROBAR
                                    </button>
                                )
                            }
                            {
                                (!this.props.editar) && (
                                    <button
                                        type="button"
                                        onClick={this.validateSubmit}
                                        className="btn btn-primary m-1 align-self-center"
                                        disabled={this.props.loader_formulario}>
                                        GUARDAR
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

//{"fecha":"2019-10-15","empresa":"1","proveedor":1,"moneda":"USD","detalle_orden":[{"producto":2,"cantidad":"3","precio_unitario":3}]}


DevolucionForm = reduxForm({
    form: 'DevolucionForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if(!data.fecha){
            errors.fecha = "Campo requerido.";
        }
        if(!data.proveedor){
            errors.proveedor = 'Campo requerido';
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido"
        }
        if(!data.cliente){
            errors.cliente = "Campo requerido";
        }
        if(!data.sucursal) {
            errors.sucursal = "Campo requerido";
        }
        if(!data.empresa){
            errors.empresa = "Campo requerido";
        }
        if(!data.encargado){
            errors.encargado = "Campo requerido";
        }
        // if(!data.depacho_devolucion){
        //     errors.depacho_devolucion = "Campo requerido";
        // }
        if(!data.detalle_despacho || !data.detalle_despacho.length){
            errors.detalle_despacho = {_error: 'Debe de ingresar al menos un producto'}
        }else {
            const detalleArray = []
            data.detalle_despacho.forEach(function(producto, index){
                let detErrors = {};
                let repetidos = [];
                if(producto.producto){
                    repetidos = _.filter(data.detalle_despacho, (x) => {
                        if(x.producto){
                            return  x.producto.id == producto.producto.id
                        }
                        return false;
                        });
                }
                if(!producto.tipo_devolucion){
                    detErrors.tipo_devolucion = "Campo requerido";
                }
                if(!producto.producto){
                    detErrors.producto =  "Campo requerido";
                }else {
                    if(1 < repetidos.length){
                        detErrors.producto = "Este producto está repetido"
                    }
                }
                
                if(!producto.cantidad){
                    detErrors.cantidad = "Campo requerido"
                }else {
                    if(parseFloat(producto.cantidad) <= 0)
                        detErrors.cantidad = "Debe ser mayor a 0"
                    else
                        if(parseFloat(producto.cantidad) > parseFloat(producto.producto.cantidad))
                            detErrors.cantidad = `Debe ser menor o igual a ${producto.producto.cantidad}`
                }
                if(producto.producto){
                    if(!producto.producto.precio_venta && !producto.precio_unitario){
                        detErrors.precio_unitario = "Campo requerido";
                    }else {
                        // aqui se verifica si el usuario que esta realizando la orden de venta es un vendedor o un administrador
                        // si es un vendedor permitira modificar los precios a un menor valor del permitido
                        // si no mantendra la restriccion del menor precio
                            if(parseFloat(producto.precio_unitario) <= 0 && parseFloat(producto.producto.precio_venta) <= 0 ){
                                detErrors.precio_unitario = `Debe ser mayor a 0`
                            }
                            else{
                                if(parseFloat(producto.producto.precio_venta) > 0){
                                    if(parseFloat(producto.precio_unitario) > parseFloat(producto.producto.precio_venta)){
                                        detErrors.precio_unitario = `Debe ser menor o igual a ${producto.producto.precio_venta}`
                                    }
                                }
                                
                            }
                    }
                }
                
                // SE CONTROLAN LOS CAMPOS DE LOS PRODUCTOS DE CAMBIO
                if((producto.tipo_devolucion === 40 || producto.tipo_devolucion === '40') && producto.producto_cambio) {
                    // Se verifica que se seleccione un producto
                    if(!producto.producto_cambio["nombre"]) {
                        detErrors["producto_cambio"] = "Debe seleccionar un producto"
                    }
                } else if(((producto.tipo_devolucion === 40 || producto.tipo_devolucion === '40') && !producto.producto_cambio)) {
                    detErrors.producto_cambio = "Debe seleccionar un producto"
                }
                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.detalle_despacho = detalleArray
            }
        }
        return errors;
    },
})(DevolucionForm);

const selector = formValueSelector('DevolucionForm');
DevolucionForm = connect(state => {
    let todos_productos = [];
    let simbolo = "Q. "
    let valor_iva = 0;

    const productos = selector(state, 'detalle_despacho');
    const moneda = selector(state, 'moneda');
    const descuento = selector(state, 'descuento');
    const empresa = selector(state, 'empresa');
    const tiene_iva = true;

    if(moneda){
        simbolo = getSimbolo(moneda)
    }
    if(productos){
        todos_productos = productos;
    }

    let total = 0;
    let subtotal = 0;
    todos_productos.forEach( x => {
        if(!isNaN(x.cantidad) && !isNaN(x.precio_unitario)){
            const p_sub = Number(x.cantidad) * Number(x.precio_unitario)
            subtotal += p_sub;
            if(x.tipo_devolucion === 40 || x.tipo_devolucion === '40') {
                if(x.producto_cambio && x.producto_cambio["nombre"]) {
                    if(!isNaN(x.producto_cambio["cantidad_a_cambiar"]) && !isNaN(x.producto_cambio["precio_venta"])) {
                        const dev_sub = Number(x.producto_cambio.cantidad_a_cambiar) * Number(x.producto_cambio.precio_venta)
                        subtotal -= p_sub > dev_sub ? dev_sub : p_sub
                    }
                }
            }
        }
    })

    total = subtotal
    if(descuento){
        total = total - descuento;
    }


    return {
        total,
        simbolo,
        subtotal,
        empresa,
        productos,
        tiene_iva,
        valor_iva
    }
})(DevolucionForm);

export default DevolucionForm;
