import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/pago_flotante';
import { setTransaccion } from '../../../../../redux/modules/Transacciones/flotante_inverso';

import AsignarContainer from './AsignarContainer';

const mstp = state => {
    return {...state.r_pago_flotante}
};

const mdtp = {
    ...actions, setTransaccion
};

export default connect(mstp, mdtp)(AsignarContainer);
