import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Grid from '../../../Utils/Grid/index';



class HistorialMiCaja extends Component{
    state = {
        openhistory: true
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    componentDidMount = ()=>{
        let { HistorialMiCaja, pagehistorial}=this.props
        HistorialMiCaja(1, this.props.caja)
    }
    
    render(){
        let {historial, HistorialMiCaja: onPageChange, pagehistorial, loader } = this.props 
        return(
            <div>
                <Grid hover striped data={historial} loading={loader} page={pagehistorial} onPageChange={onPageChange}  >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={
                            standardActions({  vermodal: this.props.setOpenModalHistory })
                        }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_apertura"
                        dataSort
                        dataFormat = {
                            (cell,row)=>(
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA DE APERTURA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_cierre"
                        dataSort
                        dataFormat = {
                            (cell,row)=>(
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA DE CIERRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="es_cerrado"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                // <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                                cell ? 'Cerrado':'Abierto'
                            )
                        }
                    >
                        ESTADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="saldo_apertura"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={'Q. '} />
                            )
                        }
                    >
                        SALDO APERTURA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="saldo_cierre"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={'Q. '} />
                            )
                        }
                    >
                        SALDO CIERRE
                    </TableHeaderColumn>
                </Grid>
                <br/>

            </div>
        )
    }
}

export default withRouter(HistorialMiCaja);