import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderAntTimeField } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SelectField } from '../../../Utils/renderField/renderField.js'
import { departamentos, municipios } from '../../../../../utility/constants';

const renderSucursales = ({ fields, meta: { error, submitFailed }, sucursales, es_cliente, municipios_bancos, AddMunicipio, ChangeMunicipio }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight  mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ENCARGADO</th>
                            <th>DIRECCIÓN</th>
                            <th>TELÉFONO</th>
                            <th >HORARIO</th>
                            <th style={{ width: "15%", minWidth: "100px" }} >DEPTO</th>
                            <th style={{ width: "15%", minWidth: "100px" }}>MPIO</th>
                            <th style={{ width: "15%", minWidth: "100px" }}>COORD.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((sucursal, index) => (
                            <tr key={index}>
                                {console.log("municipios cliente", municipios_bancos[index])}
                                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                        title="Borrar"
                                        onClick={() => {
                                            fields.remove(index)
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.encargado`}
                                        type="text"
                                        component={renderField}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.direccion`}
                                        type="text"
                                        component={renderField}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.telefono`}
                                        type="text"
                                        component={renderField}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.horario_entrada`}
                                        component={renderAntTimeField}
                                        hour_value='07:00'
                                    />
                                    <Field
                                        name={`${sucursal}.horario_salida`}
                                        component={renderAntTimeField}
                                        hour_value='19:00'
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.departamento`}
                                        label="departamento"
                                        component={SelectField}
                                        options={departamentos}
                                        className="form-control"
                                        onChange={
                                            (e) => {
                                                ChangeMunicipio(index, `${e[0]}${e[1]}`)
                                            }
                                        }
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.municipios`}
                                        label="municipios"
                                        component={SelectField}
                                        // options={municipios_bancos ? municipios_bancos.length > 0 && municipios_bancos[index] : []}
                                        options={municipios_bancos[index]}
                                        className="form-control"
                                    />
                                </td>
                                <td className="sin-borde-top text-right">
                                    {sucursales && sucursales[index] && sucursales[index].foto_sucursal &&
                                        <img src={sucursales && sucursales[index] && sucursales[index].foto_sucursal}
                                            alt="foto"
                                            width="100%"
                                        />
                                    }
                                    <Field
                                        name={`${sucursal}.latitud`}
                                        label="municipios"
                                        component={renderField}
                                        className="form-control"
                                        readOnly
                                    />
                                    <Field
                                        name={`${sucursal}.longitud`}
                                        label="municipios"
                                        component={renderField}
                                        className="form-control"
                                        readOnly
                                    />
                                    {sucursales && sucursales[index] &&
                                    sucursales[index].longitud && sucursales[index].latitud &&
                                        <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${
                                            sucursales[index].latitud && sucursales[index].latitud},${
                                                sucursales[index].longitud && sucursales[index].longitud}`}
                                        >
                                            Ver en Maps
                                        </a>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => {
                    AddMunicipio();
                    if (es_cliente == 1) {
                        fields.push({
                            'horario_entrada': "2020-12-16T07:00:00-06:00",
                            'horario_salida': "2020-01-20T19:00:00-06:00",
                        })
                    } else {
                        fields.push({
                            'horario_entrada': "2020-12-16T07:00:00-06:00",
                            'horario_salida': "2020-01-20T19:00:00-06:00",
                            'departamento': '01'
                        })
                    }
                }
                }>
                    AGREGAR SUCURSAL
                </button>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>}
    </div>
)



let SucursalesForm = (props) => {
    const { handleSubmit, previousStep, municipios_bancos, AddMunicipio, ChangeMunicipio, sucursales } = props;
    return (
        <form name="ContactoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >SUCURSALES</h4>
            </div>
            <FieldArray
                name="sucursales"
                component={renderSucursales}
                municipios_bancos={municipios_bancos}
                AddMunicipio={AddMunicipio}
                ChangeMunicipio={ChangeMunicipio}
                sucursales={sucursales}
                es_cliente={props.es_cliente}
            />

            <div className="row  justify-content-sm-around justify-content-center">
                <button onClick={previousStep} className="btn btn-secondary m-1 align-self-center">ANTERIOR</button>
                <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
            </div>
        </form>
    );
};

SucursalesForm = reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        const errors = {};
        if(!data.sucursales || !data.sucursales.length){
            errors.sucursales = { _error: 'Debe de ingresar al menos una sucursal' }
        }
        return errors;
    }, 
})(SucursalesForm);

const selector = formValueSelector('ClientesForm');
SucursalesForm = connect(state => {

    const sucursales = selector(state, 'sucursales');

    return{
        sucursales
    }
})(SucursalesForm);

export default SucursalesForm;
