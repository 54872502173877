import React from 'react';
// import MultipleValueTextInput from 'react-multivalue-text-input';


import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

import {DropDownDate} from './datedropedown';
import {DropDownDate2} from './datedropedown2';
import { NewDatePicker, NewDatePickerFree } from './datePicker';

// export const renderMultiValue = ({input, name,  placeholder, meta: { touched, error },}) => {
//     console.log("data", input)
//     const invalid = touched && error;
//     return (
//         <div>
//             <MultipleValueTextInput
//                 onItemAdded={(item, allItems) => console.log(`Item added: ${item}`)}
//                 onItemDeleted={(item, allItems) => console.log(`Item removed: ${item}`)}
//                 name={name}
//                 placeholder={placeholder}
//                 values={[]}
//                 charCodes={[12,44,32]}
//             />
//             {invalid && (<div className="invalid-feedback">{error}</div>)}
//         </div>
//     );
// };



export class renderTagsInput extends React.Component {
  constructor() {
    super()
    this.state = {tags: []}
  }

  handleChange=(tags) => {
    console.log(tags)
    this.setState({tags})
    return tags
  }

  render() {
      const keys = [9,13,32]
      const { placeholder, input}= this.props
    return (
        <TagsInput
            value={input.value}
            onChange={(value) => {this.handleChange; input.onChange(value)} }
            addKeys={keys}
            inputProps = {{
                className: 'react-tagsinput-input',
                placeholder: placeholder
              }}
        />
    )
  }
}


export const renderDropdownDateField =({ className, selectedDate=new Date() ,maxDate = '2030-12-31',minDate='1950-01-01', input,  meta: { touched, error }, changePersonalizado }) => {
  const invalid = touched && error;
  if (input.value==="" ){
    input.value=selectedDate;
  }
  return(
    <div>
         <DropDownDate2
        startDate={minDate}
        endDate={maxDate}
        selectedDate={input.value? input.value:selectedDate}
        onDateChange={(date)=>{
          input.onChange(date)
          if(changePersonalizado){
            changePersonalizado(date)
          }
        }}
      />
      {invalid && <div className="invalid-feedback" >{error} </div>}
    </div>
  )

}

export const renderDataPickerDateField =({ className, selectedDate=new Date() ,maxDate = '2030-12-31',minDate='1950-01-01', input,  meta: { touched, error }, changePersonalizado }) => {
    const invalid = touched && error;
    if (input.value==="" ){
      input.value=selectedDate;
    }
    return(
      <div>
          <NewDatePicker
              selected={input.value}
              onChange={(date)=>{
                  input.onChange(date)
                  if(changePersonalizado){
                      changePersonalizado(date)
                  }
              }}
              minDate={minDate}
              maxDate={maxDate}
          />
        {invalid && <div className="invalid-feedback" >{error} </div>}
      </div>
    )

  }

  export const renderDataPickerDateFreeField =({ className, selectedDate=new Date(), maxDate='2030-12-31', minDate='1950-01-01', input, meta:{ touched, error }, changePersonalizado }) => {
    const invalid = touched && error;
    if (input.value==="" ){
      input.value=selectedDate;
    }
    return(
      <div>
          <NewDatePickerFree
              selected={input.value}
              onChange={(date)=>{
                  input.onChange(date)
                  if(changePersonalizado){
                      changePersonalizado(date)
                  }
              }}
              minDate={minDate}
              maxDate={maxDate}
          />
        {invalid && <div className="invalid-feedback" >{error} </div>}
      </div>
    )

  }
