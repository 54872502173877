import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/PeticionMaterial/peticionmaterial';
import DetallePMGrid from './DetallePMGrid';

const mstp = state => {
    return {...state.peticionM, me: state.login.me}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetallePMGrid)
