import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderFieldRadio,renderFieldCheck } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";

let Establecimientoform = (props) => {
    const { handleSubmit} = props;
    return (
        <form name="Establecimientoform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre del establecimiento</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <label className="t-azul"  htmlFor="direccion">Dirección</label>
                    <Field name="direccion" label="direccion" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                <label className="t-azul"  htmlFor="codigo">Código</label>
                    <Field name="codigo" label="codigo" component={renderField} className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                </div>
            </div>

            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/Establecimientos" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


Establecimientoform = reduxForm({
    form: 'Establecimientoform', // a unique identifier for this form,
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            direccion: validators.exists()('Este campo es requerido'),
        });
    },
})(Establecimientoform);

const selector = formValueSelector('Establecimientoform');
Establecimientoform = connect(state =>{

})(Establecimientoform)


export default Establecimientoform