import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "empresas",
    "empresa",
    "Empresaform",
    "/empresas",
);

export default handleActions(reducers, initialState);
