import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions2} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import { Link, Redirect } from 'react-router-dom';
import ToolbarFiltroGastos from './filtroGastos';
import renderFieldCheck from '../../../Utils/renderField'
import Modal from 'react-responsive-modal';

import Swal from 'sweetalert2';


import AnulacionForm from '../../Transacciones/Listar/anulacionForm';
import ToolbarSolicitudes from '../../../Utils/Toolbar/ToolbarSolicitudes';

function cellTachado(cell, row) {
    if(row.id == 0){
        return {color: "#00334E", fontSize: 16, fontWeight: "bold"}
    }
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal'}
    }
    //return {whiteSpace: 'normal', padding: 5}
    
}

export default class GastosGrid extends Component {
    state = {
        id_transaccion: 0,
        transaccion_sel: null
    }
    componentWillMount() {
        const { listar, page, selectUsuarios, changeUsuarioSesion } = this.props;
        changeUsuarioSesion();
        listar(page);
        selectUsuarios();
    }

    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        })
        this.props.setOpenModal(true)
    }
    onSubmit = (values) => {
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminar(values)
            }
        });
    }

    opcionVerAdicional = (id, row) => {
        let ruta_gasto = "/gasto/detalle"
        if(row.tipo_flotante && row.estado){
            ruta_gasto = "/gasto"
        }
        return (
            <Link className="text-warning" to={`${ruta_gasto}/${id}`} >
                <img className="action-img" title="Editar" src={require("../../../../../../assets/img/icons/Ver.png")} alt="Edit" />
            </Link>
        )
    }


    renderModalAnulacion = () => {
        const { open_modal } = this.props;
        return (
            <Modal open={open_modal} onClose={(e) => {
                this.props.setOpenModal(false)
            }} center>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>

                    {
                        (this.state.transaccion_sel && !this.state.transaccion_sel.estado) ? (
                            <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                        ): (
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModal}
                            />
                        )
                    }

            </Modal>
        )
    }
    render() {
        const { data, loader, listar, page, onSortChange, eliminar, permisos, estadoChange, estado, changeUsuario, usuarios, usuario, total } = this.props;
        if(permisos["gastos"] === false) {
            if(!!permisos[`ver_gastos`] === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <Fragment>
                {this.renderModalAnulacion()}
                <CardSimple
                    toolbar= {<ToolbarSolicitudes
                        textBoton="Agregar"
                        ruta="gasto/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre de gasto o proveedor"}
                        permisos={permisos.gastos}
                        estatefilterBar={true}
                        filterbar={
                            <ToolbarFiltroGastos
                                changeEstado={estadoChange}
                                valorEstado={estado}
                                changeUsuario={changeUsuario}
                                usuarios={usuarios}
                                usuario={usuario}
                                permisos={permisos}
                                setDateStart={this.props.setDateStart}
                                setDateEnd={this.props.setDateEnd}
                                dateStart={this.props.dateStart}
                                dateEnd={this.props.dateEnd}
                                proveedor={this.props.proveedor}
                                changeProveedor={this.props.changeProveedor}
                                forma_pago={this.props.forma_pago}
                                formaPagoChange={this.props.formaPagoChange}
                                statusChange={this.props.statusChange}
                                status={this.props.status}
                            />
                        }
                    />}
                    
                    titulo="Gastos">
                        <div className="py-md-3 px-md-3 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                            <div className="col-md-12 m-0 p-0 d-flex justify-content-center">
                                <div className="col-md-10 m-0 p-0 inline-headers text-center">
                                    <span className="t-azul-claro font-weight-bold h5 mr-3"  htmlFor="descripcion">TOTAL </span>
                                    <h3 className="t-primary"  htmlFor="descripcion">{
                                     <RenderMoneda monto={total} simbolo={"Q"} />
                                    }</h3>
                                </div>
                            </div>
                        </div>
                        <Grid hover striped data={data} loading={loader} onPageChange={listar} page={page} onSortChange={onSortChange} >
                            {permisos.gastos && permisos.revisar_gastos && (<TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={(cell, row) =>{
                                    if(row.id != 0){
                                        return customStandardActions2(cell, row, {  
                                            adicional: (c, r) => {
                                            return <label className={`mb-0 d-flex py-1 px-0 px-sm-1 flex-row algin-items-center`}>
                                            <div className="flex-1 px-0 mt-1 d-flex justify-content-center centrar-check">
                                            <input 
                                            type="checkbox" 
                                            component={renderFieldCheck} 
                                            checked={row.check}
                                            onChange={() => {this.props.activar_desactivar(row), this.forceUpdate()}}
                                            /> 
                                            </div>
                                            </label>
                                        } })
                                    }
                                }}
                            >
                                REVISADO
                            </TableHeaderColumn>)}
                            {permisos.gastos && (<TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                dataSort
                                dataFormat={(cell, row) =>{
                                    if(row.id !=0){
                                        return customStandardActions2(cell, row, { 
                                            eliminarModal: this.abrirModalEliminar,  
                                            adicional3: this.opcionVerAdicional })
                                    }
                                }}
                            >
                            </TableHeaderColumn>)}
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                tdStyle={cellTachado}
                                dataFormat = {(cell, row) =>{
                                    if(row.id !=0 ){
                                        return <RenderDateTime fecha={cell} ></RenderDateTime>  
                                    } 
                                }}
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="proveedor"
                                dataSort
                                tdStyle={cellTachado}
                            >
                                PROVEEDOR
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_documento"
                                dataSort
                                tdStyle={cellTachado}
                            >
                                TIPO DE MOVIMIENTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_gasto"
                                dataSort
                                tdStyle={cellTachado}
                            >
                                TIPO DE GASTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                tdStyle={cellTachado}
                                dataFormat= {
                                    (cell, row) => (
                                        <span style={{width:'100%'}} className="d-inline-block text-truncate">{cell}</span>
                                    )
                                }
                            >
                                DESCRIPCIÓN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_flotante"
                                dataSort
                                tdStyle={cellTachado}
                                dataFormat= {
                                    (cell, row) => {
                                        if(row.id == 0){
                                            return(<span className="t-primary font-weight-bold">Subtotal</span>)
                                        }
                                        else if(cell){
                                            return(<span className="t-corinto font-weight-bold">Pendiente</span>)
                                        }else{
                                            return (<span className="t-verde font-weight-bold">Liquidado</span>)
                                        }
                                    }
                                }
                            >
                                LIQUIDADO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="monto"
                                dataSort
                                tdStyle={cellTachado}
                                dataFormat ={
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={this.getsimbolo(row.moneda)} />
                                    )
                                }
                            >
                                MONTO
                            </TableHeaderColumn>
                            
                        </Grid>
                        
                </CardSimple>
            </Fragment>

        );
    }
}
