import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import VerifyForm from './VerifyForm';
import LoadMask from "Utils/LoadMask/LoadMask";
import './Verify.css';
import Logo from '../../../../../assets/img/Logo.png';
class Verify extends Component {
    static propTypes = {
        onVerify: PropTypes.func.isRequired,
    };

    render() {
        const { onVerify, loader, tokenV } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="blue-gradient-bg align-self-center align-items-center justify-content-center ">
                <div className="login-wrapper col-lg-6 col-md-6 col-6">
                    <div className="card card-verify justify-content-center">
                    <img className="logo" src={Logo}></img>
                        <h5 className="text-center pv"><b>INTRODUCIR NUEVA CONTRASEÑA</b></h5>
                        <LoadMask loading={loader} light>
                            <VerifyForm onSubmit={onVerify} initialValues={{"token": tokenV}} />
                            {/* <span>¿Olvidaste tu Contraseña?&nbsp;<Link to="/ResetPassword">Recuperar</Link></span> */}
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Verify;
