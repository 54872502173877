import React, { Component } from 'react'
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardDS from '../../../Utils/Cards/casdsDS';


//manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import LoadMask from "Utils/LoadMask/LoadMask";


import HistoricoGrid from './HistoricoGrid';
import InventarioGrid from './Inventario_grid';
import MovimientosGrid from './MovimientosGrid';
import ToolbarMovimientos from './FiltrosMovimientos';
export default class EstadoBodegaGrid extends Component {
    componentWillMount() {
        const { leer, page, listDS } = this.props;
        leer(this.props.match.params.id);
        this.props.getDespachosPendientes(this.props.match.params.id);
        // lectura de los despachos

    }
    searchInventario = (search) => {
        const bandera = "INVENTARIO"
        this.props.searchChange(search, this.props.match.params.id, bandera);
    }

    searchHistorial = (search) => {
        const bandera = "HISTORIAL"
        this.props.searchChange(search, this.props.match.params.id, bandera);
    }

    searchHistorialMovimientos = (search) => {
        const bandera = "MOVIMIENTOS"
        this.props.searchChange(search, this.props.match.params.id, bandera);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, item, changeTipo, changeUsuario } = this.props;
        return (
            <CardSimple
                titulo={`Bodega: ${item.nombre}`}
            >
                {
                    (this.props.despachos_pendientes && this.props.despachos_pendientes.length > 0) && (
                        <div>
                            <CardDS
                                data={this.props.despachos_pendientes}
                                loader={loader}
                                titulo="MOVIMIENTOS BODEGA PENDIENTES"
                                id_bodega={this.props.match.params.id}
                                link={`/estado_bodega/despacho/${this.props.match.params.id}`}
                            />
                            <br />
                        </div>
                    )
                }
                <br />
                <div className="row">
                    <div className="mb-4 col-12 m-0 p-0">
                        <div className="mb-4 ">
                            <div className=""><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3 p-b">
                                <Tabs
                                    defaultActiveKey="INVENTARIO"
                                    tabBarPoition="top"
                                    onchange={this.callback}
                                    renderTabBar={() => <ScrollableInkTabBar />}
                                    renderTabContent={() => <TabContent />}
                                >
                                    <TabPane tab="INVENTARIO" key="INVENTARIO">
                                        <ToolbarSimple
                                            textBoton="Despachar"
                                            margen={"0"}
                                            ruta={`/estado_bodega/crear/${this.props.match.params.id}`}
                                            buscar={this.searchInventario}
                                            buscador={this.props.search}
                                            placeholder={"Buscar por: Nombre, Código"}
                                            permisos={true}
                                        />
                                        <br />
                                        <LoadMask loading={loader} light type={"TailSpin"}>
                                            <InventarioGrid
                                                {...this.props}
                                                id_bodega={this.props.match.params.id}
                                            />
                                        </LoadMask>
                                    </TabPane>
                                    <TabPane tab="HISTORIAL " key="HISTORIAL">
                                        <ToolbarSimple
                                            textBoton="Despachar"
                                            margen={"0"}
                                            ruta={`/estado_bodega/crear/${this.props.match.params.id}`}
                                            buscar={this.searchHistorial}
                                            buscador={this.props.search}
                                            placeholder={"Buscar por: No. Despacho, Nombre"}
                                            permisos={true}
                                        />
                                        <br />
                                        <LoadMask loading={loader} light type={"TailSpin"}>
                                            <HistoricoGrid
                                                id_bodega={this.props.match.params.id}
                                                {...this.props} />
                                        </LoadMask>
                                    </TabPane>
                                    <TabPane tab="KARDEX " key="KARDEX">    
                                        <br /> 
                                        <ToolbarMovimientos 
                                           changeUsuario={changeUsuario}
                                           usuarios={this.props.usuarios}
                                           changeTipo={changeTipo}
                                           tipos={this.props.tipos}
                                           id_bodega={this.props.match.params.id}
                                           dateStart = {this.props.dateStart}
                                           setDateStart = {this.props.setDateStart}
                                           dateEnd= {this.props.dateEnd}
                                           setDateEnd = {this.props.setDateEnd}
                                        />           
                                        <br />                                                         
                                        <ToolbarSimple
                                            textBoton="Despachar"
                                            margen={"0"}
                                            ruta={`/estado_bodega/crear/${this.props.match.params.id}`}
                                            buscar={this.searchHistorialMovimientos}
                                            buscador={this.props.search}
                                            placeholder={"Buscar por: Nombre"}
                                            permisos={true}
                                            
                                        />  
                                        <br />
                                        <LoadMask loading={loader} light type={"TailSpin"}>
                                            <MovimientosGrid
                                                id_bodega={this.props.match.params.id}
                                                {...this.props} />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br/>
                                            <br/>
                                        </LoadMask>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </CardSimple>
        );
    }
}
