import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";
import moment from 'moment';


let url = 'asignacion' ;
let form = 'AsignacionForm';
let dirGrid = '/asignacion_actividades';

export const constants ={
    LOADER: 'LOADER_ASIGNACION',
    DATA: 'DATA_ASIGNACION',
    ITEM: 'ITEM_ASIGNACION',
    PAGE: `PAGE_ASIGNACION`,
    ORDERING: `ORDERING_ASIGNACION`,
    SEARCH: `SEARCH_ASIGNACION`,
    SET_HISTORIAL: 'SET_HISTORIAL_ASIGNACION',
    SET_PAGE_HISTORIAL: 'SET_PAGE_HISTORIAL_ASIGNACION',
    SET_DATE_START: "SET_DATE_START",
    SET_DATE_END: "SET_DATE_END",
    SEARCH_HISTORIAL: "SEARCH_HISTORIAL"
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setSearchHistorial = searchHistorial => ({
    type: constants.SEARCH_HISTORIAL,
    searchHistorial
})
const setHistorial = data => ({
    type: constants.SET_HISTORIAL,
    data
})

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().asignacionActividades;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = (search, key) => (dispatch) => {
    if(key === 'ASIGNACIONES'){
        dispatch(setSearch(search));
        dispatch(listar());
    }else{
        dispatch(setSearchHistorial(search));
        dispatch(listar_historial());
    }
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().asignacionActividades.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const listar_historial = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().asignacionActividades;
    const params = { page };
    params.search = resource.searchHistorial;
    params.fecha_inicio = resource.dateStart.format("YYYY-MM-D");
    params.fecha_final = resource.dateEnd.format("YYYY-MM-D");
    api.get(`${url}/historial_de_actividades`, params).then( data => {
        dispatch(setHistorial(data));
        dispatch({
            type: constants.SET_PAGE_HISTORIAL,
            page,
        });
    }).catch( error => {
        NotificationManager.error(error.detail || 'Hubo un error en la consulta', 'Error', 3000);
    }).finally( () => {
        dispatch(setLoader(false));
    })
}

const setDateStart = (dateStart) => (dispatch) => {
    dispatch({
        type: constants.SET_DATE_START,
        dateStart
    });
    dispatch(listar_historial());
}

const setDateEnd = (dateEnd) => (dispatch) => {
    dispatch({
        type: constants.SET_DATE_END,
        dateEnd
    });
    dispatch(listar_historial());
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    listar_historial,
    setDateStart,
    setDateEnd
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_HISTORIAL]: ( state, { data }) => ({ ...state, dataHistorial: data }),
    [constants.SET_PAGE_HISTORIAL]: ( state, { page }) => ({ ...state, pageHistorial: page }),
    [constants.SET_DATE_START]: ( state, { dateStart }) => ({ ...state, dateStart }),
    [constants.SET_DATE_END]: ( state, { dateEnd }) => ({ ...state, dateEnd }),
    [constants.SEARCH_HISTORIAL]: ( state, { searchHistorial }) => ({ ...state, searchHistorial })
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    dataHistorial: {
        results: [],
        count: 0,
    },
    dateStart: moment().subtract(1, 'month'),
    dateEnd: moment(),
    pageHistorial: 1,
    item: {},
    page: 1,
    ordering: '',
    searchHistorial: '',
    search: ''
};

export default handleActions(reducers, initialState);
