import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {customStandardActions2} from '../../../Utils/Grid/StandardActions'
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';


//Extras
import {RenderMoneda, RenderDateTime} from '../../../Utils/renderField/renderTableField';
import { getsimbolo } from '../../../../../utility/constants';
import Swal from 'sweetalert2';


import Modal from 'react-responsive-modal';

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class RutasGrid extends Component {

    /*
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
        this.props.handleData(true)
    }
    */

    render() {
        const { data, eliminar, loader, listar: onPageChange, onSortChange, page, permisos, rol } = this.props;
        let editar = 'undefined';
        return (
            <Grid hover
                page={page}
                striped data={data}
                loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
            >
                {
                    this.props.permisos.visualizar_mis_datos && rol != "VENDEDOR/SUPERIOR" ?
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={(cell, row) => {
                            return customStandardActions2(cell, row, {
                                verpro: permisos.ver_detalle_itinerarios | rol == "VENDEDOR/SUPERIOR" ? "itinerario": undefined
                            });
                        }}
                    />
                    :
                    (this.props.permiso && this.props.estado) ? (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataSort
                            dataFormat={(cell, row) => {
                                return customStandardActions2(cell, row, {
                                    eliminar: permisos.eliminar_itinerarios ? eliminar: undefined,
                                    editarItinerario: permisos.editar_itinerarios ? "itinerario": undefined,
                                    verpro: permisos.ver_detalle_itinerarios ? "itinerario": undefined,
                                    gastoItinerario: permisos.desglose_itinerarios ? "itinerario": undefined
                                });
                            }}
                        />
                    ) : 
                    (this.props.permiso && !this.props.estado) ? (
                        <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={(cell, row) => {
                            return customStandardActions2(cell, row, {
                                verpro: permisos.ver_detalle_itinerarios ? "itinerario": undefined
                                
                            });
                        }}
                    />
                    ):
                    (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width="1%"
                            dataSort
                            dataFormat={cell => ""}
                        />
                    )
                }
                <TableHeaderColumn
                    dataField="piloto"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (cell ? cell : '--')
                    }
                >
                    PILOTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="vehiculo"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (cell ? cell : '--')
                    }
                >
                    VEHICULO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_salida"
                    dataSort
                    width="10%"
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )
                    }
                >
                    FECHA SALIDA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_entrada"
                    dataSort
                    width="10%"
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )
                    }
                >
                    FECHA ENTRADA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="no_orden"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={(cell, row) => cell.map((item) => {
                        return (
                            item ? item : '--'
                        )
                    })
                    }
                >
                    ORDEN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cerrado"
                    dataSort
                    width="10%"
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (
                            cell ? "CERRADO" : "ABIERTO"
                        )
                    }
                >
                    ESTADO
                </TableHeaderColumn>
        </Grid>
        );
    }
}
