import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderCurrency,
    renderTextArea,
    renderSelecttree
} from 'Utils/renderField';
import { TipoTransaccion } from '../../../../../utility/constants';
import { getSimbolo} from "Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "Utils/renderField/otherrenders";

import {RenderMoneda, RenderDateTime} from "Utils/renderField/renderTableField";


const renderTarjetas = ({fields, meta: {error, submitFailed }, getProductos, regiones, simbolo}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th>FECHA</th>
                        <th >DOCUMENTO</th>
                        <th >DESCRIPCIÓN</th>
                        <th >MONTO</th>
                        <th >PAGADO</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((PET, index) => {
                        let transaccion = fields.get(index);
                        transaccion = transaccion.transaccion;
                        console.log('transaccion ', transaccion)
                        return (
                            <tr key={index} className="align-items-start">
                                <td className="sin-borde-top ">
                                    <RenderDateTime fecha={transaccion.fecha} ></RenderDateTime>
                                </td>
                                <td className="sin-borde-top ">
                                    {transaccion.no_documento}
                                </td>
                                <td className="sin-borde-top ">
                                    {transaccion.descripcion}
                                </td>
                                <td className="sin-borde-top ">
                                    <RenderMoneda
                                                monto={transaccion.monto}
                                                simbolo={'Q'} />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${PET}.monto_ingresado`}
                                        label="monto_ingresado"
                                        prefix={'Q'}
                                        type="number"
                                        component={renderCurrency}
                                        parce={cell => parseFloat(cell)}
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
)





let TarjetaCreditoForm = (props) => {
    const { handleSubmit,
        cerrarModal,
    } = props;
    return (
        <form name="TarjetaCreditoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback  col-sm-12  col-12">
                <FieldArray
                    name="tarjeta_seleccionados"
                    label="descripcion"
                    component={renderTarjetas}
                    className="form-control"
                    simbolo={'Q.'}
                />
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                        <button type="button" onClick={cerrarModal} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">
                            AGREGAR PAGO
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};


TarjetaCreditoForm =  reduxForm({
    form: 'TarjetaCreditoForm', // a unique identifier for this form
    initialValues: {
        tipo_cuenta: 'true',
        fecha: new Date(),
        fecha_cobro: new Date(),
    },
    validate: (data) => {
        const errors = {};
        if(!data.tarjeta_seleccionados || !data.tarjeta_seleccionados.length){
            errors.tarjeta_seleccionados = {_error: 'Debe de ingresar al menos un producto'}
        }else {
            const detalleArray = []
            data.tarjeta_seleccionados.forEach(function(producto, index){
                let detErrors = {};

                if(!producto.monto_ingresado){
                    detErrors.monto_ingresado = "Campo requerido";
                }else {
                    if(parseFloat(producto.monto_ingresado) <= 0){
                        detErrors.monto_ingresado = "Debe ser mayor a 0"
                    }
                }

                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.tarjeta_seleccionados = detalleArray
            }
        }
        return errors;
    },
})(TarjetaCreditoForm);

const selector = formValueSelector('TarjetaCreditoForm');
TarjetaCreditoForm = connect(state => {
    const tipo_movimiento = selector(state, 'tipo_documento')
    const tipo_cuenta_form = selector(state, 'tipo_cuenta')

    let tipo_cuenta = true
    let esCheque = false;
    let es_tarjeta = false;
    if(tipo_cuenta_form == "false"){
        tipo_cuenta = false
    }
    let tipo_documento = 'Documento de Registro'
    if(tipo_movimiento){
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        tipo_documento = seleccionado.ayuda
        if (seleccionado.value == 2){esCheque = true}
        if(seleccionado.value == 5){es_tarjeta = true}
    }
    return{
        tipo_documento,
        tipo_cuenta,
        esCheque,
        es_tarjeta
    }
})(TarjetaCreditoForm);


export default TarjetaCreditoForm;

