import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";
import _ from 'lodash';

let url = 'periodos' ;
let form = 'transaccionesForm';
let dirGridCuentas = '/transaccion';

export const constants ={
    LOADER: 'TRANS_LOADER',
    DATA: 'TRANS_DATA',
    ITEM: `TRANS_ITEM`,
    PAGE: `TRANS_PAGE`,
    ORDERING: `TRANS_ORDERING`,
    SEARCH: `TRANS_SEARCH`,
    SET_EMPRESAS: 'SET_EMPRESAS',
    SET_CUENTAS: 'SET_CUENTAS',
    ME_CUENTA: 'ME_CUENTA',
    CUENTA_DESTINO: 'CUENTA_DESTINO',
    VALUE_MES: 'VALUE_MES',
    VALUE_ANIO: 'VALUE_ANIO',
    VALUE_MOVIMIENTO: 'VALUE_MOVIMIENTO',
    VALUE_ESTADO: 'VALUE_ESTADO',
    ID_CUENTA: 'ID_CUENTA_TRANSACCIONES',
    PERIODO_TRANSACCION: 'PERIODO_TRANSACCION',
    OPEN_MODAL: 'OPEN_MODAL_TRANS',
    OPEN_MODAL_VER: 'OPEN_MODAL_VER',
    SET_EMPRESAS: 'EMPRESAS_TRANS',
    SET_PENDIENTES:'SET_PENDIENTES_TRANS',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1, id) => (dispatch, getStore) => {
    const resource = getStore().transacciones;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.id = id;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch,getStore) => {
    dispatch(setLoader(true));
    const store = getStore().transacciones;
    const params = { cuenta: id, mes: store.valorMes.value, anio: store.valorAnio.value};
    api.get(`${url}/0`, params).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().transacciones;
    const params = { cuenta: id, mes: store.valorMes.value, anio: store.valorAnio.value};
    api.post(`periodos/crearTransaccion`, data, params).then(() => {
        NotificationManager.success('Transaccion realizada', 'Éxito', 3000);
        if (!!dirGridCuentas)
            dispatch(push(dirGridCuentas+`/${id}`));
    }).catch((error) => {
            let mensaje = "Error en la creación";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGridCuentas)
            dispatch(push(dirGridCuentas));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const bloquear_transaccion = (id, idCuenta) => (dispatch) => {
    dispatch(setLoader(true));
    const data = {
        id
    };
    api.post(`${url}/bloquear_transaccion`, data).then((data) => {
        let mensaje = "Transacción actualizada";
        if(data && data.detail){
            mensaje = data.detail
        }
        NotificationManager.success(mensaje, 'Éxito', 3000)
        dispatch(getPeriodo())
        dispatch(meCuenta(idCuenta, true));
        dispatch(listPCaja(idCuenta));

    }).catch((error) => {
        let mensaje = "Error al bloquear";
        if(error && error.detail){
            mensaje = error.detail
        }
        NotificationManager.error(mensaje, 'ERROR')
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cierrePeriodo = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = {};
    const store = getStore().transacciones;
    const params = { cuenta: id, mes: store.valorMes.value, anio: store.valorAnio.value};
    api.post(`periodos/cierrePeriodo`, data, params).then(() => {
        NotificationManager.success('Se ha cerrado el periodo', 'Éxito', 3000);
        dispatch(getPeriodo())
    }).catch((error) => {
            let mensaje = "Error en la creación"; 
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const anularTransaccion = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/anularTransaccion`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(getPeriodo())
        dispatch(setOpenModal(false))

    }).catch((error) => {
        let mensaje = "Error en la anulación";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(getPeriodo());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().cuentas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectCuentas = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let data = {id}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch(setData(constants.SET_CUENTAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const meCuenta = (id, propia )=> (dispatch, getStore) => {
    dispatch({type: constants.ID_CUENTA, id_cuenta:id})
    dispatch(setLoader(true));
    const store = getStore().transacciones;
    const params = { cuenta: id, mes: store.valorMes.value, anio: store.valorAnio.value};
    api.get(`cuentas/${id}`, params).then((response) => {
        if (propia === true)
        {
            dispatch(setData(constants.ME_CUENTA,response));

        }else {
            dispatch(setData(constants.CUENTA_DESTINO,response));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const getPeriodo = ( )=> (dispatch, getStore) => {

    dispatch(setLoader(true));
    const store = getStore().transacciones;
    const params = { cuenta: store.id_cuenta, mes: store.valorMes.value, anio: store.valorAnio.value};
    params.estado = store.valorEstado.value
    params.search = store.search
    if(store.valorMovimiento){
        params.tipo_transaccion = store.valorMovimiento.value
    }
    api.get(`${url}/${store.id_cuenta}`, params).then((response) => {
        let movimientos = _.cloneDeep(response.movimientos);
        if(movimientos.length > 0) {
            let mov_calc= [{
                id: null,
                transaccion: {
                    descripcion: "Saldo anterior"
                },
                es_ingreso: true,
                estado: true,
                fecha: `${store.valorAnio.value}-${store.valorMes.value}-1`,
                monto: 0,
                debe: 0,
                saldo: response.saldo_apertura,
            }];
            let saldo_inical = response.saldo_apertura;

            _.forEach(movimientos, (mov, index) => {
                mov.es_ingreso ? saldo_inical += mov.monto : saldo_inical -= mov.monto;
                if(index == 0){
                    mov.saldo_a = saldo_inical;
                }else {
                    mov.saldo_a = saldo_inical
                }
                mov_calc.push(_.cloneDeep(mov));
            });
            response.movimientos = mov_calc;
        }
        dispatch({type: constants.PERIODO_TRANSACCION, periodo: response})
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarTransacciones = (id) => (dispatch, getStore) => {
    const resource = getStore().cuentas;
    const params={};
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.id = id;
    dispatch(setLoader(true));
    api.get(`${url}/detalletransaccion`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const flotanteChequePagado = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let idCuenta =  getStore().transacciones.id_cuenta

    api.post(`${url}/flotanteChequePagado`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(getPeriodo())
        console.log(idCuenta)
        dispatch(meCuenta(idCuenta, true))
        dispatch(setOpenModal(false))
    }).catch((error) => {
        let mensaje = "Error en marcar cheque cobrado";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const changeMes = valorMes => (dispatch, getStore) => {
    const store = getStore().transacciones;
    dispatch({
        type: constants.VALUE_MES,
        valorMes
    })
    dispatch(getPeriodo())
};
const changeAnio = valorAnio => (dispatch, getStore) => {
    const store = getStore().transacciones;
    dispatch({
        type: constants.VALUE_ANIO,
        valorAnio
    })
    dispatch(getPeriodo())
};
const changeMovimiento = valorMovimiento => (dispatch, getStore) => {
    const store = getStore().transacciones;

    dispatch({
        type: constants.VALUE_MOVIMIENTO,
        valorMovimiento
    })
    dispatch(getPeriodo())
};
const changeEstado = valorEstado => (dispatch, getStore) => {
    const store = getStore().transacciones;

    dispatch({
        type: constants.VALUE_ESTADO,
        valorEstado
    })
    dispatch(getPeriodo())
};

const initialC = () => (dispatch) =>{
    dispatch(setData(constants.CUENTA_DESTINO,{}));
}

const setOpenModal = open_modal => (dispatch, getStore) => {
    const store = getStore().transacciones;
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}

const setOpenModalDetalle = open_modal_ver => (dispatch, getStore) => {
    const store = getStore().transacciones;
    dispatch({
        type: constants.OPEN_MODAL_VER,
        open_modal_ver: open_modal_ver
    })
}

const retiroDeCuenta = (data, cuenta)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`cuentas/retirocuentabanco`, data).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(meCuenta(cuenta,true));
        dispatch(getPeriodo());
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const subirEstadoCuenta =(data, archivo, cuenta) => dispatch =>{
    dispatch(setLoader(true));
    api.postAttachments(`${url}/estadoCuenta`, data, archivo ).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(meCuenta(cuenta, true))
        dispatch(getPeriodo())
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR');
    }
    ).finally(()=>{
        dispatch(setLoader(false));
    })

}


// Transacciones pendientes de validar
const AcceptMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`cuentas/acceptmovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(meCuenta(caja, true))
        dispatch(getPeriodo())
        dispatch(listPCaja(caja));
        dispatch(leer(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listPCaja = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id}
    api.get(`cuentas/listTransaccionescaja`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}
const RefuseMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`cuentas/refusemovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        // dispatch(estadoMiCaja(caja));
        // dispatch(leer(caja));
        dispatch(listPCaja(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const CancelMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`cuentas/cancelmovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        // dispatch(estadoMiCaja(caja));
        // dispatch(leer(caja));
        dispatch(listPCaja(caja));
    }).catch((res) => {
        NotificationManager.error(res.detail, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    selectEmpresas,
    selectCuentas,
    getPeriodo,
    meCuenta,
    initialC,
    listarTransacciones,
    changeMes,
    changeAnio,
    changeMovimiento,
    changeEstado,
    setOpenModal,
    setOpenModalDetalle,
    anularTransaccion,
    cierrePeriodo,
    flotanteChequePagado,
    retiroDeCuenta,
    subirEstadoCuenta,
    bloquear_transaccion,
    listPCaja,
    RefuseMovimiento,
    CancelMovimiento,
    AcceptMovimiento
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_CUENTAS]:(state,{ data })=>{
        return{
            ...state,
            cuentas: data.cuenta,
        }
    },
    [constants.ME_CUENTA]:(state,{ data })=>{
        return{
            ...state,
            me: data,
        }
    },
    [constants.CUENTA_DESTINO]:(state,{ data })=>{
        return{
            ...state,
            destino: data,
        }
    },
    // [constants.TRANSACCIONES]:(state,{ data })=>{
    //     return{
    //         ...state,
    //         resultados: data,
    //     }
    // },
    [constants.VALUE_MES]:(state,{ valorMes })=>{
        return{
            ...state,
            valorMes,
        }
    },
    [constants.VALUE_ANIO]:(state,{ valorAnio })=>{
        return{
            ...state,
            valorAnio,
        }
    },
    [constants.VALUE_MOVIMIENTO]:(state,{ valorMovimiento })=>{
        return{
            ...state,
            valorMovimiento,
        }
    },
    [constants.VALUE_ESTADO]:(state,{ valorEstado })=>{
        return{
            ...state,
            valorEstado,
        }
    },
    [constants.ID_CUENTA]: (state, {id_cuenta}) => {
        return {
            ...state,
            id_cuenta
        }
    },
    [constants.PERIODO_TRANSACCION]: (state, {periodo}) => {
        return {
            ...state,
            periodo
        }
    },
    [constants.OPEN_MODAL]: (state, {open_modal}) => {
        return {
            ...state,
            open_modal
        }
    },
    [constants.OPEN_MODAL_VER]: (state, {open_modal_ver}) => {
        return {
            ...state,
            open_modal_ver
        }
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    empresas:[],
    cuentas:[],
    me: {},
    destino:{},
    periodo: {},
    // resultados=[],
    valorMes: {
        label: new Date().toLocaleString('default', {month: 'long'}),
        value: new Date().getMonth() + 1
    },
    valorAnio: {
        label: new Date().getFullYear(),
        value: new Date().getFullYear()
    },
    valorMovimiento: null,
    valorEstado: {value: true, label: "Activos"},
    id_cuenta: null,
    open_modal: false,
    open_modal_ver: false,
    pendientes:[],
};

export default handleActions(reducers, initialState);
