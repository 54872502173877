import React, { Component } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';

//manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

//Toolbar
import ChequeFlotante from './cheque_flotante';
import TarjetaFlotante from './tarjeta_flotante';
import FlotanteInverso from './Componentes/fl_inverso';
import DepositoFlotante from './Componentes/deposito_flotante';
import { Redirect } from 'react-router';
// import TransaccionFlotante from './Componentes/transaccion_flotante';


export default class PagoFlotanteContainer extends Component {
    componentDidMount(){
        this.props.selectCuentas(null, null);
    }

    render() {
        const { deudasEmpresas, data, loader, open_modal, permisos, cuentas_banco } = this.props;
        if(this.props.permisos.pago_flotante === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple
                titulo="PAGOS FLOTANTES"
            >
                <div className="row">
                            <div className="mb-4 col-12 m-0 p-0">
                                <div className="mb-4 ">
                                    <div className=""><h6 className="m-0"></h6></div>
                                    <div className="p-0 px-3 pt-3 p-b">
                                    <Tabs
                                        defaultActiveKey="CHEQUE"
                                        tabBarPoition = "top"
                                        onchange={this.callback}
                                        renderTabBar ={()=><ScrollableInkTabBar/>}
                                        renderTabContent={()=><TabContent/>}
                                    >
                                        <TabPane tab="CHEQUE" key="CHEQUE">
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <ChequeFlotante {...this.props} />
                                            </LoadMask>
                                        </TabPane>
                                        <TabPane tab="TARJETA " key="TARJETA">
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <TarjetaFlotante {...this.props} />
                                            </LoadMask>
                                        </TabPane>
                                        <TabPane tab="DEPÓSITOS " key="DEPÓSITOS">
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <DepositoFlotante {...this.props} />
                                            </LoadMask>
                                        </TabPane>
                                        <TabPane tab="FLOTANTE INV." key="FLOTANTE INV.">
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <FlotanteInverso {...this.props} />
                                            </LoadMask>
                                        </TabPane>
                                    </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>

            </CardSimple>
        );
    }
}
