import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset as resetForm } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";
import Swal from 'sweetalert2';

let url = 'gastos' ;
let form = 'GastoForm';
let dirGrid = '/gastos';

export const constants ={
    LOADER: 'GASTOS_LOADER',
    DATA: 'GASTOS_DATA',
    ITEM: `GASTOS_ITEM`,
    PAGE: `GASTOS_PAGE`,
    ORDERING: `GASTOS_ORDERING`,
    SEARCH: `GASTOS_SEARCH`,
    OPEN_MODAL: 'GASTOS_OPENMODAL',
    LOADER_FORMULARIO: 'GASTOS_LOADER_FORMULARIO',
    EMPRESAS: 'GASTOS_EMPRESAS',
    ESTADO: 'GASTOS_ESTADO',
    TIPO_GASTOS: 'GASTOS_TIPO_GASTO',
    CUENTAS: 'GASTOS_CUENTAS',
    SET_USUARIOS: 'GASTOS_USUARIOS',
    SET_SEARCH_USUARIO: 'GASTOS_USUARIO_SET',
    SET_DATE_START: 'GASTOS_SET_DATE_START',
    SET_DATE_END: 'GASTOS_SET_DATE_END',
    SET_FORMA_PAGO: 'SET_FORMA_PAGO_GASTOS',
    SET_PROVEEDOR: 'SET_PROVEEDOR_GASTOS',
    SET_STATUS: 'SET_STATUS_GASTOS',
    SET_TOTAL: 'SET_TOTAL_GASTOS'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})
const setLoaderFormulario = loader_formulario =>({
    type:constants.LOADER_FORMULARIO,
    loader_formulario
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setDataSelect = (type, data) =>({
    type,
    data
});

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().gastos;
    const params = { page };
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    if(visualizar_mis_datos == true | me.permisos.gastos_propios){
        params.creado_por = me.id
    }
    params.search = resource.search;
    params.estado = resource.estado.value;
    if(resource.usuario != null){
        params.creado_por = resource.usuario.value
    }
    if(resource.dateStart != null && resource.dateEnd != null){
        params.fecha_inicial = resource.dateStart.format("YYYY-MM-DD");
        params.fecha_final = resource.dateEnd.format("YYYY-MM-DD");
    }
    if(resource.proveedor){
        params.proveedor = resource.proveedor.id
    }
    if(resource.forma_pago){
        params.tipo_documento = resource.forma_pago.value
    }
    if(resource.status){
        params.tipo_flotante = resource.status.id
    }
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        api.get(`${url}/total_gastos`, params).then((response) =>{
            dispatch({type: constants.SET_TOTAL, total: response})
        })
        
        const results = _.cloneDeep(response.results);
        let subtotal = 0
        if(results.length > 0){
            results.forEach(gasto =>{
                subtotal += gasto.monto
            })
        }
        results.push({id: 0,  monto: subtotal, moneda: "GTQ"});
        response.results = results;
        dispatch(setData(constants.DATA ,response));
        dispatch({type: constants.PAGE, page: page});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        let afectados = []
        let total_empresas = 0
        if(response.afectados.length > 0){
            response.afectados.forEach(afectado => {
                if(afectado.debe > 0){
                    total_empresas += afectado.debe
                    afectados.push(afectado)
                }
            })
            if(total_empresas < response.monto){
                afectados.push({debe: response.monto - total_empresas, empresa_nombre: "INDUSTRIA AYG LOS ALTOS, S.A.", moneda: "GTQ"})
            }
        }
        else{
            afectados.push({debe: response.monto, empresa_nombre: "INDUSTRIA AYG LOS ALTOS, S.A.", moneda: "GTQ"})
        }
        
        response.afectados = afectados
        dispatch({type: constants.ITEM, item: response});
        dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    if(data.vehiculo_asignado)
        data.vehiculo_asignado = data.vehiculo_asignado.id;
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push('/gastos'));
        dispatch(listar());
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};
const crearDesdeReportes = data => (dispatch) => {
    dispatch(setLoader(true));
    if(data.vehiculo_asignado)
        data.vehiculo_asignado = data.vehiculo_asignado.id;
    if(data.fecha_inicio && data.fecha_fin)
        data.fecha_inicio= data.fecha_inicio.format("YYYY-MM-DD");
        data.fecha_fin = data.fecha_fin.format("YYYY-MM-DD");
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        api.post(`valesGasolina/cerrarVales`, data).then(() => {
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
        });
        dispatch(push('/reporte_vales_gasolina'));
        dispatch(listar());
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};

const crearDesdeItinerario = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`itinerario/desgloseGastos`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push(`/itinerario`));
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    if(data.vehiculo_asignado)
        data.vehiculo_asignado = data.vehiculo_asignado.id;
    if(data.tipo_gasto)
        data.tipo_gasto = data.tipo_gasto.value
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/gastos'));

    }).catch((e) => {
        console.log(e)
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};

const eliminar =values => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}/anularTransaccion`, values).then(() => {
        dispatch(setOpenModal(false))
        dispatch(listar());

    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const cambioDescuento = (valor, campo, index, vista) => (dispatch, getStore) => {
    const {  values: { desglose }, values } = _.cloneDeep(vista==undefined ? getStore().form.GastoForm:getStore().form.DesgloseForm);
    if (desglose != undefined){
        if (desglose.length > 0){
            const fraccion = desglose[index]

            let monto = 0
            if(values.monto){
                monto = values.monto
            }
            if(campo === 'precio'){
                if (valor){
                    fraccion.porcentaje = ((valor / parseFloat(monto)) * 100).toFixed(3);
                }
                else{
                    fraccion.porcentaje = 0;
                }
            }
            else if(campo=== 'monto'){
                if(valor){
                    if(fraccion.porcentaje){
                        fraccion.subtotal = ((fraccion.porcentaje / 100) * parseFloat(valor)).toFixed(3)
                    }
                }
            }
            else{
                if (valor){
                    fraccion.subtotal = ((valor / 100) * parseFloat(monto)).toFixed(3);
                }
                else{
                    fraccion.porcentaje = 0;
                }

            }
            dispatch(initializeForm(vista == undefined ? 'GastoForm' : 'DesgloseForm', {...values}))
        }
    }
}

const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}


const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch({
            type: constants.EMPRESAS,
            empresas: res.empresa
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const getTipoGastos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipo_gasto').then((res)=>{
        dispatch({
            type: constants.TIPO_GASTOS,
            tipo_gastos: res
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const  getSelectCuentas = () => (dispatch) =>{
    dispatch(setLoader(true));
    let data = {}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch({
            type: constants.CUENTAS,
            select_cuentas:res.cuenta
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const resetFormulario = () => (dispatch, getStore) => {
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    let tipo_cuenta = "true"
    if(visualizar_mis_datos == true){
        tipo_cuenta = "false"
    }
    dispatch(initializeForm('GastoForm', {
        desglose:[{}],
        tipo_cuenta: tipo_cuenta,
        fecha: new Date(),
        pendiente_liquidar:false
    }))
}
const estadoChange = estado => dispatch => {
    dispatch({type:constants.ESTADO, estado});
    dispatch(listar());
}

const changeUsuario = (usuario) => dispatch => {
    dispatch({type:constants.SET_SEARCH_USUARIO, usuario});
    dispatch(listar())
}

const changeUsuarioSesion = () => (dispatch, getStore) =>{
    const resource = getStore().gastos
    const me = getStore().login.me
    const usuario = {}
    usuario.value = me.id
    usuario.label = me.nombre
    if(resource.usuario == false){
        dispatch({type:constants.SET_SEARCH_USUARIO, usuario});
    }
}

const selectUsuarios = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('user/selectusuarios').then((res)=>{
        dispatch(setDataSelect(constants.SET_USUARIOS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setDateStart = (value) => (dispatch) => {
    dispatch({type: constants.SET_DATE_START, dateStart: value})
    dispatch(listar());
}

const setDateEnd = (value) => (dispatch) => {
    dispatch({type: constants.SET_DATE_END, dateEnd: value})
    dispatch(listar());
}

const changeProveedor = (value) => (dispatch) => {
    dispatch(setData(constants.SET_PROVEEDOR, value));
    dispatch(listar());
}

const formaPagoChange = (forma_pago) => dispatch => {
    dispatch({type:constants.SET_FORMA_PAGO, forma_pago});
    dispatch(listar());
}

const statusChange = status => dispatch => {
    dispatch({type:constants.SET_STATUS, status});
    dispatch(listar());
}

const activar_desactivar = row => (dispatch, getStore) =>{
    const params = {}
    params.id = row.id
    params.check = !row.check
    Swal.fire({
        title: '¿Desea cambiar el estado del gasto?',
        //text: '¡No podrá revertir esta acción!',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: '¡Aceptar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if(result.value){
            api.post(`${url}/activar_desactivar`, params).then((res)=>{
                NotificationManager.success('Gasto actualizado', 'Éxito', 3000);
                dispatch(listar())
            }).catch(()=>{
            }).finally(()=>{
                dispatch(setLoader(false))
            })
        }
    })
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModal,
    selectEmpresas,
    getTipoGastos,
    getSelectCuentas,
    resetFormulario,
    cambioDescuento,
    crearDesdeItinerario,
    crearDesdeReportes,
    estadoChange,
    changeUsuario,
    changeUsuarioSesion,
    selectUsuarios,
    setDateStart,
    setDateEnd,
    changeProveedor,
    formaPagoChange,
    statusChange,
    activar_desactivar
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.EMPRESAS]: (state, {empresas}) => {
        return {
            ...state,
            empresas
        }
    },
    [constants.ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
    [constants.TIPO_GASTOS]: (state, {tipo_gastos}) => {
        return {
            ...state,
            tipo_gastos
        }
    },
    [constants.CUENTAS]: (state, {select_cuentas}) => {
        return {
            ...state,
            select_cuentas
        }
    },
    [constants.SET_USUARIOS]:(state,{ data })=>{
        return{
            ...state,
            usuarios: data.usuario,
        }
    },
    [constants.SET_SEARCH_USUARIO]: (state, {usuario}) => {
        return {
            ...state,
            usuario
        }
    },
    [constants.SET_DATE_START]: (state, { dateStart }) => {
        return {
            ...state,
            dateStart
        }
    },
    [constants.SET_DATE_END]: (state, { dateEnd }) => {
        return {
            ...state,
            dateEnd
        }
    },
    [constants.SET_FORMA_PAGO]: (state, { forma_pago }) => {
        return {
            ...state,
            forma_pago,
        };
    },
    [constants.SET_PROVEEDOR]: (state, { data }) => {
        return {
            ...state,
            proveedor: data
        };
    },
    [constants.SET_STATUS]: (state, { status }) => {
        return {
            ...state,
            status,
        };
    },
    [constants.SET_TOTAL]: (state, { total }) => {
        return {
            ...state,
            total,
        };
    },
};


export const initialState ={
    loader:false,
    data: [],
    item: {},
    page: 1,
    ordering: '',
    search: '',
    open_modal: false,
    loader_formulario: false,
    estado: {value: true, label: "Activos"},
    empresas: [],
    tipo_gastos: [],
    select_cuentas: [],
    usuario: false,
    usuarios: [],
    dateStart: null,
    dateEnd: null,
    forma_pago: null,
    proveedor: null,
    status: null,
    total: 0
};

export default handleActions(reducers, initialState);
