import React, { Component } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";
import {AsyncSelectField} from '../../../Utils/renderField';
import {standardActions} from '../../../Utils/Grid/StandardActions';

export default class detalleCuenta extends Component {
    state = {
        es_cliente:this.props.es_cliente,
    }
    handleEstado = (value) =>{
        this.setState({es_cliente: value})
        this.props.cuentasporCOP({}, value)            
    }
    handleDireccion = (direccion) =>{
        if (direccion == '/cuentas_por_cobrar')
            this.handleEstado(true)    
        else
            this.handleEstado(false)
        
    }
    componentDidMount(){
        // console.log(this.props.match.url)
        // this.handleDireccion(this.props.match.url)
        // console.log(this.props)
    }
    componentDidUpdate(prevProps){
        // console.log(this.props.match.url)
        // if (this.props.match.path != prevProps.match.path){
            // this.handleDireccion(this.props.match.url) 
        // }
    }
    
    render() {
        const { data, loader, moneda } = this.props;
        
        return (
            <div style={{paddingLeft:"18px"}}>
                <h5> DETALLE DE CUENTA </h5>
                <Grid hover striped data={{count: 0, results: data}} loading={loader} >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        className='reporte-table'
                        isKey
                        dataFormat={
                            standardActions({ verpro: `${ this.props.es_cliente ? 'ordenesventa':'ordencompra'}` })
                        }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado"
                        dataAlign="center"
                        dataSort
                        dataFormat={
                            (cell, row) =>(
                                <div className="d-flex justify-content-center">
                                    <span className={`estado-reporte-${cell}`} style={{margin:"0"}}></span>
                                </div>
                            )
                        }
                        className='reporte-table'
                    >
                        ESTADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_orden"
                        dataSort
                        className='reporte-table'
                    >
                        ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        className='reporte-table'
                    >
                        FECHA DE VENCIMINETO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataAlign="right"
                        dataSort
                        dataFormat={
                            (cell, row) =>(
                                <RenderMoneda monto={Math.abs(cell)} simbolo={getsimbolo(moneda)} />
                            )
                        }
                        className='reporte-table'
                    >
                        TOTAL
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                        dataField="abonado"
                        dataAlign="right"
                        dataSort
                        dataFormat={
                            (cell, row) =>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(moneda)} />
                            )
                        }
                        className='reporte-table'
                    >
                        PAGADO
                    </TableHeaderColumn> */}
                    <TableHeaderColumn
                        dataField="pendiente"
                        dataAlign="right"
                        dataSort
                        dataFormat={
                            (cell, row) =>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(moneda)} />
                            )
                        }
                        className='reporte-table'
                    >
                        PENDIENTE
                    </TableHeaderColumn>
                </Grid>
            </div>
        );
    }
}
