import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "establecimientos",
    "establecimiento",
    "Establecimientoform",
    "/establecimientos",
);

export default handleActions(reducers, initialState);
