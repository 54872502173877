import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/Empresas/empresas';
import Empresa from './empresa';


const ms2p = (state) => {
  return {
    ...state.empresas,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Empresa);