import React, { Component,  Fragment } from 'react'
import ToolBar from '../../../../Utils/Toolbar/ToolbarReporteFlotantes';

import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../../utility/constants";
import {standardActions} from "../../../../Utils/Grid/StandardActions";
import Modal from 'react-responsive-modal';


import AnulacionForm from '../../../Transacciones/Listar/anulacionForm';
import RealizarPagoForm from '../../../Transacciones/Listar/realizarPagoForm';
import RetiroForm from '../../../Transacciones/Listar/retiroForm';


import Swal from 'sweetalert2';
import moment from 'moment';

import ToolbarSimple from '../../../../Utils/Toolbar/ToolbarSimple';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}

export default class FlotanteInverso extends Component {
    state = {
        id_transaccion: 0,
        transaccion_sel: null,
        anular_transaccion: false,
        open_modal_retiro:false
    }
    componentDidMount(){
        this.props.reporteFlotanteInverso();
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    botonModal = (id, row) => {

        if(row.estado){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../../assets/img/icons/Ver.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            anular_transaccion: true
                        })
                        this.props.setOpenModal(true);
                     }}
                      alt="Ver eliminado" />)
    }
    botonRealizarMovimiento = (id, row) => {
        if(!row.estado){
            return(<div></div>)
        }
        if(row.transaccion.flotante_inverso){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={
                    (e)=>{
                    e.preventDefault();
                    this.props.setTransaccion({
                        ...row.transaccion,
                        id_cuenta: this.props.match.params.id
                    }, 'reporte_pago_flotante');
                }
                }>
                    <img className="action-img" title="Ver flotante" src={require("../../../../../../../assets/img/icons/Pagos_flotantes.png")} alt="Ver pago flotante" />
                </a>
            )
        }else {
            if(!row.flotante){

                return(<div></div>)
            }
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={
                    (e)=>{
                    e.preventDefault();
                    this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    anular_transaccion: false
                    })
                    this.props.setOpenModal(true);
                }
                }>
                    <img className="action-img" title="Marcar bono" src={require("../../../../../../../assets/img/icons/marcar_bono.png")} alt="Marcar bono" />
                </a>
            )
        }
    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModal(true);
    }
    onSubmit = (values) => {
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values);
            }
        });
    }
    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD')
                this.props.flotanteChequePagado(values);
            }
        });
    }
    renderModalAnulacion = () => {
        return(
            <Fragment>
                <div className="mt-5">
                            <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                        </div>
                            {
                                (this.state.transaccion_sel && !this.state.transaccion_sel.estado) ? (
                                    <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                                ): (
                                    <AnulacionForm
                                    onSubmit={this.onSubmit}
                                    cerrarModal={this.props.setOpenModal}
                                    />
                                )
                            }
            </Fragment>
        )
    }
    renderModalFlotantePagado = () => {
        let transaccion = null
        if(this.state.transaccion_sel){
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            }
        }
        return(
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">REALIZAR PAGO</h2>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={this.props.setOpenModal}
                    />
            </Fragment>
        )
    }
    render() {
        const {
            loader,
            cuentas_banco,
            setCuentaInverso,
            reporte_fl_inverso,
            cuenta_inverso_flotane,
            open_modal
        } = this.props;
        return (
            <div>
                <Modal open={open_modal} onClose={(e) => {
                        this.props.setOpenModal(false)
                    }} center>
                        {
                            (this.state.anular_transaccion) ? (
                                this.renderModalAnulacion()
                            ): (
                                this.renderModalFlotantePagado()
                            )
                        }
                    </Modal>

                <div className="py-md-3 px-md-3 p-0" style={{border:"1px solid #E8E8E8", }}>
                    <ToolBar
                        es_tarjeta={false}
                        {...this.props}
                        valor_filtro={cuenta_inverso_flotane}
                        cuentas_banco={cuentas_banco}
                        changeFiltro={setCuentaInverso}/>
                </div>
                <div className="py-md-3 px-md-3 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-md-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-10 m-0 p-0 inline-headers text-center">
                            <span className="t-azul-claro font-weight-bold h5 mr-3"  htmlFor="descripcion">TOTAL </span>
                            <h3 className="t-primary"  htmlFor="descripcion">{
                             <RenderMoneda monto={reporte_fl_inverso.total_cobrar} simbolo={"Q"} />
                            }</h3>
                        </div>
                    </div>
                </div>
                <div className="py-md-3 px-md-0 p-0" >
                    <ToolbarSimple
                        buscar={this.props.searchFlotanteChange}
                        buscador={this.props.search_flontante}
                        placeholder={"Buscar por: No doc, descripción, monto"}
                    />
                </div>
                <div className="p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                 <Grid hover striped data={{count: 0, results: reporte_fl_inverso.movimientos}} loading={loader}  >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                className='reporte-table'
                                isKey
                                dataFormat={standardActions({ adicional: this.botonModal, adicional2: this.botonRealizarMovimiento})}
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                tdStyle={cellTachado}
                                dataSort
                                className='reporte-table'
                                dataFormat = {
                                    (cell,row) => {
                                        return(
                                            <div>
                                                <span className="font-weight-bold">FECHA </span><RenderDateTime fecha={cell} ></RenderDateTime> <br/>
                                                {
                                                    (row.fecha_emision) && (
                                                        <Fragment>
                                                            <span className="font-weight-bold">FECHA EMISIÓN </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br/>
                                                        </Fragment>
                                                    )
                                                }
                                                <span className="font-weight-bold">TIPO DE PAGO</span> <span>{row.flotante ? <span>Flotante</span> : <span>Inmediato</span>}</span>
                                            </div>
                                        )
                                    }
                                }
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="transaccion"
                                dataSort
                                className='reporte-table'
                                dataFormat={
                                    (cell, row) => {
                                        if(cell){
                                        return <span>{cell.descripcion}</span>
                                        }
                                        return <span>-- --</span>
                                    }
                                }
                                tdStyle={cellTachado}
                            >
                                DESCRIPCIÓN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="es_ingreso"
                                tdStyle={cellTachado}
                                dataSort
                                className='reporte-table'
                                dataFormat = {
                                    (cell, row)=>(cell? "Ingreso":"Egreso")
                                }
                            >
                                MOVIMIENTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="transaccion"
                                dataSort
                                className='reporte-table'
                                tdStyle={cellTachado}
                                dataFormat={
                                    (cell, row) => {
                                        if(cell){
                                            return <span>{cell.no_documento}</span>
                                        }
                                        return <span>-- --</span>
                                    }
                                }
                            >
                                DOCUMENTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="transaccion"
                                dataSort
                                className='reporte-table'
                                tdStyle={cellTachado}
                                dataFormat = {
                                    (cell, row)=>{
                                        return <span>{cell?  cell.propietario:"---"}</span>
                                    }
                                }
                            >
                                PROPIETARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="saldo"
                                dataSort
                                className='reporte-table'
                                tdStyle={cellTachado}
                                dataFormat ={
                                    (cell, row)=>(
                                        <RenderMoneda monto={row.transaccion.monto } simbolo={this.getsimbolo(row.transaccion.moneda)} />
                                    )
                                }
                            >
                                MONTO
                            </TableHeaderColumn>
                        </Grid>
                </div>
            </div>
        )
    }
}
