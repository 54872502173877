import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_PRECIO_PRODUCTO_LOADER',
    DATA: "REPORTE_PRECIO_PRODUCTO_DATA",
    ITEM: "REPORTE_PRECIO_PRODUCTO_ITEM",
    PAGE: `REPORTE_PRECIO_PRODUCTO_PAGE`,
    SET_PRODUCTO: 'SET_PRODUCTO_REPORTE_PRECIO_PRODUCTO',
    SET_CLIENTE: 'SET_CLIENTE_PRECIO_PRODUCTO'
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type:type,
    data
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setCliente = cliente => (dispatch) => {
    dispatch({
        type: constants.SET_CLIENTE,
        cliente
    })
}

const setProducto = producto => ({
    type: constants.SET_PRODUCTO,
    producto
})

// ACTIONS

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportePrecioProducto;
    const params = { page };
    if(resource.producto){
        params.producto = resource.producto.id
    }
    if(resource.cliente){
        params.cliente = resource.cliente.id
    }
    dispatch(setLoader(true));
    api.get(`${url}/precios_cliente`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const mensajeError = (error) => {
    let mensaje = 'Hubo un error';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
}

const changeCliente = (value) => (dispatch) => {
    dispatch(setCliente(value));
    dispatch(listar())
}

const changeProducto = (value) => (dispatch) => {
    dispatch(setProducto(value));
    dispatch(listar())
}

export const actions = {
    listar,
    changeCliente,
    changeProducto,
}

export const reducers = {
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) =>{
        return{
            ...state,
            data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SET_PRODUCTO]: (state, { producto }) => {
        return {
            ...state,
            producto
        };
    },
    [constants.SET_CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente
        };
    },
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    producto: undefined,
    cliente: undefined,
}

export default handleActions(reducers, initialState);
