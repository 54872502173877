import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { RenderDateTimeAll, RenderDateTime, RenderMoneda, getSimbolo } from "../../../Utils/renderField/renderTableField";

import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';

import LoadMask from "Utils/LoadMask/LoadMask";
import GridDespachos from './GridDespachos';


export default class DetalleDespachoPlanta extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        let id = this.props.match.params.id
        this.props.leerDespachoPlanta(id);
    }

    cerrar = (event) => {
        this.props.cerrar(event.target.value);
    }

    render() {
        const { item, data, loader, listar: onPageChange, mostrarVista, onSortChange, permisos } = this.props;

        return (
            <LoadMask loading={loader} light type={"TailSpin"}>

                <div className="Container bg-white">
                    {item && (
                        <div className="m-t p-t fnt-size">
                            <div className="row m-none" style={{ paddingTop: "15px" }}>
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-primary m-none"> {item ? `Despacho No. ${item.id}` : ""}</h3>
                                </div>
                            </div>
                            <br />

                            <div className="col-12">
                                <span className="m-none t-primary bold"> Cliente </span>
                            </div>
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Nombre Cliente: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.nombre_cliente ? item.nombre_cliente : "--"}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Nombre quien recibe: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.nombre_recibe ? item.nombre_recibe : "--"}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha Despacho: </span> <br />
                                    <span className="m-none t-musgo semibold"> <RenderDateTime fecha={item.fecha_despacho ? item.fecha_despacho : "--"} > </RenderDateTime> </span>
                                </div>
                            </div>
                            <br /><br />
                            {item && item.despachoplanta_detalle && (
                                <GridDespachos
                                    {...this.props}
                                    despachoplanta={item.despachoplanta_detalle}
                                    loader={loader}
                                    page={1}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                    paraCliente={true}
                                    PDFDESPACHO={this.props.PDFDESPACHO}
                                    mostrarVista={mostrarVista}
                                    permisos={permisos}
                                    rol={this.props.rol}
                                />
                            )}
                        </div>
                    )}
                </div>
            </LoadMask>
        )
    }
}
