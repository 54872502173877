import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { PROCESOS } from '../../../../../utility/constants'
import CardPMEnviadas from '../../../Utils/Cards/cardsPM';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { Redirect } from 'react-router-dom';


export default class OrdenCompraGrid extends Component {
    componentDidMount() {
        const { listar, listPM, page, permisos } = this.props;
        listar(page);
        permisos.ordencompra && (listPM(''))
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos["peticionmateriales"] === false && permisos["ordencompra"] === false ) {
            return <Redirect to={`/`} />
        } 
        return (
            <CardSimple
                titulo="Peticiones de Materiales">
                    {
                        (this.props.pendientes && this.props.pendientes.length > 0) &&  (
                            <CardPMEnviadas
                                data={this.props.pendientes}
                                loader={loader}
                                titulo="PENDIENTES DE APROBAR"
                                link={`peticiones`}
                            />
                        )
                    }

                    <h5 className="t-primary">{'LISTADO DE PETICIONES DE MATERIALES'}</h5>
                    <ToolbarSimple
                        margen={"0"}
                        textBoton="Agregar"
                        ruta="/peticiones/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Orden"}
                        permisos={permisos.peticionmateriales}
                    />
                    <br/>
                    <Grid page={this.props.page} hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataSort
                            dataFormat={
                                standardActions({ editarpm: "peticiones",  eliminarpm:eliminar , verpro:"peticiones" })
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre_empresa"
                            dataSort
                        >
                            EMPRESA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataSort
                            dataFormat = {(cell, row) => {
                                return <RenderDateTime fecha={cell} />
                            }}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="no_orden"
                            dataSort
                        >
                            NO. ORDEN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proceso_estado"
                            dataSort
                        >
                            ESTADO
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
