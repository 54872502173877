import React, { Component } from 'react'
import {Link, Redirect} from 'react-router-dom'
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
// formulario
import DespachoForm from './despachoForm';


export default class DespachoContainer extends Component {
    componentDidMount(){
        let id = this.props.match.params.id
        this.props.getDetalleOrden(id);
        if (id != undefined && id != null){

        }else{
            return (<Redirect to="/ordenesventa" />)
        }
    }
    handleSubmit = (values) => {
        const {DespacharProducto} = this.props
        let id = this.props.match.params.id
        let valores = {...values}
        valores.tipo_movimiento = 10; // 10 se refiere a ingreso de materia prima.
        DespacharProducto(valores, id)
    }
    render() {
        const { loader, detalle_orden } = this.props;
        return (
            <CardFormulario titulo={"Ingreso materia prima"}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <DespachoForm
                        orden={this.props.match.params.id}
                        onSubmit={this.handleSubmit}
                        setearConversion={this.props.setearConversion}
                    />
                </LoadMask>
            </CardFormulario>
        )
    }
}
