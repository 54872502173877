import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
    actions
} from '../../../../../redux/modules/itinerarios/itinerarios';
import CrearItinerario from './crearItinerario';
import Facturacion from '../Facturas/index';

const mstp = state => {
    return {...state.itinerario, permisos: {...state.login.me.permisos}, misdatos: {...state.form.itinerarioForm}}
};

const mdtp = {
    ...actions
};

const container = (props) => {
    const { misdatos } = props
    const [state, setState] = React.useState({ vistaFacturacion: false, data: null, entra_editar: false });
    const [count, setCount] = React.useState(0);

    const setClienteSucursal = (e) => {
        setCount({ cliente: e.id, sucursal: e.sucursal })
    }

    return (
        state.vistaFacturacion === false ?
            <CrearItinerario
                {...props}
                count={count}
                setClienteSucursal={setClienteSucursal}
                entra_editar={state.entra_editar}
                mantener_datos={() => setState({ entra_editar: true })}
                mostrarVista={(vistaFacturacion, index) => {
                    setState({
                        losdatos: misdatos.values.itinerario_detalle_clientes ? misdatos.values.itinerario_detalle_clientes[index] : [], vistaFacturacion, index
                    }
                    )
                }}
            />
            :
            <Facturacion
                {...props}
                losdatos={state.losdatos}
                mostrarVista={() => setState({ vistaFacturacion: false })} 
                mantener_datos={() => setState({ entra_editar: true })}/>
                
    )
}

export default connect(mstp, mdtp)(container)
