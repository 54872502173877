import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/pilotos/pilotos';
import PilotosGrid from './PilotosGrid';

const mstp = state => {
    return {
        ...state.pilotos, 
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PilotosGrid)
