import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reportePreciosClientes';
import ReportePreciocliente from './ReportePrecioCliente';

const mstp = state => {
    return {
        ...state.reportePrecioCliente,
        permisos: state.login.me.permisos,
        id_user: state.login.me.id
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReportePreciocliente)
