import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import Swal from 'sweetalert2';

let url = 'reportes' ;
let form = 'PrecioClienteForm';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_CLIENTES_LOADER',
    DATA: "REPORTE_CLIENTES_DATA",
    ITEM: "REPORTE_CLIENTES_ITEM",
    PAGE: `REPORTE_CLIENTES_PAGE`,
    SET_PRODUCTO: 'SET_PRODUCTO_REPORTE_CLIENTE',
    SET_CLIENTE: 'SET_CLIENTE_REPORTE',
    SET_OPEN_MODAL: 'SET_OPEN_MODAL_PRECIOS_CLIENTES',
    PRODUCTO_SELECCIONADO: 'PRECIOS_CLIENTES_SELECCIONADO',
    SET_FECHA_ACTUALIZACION: 'SET_FECHA_ACTUALIZACION_PRECIOS',
    SET_PRECIO_INTERNO_ANTERIOR: 'SET_PRECIO_CLIENTE_ANTERIOR',
    SET_EMPRESAS:'PRECIOS_CLIENTES_SET_EMPRESAS',
    SET_TIPOS: 'PRECIOS_CLIENTES_TIPOS',
    SET_SEARCH_EMPRESA: 'PRECIOS_CLIENTES_SET_SEARCH_EMPRESA',
    SET_SEARCH_TIPO: 'PRECIOS_CLIENTES_SET_SEARCH_TIPO',
    SET_VENDEDORES: 'PRECIOS_CLIENTES_SET_VENDEDORES',
    SET_SEARCH_VENDEDOR: 'PRECIOS_CLIENTES_SET_SEARCH_VENDEDOR',
    SET_CLIENTES: 'PRECIOS_CLIENTES_SET_CLIENTES',
    SET_PRODUCTOS: 'PRECIOS_CLIIENTES_SET_PRODUCTOS',
    SEARCH: `PRECIOS_CLIENTES_SEARCH`,
    PRODUCTOS_SELECCIONADOS: 'PRECIOS_CLIENTES_SELECCIONADOS',
    SET_SELECCIONAR_TODOS: 'PRECIOS_CLIENTES_SET_SELECCIONAR_TODOS',
    SET_PRODUCTOS_DESMARCADOS: 'PRECIOS_CLIENTES_SET_PRODUCTOS_DESMARCADOS'
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type:type,
    data
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setCliente = cliente => (dispatch) => {
    dispatch({
        type: constants.SET_CLIENTE,
        cliente
    })
}

const setProducto = producto => ({
    type: constants.SET_PRODUCTO,
    producto
})
const setDataSelect = (type, data) =>({
    type,
    data
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

// ACTIONS

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportePrecioCliente;
    const params = { page };
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = me.id
    }
    let productos_ids = []
    if(resource.producto){
        resource.producto.forEach(producto =>{
            productos_ids.push(producto.value)
        })
        params.producto = productos_ids
    }
    let clientes_ids = []
    if(resource.cliente.length > 0){
        resource.cliente.forEach(cliente =>{
            if(resource.vendedor.length > 0){
                clientes_ids.push(cliente.value)
            }
            else{
                clientes_ids.push(cliente.id)
            }
        })
        params.cliente = clientes_ids
    }
    if(resource.empresa){
        params.empresa = resource.empresa.value
    }
    let tipos_ids =[]
    if(resource.tipo){
        resource.tipo.forEach(tipo =>{
            tipos_ids.push(tipo.value)
        })
        params.tipo_producto = tipos_ids
    }
    if(resource.search){
        params.search = resource.search
    }
    dispatch(setLoader(true));
    api.get(`${url}/precios_cliente`, params).then((response) => {
        if(resource.seleccionar_todos){
            if(resource.productos_desmarcados.length > 0){
                response.results.forEach(producto =>{
                    resource.productos_desmarcados.forEach(prod =>{
                        if(producto.id_catalogo == prod.id_catalogo){
                            producto.check=false
                        }
                        else{
                            producto.check=true
                        }
                    })
                })
            }
            else{
                response.results.forEach(producto =>{
                    producto.check=true
                })
            }
        }
        else{
            response.results.forEach(producto =>{
                if(resource.productos_seleccionados.length > 0){
                    resource.productos_seleccionados.forEach(prod =>{
                        if(producto.id_catalogo == prod.id_catalogo){
                            producto.check=true
                        }
                    })
                }
                else{
                    producto.check=false
                }
            })
        }
        dispatch(setData(response));
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const mensajeError = (error) => {
    let mensaje = 'Error en la creación de la ruta';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
}

const changeCliente = (value) => (dispatch) => {
    dispatch(setCliente(value));
    if(value.length > 0){
        dispatch(listar())
    }
    else{
        dispatch(setData(constants.DATA ,{
            results: [],
            count: 0,
        },));
    }
}

const changeProducto = (value) => (dispatch) => {
    dispatch(setProducto(value));
    dispatch(listar())
}

const guardarCambio = (es_individual=true) => (dispatch, getStore) => {
    const form = getStore().form.PrecioClienteForm.values
    const producto = getStore().reportePrecioCliente.producto_seleccionado
    const productos = getStore().reportePrecioCliente.productos_seleccionados
    const resource = getStore().reportePrecioCliente
    const producto_filtrado = getStore().reportePrecioCliente.producto
    const search_data = getStore().reportePrecioCliente.search
    const seleccionar_todos = getStore().reportePrecioCliente.seleccionar_todos
    let data = {}
    if(es_individual){
        data.producto = producto
    }
    else{
        data.productos = productos
        let productos_ids = []
        if(producto_filtrado){
            producto_filtrado.forEach(producto =>{
                productos_ids.push(producto.value)
            })
            data.producto_filtrado = productos_ids
        }
    }
    const tipo_monetario = form.tipo_monetario
    const es_aumento = form.es_aumento
    data.tipo_monetario = tipo_monetario
    data.es_aumento = es_aumento
    data.es_individual = es_individual
    data.seleccionar_todos = seleccionar_todos
    data.productos_desmarcados = resource.productos_desmarcados
    let clientes_ids = []
    if(resource.cliente.length > 0){
        resource.cliente.forEach(cliente =>{
            if(resource.vendedor.length > 0){
                clientes_ids.push(cliente.value)
            }
            else{
                clientes_ids.push(cliente.id)
            }
        })
        data.cliente = clientes_ids
    }
    
    if(search_data){
        data.search = search_data
    }
    if(tipo_monetario== 'true'){
        let monto = form.monto
        data.monto = monto
    }
    else{
        let porcentaje = form.porcentaje
        data.porcentaje = porcentaje
    }
    if(es_individual){
        dispatch(setLoader(true));
        api.post(`productos/cambiar_precio_cliente`, data).then((res)=>{
            NotificationManager.success('Producto actualizado', 'Éxito', 3000);
            dispatch({type: constants.SET_OPEN_MODAL, open_modal: false})
            dispatch(listar())
            let tipo_monetario = 'true'
            let es_aumento = 'true'
            let values = {}
            values.tipo_monetario = tipo_monetario
            values.es_aumento = es_aumento
            values.monto = 0
            values.porcentaje = 0
            dispatch(initializeForm('PrecioClienteForm', values));
        }).catch((res)=>{
            NotificationManager.error(res.detail, 'ERROR');
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }
    else if(productos.length == 0 && !seleccionar_todos){
        if(!seleccionar_todos){
            NotificationManager.error('Debe seleccionar al menos un producto', 'Éxito', 3000);
        }
    }
    else if(seleccionar_todos && resource.data.count == 0){
        NotificationManager.error('Debe seleccionar al menos un producto', 'Éxito', 3000);
    }
    else{
        Swal.fire({
            title: '¿Desea actualizar todos los productos listados?',
            text: '¡No podrá revertir esta acción!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Aceptar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if(result.value){
                const resource = getStore().reportePrecioCliente
                if (resource.empresa != null)
                    data.empresa = resource.empresa.value;
                let tipos_ids =[]
                if(resource.tipo){
                    resource.tipo.forEach(tipo =>{
                        tipos_ids.push(tipo.value)
                    })
                    data.tipo_producto = tipos_ids
                }
                dispatch(setLoader(true));
                api.post(`productos/cambiar_precio_cliente`, data).then((res)=>{
                    NotificationManager.success('Producto actualizado', 'Éxito', 3000);
                    dispatch({type: constants.SET_OPEN_MODAL, open_modal: false})
                    let tipo_monetario = 'true'
                    let es_aumento = 'true'
                    let values = {}
                    values.tipo_monetario = tipo_monetario
                    values.es_aumento = es_aumento
                    values.monto = 0
                    values.porcentaje = 0
                    dispatch(initializeForm('PrecioClienteForm', values));
                    dispatch(listar())
                }).catch((res)=>{
                    NotificationManager.error(res.detail, 'ERROR');
                }).finally(()=>{
                    dispatch(setLoader(false))
                })
            }
            else{
                dispatch(setLoader(false))
            }
        });
    }
    
}
const setOpenModal = (value, precio_unitario=0) => (dispatch) =>{
    dispatch({type: constants.SET_OPEN_MODAL, open_modal: value})
    if (!!form){
        let tipo_monetario = 'true'
        let es_aumento = 'true'
        let values = {}
        values.tipo_monetario = tipo_monetario
        values.es_aumento = es_aumento
        if(value == true){
            values.monto = precio_unitario
        }
        dispatch(initializeForm(form, values));
    }
} 
const setProductoSeleccionado = producto => (dispatch) => {
    dispatch({type: constants.PRODUCTO_SELECCIONADO, producto_seleccionado: producto.id_catalogo})
}
const setDatosModal = (row) => (dispatch, getStore) => {
    dispatch({type: constants.SET_FECHA_ACTUALIZACION, fecha_actualizacion: row.fecha_actualizacion_actual})
    dispatch({type: constants.SET_PRECIO_INTERNO_ANTERIOR, precio_interno_anterior: row.precio_unitario_anterior})
}
const changeEmpresa = empresa => (dispatch, getStore) => {
    const resource = getStore().reportePrecioCliente
    dispatch({type:constants.SET_SEARCH_EMPRESA, empresa});
    if(resource.cliente.length > 0){
        dispatch(listar());
    }
    else{
        const data= {
            results: [],
            count: 0, 
        }
        dispatch({type: constants.DATA, data})
    }    
}
const changeTipo = tipo => (dispatch, getStore) => {
    const resource = getStore().reportePrecioCliente
    dispatch({type:constants.SET_SEARCH_TIPO, tipo});
    if(resource.cliente.length > 0){
        dispatch(listar());
    }
    else{
        const data= {
            results: [],
            count: 0,
        }
        dispatch({type: constants.DATA, data})
    }    
}
const changeVendedor = vendedor => dispatch => {
    dispatch({type:constants.SET_SEARCH_VENDEDOR, vendedor});
    const data= {
        results: [],
        count: 0,
    }
    dispatch({type: constants.DATA, data})
    
}
const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};
const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch(setDataSelect(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectTipos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipoproducto/selecttipos').then((res)=>{
        dispatch(setDataSelect(constants.SET_TIPOS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectVendedores = () => (dispatch, getStore) =>{
    dispatch(setLoader(true));
    const me = getStore().login.me
    const params = {}
    if(me.permisos.visualizar_mis_datos == true){
        params.id = me.id
    }
    api.get('vendedores', params).then((res)=>{
        dispatch(setDataSelect(constants.SET_VENDEDORES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectClientes = (params, vendedor=null) => (dispatch, getStore) =>{
    const resource = getStore().reportePrecioCliente
    dispatch(setLoader(true));
    let vendedores_ids = []
    if(resource.vendedor){
        resource.vendedor.forEach(vendedor =>{
            vendedores_ids.push(vendedor.value)
        })
        params.vendedor = vendedores_ids
    }
    api.get('clientes/clientes_todos_vendedor', params).then((res)=>{
        dispatch(setDataSelect(constants.SET_CLIENTES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const selectProductos = (cliente=null) => (dispatch, getStore) =>{
    const resource = getStore().reportePrecioCliente
    dispatch(setLoader(true));
    const params = {}
    let clientes_ids = []
    if(resource.cliente){
        resource.cliente.forEach(cliente =>{
            clientes_ids.push(cliente.id)
        })
        params.cliente = clientes_ids
    }
    if(cliente != null){
        api.get('productos/select_productos_cliente', params).then((res)=>{
            dispatch(setDataSelect(constants.SET_PRODUCTOS, res))
        }).catch(()=>{
        }).finally(()=>{
            dispatch(setLoader(false))
        })
    }
    else{
        dispatch(setDataSelect(constants.SET_PRODUCTOS, null))
        dispatch(setLoader(false))
    }
}
const setProductosSeleccionados = producto => (dispatch, getStore) => {
    const seleccionar_todos = getStore().reportePrecioCliente.seleccionar_todos
    let productos = getStore().reportePrecioCliente.productos_seleccionados
    let productos_desmarcados = getStore().reportePrecioCliente.productos_desmarcados
    let productos_listados = getStore().reportePrecioCliente.data
    let existe = false
    productos_listados.results.forEach(prod =>{
        if(producto.id_catalogo == prod.id_catalogo){
            prod.check = !prod.check
        }
    })
    if(productos.length == 0){
        productos.push(producto)
    }
    else{
        productos.forEach((prod, index) =>{
            if(producto.id_catalogo == prod.id_catalogo){
                productos.splice(index, 1)
                existe = true
            }
        })
        if(existe == false){
            productos.push(producto)
        }
    }
    if(seleccionar_todos){
        if(productos_desmarcados.length == 0){
            productos_desmarcados.push(producto)
        }
        else{
            productos_desmarcados.forEach((prod, index) =>{
                if(producto.id_catalogo == prod.id_catalogo){
                    productos_desmarcados.splice(index, 1)
                    existe = true
                }
            })
            if(existe == false){
                productos_desmarcados.push(producto)
            }
        }
    }
    dispatch(setData(productos_listados));
    dispatch({type: constants.PRODUCTOS_SELECCIONADOS, data: productos})
}
const setSeleccionarTodos = () => (dispatch, getStore) => {
    let productos_listados = getStore().reportePrecioCliente.data
    let value = getStore().reportePrecioCliente.seleccionar_todos
    value = !value
    dispatch({type: constants.SET_SELECCIONAR_TODOS, seleccionar_todos: value})
    productos_listados.results.forEach(prod =>{
        prod.check = value
    })
    dispatch({type: constants.SET_PRODUCTOS_DESMARCADOS, data: []})
    dispatch({type: constants.PRODUCTOS_SELECCIONADOS, data: []})
    dispatch(setData(productos_listados));
}

export const actions = {
    listar,
    changeCliente,
    changeProducto,
    guardarCambio,
    setOpenModal,
    setProductoSeleccionado,
    setProductosSeleccionados,
    setSeleccionarTodos,
    setDatosModal,
    changeEmpresa,
    changeTipo,
    selectEmpresas,
    selectTipos,
    changeVendedor,
    selectVendedores,
    selectClientes,
    selectProductos,
    searchChange
}

export const reducers = {
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) =>{
        return{
            ...state,
            data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SET_PRODUCTO]: (state, { producto }) => {
        return {
            ...state,
            producto
        };
    },
    [constants.SET_CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente
        };
    },
    [constants.SET_OPEN_MODAL]: (state, {open_modal}) => {
        return {
            ...state,
            open_modal
        }
    },
    [constants.SET_FECHA_ACTUALIZACION]: (state, {fecha_actualizacion}) => {
        return {
            ...state,
            fecha_actualizacion
        }
    },
    [constants.SET_PRECIO_INTERNO_ANTERIOR]: (state, {precio_interno_anterior}) => {
        return {
            ...state,
            precio_interno_anterior
        }
    },
    [constants.PRODUCTO_SELECCIONADO]: (state, {producto_seleccionado}) => {
        return {
            ...state,
            producto_seleccionado
        }
    },
    [constants.SET_SEARCH_EMPRESA]: (state, {empresa}) => {
        return {
            ...state,
            empresa
        }
    },
    [constants.SET_SEARCH_TIPO]: (state, {tipo}) => {
        return {
            ...state,
            tipo
        }
    },
    [constants.SET_SEARCH_VENDEDOR]: (state, {vendedor}) => {
        return {
            ...state,
            vendedor
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_TIPOS]:(state,{ data })=>{
        return{
            ...state,
            tipos: data.tipo,
        }
    },
    [constants.SET_VENDEDORES]:(state,{ data })=>{
        return{
            ...state,
            vendedores: data.results,
        }
    },
    [constants.SET_CLIENTES]:(state,{ data })=>{
        return{
            ...state,
            clientes: data,
        }
    },
    [constants.SET_PRODUCTOS]:(state,{ data })=>{
        return{
            ...state,
            productos: data,
        }
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.PRODUCTOS_SELECCIONADOS]: (state, {data}) => {
        return {
            ...state,
            productos_seleccionados: data
        }
    },
    [constants.SET_PRODUCTOS_DESMARCADOS]: (state, {data}) => {
        return {
            ...state,
            productos_desmarcados: data
        }
    },
    [constants.SET_SELECCIONAR_TODOS]: (state, {seleccionar_todos}) => {
        return {
            ...state,
            seleccionar_todos
        }
    }
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    producto: undefined,
    cliente: [],
    producto_seleccionado: null,
    productos_seleccionados: [],
    seleccionar_todos: false,
    productos_desmarcados: [],
    open_modal: false,
    fecha_actualizacion: null,
    precio_interno_anterior: null,
    empresa: null,
    tipo: null,
    empresas:[],
    tipos: [],
    vendedores: [],
    vendedor: [],
    clientes: [],
    productos: [],
    search: ''
}

export default handleActions(reducers, initialState);
