import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/liquidaciones/liquidaciones';
import liquidacionesGrid from './liquidacionesGrid';

//state.login.me.profile.role
const mstp = state => {
    return {
        ...state.liquidaciones,
        permisos: state.login.me.permisos,
        meProfile: state.login.me.profile
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(liquidacionesGrid)
