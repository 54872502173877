import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { renderField, renderTextArea, renderSelectField, renderCurrency, AsyncSelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import CardSimple from '../../../Utils/Cards/cardSimple';

import LoadMask from "Utils/LoadMask/LoadMask";



class ItemArray extends Component {
    state = {
        producto: {},
        sel_producto: {}
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.textInput = React.createRef();
        this.state = {
            producto: {}
          }
      }
    componentDidUpdate(prevProps, prevState){
        if(this.props.prod_seleccionado !== prevProps.prod_seleccionado){
            const { todos, index } = this.props;
            if( this.props.prod_seleccionado)
                this.setState({producto: this.props.prod_seleccionado.producto});
        }
    }

    render(){
        const {index,productos, producto, simbolo, eliminar, subtotal, proveedor} = this.props;
        return (
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${producto}-${simbolo}-${proveedor}`}
                        ref={this.textInput}
                        id={`${producto}.nombre_producto`}
                        name={`${producto}.nombre_producto`}
                        component={renderSelectField}
                        options={productos}
                        top ={{top:"67px", position:"inherit"}}
                        type="text"
                        valueKey="nombre_producto"
                        labelKey="nombre_producto"
                        onChange={(e) => {
                            this.setState({producto: e});
                        }}
                        className="form-control" />
                        {
                            (this.state.producto && this.state.producto.menor_precio) && (
                                <div className="speech-bubble mt-1 p-2">
                                   <span> {this.state.producto.menor_precio}</span>
                                </div>
                            )
                        }

                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${producto}.cantidad`}
                        type="number"
                        component={renderField}
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
            </tr>
        );
    }
}


const renderProductos = ({fields, meta: {error, submitFailed }, simbolo, reRender, productos, proveedor}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Producto</th>
                        <th style={{width: "20%"}}>Cantidad</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((producto, index) => {
                        return (
                            <ItemArray
                                proveedor={proveedor}
                                index={index}
                                key={index}
                                producto={producto}
                                productos = { productos }
                                simbolo={simbolo}
                                eliminar={ () =>{
                                    fields.remove(index)
                                }}
                                field={fields ? fields.get(index) : null}
                            />
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR PRODUCTO
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}
    </div>
)


class DetalleProductos extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    handleSubmit = (data) => {
        console.log(data);
    }

    render() {
        const { itemOC, loader, listar: onPageChange, handleSubmit} = this.props;
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                <div className="row">
                    <div className="mb-4 col-md-10 offset-md-1 col-12 justify-content-center">
                        <div className="mb-4 col-12">
                            <div className="p-0 px-3 pt-3 p-b">
                                <form name="itinerarioFormProductos" className="form-validate mb-lg " onSubmit={handleSubmit}>
                                    <FieldArray
                                        productos={itemOC.detalle_orden}
                                        name="detalle_orden"
                                        component={renderProductos}
                                    />
                                    <br/>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                                                <button className="btn btn-secondary  align-self-center" onClick={this.props.cancelar} >CANCELAR</button>
                                                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                                            </div>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </LoadMask>
        )
    }
}


DetalleProductos = reduxForm({
    form: 'itinerarioFormProductos', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {};
        if ((!data.itinerario_detalle_clientes || !data.itinerario_detalle_clientes.length) &&
            (!data.itinerario_detalle_proveedores || !data.itinerario_detalle_proveedores.length)) {
            errors.itinerario_detalle_proveedores = { _error: 'Debe de ingresar al menos un Registro' }
        } else {
            if (data.itinerario_detalle_clientes && data.itinerario_detalle_clientes.length) {
                const detalleArray = []
                data.itinerario_detalle_clientes.forEach((campo, index) => {
                    let detErrors = {};
                    let repetidos = [];
                    if (!campo.clienteProveedor) {
                        detErrors.clienteProveedor = "Campo requerido"
                    }
                    if (!campo.sucursal) {
                        detErrors.sucursal = "Campo requerido"
                    }
                    if (campo.despacho) {
                        repetidos = _.filter(data.itinerario_detalle_clientes, (x) => {
                            if (x.despacho)
                                return x.despacho.id == campo.despacho.id
                            return false;
                        });
                    }
                    if (!campo.despacho) {
                        detErrors.despacho = "Campo requerido"
                    } else {
                        if (1 < repetidos.length) {
                            detErrors.despacho = "Este despacho está repetido"
                        }
                    }
                    if (detErrors) {
                        detalleArray[index] = detErrors;
                    }
                });
                if (detalleArray.length) {
                    errors.itinerario_detalle_clientes = detalleArray
                }
            }
        }
        return errors;
    },
})(DetalleProductos);


export default DetalleProductos;