import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';

const TipoProductoForm = (props) => {
    const { handleSubmit} = props;
    return (
        <form name="TipoProductoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>

            </div>
            <div className="row  justify-content-sm-around justify-content-center">
                        <Link className="btn btn-secondary  align-self-center" to="/tipoproductos" >CANCELAR</Link>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'TipoProductoForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
        });
    },
})(TipoProductoForm);
