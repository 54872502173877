import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset, change} from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';
import { getMunicipios } from '../../../utility/constants';


let url = 'despachosplanta' ;
let url_factura = 'factura' ;
let form = 'DespachosPlantaForm';
let dirGrid = '/despachosplanta';

export const constants ={
    LOADER: 'DESPACHOSPLANTA_LOADER',
    DATA: 'DESPACHOSPLANTA_DATA',
    ITEM: `DESPACHOSPLANTA_ITEM`,
    PAGE: `DESPACHOSPLANTA_PAGE`,
    ORDERING: `DESPACHOSPLANTA_ORDERING`,
    SEARCH: `DESPACHOSPLANTA_SEARCH`,
    SET_MODAL: 'SET_MODAL_DESPACHOSPLANTA',
    SET_MODAL_HISTORIAL: 'SET_MODAL_FACTURAS_HISTORIAL',
    SET_MUNICIPIOS: 'SET_MUNICIPIOS',
    LOADER_FORMULARIO: 'DESPACHOSPLANTA_LOADER_FORMULARIO',
    SET_MUNICIPIOS_BANCOS: 'SET_MUNICIPIOS_BANCOS',
    OPEN_MODAL: 'OPEN_MODAL_ANULAR_FACTURA',
    OPEN_MODAL_HISTORIAL: 'OPEN_MODAL_HISTORIAL_FACTURAS',
    TOTAL_ORDEN: "TOTAL_ORDEN"
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().despachosplanta;
    const me = getStore().login.me
    const params = { page };
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.cliente__vendedor_id = me.id
    }
    //params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const crear = data => (dispatch, getStore) => {

    let store = getStore().despachosplanta;
    let detalle = Object.values(store.data)

    data.despachos_Detalles = [];

    if(data.despachosDetalles){
        for(let i = 0; i < data.despachosDetalles.length ;i++){
            data.despachos_Detalles.push(data.despachosDetalles[i].despacho);
        }
    }
    dispatch(setLoader(true));
    api.post(`${url}`, data).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERRIR1', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const BillDespachoPlanta = (params, bandera)=> (dispatch, getStore ) => {
    //params.despacho_id = datos.despacho.id
    //params.fecha = moment(datos.fecha).format('YYYY-MM-DDThh:mm');
    const despachos = getStore().form.DespachoPlantaForm ? getStore().form.DespachoPlantaForm.values.despachosDetalles : getStore().despachosplanta.item.despachoplanta_detalle
    dispatch(setLoader(true));


    // Excluir identificacion si es Extranjero
    let error_ext = false;
    despachos[0].despacho.orden_detalle.proveedor.empresa.map(empresa =>{
        if (despachos[0].despacho.orden_detalle.nit_facturacion == empresa.Nit && empresa.tipo == 'EXT') {
            error_ext = true;
        }
    })
    if (error_ext) {
        NotificationManager.error('No se permite la facturación con EXT', 'Error', 5000);
        dispatch(setLoader(false));
        return ;
    }


    api.post(`${url}/billdespachoplanta`, params).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(verifyBil(res.idFactura, params.id, bandera))

        if (despachos) {
            despachos.forEach(despacho =>{
                if(params.idDespacho == despacho.despacho.id){
                    despacho.despacho.facturas_despacho.push(res.idFactura)
                }
            })
        }
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR1', 3000);
        dispatch(setLoader(false));
    })
}

const verifyBil = (id, ov, bandera) => (dispatch) => {
    const params={id}
    api.get(`${url}/verify_bill`, params).then((res)=>{
        if (res.estado == "0" || res.estado == 0){
            setTimeout(() => {
                dispatch(verifyBil(id, ov))
            }, 500);
        }else{
            if ((res.estado == "1" || res.estado == 1) || (res.estado == "4" || res.estado == 4)){
                NotificationManager.success('Factura timbrada', 'Éxito', 3000);
                dispatch(setData(constants.DATA, ov));

                if (bandera){
                    bandera()
                }
            }else{
                NotificationManager.error(res.mensaje, 'ERROR', 3000);
            }
            dispatch(setLoader(false))
        }
    }).catch(()=>{
        dispatch(setLoader(false))
    })
}

const getDataFormTipoIdentificacion = () => (dispatch, getStore) => {
    const form = getStore().form.FacturaForm.values;
    const tipo = form.tipo;
    return tipo;
}

const getDataFormIdentificacion = () => (dispatch, getStore) => {
    const form = getStore().form.FacturaForm.values;
    const identificacion = form.nit;
    return identificacion;
}

const leerDespachoPlanta = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/getDetalleDespachoPlanta/${id}`).then((response) => {

        dispatch({type: constants.ITEM, item: {} });
        dispatch({type: constants.ITEM, item: response});
        dispatch({
            //type: constants.SUBMIT_PRODUCTOS,
            //detalle
        })
        if (!!form)
            dispatch(initializeForm(form, response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const PDFDESPACHO = ( id ) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('itinerario/imprimibleitinerario', {id}).then((res)=>{
       if (res.detail){
           console.log(res.detail)
           window.open(res.detail.archivo, '_blank');
            // return <a target="_blank" href={res.detail.archivo}
            // className="btn btn-primary align-self-center"
            // />
       }
    }).catch((error)=>{
        mensajeError(error)
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}


const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL,
        open_modal: open_modal
    })
}

const validarNitEP = () => (dispatch, getStore) => {
    const form = getStore().form.FacturaForm.values
    const nit = form.nit;
    const params = { nit };

    api.get(`clientes/validateNit`, params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        form.nombre = response.detail.nombre;
        dispatch(initializeForm('ClienteForm', form))
    }).finally( );
};

const obtenerTipoIdentificacion = () => (dispatch, getStore) => {
    const form = getStore().form.FacturaForm.values
    const tipo_doc = form.tipo;
    return tipo_doc;
}

const validarCuiEP = () => (dispatch, getState) => {
    const form = getState().form.FacturaForm.values
    const URL_GET_CUI = "get-valid-cui";
    const cui = form.nit;
    let nameNew = "";

    const fetchData = async () => {
        try {
            const data = await api.post(URL_GET_CUI, {cui});
            nameNew = data.first_name + " " + data.last_name;
            form.nombre = nameNew;
            await dispatch(initializeForm("FacturasForm", form));

        } catch (error) {
            nameNew = "invalido";
            form.nombre = nameNew;
            await dispatch(initializeForm("FacturasForm", form));
        }
    };
    fetchData();
};

const setOpenModalFacturasHistorial = (open_modal_historial) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_HISTORIAL,
        open_modal_historial: open_modal_historial
    })
}

const resetFormulario = () => (dispatch) => {
    dispatch(initializeForm(form, {
        fecha_despacho: new Date()
    }))
}


const setMunicipios = (val, set= 1) => (dispatch) =>{
    if (set == 1) {
        let municipios = getMunicipios(val)
        dispatch({type: constants.SET_MUNICIPIOS, data:municipios})
    }else
        dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:val})

}

const setAutomaticoReceptor = (info) => (dispatch, getStore) =>{
    const resource = getStore().form.FacturaForm;
    const formulario = resource.values;
    console.log(info, "<<<<<<<<<<<<<<<<<<<<<<<<<")
    if (info.nombre) {
        formulario.nombre = info.nombre;
        formulario.tipo = info.tipo || "NIT";
        formulario.nit = info.propietario.Nit.replace("-", "");
    }else{
        if (info.despacho.cliente_orden){
            let existe = 0
            info.despacho.orden_detalle.proveedor.empresa.forEach(empresa =>{
                if(info.despacho.orden_detalle.nit_facturacion==empresa.Nit){
                    formulario.nombre = empresa.nombre;
                    formulario.tipo = empresa.tipo;
                    existe = 1
                }
                if(existe==0){
                    if(empresa.Nit=="CF" | empresa.Nit =="C/F"){
                        formulario.nombre = empresa.nombre
                    }
                }
            })
            if (info.datoNit != null){
                formulario.nit = info.datoNit.replace("CF", "CF");
            }else{
                formulario.nit = "CF"
            }
        }
    }
    dispatch(initializeForm('FacturaForm', formulario));
}
const setCodigoPostal = (municipio) => (dispatch, getStore) =>{
    const resource = getStore().despachosplanta;
    const codigo =  resource.municipios.find((item) => {
            if (item.value == municipio)
                return item.codigo
        })
    dispatch(change('FacturaForm','codigo_postal',codigo.codigo.toString()))
}

const anularFacturaEP = (data, idDespachoPlanta) => (dispatch) => {
    dispatch(setLoader(true));

    api.post(`${url_factura}/anular_factura`, data).then(() => {
        NotificationManager.success('Factura Anulada', 'Éxito', 3000);

    }).catch((error) => {
        let mensaje = "Error en la anulación";
        if(error && error.detail) {
            mensaje = error.detail
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(leerDespachoPlanta(idDespachoPlanta))

        dispatch(setOpenModal(false))
        dispatch(setLoader(false));
    });
};

const fetchTotalOrden = (orden) => (dispatch, getStore) => {
    const fetchTotalOrden2 = async () => {
        try{
            const data = await api.get("ordenventa/productosporventa", {
                orden: orden,
                bandera: 1
            })
            if (data){
                let _total = 0
                forEach(data, (producto) => {
                    _total += producto.subtotal
                })
                console.log(_total, "TOTAL")
                await dispatch({
                    type: constants.TOTAL_ORDEN,
                    total_orden: _total
                })
            }
            await dispatch({
                type: constants.TOTAL_ORDEN,
                total_orden: 0
            })
        }
        catch (error) {
            console.log("ALVAaSDASDSda", error)
            await dispatch({
                type: constants.TOTAL_ORDEN,
                total_orden: 0
            })
        }
    }
    fetchTotalOrden2()
}

export const actions = {
    listar,
    crear,
    BillDespachoPlanta,
    leerDespachoPlanta,
    getDataFormTipoIdentificacion,
    getDataFormIdentificacion,
    PDFDESPACHO,
    searchChange,
    onSortChange,
    validarCuiEP,
    obtenerTipoIdentificacion,
    validarNitEP,
    setOpenModal,
    setOpenModalFacturasHistorial,
    resetFormulario,
    setMunicipios,
    setLoader,
    setAutomaticoReceptor,
    setCodigoPostal,
    fetchTotalOrden,
    anularFacturaEP
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.SET_MODAL_HISTORIAL]: (state, { open_modal_historial }) => {
        return {
            ...state,
            open_modal_historial,
        };
    },
    [constants.SET_MUNICIPIOS]:(state,{ data })=>{
        return{
            ...state,
            municipios: data,
        }
    },
    [constants.SET_MUNICIPIOS_BANCOS]:(state,{ data })=>{
        return{
            ...state,
            municipios_bancos: data,
        }
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.TOTAL_ORDEN]: (state, { total_orden }) => {
        return {
            ...state,
            total_orden,
        };
    }
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    open_modal: false,
    open_modal_historial: false,
    loader_formulario: false,
    municipios:[],
    total_orden: 0
};

export default handleActions(reducers, initialState);
