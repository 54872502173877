import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { Monedas } from "../../../../../utility/constants";
import { withRouter } from 'react-router';
import { customStandardActions, standardActions } from "../../../Utils/Grid/StandardActions";
import Grid from '../../../Utils/Grid/index';
import CardsCaja from '../../../Utils/Cards/cardsCaja';
import Modal from 'react-responsive-modal';
import ToolbarCaja from '../../../Utils/Toolbar/ToolbarCaja';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ImageVer from '../../../../../../assets/img/icons/Ver.png';

function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}

class Micaja extends Component {
    state = {
        id_transaccion: 0,
        transaccion_sel: null,
        anular_transaccion: false,
        open_modal_retiro: false,
        archivo: null,
        open_modal_cuenta: false
    }
    getsimbolo = (valor) => {
        let simbolo = ''
        Monedas.forEach((item, index) => {
            if (item.value === valor) {
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    aceptarEnvio = (id) => {
        this.props.AcceptMovimiento(id, this.props.idcaja)
    }
    rechazarEnvio = (id) => {
        this.props.RefuseMovimiento(id, this.props.idcaja)
    }
    cancelEnvio = (id) => {
        this.props.CancelMovimiento(id, this.props.idcaja)
    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModal(true);
    }
    botonModal = (id, row) => {

        if (row.estado) {
            return (<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../assets/img/icons/Ver.png")}
            onClick={(e) => {
                e.preventDefault();
                this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    anular_transaccion: true
                })
                this.props.setModalDescripcion(true);
            }}
            alt="Ver eliminado" />)
    }
    renderModalAnulacion = () => {
        const { open_modal } = this.props;
        return (
            <Modal open={open_modal} onClose={(e) => {
                this.props.setModalDescripcion(false)
            }} center>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>
                {
                    (this.state.transaccion_sel) && (
                        <div className="form-validate mb-lg text-center" >
                            <span className="m-none t-mostaza semibold">Fecha anulación: </span><br />
                            <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br />
                            <span className="m-none t-mostaza semibold">Usuario anulación: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br />
                            <span className="m-none t-mostaza semibold">descripción: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br />
                        </div>
                    )
                }


            </Modal>

        )
    }
    render() {
        let { data, item, loader, entidad, dateStart, dateEnd, setDateStart, setDateEnd, valorMovimiento, changeMovimiento, total_debe, total_haber } = this.props
        return (
            <div>
                <div className="py-md-3 px-md-3 p-0" style={{border:"1px solid #E8E8E8", }}>
                    <ToolbarCaja
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por Descripcion"}
                        estatefilterBar={true}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        setDateStart={setDateStart}
                        setDateEnd={setDateEnd}
                        valorMovimiento={valorMovimiento}
                        changeMovimiento={changeMovimiento}
                        />
               </div>
                {this.renderModalAnulacion()}
                {
                    (entidad === item.origen) &&
                    (
                        <div>
                            <CardsCaja
                                data={this.props.pendientes}
                                loader={loader}
                                aceptarEnvio={this.aceptarEnvio}
                                rechazarEnvio={this.rechazarEnvio}
                                cancelEnvio={this.cancelEnvio}
                                titulo={"TRANSFERENCIAS PENDIENTES"}                               
                            />
                            <div className="row">
                                <div className="col-lg-6 col-md-6 row pl-0 ml-0 mb-4 ml-md-1 justify-content-center">
                                    <div className="col-md-auto mt-3">
                                        <span className="t-musgo font-weight-bold">SALDO INICIAL</span>

                                        <h4 className="t-mostaza m-none">{<RenderMoneda monto={item.saldo_inicial} simbolo={this.getsimbolo(item.moneda)} />}</h4>
                                    </div>
                                    <div className="col-md-auto mt-3">

                                        <span className="t-musgo font-weight-bold">
                                            SALDO {(false ? 'FINAL' : 'ACTUAL')}
                                        </span>
                                        <h2 className="t-azul m-none">{<RenderMoneda monto={item.saldo_actual} simbolo={this.getsimbolo(item.moneda)} />}</h2>
                                    </div>
                                </div>
                                <br />


                                <div className="row col-lg-6 col-md-6 m-0 p-0 d-flex justify-content-md-end justify-content-center">
                                    <div className="col-12 m-0 p-0 row d-flex justify-content-sm-end justify-content-center">
                                        {
                                            (this.props.permisos["retiro_caja"] === true) && (
                                                <button type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.props.setOpenModalRetiro(true);
                                                    }}
                                                    className="btn btn-t btn-mostaza m-l mt-3">RETIRO</button>
                                            )
                                        }
                                        <button type="button"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.props.setOpenModal(true);
                                            }}
                                            className="btn btn-t btn-primary m-l mt-3">TRANSFERIR</button>
                                        <button type="button"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.props.setOpenModalCierre(true);
                                            }}
                                            className="btn btn-azul-claro m-l mt-3">CERRAR</button>
                                    </div>
                                </div>
                                <br />
                                <div className="col-lg-7 col-md-7 row pl-0 ml-0 mb-4 ml-md-1 justify-content-center">
                                </div>
                                <br />
                                <div className="col-md-auto">
                                    <span className="t-musgo font-weight-bold">
                                        TOTAL DEBE
                                    </span>
                                    <h4 className="t-azul m-none">{ <RenderMoneda monto={total_debe} simbolo={this.getsimbolo(item.moneda)} />}</h4>
                                </div>
                                <div className="col-md-auto">
                                        <span className="t-musgo font-weight-bold">
                                            TOTAL HABER
                                        </span>
                                        <h4 className="t-primary m-none">{ <RenderMoneda monto={total_haber} simbolo={this.getsimbolo(item.moneda)} />}</h4>
                                </div>
                            </div>
                        </div>
                    )
                }
                <br />
                <Grid hover striped data={{ count: 10, results: data }} loading={loader} pagination={false}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        width="5%"
                        dataFormat={
                            standardActions({
                                adicional: this.botonModal
                            })
                        }

                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="liquidacion"
                        dataAlign="center"
                        dataSort
                        width="5%"
                        dataFormat={(cell, row) => {
                            return customStandardActions(cell, {
                                adicional: (c, r) => {
                                    return <Link to={`/caja/${row.cuenta}/liquidacion/${cell}`} >
                                    <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                                </Link>
                                }
                            });
                        }}
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={
                            (cell, row) => (
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey
                        tdStyle={cellTachado}
                        dataField="transaccion"
                        dataSort
                        width="25%"
                        dataFormat={(cell, row) => (
                            cell ? (
                                <div>
                                    {
                                        cell.nombre_tipo_transaccion && (
                                            <Fragment>
                                                <span className="font-weight-bold">Tipo de Movimiento: </span>
                                                <span style={{ color: "black" }} >{cell.nombre_tipo_transaccion}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        cell.label_tipo_documento && (
                                            <Fragment>
                                                <span className="font-weight-bold">Tipo de Transacción: </span>
                                                <span style={{ color: "black" }} >{cell.label_tipo_documento}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        cell.no_documento && (
                                            <Fragment>
                                                <span className="font-weight-bold">No. Documento: </span>
                                                <span style={{ color: "black" }} >{cell.no_documento}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        cell.nombre_tipo_gasto && (
                                            <Fragment>
                                                <span className="font-weight-bold">Tipo de Gasto: </span>
                                                <span style={{ color: "black" }} >{cell.nombre_tipo_gasto}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        cell.nombre_proveedor && (
                                            <Fragment>
                                                <span className="font-weight-bold">Proveedor: </span>
                                                <span style={{ color: "black" }} >{cell.nombre_proveedor}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        row.emitido_por &&(
                                            <Fragment>
                                                <span className="font-weight-bold">Enviada por: </span>
                                                <span style={{ color: "black" }} >{row.emitido_por}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        row.aceptado_por &&(
                                            <Fragment>
                                                <span className="font-weight-bold">Aceptada por: </span>
                                                <span style={{ color: "black" }} >{row.aceptado_por}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        row.pago_a &&(
                                            <Fragment>
                                                <span className="font-weight-bold">Pago a: </span>
                                                <span style={{ color: "black" }} >{row.pago_a}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    <span className="font-weight-bold">Descripcion: </span>
                                    <span className="t-musgo ">{cell.descripcion}</span><br />
                                </div>
                            ) : "NO HAY INFORMACIÓN"
                        )}
                    >
                        DESCRIPCIÓN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="es_ingreso"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={
                            (cell, row) => (
                                // <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                                cell ? 'Ingreso' : 'Egreso'
                            )
                        }
                    >
                        MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="debe"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={
                            (cell, row) => (
                                <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        DEBE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="haber"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={
                            (cell, row) => (
                                <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        HABER
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="saldo"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={
                            (cell, row) => (
                                <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        SALDO
                    </TableHeaderColumn>
                </Grid>
                <br />

            </div>
        )
    }
}

export default withRouter(Micaja);
