import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';


import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';

import FlotanteInverso from './fl_inverso';
import { Link } from 'react-router-dom';

import { renderCurrency, renderTextArea, renderFieldRadio, renderField, renderDropDownField } from '../../../Utils/renderField';


import connected from 'rc-menu/lib/MenuItem';
import {getSimbolo} from "Utils/renderField/renderReadField";


 class AsignarTarjeta extends Component {
    render() {
        const { ver_nuevo, handleSubmit, onSubmit } = this.props;
        return (
            <form name="ordencompraForm" className="form-validate mb-lg " onSubmit={handleSubmit}>



                                <FlotanteInverso
                                    onSubmit={onSubmit}
                                    {...this.props}/>


            </form>

        )
    }
}

AsignarTarjeta = reduxForm({
    form: 'AsignarTarjetaForm',
    validate: (data, props) => {
        const monto_tarjetas = props.monto_tarjeta_seleccionada;
        const errors = {};

        if(!data.no_documento){
            errors.no_documento = "Campo requerido.";
        }
        if(!data.monto){
            errors.monto = "Campo requerido.";
        }else{
            if(Number(data.monto) < 1){
                errors.monto = "Debe ser mayor de 0";
            }
            if(monto_tarjetas < Number(data.monto)){
                errors.monto = `Debe de ingresar una cantidad menor de Q.${monto_tarjetas}`;
            }
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido.";
        }

        return errors;
    }
})(AsignarTarjeta);

const selector = formValueSelector('AsignarTarjetaForm');
AsignarTarjeta = connect((state, props) => {
    const opcion_asginacion = selector(state, 'opcion_asignacion');
    let ver_nuevo = true;

    if(opcion_asginacion == 'false'){
        ver_nuevo = false;
    }
    return {
        ver_nuevo
    }
})(AsignarTarjeta);

export default AsignarTarjeta;
