import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';


export default class ToolbarEmpresas extends Component {
    componentWillMount(){
    }
    format = (array=[]) =>{
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }
    render() {
        const {
            changeEmpresa,
            empresas,
            changeTipo,
            tipos
        } = this.props;
        return (
            <div className="row col-12 p-0 m-0">
                <div className="col-6 p-0 m-0 ">
                    <Select
                        isClearable={true}
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={true}
                        isSearchable={true}
                        options={this.format(empresas? empresas:[])}
                        placeholder={"Empresa"}
                        // value={null}
                        onChange={(e, action)=>{console.log(e, action); changeEmpresa(e)}}
                    />
                </div>
                <div className="col-6 p-0 m-0 ">
                    <Select
                        isClearable={true}
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={true}
                        isSearchable={true}
                        options={this.format(tipos? tipos:[])}
                        placeholder={"Tipo"}
                        // value={null}
                        onChange={(e, action)=>{console.log(e, action); changeTipo(e)}}
                    />
                </div>
            </div>
        )
    }
}