import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { RenderDateTimeAll, RenderDateTime, RenderMoneda, getSimbolo } from "../../../Utils/renderField/renderTableField";

import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import DesgloseGastos from './desglose';

import LoadMask from "Utils/LoadMask/LoadMask";
import GridClientesProveedores from './gridClientesProveedores';


export default class DetalleItinerario extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        //this.props.clear()
        let id = this.props.match.params.id
        this.props.leerItinerario(id);
    }

    cerrar = (event) => {
        this.props.cerrar(event.target.value);
    }

    render() {
        const { item, data, loader, listar: onPageChange, onSortChange, eliminar, mostrarVista, permisos, cuentas, tipo_gastos } = this.props;
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>

                <div className="Container bg-white">
                    {!!item && item.vehiculo && (
                        <div className="m-t p-t fnt-size">
                            <div className="row m-none" style={{ paddingTop: "15px" }}>
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-primary m-none"> {item ? `No. ${item.id}` : ""}</h3>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Estado: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.cerrado ? "Cerrado" : "Abierto"}`}</span>
                                </div>
                            </div>
                            <br />

                            <div className="col-12">
                                <span className="m-none t-primary bold"> Vehiculo </span>
                            </div>
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Placa: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.vehiculo.placa}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Modelo: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.vehiculo.modelo}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Marca: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.vehiculo.marca}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">kilometraje inicial: </span> <br />
                                    <span className="m-none t-musgo semibold">{item.km_inicial ? `${item.km_inicial} km` : ''}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">kilometraje final: </span> <br />
                                    <span className="m-none t-musgo semibold">{item.km_final ? `${item.km_final} km` : ""}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">km Recorrido: </span> <br />
                                    <span className="m-none t-musgo semibold">{item.km_inicial && item.km_final ? `${item.km_final - item.km_inicial} km` : ""}</span>
                                </div>
                            </div>
                            <br />
                            <div className="col-12">
                                <span className="m-none t-primary bold"> Piloto </span>
                            </div>
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Nombre: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.piloto.nombre}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Telefono: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.piloto.profile.phone}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Dirección: </span> <br />
                                    <span className="m-none t-musgo semibold">{`${item.piloto.profile.address}`}</span>
                                </div>
                            </div>
                            <br />
                            {
                                (item.ayudante) && (
                                    <div>
                                        <div className="col-12">
                                            <span className="m-none t-primary bold"> Ayudante </span>
                                        </div>
                                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                                        <div className="row m-none">
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Nombre: </span> <br />
                                                <span className="m-none t-musgo semibold">{`${item.ayudante.nombre}`}</span>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Telefono: </span> <br />
                                                <span className="m-none t-musgo semibold">{`${item.ayudante.profile.phone}`}</span>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Dirección: </span> <br />
                                                <span className="m-none t-musgo semibold">{`${item.ayudante.profile.address}`}</span>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )
                            }
                            <div className="col-12">
                                <span className="m-none t-primary bold"> Detalle de Ruta </span>
                            </div>
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha de Salida: </span> <br />
                                    <span className="m-none t-musgo semibold"> <RenderDateTime fecha={item.fecha_salida} > </RenderDateTime> </span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha de Entrada: </span> <br />
                                    <span className="m-none t-musgo semibold"> <RenderDateTime fecha={item.fecha_entrada}> </RenderDateTime> </span>
                                </div>
                            </div>
                            <br />
                            {item && item.itinerario_detalle_clientes && (
                                <GridClientesProveedores
                                    {...this.props}
                                    itinerario={item.itinerario_detalle_clientes}
                                    loader={loader}
                                    page={1}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                    paraCliente={true}
                                    PDFITINERARIO={this.props.PDFITINERARIO}
                                    estado={item.estado}
                                    mostrarVista={mostrarVista}
                                />
                            )
                            }
                            <br />
                            {item && item.itinerario_detalle_proveedores && !permisos.visualizar_mis_datos &&(
                                <GridClientesProveedores
                                    itinerario={item.itinerario_detalle_proveedores}
                                    loader={loader}
                                    page={1}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                    paraCliente={false}
                                    estado={item.estado}
                                />
                            )
                            }
                            {item.desglose_gastos &&
                                <div>
                                    <br />
                                    <div className=" fnt-size">
                                        <h4 className="t-primary">Desglose de gastos</h4>
                                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                                        <div className="row m-none">
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Tipo de Pago Piloto: </span><br />
                                                <span className="m-none t-musgo semibold">
                                                    {
                                                        (item.pagoextrapiloto) ? "Extraordinario" : "Normal"
                                                    }
                                                </span>
                                            </div>
                                            {
                                                (item.pagoextrapiloto) && (
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <span className="m-none t-mostaza semibold">Nombre del pago: </span><br />
                                                        <span className="m-none t-musgo semibold">{item.pagoextrapiloto.nombre}</span>
                                                    </div>
                                                )
                                            }
                                            {
                                                (item.pagoextrapiloto) && (
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <span className="m-none t-mostaza semibold">Monto: </span><br />
                                                        <span className="m-none t-musgo semibold">
                                                            <RenderMoneda monto={item.pagoextrapiloto.monto} />
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="row m-none">
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Tipo de Pago Ayudante: </span><br />
                                                <span className="m-none t-musgo semibold">
                                                    {
                                                        (item.pagoextraayudante) ? "Extraordinario" : "Normal"
                                                    }
                                                </span>
                                            </div>
                                            {
                                                (item.pagoextraayudante) && (
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <span className="m-none t-mostaza semibold">Nombre del pago: </span><br />
                                                        <span className="m-none t-musgo semibold">{item.pagoextraayudante.nombre}</span>
                                                    </div>
                                                )
                                            }
                                            {
                                                (item.pagoextraayudante) && (
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <span className="m-none t-mostaza semibold">Monto: </span><br />
                                                        <span className="m-none t-musgo semibold">
                                                            <RenderMoneda monto={item.pagoextraayudante.monto} />
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="row m-none">
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Categoría de gasto: </span><br />
                                                <span className="m-none t-musgo semibold">{item.desglose_gastos.tipo_gasto ? item.desglose_gastos.tipo_gasto.title : null}</span>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Fecha: </span><br />
                                                <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.desglose_gastos.fecha} ></RenderDateTime></span>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <span className="m-none t-mostaza semibold">Monto: </span><br />
                                                <span className="m-none t-musgo semibold"><RenderMoneda monto={item.desglose_gastos.monto} /*simbolo={getSimbolo(this.props.moneda)} */ /></span>
                                            </div>
                                        </div>
                                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                                        <div className="row m-none">
                                            <div className="col-12">
                                                <span className="m-none t-mostaza semibold">Descripción: </span><br />
                                                <span className="m-none t-musgo semibold">{`${item.desglose_gastos.descripcion}`}</span>
                                            </div>
                                        </div>
                                        <div className="row m-none">
                                            <div className="col-12 color-header-blanco">
                                                <hr />
                                                <table className="table table-sm table-responsive-sm m-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="t-mostaza" >Empresa</th>
                                                            <th className="t-mostaza text-right" style={{ width: "20%" }}>Cantidad</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            item.desglose_gastos && item.desglose_gastos.afectados && (
                                                                <Fragment>
                                                                    {item.desglose_gastos.afectados.map((empresa, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td className="sin-borde-top" style={{ width: "48px" }}>
                                                                                    <span>{empresa.empresa_nombre}</span>
                                                                                </td>
                                                                                <td className="sin-borde-top text-right" style={{ width: "20%" }}>
                                                                                    <RenderMoneda monto={empresa.debe} /* simbolo={getsimbolo(empresa.transaccion.moneda)} */ />
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </Fragment>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className="col-md-12 m-0 p-0 mt-4 inline-headers text-center text-md-right">
                                                    <span className="t-mostaza h5 mr-3" htmlFor="descripcion">Total:  </span>
                                                    <h3 className=" t-primary" htmlFor="descripcion">{
                                                        <RenderMoneda monto={item.desglose_gastos.monto} /* simbolo={getsimbolo(item.desglose_gastos.moneda)} */ />
                                                    }</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                    }
                </div>
            </LoadMask>
        )
    }
}
