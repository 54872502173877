import React, {Component} from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '../../../Utils/Grid/index';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {Monedas} from "../../../../../utility/constants";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";


export default class EmpresaGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos["cajaschicas"] === false) {
            return <Redirect to={`/`} />
        }
        return(
            <div className="Container bg-white">
                <h3 className="t-primary m-t p-t">CAJAS CHICAS</h3>
                <div className="row justify-content-end">
                    <div className="col-md-8 col-sm-8 col-12">
                        <ToolbarSimple
                        // textBoton="AGREGAR"
                        // ruta="/cuenta/nueva"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Propietario, Banco, Cuenta "}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                                <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataSort
                                        isKey
                                        dataFormat={
                                            standardActions({ ver: "caja"})
                                        }
                                    >
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="creado"
                                        dataSort
                                        dataFormat = {
                                            (cell,row)=>(
                                                <RenderDateTime fecha={cell}></RenderDateTime>
                                            )
                                        }
                                    >
                                        FECHA APERTURA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="nombre_dueno"
                                        dataSort
                                        dataFormat={
                                            (cell,row)=> cell? (`${row.nombre_dueno} ${row.apellido_dueno}`):"-------"
                                        }
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="saldo_actual"
                                        dataSort
                                        dataFormat={
                                            (cell,row)=>(
                                                <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                                            )
                                        }
                                    >
                                        SALDO
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

