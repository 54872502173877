import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';


let estados = [
    {id: 0, value: "0", label:"Todas"},
    {id: 1, value: "1", label:"Sin ordenes de producción"},
    // {id: 2, value: "2", label:"Todos"},
]

export default class ToolbarFiltrosOV extends Component {
    componentWillMount(){
    }
    render() {
        const {
            valorEstado,
            changeEstado
        } = this.props;
        return (
            <div className=" row col-lg-12 col-12 row m-0 p-0 ">
                <div className="col-lg-12 col-12">
                    {/* <span className="t-musgo font-weight-bold">Año</span> */}
                    <Select
                        isClearable={false}
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={false}
                        isSearchable={true}
                        options={estados}
                        placeholder={"Filtro de OV"}
                        value={valorEstado}
                        onChange={(e)=>{changeEstado(e,this.props.empresa)}}
                    />
                </div>
            </div>
        )
    }
}
