import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { renderField, renderTextArea, renderSelectField, renderCurrency, AsyncSelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {  Monedas } from '../../../../../utility/constants';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";


import {api} from 'api';

const getClientes = (search) => {
    return api.get("clientes", { search, tipo: 1 }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

class ItemArray extends Component {
    state = {
        producto: {},
        sel_producto: {}
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => props.getProductos(search)
        }
        console.log("productos  ", props.productos)
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.simbolo !== prevProps.simbolo){
            if(this.textInput && this.textInput.current){
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                  })
                this.forceUpdate();
               // this.textInput.ref.props.loadOptions()
            }
        }
        if(this.props.cliente !== prevProps.cliente){
            if(this.textInput && this.textInput.current){
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                  })
                this.forceUpdate();
               // this.textInput.ref.props.loadOptions()
            }
        }
        if(this.props.prod_seleccionado !== prevProps.prod_seleccionado){
            const { todos, index } = this.props;
            if( this.props.prod_seleccionado)
                this.setState({producto: this.props.prod_seleccionado.producto});
        }
    }
    setPrecioUnitario = (producto_nuevo) => {
        const { todos, index } = this.props;
            if(producto_nuevo !== undefined && producto_nuevo.precio_ultimo !== undefined){
                if (producto_nuevo.precio_ultimo != null && producto_nuevo.precio_ultimo != undefined ){
                    todos[index]['precio_unitario'] = producto_nuevo.precio_ultimo;}
                else{
                    todos[index]['precio_unitario'] = 0;
                }
            }

    }
    render(){
        const {index, producto, simbolo, eliminar, empresa, subtotal, cliente, prod_seleccionado, editar} = this.props;
        return (
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        forwardRef
                        //withRef
                        key={`${producto}-${cliente}-${empresa}`}
                        ref={this.textInput}
                        id={`${producto}.selectProducto`}
                        name={`${producto}.producto`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top ={{top:"67px", position:"inherit"}}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            this.setState({producto: e});
                            this.setPrecioUnitario(e);
                        }}
                        className="form-control" />
                        {
                            (this.props.productos && this.props.productos[index].tipo_devolucion ) && (
                                <div className="speech-bubble mt-1 p-2">
                                    <span>
                                        {this.props.productos[index].tipo_devolucion == '20' ?
                                            'Se realizará un cargo al cliente'
                                            : this.props.productos[index].tipo_devolucion == '30' ? 'Se generará un credito al cliente'
                                            : 'No se realizará ningun cargo al cliente'
                                        }
                                    </span>
                                </div>
                            )
                        }
                </td>
                {/* <td className="sin-borde-top">
                    <Field
                        name={`${producto}.tipo_devolucion`}
                        labelKey={`label`}
                        valueKey="value"
                        component={renderSelectField}
                        options={[
                            {value: 10, label:'Garantía'},
                            {value: 20, label:'Reparación'},
                            {value: 30, label:'Devolución directa'},
                            {value: 40, label:'Cambio de mercancía'},
                        ]}
                    />
                </td> */}
                <td className="sin-borde-top">
                    <Field
                        name={`${producto}.cantidad`}
                        type="number"
                        component={renderField}
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
                {/* {
                    (editar) && (
                        <Fragment>
                            <td className="sin-borde-topzz">
                                <Field
                                    name={`${producto}.precio_unitario`}
                                    label="tipo_cambio"
                                    prefix={'Q.'}
                                    component={renderCurrency}
                                    parse={cell => parseFloat(cell)}
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </td>
                            <td className="sin-borde-top"  style={{width: "10%"}}>
                                <RenderMoneda monto={subtotal} simbolo={simbolo} />
                            </td>
                        </Fragment>
                    )
                } */}

            </tr>
        );
    }
}


const renderProductos = ({fields, meta: {error, submitFailed }, simbolo, getProductos, mostrar_precios, productos, proveedor, empresa, editar}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Producto</th>
                        <th style={{width: "20%"}}>Cantidad</th>
                        {/* {
                            (editar) && (
                                <Fragment>
                                    <th style={{width: "20%"}}>Costo unitario</th>
                                    <th  style={{width: "10%"}}>Subtotal</th>
                                </Fragment>
                            )
                        } */}

                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((producto, index) => {
                        let subtotal = 0;
                        let mensaje;
                        let todos = fields.getAll();
                        const prod = fields.get(index);
                        try {
                            subtotal = Number(prod.cantidad) * Number(prod.precio_unitario)
                        } catch (error) {
                            subtotal = 0;
                        }
                        return (
                            <ItemArray
                                editar={editar}
                                empresa={empresa}
                                mostrar_precios={mostrar_precios}
                                prod_seleccionado={prod}
                                proveedor={proveedor}
                                index={index}
                                key={index}
                                todos={todos}
                                subtotal={subtotal}
                                producto={producto}
                                getProductos={getProductos}
                                productos={productos}
                                simbolo={simbolo}
                                eliminar={ () =>{
                                    fields.remove(index)
                                }}
                                field={fields ? fields.get(index) : null}
                            />
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR PRODUCTO
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}
    </div>
)
//fin de render de productos


const getTrabajador = (search) => {
    return api.get("user", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
class DevolucionForm extends Component {
    state = {
        proveedor: null,
        proveedorDespacho: {},
        OC: null,
        idOrden: null,
        moneda: null,
        empresa: null
    }

    getCliente = (id) => {
        return api.get(`clientes/${id}`, {esCliente: undefined}).
        then((data) => {
            if (data){
                this.setState({ proveedorDespacho: data });
                this.setState({ proveedor: data.id });
                this.props.setCliente(data.id);
                this.getDespachos();
            }
            return [];
        }).catch(() => {
            return [];
        });
    };

    constructor(props){
        super(props);
        this.textInputDespachos = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => this.getDespachos(search),
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.cliente !== prevProps.cliente){
            if(this.textInputDespachos && this.textInputDespachos.current){
                this.textInputDespachos.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.getDespachos(search)
                });
                this.forceUpdate();
                this.setState({producto: {}})
                //this.textInputDespachos.ref.props.loadOptions();
            }
        }
    }
    getOrdenes = (search) => {
        return api.get("ordencompra", { search }).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    };
    getDespachos = (search) => {
        let params = {
            tipo_movimiento : 10,
            tipo_despacho: 20
        };
        if(search){
            params.search = search;
        }
        if(this.state.proveedor){
            // params.orden__proveedor = this.state.proveedor.id
            params.orden = this.state.OC.id
            return api.get("despachos", params).
                then((data) => {
                    if (data)
                    return data.results;
                    return [];
                }).catch(() => {
                    return [];
            });
        }else{
            return [];
        }

    }
    getProductos = (search) => {
        let params = {
            tipo_movimiento : 10,
            tipo_despacho: 20
        };
        if(search){
            params.search = search;
        }
        if(this.state.idOrden){
            return api.get(`despachos/${this.state.idOrden}`,params).
            then((data) => {
                if (data){
                    let p = [];
                    data.detalle_despacho.map((item,index) => {
                        item.producto.cantidad = data.detalle_despacho[index].cantidad;
                        p.push(item.producto);
                    })
                    return p;
                }
                return [];
            }).catch(() => {
                return [];
            });
        } else {
            return [];
        }
    };
        reRender = () => {
        this.setState({
            proveedor: this.props.cliente,
            moneda: this.props.moneda
        });
    }
    render() {
        const { handleSubmit,
                total,
                simbolo,
                mostrar_precios,
                empresas,
                texto_descripcion,
                cliente,
                subtotal, handleCuentas, empresa, productos, tiene_iva} = this.props;
        return (
            <form name="DevolucionForm" className="form-validate mb-lg " onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <h4 className="t-primary" >{texto_descripcion}</h4>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 row m-0 p-0">
                        <div className="row col-12 m-0 p-0">
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="nombre">Fecha</label>
                                <Field
                                    name="fecha"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="caja">Orden de Compra</label>
                                {/* <Field
                                    name="empresa"
                                    labelKey={`nombre`} valueKey="id"
                                    component={renderSelectField}
                                    onChangePersonal={e => {
                                        this.setState({empresa:e})
                                    }}
                                    options={empresas}
                                /> */}
                                 <Field
                                    name="orden"
                                    // deafultValue={this.state.proveedor}
                                    label="orden"
                                    component={AsyncSelectField}
                                    loadOptions={this.getOrdenes}
                                    // defaultValue={this.state.proveedorDespacho}
                                    type="text"
                                    valueKey="id"
                                    labelKey="no_orden"
                                    isClearable={false}
                                    onChange={(e) => {
                                        // this.setState({});
                                        this.setState({proveedor: e.proveedor, OC: e});
                                        this.props.setCliente(e.proveedor.id);
                                        this.props.clearDespacho()
                                    }}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="row col-12 m-0 p-0">
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="cliente">Proveedor</label>
                                {
                                    (this.props.bandera_edit) && (!this.state.proveedor) ? (
                                        <h3 className="t-azul" htmlFor="cliente">{
                                            this.props.item.orden && this.props.item.orden.proveedor && this.props.item.orden.proveedor.nombre
                                        }</h3>
                                    ) : (
                                        <h3 className="t-azul" htmlFor="cliente">{this.state.proveedor? this.state.proveedor.nombre:""}</h3>
                                    )
                                }
                                {/* <Field
                                    name="cliente"
                                    deafultValue={this.state.proveedor}
                                    label="cliente"
                                    component={AsyncSelectField}
                                    loadOptions={getClientes}
                                    defaultValue={this.state.proveedorDespacho}
                                    type="text"
                                    valueKey="id"
                                    labelKey="nombre"
                                    onChange={(e) => {
                                        // this.props.setCliente(e.id);
                                    }}
                                    className="form-control" /> */}
                            </div>
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="depacho_devolucion">Ingresos por OC</label>
                                <Field
                                    name="depacho_devolucion"
                                    label="nombre"
                                    key={`od-${cliente}`}
                                    id={`od-${cliente}`}
                                    ref={this.textInputDespachos}
                                    component={AsyncSelectField}
                                    loadOptions={this.state.loadOptions}
                                    type="text"
                                    valueKey="id"
                                    labelKey="no_despacho"
                                    onChange={(e) => {
                                        this.setState({idOrden: e.id});
                                        // this.getCliente(e.proveedor);
                                        //this.setState({proveedor: e.proveedor});
                                    }}
                                    className="form-control" />
                            </div>
                        </div>
                        {/* <div className="row col-12 m-0 p-0">
                            <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="sucursal">Sucursal</label>
                                    <Field
                                        name="sucursal"
                                        component={renderField}
                                        className="form-control"
                                    />
                            </div>

                        </div> */}

                        <div className="form-group has-feedback col-12">
                            <label className="t-azul" htmlFor="descripcion">Descripción</label>
                            <Field
                                name="descripcion"
                                label="descripcion"
                                rows={3}
                                component={renderTextArea}
                                type="text"
                                top={{top:"174px"}}
                                className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 m-0 p-0">
                        <FieldArray
                            empresa={this.state.empresa}
                            mostrar_precios={mostrar_precios}
                            cliente={this.state.cliente}
                            productos={productos}
                            reRender={this.reRender}
                            getProductos={this.getProductos}
                            simbolo={simbolo}
                            name="detalle_despacho"
                            editar={this.props.editar}
                            component={renderProductos} />
                    </div>
                </div>
                {/* {
                    (this.props.editar) && (
                        <div className="col-md-12 m-0 p-0 d-flex justify-content-center mt-3">
                                <div className="col-md-10 m-0 p-0 inline-headers text-center text-md-right">
                                    <span className="t-primary h5 mr-3"  htmlFor="descripcion">TOTAL.     </span>
                                    <h2 className="t-mostaza"  htmlFor="descripcion">{
                                        <RenderMoneda monto={total} simbolo={simbolo} />
                                    }</h2>
                                </div>
                        </div>
                    )
                } */}

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <Link className="btn btn-secondary  align-self-center" to="/devolucionespro" >CANCELAR</Link>
                            {
                                this.props.editar && (
                                    <button type="button" onClick={this.props.anular} className="btn btn-azul-claro m-1 align-self-center">
                                        Rechazar
                                    </button>

                                )
                            }
                            <button type="submit" className="btn btn-primary m-1 align-self-center">
                                {this.props.editar ? 'APROBAR' : 'GUARDAR'}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

//{"fecha":"2019-10-15","empresa":"1","proveedor":1,"moneda":"USD","detalle_orden":[{"producto":2,"cantidad":"3","precio_unitario":3}]}


DevolucionForm = reduxForm({
    form: 'DevolucionProForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if(!data.fecha){
            errors.fecha = "Campo requerido.";
        }
        if(!data.proveedor){
            errors.proveedor = 'Campo requerido';
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido"
        }
        if(!data.cliente){
            errors.cliente = "Campo requerido";
        }
        if(!data.sucursal) {
            errors.sucursal = "Campo requerido";
        }
        if(!data.empresa){
            errors.empresa = "Campo requerido";
        }
        if(!data.encargado){
            errors.encargado = "Campo requerido";
        }
        if(!data.depacho_devolucion){
            errors.depacho_devolucion = "Campo requerido";
        }
        if(!data.detalle_despacho || !data.detalle_despacho.length){
            errors.detalle_despacho = {_error: 'Debe de ingresar al menos un producto'}
        }else {
            const detalleArray = []
            data.detalle_despacho.forEach(function(producto, index){
                let detErrors = {};
                let repetidos = [];
                if(producto.producto){
                    repetidos = _.filter(data.detalle_despacho, (x) => {
                        if(x.producto){
                            return  x.producto.id == producto.producto.id
                        }
                        return false;
                        });
                }
                if(!producto.tipo_devolucion){
                    detErrors.tipo_devolucion = "Campo requerido";
                }
                if(!producto.producto){
                    detErrors.producto =  "Campo requerido";
                }else {
                    if(1 < repetidos.length){
                        detErrors.producto = "Este producto está repetido"
                    }
                }
                if(!producto.cantidad){
                    detErrors.cantidad = "Campo requerido"
                }else {
                    if(parseFloat(producto.cantidad) <= 0)
                        detErrors.cantidad = "Debe ser mayor a 0"
                    else
                        if(parseFloat(producto.cantidad) > parseFloat(producto.producto.cantidad))
                            detErrors.cantidad = `Debe ser menor o igual a ${producto.producto.cantidad}`
                }
                if(!producto.precio_unitario){
                    detErrors.precio_unitario = "Campo requerido";
                }else {
                    // aqui se verifica si el usuario que esta realizando la orden de venta es un vendedor o un administrador
                    // si es un vendedor permitira modificar los precios a un menor valor del permitido
                    // si no mantendra la restriccion del menor precio
                    if (props.role === true){
                        if(parseFloat(producto.precio_unitario) <= 0){
                            detErrors.precio_unitario = `Debe ser mayor a 0`
                        }
                    }
                }

                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.detalle_despacho = detalleArray
            }
        }
        return errors;
    },
})(DevolucionForm);

const selector = formValueSelector('DevolucionForm');
DevolucionForm = connect(state => {
    let todos_productos = [];
    let simbolo = "Q. "
    let valor_iva = 0;

    const productos = selector(state, 'detalle_despacho');
    const moneda = selector(state, 'moneda');
    const descuento = selector(state, 'descuento');
    const empresa = selector(state, 'empresa');
    const tiene_iva = true;

    if(moneda){
        simbolo = getSimbolo(moneda)
    }
    if(productos){
        todos_productos = productos;
    }

    let total = 0;
    let subtotal = 0;
    todos_productos.forEach( x => {
        if(!isNaN(x.cantidad) && !isNaN(x.precio_unitario)){
            subtotal += Number(x.cantidad) * Number(x.precio_unitario);
        }
    })

    total = subtotal
    if(descuento){
        total = total - descuento;
    }


    return {
        total,
        simbolo,
        subtotal,
        empresa,
        productos,
        tiene_iva,
        valor_iva
    }
})(DevolucionForm);

export default DevolucionForm;
