import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarFiltroPlanilla from './filtroPlanilla';
import PlanillaGrid from './PlanillaGrid';

export default class PlanillaGridTab extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };
    state = {
        aprube:true,
    }
    componentWillMount() {
        const { listar, page, permisos } = this.props;
        // listar(page);
    }
    handleData =(value)=>{
        this.setState({aprube:value})
    }
    
    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos.planilla === false) {
            if(!!permisos.ver_planilla === false) {
                return <Redirect to={`/`} />
            }
        }
        return(
            <CardSimple
                titulo="pago de Planilla">
                    <ToolbarSimple
                        margen={"0"}
                        textBoton="NUEVO PAGO"
                        ruta="/planilla/nuevo"
                        buscar={this.state.aprube? this.props.searchChange:this.props.searchChangeRefuse}
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Pago de Planilla"}
                        permisos={permisos.planilla}
                        estatefilterBar={true}
                        filterbar={
                            !this.state.aprube ?
                            <ToolbarFiltroPlanilla
                                changeEstado={this.props.estadoChange}
                                valorEstado={this.props.estado}
                            />
                            : false
                        }
                    />
                    <br/>
                    <Tabs
                        defaultActiveKey="PAGODEPLANILLAS"
                        tabBarPoition = "top"
                        onChange={(key) => {
                            key === 'PAGADAS' ? this.handleData(true): this.handleData(false)
                            this.props.resetPage();
                        }}
                        renderTabBar ={()=><ScrollableInkTabBar/>}
                        renderTabContent={()=><TabContent/>}
                    >
                        <TabPane tab="APROBADAS" key="PAGADAS">
                            <PlanillaGrid
                                data={data}
                                listar={this.props.listar}
                                loader={loader}
                                page={page}
                                onPageChange={onPageChange}
                                onSortChange={onSortChange}
                                eliminar={eliminar}
                                handleData={this.handleData}
                                value={true}
                                descargarCSV={this.props.descargarCSV}
                                permiso={permisos.planilla}
                                estado={true}
                            />
                        </TabPane>
                        <TabPane tab="BORRADOR" key="BORRADOR">
                            <PlanillaGrid
                                data={this.props.datarefuse}
                                listar={this.props.listarRefuse}
                                loader={loader}
                                page={page}
                                onPageChange={onPageChange}
                                onSortChange={this.props.onSortChangeRefuse}
                                eliminar={eliminar}
                                handleData={this.handleData}
                                value={false}
                                permiso={permisos.planilla}
                                estado={this.props.estado.value}
                            />
                        </TabPane>
                    </Tabs>

            </CardSimple>
        )
    }
}

