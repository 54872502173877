import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import {standardActions} from '../../../Utils/Grid/StandardActions';
import moment from 'moment';

export default class GridTipos extends Component {

    componentDidMount() {
        this.props.tipoProductosConsumidos();
    }

    render() {
        const { page, data, loader, tipoProductosConsumidos} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={tipoProductosConsumidos}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="producto_Base__tipo_producto"
                        className='reporte-table'
                        width="10%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="producto_Base__tipo_producto__nombre"
                        className='reporte-table'
                    >
                        TIPO DE PRODUCTO CONSUMIDO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        className='reporte-table'
                        dataAlign="right"
                        dataFormat={(cell) => <RenderNumber value={cell} decimalScale={4} />  }
                    >
                        UNIDADES CONSUMIDAS
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataFormat={(cell) => ""}
                        width="10%"
                    />
                </Grid>
            </Fragment>
        )
    }
}
