import React, { Fragment, Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Link, Redirect } from 'react-router-dom';
import { renderDropdownDateField } from "Utils/renderField/otherrenders";
import {
    renderField,
    renderTextArea,
    renderFieldCheck,
    renderSelectField,
    renderCurrency,
    AsyncSelectField,
    renderFieldRadio,
    SelectField,
    renderSelecttree,
    renderPercentage,
    SelectFieldGroup,
    renderNumber
} from '../../../Utils/renderField';
import { Monedas, getsimbolo } from '../../../../../utility/constants';
import { RenderMoneda, getSimbolo } from "../../../Utils/renderField/renderTableField";
import { TipoTransaccion } from '../../../../../utility/constants';
import { api } from 'api';
import moment from 'moment';
import iconoVer from '../../../../../../assets/img/icons/ver.png';
import iconoImpresora from '../../../../../../assets/img/icons/imprimir.png';
import iconoFacturar from '../../../../../../assets/img/icons/facturar.png';
import iconoVerFactura from '../../../../../../assets/img/icons/ver.png';
import iconoImprimir from '../../../../../../assets/img/icons/imprimir.png';
import iconoPDFFactura from '../../../../../../assets/img/icons/facturapdf.png';


class ItemArray extends Component {
    state = {
        cliente: undefined,
        results: []
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        // 
        this.textInputDespachos = React.createRef();
        this.textInputSU = React.createRef();
        this.textInputOV = React.createRef();
        this.state = {
            loadOptions: (search) => this.getDespachos(search),
            optionsSU: (search) => this.getSucursales(search),
        }
    }


    getDespachos = (search) => {
        let params = {
            tipo_movimiento: 40,
            tipo_despacho: 10,
        };
        if (search) {
            params.search = search;
        }
        if (this.props.count) {
            params.orden__proveedor = this.props.count.cliente
            params.flujo_despacho = 20 // Para despachos aceptados
            params.entregado = 0
        }
        return api.get("despachos/listar_despachos_para_planta", params).
            then((data) => {
                if (data) {
                    this.setState({results: data.results})
                    return data.results;
                }
            }).catch(() => {
                return [];
            });
    }


    botonImprimirDespacho = (id) => {
        const { PDFDESPACHO } = this.props
        PDFDESPACHO(id)
    }


    botonPDFFactura = (row, dato) => {
        this.state.results.map(result => {
            result.orden_detalle.facturas.map((item) =>{
                if(item.factura && item.id == dato){
                    window.open(item.factura, "_blank");
                }else{return(<div></div>)}
            })
        })
    }

    botonXml = (row, dato) => {
        row.despacho.orden_detalle.facturas.map((item) =>{
            if (item.archivo_xml && item.id == dato) {
                window.open(item.archivo_xml, "_blank");
            } else {return (<div></div>) }
        })
    }



    render() {

        const { index, count, eliminar, campo, mostrarVista, field, item, despachos } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar" campo
                        onClick={() => {
                            eliminar()
                        }}
                    />
                </td>

                <td className="sin-borde-topzz">
                    <Field
                        //key={campo+'sucursal'}
                        //name={campo+`sucursal`}
                        key={`${campo}-sucursal`}
                        name={`${campo}.sucursal`}
                        label="sucursal"
                        labelKey="direccion" valueKey="id"
                        component={renderSelectField}
                        options={count ? count.sucursal : []}
                        sClearable={true}
                        className="form-control"
                        top={{ top: "5px", position: "inherit" }}
                    />
                </td>
                <td className="sin-borde-topzz">
                    <Field
                        ref={this.textInputDespachos} //Referencias react 
                        key={`${campo}-od-${count ? count.cliente : '0'}`}
                        name={`${campo}.despacho`}

                        //key={`-od-${count ? count.cliente : '0'}`}
                        id={`-od-${count ? count.cliente : '0'}`}
                        //name={`despacho`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top={{ top: "5px", position: "inherit" }}
                        type="text"
                        valueKey="id" //campo del modelo a mostrar en el select
                        labelKey="no_despacho" //campo del modelo a mostrar en el select
                        isClearable={true}
                        className="form-control"
                    />
                </td>


            
                <td>
                    {(field && Object.keys(field).length > 0 && despachos.length > 0) ? (
                        (field.despacho && Object.keys(field).length > 0 && despachos[index].despacho) ? (
                            (field.despacho.facturas_despacho && field.despacho.facturas_despacho.length > 0 && despachos[index].despacho.facturas_despacho.length > 0) ? (
                                despachos[index].despacho.facturas_despacho.map((dato, index) => {
                                    //console.log("field: ", field, "index: ",index , "dato: ", dato)
                                    return (
                                        <Fragment>
                                            <td className="sin-borde-topzz">
                                                <img className="action-img" alt="PDF Factura" title="PDF Factura" src={iconoPDFFactura} onClick={() => {this.botonPDFFactura(field, dato); this.botonXml(field, dato);}} ></img>
                                            </td>
                                            <td className="sin-borde-topzz">
                                                <img className="action-img" alt="Imprimir Despacho" title="Imprimir Despacho" src={iconoImprimir} onClick={() => this.botonImprimirDespacho(field.despacho.id)}></img>
                                            </td>
                                        </Fragment>
                                    )
                                })
                            ) :
                                <td className="sin-borde-topzz">
                                    <img className="action-img" alt="Facturacion" title="Facturación" src={iconoFacturar} onClick={() => mostrarVista(true, index)}></img>
                                </td>
                        ) :
                            <td></td>
                    ) :
                        <td></td>
                    }
                </td>
            </tr >
        );
    }
}

const getClientes = (search, props) => {
    let params = { tipo: 2, esCliente: '1' }
    if (search) {
        params.search = search;
    }
    if(props.permisos.visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = props.id_user
    }
    return api.get("clientes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const renderDespacho = ({ fields, meta: { error, submitFailed }, PDFDESPACHO, mostrarVista, count, borrarProductos, cliente, getDetalleOrden, verProductos, item, reRender, editar, paraCliente, setCliente, despachos }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: "2%" }}></th>
                            <th style={{ width: "40%" }}>Sucursal</th>
                            <th style={{ width: "30%" }}>Despacho</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((campo, index) => {
                            let todos = fields.getAll()

                            return (
                                //Manejo de campos Agregar listado
                                <ItemArray
                                    campo={campo}
                                    index={index}
                                    key={index}
                                    item={item}
                                    eliminar={() => {
                                        fields.remove(index)
                                    }}
                                    count={count}
                                    mostrarVista={mostrarVista}
                                    field={fields ? fields.get(index) : null}
                                    PDFDESPACHO={PDFDESPACHO}
                                    despachos={despachos}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    {'AGREGAR'}
                </button>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>}
    </div>
)


let DespachoPlantaForm = (props) => {

    const { handleSubmit, editar, setCliente, cliente, mostrarVista, count, setClienteSucursal, setClienteOV, PDFDESPACHO, despachos } = props;
    console.log("props*******------", props)
    return (
        <form name="DespachoPlantaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Fecha</label>
                    <Field
                        name="fecha_despacho"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6"></div>
            </div>

            <div className="row">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="quien_recibe">Nombre quien recibe</label>
                    <Field
                        name="quien_recibe"
                        label="quien_recibe"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Cliente</label>
                    <Field
                        name="cliente"
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={(search) => getClientes(search, props)}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            setClienteSucursal(e)
                        }}
                        isClearable={false}
                        className="form-control"
                    />
                </div>
            </div>
            <h4 className="t-primary text-uppercase">
                DESPACHOS
                </h4>
            <div className="col-12 m-0 p-0 d-flex justify-content-center">
                <div className="col-md-10 m-0 p-0 form-group has-feedback">
                    <FieldArray
                        name="despachosDetalles"
                        component={renderDespacho}
                        count={count}
                        mostrarVista={mostrarVista}
                        top={{ top: "10px", position: "inherit" }}
                        PDFDESPACHO={PDFDESPACHO}
                        despachos={despachos}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <Link className="btn btn-secondary  align-self-center" to="/despachosplanta" >CANCELAR</Link>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


DespachoPlantaForm = reduxForm({
    form: 'DespachoPlantaForm', // a unique identifier for this form,
    destroyOnUnmount: false,

    validate: (data) => {
        const errors = {};
        if (!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if (!data.quien_recibe) {
            errors.quien_recibe = "Campo requerido";
        }
        if (!data.cliente) {
            errors.cliente = "Campo requerido";
        }
        if (data.despachosDetalles && data.despachosDetalles.length) {
            const detalleArray = []
            data.despachosDetalles.forEach((campo, index) => {
                let detErrors = {};
                let repetidos = [];
                //if (!campo.sucursal) {
                //detErrors.sucursal = "Campo requerido"
                //}
                if (campo.despacho) {
                    repetidos = _.filter(data.despachosDetalles, (x) => {
                        if (x.despacho)
                            return x.despacho.id == campo.despacho.id
                        return false;
                    });
                } if (!campo.despacho) {
                    detErrors.despacho = "Campo requerido"
                } else {
                    if (1 < repetidos.length) {
                        detErrors.despacho = "Este despacho está repetido"
                    }
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.despachosDetalles = detalleArray
            }
        }
        return errors;
    },
})(DespachoPlantaForm);

const selector = formValueSelector('DespachoPlantaForm');
DespachoPlantaForm = connect(state => {
    let despachos =[]

    if(state.form.DespachoPlantaForm){
        despachos=state.form.DespachoPlantaForm.values.despachosDetalles
    }
    
   return {
       despachos
   };
})(DespachoPlantaForm);

export default DespachoPlantaForm
