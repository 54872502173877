import React, { Component, Fragment } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import CardSimple from '../../../Utils/Cards/cardSimple';

import LoadMask from "../../../Utils/LoadMask/LoadMask";
import moment from 'moment';

import PlanillaGridForm from './PVform';
import TotalForm from './totalform';



export default class NuevoPagoPlanilla extends Component {
    state ={
        editar: false,
        titulo: 'NUEVA PlANILLA',
        step: 1,
        valor_formulario: null,
    }
    get_fechaI = () =>{
        let fecha = new Date();
        let dia = fecha.getDate()
        if (dia > 1){
            fecha.setDate(fecha.getDate() - 1);
        }
        return fecha
    }
    getBusinessDays = (startDate, endDate) => {
        var startDateMoment = moment(startDate);
        var endDateMoment = moment(endDate)
        var days = endDateMoment.diff(startDateMoment, 'days') 
        days ++;
        while (startDateMoment <= endDateMoment) {
            if (startDateMoment.isoWeekday() === 7) {
                days --;
            }
            startDateMoment = startDateMoment.add(1, 'days');
        }

        var year = moment(endDate).format('YYYY');
        var month = moment(endDate).format('MM');
        // avaliable month
        var startDateMoment = moment(`${year}-${month}-1`, 'YYYY-MM-DD');
        var endDateMoment =  moment(`${year}-${month}-${moment().daysInMonth(endDate)}`, 'YYYY-MM-DD')
        var days_month = endDateMoment.diff(startDateMoment, 'days') 
        days_month ++;
        while (startDateMoment <= endDateMoment) {
            if (startDateMoment.isoWeekday() === 7) {
                days_month --;
            }
            startDateMoment = startDateMoment.add(1, 'days');
        }

        return { days, days_month };
    }
    componentDidMount(){
        const { selectEmpresas, state_bonos, setEmpleadoInfo} = this.props
        selectEmpresas();
        if (state_bonos){
            if (this.props.match.params.id) {
                this.setState({editar: true,  titulo:'EDITAR PlANILLA'});
            }
            setEmpleadoInfo({}, null, [], false)
        }else{
            const {selectEmpleados} =this.props
            if (this.props.match.params.id) {
                this.setState({editar: true,  titulo:'EDITAR PlANILLA'});
                this.props.leer(this.props.match.params.id);
            }
            else{
                let cant = this.getBusinessDays(this.get_fechaI(), new Date())
                selectEmpleados(this.get_fechaI(), new Date(), cant)
            }
        }
    }
    componentWillUnmount(){
        this.props.clear()
    }
    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };
    firstStep = (values) => {
        this.setState({valor_formulario: values});
        this.nextStep();
    }
    handleSubmit = (values) => {
        // this.props.marcarComoTarjetaPagado(this.state.valor_formulario);
        const { crear, editar, item, clear} = this.props
        if (this.state.editar){
            values.id = this.props.match.params.id
            editar(this.props.match.params.id, values)
            // clear()
        }else{ crear(values)}
    }
    render() {
        const { loader, empleados } = this.props
        const { step } = this.state;

        return (
            <CardSimple
                    titulo={this.state.titulo}
                >
                <LoadMask loading={loader} light>
                    <div className="col-md-12 row d-flex justify-content-center">
                        <div className="col-md-7">
                            <ProgressBar
                                percent={step === 1 ? 0: step === 2 ? 100 : 100}
                                filledBackground= "#f3a631"
                                stepPositions={[0,100]}

                            >
                                <Step transition="scale">
                                {({ accomplished, index }) => (
                                    <div
                                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                >
                                    <div className={ `${accomplished? 'step-caption-selected':"step-caption"}`}>
                                        {index + 1}
                                    </div>
                                </div>
                                )}
                                </Step>
                                <Step transition="scale">
                                {({ accomplished, index }) => (
                                    <div
                                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                >
                                    <div className={ `${accomplished? 'step-caption-selected':"step-caption"}`}>
                                        {index + 1}
                                    </div>
                                </div>
                                )}
                                </Step>
                            </ProgressBar>
                        </div>
                        <div className="col-md-12 mt-3">
                            {
                                (step == 1) && (
                                    <PlanillaGridForm
                                        mostrar_precios={this.state.mostrar_precios}
                                        onSubmit={this.nextStep}
                                        getBusinessDays={this.getBusinessDays}
                                        texto_descripcion={this.state.texto_descripcion}
                                        {...this.props}
                                        initialValues={{
                                            es_borrador:"1",
                                            'fechaI': this.get_fechaI(),
                                            'fechaF': new Date(),
                                        }}
                                    />
                                )
                            }
                            {
                                (step === 2) && (
                                    <TotalForm
                                        previousStep={this.previousStep}
                                        onSubmit={this.handleSubmit}
                                        {...this.props}
                                        // initialValues={{es_borrador:"1"}}
                                    />
                                )
                            }
                        </div>
                    </div>
                </LoadMask>

            </CardSimple>

        )
    }
}
