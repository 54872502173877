import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import ToolbarReporteCostoInventario from '../../../Utils/Toolbar/ToolbarReporteCostoInventario';
import GridCostoInventario from './GridCostoInventario';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import moment from 'moment';
import { Redirect } from 'react-router';
import Swal from 'sweetalert2';

export default class ReporteCostoInventario extends Component {

    componentDidMount(){
        this.props.listar();
    }

    cerrarCostos = () => {
        Swal.fire({
            title: 'Cerrar costos?',
            text: '¡Se van a cerrar todos los productos de todas las empresas, no solo los que están visibles!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, Cerrar costos!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.HistorialCostos();
            }
        });
    }

    render() {
        const { loader, data, permisos } = this.props;
        if(permisos.costo_inventario === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE COSTOS DE INVENTARIO">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReporteCostoInventario {...this.props} />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-12 d-flex justify-content-end " style={{padding: "20px"}}>
                            <DatePicker
                                selected={new Date(this.props.fecha_cierre)}
                                onChange={value => {
                                    this.props.changeFecha(moment(value))
                                }}
                                className="form-control text-date d-flex flex-column flex-1 general_height"
                                dateFormat="MMMM/yyyy"
                                locale="es"
                                showMonthYearPicker
                            />
                            {data != null && data.results && data.results[0] ?
                            !data.results[0].costos_cerrados &&
                                <button onClick={this.cerrarCostos} className="btn btn-primary" style={{marginLeft:"20px"}}>CERRAR COSTOS</button>
                            :''}
                        </div>
                        <div className="col-12 d-flex justify-content-center" >
                            <div className="justify-content-center">
                                <br/>
                                <h3 className="t-musgo">TOTAL DE INVENTARIO</h3>
                                <h3 className="t-primary m-0 text-center">
                                    <RenderMoneda monto = {data != null && data.results && data.results[0] ? `${data.results[0].suma_total} ` : 0}/>
                                </h3>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <br/>

                    <GridCostoInventario
                        { ...this.props }
                    />

                </LoadMask>

            </CardSimple>
        )

    }
}
