import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import  {renderDropdownDateField} from "Utils/renderField/otherrenders";
import {
    renderField,
    renderSelectField,
    renderCurrency,
    renderSelecttree,
    renderPercentage } from '../../../../Utils/renderField';



class ItinerarioForm extends Component {
    state = {
        km_diferencia: 0,
        km_inicial: 0,
        km_final: 0
    }

    render() {
        const { handleSubmit,
            tipo_gastos,
            cambioDescuento,
            itinerario,
            pendiente_liquidar,
        } = this.props;
        return (
            <form name="itinerarioForm" className="form-validate mb-lg " onSubmit={handleSubmit}>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-lg-6 pl-0">
                            <div className="form-group has-feedback">
                                <label className="t-azul" htmlFor="nombre">Fecha de Entrada</label>
                                <Field
                                    name="fecha_entrada"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                            <div className="form-group has-feedback">
                                <label className="t-azul" htmlFor="piloto">Km. Diferencia</label>
                                <Field
                                    readOnly = { true }
                                    name="km_diferencia"
                                    input = {{ value: this.props.km_diferencia }}
                                    type="number"
                                    component={renderField}
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 pr-0">
                            <div className="form-group has-feedback">
                                <label className="t-azul" htmlFor="nombre">KM Inicial</label>
                                <Field
                                    name="km_inicial"
                                    type="number"
                                    component={renderField}
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </div>
                            <div className="form-group has-feedback">
                                <label className="t-azul" htmlFor="nombre">KM Final</label>
                                <Field
                                    name="km_final"
                                    type="number"
                                    component={renderField}
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </div>
                        </div>
                </div>

                <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Fecha desglose</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                    <label className="t-azul" htmlFor="nombre">Monto</label>
                    <Field
                        name={`monto`}
                        label="tipo_cambio"
                        prefix={"Q"}
                        _onChange={(e) => {cambioDescuento(e, 'monto', 0,'vistas')}}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                        top ={{top:"67px", position:"inherit"}}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre"> Categoria Gasto </label>
                            <Field
                            name="tipo_gasto"
                            label="nombre"
                            options={tipo_gastos}
                            valueKey="value"
                            labelKey="label"
                            component={renderSelecttree}
                            showSearch = { true }
                            filtro = "title"
                            type="text"
                            className="form-control" />
                </div>
            </div>
            <br/>
            {
            (!pendiente_liquidar) && (
                    <Fragment>
            <h4 className="t-primary text-uppercase">DESGLOSE DE GASTOS</h4>

                        <div className="col-12 m-0 p-0 d-flex justify-content-center">
                            <FieldArray
                                cambioDescuento={cambioDescuento}
                                simbolo={'Q'}
                                name="desglose"
                                empresas={this.props.empresas}
                                component={renderDesglose} />
                        </div>
                    </Fragment>
                    )
            }
            <div className="row">
            <br/>
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <Link className="btn btn-secondary  align-self-center" to="/itinerario"> CANCELAR </Link>
                        <button type="submit" className="btn btn-primary m-1 align-self-center"> GUARDAR </button>
                    </div>
                </div>
            </div>
            <br/>

            </form>
        );
    }
}


ItinerarioForm = reduxForm({
    form: 'itinerarioForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {};
        if(!data.fecha_entrada){
            errors.fecha_entrada = "Campo requerido.";
        }
        if(!data.fecha_salida){
            errors.fecha_salida = "Campo requerido";
        }
        if(!data.km_inicial){
            errors.km_inicial = "Campo requerido";
        }
        if(!data.km_final){
            errors.km_final = "Campo requerido";
        }
        if(data.km_inicial && data.km_final){
            if(parseInt(data.km_final) < parseInt(data.km_inicial)){
                errors.km_final = "No puede ser menor al inicial";
            }
        }
        if(!data.monto){
            errors.monto = "Campo requerido";
        }
        if(!data.tipo_gasto){
            errors.tipo_gasto = "Campo requerido";
        }
        if(!data.desglose || !data.desglose.length){
            errors.desglose = {_error: 'Debe de ingresar al menos una empresa'}
        }else {

            const detalleArray = []
            let hay_error = false
            data.desglose.forEach(function(empresa, index){
                let detErrors = {};
                let repetidos = [];
                if(empresa.empresa){
                    repetidos = _.filter(data.desglose, (x) => {
                        if(x.empresa){
                            return  x.empresa == empresa.empresa
                        }

                        return false;
                        });
                }

                if(!empresa.empresa){
                    detErrors.empresa =  "Campo requerido";
                    hay_error = true
                }else {
                    if(1 < repetidos.length){
                        detErrors.empresa = "Esta empresa está repetido"
                        hay_error = true
                    }
                }
                if(!empresa.subtotal){
                    detErrors.subtotal = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.subtotal) <= 0){
                        detErrors.subtotal = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }
                if(!empresa.porcentaje){
                    detErrors.porcentaje = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.porcentaje) <= 0){
                        detErrors.porcentaje = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }

                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.desglose = detalleArray
                if(!hay_error){
                     /*Manejo del total de desglose */
                    let subtotal = 0;
                    data.desglose.forEach( x => {
                        subtotal += Number(x.subtotal)
                    })
                    console.log('sub total: ', subtotal, data.monto)
                    if(Number(subtotal) != Number(data.monto)){
                        console.log('La sumatoria es difertene')
                        errors.desglose = {_error: 'La sumatoria del desglose no coincide con el monto del gasto'}
                    }
                    /*Fin del desglose */
                }

            }
        }
        return errors;
    },
})(ItinerarioForm);


const selector = formValueSelector('itinerarioForm');
ItinerarioForm = connect(state => {
    const km_inicial = selector(state, 'km_inicial');
    const km_final = selector(state, 'km_final');

    let km_diferencia = 0
    if( km_inicial && km_final ) {
        km_diferencia = Math.abs(parseInt(km_final)) - Math.abs(parseInt(km_inicial))
    }

    return{
        km_diferencia
    }

})(ItinerarioForm);


export default ItinerarioForm;

const renderDesglose = ({fields, meta: {error, submitFailed }, simbolo, empresas, cambioDescuento}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Empresa</th>
                        <th style={{width: "20%"}}>Porcentaje</th>
                        <th  style={{width: "20%"}}>Subtotal</th>
                    </tr>
                    </thead>
                {/* </table>

            </div>

            <div className="contenido-contactos">
                <table className="table table-sm  m-0 table-striped"> */}
                    <tbody>
                    {fields.map((empresa, index) => {
                        return (
                            <tr key={index} style={{height:"68px"}}>
                                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                        title="Borrar"
                                        onClick={() =>{
                                            fields.remove(index)
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                    name={`${empresa}.empresa`}
                                    label="empresa"
                                    labelKey="nombre" valueKey="id"
                                    component={renderSelectField}
                                    options={empresas}
                                    className="form-control"/>
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                            name={`${empresa}.porcentaje`}
                                            type="number"
                                            addClass={"text-right"}
                                            component={renderPercentage}
                                            placeholder="Precio"
                                            _onChange={(e) => {cambioDescuento(e, 'porcentaje', index)}}
                                        />
                                </td>
                                <td className="sin-borde-topzz">
                                    <Field
                                        name={`${empresa}.subtotal`}
                                        label="tipo_cambio"
                                        prefix={simbolo}
                                        component={renderCurrency}
                                        parse={cell => parseFloat(cell)}
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                        _onChange={(e) => {cambioDescuento(e, 'precio', index)}}
                                    />
                                </td>
                            </tr>
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR EMPRESA
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger h5 font-weight-bold">
                        {error}
                </div>}
    </div>
)
//fin de render de desglose

/* 
let DesgloseForm = (props) => {
    const {
        handleSubmit,
        tipo_gastos,
        cambioDescuento,
        itinerario,
    } = props;
    console.log(itinerario)
    return (
        <div>
        <ItinerarioForm onSubmit={handleSubmit} id="formItinerario"/>
        <form name="DesgloseForm" id="formGastos" className="form-validate mb-lg" onSubmit={submitTwoForms}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                    <label className="t-azul" htmlFor="nombre">Monto</label>
                    <Field
                        name={`monto`}
                        label="tipo_cambio"
                        prefix={"Q"}
                        _onChange={(e) => {cambioDescuento(e, 'monto', 0,'vistas')}}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                        top ={{top:"67px", position:"inherit"}}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre"> Categoria Gasto </label>
                            <Field
                            name="tipo_gasto"
                            label="nombre"
                            options={tipo_gastos}
                            valueKey="value"
                            labelKey="label"
                            component={renderSelecttree}
                            showSearch = { true }
                            filtro = "title"
                            type="text"
                            className="form-control" />
                </div>
            </div>
            <br/>
            <h4 className="t-primary text-uppercase">DESGLOSE DE GASTOS</h4>

                    <Fragment>
                        <div className="col-12 m-0 p-0 d-flex justify-content-center">
                            <FieldArray
                                cambioDescuento={cambioDescuento}
                                simbolo={'Q'}
                                name="desglose"
                                empresas={props.empresas}
                                component={renderDesglose} />
                        </div>
                    </Fragment>

            <div className="row">
            <br/>
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <Link className="btn btn-secondary  align-self-center" to="/itinerario"> CANCELAR </Link>
                        <button type="submit" className="btn btn-primary m-1 align-self-center"> GUARDAR </button>
                    </div>
                </div>
            </div>
            <br/>
        </form>
        </div>
    );
};


DesgloseForm = reduxForm({
    form: 'DesgloseForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if(!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if(!data.monto){
            errors.monto = "Campo requerido";
        }
        if(!data.tipo_gasto){
            errors.tipo_gasto = "Campo requerido";
        }
        if(!data.desglose || !data.desglose.length){
            errors.desglose = {_error: 'Debe de ingresar al menos una empresa'}
        }else {

            const detalleArray = []
            let hay_error = false
            data.desglose.forEach(function(empresa, index){
                let detErrors = {};
                let repetidos = [];
                if(empresa.empresa){
                    repetidos = _.filter(data.desglose, (x) => {
                        if(x.empresa){
                            return  x.empresa == empresa.empresa
                        }

                        return false;
                        });
                }

                if(!empresa.empresa){
                    detErrors.empresa =  "Campo requerido";
                    hay_error = true
                }else {
                    if(1 < repetidos.length){
                        detErrors.empresa = "Esta empresa está repetido"
                        hay_error = true
                    }
                }
                if(!empresa.subtotal){
                    detErrors.subtotal = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.subtotal) <= 0){
                        detErrors.subtotal = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }
                if(!empresa.porcentaje){
                    detErrors.porcentaje = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.porcentaje) <= 0){
                        detErrors.porcentaje = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }

                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.desglose = detalleArray
                if(!hay_error){
                     //Manejo del total de desglose
                    let subtotal = 0;
                    data.desglose.forEach( x => {
                        subtotal += Number(x.subtotal)
                    })
                    console.log('sub total: ', subtotal, data.monto)
                    if(Number(subtotal) != Number(data.monto)){
                        console.log('La sumatoria es difertene')
                        errors.desglose = {_error: 'La sumatoria del desglose no coincide con el monto del gasto'}
                    }
                    //Fin del desglose
                }

            }
        }
        return errors;
    },
})(DesgloseForm);

export default DesgloseForm
 */
