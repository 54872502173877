import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../../Utils/Grid/index';
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from '../../../../../../utility/constants';
import {RenderCurrency, RenderNumber} from "../../../../Utils/renderField/renderReadField";
import { renderNumber } from '../../../../Utils/renderField';

export default class DteGrid extends Component{
    botonFactura = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.factura){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank"
                    href={row.factura}
                >
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../../assets/img/icons/facturapdf.png")} alt="PDF"/>
                </a>
            )
        }else{return(<div></div>)}
    }

    botonXml = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.archivo_xml){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank" 
                    href={row.archivo_xml}
                    download={`${row.archivo_xml}`}
                >
                    <img
                        className="action-img"
                        title="XML"
                        src={require("../../../../../../../assets/img/icons/XML.png")} alt="XML"/>
                </a>
            )
        }else{return(<div></div>)}
    }

    render(){
        const { dtes_list, loader, listar: onPageChange, onSortChange, eliminar  } = this.props;
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={{count:10,results:dtes_list}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={
                            (cell, row)=>{
                                return row.estado_factura=='ACEPTADA' ?
                                standardActions({
                                    adicional:this.botonFactura,
                                    adicional2:this.botonXml,
                                })(cell,row) : (row.estado_factura==2 ? 'FALLIDA' : 'PENDIENTE')
                            }
                        }
                    > </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_dte_string"
                        dataSort
                    >
                        TIPO DTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nit"
                        dataSort
                    >
                        NIT
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_serie"
                        dataSort

                    >
                        No. DTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado_factura"
                        dataSort
                        dataFormat={
                            (cell, row)=>{
                                if (row.dte_origen && row.dte_origen_numero) return row.dte_origen_numero
                                else return "N/A"
                            }
                        }
                    >
                        DTE ORIGEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado_factura"
                        dataSort
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}
