import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderNumber} from "../../../Utils/renderField/renderReadField";
import {standardActions} from '../../../Utils/Grid/StandardActions';
import moment from 'moment';

export default class GridTipos extends Component {
    componentDidMount() {
        this.props.porTiposProductos();
    }

    render() {
        const { page, data, loader, porTiposProductos} = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={porTiposProductos}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="producto__tipo_producto"
                        className='reporte-table'
                        width="5%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="producto__tipo_producto__nombre"
                        className='reporte-table'
                    >
                        TIPO DE PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="producido"
                        className='reporte-table'
                        dataAlign="right"
                        dataFormat={(cell) => <RenderNumber value={cell} decimalScale={2} />  }
                        >
                        UNIDADES PRODUCIDAS
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="existencias"
                        className='reporte-table'
                        dataAlign="right"
                        dataFormat={(cell) => <RenderNumber value={cell} decimalScale={2} />  }
                    >
                        EN EXISTENCIAS
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="existencias"
                        dataFormat={(cell) => ""}
                        width="5%"
                    />
                </Grid>
            </Fragment>
        )
    }
}
