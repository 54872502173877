import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../../Utils/Grid/index';
import moment from 'moment';


export default class HistorialEntregas extends Component{

    render(){
        const { data, loader } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid
                    hover
                    striped
                    data={{count:10, results:data}}
                    loading={loader}
                    pagination={false}
                >
                    <TableHeaderColumn
                        dataField="id"
                        isKey
                        dataFormat={(cell, row) => `No. ${row.itinerario}`}
                    >
                        ITINERARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataFormat={(cell) => cell ? moment(cell).format("L") : ""}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despacho"
                    >
                        NO. DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="firma"
                        dataSort
                        dataFormat={
                            (cell, row) => (
                                cell ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img className="firma-img" src={cell}  />
                                    </div>
                                ) : 'Sin entregar'
                            )
                        }
                    >
                        FIRMA DE RECIBIDO
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}
