import React, { Component } from 'react';
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
// import NVD3ChartMask from "../../../Utils/Graficas/NDV3ChartMask";
// import {
//     opcionesG1
// } from '../../../Utils/Graficas/opciones';

import Chart from "react-google-charts";

import _ from 'lodash';

export default class GridMetas extends Component {

    render() {
        // let options2 = _.cloneDeep(opcionesG1.optionsMedia)
        const { totales } = this.props;
        return (
            <React.Fragment>
                <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3"
                    style={{ border: "1px solid #E8E8E8", }}>
                    <div className="col-md-6 col-12  d-flex justify-content-center" >
                        <div>
                            <br />
                            <h4 className="t-musgo">TOTAL VENTAS</h4>
                            <h3 className="t-primary m-0 text-center">
                                <RenderMoneda monto={totales.ventas} />
                            </h3>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-6 col-12 d-flex justify-content-center">
                        <div>
                            <br />
                            <h4 className="t-musgo">TOTAL METAS</h4>
                            <h3 className="t-mostaza m-0 text-center">
                                <RenderMoneda monto={totales.metas} />
                            </h3>
                            <br />
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 mt-3">
                    <div className="">
                        <h3 className="t-primary">GENERAL</h3>
                    </div>
                    <div className="col-md-12 mx-0 px-0">
                        <div className="horizontal-scroll">
                            <div style={{ width: '100%', flex: 1 }}>
                                <Chart
                                    width={"100%"}
                                    height={500}
                                    chartType="ColumnChart"
                                    loader={<div>Loading Chart</div>}
                                    data={totales.grafica}
                                    options={{
                                        chartArea: { width: '85%' },
                                        legend: { position: 'top', maxLines: 3 },
                                        bar: { groupWidth: '45%' },
                                        colors:['#00334E','#F5A233'],
                                        hAxis: {
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            format:'Q ###,###,###.00'
                                        },
                                        tooltip: {
                                            format:'Q ###,###,###.00'
                                        },
                                    }}
                                    legendToggle
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
