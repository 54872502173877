import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reporteVehiculos';
import ReporteVehiculos from './ReporteVehiculos';

const mstp = state => {
    return {
        ...state.reporteVehiculos,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteVehiculos)
