import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions, customStandardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSI from '../../../Utils/Toolbar/ToolbarSI'
import ToolbarSolicitudes from '../../../Utils/Toolbar/ToolbarSolicitudes';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { Redirect } from 'react-router-dom';
import { api } from 'api';
import { getsimbolo } from '../../../../../utility/constants';
export default class VentaInternaGrid extends Component {
    componentDidMount() {
        this.props.changeFechaSI(null, 1);
        const { listar, page, me, changeEmpresa1 } = this.props;
        const empresa = me.profile.empresa;
        if (empresa !== null) {
            api.get(`empresa/${empresa}`).
            then((data) => {
                changeEmpresa1(data);
            }).catch(() => {
            });
        } else {
            listar(page);
        }
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos, total } = this.props;
        if(permisos["sim"] === false && permisos["ver_sim"] === false ) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple
                titulo="Solicitud Interna de Materiales"
                margen=" "
                toolbar={
                    <ToolbarSolicitudes
                        textBoton="Nueva solicitud"
                        ruta="/solicitud_interna/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        permisos={true}
                        placeholder={"Buscar por: No. Orden"}
                        estatefilterBar={true}
                        // margen="mt-5 px-4"
                        filterbar={
                            <ToolbarSI
                                change_empresa1={this.props.changeEmpresa1}
                                change_empresa2={this.props.changeEmpresa2}
                                change_estado={this.props.changeEstado}
                                valor_estado={this.props.estado}
                                empresa1={this.props.empresa_1}
                                empresa2={this.props.empresa_2}
                                changeFechaSI={this.props.changeFechaSI}
                                fecha_I={this.props.fecha_I}
                                fecha_F={this.props.fecha_F}
                            />
                        }
                    />
                }
            >
                {
                    permisos.ingresos_sim &&
                    <div className="py-md-3 px-md-3 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-md-12 m-0 p-0 d-flex justify-content-center">
                            <div className="col-md-10 m-0 p-0 inline-headers text-center">
                                <span className="t-azul-claro font-weight-bold h5 mr-3"  htmlFor="descripcion">TOTAL </span>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                 <RenderMoneda monto={total} simbolo={"Q"} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                }
                <br/>
                <Grid
                    page={this.props.page}
                    hover striped
                    data={data}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange} >
                   {
                       permisos.sim == true &&
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell, row) => {
                                return customStandardActions(cell, {
                                    eliminar: !row.tiene_despachos ? eliminar : undefined,
                                    editar: !row.tiene_despachos ? "solicitud_interna" : undefined,
                                    verpro: "solicitud_interna",
                                });
                            }}
                        >
                        </TableHeaderColumn>
                    }
                    <TableHeaderColumn
                        dataField="fecha"
                        isKey
                        dataSort
                        dataFormat = {(cell, row) => {
                            return <RenderDateTime fecha={cell} />
                        }}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_orden"
                        dataSort
                    >
                        NO. ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="emitido_por"
                        dataSort
                        dataFormat = {(cell, row) => cell.username }
                    >
                        USUARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor"
                        dataSort
                        dataFormat = {
                            (cell, row) => {
                                return(
                                    <div>
                                        {
                                            (row.empresa_origen && row.empresa_destino) && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Origen: </span><span>{row.empresa_origen.nombre}</span><br/>
                                                    <span className="font-weight-bold">Destino: </span><span>{row.empresa_destino.nombre}</span><br/>
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }
                        }
                    >
                        EMPRESAS
                    </TableHeaderColumn>
                    {
                        permisos.ingresos_sim &&
                        <TableHeaderColumn
                        dataField="monto_SI"
                        dataSort
                        dataFormat = {
                            (cell, row) => (
                                <RenderMoneda
                                    monto={row.monto_SI ? cell : 0}
                                    simbolo={getsimbolo(row.moneda)}
                                />
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                    }
                    <TableHeaderColumn
                        dataField="entregado"
                        dataSort
                        // tdStyle={cellTachado}
                        dataFormat = {
                            (cell, row) => {
                                if(cell){
                                    return (<span className="t-verde font-weight-bold">Entregada</span>)
                                }else{
                                    return(<span className="t-corinto font-weight-bold">Pendiente</span>)
                                }
                            }

                        }
                    >
                        DESPACHADO
                </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
