import React, {Component, Fragment} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Grid from  '../../../Utils/Grid/index';
import Modal from 'react-responsive-modal';
import ChequesForm from './chequesForm';
import CardLiquidacionesPendientes from '../../../Utils/Cards/cardsLiquidaciones';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import ConvertirChequeForm from './covertirCheque';
import { valores_tipo_transacciones as VALORES_TIPO_TRANSACCIONES } from '../../../../../utility/constants';


const chequeAefectivo = require('../../../../../../assets/img/icons/chequeAefectivo.png');

class liquidacionesGrid extends Component{
    state = {
        openhistory: true,
        modalChequeEfectivo:false,
        transaccion_sel: null,
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
            else{
                simbolo="Q"
            }
        })
        return simbolo + '. '
    }
    componentDidMount = ()=>{
        let { listar, page, verPendientes}=this.props
        listar(page, this.props.id)
        verPendientes()

    }

    setOpenModal = () => {
        this.props.setOpenModal(true, this.props.id)
    }
    setOpenModalTarjeta = () => {
        this.props.setOpenModalTarjeta(true, this.props.id, [VALORES_TIPO_TRANSACCIONES.tarjeta])
    }
    setOpenModalCheque = () => {
        this.props.setOpenModalCheque(true, this.props.id, [VALORES_TIPO_TRANSACCIONES.cheque])
    }
    setOpenModalTransaccion = () => {
        this.props.setOpenModalTransaccion(true, this.props.id, [VALORES_TIPO_TRANSACCIONES.electronica, VALORES_TIPO_TRANSACCIONES.deposito])
    }
    setOpenModalEfectivo = () => {
        this.props.setOpenModalEfectivo(true, this.props.id, [VALORES_TIPO_TRANSACCIONES.efectivo])
    }

    setCloseModal = () => {
        this.props.setOpenModal(false)
    }
    setCloseModalTarjeta = () => {
        this.props.setOpenModalTarjeta(false)
    }
    setCloseModalCheque = () => {
        this.props.setOpenModalCheque(false)
    }
    setCloseModalTransaccion = () => {
        this.props.setOpenModalTransaccion(false)
    }
    setCloseModalEfectivo = () => {
        this.props.setOpenModalEfectivo(false)
    }

    submit = (values) => {
        this.props.crear(values, this.props.id);
    }
    botonChequeEfectivo = (id, row) => {

        if (row.tipo_documento != 2 || row.flotante == false) {
            return (<div></div>)
        }
        if (row.estado){
            return (
                <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                    onClick={(e) => {
                        e.preventDefault();
                        console.log(row);
                        this.setState({
                            modalChequeEfectivo: true,
                            // id_transaccion:row.id,
                            transaccion_sel:row,
                        });
                    }}
                >
                    <img
                        // className="action-img"
                        style={{width:"24px", height:"24px"}}
                        title="Cobrar cheque"
                        src={chequeAefectivo} alt="Ver eliminado" />
                </a>
            )
        }
    }
    cerrarModal(){
        this.setState({modalChequeEfectivo:false})
    }
    renderModalChequeEfectivo = () => {
        // const { open_modal_pagos, cuentas, simbolo, orden, data } = this.props;
        // let no_cuenta = cuentas.length ? cuentas[0].id : null;
        // let es_cobro = data.activar_boton;
        return (
            <Fragment>
                <Modal open={this.state.modalChequeEfectivo} onClose={(e) => {
                    this.cerrarModal(false)
                }} center>
                    <ConvertirChequeForm
                        titulo_modal="COBRO DE CHEQUE"
                        initialValues={{
                            tipo_cuenta:"false",
                            fecha: new Date(),
                            // numero_cuenta: no_cuenta,
                            monto: this.state.transaccion_sel ? this.state.transaccion_sel.monto: 0,
                            descripcion: this.state.transaccion_sel ? this.state.transaccion_sel.descripcion: ""
                        }}
                        cerrarModal={this.cerrarModal}
                        onSubmit={(values) => {
                            this.props.abonarEfectivoCheque(
                                this.state.transaccion_sel.cuenta,
                                true,
                                this.state.transaccion_sel.afectado_cobro,
                                this.props.match.params.id
                            );
                            this.cerrarModal(false)

                        }}
                    />
                </Modal>

            </Fragment>
        )
    }
    render(){
        let {data, onPageChange, page, loader, item, open_modal, permisos,
            open_modal_tarjeta, open_modal_cheque, open_modal_transaccion, open_modal_efectivo
         } = this.props
        return(
            <div>
                {
                    permisos['liquidacion_pendientes'] == true && (
                        <div className="col-12">
                            <CardLiquidacionesPendientes
                                data={this.props.pendientes}
                                id = {this.props.id}
                                loader={loader}
                                titulo={"LIQUIDACIONES PENDIENTES"}
                                />
                        </div>
                    )
                }

                <Modal open={open_modal} onClose={this.setCloseModal} center>
                        <div className="mt-0">
                        <h2 className="text-center text-primary">LIQUIDAR CHEQUES</h2>
                        </div>
                        <LoadMask loading={this.props.loader_formulario} blur>
                            <ChequesForm
                                setCloseModal = {this.setCloseModal}
                                onSubmit = { this.submit }
                                meProfile={this.props.meProfile}
                            />
                        </LoadMask>
                </Modal>
                <Modal open={open_modal_tarjeta} onClose={this.setCloseModalTarjeta} center>
                        <div className="mt-0">
                        <h2 className="text-center text-primary">LIQUIDAR TARJETAS</h2>
                        </div>
                        <LoadMask loading={this.props.loader_formulario} blur>
                            <ChequesForm
                                setCloseModal = {this.setCloseModalTarjeta}
                                onSubmit = { this.submit }
                                meProfile={this.props.meProfile}
                                tipo_documento={VALORES_TIPO_TRANSACCIONES.tarjeta}
                            />
                        </LoadMask>
                </Modal>
                <Modal open={open_modal_cheque} onClose={this.setCloseModalCheque} center>
                        <div className="mt-0">
                        <h2 className="text-center text-primary">LIQUIDAR CHEQUES</h2>
                        </div>
                        <LoadMask loading={this.props.loader_formulario} blur>
                            <ChequesForm
                                setCloseModal = {this.setCloseModalCheque}
                                onSubmit = { this.submit }
                                meProfile={this.props.meProfile}
                                tipo_documento={VALORES_TIPO_TRANSACCIONES.cheque}
                            />
                        </LoadMask>
                </Modal>
                <Modal open={open_modal_transaccion} onClose={this.setCloseModalTransaccion} center>
                        <div className="mt-0">
                        <h2 className="text-center text-primary">LIQUIDAR TRANSACCIONES</h2>
                        </div>
                        <LoadMask loading={this.props.loader_formulario} blur>
                            <ChequesForm
                                setCloseModal = {this.setCloseModalTransaccion}
                                onSubmit = { this.submit }
                                meProfile={this.props.meProfile}
                                tipo_documento={VALORES_TIPO_TRANSACCIONES.deposito}
                            />
                        </LoadMask>
                </Modal>
                <Modal open={open_modal_efectivo} onClose={this.setCloseModalEfectivo} center>
                        <div className="mt-0">
                        <h2 className="text-center text-primary">LIQUIDAR EFECTIVO</h2>
                        </div>
                        <LoadMask loading={this.props.loader_formulario} blur>
                            <ChequesForm
                                setCloseModal = {this.setCloseModalEfectivo}
                                onSubmit = { this.submit }
                                meProfile={this.props.meProfile}
                                tipo_documento={VALORES_TIPO_TRANSACCIONES.efectivo}
                            />
                        </LoadMask>
                </Modal>

                {this.renderModalChequeEfectivo()}

                <div className="row">
                    <div className="col-md-10 row pl-0 ml-0 pl-5 mb-4 mt-4 ml-md-1 justify-content-start">
                        <div className="col-md-auto mt-3">
                            <span className="t-musgo font-weight-bold">
                                TOTAL EN EFECTIVO
                            </span>
                            <h3 className="t-azul m-none">{ <RenderMoneda monto={data.totalEfectivo} simbolo={this.getsimbolo(data.moneda)} />}</h3>
                        </div>
                        <div className="col-md-auto mt-3">
                            <span className="t-musgo font-weight-bold">
                                TOTAL EN CHEQUES
                            </span>
                            <h3 className="t-primary m-none">{ <RenderMoneda monto={data.totalCheques} simbolo={this.getsimbolo(data.moneda)} />}</h3>
                        </div>
                        <div className="col-md-auto mt-3">
                            <span className="t-musgo font-weight-bold">
                                TOTAL EN DEPOSITOS
                            </span>
                            <h3 className="t-mostaza m-none">{ <RenderMoneda monto={data.totalDepositos} simbolo={this.getsimbolo(data.moneda)} />}</h3>
                        </div>
                        <div className="col-md-auto mt-3">
                            <span className="t-musgo font-weight-bold">
                                TOTAL EN TARJETA
                            </span>
                            <h3 className="t-azul m-none">{ <RenderMoneda monto={data.totalTarjeta} simbolo={this.getsimbolo(data.moneda)} />}</h3>
                        </div>
                        <div className="col-md-auto mt-3">
                            <span className="t-musgo font-weight-bold">
                                TOTAL EN ELECTRONICA
                            </span>
                            <h3 className="t-primary m-none">{ <RenderMoneda monto={data.totalElectronica} simbolo={this.getsimbolo(data.moneda)} />}</h3>
                        </div>
                    </div>
                    {/*
                    <div className="col-md-2 row p-0 mb-4 mt-4 justify-content-end align-self-end">
                        <button type="button" className="btn btn-primary" onClick = { this.setOpenModal } >
                            LIQUIDAR
                        </button>
                    </div>
                    */}
                </div>

                <div className="row">
                    <div className="col-md-12 row pl-0 ml-0 pl-5 mb-4 mt-4 ml-md-1 justify-content-start">
                        <div className="col-md-auto mt-3">
                            <button type="button" className="btn btn-primary" onClick = { this.setOpenModalTarjeta } >
                                TARJETA
                            </button>
                        </div>
                        <div className="col-md-auto mt-3">
                            <button type="button" className="btn btn-primary" onClick = { this.setOpenModalCheque } >
                                CHEQUE
                            </button>
                        </div>
                        <div className="col-md-auto mt-3">
                            <button type="button" className="btn btn-primary" onClick = { this.setOpenModalTransaccion } >
                                TRANSACCIÓN
                            </button>
                        </div>
                        <div className="col-md-auto mt-3">
                            <button type="button" className="btn btn-primary" onClick = { this.setOpenModalEfectivo } >
                                EFECTIVO
                            </button>
                        </div>
                    </div>
                </div>

                <br/>

                <Grid hover striped data={data} loading={loader} page={page} pagination={false} onPageChange={onPageChange}  >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={
                            standardActions({
                                vermodal: this.props.setOpenModalHistory,
                                adicional2: this.botonChequeEfectivo,
                            })
                        }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat = {
                            (cell,row)=>(
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre_cliente"
                        dataSort
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="label_tipo_transaccion"
                        dataSort
                        dataFormat ={
                            (cell) => (
                                cell
                            )
                        }
                    >
                        TIPO DE DOCUMENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_documento"
                        dataSort
                        dataFormat ={
                            (cell) => (
                                cell
                            )
                        }
                    >
                        NO. DOCUMENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={'Q. '} />
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>

                <br/>
            </div>
        )
    }
}

export default withRouter(liquidacionesGrid);
