import React, { Component, Fragment } from 'react'
import Select, { Creatable, Async } from 'react-select';
import { SingleDatePicker } from 'react-dates';
import { Field } from 'redux-form';
import { renderField, AsyncSelectField } from '../../Utils/renderField';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)

import classNames from 'classnames';
import moment from 'moment';
import { api } from 'api';


const getClientes = (search, props) => {
    const params ={}
    params.search=search
    params.esCliente = true
    if(props.permisos.visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = props.id_user
    }
    return api.get("clientes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

export default class ToolbarReporteDespachosBancos extends Component {

    format = (array=[]) =>{
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }

    render() {
        const { reporte, cliente, despacho, empresas, fecha_inicio, fecha_fin, changeFechaInicial, changeFechaFinal,
            changeCliente, changeEmpresa
        } = this.props;

        return (
            <div className="row col-12 p-0">
                <div className="row col-12 m-0 text-lg-left">
                    {
                        reporte == 1 && (
                            <Fragment>
                                <div className={"col-lg-3 col-md-3 col-12 align-self-center d-flex flex-column"}>
                                    <span className="t-azul-claro font-weight-bold">Fecha Inicial</span>
                                    <DatePicker
                                        selected={new Date(fecha_inicio)}
                                        onChange={value => {
                                            changeFechaInicial(moment(value))
                                        }}
                                        className="form-control text-date d-flex flex-column flex-1"
                                        dateFormat="MMMM/yyyy"
                                        locale="es"
                                        showMonthYearPicker
                                    />
                                </div>
                                <div className={"col-lg-3 col-md-3 col-12 align-self-center d-flex flex-column"}>
                                    <span className="t-azul-claro font-weight-bold">Fecha Final</span>
                                    <DatePicker
                                        selected={new Date(fecha_fin)}
                                        onChange={value => {
                                            changeFechaFinal(moment(value))
                                        }}
                                        className="form-control text-date d-flex flex-column flex-1"
                                        dateFormat="MMMM/yyyy"
                                        locale="es"
                                        showMonthYearPicker
                                    />
                                </div>
                                <div className={"col-lg-3 col-md-3 col-12 align-self-center"}>
                                    <span className="t-azul-claro font-weight-bold">Cliente</span>
                                    <Async
                                        isClearable={true}
                                        backspaceRemovesValue={false}
                                        value={cliente}
                                        isSearchable={true}
                                        loadOptions={(search) => getClientes(search, this.props)}
                                        getOptionValue={(option) => (option["id"])}
                                        getOptionLabel={(option) => (option["nombre"])}
                                        type="text"
                                        onChange={(e) => changeCliente(e)}
                                        multi={true}
                                        autoload={false}
                                        cache={false}
                                        className={classNames('react-select-container ')}
                                        defaultOptions={true}
                                        placeholder="Seleccionar.."
                                    />
                                </div>
                            </Fragment>
                        )
                    }
                    {
                        reporte == 2 && (
                            <div className={"col-lg-3 col-md-3 col-12 align-self-center"}>
                                <span className="t-azul-claro font-weight-bold">Empresa</span>
                                <Select
                                    isClearable={true}
                                    className={classNames('react-select-container')}
                                    backspaceRemovesValue={true}
                                    isSearchable={true}
                                    options={this.format(empresas? empresas:[])}
                                    placeholder={"Empresa"}
                                    // value={null}
                                    onChange={(e, action)=>{changeEmpresa(e)}}
                                />
                            </div>
                        )
                    }
                    {
                        reporte == 3 && (
                            <Fragment>
                                <div className={"col-lg-3 col-md-3 col-12 align-self-center d-flex flex-column"}>
                                    <span className="t-azul-claro font-weight-bold">Fecha Inicial</span>
                                    <DatePicker
                                        selected={new Date(fecha_inicio)}
                                        onChange={value => {
                                            changeFechaInicial(moment(value))
                                        }}
                                        className="form-control text-date d-flex flex-column flex-1"
                                        dateFormat="MMMM/yyyy"
                                        locale="es"
                                        showMonthYearPicker
                                    />
                                </div>
                                <div className={"col-lg-3 col-md-3 col-12 align-self-center d-flex flex-column"}>
                                    <span className="t-azul-claro font-weight-bold">Fecha Final</span>
                                    <DatePicker
                                        selected={new Date(fecha_fin)}
                                        onChange={value => {
                                            changeFechaFinal(moment(value))
                                        }}
                                        className="form-control text-date d-flex flex-column flex-1"
                                        dateFormat="MMMM/yyyy"
                                        locale="es"
                                        showMonthYearPicker
                                    />
                                </div>
                                <div className={"col-lg-3 col-md-3 col-12 align-self-center"}>
                                    <span className="t-azul-claro font-weight-bold">Cliente</span>
                                    <Async
                                        isClearable={true}
                                        backspaceRemovesValue={false}
                                        value={cliente}
                                        isSearchable={true}
                                        loadOptions={(search) => getClientes(search, this.props)}
                                        getOptionValue={(option) => (option["id"])}
                                        getOptionLabel={(option) => (option["nombre"])}
                                        type="text"
                                        onChange={e => {
                                            changeCliente(e);
                                        }}
                                        multi={true}
                                        autoload={false}
                                        cache={false}
                                        className={classNames('react-select-container ')}
                                        defaultOptions={true}
                                        placeholder="Seleccionar.."
                                    />
                                </div>
                            </Fragment>
                        )
                    }
                </div>
            </div>
        )
    }
}
