import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

export default class EmpresaGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { ListarEmpresasProduccion, page } = this.props;
        ListarEmpresasProduccion();
    }

    render(){
        const { produccionEmpresa, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos["orden_produccion"] === false) {
            if(!!permisos[`ver_orden_produccion`] === false) {
                return <Redirect to={`/`} />
            }
        }
        return(
            <div className="Container bg-white">
                <h3 className="t-primary m-t p-t">PRODUCCIÓN</h3>
                <div className="row justify-content-end">
                    <div className="col-md-8 col-12">
                        {/* <ToolbarSimple
                            // textBoton="AGREGAR"
                            ruta="/empresa/nuevo"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre"}
                            permisos={permisos.empresas}
                        /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid hover striped data={{count:10, results:produccionEmpresa}} loading={loader}  onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} >
                                   {(permisos.orden_produccion || permisos.ver_orden_produccion) &&( <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataSort
                                        dataFormat={
                                            standardActions({
                                                verproduccion:"produccion",
                                            })
                                        }
                                    >
                                    </TableHeaderColumn>)}
                                    <TableHeaderColumn
                                        isKey
                                        dataField="nombre"
                                        dataSort
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="produccion"
                                        dataSort
                                        dataAlign="right"
                                        tdStyle={{paddingRight: "5%"}}
                                        thStyle={{paddingRight: "5%"}}
                                    >
                                        ORDENES PENDIENTES
                                    </TableHeaderColumn>

                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

