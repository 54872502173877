import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { addDays, subDays } from "date-fns";
import es from 'date-fns/locale/es';

registerLocale('es', es)

export function NewDatePicker({selected, onChange}) {
  const minFecha = subDays(new Date(), 5);
  const maxFecha = addDays(new Date(), 5);

  return (
    <div className="contenedor">
      <div className="center">
        <DatePicker
          selected={selected}
          onChange={onChange}
          minDate={minFecha}
          maxDate={maxFecha}
          locale="es"
          dateFormat="d-MM-yyyy"
          className="form-control"
        />
      </div>
    </div>
  );
}

export function NewDatePickerFree({selected, onChange, minDate, maxDate}) {
  maxDate = new Date(maxDate)
  minDate = new Date(minDate)

  var userTimezoneOffset = minDate.getTimezoneOffset() * 60000;

  maxDate = new Date(maxDate.getTime() + userTimezoneOffset)
  minDate = new Date(minDate.getTime() + userTimezoneOffset)

  return (
    <div className="contenedor">
      <div className="center">
        <DatePicker
          selected={selected}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          locale="es"
          dateFormat="d-MM-yyyy"
          className="form-control"
        />
      </div>
    </div>
  );
}
