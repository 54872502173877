import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';

let estado = [
    {id: true, value: true, label:"Activos"},
    {id: false, value: false, label: "Anulados"},
]

let estado_ac = [
    {id: false, value: false, label:"Abierto"},
    {id: true, value: true, label: "Cerrado"},
]

export default class ToolbarFiltroItinerarios extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
        
    }
    componentWillMount(){
    }
    render() {
        const {
            valorEstado,
            changeEstado,
            valorEstadoAC,
            changeEstadoAC
        } = this.props;
        return (
            <div className="row col-12 p-0">
                <div className="row col-12 m-0 text-lg-left mb-2">
                    <div className="col-lg-6 col-12">
                    <span className="t-azul-claro font-weight-bold">Fecha Inicial</span>
                        <SingleDatePicker
                        showClearDate={true}
                        className="form-control"
                        placeholder={"Fecha Inicio"}
                        date={this.props.dateStart ? moment(this.props.dateStart ) : null}
                        focused={this.state.focusedStart}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                        this.setState({dateStart:value})
                        this.props.setDateStart(value)
                        }}
                        onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                        numberOfMonths={1}
                        id={"dateStart"}
			            readOnly = { true }
                    />
                    </div>
                    <div className="col-lg-6 col-12">
                    <span className="t-azul-claro font-weight-bold">Fecha Final</span>
                        <SingleDatePicker
                        showClearDate={true}
                        className="form-control"
                        placeholder={"Fecha Final"}
                        date={this.props.dateEnd ? moment(this.props.dateEnd ) : null}
                        focused={this.state.focusedEnd}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                           this.setState({dateEnd:value})
                           this.props.setDateEnd(value);
                        }}
                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                        numberOfMonths={1}
                        id={"dateStart"}
			            readOnly = { true }
                    />
                    </div>
                </div>
                <div className="row col-12 m-0 text-lg-left mb-2">    
                    <div className="col-lg-6 col-12">
                        <span className="t-azul font-weight-bold">Abierto / Cerrado</span>
                        <Select
                                isClearable={true}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={estado_ac}
                                placeholder={"Estado"}
                                value={valorEstadoAC}
                                onChange={changeEstadoAC}
                            />
                    </div>
                    <div className="col-lg-6 col-12">
                         <span className="t-azul font-weight-bold">Estado</span>
                            <Select
                                isClearable={false}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={estado}
                                placeholder={"Estado"}
                                value={valorEstado}
                                onChange={changeEstado}
                            />
                    </div>
                </div>              
            </div>
        )
    }
}
