import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/despachosplanta/despachosplanta';
import DetalleDespachoPlanta from './DetalleDespachoPlanta';
import Facturacion from '../Facturas/index';

const mstp = state => {
    return {...state.despachosplanta, misdatos: state.despachosplanta, permisos: state.login.me.permisos, rol: state.login.me.profile.rol_name }
};
import React, { Component } from 'react';
const mdtp = {
    ...actions
};

//export default connect(mstp, mdtp)(DetalleDespachoPlanta)

const container = (props) => {
    const { misdatos } = props
    const [state, setState] = React.useState({ vistaFacturacion: false, data: null });

    return (
        state.vistaFacturacion === false ?
            <DetalleDespachoPlanta
                {...props}
                mostrarVista={(vistaFacturacion, index) => {
                    setState({
                        losdatos: misdatos.item.despachoplanta_detalle ? misdatos.item.despachoplanta_detalle[index] : [], vistaFacturacion, index
                    }
                    )
                }}
            />
            :
            <Facturacion
                {...props}
                losdatos={state.losdatos}
                mostrarVista={() => setState({ vistaFacturacion: false })} />
    )
}

export default connect(mstp, mdtp)(container)