import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import GastoForm from './GastoForm';
import moment from 'moment';


class GastoCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR GASTO',
    }
    componentWillMount(){
        //this.props.resetFormulario();
        this.props.getTipoGastos();
        this.props.selectEmpresas();
        this.props.getSelectCuentas()
    }
    onSubmit = (values) =>{
        const {crearDesdeReportes} =this.props
        let valores_formulario = _.cloneDeep(values)
        if(valores_formulario && valores_formulario.cliente){
            valores_formulario.cliente = valores_formulario.cliente.id
        }
        if(valores_formulario && valores_formulario.trabajador){
            valores_formulario.trabajador = valores_formulario.trabajador.id
        }
        if(valores_formulario && valores_formulario.proveedor){
            valores_formulario.proveedor = valores_formulario.proveedor.id
        }
        if (typeof valores_formulario.tipo_documento == 'undefined') {
            valores_formulario.tipo_documento = null
        }
        valores_formulario.fecha = moment(valores_formulario.fecha).format('YYYY-MM-DD')
        crearDesdeReportes(valores_formulario);
    }
    render() {
        const { loader, empresas, select_cuentas, cambioDescuento } = this.props;

        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <GastoForm
                        empresas={empresas}
                        select_cuentas={select_cuentas}
                        initialValues={{
                            desglose:[{}],
                            tipo_cuenta:"true",
                            fecha: new Date(),
                            pendiente_liquidar:false
                        }}
                        editar={this.state.editar}
                        cambioDescuento={cambioDescuento}
                        tipo_gastos={this.props.tipo_gastos}
                        tipo_gasto = {this.props.item}
                        onSubmit={this.onSubmit}/>
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default GastoCrear;
