import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";

// Manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import moment from 'moment';

import ToolbarReportePlanilla from '../../../Utils/Toolbar/ToolbarReporePlanilla';
import { Redirect } from 'react-router';

import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {standardActions} from '../../../Utils/Grid/StandardActions';



export default class PPlanillaporEmpleado extends Component {

    componentDidMount(){
        // this.props.getResumen();
    }

    render() {
        const { loader, resumen, permisos, page3, data3, ListarPagosEmpresas } = this.props;
        if(permisos.rep_pagoplanilla === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE DE PAGO DE PLANILLA POR EMPRESA">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReportePlanilla 
                                // {...this.props} 
                                changeEmpresa={this.props.changeEmpresaoPlanilla}
                                empresa={this.props.empresa}
                                fecha_inicio={this.props.fecha_inicio}
                                fecha_fin={this.props.fecha_fin}
                                changeFechaInicio={this.props.changeFechaInicio2}
                                changeFechaFin={this.props.changeFechaFin2}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-12 d-flex justify-content-center" >
                            <div >
                                <br/>
                                <h3 className="t-musgo">TOTAL PAGADO</h3>
                                <h3 className="t-primary m-0 text-center">
                                    <RenderMoneda monto={ this.props.totalizados.total } simbolo={'Q. '} />
                                </h3>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div  style={{border:"1px solid #E8E8E8", }}>

                        <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3">
                            <div className="col-md-6 col-12 d-flex justify-content-center">
                                <div>
                                    <br/>
                                    <h4 className="t-musgo">{"TOTAL SUELDO"}</h4>
                                    <h4 className="t-mostaza m-0 text-center bold">
                                        <RenderMoneda monto={ this.props.totalizados.total_sueldo } simbolo={'Q. '} />
                                    </h4>
                                    <br/>
                                </div>
                            </div>
                            <div className="col-md-6 col-12  d-flex justify-content-center" >
                                <div>
                                    <br/>
                                    <h4 className="t-musgo">TOTAL BONOS</h4>
                                    <h4 className="t-mostaza m-0 text-center bold">
                                        <RenderMoneda monto={ this.props.totalizados.total_bonos } simbolo={'Q. '} />
                                    </h4>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-center justify-content-center mt-3">
                            <div className="col-md-6 col-12 d-flex justify-content-center">
                                <div>
                                    <br/>
                                    <h4 className="t-musgo">{ "TOTAL ACTIVIDADES"}</h4>
                                    <h4 className="t-mostaza m-0 text-center bold">
                                        <RenderMoneda monto={ this.props.totalizados.total_actividades } simbolo={'Q. '} />
                                    </h4>
                                    <br/>
                                </div>
                            </div>
                            <div className="col-md-6 col-12  d-flex justify-content-center" >
                                <div>
                                    <br/>
                                    <h4 className="t-musgo">TOTAL PRODUCCION</h4>
                                    <h4 className="t-mostaza m-0 text-center bold">
                                        <RenderMoneda monto={ this.props.totalizados.total_produccion } simbolo={'Q. '} />
                                    </h4>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-center justify-content-center mt-3">
                            <div className="col-md-6 col-12  d-flex justify-content-center" >
                                <div>
                                    <br/>
                                    <h4 className="t-musgo">TOTAL DESCUENTOS</h4>
                                    <h4 className="t-mostaza m-0 text-center bold">
                                        <RenderMoneda monto={ this.props.totalizados.total_descuentos } simbolo={'Q. '} />
                                    </h4>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Grid
                            hover
                            striped
                            data={data3}
                            loading={loader}
                            page={page3}
                            className="reporte-table"
                            onPageChange={ListarPagosEmpresas}
                        >
                            {/* <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataSort
                                className='reporte-table'
                                dataFormat={(cell) => ""}
                                width="10%"
                            /> */}
                            <TableHeaderColumn
                                dataField="creado"
                                dataSort
                                className='reporte-table'
                                // width="14%"
                                dataFormat={(cell) => moment(cell).format('DD-MM-YYYY')}
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="empleado"
                                isKey
                                dataSort
                                className='reporte-table'
                                // width="A%"
                            >
                                EMPLEADO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_empleado"
                                dataSort
                                className='reporte-table'
                                // width="20%"
                                // dataAlign="right"
                                dataFormat={(cell, row) => {
                                    if (cell == 5 && row.tipo_pago == 2){
                                        return <span className="text-uppercase" >produccion pagada</span>
                                    }else{
                                        return <span className="text-uppercase" >{ row.label_tipo_pago }</span>
                                    }
                                }}
                            >
                                TIPO DE PAGO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="subtotal"
                                dataSort
                                className='reporte-table'
                                // width="20%"
                                dataAlign="right"
                                dataFormat={(cell, row) => (<RenderMoneda monto={ cell } simbolo={'Q'} />)}
                            >
                                MONTO
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                      
                </LoadMask>

            </CardSimple>
        )

    }
}
