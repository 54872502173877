import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import image from '../../../../../assets/img/ilustracion.png'
import Logo from '../../../../../assets/img/Logo.png'
class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="blue-gradient-bg  align-self-center align-items-center justify-content-center">
                <div className="col-lg-4 col-md-4  pt-3 bienvenida ">
                    {/* <h1 className="text-center">AyG</h1>
                            <p>Industrias</p> */}
                    <img className="imagen" src={image}></img>
                </div>

                <div className="login-wrapper col-lg-4 col-md-4 col-4 col-sm-6  ">
                    <div className="card card-login   justify-content-center align-self-center">
                        {/* <h5 className="text-center pv">INGRESAR</h5> */}
                        <img className="logo" src={Logo}></img>
                        <LoadMask loading={loader} light type={"TailSpin"}>
                            <LoginForm onSubmit={onSubmit} />
                            <div className="text-center">
                                <span>¿Olvidaste tú Contraseña?</span>
                                <br />
                                <span><Link to="/ResetPassword">Recuperar Crontraseña</Link></span>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
