import React, {Component} from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, AsyncSelectField } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';

import {api} from 'api';
import {RenderCurrency, RenderNumber} from "../../../Utils/renderField/renderReadField";

class ItemArray extends Component{
    state={
        producto: {},
    }
    setPrecioUnitario = (producto) => {
        const { todos, index, Despacho } = this.props;
        if(producto !== undefined && producto.cantidad_actual > 0){
            todos[index]['despachar'] = producto.cantidad_actual;
        }
    }
    render(){
        const {getProductosOrden, eliminar, index, Despacho, producto }= this.props;
        return(
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <span>{producto.producto.nombre}</span>
                </td>
                <td className="sin-borde-top" >
                <Field
                        name={`${Despacho}.cant_despacho`}
                        type="number"
                        // min="0" max="5"
                        component={renderField}
                        placeholder={"543"}
                        top ={{top:"67px", position:"inherit"}}
                    />
                    
                </td>
                <td className="sin-borde-top" >
                    {producto.producto.label_unidad_inventario}    
                </td>           
            </tr>
        )
    }
}
const renderProductos = ({fields, meta: {error, submitFailed }, getProductosOrden}) => (
        <div className=" col-sm-12 p-0">
            <div className=" col-sm-12 form-group np-r p-0">
                <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                    <table className="table table-sm table-responsive-sm m-0 table-striped">
                        <thead>
                        <tr>
                            <th ></th>
                            <th>Producto</th>
                            <th style={{width: "20%"}}>Cantidad despacho</th>
                        </tr>
                        </thead>
                        <tbody>
                            {fields.map((Despacho, index) => {
                                let todos = fields.getAll()
                                let producto = todos[index];
                                if(producto.cant_despacho > 0){
                                    return(
                                        <ItemArray
                                            index={index}
                                            key={index}
                                            Despacho={Despacho}
                                            todos={todos}
                                            producto={producto}
                                            getProductosOrden={getProductosOrden}
                                            eliminar={ () =>{
                                                fields.remove(index)
                                            }}
                                        />
                                    )
                                } 
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {submitFailed &&
                    error &&
                    <div className="invalid-feedback-array text-danger">
                            {error}
                    </div>}
        </div>
)
//fin de render de productos


class IngresoForm extends Component {
    getProductosOrden = (search) => {
        let params = {}
        if(search){
            params.search = search;
        }
        params.orden = this.props.orden
        return api.get(`ordenventa/getPendientesIngreso/${this.props.orden}`).
        then((data) => {
            if (data) return data;
            return [];
        }).catch(() => {
            return [];
        });
    };
    getBodega = (search) => {
        const {item} = this.props;
        return api.get("bodega", { search, empresa: item.empresa_destino.id}).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
    };
    getBodegaOrigen = (search) => {
        const {item} = this.props;
        return api.get("bodega", { search, empresa: item.empresa_origen.id}).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
    };
    render(){
        const { handleSubmit, orden } = this.props;
        return (
            <form name="IngresoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                        <label className="t-azul" htmlFor="bodega_origen">Bodega origen*</label>
                        <Field
                                    name={`bodega_origen`}
                                    component={AsyncSelectField}
                                    loadOptions={this.getBodegaOrigen}
                                    type="text"
                                    filterOptions= {false}
                                    valueKey="id"
                                    labelKey="nombre"
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                        <label className="t-azul" htmlFor="bodega_destino">Bodega destino*</label>
                        <Field
                                    name={`bodega_destino`}
                                    component={AsyncSelectField}
                                    loadOptions={this.getBodega}
                                    type="text"
                                    filterOptions= {false}
                                    valueKey="id"
                                    labelKey="nombre"
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-12">
                        <FieldArray
                            name="Despachos"
                            component={renderProductos}
                            getProductosOrden={this.getProductosOrden}
                        />
                    </div>
                </div>
                <br/>
                <div className="row  justify-content-sm-around justify-content-center">
                    <Link className="btn btn-secondary m-1 align-self-center" to={`/solicitud_interna/${orden}/detalle`} >CANCELAR</Link>
                    <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                </div>
            </form>
        );
    }
};


export default reduxForm({
    form: 'IngresoForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if(!data || !data.bodega_origen){
            errors.bodega_origen = 'Campo obligatorio.';
        }
        if(!data || !data.bodega_destino){
            errors.bodega_destino = 'Campo obligatorio.';
        }
        if (!data || !data.Despachos ){
            errors.Despachos = {_error:'Debe ingresar al menos un producto'}
        }else{
            const despachoArray = []
            data.Despachos.forEach( (producto, index)=>{
                let detErrors = {};
                let repetidos = [];
                if(producto.producto){
                    repetidos = _.filter(data.Despachos, (x) => {
                        if(x.producto)
                            return  x.producto.id == producto.producto.id
                        return false;
                        });
                }

                if(!producto.producto){
                    detErrors.producto =  "Campo requerido";
                }else {
                    if(1 < repetidos.length){
                        detErrors.producto = "Este producto está repetido"
                    }
                }
                if(!producto.cant_despacho){
                    detErrors.cant_despacho = "Campo requerido"
                }else {
                    if(parseFloat(producto.cant_despacho) <= 0){
                        detErrors.cant_despacho = "Debe ser mayor a 0"
                    }
                    if(parseFloat(producto.cant_despacho) > producto.cantidad_actual){
                        detErrors.cant_despacho = `Debe ser un maximo de ${producto.cantidad_actual}`
                    }
                }
                if(!producto.cant_conversion){
                    detErrors.cant_conversion = "Campo requerido";
                }else {
                    if(parseFloat(producto.cant_conversion) <= 0){
                        detErrors.cant_conversion = "Debe ser mayor a 0"
                    }
                }
                if(detErrors){
                    despachoArray[index] = detErrors;
                }

            });
            if(despachoArray.length){
                errors.Despachos = despachoArray
            }
        }
        return errors
    },
})(IngresoForm);

// const selector = formValueSelector('IngresoForm');
// IngresoForm = connect(state =>{
// })
