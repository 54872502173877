import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";

import ProduccionForm from './produccionForm';
import { NotificationManager } from "react-notifications";

class ProduccionNuevo extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        orden: false,
        titulo: 'NUEVA ORDEN DE PRODUCCION',
        values: {},
        estadoRead: false,
        tipo: 'N'
    }
    componentWillMount(){
        let direccion = this.props.match.path
        const { selectEmpresas, leerov, getRecetaOV } = this.props
        selectEmpresas();
        let orden = this.props.match.params.orden ? this.props.match.params.orden: null
        let id_empresa = this.props.match.params.empresa
        if (orden){
            console.log("Orden perro")
            this.setState({orden: true, tipo:"OV"})
            leerov(orden)
            getRecetaOV(orden, id_empresa)
        }
        if (direccion == "/produccion/:empresa/sim"){
            this.setState({orden: true, tipo:"SI"})
        }
        if(this.props.match.params.empresa){
            this.setState(
                {
                    values:{
                        orden:orden,
                        empresa:id_empresa,
                    },
                    estadoRead: true,
                }
            )
        }
    } 
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        // let id = this.props.match.params.id
        const produccion = values.produccion ? values.produccion : []
        
        let repetido = false;
        let i = 0;

        while( !repetido && i < produccion.length){
            let resp = produccion.filter( prod => prod.producto.id === produccion[i].producto.id )
            if( resp.length > 1) repetido = true
            i += 1;
        }

        if(!repetido) { crear(values, this.props.match.params.empresa) }
        else{ NotificationManager.error('Productos repetidos', 'ERROR') }
    }
    
    render() {
        const { loader, empresas, itemov } = this.props;
        return (
            <CardFormulario
                titulo={`${this.state.titulo}`}
            >
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br/>
                    <ProduccionForm 
                        onSubmit={this.onSubmit} 
                        empresas={empresas}
                        initialValues={{
                            empresa: this.state.values.empresa,
                            fecha_prox_entrega: new Date(),
                            fecha: new Date(),
                        }}
                        empresa={this.props.match.params.empresa}
                        estadoRead={this.state.estadoRead}
                        getRecetaOV={this.props.getRecetaOV}
                        leerov={this.props.leerov}
                        leersi={this.props.leersi}
                        bandera_orden={this.state.orden}
                        tipo_pro={this.state.tipo}
                        repetido={this.state.repetido}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default ProduccionNuevo;
