import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";

// Manejo de tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import moment from 'moment';

import ToolbarReporteAusencias from '../../../Utils/Toolbar/ToolbarReporteAusencias';
import { Redirect } from 'react-router';

import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {standardActions} from '../../../Utils/Grid/StandardActions';



export default class ReporteVehiculos extends Component {

    componentDidMount(){
        // this.props.getResumen();
        this.props.listarAusencias(1)
    }

    render() {
        const { loader, resumen, permisos, page, data, listarAusencias } = this.props;
        if(permisos.rep_ausencias === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple titulo="REPORTE DE AUSENCIAS">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-0"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="row col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}}>
                            <ToolbarReporteAusencias 
                                // {...this.props}
                                changeEmpleado={this.props.changeEmpleado}
                                changeEmpresa={this.props.changeEmpresao2}
                                empleado={this.props.empleado}
                                empresa={this.props.empresa}
                                fecha_inicio={this.props.fecha_inicio}
                                fecha_fin={this.props.fecha_fin}
                                changeFechaInicio={this.props.changeFechaInicio}
                                changeFechaFin={this.props.changeFechaFin}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                        style={{border:"1px solid #E8E8E8", }}>
                        <div className="col-12 d-flex justify-content-center" >
                            <div >
                                <br/>
                                <h3 className="t-musgo">TOTAL DE DÍAS DE AUSENCIA</h3>
                                <h3 className="t-primary m-0 text-center">
                                        {`${this.props.total_dias} día(s)`}
                                </h3>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <Grid
                            hover
                            striped
                            data={data}
                            loading={loader}
                            page={page}
                            className="reporte-table"
                            onPageChange={listarAusencias}
                        >
                            {/* <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataSort
                                className='reporte-table'
                                dataFormat={(cell) => ""}
                                width="10%"
                            /> */}
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                className='reporte-table'
                                // width="14%"
                                dataFormat={(cell) => moment(cell).format('DD-MM-YYYY')}
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="empleado"
                                dataSort
                                className='reporte-table'
                                // width="20%"
                                // dataAlign="right"
                                dataFormat={(cell) => `${cell.nombre} ${cell.apellido}`}
                                
                            >
                                EMPLEADO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="dias_ausencia"
                                isKey
                                dataSort
                                className='reporte-table'
                                // width="A%"
                                dataFormat={(cell, row) => (
                                    <div>
                                        <span style={{ color: "black" }} >{cell + ' día(s)' }</span>
                                    </div>
                                )}
                            >
                                AUSENCIA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="label_tipo_ausencia"
                                dataSort
                                className='reporte-table'
                                // width="20%"
                                dataAlign="right"
                                thStyle={{paddingRight: "5%"}}
                                tdStyle={{paddingRight: "5%"}}
                            >
                                TIPO DE AUSENCIAS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                className='reporte-table'
                                // width="20%"
                                dataAlign="right"
                                thStyle={{paddingRight: "5%"}}
                                tdStyle={{paddingRight: "5%"}}
                            >
                               DESCRIPCION
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>

            </CardSimple>
        )

    }
}
