
import React, { Component } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import flecha from '../../../../../../assets/img/icons/down arrow.png';
import { standardActions, customStandardActions2 } from "../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { Link, Redirect } from 'react-router-dom';
import { Fragment } from 'react';
import { forEach, toSafeInteger } from 'lodash';
import NotificacionSweetForm from '../../../Examples/Notificaciones/NotificacionSweetForm';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';
import CardSimple from '../../../Utils/Cards/cardSimple';
import { NotificationManager } from 'react-notifications';
import iconoFacturar from '../../../../../../assets/img/icons/facturar.png';
import AnularFacturaForm from './anularFacturaForm';

import flechaA from  '../../../../../../assets/img/icons/flecha_amarilla.png';
import flechaD from  '../../../../../../assets/img/icons/flecha_azul.png';
const CuentasproC = require('../../../../../../assets/img/icons/Cuentas.png');

import moment from 'moment';
import {api} from 'api';


class DetalleHistorialGrid extends Component {
    render(){
        const { data, loader, listar: onPageChange, onSortChange, } = this.props
        return(
            <Grid hover striped data={{count:10, results:data}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    width="2%"
                    dataFormat={cell => ""}
                    isKey
                />
                <TableHeaderColumn
                    dataField="mensaje_error"
                    dataSort
                >
                    MOTIVO ANULACIÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    width="15%"
                    dataFormat = {(cell, row)=> moment(cell).format("L") }
                >
                    FECHA
                </TableHeaderColumn>
            </Grid>
        )
    }
}


export default class GridDespachos extends Component {

    state = {
        despachoplanta_detalle: {
            results: null,
            count: 0
        },
        id_factura: null,
        historial_facturas: []
    }

    componentDidMount() {
        const { despachoplanta } = this.props;
        let count = despachoplanta.length
        this.setState({ despachoplanta_detalle: { results: despachoplanta, count: count } });
    }

    abrirModalAnularFactura = (id, row) => {
        this.props.setOpenModal(true);

        this.setState({
            id_factura: row.despacho.facturas_despacho[0]
        })
    }

    renderModalAnularFactura = () => {
        return (
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>
                {
                    (
                        <AnularFacturaForm
                            onSubmit={this.onSubmitAnularFactura}
                            cerrarModal={this.props.setOpenModal}
                        />
                    )
                }
            </Fragment>
        )
    }

    onSubmitAnularFactura = (values) => {
        values.id_factura = this.state.id_factura;

        Swal.fire({
            title: '¿Desea anular la factura?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularFacturaEP(values, this.props.match.params.id);
            }
        });
    }

    abrirModalHistorialFacturas = (id, row) => {
        api.get('factura/get_historial_facturas', {id_despacho: row.despacho.id}
        ).then((data) => {
            if (data) {
                this.setState({
                    historial_facturas: data.results
                })
            }
        }).catch(() => {
            this.setState({
                historial_facturas: []
            })
        });
        this.props.setOpenModalFacturasHistorial(true, row);
    }

    botonFactura = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.factura){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank" 
                    href={row.factura}
                    // onClick={(e)=>{
                    //     e.preventDefault();
                    //     this.setState({
                    //     id_transaccion: id,
                    //     transaccion_sel: row
                    // })
                    // this.props.setOpenModal(true);
                    // }}
                >
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/facturapdf.png")} alt="PDF"/>
                </a>
            )
        }else{return(<div></div>)}

    }

    botonXml = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.archivo_xml){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank" 
                    href={row.archivo_xml}
                    // onClick={(e)=>{
                    //     e.preventDefault();
                    //     this.setState({
                    //     id_transaccion: id,
                    //     transaccion_sel: row
                    // })
                    // this.props.setOpenModal(true);
                    // }}
                    download={`${row.archivo_xml}`}
                >
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/XML.png")} alt="PDF"/>
                </a>
            )
        }else{return(<div></div>)}

    }

    isExpandableRow(row) {
        if (row.estado_factura=='ANULADA') return true;
        else return false;
    }
    expandComponent(row) {
        row = [row]
        return (
            <DetalleHistorialGrid data={ row }/>
        );
    }

    renderModalHistorialFacturas = (props) => {
        const { historial_facturas } = this.state;

        return (
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">Historial de Facturas</h2>
                </div>
                <Grid hover
                    striped data={{count:10,results:historial_facturas}}
                    loading={props.loader}
                    pagination={false}
                    expandableRow={ this.isExpandableRow }
                    expandComponent={ this.expandComponent }
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={
                            (cell, row)=>{
                                return row.estado_factura=='ACEPTADA' || row.estado_factura=='ANULADA' || row.estado_factura=='EXTERNA' ?
                                standardActions({
                                    // eliminar:this.handleDelete
                                    adicional:this.botonFactura,
                                    adicional2:this.botonXml,
                                })(cell,row) : (row.estado_factura==2 ? 'FALLIDA' : 'PENDIENTE')
                            }
                        }
                    > </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nit"
                        dataSort
                    >
                        NIT
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero"
                        dataSort
                    >
                        No. FACTURA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        dataFormat ={
                            (cell, row)=>(
                                <RenderMoneda monto={cell? cell : 0} simbolo={'Q'} />
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado_factura"
                        dataSort
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }

    botonImprimirDespacho = (id, row) => {
        return (
            <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.props.PDFDESPACHO(row.despacho.id)
                }}
            >
                <img
                    className="action-img"
                    title="Imprimir Despacho"
                    src={require("../../../../../../assets/img/icons/imprimir.png")} alt="Imprimir Despacho" />
            </a>
        )
    }
    botonFacturar = (id, row, cont) => {
        return (
            <td className="sin-borde-topzz">
                <img className="action-img" alt="Facturacion" title="Facturación" src={iconoFacturar} onClick={() => this.props.mostrarVista(true, cont - 1)}></img>
            </td>
        )
    }

    botonImprimirFactura = (id, row) => {
        return (
            <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                target="_blank"
                onClick={(e) => {
                    if (row.despacho.facturas_despacho[0]) {
                        row.despacho.orden_detalle.facturas.forEach((item) => {
                            if (item.id == row.despacho.facturas_despacho[0]) {
                                window.open(item.factura, "_blank")
                            }
                        })
                    } else {
                        NotificationManager.error("Este Despacho no esta facturado")
                    }
                }}
            >
                <img
                    className="action-img"
                    title="PDF Factura"
                    src={require("../../../../../../assets/img/icons/facturapdf.png")} alt="PDF Factura" />
            </a>
        )
    }

    render() {
        const { paraCliente, loader, page, onPageChange, open_modal, open_modal_historial, onSortChange, despachoplanta, permisos, rol } = this.props;
        let cont = 0

        return (
            <Fragment>

                <Modal open={open_modal} onClose={(e) => {this.props.setOpenModal(false)}} center>
                    {this.renderModalAnularFactura()}
                </Modal>

                <Modal open={open_modal_historial} onClose={(e) => {this.props.setOpenModalFacturasHistorial(false)}} center>
                    {this.renderModalHistorialFacturas(this.props)}
                </Modal>

                <div className="row m-none">
                    <div className="col-12">
                        <Grid hover
                            pagination={false}
                            striped data={this.state.despachoplanta_detalle}
                            loading={loader}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                dataSort
                                dataFormat={(cell, row, index) => {
                                    cont +=1
                                    return customStandardActions2(cell, row,{
                                        adicional2: row.despacho.facturas_despacho.length > 0 | (permisos.visualizar_mis_datos && rol != "VENDEDOR/SUPERIOR" ) ? undefined : this.botonFacturar.bind(this, row.id, row, cont),
                                        adicional3: this.botonImprimirDespacho,
                                        adicional: row.despacho.facturas_despacho.length > 0 ? this.botonImprimirFactura : undefined,
                                        anularFacturaModal: row.despacho.facturas_despacho.length > 0 && permisos.anular_factura ? this.abrirModalAnularFactura : undefined,
                                    });
                                }}
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="sucursal"
                                dataSort
                                dataFormat={
                                    (cell, row) => (row ? row.detalle_sucursal.direccion : '--')
                                }
                            >
                                SUCURSAL
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="despacho"
                                dataSort
                                dataFormat={
                                    (cell, row) => (cell ? cell.no_despacho : '--')
                                }
                            >
                                DESPACHO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="despacho"
                                dataSort
                                dataFormat={(cell, row, index) => {
                                    return customStandardActions2(cell, row,{
                                        verpagosModal: this.abrirModalHistorialFacturas,
                                    });
                                }}
                            >
                            HISTORIAL FACTURAS
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>

                <br />
                <div className="row m-none">
                    <div className="col-12">
                        <div className="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <Link className="btn btn-secondary  align-self-center" to="/despachosplanta" >CANCELAR</Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
