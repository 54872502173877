import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import AusenciasForm from './ausenciasForm';
import moment from 'moment';
import { NotificationManager} from "react-notifications";
import { NonceProvider } from 'react-select';

class AsignacionesCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };

    state ={
        editar: false,
        titulo: 'REPORTAR AUSENCIAS',
    }

    componentWillMount(){
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR TIPO PRODUCTO'
            });
        }
    }

    getBusinessDays = (startDate, endDate) => {
        var startDateMoment = moment(startDate);
        var endDateMoment = moment(endDate)
        var days = endDateMoment.diff(startDateMoment, 'days') 
        days ++;
        while (startDateMoment <= endDateMoment) {
            if (startDateMoment.isoWeekday() === 7) {
                days --;
            }
            startDateMoment = startDateMoment.add(1, 'days');
        }
        return days;
    }
    crear = (values) => {
        const {crear, editar} = this.props;
        let detalle = [];
        let cant_dias =  1;
        if (values.dia == "true"){ 
            values.dia = true;
            cant_dias = this.getBusinessDays(values.fecha, values.fecha);
        }
        else{
            values.dia = false ;
            cant_dias = this.getBusinessDays(values.fecha, values.fecha_final);
        }
        if (cant_dias <= 0) {
             NotificationManager.error('El dia corresponde a un fin de semana', 'ERROR')
        }else{
            if (values.ausencias){
                for (const item of values.ausencias) {
                    detalle.push({
                        empresa: item.empleado.empresa.id,
                        empleado: item.empleado.id,
                        tipo_ausencia: item.tipo_ausencia,
                        dias_ausencia: item.dias_ausencia,
                        fecha: moment(values.fecha).format("YYYY-MM-DD"),
                        fecha_final: !values.dia ? moment(values.fecha_final).format("YYYY-MM-DD"): null,
                        descripcion: item.descripcion,
    
                    })
                }
                let body = {
                    detalle: detalle,
                    cant_dias: cant_dias,
                    option: values.dia,
                }
                console.log("body ", body)
                crear(body);
            } else {
                NotificationManager.error('Ingrese al menos un registro', 'ERROR');
            }
        }

    
    }

    render() {
        const { loader } = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <AusenciasForm onSubmit={this.crear} initialValues={{dia:"true", fecha: moment(), fecha_final: moment()}} />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default AsignacionesCrear;
