import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from "Utils/Grid/StandardActions";
import { RenderMoneda, RenderDateTime } from "Utils/renderField/renderTableField";
import { TIPOS_DESPACHOS } from '../../../../../utility/constants'
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

export default class HistoricoGrid extends Component {
    componentDidMount() {
        this.props.getHistoria(1, this.props.id_bodega);

    }

    pageChange = (page) => {
        this.props.getHistoria(page, this.props.id_bodega);
    }

    render() {
        const { historia_movimientos, loader, onSortChange, eliminar, page2 } = this.props;

        return (
            <Fragment>
                <Grid
                    data={historia_movimientos}
                    page={page2}
                    loading={loader}
                    onPageChange={this.pageChange}
                    onSortChange={onSortChange}
                    hover
                    striped
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        // dataSort
                        isKey
                        hidden
                        dataFormat={
                            standardActions({ editar: "e", eliminar })
                        }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        // dataSort
                        dataFormat={
                            (cell, row) => (
                                <RenderDateTime fecha={cell} />
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_despacho"
                        // dataSort
                    >
                        NO DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despachado_por"
                        // dataSort
                        dataFormat={(cell, row) => (
                            <span>{cell.first_name}</span>

                        )
                        }
                    >
                        DESPACHADO POR
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_movimiento"
                        // dataSort
                        dataFormat={(cell) => {
                            const item = _.find(TIPOS_DESPACHOS, { value: cell });
                            return item ? item.label : 'Desconocido'
                        }}
                    >
                        TIPO MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="bodega_origen"
                        // dataSort
                        dataFormat={(cell, row) => (
                            <span>{cell ? cell.nombre : ""}</span>
                        )
                        }
                    >
                        ORIGEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="bodega_destino"
                        // dataSort
                        dataFormat={(cell, row) => (
                            <span>{cell ? cell.nombre : ""}</span>
                        )
                        }
                    >
                        DESTINO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
