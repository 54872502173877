import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { standardActions } from '../../../Utils/Grid/StandardActions';


export default class PagosItinerarioGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }
    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if (permisos.pagos_itinerarios === false) {
            if (!!permisos.ver_pagos_itinerarios === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <div className="Container bg-white">
                <h3 className="t-primary m-t p-t">PAGOS ITINERARIO</h3>
                <div className="row justify-content-end">
                    <div className="col-md-8 col-12">
                        <ToolbarSimple
                            textBoton="AGREGAR"
                            ruta="/pagos_itinerario/nuevo"
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre"}
                            permisos={permisos.pagos_itinerarios}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange} onSortChange={onSortChange} >
                                    {
                                        (permisos.pagos_itinerarios) ? (
                                            <TableHeaderColumn
                                                dataField="id"
                                                dataAlign="center"
                                                dataSort
                                                width="20%"
                                                dataFormat={
                                                    standardActions({
                                                        editar: "pagos_itinerario",
                                                        eliminar
                                                    })
                                                }
                                            />
                                        ) : (
                                            <TableHeaderColumn
                                                dataField="id"
                                                dataAlign="center"
                                                dataSort
                                                width="2%"
                                                dataFormat={cell => ""}
                                            />
                                        )
                                    }

                                    <TableHeaderColumn
                                        isKey
                                        dataField="nombre"
                                        dataSort
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="monto"
                                        dataSort
                                        dataFormat={
                                            (cell, row) => (
                                                <RenderMoneda monto={cell ? cell : 0} simbolo={"Q. "} />
                                            )
                                        }
                                    >
                                        MONTO
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

