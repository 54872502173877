import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import LoadMask from "../LoadMask/LoadMask";
import ImageVer from '../../../../../assets/img/icons/Ver.png';
import { RenderDateTime } from "../../Utils/renderField/renderTableField";


export default class CardLiquidacionesPendientes extends Component {
    render() {
        const { data, loader } = this.props;
        return (
            <div className="">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br/>
                    <h5 className="t-primary">{ data && (data.length > 0 ? this.props.titulo: "")}</h5>
                    <div className="row d-flex justify-content-center">
                            <div className="row col-12 d-flex justify-content-around">
                                {
                                    data &&
                                    (data.map((item,index)=>{
                                        return(
                                            <div
                                            key={index}
                                            className="card col-lg-3 col-md-4 col-sm-6 col-12 pmcard"
                                            >
                                                <div className="text-center">
                                                    <h5 className="t-primary text-align-center">LIQUIDACION</h5>
                                                </div>
                                                <div className="col-12">
                                                    <span style={{color:"#f3a631"}} >Fecha: </span>
                                                    <span><RenderDateTime fecha={item.fecha} /></span><br/>
                                                    <span style={{color:"#f3a631"}}>Vendedor: </span><br/>
                                                    <span>{item.vendedor &&  (`${item.vendedor.nombre}`)}</span><br/>
                                                    <span style={{color:"#f3a631"}}>Monto: </span>
                                                    <span>{item.monto && `Q. ${item.monto}`}</span><br/>
                                                        <div className="pmiconLiquidaciones">
                                                            <Link to={`/caja/${this.props.id}/liquidacion/${item.id}`} >
                                                                <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                                                            </Link>
                                                        </div>
                                                </div>
                                            </div>
                                        )
                                    }))
                                }
                            </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}
