import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/Reporte/reportePilotos';
import ReportePilotos from './ReportePilotos';

const mstp = state => {
    return {
        ...state.reportePilotos,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReportePilotos)
