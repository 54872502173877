import React, { Component } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";
// import {AsyncSelectField} from '../../../Utils/renderField';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import ToolbarReporteCuentas from '../../../Utils/Toolbar/ToolbarReporteCuentas'
import { api } from 'api';
// grid expandible
import moment from 'moment';
// import flechaA from  '../../../../../../assets/img/icons/flecha_amarilla.png';
// import flechaD from  '../../../../../../assets/img/icons/flecha_azul.png';

export default class OrdenesPorPoC extends Component {
    state = {
        es_cliente:this.props.es_cliente,
        cliente: null
    }
    componentWillMount() {
        let idclipro = this.props.match.params.idclipro;
        let params = {}
        if (this.props.es_cliente)
            params.esCliente = true
        return api.get(`clientes/${idclipro}`, params).then((data) => {
            if (data) this.props.changeCliente(data)
        }).catch(() => {
            this.props.changeCliente(null)
        });
    }
    handleEstado = (value) =>{
        let idclipro = this.props.match.params.idclipro;
        this.setState({es_cliente: value})
        this.props.cuentasporCOP({
            clienteproveedor: idclipro,
            vendedor: null,
            estado: 1,
            moneda: 'GTQ',
            tipo_cambio_dolar:  0.13,
            tipo_cambio_euro:  0.12,
        }, value, 1)
    }
    handleDireccion = (direccion) =>{
        if (direccion == '/ordenes_por_cobrar/:idclipro')
            this.handleEstado(true)
        else
            this.handleEstado(false)

    }
    componentDidMount(){
        // console.log(this.props.match)
        this.handleDireccion(this.props.match.path)
    }
    componentDidUpdate(prevProps){
        // console.log(this.props.match.url)
        if (this.props.match.path != prevProps.match.path){
            this.handleDireccion(this.props.match.path)
        }
    }
    render() {
        const { deudasCoP, page, data, loader, open_modal, permisos, totalizado, variables_busqueda } = this.props;
        if(permisos["cuentasp"] === false && permisos["cuentasc"] === false) {
            return <Redirect to={`/`} />
        }
        let idclipro = this.props.match.params.idclipro;
        return (
            <CardSimple
                titulo={ this.state.es_cliente? "ORDENES POR COBRAR":"ORDENES POR PAGAR"} margen={' '}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3" style={{border:"1px solid #E8E8E8", }}>
                            <div className="row col-md-9 col-12 m-0  inline-headers text-lg-left" style={{padding:"15px"}} >
                                <ToolbarReporteCuentas
                                    es_cliente={this.state.es_cliente}
                                    valorEsetado={{value: 1, label:"Todos"}}
                                    valorMoneda={{value: 'GTQ',label:"GTQ"}}
                                    cuentasporCOP={this.props.cuentasporCOP}
                                    isClearableCliPro={false}
                                    vistaOrdenes={true}
                                    defaultclipro ={idclipro}
                                    asyncValue={this.props.asyncValue}
                                    cliente={this.props.cliente}
                                    changeCliente={this.props.changeCliente}
                                    changeFechaPagar={this.props.changeFechaPagar}
                                    fecha_I={this.props.fecha_I}
                                    fecha_F={this.props.fecha_F}
                                    changeEmpresa={this.props.changeEmpresa}
                                    empresa={this.props.empresa}
                                    permisos={permisos}
                                    id_user={this.props.id_user}
                                    changeVendedor={this.props.changeVendedor}
                                    changeMoneda={this.props.changeMoneda}
                                />
                            </div>
                            <div className="col-md-3 col-12 m-0 p-0 inline-headers " style={{borderLeft:"1px solid #E8E8E8"}}>
                                <div style={{padding:"10px 20px"}} className="text-center">
                                    <label className=""> ESTADOS: </label>
                                    <div className="text-left align-self-center">
                                        <label className="d-flex align-items-center"><span className="estado-reporte-2"></span><span className="t-musgo">Vigente</span></label>
                                        <label className="d-flex align-items-center"><span className="estado-reporte-3"></span><span className="t-musgo">Por vencer</span></label>
                                        <label className="d-flex align-items-center"><span className="estado-reporte-4"></span><span className="t-musgo">Vencido</span></label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                    style={{border:"1px solid #E8E8E8", }}
                    >
                       <div className="col-md-12 row m-0 p-0 d-flex  justify-content-center mt-3"
                    style={{border:"1px solid #E8E8E8", }}
                    >
                       <div className="col-md-12 row m-0 p-0 d-flex justify-content-center mt-3" style={{ border: "1px solid #E8E8E8" }}>
                        <div className={` d-flex ${this.state.es_cliente ? 'justify-content-between' : 'justify-content-center'}`}>
                            <div className="text-center" style={{marginLeft: '10cm' }}>
                                <br />
                                <h4 className="t-musgo">Total Pendiente</h4>
                                <h4 className="t-primary m-0"><RenderMoneda monto={totalizado.total} simbolo={getsimbolo(variables_busqueda.moneda)} /></h4>
                                <br />
                            </div>
                            {
                                this.state.es_cliente && (
                                    <div className="text-center" style={{marginLeft: '5cm' }} >
                                        <br />
                                        <h4 className="t-musgo">Límite de Crédito</h4>
                                        <h4 className="t-primary m-0">
                                            <RenderMoneda monto={this.props.cliente != null ? this.props.cliente.limite_credito : 0} simbolo={getsimbolo(variables_busqueda.moneda)} />
                                        </h4>
                                        <br />
                                    </div>
                                )
                            }
                        </div>

                        <div className="col-md ">
                            <div className="text-right" > 
                                <br />
                                    <h4 className="t-musgo" style={{ fontSize: '18px'}}>Pendiente con Factura:&nbsp;&nbsp;  
                                     < RenderMoneda className="t-primary m-0" monto={totalizado.total_factura} simbolo={ getsimbolo(variables_busqueda.moneda)} />
                                    </h4>
                            </div>
                            <div className="text-right" >
                                <h4 className="t-musgo" style={{ fontSize: '18px'}}>Pendiente con Nota de Despacho:&nbsp;&nbsp;      
                                    < RenderMoneda className="t-primary m-0" monto={totalizado.total_despacho} simbolo={ getsimbolo(variables_busqueda.moneda)} />
                                </h4>
                                <br />
                            </div>
                        </div>
                    </div>

                    </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-end justify-content-center mt-3"
                    style={{border:"1px solid #E8E8E8", }}
                    >
                        <div className="col-md-4 col-12  d-flex justify-content-center" >
                            <div
                            // style={{borderRight:"1px solid #E8E8E8"}}
                            >
                                <br/>
                                <h5 className="t-musgo">Total Vigente</h5>
                                <h5 className="t-verde m-0"><RenderMoneda monto={totalizado.total_vigente} simbolo={getsimbolo(variables_busqueda.moneda)}/></h5>
                                <br/>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 d-flex justify-content-center">
                            <div
                            // style={{borderRight:"1px solid #E8E8E8"}}
                            >
                                <br/>
                                <h5 className="t-musgo">Total por Vencer</h5>
                                <h5 className="t-mostaza m-0"><RenderMoneda monto={totalizado.total_porvencer} simbolo={getsimbolo(variables_busqueda.moneda)}/></h5>
                                <br/>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 d-flex justify-content-center">
                            <div>
                                <br/>
                                <h5 className="t-musgo">Total de Vencidos</h5>
                                <h5 className="t-corinto m-0"><RenderMoneda monto={totalizado.total_vencido} simbolo={getsimbolo(variables_busqueda.moneda)}/></h5>
                                <br/>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Grid hover striped data={{count: 1, results: deudasCoP.results[0]? deudasCoP.results[0].saldo_vigencia.ordenes:[]}} loading={loader} pagination={false}>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                className='reporte-table'
                                isKey
                                dataFormat={
                                    standardActions({ verpro: `${ this.props.es_cliente ? 'ordenesventa':'ordencompra'}` })
                                }
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estado"
                                dataAlign="center"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <div className="d-flex justify-content-center">
                                            <span className={`estado-reporte-${cell}`} style={{margin:"0"}}></span>
                                        </div>
                                    )
                                }
                                className='reporte-table'
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="no_orden"
                                dataSort
                                className='reporte-table'
                            >
                                {this.state.es_cliente? 'DESPACHO':'ORDEN'}
                            </TableHeaderColumn>

                            {this.state.es_cliente&&
                                <TableHeaderColumn
                                    dataField="no_factura"
                                    dataSort
                                    className='reporte-table'
                                >
                                    NO. FACTURA
                                </TableHeaderColumn>
                            }

                            {this.state.es_cliente &&
                                <TableHeaderColumn
                                    dataField="factura_data_string"
                                    dataSort
                                    className='reporte-table'
                                    width="8%"
                                    dataFormat={(cell)=>{
                                        if (cell.nits) return cell.nits
                                        else ""
                                    }}
                                >
                                    FACTURA NIT
                                </TableHeaderColumn>
                            }

                            {this.state.es_cliente &&
                                <TableHeaderColumn
                                    dataField="factura_data_string"
                                    dataSort
                                    className='reporte-table'
                                    dataFormat={(cell)=>{
                                        if (cell.nombres) return cell.nombres
                                        else ""
                                    }}
                                >
                                    FACTURA NOMBRE
                                </TableHeaderColumn>
                            }

                            <TableHeaderColumn
                                dataField="orden_emision"
                                dataSort
                                className='reporte-table'
                                dataFormat={(cell)=>{
                                    return moment(cell,"DD/MM/YYYY").format("DD-MM-YYYY")
                                }}

                            >
                                FECHA DE EMISION
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                className='reporte-table'
                                dataFormat={(cell)=>{
                                        if(this.state.es_cliente){
                                            return moment(cell,"DD/MM/YYYY").format("DD-MM-YYYY")

                                        }
                                            else {
                                                return moment(cell).format("DD-MM-YYYY")
                                            }
                                        }}
   
                            >
                                FECHA DE VENCIMINETO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                dataAlign="right"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <RenderMoneda monto={Math.abs(cell)} simbolo={getsimbolo(variables_busqueda.moneda)} />
                                    )
                                }
                                className='reporte-table'
                            >
                                TOTAL
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                dataField="abonado"
                                dataAlign="right"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <RenderMoneda monto={cell} simbolo={getsimbolo(variables_busqueda.moneda)} />
                                    )
                                }
                                className='reporte-table'
                            >
                                PAGADO
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="pendiente"
                                dataAlign="right"
                                dataSort
                                dataFormat={
                                    (cell, row) =>(
                                        <RenderMoneda monto={cell} simbolo={getsimbolo(variables_busqueda.moneda)} />
                                    )
                                }
                                className='reporte-table'
                            >
                                PENDIENTE
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>
            </CardSimple>
        );
    }
}
