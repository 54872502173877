import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change, reset } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";
import moment from 'moment';


let url = 'valesGasolina' ;
let form = 'ValesForm';
let dirGrid = '/vales_gasolina';

export const constants ={
    LOADER: 'LOADER_VALES',
    DATA: 'DATA_VALES',
    ITEM: 'ITEM_VALES',
    PAGE: `PAGE_VALES`,
    ORDERING: `ORDERING_VALES`,
    SEARCH: `SEARCH_VALES`,
    SET_MODAL_ANULACION: 'SET_MODAL_ANULACION_VALE',
    TIPO_GASTOS: 'VALES_TIPO_GASTO',
    CUENTAS: 'VALES_CUENTAS',
    EMPRESAS: 'VALES_EMPRESAS',
    SET_GALONES: 'VALES_GALONES',
    SET_PRECIO_GALONES: 'VALES_PRECIO_GALONES'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setOpenModalAnulacion = open_modal => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_ANULACION,
        open_modal_anulacion: open_modal
    })
}

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().valesGasolina;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if(response.cerrado) response.cerrado = 1;
        else response.cerrado = 0;
        response.tipo_cuenta = "true"
        dispatch({type: constants.SET_PRECIO_GALONES, precio_galones: response.precio_galon})
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setGalones());
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    data.fecha = moment(data.fecha).format("YYYY-MM-DD HH:mm")
    data.fecha_gasto = moment(data.fecha).format("YYYY-MM-DD")
    if(data.piloto)
        data.piloto = data.piloto.user.id
    if(data.vehiculo)
        data.vehiculo = data.vehiculo.id
    if(data.galones_gasolina && data.precio_galon)
        data.total = parseFloat(data.galones_gasolina) * parseFloat(data.precio_galon)
    data.cerrado = data.cerrado == 1 ? true : false
    const body = {...data}
    dispatch(setLoader(true));
    api.post(`${url}`, body).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        // dispatch(reset(form));
        // if(!!form)
        if(!response.cerrado){
            dispatch(push(`/vales_gasolina/${response.id}/documento`));
            dispatch(initializeForm(form, {}));
        }
        else if(!!dirGrid) {
            dispatch(push(dirGrid));
        }
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    data.fecha = moment(data.fecha).format("YYYY-MM-DD HH:mm")
    data.fecha_gasto = moment(data.fecha).format("YYYY-MM-DD")
    if(data.piloto && data.piloto.user)
        data.piloto = data.piloto.user.id
    else
        data.piloto = null
    if(data.vehiculo)
        data.vehiculo = data.vehiculo.id
    if(data.galones_gasolina && data.precio_galon)
        data.total = parseFloat(data.galones_gasolina) * parseFloat(data.precio_galon)
    data.cerrado = data.cerrado == 1 ? true : false
    const body = {...data}
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, body).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if(!!form)
            dispatch(reset(form));
        if(!data.cerrado)
            dispatch(push(`/vales_gasolina/${id}/documento`));
        else if(!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la operacion', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const anularVale = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/${data.id}/anularVale`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(listar());
        dispatch(setOpenModalAnulacion(false))
    }).catch((error) => {
        let mensaje = "Error en la anulación";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().VEHICULOSActividades.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const resetForm = () => (dispatch) => {
    console.log("reset");
    dispatch(initializeForm(form, {fecha: moment(), tipo_cuenta:"true", desglose:[{}],}));
    dispatch(reset(form));
}

const getTipoGastos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipo_gasto').then((res)=>{
        dispatch({
            type: constants.TIPO_GASTOS,
            tipo_gastos: res
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const  getSelectCuentas = () => (dispatch) =>{
    dispatch(setLoader(true));
    let data = {}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch({
            type: constants.CUENTAS,
            select_cuentas:res.cuenta
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch({
            type: constants.EMPRESAS,
            empresas: res.empresa
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setGalones = () => (dispatch, getStore) =>{
    const galones = getStore().form.ValesForm.values.galones_gasolina
    dispatch({type: constants.SET_GALONES, galones: galones})
    const precio_galones = getStore().valesGasolina.precio_galones
    const total = galones * precio_galones
    dispatch(cambioDescuento(total, 'monto', 0))
}
const setPrecioGalones = (precio_galones) => (dispatch, getStore) =>{
    dispatch({type: constants.SET_PRECIO_GALONES, precio_galones: precio_galones})
    const galones = getStore().valesGasolina.galones
    const total = galones * precio_galones
    dispatch(cambioDescuento(total, 'monto', 0))
}

export const cambioDescuento = (valor, campo, index, vista) => (dispatch, getStore) => {
    const {  values: { desglose }, values } = _.cloneDeep(vista==undefined ? getStore().form.ValesForm:getStore().form.DesgloseForm);
    const valores = getStore().valesGasolina
    if (desglose != undefined){
        if (desglose.length > 0){
            const fraccion = desglose[index]

            let monto = 0
            if(valores.precio_galones && valores.galones){
                monto = valores.precio_galones * valores.galones
            }
            if(campo === 'precio'){
                if (valor){
                    fraccion.porcentaje = ((valor / parseFloat(monto)) * 100).toFixed(3);
                }
                else{
                    fraccion.porcentaje = 0;
                }
            }
            else if(campo=== 'monto'){
                if(valor){
                    if(fraccion.porcentaje){
                        fraccion.subtotal = ((fraccion.porcentaje / 100) * parseFloat(valor)).toFixed(3)
                    }
                }
            }
            else{
                if (valor){
                    fraccion.subtotal = ((valor / 100) * parseFloat(monto)).toFixed(3);
                }
                else{
                    fraccion.porcentaje = 0;
                }

            }
            dispatch(initializeForm(vista == undefined ? 'ValesForm' : 'DesgloseForm', {...values}))
        }
    }
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModalAnulacion,
    anularVale,
    resetForm,
    getTipoGastos,
    getSelectCuentas,
    cambioDescuento,
    selectEmpresas,
    setGalones,
    setPrecioGalones
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SET_MODAL_ANULACION]:(state,{ open_modal_anulacion })=>{
        return{
            ...state,
            open_modal_anulacion,
        }
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.TIPO_GASTOS]: (state, {tipo_gastos}) => {
        return {
            ...state,
            tipo_gastos
        }
    },
    [constants.CUENTAS]: (state, {select_cuentas}) => {
        return {
            ...state,
            select_cuentas
        }
    },
    [constants.EMPRESAS]: (state, {empresas}) => {
        return {
            ...state,
            empresas
        }
    },
    [constants.SET_GALONES]: (state, {galones}) => {
        return {
            ...state,
            galones
        }
    },
    [constants.SET_PRECIO_GALONES]: (state, {precio_galones}) => {
        return {
            ...state,
            precio_galones
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    open_modal_anulacion: false,
    tipo_gastos: [],
    select_cuentas: [],
    empresas: [],
    galones: 0,
    precio_galones: 0
};

export default handleActions(reducers, initialState);
