import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'tipo_gasto' ;
let form = 'TipoCategoriaForm';
let dirGrid = '/admin_tipocategoria';

export const constants ={
    LOADER: 'TIPOCATEGORIA_LOADER',
    DATA: 'TIPOCATEGORIA_DATA',
    ITEM: `TIPOCATEGORIA_ITEM`,
    PAGE: `TIPOCATEGORIA_PAGE`,
    ORDERING: `TIPOCATEGORIA_ORDERING`,
    SEARCH: `TIPOCATEGORIA_SEARCH`,
    OPEN_MODAL: 'TIPOCATEGORIA_OPENMODAL',
    LOADER_FORMULARIO: 'TIPOCATEGORIA_LOADER_FORMULARIO'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})
const setLoaderFormulario = loader_formulario =>({
    type:constants.LOADER_FORMULARIO,
    loader_formulario
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().tipocategorias;
    const params = { page };
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(listar());

    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());

    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}

const abrir_categoria = (value) => (dispatch, getStore) =>{
    const data = getStore().tipocategoria.data
    data.forEach(categoria =>{
        if(value == categoria.value){
            categoria.seleccionado = !categoria.seleccionado
        }
        else{
            categoria.children.forEach(sub =>{
                if(value == sub.value){
                    sub.seleccionado = !sub.seleccionado
                }
                else{
                  sub.children.forEach(sub1 =>{
                    if(value == sub1.value){
                        sub1.seleccionado = !sub1.seleccionado
                    }
                    else{
                      sub1.children.forEach(sub12 =>{
                        if(value == sub12.value){
                            sub12.seleccionado = !sub12.seleccionado
                        }
                        else{
                          sub12.children.forEach(sub2 =>{
                            if(value == sub12.value){
                                sub12.seleccionado = !sub12.seleccionado
                            }
                          })  
                        }
                      })  
                    }
                  })  
                }
            })
        }
    })
    dispatch({type: constants.DATA, data: data})
}


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModal,
    abrir_categoria
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    }
};


export const initialState ={
    loader:false,
    data: [],
    item: {},
    page: 1,
    ordering: '',
    search: '',
    open_modal: false,
    loader_formulario: false
};

export default handleActions(reducers, initialState);
