
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/reset';
import Verify from './Verify';


const mstp = (state, props) => {
    const tokenV = props.match.params.token
    console.log(tokenV)
    return {
        tokenV,
        ...state.reset,
    };
};

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Verify);