import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';

class ProductosGrid extends Component{
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    render(){
        const { detalle_orden,moneda, loader, listar: onPageChange, onSortChange, eliminar, handleSubmit, rechazarDespacho  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <form name="DespachoEnBodegaForm" className="form-validate mb-lg" onSubmit={handleSubmit}> {/*(e)=>{this.submit(e)}*/}
                <Grid hover striped data={{count:10,results:detalle_orden}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                    <TableHeaderColumn
                        dataField="producto"
                        dataSort
                        dataFormat = {
                            (cell)=>(cell.nombre)
                        }
                        isKey
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataSort
                    >
                        CANTIDAD
                    </TableHeaderColumn>
                </Grid>
                    <div className="col-12">
                        <button className="btn btn-mostaza m-1 align-self-center" type="button" onClick={rechazarDespacho}>RECHAZAR</button>
                        {loader==false ? <button className="btn btn-primary m-1 align-self-center" type="submit">ACEPTAR</button>: ""}
                    </div>
                </form>
            </div>
        )
    }

}
ProductosGrid = reduxForm({
    form: 'DespachoEnBodegaForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if (!data || !data.detalle_despacho || !data.detalle_despacho.length ){
            errors.detalle_despacho = {_error:'Debe despachar al menos un producto'}
        }else{
            const despachoArray = []
            data.detalle_despacho.forEach( (producto, index)=>{
                let detErrors = {};
                if(!producto.cantidad){
                    detErrors.cantidad = "Campo requerido"
                }else {
                    if(parseFloat(producto.cantidad) <= 0){
                        detErrors.cantidad = "Debe ser mayor a 0"
                    }
                    if(parseFloat(producto.cantidad) > producto.cantidad_actual){
                        detErrors.cantidad = `Debe ser un maximo de ${producto.cantidad_actual}`
                    }
                }
                if(detErrors){
                    despachoArray[index] = detErrors;
                }

            });
            if(despachoArray.length){
                errors.detalle_despacho = despachoArray
            }
        }
        return errors
    },
})(ProductosGrid);

export default ProductosGrid;