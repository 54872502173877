import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const ResetForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="email">Ingrese correo</label>
                <Field name="email" label="email" component={renderField} type="text" className="form-control" />
            </div>
            <div className="buttons-box">
                <button type="submit" className="btn btn-primary btn-block m-1 align-self-center">Enivar</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'reset',
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('Este campo es requerido'),
        });
    },
})(ResetForm);
