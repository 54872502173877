import React, {Component} from 'react';
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";

import CardSimple from '../../../../Utils/Cards/cardSimple';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class DevolucionesGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {

    }

    render(){
        const { devoluciones, loader, listar: onPageChange, onSortChange, eliminar, botonDevolucionPDF  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={{count:10,results:devoluciones}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            dataFormat={(cell, row) => {
                                return standardActions({
                                        ver: "devolucion",
                                        adicional: row.recibos[0] ? botonDevolucionPDF : undefined,
                                    })(cell,row)
                            }}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataSort
                            dataFormat = {
                                (cell, row) => (
                                    <RenderDateTime fecha={cell} />
                                )
                            }
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cliente"
                            dataSort
                            dataFormat = {
                                (cell) => {
                                    if(cell){
                                    return  (<span>{cell.nombre}</span>)
                                    }
                                    return (<div></div>)
                                }
                            }
                        >
                            CLIENTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="no_despacho"
                            dataSort
                        >
                            NO MOVIMIENTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="despachado_por"
                            dataSort
                            dataFormat = { (cell, row) => (
                                <span>{cell.first_name}</span>

                            )
                            }
                        >
                            RECIBIDO POR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        dataFormat ={
                            (cell, row)=>(
                                <RenderMoneda monto={cell? cell: 0}/>
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }
}

