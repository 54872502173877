import React, { Component } from 'react';
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import _ from 'lodash';

function cellTotal(cell, row) {
    if(row.nombre_origen == "METAS"){
        return {color: "#f3a631", fontSize: 16, fontWeight: "bold"}
    }
    if(row.id == 0){
        return {color: "#00334E", fontSize: 16, fontWeight: "bold"}
    }
    return {}
}

export default class GridVentas extends Component {

    render() {
        const { loader, resumen, data, listarCobros, page, meses } = this.props;
        return (
            <React.Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={listarCobros}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        className='reporte-table'
                        dataFormat={(cell, row) => (
                            row.nombre_origen
                        )}
                        tdStyle={cellTotal}
                        width="15%"
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    {
                        meses.map((label, index) => (
                            <TableHeaderColumn
                                dataField="meses"
                                className='reporte-table'
                                thStyle={{ textTransform: "uppercase" }}
                                dataAlign="right"
                                tdStyle={cellTotal}
                                dataFormat={(cell, row) => (cell[index] ? <RenderMoneda monto={cell[index].total} /> : "Sin registro")}
                            >
                                {label}
                            </TableHeaderColumn>
                        ))
                    }
                    <TableHeaderColumn
                        dataField="meses"
                        className='reporte-table'
                        dataAlign="right"
                        dataFormat={(cell, row) => {
                            let suma = 0;
                            cell.forEach(mes => suma += mes.total);
                            return (<RenderMoneda monto={suma} />)
                        }}
                        tdStyle={cellTotal}
                        width="10%"
                    >
                        TOTAL
                    </TableHeaderColumn>
                </Grid>
            </React.Fragment>
        )
    }
}
