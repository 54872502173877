import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';
import AnulacionForm from './anulacionForm';
import moment from 'moment';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}

export default class VehiculosGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    abrirModalEliminar = (id, row) => {
        let _data = this.props.data
        this.setState({
            id_vale: id,
        })
        this.props.setOpenModalAnulacion(true);
    }

    onSubmit = (values) => {
        values.id = this.state.id_vale;
        Swal.fire({
            title: '¿Desea anular este vale?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularVale(values);
            }
        });
    }


    render() {
        const { data, page, loader, open_modal_anulacion, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos.vales === false) {
            if(!!permisos.ver_vales === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton="AGREGAR VALE"
                    ruta="vales_gasolina/nuevo"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Piloto, Vehiculo"}
                    permisos={permisos.vales}
                />}
            titulo="VALES DE GASOLINA">
                <Modal open={open_modal_anulacion} onClose={(e) => {
                    this.props.setOpenModalAnulacion(false)
                }} center>
                    <div className="mt-5">
                        <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                    </div>
                        <AnulacionForm
                        onSubmit={this.onSubmit}
                        cerrarModal={this.props.setOpenModalAnulacion}
                        />
                </Modal>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        {
                            (permisos.vales) && (
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="left"
                                    dataSort
                                    dataFormat={
                                        standardActions({
                                            eliminarModal: this.abrirModalEliminar,
                                            editarVale: "vales_gasolina",
                                            verproVale: "vales_gasolina"
                                        })
                                    }
                                />
                            )
                        }
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            tdStyle={cellTachado}
                            dataAlign="center"
                            dataFormat={(cell)=> cell ? cell : '' }
                        >
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="piloto"
                            dataSort
                            tdStyle={cellTachado}
                            dataFormat={(cell)=> cell ? cell.user.nombre : '' }
                        >
                            PILOTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="vehiculo"
                            dataAlign="center"
                            dataSort
                            tdStyle={cellTachado}
                            dataFormat={(cell)=> cell ? cell.placa : ''}
                        >
                            VEHICULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="galones_gasolina"
                            dataAlign="center"
                            tdStyle={cellTachado}
                            dataFormat={(cell) => ( cell ? `${cell} galones` : "Sin registro")}
                        >
                            GALONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_galon"
                            dataAlign="center"
                            tdStyle={cellTachado}
                            dataFormat={(cell) => ( cell ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                        >
                            PRECIO/GALON
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total"
                            dataAlign="center"
                            tdStyle={cellTachado}
                            dataFormat={(cell) => ( cell ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                        >
                            TOTAL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="km_vehiculo"
                            dataAlign="center"
                            tdStyle={cellTachado}
                            dataFormat={(cell) => ( cell ? cell : "Sin registro")}
                        >
                            KM_VEHICULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cerrado"
                            dataAlign="center"
                            tdStyle={cellTachado}
                            dataFormat={(cell)=> ( cell ? 'Cerrado': 'Pendiente de pago' )}
                        >
                            ESTADO
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
