import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import ToolbarFiltroFecha from '../../../Utils/Toolbar/ToolbarFiltroFecha';
import ver from '../../../../../../assets/img/icons/Ver.png';
import moment from 'moment';


export default class NotificacionesGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    state = {
        dateStart: moment().subtract(3, 'days'),
        dateEnd: moment(),
    }
    componentWillMount() {
        const { listarespecial, page } = this.props;
        listarespecial(
            "notificaciones/filtrarNotificaciones",
            page,
            {
                asignado_a:this.props.me.id,
                fechaI:this.state.dateStart.format("YYYY-MM-D"),
                fechaF:this.state.dateEnd.format("YYYY-MM-D")
            }
        );
    }
    botonLink = (id, row) => {
        console.log(row)
        if (!row.link) {
            return (<div></div>)
        }
        return (
            <Link
                className="px-2"
                style={{ cursor: "pointer", color: "#c4183c" }}
                to={
                    (row.link == '/caja/') ? (
                        `${row.link}${this.props.me.caja}`
                    ):(
                        row.link
                    )
                }
            >
                <img
                    // className="action-img"
                    style={{width:"24px", height:"24px"}}
                    title="link"
                    src={ver} alt="Ver eliminado"
                />
            </Link>
        )
    }
    handleDateStart = (date) => {
        const { listarespecial, page } = this.props;
        this.setState({dateStart: date})
        listarespecial(
            "notificaciones/filtrarNotificaciones",
            page,
            {
                asignado_a:this.props.me.id,
                fechaI:date.format("YYYY-MM-D"),
                fechaF:this.state.dateEnd.format("YYYY-MM-D")
            }
        );
    }
    handleDateEnd = (date) => {
        const { listarespecial, page } = this.props;
        this.setState({dateEnd: date})
        listarespecial(
            "notificaciones/filtrarNotificaciones",
            page,
            {
                asignado_a:this.props.me.id,
                fechaI:this.state.dateStart.format("YYYY-MM-D"),
                fechaF:date.format("YYYY-MM-D"),
            }
        );
    }
    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        // if(permisos["empresas"] === false) {
        //     if(!!permisos[`ver_empresas`] === false) {
        //         return <Redirect to={`/`} />
        //     }
        // }
        return(
            <div className="Container bg-white">
                <h3 className="t-primary m-t p-t">NOTIFICACIONES</h3>
                <div className="row justify-content-start">
                    <div className="col-md-8 col-12">
                        <ToolbarFiltroFecha
                            {...this.props}
                            setDateStart={this.handleDateStart}
                            setDateEnd={this.handleDateEnd}
                            dateStart={this.state.dateStart}
                            dateEnd={this.state.dateEnd}
                            justify={"justify-content-start justify-content-sm-start"}
                        />
                        {/* <Link className="btn btn-primary" to="/empresa/nuevo">AGREGAR</Link> */}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange} onSortChange={onSortChange} >
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataSort
                                        dataFormat={
                                            standardActions({
                                                adicional2: this.botonLink,
                                                // eliminar
                                            })
                                        }
                                    >
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="creado"
                                        dataSort
                                        dataFormat={
                                            (cell,row)=>(
                                                <RenderDateTime fecha={cell} ></RenderDateTime>
                                            )
                                        }
                                    >
                                        FECHA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        isKey
                                        dataField="generada_por_name"
                                        dataSort
                                    >
                                        USUARIO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="mensaje"
                                        dataSort
                                    >
                                        DESCRIPCION
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

