import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/poss/poss';
import PossCrear from './PossCrear';

const mstp = state => {
    return {...state.poss}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PossCrear)
