import React from 'react';
import HeaderSimple from '../Header/HeaderSimple';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

function CardFormularioOrden(props) {
    return (
        <div className="Container">
            <div className="row">
                <div className="mb-4 col-12">
                    <div className="mb-4 card card-small m-t">
                        <div className="card-body">
                            {
                                (props.titulo) && (
                                    <HeaderSimple titulo={props.titulo}/>
                                )
                            }

                            <div className="row justify-content-center mt-4">
                                <div className="col-sm-6 col-8">
                                <ProgressBar
                                        percent={props.step === 1 ? 50: props.step === 2 ? 100 : 100}
                                        filledBackground= "#f3a631"
                                    >
                                        <Step transition="scale">
                                        {({ accomplished, index }) => (
                                             <div
                                             style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                         >
                                             <div className={ `${accomplished? 'step-caption-selected':"step-caption"}`}>
                                                 {index + 1}
                                             </div>
                                         </div>
                                        )}
                                        </Step>
                                        <Step transition="scale">
                                        {({ accomplished, index }) => (
                                             <div
                                             style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                         >
                                             <div className={ `${accomplished? 'step-caption-selected':"step-caption"}`}>
                                                 {index + 1}
                                             </div>
                                         </div>
                                        )}
                                        </Step>
                                    </ProgressBar>

                                </div>
                            </div>
                            <div className="mb-4 mt-5 col-12">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardFormularioOrden;
