import React, {Fragment} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {connect} from 'react-redux';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderSelectField, renderTextArea,renderFieldRadio, AsyncSelectField } from '../../../Utils/renderField';

import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";

import {api} from 'api';

const getCaja = (search) => {
    return api.get("cuentas", { search, cob:'1' }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getBanco = (search) => {
    let data = {solo_principal: true}
    return api.post("cuentas/selectcuentas", data).
    then((data) => {
        if (data){
            console.log(data.cuenta)
            return data.cuenta;
        }
        return [];
    }).catch(() => {
        return [];
    });
};



let EgresoCajaForm = (props) => {
    const {
        data,
        handleSubmit,
        previousStep,
        tipo_entrega,
        sucursales,
        bit_egreso,
        boton
    } = props;
    return (
        <form name="EgresoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="col-12 m-0 p-0 d-flex justify-content-center">
                <div className="col-md-12 row m-0 p-0">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div
                            className="col-md-10 mb-3"
                            style={{border: "1px solid #e8e8e8", padding:"10px"}}>
                            <div className="d-flex justify-content-center">
                                <h5 className="t-primary text-uppercase align-self-center">Seleccione tipo de transacción</h5>
                            </div>
                            <div className="row d-flex justify-content-md-between ">
                                <div className={`form-group has-feedback col-md-4 col-sm-6 col-12`}>
                                    <Field
                                        value={"false"}
                                        name="egreso_tipo"
                                        label="A otra caja chica"
                                        component={renderFieldRadio}
                                        type="radio"
                                        className="form-control" />
                                </div>
                                <div className={`form-group has-feedback  col-md-4 col-sm-6 col-12`}>
                                    <Field
                                        name="egreso_tipo"
                                        label="A banco"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"true"}
                                        className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="fecha">Fecha</label>
                            <Field
                                name="fecha"
                                selectedDate={new Date()}
                                maxDate="2040-12-31"
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                        </div>

                    </div>
                    <div className="form-group has-feedback col-12 row">
                        {
                            (!bit_egreso) && (
                                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                    <label className="t-azul" htmlFor="caja">{'Caja'}</label>
                                            <Field
                                                name="caja"
                                                label="caja"
                                                labelKey="nombre_dueno" valueKey="id"
                                                component={AsyncSelectField}
                                                type="text"
                                                loadOptions={getCaja}
                                                // onChange={(e)=>handleCuentas(e.target.value)}
                                                className="form-control"
                                            />
                                </div>
                            )
                        }

                         <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="monto">Monto</label>
                            <Field
                                name="monto"
                                prefix={'Q. '}
                                component={renderCurrency}
                                parse={cell => parseFloat(cell)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    {
                        (bit_egreso) && (
                            <div className="form-group has-feedback col-12 row">
                                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                    <label className="t-azul" htmlFor="banco">Banco</label>
                                    <Field
                                            name="banco"
                                            label="label"
                                            valueKey="id"
                                            labelKey="label"
                                            component={AsyncSelectField}
                                            type="text"
                                            loadOptions={getBanco}
                                            className="form-control"
                                        />
                                </div>
                                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                    <label className="t-azul" htmlFor="no_documento">No boleta</label>
                                    <Field
                                        name="no_documento"
                                        component={renderField}
                                        className="form-control"
                                    />
                                </div>

                            </div>
                        )
                    }

                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback col-12 `}>
                            <label className="t-azul" htmlFor="descripcion">Descripción</label>
                            <Field
                                name="descripcion"
                                component={renderTextArea}
                                top={{top:'94px'}}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <button onClick={()=>{props.setOpenModal(false)}} className="btn btn-secondary  align-self-cente">CANCELAR</button>
                        {
                            boton==false && (<button type="submit" className="btn btn-primary m-1 align-self-center">TRANSFERIR</button>)
                        }
                        
                    </div>
                </div>
            </div>
        </form>
    );
};


EgresoCajaForm = reduxForm({
    form: 'EgresoForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if(!data.caja){
            errors.caja = "Campo requerido.";
        }
        if(!data.empresa) {
            errors.empresa = "Campo requerido.";
        }
        if(!data.monto){
            errors.monto = 'Campo requerido';
        }else{
            if(parseFloat(data.monto)<= 0)
            {errors.monto = 'El monto tiene que ser mayor a 0';}
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido"
        }

        return errors;
    },
})(EgresoCajaForm);

const selector = formValueSelector('EgresoForm');

EgresoCajaForm = connect(state => {
    const egreso_tipo = selector(state, 'egreso_tipo');
    let bit_egreso = egreso_tipo == 'true' ? true : false;

    return {
        bit_egreso
    };
})(EgresoCajaForm);
export default EgresoCajaForm;
