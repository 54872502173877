import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import CardSimple from '../../../Utils/Cards/cardSimple';
import RechazoForm from "./rechazoForm";

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import TabDetalle from './tabDetalle'
import TabLinks from './tabLinks'


import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";


export default class OrdenCompraGrid extends Component {
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.leer(id);
    }
    state = {
        open_modal:false,
    }
    handleSubmit = (values) =>{
        let id = this.props.match.params.id;
        values.id =  id
        this.props.refusePM(values);
    }
    handleModal = (value) =>{
        this.setState({open_modal:value})
    }
    handleClick = ()=>{
        let id = this.props.match.params.id;
        this.props.acceptPM({id, pm_oc:'pm' });
    }
    getoptions = (estate)=> {
        const { item } = this.props;

        if (this.props.me.permisos.ordencompra){
            if (estate === 100){
                // if(!item.comprado){
                    return(
                        <div className="row  justify-content-sm-around justify-content-center">
                            <Link className="btn btn-primary m-1 align-self-center"  to={`/ordencompra/${this.props.match.params.id}`}>GENERAR ORDEN DE COMPRA</Link>
                        </div>
                    )
                // }

            }else{
                return(
                <div className="row  justify-content-sm-around justify-content-center">
                    <button onClick={()=>{this.handleModal(true)}}  className="btn btn-secondary m-1 align-self-center">RECHAZAR</button>
                    <button onClick={()=>{this.handleClick()}} className="btn btn-primary m-1 align-self-center">APROBAR</button>
                </div>)
            }
        } else {
            return '';
        }
    }
    getJustificacion = (item) =>{
        if (item.proceso_estado == 80){
            let ultiJustificacion = item.justificacion[item.justificacion.length -1]
            return(
                <div className="col-12">
                    <h6 className="t-azul">Justificación de Rechazo</h6>
                    <span>{ultiJustificacion.justificacion}</span>
                </div>
            )
        }
    }
    render() {
        const { item, hijos, loader, listar: onPageChange, onSortChange, eliminar } = this.props;
        return (
            <CardSimple
                titulo={item.no_orden}>
                <Modal open={this.state.open_modal} onClose={(e)=>{
                    this.setState({open_modal:false})
                }} center >
                    <div className="mt-5">
                        <h2 className="text-center text-primary">JUSTIFICACIÓN DE RECHAZO</h2>
                    </div>
                    {
                        (item.proceso_estado!="Rechazado")?(
                            <RechazoForm
                               onSubmit={this.handleSubmit}
                               handleModal={this.handleModal}
                            />
                        ):(
                            <div className="form-validate mb-lg text-center" >
                                <span style={{fontSize: 20}}>{"Esta Peticion de materiales ya fue rechazada"}</span>
                            </div>
                        )
                    }
                </Modal>
                <div className="row justy-content-center">
                    <div className=" col-md-6 col-12">
                        <div className="row">
                            <div className="col-12">
                                <h6  className="t-azul">Fecha</h6>

                                <span className="t-musgo"><RenderDateTime fecha={item.fecha} /></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="t-azul">Empresa</h6>
                                <span className="t-musgo">{item.nombre_empresa}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="t-azul">Productos:</h6>
                                {
                                item.comprado ? (
                                        <span className="t-musgo">Todos los productos ya fueron comprados</span>
                                    ):(
                                        <span className="t-musgo">Aun hay productos por comprar</span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="col-12">
                            <h6 className="t-azul">Descripción</h6>
                            <span className="t-musgo">{item.descripcion}</span>
                        </div>
                        {this.getJustificacion(item)}

                    </div>
                </div><br/>
                <Tabs
                    defaultActiveKey="DETALLEPM"
                    tabBarPoition = "top"
                    onChange={(key) => {
                        key === 'LINKS' && this.props.listChilds(this.props.match.params.id)
                        
                    }}
                    renderTabBar ={()=><ScrollableInkTabBar/>}
                    renderTabContent={()=><TabContent/>}

                >
                    <TabPane tab="DETALLE" key="DETALLE">
                        <TabDetalle
                            item={item}
                            loader={loader}
                        />
                    </TabPane>
                   { item.proceso_estado==100 && ( 
                        <TabPane tab="LINKS " key="LINKS">
                            <TabLinks
                                    hijos={hijos}
                                    loader={loader}
                                />
                        </TabPane>
                    )}
                </Tabs>
                <br/>
                {
                    this.getoptions(item.proceso_estado)
                }
            </CardSimple>
        );
    }
}
