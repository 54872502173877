import React, { Component } from 'react'
import DespachoGrid  from './despachogrid';
import {Link} from 'react-router-dom'
import Modal from 'react-responsive-modal';

import HistoriaDespachosGrid from './historydespacho';

export default class DespachoContainer extends Component {
    state={
        open_modal: false,
    }
    setOpenModal = (val) =>{
        this.setState({
            open_modal:val,
        })

        if (val){
            console.log('bandera')
            let data = {orden:this.props.orden.id}
            this.props.HistoryDispatch(this.props.id)
        }
    }
    setDespachar= ()=>{
        this.props.AutorizarDespacho(
            this.props.orden.id
        )
    }
    render() {
        const { cuentas, orden, id } = this.props;
        return (
            <div>
                <Modal open={this.state.open_modal} onClose={(e) => {
                    this.setOpenModal(false)
                }} center>
                    <div className="">
                        <h2 className="text-center text-primary text-uppercase">Historial de despachos</h2>
                    </div>
                    <HistoriaDespachosGrid
                        data={this.props.history}
                    />
                </Modal>
                <DespachoGrid
                    detalle_orden={this.props.item.detalle_despacho}
                />

                <div className="row  justify-content-sm-start justify-content-start">
                    <div className="col-12">
                        <Link  className="btn btn-primary m-1 align-self-center" to={`/devolucion/${id}/despacho`} >DESPACHAR</Link>
                        <button className="btn btn-mostaza m-1 align-self-center" onClick={ (e)=>{this.setOpenModal(true)}}>HISTORIAL</button>
                        {/* <button className="btn btn-mostaza m-1 align-self-center">MARCAR DESPACHADA</button> */}
                    </div>
                </div>
            </div>
        )
    }
}
