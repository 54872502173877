import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { renderField, renderTextArea, renderSelectField, renderCurrency, AsyncSelectField, SelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import { Monedas, getsimbolo } from '../../../../../utility/constants';
import { RenderMoneda, getSimbolo } from "../../../Utils/renderField/renderTableField";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import iconoFacturar from '../../../../../../assets/img/icons/facturar.png';
import iconoImprimir from '../../../../../../assets/img/icons/imprimir.png';
import iconoPDFFactura from '../../../../../../assets/img/icons/facturapdf.png';

import { api } from 'api';
import { set } from 'lodash';


const TIPO_PAGO = [
    {value:1, label:'Normal'},
    {value:2, label:'Extraordinario'},
]

class ItemArray extends Component {
    state = {
        cliente: undefined,
        proveedor: undefined,
        idOC: undefined,
        sucursal: undefined,
        productosOrden: [],
        results: []
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.textInputDespachos = React.createRef();
        this.textInputOC = React.createRef();
        this.textInputSU = React.createRef();
        this.state = {
            loadOptions: (search, index) => this.getDespachos(search,index),
            optionsOC: (search, index) => this.getOC(search, index),
            optionsSU: (search) => this.getSucursales(search)
        }
    }


    getClientes = (search) => {
        let params = { tipo: 2, esCliente: '1' }
        if (search) {
            params.search = search;
        }
        return api.get("clientes", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    getProveedores = (search) => {
        let params = {}
        if (search) {
            params.search = search;
        }
        return api.get("clientes", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    setClienteSucursal = (e) => {
        if (this.props.paraCliente) {
            this.setState({ cliente: e.id, sucursal: e.sucursal })
        } else {
            this.setState({ proveedor: e.id, sucursal: e.sucursal })
        }
    }

    getOC = (search, index) => {
        let params = {
            entregado: false,
            estado: true
        }
        let ordenes =[]
        ordenes=this.props.ordenes
        let count = 0
        if(ordenes){
            ordenes.forEach(orden => {
                if(index==count){
                    if(orden.clienteProveedor){
                        this.setState({proveedor: orden.clienteProveedor.id})
                    }   
                }
                count += 1
            })
        }
        if (search) {
            params.search = search;
        }
        if (this.state.proveedor) {
            params.proveedor__id = this.state.proveedor;
        }
        return api.get("ordencompra", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    getDespachos = (search, index) => {
        let params = {
            tipo_movimiento: 40,
            tipo_despacho: 10,
            //orden__entregado: false,
        };
        let despachos =[]
        despachos=this.props.despachos
        let count = 0
        despachos.forEach(despacho => {
            if(index==count){
                if(despacho.clienteProveedor){
                    this.setState({cliente: despacho.clienteProveedor.id})
                }   
            }
            count += 1
        })
        if (search) {
            params.search = search;
        }
        if (this.state.cliente) {
            params.orden__proveedor = this.state.cliente
            params.flujo_despacho = 20 // Para despachos aceptados
            params.entregado = 0
        }
        return api.get("despachos/despachoscondevolucion", params).
            then((data) => {
                if (data) {
                    this.setState({results: data.results})
                    return data.results;
                }
            }).catch(() => {
                return [];
            });
    }

    botonPDFFactura = (row, dato) => {
        let params = {
            tipo_movimiento: 40,
            tipo_despacho: 10,
            //orden__entregado: false,
        };
        if (row.despacho) {
            params.search = row.despacho.no_despacho;
        }
        if (row.clienteProveedor) {
            params.orden__proveedor = row.clienteProveedor.id
            params.flujo_despacho = 20 // Para despachos aceptados
            params.entregado = 0
        }
        return api.get("despachos/despachoscondevolucionPDF", params).
            then((data) => {
                if (data) {
                    data.results.map(result => {
                        if(result.orden_detalle){
                            result.orden_detalle.facturas.map((item) =>{
                                if(item.factura && item.id == dato){
                                    window.open(item.factura, "_blank");
                                }else{return(<div></div>)}
                            })
                        }   
                    })
                }
            }).catch(() => {
                return [];
            });
        
    }

    botonXml = (row, dato) => {
        row.despacho.orden_detalle.facturas.map((item) =>{
            if (item.archivo_xml && item.id == dato) {
                window.open(item.archivo_xml, "_blank");
            } else {return (<div></div>) }
        })
    }

    botonImprimirDespacho = (id) => {
        const { PDFITINERARIO } = this.props
        PDFITINERARIO(id)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.proveedor !== prevState.proveedor) {
            if (this.textInputDespachos && this.textInputDespachos.current) {
                this.textInputDespachos.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.getDespachos(search)
                });
                this.forceUpdate();
            }
        }
        if (this.state.cliente !== prevState.cliente) {
            if (this.textInputDespachos && this.textInputDespachos.current) {
                this.textInputDespachos.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.getDespachos(search)
                });
                this.forceUpdate();
            }
        }
    }

    render() {
        const { index, borrarProductos, cliente, campo, eliminar, verProductos, editar, paraCliente, field, despachos, mostrarVista } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                        <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                            title="Borrar"
                            onClick={() => {
                                eliminar()
                                borrarProductos && borrarProductos.length > 0 ? borrarProductos(index) : []
                                
                            }}
                        />
                    
                    {(this.state.idOC || editar) && !paraCliente &&
                        <img className="action-img" src={require('assets/img/icons/productos pro.png')} alt="ver"
                        title="Productos"
                        onClick={() => {
                            verProductos(index)
                        }}
                        />
                    }
                </td>
                <td className="sin-borde-top">
                    <Field
                        forwardRef
                        key={`${campo}-cliente`}
                        id={`${campo}.selectCliente`}
                        name={`${campo}.clienteProveedor`}
                        label="cliente"
                        component={AsyncSelectField}
                        loadOptions={paraCliente ? this.getClientes : this.getProveedores}
                        top={{ top: "5px", position: "inherit" }}
                        valueKey="id"
                        labelKey="nombre"
                        isClearable={true}
                        type="text"
                        onChange={(e) => {
                            this.setClienteSucursal(e),
                            paraCliente ?
                            this.props.setClienteSucursal(e)
                            :
                            ""
                        }
                        }
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top">
                    {paraCliente
                        ?
                        <Field
                            name={`${campo}.sucursal`}
                            label="sucursal"
                            labelKey="direccion" valueKey="id"
                            component={renderSelectField}
                            options={this.props.item && this.props.editar && !this.state.sucursal
                                ? this.props.item.itinerario_detalle_clientes && this.props.item.itinerario_detalle_clientes[index]
                                    ? this.props.item.itinerario_detalle_clientes[index].clienteProveedor.sucursales
                                    : this.props.field 
                                    ? this.props.field.clienteProveedor
                                        ? this.props.field.clienteProveedor.sucursal
                                        ? this.props.field.clienteProveedor.sucursal
                                        : []
                                    : []
                                    :[]
                                : this.props.field 
                                    ? this.props.field.clienteProveedor
                                        ? this.props.field.clienteProveedor.sucursal
                                        ? this.props.field.clienteProveedor.sucursal
                                        : []
                                    : []
                                    :[]
                            }
                            className="form-control"
                            top={{ top: "5px", position: "inherit" }}
                        />
                        :
                        <Field
                            name={`${campo}.sucursal`}
                            label="sucursal"
                            labelKey="direccion" valueKey="id"
                            component={renderSelectField}
                            options={this.props.item && this.props.editar && !this.state.sucursal
                                ? this.props.item.itinerario_detalle_proveedores && this.props.item.itinerario_detalle_proveedores[index]
                                    ? this.props.item.itinerario_detalle_proveedores[index].clienteProveedor.sucursales
                                    : this.props.field 
                                    ? this.props.field.clienteProveedor
                                        ? this.props.field.clienteProveedor.sucursal
                                        ? this.props.field.clienteProveedor.sucursal
                                        : []
                                    : []
                                    :[]
                                    : this.props.field 
                                    ? this.props.field.clienteProveedor
                                        ? this.props.field.clienteProveedor.sucursal
                                        ? this.props.field.clienteProveedor.sucursal
                                        : []
                                    : []
                                    :[]
                            }
                            className="form-control"
                            top={{ top: "5px", position: "inherit" }}
                        />
                    }
                </td>
                {paraCliente ? (
                    <Fragment>
                        <td className="sin-borde-topzz">
                            <Field
                                ref={this.textInputDespachos}
                                key={`${campo}-od-${this.state.cliente}`}
                                id={`${campo}-od-${this.state.cliente}`}
                                name={`${campo}.despacho`}
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={(search) => this.state.loadOptions(search, index)}
                                top={{ top: "5px", position: "inherit" }}
                                type="text"
                                valueKey="id"
                                labelKey="no_despacho"
                                isClearable={true}
                                className="form-control"
                                />
                        </td>
                        <td>
                        {(field && Object.keys(field).length > 0 && despachos.length > 0 ) ? (
                        (field.despacho && Object.keys(field).length > 0 && despachos[index].despacho) ? (
                            (field.despacho.tipo_movimiento==40) ? (
                            (field.despacho.facturas_despacho && field.despacho.facturas_despacho.length > 0 && despachos[index].despacho.facturas_despacho.length > 0) ? (
                                despachos[index].despacho.facturas_despacho.map((dato, index) => {
                                    //console.log("field: ", field, "index: ",index , "dato: ", dato)
                                    return (
                                        <Fragment>
                                            <td className="sin-borde-topzz">
                                                <img className="action-img" alt="PDF Factura" title="PDF Factura" src={iconoPDFFactura} onClick={() => this.botonPDFFactura(field, dato)} ></img>
                                            </td>
                                            <td className="sin-borde-topzz">
                                                <img className="action-img" alt="Imprimir Despacho" title="Imprimir Despacho" src={iconoImprimir} onClick={() => this.botonImprimirDespacho(field.despacho.id)}></img>
                                            </td>
                                        </Fragment>
                                    )
                                })
                            ) :
                                <td className="sin-borde-topzz">
                                    <img className="action-img" alt="Facturacion" title="Facturación" src={iconoFacturar} onClick={() => mostrarVista(true, index)}></img>
                                </td>
                            ) :
                                <td></td>
                        ) :
                            <td></td>
                    ) :
                        <td></td>
                    }
                            
                        </td>
                    </Fragment>
                ) : (
                        <td className="sin-borde-topzz">
                            <Field
                                ref={this.textInputOC}
                                key={`${campo}-oc-${this.state.proveedor}`}
                                id={`${campo}.oc-${this.state.proveedor}`}
                                name={`${campo}.orden_compra`}
                                label="Sucursal"
                                component={AsyncSelectField}
                                loadOptions={(search) => this.state.optionsOC(search, index)}
                                top={{ top: "5px", position: "inherit" }}
                                valueKey="id"
                                labelKey="no_orden"
                                isClearable={true}
                                className="form-control"
                                onChange = { (e) => (
                                    this.setState({ idOC: e.id }),
                                    this.props.getDetalleOrden(e.id, index)
                                )}
                            />
                        </td>
                    )
                }
                
            </tr>
        );
    }
}


const renderRuta = ({ fields, meta: { error, submitFailed }, borrarProductos, cliente, getDetalleOrden, verProductos, item, reRender, editar, paraCliente, setCliente, despachos, PDFITINERARIO, mostrarVista, setClienteSucursal, count, ordenes}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th ></th>
                            <th> {paraCliente ? 'Cliente' : 'Proveedor'} </th>
                            <th style={{ width: "25%" }}>Sucursal</th>
                            <th style={{ width: "30%" }}> {paraCliente ? 'Despacho' : 'Orden de C.'} </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((campo, index) => {
                            return (
                                <ItemArray
                                    campo={campo}
                                    index={index}
                                    key={index}
                                    editar={editar}
                                    setCliente={setCliente}
                                    cliente={cliente}
                                    item={item}
                                    paraCliente={paraCliente}
                                    eliminar={() => {
                                        fields.remove(index)
                                    }}
                                    verProductos = {verProductos}
                                    getDetalleOrden = { getDetalleOrden }
                                    borrarProductos = { borrarProductos }
                                    field={fields ? fields.get(index) : null}
                                    despachos={despachos}
                                    PDFITINERARIO={PDFITINERARIO}
                                    mostrarVista={mostrarVista}
                                    setClienteSucursal={setClienteSucursal}
                                    count={count}
                                    ordenes={ordenes}
                                />
                            );

                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    {paraCliente ? 'AGREGAR CLIENTE' : 'AGREGAR PROVEEDOR'}
                </button>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>}
    </div>
)
//fin de render de productos

class ItinerarioForm extends Component {
    state = {
        proveedor: null,
        sucursales: {},
        km_diferencia: 0,
        km_inicial: 0,
        km_final: 0
    }

    getPilotos = (search) => {
        let params = {}
        if (search) {
            params.search = search;
        }
        return api.get("pilotos", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }
    getPagos = (search) => {
        let params = {}
        if (search) {
            params.search = search;
        }
        // http://0.0.0.0:8080/api/pagosItinerarios/?page=1&ordering=&search=
        return api.get("pagosItinerarios", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }
    getVehiculos = (search) => {
        let params = {
            tipo: 1 // Tipo 1 es vehiculos
        }
        if (search) {
            params.search = search;
        }
        return api.get("vehiculos", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    reRender = () => {
        this.setState({
            proveedor: this.props.cliente
        });
    }

    render() {
        const { handleSubmit,
            editar,
            setCliente,
            cliente,
            item,
            total,
            simbolo,
            valor_iva,
            empresas,
            subtotal, handleCuentas, empresa, productos, tiene_iva, despachos, PDFITINERARIO, mostrarVista, setClienteSucursal, count, ordenes } = this.props;
        return (
            <form name="itinerarioForm" className="form-validate mb-lg " onSubmit={handleSubmit}>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 row m-0 p-0">
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">Fecha de Salida*</label>
                                <Field
                                    name="fecha_salida"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">Fecha de Entrada</label>
                                <Field
                                    name="fecha_entrada"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="vehiculo">Vehiculo</label>
                                <Field
                                    name="vehiculo"
                                    loadOptions={this.getVehiculos}
                                    type="text"
                                    component={AsyncSelectField}
                                    top={{ top: "5px", position: "inherit" }}
                                    valueKey="id"
                                    labelKey="nombre"
                                    isClearable={true}
                                    className="form-control"
                                />
                                {
                                    (this.props.vehiculo_elegido) && (
                                        <div className="speech-bubble mt-1 p-2 ">
                                            <span style={{color: "#ad1717"}} >
                                                {`Proximo cambio de aceite: ${this.props.vehiculo_elegido.cambio_aceite} km.`}
                                            </span>
                                        </div>

                                    )
                                }
                            </div>
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="piloto">Tipo de pago</label>
                                <Field
                                    name='tipo_pago'
                                    component={SelectField}
                                    options={TIPO_PAGO}
                                    type="text"
                                    filterOptions={false}
                                    valueKey="value"
                                    labelKey="label"
                                    className="form-control"
                                // top ={{top:"67px", position:"inherit"}}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="piloto">Piloto</label>
                                <Field
                                    name="piloto"
                                    loadOptions={this.getPilotos}
                                    type="text"
                                    component={AsyncSelectField}
                                    top={{ top: "5px", position: "inherit" }}
                                    valueKey="id"
                                    labelKey="nombre"
                                    isClearable={false}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="piloto">Ayudante</label>
                                <Field
                                    name="ayudante"
                                    loadOptions={this.getPilotos}
                                    type="text"
                                    component={AsyncSelectField}
                                    top={{ top: "5px", position: "inherit" }}
                                    valueKey="id"
                                    labelKey="nombre"
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {
                            this.props.es_pagoExtra == 2 && (
                                <div className="col-lg-6 p-0">
                                    <div className="form-group has-feedback col-12">
                                        <label className="t-azul" htmlFor="piloto">Pago extra a Piloto</label>
                                        <Field
                                            name="pagoextrapiloto"
                                            loadOptions={this.getPagos}
                                            type="text"
                                            component={AsyncSelectField}
                                            top={{ top: "5px", position: "inherit" }}
                                            valueKey="id"
                                            labelKey="nombre"
                                            isClearable={true}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group has-feedback col-12">
                                        <label className="t-azul" htmlFor="piloto">Pago extra a Ayudante</label>
                                        <Field
                                            name="pagoextraayudante"
                                            loadOptions={this.getPagos}
                                            type="text"
                                            component={AsyncSelectField}
                                            top={{ top: "5px", position: "inherit" }}
                                            valueKey="id"
                                            labelKey="nombre"
                                            isClearable={true}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            )
                        }

                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">KM Inicial</label>
                                <Field
                                    name="km_inicial"
                                    type="number"
                                    component={renderField}
                                    top={{ top: "67px", position: "inherit" }}
                                />
                            </div>
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">KM Final</label>
                                <Field
                                    name="km_final"
                                    type="number"
                                    component={renderField}
                                    top={{ top: "67px", position: "inherit" }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="piloto">Km. Diferencia</label>
                                <Field
                                    readOnly={true}
                                    name="km_diferencia"
                                    input={{ value: this.props.km_diferencia }}
                                    type="number"
                                    component={renderField}
                                    top={{ top: "67px", position: "inherit" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 pt-4 px-4 m-0" >
                    <h4 className="t-primary text-uppercase">
                        ITINERARIO RUTA CLIENTE
                    </h4>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 m-0 p-0 form-group has-feedback">
                        <FieldArray
                            name="itinerario_detalle_clientes"
                            component={renderRuta}
                            reRender={this.reRender}
                            item={this.props.item}
                            setCliente={setCliente}
                            cliente={cliente}
                            paraCliente={true}
                            editar={editar}
                            top={{ top: "10px", position: "inherit" }}
                            despachos={despachos}
                            PDFITINERARIO={PDFITINERARIO}
                            mostrarVista={mostrarVista}
                            setClienteSucursal={setClienteSucursal}
                            count={count}
                        />
                    </div>
                </div>
                <div className="col-12 pt-4 px-4 m-0" >
                    <h4 className="t-primary text-uppercase">
                        ITINERARIO RUTA PROVEEDOR
                    </h4>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 m-0 p-0 form-group has-feedback">
                        <FieldArray
                            name="itinerario_detalle_proveedores"
                            component={renderRuta}
                            reRender={this.reRender}
                            item={this.props.item}
                            getDetalleOrden = { this.props.getDetalleOrden }
                            verProductos = {this.props.verProductosOrden}
                            borrarProductos = { this.props.borrarProductos }
                            paraCliente={false}
                            editar={editar}
                            top={{ top: "10px", position: "inherit" }}
                            ordenes={ordenes}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <Link className="btn btn-secondary  align-self-center" to="/itinerario" >CANCELAR</Link>
                            <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}


ItinerarioForm = reduxForm({
    form: 'itinerarioForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {};
        if (!data.fecha_entrada) {
            errors.fecha_entrada = "Campo requerido.";
        }
        if (!data.fecha_salida) {
            errors.fecha_salida = "Campo requerido";
        }
        if (!data.vehiculo) {
            errors.vehiculo = "Campo requerido";
        }
        if (!data.piloto) {
            errors.piloto = { user: "Campo requerido" }
        }
        if (data.km_inicial && data.km_final) {
            if (parseInt(data.km_final) < parseInt(data.km_inicial)) {
                errors.km_final = "No puede ser menor al inicial";
            }
        }
        if ((!data.itinerario_detalle_clientes || !data.itinerario_detalle_clientes.length) &&
            (!data.itinerario_detalle_proveedores || !data.itinerario_detalle_proveedores.length)) {
            errors.itinerario_detalle_proveedores = { _error: 'Debe de ingresar al menos un Registro' }
        } else {
            if (data.itinerario_detalle_clientes && data.itinerario_detalle_clientes.length) {
                const detalleArray = []
                data.itinerario_detalle_clientes.forEach((campo, index) => {
                    let detErrors = {};
                    let repetidos = [];
                    if (!campo.clienteProveedor) {
                        detErrors.clienteProveedor = "Campo requerido"
                    }
                    if (!campo.sucursal) {
                        detErrors.sucursal = "Campo requerido"
                    }
                    if (campo.despacho) {
                        repetidos = _.filter(data.itinerario_detalle_clientes, (x) => {
                            if (x.despacho)
                                return x.despacho.id == campo.despacho.id
                            return false;
                        });
                    }
                    if (!campo.despacho) {
                        detErrors.despacho = "Campo requerido"
                    } else {
                        if (1 < repetidos.length) {
                            detErrors.despacho = "Este despacho está repetido"
                        }
                    }
                    if (detErrors) {
                        detalleArray[index] = detErrors;
                    }
                });
                if (detalleArray.length) {
                    errors.itinerario_detalle_clientes = detalleArray
                }
            }
            if (data.itinerario_detalle_proveedores && data.itinerario_detalle_proveedores.length) {
                const detalleArray = []
                data.itinerario_detalle_proveedores.forEach((campo, index) => {
                    let detErrors = {};
                    let repetidos = [];
                    if (!campo.clienteProveedor) {
                        detErrors.clienteProveedor = "Campo requerido"
                    }
                    if (!campo.sucursal) {
                        detErrors.sucursal = "Campo requerido"
                    }
                    if (campo.orden_compra) {
                        repetidos = _.filter(data.itinerario_detalle_proveedores, (x) => {
                            if (x.orden_compra)
                                return x.orden_compra.id == campo.orden_compra.id
                            return false;
                        });
                    }
                    if (!campo.orden_compra) {
                        detErrors.orden_compra = "Campo requerido"
                    } else {
                        if (1 < repetidos.length) {
                            detErrors.orden_compra = "La orden está repetida"
                        }
                    }
                    if (detErrors) {
                        detalleArray[index] = detErrors;
                    }
                });
                if (detalleArray.length) {
                    errors.itinerario_detalle_proveedores = detalleArray
                }
            }
        }
        return errors;
    },
})(ItinerarioForm);


const selector = formValueSelector('itinerarioForm');
ItinerarioForm = connect(state => {
    const km_inicial = selector(state, 'km_inicial');
    const km_final = selector(state, 'km_final');
    const vehiculo_elegido = selector(state, 'vehiculo');
    const extra = selector(state, "tipo_pago")

    let km_diferencia = 0
    if (km_inicial && km_final) {
        km_diferencia = Math.abs(parseInt(km_final)) - Math.abs(parseInt(km_inicial))
    }
    let es_pagoExtra = extra

    let despachos =[]
    let ordenes = []
    if(state.form.itinerarioForm){
        if(state.form.itinerarioForm.values){
            despachos=state.form.itinerarioForm.values.itinerario_detalle_clientes
            ordenes = state.form.itinerarioForm.values.itinerario_detalle_proveedores
        }
    }
    return {
        km_diferencia,
        vehiculo_elegido,
        es_pagoExtra,
        despachos,
        ordenes
    }

})(ItinerarioForm);


export default ItinerarioForm;
