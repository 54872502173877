import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardFormulario from 'Utils/Cards/cardFormulario';

// Componentes extra
import DevolucionForm from './DevolucionForm';
import moment from 'moment';
import DetalleDespacho from '../Detalle/detalleOV';
import Swal from 'sweetalert2';

export default class DevolucionCrear extends Component {
    state = {
        step: 1,
        titulo: 'CREAR DEVOLUCIÓN',
        editar: false
    }
    componentWillMount() {
        this.props.selectEmpresas();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.setState({
                editar:true,
                titulo: 'VERIFICAR DEVOLUCIÓN'
            });
            this.props.leer(this.props.match.params.id, true)
        }
    }

    componentWillUnmount(){
        this.props.clearData();
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    onSubmit = (values) =>{
        const {crear, aceptarDevoluciones} =this.props
        let id = this.props.match.params.id
        let valores = _.cloneDeep(values);
        if(valores.detalle_despacho){
            valores.detalle_despacho.forEach( x => {
                x.producto = x.producto.id
            })
        }

        if(valores.depacho_devolucion){
            valores.depacho_devolucion = valores.depacho_devolucion.id;
        }
        if(valores.despachado_por){
            valores.despachado_por = valores.despachado_por.id;
        }
        valores.cliente = valores.cliente.id;
        valores.encargado = valores.encargado.id;
        valores.empresa = Number(valores.empresa);
        valores.fecha = moment(valores.fecha).format("YYYY-MM-D");
        this.state.editar ? aceptarDevoluciones(valores) : crear(valores);
    };

    anular = () => {
        const id = this.props.match.params.id;
        Swal.fire({
            title: '¿Desea rechazar esta devolución?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, rechazar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.rechazarDevolucion(id, false);
            }
        });
    };

    render() {
        const { loader, empresas, item, permisos } = this.props;
        return (
            <Fragment>
                {
                    (this.state.editar) ? (
                        <Fragment>
                            {

                                (permisos.devolucion_cli) ? (
                                    (item.flujo_despacho == 20) ? (
                                        <LoadMask loading={loader} light type={"TailSpin"}>
                                            <DetalleDespacho {...this.props} />
                                        </LoadMask>
                                    ): (
                                        <CardFormulario titulo={this.state.titulo}>
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <DevolucionForm
                                                    {...this.props}
                                                    initialValues={{fecha: new Date()}}
                                                    empresas={empresas}
                                                    editar={this.state.editar}
                                                    onSubmit={this.onSubmit}
                                                    anular={this.anular}
                                                />
                                            </LoadMask>
                                        </CardFormulario>
                                    )
                                ) : (
                                    <LoadMask loading={loader} light type={"TailSpin"}>
                                        <DetalleDespacho {...this.props} />
                                    </LoadMask>
                                )
                            }
                        </Fragment>
                    ) : (
                        <CardFormulario titulo={this.state.titulo}>
                            <LoadMask loading={loader} light type={"TailSpin"}>
                                <DevolucionForm
                                    {...this.props}
                                    initialValues={{fecha: new Date()}}
                                    empresas={empresas}
                                    editar={this.state.editar}
                                    onSubmit={this.onSubmit}/>
                            </LoadMask>
                        </CardFormulario>
                    )
                }
            </Fragment>
        );
    }
}
