import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}

export default class PlanillaGrid extends Component {
    componentDidMount() {
        const { listar, page, permisos } = this.props;
        listar(page);
        this.props.handleData(this.props.value)
    }

    descargar = (id) => {
        const { descargarCSV } = this.props;
        console.log("Planilla ", id);
        descargarCSV(id, 1);
        setTimeout(() => {
            descargarCSV(id, 2);
        }, 2000);
        console.log("Planilla ", id);
    }

    renderStandarActions = () =>{
        const { value, eliminar } = this.props
        const { descargar } = this;
        if (value) {
            return standardActions({ verpro: "planilla", descargar })
        }
        else{
            return standardActions({ editar: "planilla",  eliminar })
        }
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;

        return (
            <Grid page={this.props.page} hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                {
                    (this.props.permiso && this.props.estado) ? (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataSort
                            dataFormat={
                                this.renderStandarActions()
                            }
                        />
                    ) : (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataSort
                            width="16.7%"
                            dataFormat={cell => ""}
                        />
                    )
                }
                <TableHeaderColumn
                    dataField="modificado"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {(cell, row) => {
                        return <RenderDateTime fecha={cell} />
                    }}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="no_pago"
                    dataSort
                    tdStyle={cellTachado}
                >
                    NO. PAGO DE PLANILLA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    dataSort
                    dataFormat={
                        (cell, row) => (<RenderMoneda monto={cell}  />)
                    }
                >
                    TOTAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total_banco"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (<RenderMoneda monto={cell}  />)
                    }
                >
                    TOTAL BANCO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total_efectivo"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (<RenderMoneda monto={cell}  />)
                    }
                >
                    TOTAL EFECTIVO
                </TableHeaderColumn>
            </Grid>
        );
    }
}
