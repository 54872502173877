import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import ProductoForm from './ProductoForm';
import RecetaForm from './recetaForm';

import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import { UnidadMedida } from "../../../../../utility/constants";
import CardFormulario from '../../../Utils/Cards/CardProducto';


class ProductoCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR PRODUCTO',
        step: 1,
        arr_medidas: [],
    }
    setValores = (id) => {
        let unidades = _.filter(UnidadMedida, (e) => {
            if (e.unidad_medida == id) {
                return e;
            }
        });
        unidades = _.without(unidades, undefined);
        this.setState({ arr_medidas: unidades });
    };
    onChangeTipoUnidad = (id) => {
        this.setValores(id);
    };
    componentWillMount(){
        this.props.selectEmpresas2();
        this.props.selectRegiones()

        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR PRODUCTO'
            });
        }
    }
    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };


    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        let valores = _.cloneDeep(values);
        if(valores.recetas){
            valores.recetas.forEach( x => {
                x.producto = x.producto.id
            })
        }
        this.state.editar? editar(id,valores) : crear(valores);
    }
    componentWillUnmount(){
        this.props.resetFormulario();
    }
    render() {
        const { loader, empresas, regiones, item } = this.props;
        const { step } = this.state;
        return (
            <CardFormulario
                step={this.state.step}
                titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {
                        (step === 1) && (
                            <ProductoForm
                                item={item}
                                onSubmit={this.onSubmit}
                                onSubmit={this.nextStep}
                                empresas={empresas}
                                regiones={regiones}
                                initialValues={{
                                    precio_venta_interna:0,
                                    precio_por_produccion:0
                                }}
                                setValores={this.setValores}
                                onChangeTipoUnidad={this.onChangeTipoUnidad}
                                arr_medidas={this.state.arr_medidas}
                            />
                        )
                    }
                    {
                        (step === 2) && (
                            <RecetaForm
                                item={item}
                                onSubmit={this.onSubmit}
                                empresas={empresas}
                                regiones={regiones}
                                previousStep={this.previousStep}
                            />
                        )
                    }

                </LoadMask>
            </CardFormulario>
        );
    }
}
export default ProductoCrear;
