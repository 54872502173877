import React, {Component, Fragment} from 'react'
import { Link, Redirect } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import Grid from '../../../Utils/Grid/index';
import { customStandardActions } from "../../../Utils/Grid/StandardActions";
import ImageVer from '../../../../../../assets/img/icons/Ver.png';
// import getsimbolo from '../../../../../utility/constants.js'
// /home/yeicob/Documentos/ayg/frontend/src/js/utility/constants.js
export default class CerrarEstadoPeriodo extends Component{

    render(){
        let { data, loader } = this.props
        return(
            <div>

                <Grid hover striped data={{count:10, results: data}}  loading={loader}  pagination={false}>
                <TableHeaderColumn
                        dataField="liquidacion"
                        dataAlign="center"
                        dataSort
                        width="5%"
                        dataFormat={(cell, row) => {
                            return customStandardActions(cell, {
                                adicional: (c, r) => {
                                    return <Link to={`/caja/${row.cuenta}/liquidacion/${cell}`} >
                                    <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                                </Link>
                                }
                            });
                        }}
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat = {
                            (cell,row)=>(
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey
                        dataField="transaccion"
                        dataSort
                        dataFormat={
                            (cell,row)=> cell? 
                            <div>
                                <span className="font-weight-bold">Tipo de movimiento: </span>
                                {row.transaccion.nombre_tipo_transaccion}
                                <br />
                                {
                                    row.transaccion.nombre_proveedor && (
                                        <div>
                                            <span className="font-weight-bold">Proveedor: </span>
                                            {row.transaccion.nombre_proveedor}
                                            <br />
                                        </div>
                                    )
                                }
                                <span className="font-weight-bold">Descripción: </span>
                                {cell.descripcion}
                                {
                                        row.emitido_por &&(
                                            <Fragment>
                                                <span className="font-weight-bold">Enviada por: </span>
                                                <span style={{ color: "black" }} >{row.emitido_por}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        row.aceptado_por &&(
                                            <Fragment>
                                                <span className="font-weight-bold">Aceptada por: </span>
                                                <span style={{ color: "black" }} >{row.aceptado_por}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                            </div>
                            :"-------"
                        }
                    >
                        DESCRIPCIÓN 
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="es_ingreso"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                cell ? 'Ingreso':'Egreso'
                            )
                        }
                    >
                        MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="debe"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        DEBE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="haber"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        HABER
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="saldo"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        SALDO
                    </TableHeaderColumn>
                </Grid>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <button type="button"
                            onClick={(e) => {
                                e.preventDefault()
                                this.props.cerrarperiodo();
                                this.props.setOpenModalCierre(false);
                            }}
                            className="btn btn-t btn-primary m-l mt-3">CERRAR</button>
                        </div>
                    </div>
                </div>
               
                <br/>
            </div>
        )
    }
}