import React, {Component} from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import PDFViewer from 'pdf-viewer-reactjs'

import {api} from 'api'

export default class ValePreview extends Component{

    componentDidMount(){
        this.props.leer(this.props.match.params.id);
        this.props.resetForm();
    }

    render(){
        let { item, loader } = this.props
        return(
            <div className="Container bg-white">
                <div className="m-t p-t fnt-size">
                    <LoadMask loading={loader} light type={"TailSpin"}>
                        <h3 className="t-primary m-t p-t align-self-sm-start align-self-center">Vale No. {item.id}</h3>
                            {item.documento != null ? (
                                <div className="">
                                <div className="row">
                                <div className="col-12">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                                <a target="_blank" href={item.documento} download={`${item.id}`} className="btn btn-azul-claro m-1 align-self-center">IMPRIMIR</a>
                                </div>
                                </div>
                                </div><br/>
                                <div className="prueba">
                                <PDFViewer
                                navbarOnTop={true}
                                hideRotation={false}
                                document={{
                                    url: `${item.documento}`
                                }}
                                        />
                                    </div>
                                </div>
                            ):(
                                <div className="t-mostaza align-self-center">{"Documento no disponible para mostrar"}</div>
                                )
                            }
                    </LoadMask>
                </div>
            </div>
        )
    }
}
