import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {SelectField, AsyncSelectField} from '../../../Utils/renderField/renderField.js'
import { formatSelects } from '../../../Utils/renderField/renderReadField';

import { renderField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';

const BodegaForm = (props) => {
    const { handleSubmit, empresas} = props;
    return (
        <form name="bodegaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="empresa">Empresa</label>
                    <Field
                        name="empresa"
                        label="empresa"
                        valueKey="value"
                        labelKey="label"
                        options={formatSelects(empresas)}
                        component={SelectField}
                    />
                </div>
            </div>
            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary  align-self-center" to="/bodegas" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'bodegaForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            empresa: validators.exists()('Este campo es requerido'),
        });
    },
})(BodegaForm);
