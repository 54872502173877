import React, { Component, Fragment } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderTextArea,
    renderCurrency,
    AsyncSelectField,
    renderSelectField2,
    renderFieldDPI,
} from "../../../Utils/renderField";
import { SelectField } from "../../../Utils/renderField/renderField.js";
import { departamentos, municipios } from "../../../../../utility/constants";
import { api } from "api";
import _ from "lodash";

const getVendedores = (search) => {
    return api
        .get("vendedores", { search, is_active: true })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};
const renderCuentas = ({
    fields,
    meta: { error, submitFailed },
    contactos,
    validateNit,
    setDataName,
    changeFieldClienteForm,
    getFieldClienteFormFacturacion,
}) => {
    let optionsDocumentos = [
        { value: "NIT", label: "NIT", id: "NIT" },
        { value: "CUI", label: "CUI", id: "CUI" },
        { value: "EXT", label: "EXT", id: "EXT" },
    ];
    const tipoDeField = (index) => {
        let tipo = getFieldClienteFormFacturacion(index);
        switch (tipo) {
            case "NIT":
                return renderField;
            case "CUI":
                return renderFieldDPI;
            case "EXT":
                return renderField;
            default:
                return renderField;
        }
    };

    const readOnlyTipo = (index) => {
        let tipo = getFieldClienteFormFacturacion(index, "tipo");
        if (tipo) {//DESCOMENTAR
            // return tipo.length > 0
            //     ? ["NIT", "CUI"].includes(
            //           getFieldClienteFormFacturacion(index, "tipo")
            //       )
            //     : true;
            return tipo.length > 0
                ? ["NIT", "", "CUI"].includes(
                      getFieldClienteFormFacturacion(index, "tipo")
                  )
                : true;
        }
        return true;
    };

    const readOnlyName = (index) => {
        let tipo = getFieldClienteFormFacturacion(index, "tipo");
        if (tipo) {
            return tipo.length > 0 ? false : true;
        }
        return true;
    };
    return (
        <div className=" col-sm-12 p-0">
            <div className=" col-sm-12 form-group np-r p-0">
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table table-striped table-highlight mb-0">
                        <thead>
                            <tr>
                                <th>TIPO</th>
                                <th>IDENTIFICACIÓN</th>
                                <th></th>
                                <th>NOMBRE</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((mNit, index) => (
                                <tr key={index}>
                                    <td className="sin-borde-top">
                                        <Field
                                            name={`${mNit}.tipo`}
                                            type="text"
                                            component={renderSelectField2}
                                            options={optionsDocumentos}
                                            item_vacio={true}
                                            disabled={false}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td className="sin-borde-top">
                                        <Field
                                            name={`${mNit}.Nit`}
                                            type="text"
                                            component={tipoDeField(index)}
                                            onBlurs={true}
                                            onKeyDownEnter={setDataName}
                                            index={index}
                                            readOnly={readOnlyName(index)}
                                        />
                                    </td>
                                    <td
                                        className="text-center sin-borde-top boton-reactivo my-2"
                                        style={{
                                            width: "48px",
                                        }}
                                    >
                                        <img
                                            className="action-img"
                                            src={require("assets/img/icons/buscar.png")}
                                            alt="Buscar"
                                            title="Buscar"
                                            onClick={() => {
                                                let tipo =
                                                    getFieldClienteFormFacturacion(
                                                        index,
                                                        "tipo"
                                                    );
                                                switch (tipo) {
                                                    case "NIT":
                                                        validateNit(index);
                                                        break;
                                                    case "CUI":
                                                        setDataName(index);
                                                        break;
                                                    case "EXT":
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </td>
                                    <td className="sin-borde-top">
                                        <Field
                                            name={`${mNit}.nombre`}
                                            type="text"
                                            component={renderField}
                                            readOnly={readOnlyTipo(index)}
                                        />
                                    </td>
                                    <td
                                        className="text-center sin-borde-top"
                                        style={{ width: "48px" }}
                                    >
                                        <img
                                            className="action-img"
                                            src={require("assets/img/icons/Cerrar.png")}
                                            alt="Borrar"
                                            title="Borrar"
                                            onClick={() => {
                                                fields.remove(index);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-start mt-2">
                    <button
                        type="button"
                        className="btn btn-primary py-0"
                        onClick={() => fields.push({})}
                    >
                        AGREGAR IDENTIFICACIÓN
                    </button>
                </div>
            </div>
        </div>
    );
};

const renderSucursales = ({
    fields,
    meta: { error, submitFailed },
    es_cliente,
    municipios_bancos,
    AddMunicipio,
    ChangeMunicipio,
    nombre,
    telefono,
    departamento,
    municipio,
    EliminarMunicipio,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight  mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ENCARGADO</th>
                            <th>DIRECCIÓN</th>
                            <th>TELÉFONO</th>
                            <th style={{ width: "20%", minWidth: "100px" }}>
                                DEPTO
                            </th>
                            <th style={{ width: "20%", minWidth: "100px" }}>
                                MPIO
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((sucursal, index) => (
                            <tr key={index}>
                                <td
                                    className="text-center sin-borde-top"
                                    style={{ width: "48px" }}
                                >
                                    <img
                                        className="action-img"
                                        src={require("assets/img/icons/Cerrar.png")}
                                        alt="Borrar"
                                        title="Borrar"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.encargado`}
                                        type="text"
                                        component={renderField}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.direccion`}
                                        type="text"
                                        component={renderField}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.telefono`}
                                        type="text"
                                        component={renderField}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.departamento`}
                                        label="departamento"
                                        component={SelectField}
                                        options={departamentos}
                                        className="form-control"
                                        onChange={(e) => {
                                            ChangeMunicipio(
                                                index,
                                                `${e[0]}${e[1]}`
                                            );
                                            AddMunicipio(
                                                departamento[0] +
                                                    departamento[1],
                                                1
                                            );
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${sucursal}.municipios`}
                                        label="municipios"
                                        component={SelectField}
                                        options={municipios_bancos[index]}
                                        className="form-control"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => {
                        EliminarMunicipio(1);
                        AddMunicipio(departamento[0] + departamento[1]);
                        if (es_cliente == 1) {
                            fields.push({
                                horario_entrada: "2020-12-16T07:00:00-06:00",
                                horario_salida: "2020-01-20T19:00:00-06:00",
                            });
                        } else {
                            let municipio_set = "";
                            let mun = Object.values(municipio);
                            mun.pop();
                            municipio_set = mun.join("");
                            fields.push({
                                horario_entrada: "2020-12-16T07:00:00-06:00",
                                horario_salida: "2020-01-20T19:00:00-06:00",
                                encargado: nombre.target
                                    ? nombre.target.defaultValue
                                    : "",
                                telefono: telefono.target
                                    ? telefono.target.defaultValue
                                    : "",
                                departamento: departamento
                                    ? departamento[0] + departamento[1]
                                    : "",
                                municipios: municipio_set,
                            });
                        }
                    }}
                >
                    AGREGAR SUCURSAL
                </button>
            </div>
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);
class ClienteForm extends Component {
    state = {
        nombre: "",
        telefono: "",
    };
    render() {
        const {
            departamento,
            municipio,
            setDepartamento,
            setMunicipio,
            handleSubmit,
            municipios,
            es_cliente,
            validateNit,
            AddMunicipio,
            ChangeMunicipio,
            municipios_bancos,
            setDataName,
            changeFieldClienteForm,
            getFieldClienteFormFacturacion,
        } = this.props;
        return (
            <form name="ClienteForm" className="form-validate mb-lg">
                <div className="row justify-content-center">
                    <h4 className="t-primary">{this.props.titulo}</h4>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-8 col-md-12  col-12">
                        <label className="t-azul" htmlFor="nombre">
                            Nombre*
                        </label>
                        <Field
                            name="nombre"
                            label="nombre"
                            type="text"
                            component={renderField}
                            className="form-control"
                            onChange={(value) => {
                                this.setState({ nombre: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                        <label className="t-azul" htmlFor="telefono">
                            Teléfono
                        </label>
                        <Field
                            name="telefono"
                            label="telefono"
                            component={renderField}
                            className="form-control"
                            onChange={(value) => {
                                this.setState({ telefono: value });
                            }}
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                        <label className="t-azul" htmlFor="correo">
                            Correo
                        </label>
                        <Field
                            name="correo"
                            label="correo"
                            component={renderField}
                            className="form-control"
                        />
                    </div>
                </div>
                {es_cliente == 2 && (
                    <div className="row justify-content-center">
                        <div
                            className={`form-group has-feedback ${
                                es_cliente == 2
                                    ? "col-lg-4 col-md-6  col-12"
                                    : "col-lg-8 col-12"
                            }`}
                        >
                            <label className="t-azul" htmlFor="region">
                                Región*
                            </label>
                            <Field
                                name="region"
                                label="region"
                                component={SelectField}
                                options={this.props.regiones}
                                valueKey={"id"}
                                labelKey={"region"}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                            <label className="t-azul" htmlFor="nombre">
                                Vendedor*
                            </label>
                            <Field
                                isClearable={true}
                                name="vendedor"
                                label="vendedor"
                                type="text"
                                component={AsyncSelectField}
                                loadOptions={getVendedores}
                                valueKey="id"
                                labelKey="first_name"
                                className="form-control"
                            />
                        </div>
                    </div>
                )}
                {es_cliente == 2 && (
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                            <label className="t-azul" htmlFor="correo">
                                Departamento*
                            </label>
                            <Field
                                name="departamento"
                                label="departamento"
                                component={SelectField}
                                options={departamentos}
                                className="form-control"
                                onChange={(e) => {
                                    this.props.getMunicipios(`${e[0]}${e[1]}`);
                                    setDepartamento(e);
                                }}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                            <label className="t-azul" htmlFor="municipios">
                                Municipio*
                            </label>
                            <Field
                                name="municipios"
                                label="municipios"
                                component={SelectField}
                                options={municipios}
                                className="form-control"
                                onChange={(e) => {
                                    setMunicipio(e);
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-8 col-md-12  col-12">
                        <label className="t-azul" htmlFor="descripcion">
                            Comentario
                        </label>
                        <Field
                            name="descripcion"
                            label="descripcion"
                            component={renderTextArea}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <h4 className="t-primary">DOCUMENTOS DEL CLIENTE</h4>
                </div>
                <FieldArray
                    name="facturacion"
                    component={renderCuentas}
                    validateNit={validateNit}
                    es_cliente={es_cliente}
                    changeFieldClienteForm={changeFieldClienteForm}
                    setDataName={setDataName}
                    getFieldClienteFormFacturacion={
                        getFieldClienteFormFacturacion
                    }
                />

                <div className="row justify-content-center">
                    <h4 className="t-primary">SUCURSALES</h4>
                </div>
                <FieldArray
                    name="sucursales"
                    component={renderSucursales}
                    municipios_bancos={municipios_bancos}
                    AddMunicipio={AddMunicipio}
                    ChangeMunicipio={ChangeMunicipio}
                    es_cliente={2}
                    nombre={this.state.nombre}
                    telefono={this.state.telefono}
                    departamento={departamento}
                    municipio={municipio}
                    EliminarMunicipio={this.props.EliminarMunicipio}
                />
                <div></div>
                <div className="row  justify-content-sm-around justify-content-center">
                    <button
                        type="button"
                        className="btn btn-primary m-1 align-self-center"
                        onClick={handleSubmit}
                    >
                        GUARDAR
                    </button>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: "ClientesForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data, props) => {
        const errors = {};
        if (!data.nombre) {
            errors.nombre = "Campo requerido";
        }
        if (!data.region) {
            errors.region = "Campo requerido";
        }
        if (!data.vendedor) {
            errors.vendedor = "Campo requerido";
        }
        if (!data.departamento) {
            errors.departamento = "Campo requerido";
        }
        if (!data.municipios) {
            errors.municipios = "Campo requerido";
        }
        if (data.facturacion && data.facturacion.length) {
            const detalleArray = [];
            data.facturacion.forEach((campo, index) => {
                let detErrors = {};
                if (!campo.Nit) {
                    detErrors.Nit = "Campo requerido";
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.facturacion = detalleArray;
            }
        }
        /* if (data.facturacion && data.facturacion.length) {
            const detalleArray = [];
            data.facturacion.forEach((identificacion, index) => {
                let detErrors = {};
                // Verificación NIT
                if (identificacion.tipo == "NIT" && identificacion.Nit && identificacion.nombre) {
                    let existe = _.find(data.facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='NIT')
                    if (existe && existe.Nit != identificacion.Nit) detErrors.Nit = "NIT no válido.";
                    if (detErrors.Nit) detalleArray[index] = detErrors;
                }
                // Verificación CUI
                if (identificacion.tipo == "CUI" && identificacion.Nit && identificacion.nombre) {
                    let existe = _.find(data.facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='CUI')
                    if (existe && existe.Nit != identificacion.Nit) detErrors.Nit = "CUI no válido.";
                    if (detErrors.Nit) detalleArray[index] = detErrors;
                }
            })
            if (detalleArray.length) errors.facturacion = detalleArray;
        } */
        if (!data.sucursales || !data.sucursales.length) {
            errors.sucursales = {
                _error: "Debe de ingresar al menos una sucursal",
            };
        }
        return errors;
    },
})(ClienteForm);
